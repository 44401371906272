<template>
  <div>
    <van-tree-select :items="items"
                     :active-id.sync="activeId"
                     :main-active-index.sync="activeIndex"
                     @click-item="clickItem(activeId)">
      <template #content
                v-if="activeIndex == 2">
        <van-tree-select :items="items2"
                         :active-id.sync="activeId2"
                         :main-active-index.sync="activeIndex2"
                         @click-item="clickItem(activeId2)">
        </van-tree-select>
      </template>
    </van-tree-select>
    <div class="bottom_btn">
      <!-- <van-button type="primary"
                  size="large"
                  plain
                  style="width:50%;
                      color:#36e0c9;
                      border:none;
                      border-top:1px solid #f6f6f6"
                  @click="resetPrice">重置</van-button>
      <van-button type="primary"
                  size="large"
                  color="#36e0c9"
                  style="width:50%"
                  @click="getPrice">完成</van-button> -->
    </div>
  </div>
</template>
<script>
import { getHomeData } from '@/api/hotel.js'
import { coordinates } from '@/commom/coordinates.js'
export default {
  data () {
    return {
      items: [
        {
          // 导航名称
          cityInfo: null,
          text: '直线距离',
          // 该导航下所有的可选项
          children: [
            {
              text: '市以内',
              id: 1
            },
            {
              text: '1公里内',
              id: 2
            },
            {
              text: '2公里内',
              id: 3
            },
            {
              text: '4公里内',
              id: 4
            },
            {
              text: '8公里内',
              id: 5
            },
            {
              text: '10公里内',
              id: 6
            }
          ]
        }
        // {
        //   text: '景点',
        //   children: [
        //     {
        //       text: '松山湖',
        //       id: 3,
        //     },
        //     {
        //       text: '华阳湿地公园',
        //       id: 4,
        //     },
        //   ],
        // },
        // {
        //   text: '地铁线',
        //   children: [
        //     {
        //       text: '5号线',
        //       id: 5,
        //     },
        //     {
        //       text: '2号线',
        //       id: 6,
        //     },
        //   ],
        // },
      ],
      // 位置返回的数据
      coordinates: null,
      activeId: 13, //右侧选中项的 id
      activeId2: 1, //右侧选中项的 id
      activeIndex: 0, //左侧选中项的索引
      activeIndex2: 0
    }
  },
  created () {
    this.cityInfo = JSON.parse(sessionStorage.getItem('sessionCityData'))
  },
  methods: {
    async clickItem (items) {
      console.log(items)
      if (items == 1) {
        let data = {
          city_id: this.cityInfo.city_id,
          status: 1
        }
        console.log(data)
        getHomeData(data).then(res => {
          console.log(res)
          this.coordinates = res.data.data.list
          this.$emit('coordinates', this.coordinates)
          console.log(this.coordinates)
        })
      } else if (items == 2) {
        this.coordinates = await coordinates(1000)

        console.log(this.coordinates, ' this.coordinates=======')
        this.$emit('coordinates', this.coordinates)
      } else if (items == 3) {
        let today = new Date().getTime()
        today = today.toString()
        today = today.substring(0, today.length - 3)
        today = Number(today)
        let tomorrow = new Date().getTime() + 24 * 60 * 60 * 1000
        tomorrow = tomorrow.toString()
        tomorrow = tomorrow.substring(0, tomorrow.length - 3)
        tomorrow = Number(tomorrow)
        let latitude = localStorage.getItem('latitude')

        let longitude = localStorage.getItem('longitude')
        let data = {
          longitude: longitude,
          latitude: latitude,
          start_date: today,
          end_date: tomorrow,
          distance: 2000
        }
        console.log(data, '距离里面data')
        getHomeData(data).then(res => {
          // console.log(res);
          this.coordinates = res.data.data.list
          this.$emit('coordinates', this.coordinates)
        })
      } else if (items == 4) {
        let today = new Date().getTime()
        today = today.toString()
        today = today.substring(0, today.length - 3)
        today = Number(today)
        let tomorrow = new Date().getTime() + 24 * 60 * 60 * 1000
        tomorrow = tomorrow.toString()
        tomorrow = tomorrow.substring(0, tomorrow.length - 3)
        tomorrow = Number(tomorrow)
        let latitude = localStorage.getItem('latitude')
        let longitude = localStorage.getItem('longitude')
        let data = {
          longitude: longitude,
          latitude: latitude,
          start_date: today,
          end_date: tomorrow,
          distance: 4000
        }
        console.log(data, '距离里面data')
        getHomeData(data).then(res => {
          console.log(res)
          this.coordinates = res.data.data.list
          this.$emit('coordinates', this.coordinates)
          console.log(this.coordinates)
        })
      } else if (items == 5) {
        let today = new Date().getTime()
        today = today.toString()
        today = today.substring(0, today.length - 3)
        today = Number(today)
        let tomorrow = new Date().getTime() + 24 * 60 * 60 * 1000
        tomorrow = tomorrow.toString()
        tomorrow = tomorrow.substring(0, tomorrow.length - 3)
        tomorrow = Number(tomorrow)
        let latitude = localStorage
          .getItem('latitude')
        let longitude = localStorage
          .getItem('longitude')
        let data = {
          longitude: longitude,
          latitude: latitude,
          start_date: today,
          end_date: tomorrow,
          distance: 8000
        }
        console.log(data, '距离里面data')
        getHomeData(data).then(res => {
          // console.log(res);
          this.coordinates = res.data.data.list
          this.$emit('coordinates', this.coordinates)
          console.log(this.coordinates)
        })
      } else if (items == 6) {
        let today = new Date().getTime()
        today = today.toString()
        today = today.substring(0, today.length - 3)
        today = Number(today)
        let tomorrow = new Date().getTime() + 24 * 60 * 60 * 1000
        tomorrow = tomorrow.toString()
        tomorrow = tomorrow.substring(0, tomorrow.length - 3)
        tomorrow = Number(tomorrow)
        let latitude = localStorage.getItem('latitude')
        let longitude = localStorage.getItem('longitude')
        let data = {
          longitude: longitude,
          latitude: latitude,
          start_date: today,
          end_date: tomorrow,
          distance: 10000
        }
        console.log(data, '距离里面data')
        getHomeData(data).then(res => {
          // console.log(res);
          this.coordinates = res.data.data.list
          this.$emit('coordinates', this.coordinates)
          console.log(this.coordinates)
        })
      }
    },
    resetPrice () {
      console.log('222')
    },
    getPrice () {
      console.log('点击了')
    }
  }
}
</script>
