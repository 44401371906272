<template>
  <div id="HotelDatail"
       ref='scorll_top'>
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->

    <van-icon class="icon"
              @click="collect"
              :name="star" />
    <div class="iconfont"
         id="QRcode"
         @click="QRcode">&#xeaf1;
    </div>

    <div class="advertising">

      <van-popup v-model="advertising">
        <div class="btn"
             @click="close"><img src="../../../assets/Esports/bt.png"
               alt=""></div>
        <div class="advertising_img"
             @click="goPay"><img src="../../../assets/Esports/advertising.png"
               alt=""></div>

      </van-popup>
    </div>

    <div class="header">

      <div class="headerImg">
        <van-swipe class="my-swipe"
                   :autoplay="0"
                   :show-indicators='false'>
          <van-swipe-item v-for="item in hotelInfo.images"
                          :key="item.id">
            <img :src="item"
                 alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="InfoShow">
        <div style="font-weight:600;font-size:.35rem ;">{{hotelInfo.name}}</div>
        <div style="font-size:0.25rem;margin-top:0.2rem">
          <!-- <span style="width: 5rem;display: inline-block;">{{hotelInfo.describe}}</span> -->
          <div class="tag"
               style="display: inline-block;"
               v-for="item in hotelInfo.hotel_tags"
               :key="item.id">{{item}}</div>
          <span style="color:#0086F7;float: right;"
                @click="goHotel">设施/详情
            <van-icon name="arrow" />
          </span>
        </div>
        <div class="InfoShow_bottom">
          <div class="evaluate_hotel"
               @click="goEvaluate"
               v-if="is_showList !=3">
            <span class="evaluate_lv">5.0
            </span>
            <span class="evaluate_grade">分</span>
            <div style="display: flex;
    justify-content: space-around;">
              <span class="evaluate_total">430评价<span class="iconfont"
                      style="color:white">&#xe709;</span></span>
            </div>

          </div>
          <!-- 电竞板块  -->
          <div class="evaluate_sprots"
               @click="goEvaluate"
               v-if="is_showList ==3">
            <span class="evaluate_lv">5.0

            </span><span class="evaluate_grade">分</span>
            <span class="evaluate_total">430评价<span class="iconfont"
                    style="color:white">&#xe709;</span></span>
            <!-- <span class="evaluate_1">8条
              <van-icon name="arrow" />
            </span> -->
            <!-- <span class="evaluate_2">期待入住后留下评论</span> -->
          </div>
          <div class="address"
               @click="HotelAddress">
            <div style="font-size:0.26rem;">
              <div style="display: flex;">
                <span class="address_info"> {{hotelInfo.address}}</span>
              </div>
              <img class="address_position"
                   src="../../../assets/HotelImg/position.png"
                   alt="">
            </div>
          </div>
        </div>
        <!-- <div style="font-size:0.26rem;margin-top:0.3rem;width:5rem">
          {{hotelInfo.address}}
        </div>
        <div class="bottom">
          <div class="shortDesc">{{hotelInfo.describe}}</div>
          <div>
            <div class="icon"
                 @click="HotelAddress">
              <img src="@/assets/HotelImg/Map_iconBlue.png"
                   alt="">
              <div style="color: #0086F7;">地图·周边
                <van-icon name="arrow" />
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="DetailList">
      <div :class="is_showList!=3?'date':'date_esports'"
           @click="changeDate">
        {{ ArrivalDate}}
        <i style="font-style: normal; font-size: 14px; color: #717272; font-weight: 400;">今天入住</i>
        <span>共{{dateLength}}晚
        </span>
        {{LeavelDate}}
        <i style="font-style: normal; font-size: 14px; color: #717272; font-weight: 400;">明天离店</i>
        <van-icon name="arrow"
                  style="float:right; line-height: .5556rem;"
                  color="#60e4de" />
      </div>
      <!-- 不套多一个div会在渲染dom时报未定义属性 -->
      <div v-if="typeData!=''">
        <div v-if="typeData[0].text!=undefined"
             :class="is_showList!=3?'type':'type_esports'">
          <div class="type_btn"
               v-for="(item,index) in typeData"
               :key="item.id"
               :class="item.isclick==true?'is_type_active':''"
               @click="getType(item,index)">{{item.text}}
            <div v-if="item.isclick"
                 class="checked">
              <!-- <img src="@/assets/HotelImg/is_active.png"
                   alt=""> -->
            </div>
          </div>
        </div>
      </div>

      <div style="background-color:#f0f2f5">
        <!-- 酒店列表 -->
        <div class="homeList">
          <ShowList :is_showList="is_showList"
                    :roomList='roomList'
                    @getHomeID='getHomeID'
                    @getOrderId='getOrderId'
                    @goPay='goPay'></ShowList>
        </div>
        <!-- 酒店卖点 -->
        <div class="sellingPoint">
          <div style="font-size:0.3rem;font-weight:bold">
            酒店卖点
          </div>
          <div class="img">
            <div v-html="hotelInfo.description"></div>
          </div>
          <div class="btn">
            <van-button type="default"
                        @click="seeMore(1)"
                        v-if="ifMore">查看更多</van-button>
            <van-button type="default"
                        @click="packUp(1)"
                        v-else>收起</van-button>
          </div>
        </div>
        <!-- 订房必读 -->
        <div class="ReserImport">
          <div style="position: relative;padding-left:0.3rem">
            <img src="@/assets/HotelImg/tinme_icon.png"
                 alt="">
            <span>订房必读</span>
          </div>
          <div style="padding:0 0.3rem;margin-top:0.2rem">
            <div v-html="hotelInfo.buy_notice"></div>
          </div>

        </div>
        <!-- 政策 -->
        <div class="Policy">
          <div style="padding-left:0.3rem">
            <div class="title"
                 style="font-size:0.3rem;font-weight:bold">政策</div>
            <div class="content">
              <div v-if="hotelInfo.entry_departure_time">
                <div class="title">
                  <img src="@/assets/HotelImg/EL_icon.png"
                       alt="">
                  <span>入离时间</span>
                </div>
                <div class="cont">
                  <span style="color:gray;font-size:0.24rem">
                    <span>{{hotelInfo.entry_departure_time}}</span>
                  </span>
                </div>
              </div>
              <div v-if="hotelInfo.add_beds">
                <div class="title">
                  <img src="@/assets/HotelImg/child_Icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>儿童及加床</span>
                </div>
                <div class="cont">
                  <div>{{hotelInfo.add_beds}}</div>
                </div>
              </div>
              <div v-if="hotelInfo.pets">
                <div class="title">
                  <img src="@/assets/HotelImg/pets_Icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>宠物</span>
                </div>
                <div class="cont">
                  <span style="color:gray;font-size:0.24rem">
                    <span>{{hotelInfo.pets}}</span>
                  </span>
                </div>
              </div>
              <div class="meal"
                   v-if="hotelInfo.meal_explanation">
                <div class="title">
                  <img src="@/assets/HotelImg/meal_Icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>用餐说明</span>
                </div>
                <div class="cont">
                  <!-- <div>
                    <div>
                      <span>种类</span><span class="right">西式,中式,亚洲风味</span>
                    </div>
                    <div>
                      <span>形式</span><span class="right">自助餐</span>
                    </div>
                    <div>
                      <span>价格</span><span class="right">￥148.00</span>
                    </div>
                    <div>
                      <span>营业</span><span class="right">星期一至星期五07:00至10:00开放</span>
                      <div style="margin-top:0rem">
                        <span>时间</span><span class="right">星期六至星期日07:00至10:30开放</span>
                      </div>
                    </div>
                  </div> -->
                  {{hotelInfo.meal_explanation}}
                </div>
              </div>
              <div v-if="hotelInfo.deposit">
                <div class="title">
                  <img src="@/assets/HotelImg/pay_Icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>押金政策</span>
                </div>
                <div class="cont">
                  <span style="color:gray;font-size:0.24rem">
                    <span>{{hotelInfo.deposit}}</span>
                  </span>
                </div>
              </div>
              <div v-if="hotelInfo.unsubscribe">
                <div class="title">
                  <img src="@/assets/HotelImg/DepositPolicy.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>退订政策</span>
                </div>
                <div class="cont">
                  <span style="color:gray;font-size:0.24rem">
                    <span>{{hotelInfo.unsubscribe}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 设施 -->
        <div class="Facilities">
          <div class="title">
            设施服务
          </div>
          <!-- <div class="imageList">
            <span v-for="(item,index) in FirstImg"
                  :key="index">
              <img :src="item.img"
                   alt=""
                   @click="getImg(index)">
              <div>{{item.title}}(共{{item.length}}张)</div>
            </span>
          </div> -->
          <div class="contentF"
               v-if="hotelInfo.facility">
            <div v-for="fac in hotelInfo.facility"
                 :key='fac.id'>
              <div v-if="fac.name=='停车场'">
                <div class="title">
                  <img src="@/assets/HotelImg/park_icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
              <div v-if="fac.name=='网络'">
                <div class="title">
                  <img src="@/assets/HotelImg/wifi_Icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
              <div v-if="fac.name=='交通服务'">
                <div class="title">
                  <img src="@/assets/HotelImg/tra_icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
              <div v-if="fac.name=='前台服务'">
                <div class="title">
                  <img src="@/assets/HotelImg/rec_icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
              <div v-if="fac.name=='餐饮服务'">
                <div class="title">
                  <img src="@/assets/HotelImg/meal_Icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
              <div v-if="fac.name=='通用设施'">
                <div class="title">
                  <img src="@/assets/HotelImg/cur_icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
              <div v-if="fac.name=='商务服务'">
                <div class="title">
                  <img src="@/assets/HotelImg/bus_icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
              <div v-if="fac.name=='其他服务'">
                <div class="title">
                  <img src="@/assets/HotelImg/oth_icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
              <div v-if="fac.name=='儿童设施'">
                <div class="title">
                  <img src="@/assets/HotelImg/childF_icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
              <div v-if="fac.name=='公共区'">
                <div class="title">
                  <img src="@/assets/HotelImg/pub_icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
              <div v-if="fac.name=='康体设施'">
                <div class="title">
                  <img src="@/assets/HotelImg/well_icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
              <div v-if="fac.name=='娱乐活动设施'">
                <div class="title">
                  <img src="@/assets/HotelImg/swim_icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
              <div v-if="fac.name=='特色设施'">
                <div class="title">
                  <img src="@/assets/HotelImg/spec_icon.png"
                       alt=""
                       style="width:5%;left:-1%">
                  <span>{{fac.name}}</span>
                </div>
                <div class="cont"
                     v-for="item in fac.data"
                     :key="item.id">
                  <div style="color:gray;font-size:0.22rem">
                    {{item}}
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="btn"
               v-if="hotelInfo.facility">
            <van-button type="default"
                        @click="seeMore(2)"
                        v-if="ifMore2">查看更多</van-button>
            <van-button type="default"
                        @click="packUp(2)"
                        v-else>收起</van-button>
          </div>
        </div>
        <!-- 交通娱乐 -->
        <div class="TraEte">
          <div class="title">
            交通娱乐
          </div>
          <div class="location">
            <!-- 地图 -->
            <div>
              <baidu-map class="bm-view"
                         v-if="{lng:this.lng,lat:this.lat}"
                         ak='VFc7NsfU7amcxYbEiqypp5a3gHPLaiOO'
                         :center="{lng:this.lng,lat:this.lat}"
                         :zoom="15">
                <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                                :showAddressBar="true"
                                :autoLocation="true"></bm-geolocation>
                <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
                <bm-marker :position="{lng:this.lng,lat:this.lat}"
                           :dragging="true">

                  <!-- <bm-info-window :showMap="showMap"
                                  @close="infoWindowClose"
                                  @open="infoWindowOpen">
                  </bm-info-window> -->
                </bm-marker>
              </baidu-map>
            </div>
            <div class="address">
              地址:{{hotelInfo.address}}
              <!-- 地址:东纵路208号SOHO20栋一楼大堂147号商铺 -->
            </div>
          </div>
          <!-- <div class="info">
            <van-tabs v-model="activeInfo">
              <van-tab title="交通信息">内容 1</van-tab>
              <van-tab title="景点">内容 2</van-tab>
              <van-tab title="美食">内容 3</van-tab>
              <van-tab title="商城">内容 4</van-tab>
              <van-tab title="丽人美容">内容 5</van-tab>
            </van-tabs>
          </div> -->
        </div>
        <div class="reserv">
          <div>
            <span :class="is_showList!=3?'btn':'btn_sports'"
                  @click="returnHome"
                  v-if="roomList.length !=0">预定房间 | ￥{{roomList[0].salesCeil_price}}</span>
          </div>
        </div>
      </div>

    </div>
    <!-- 滚动时的顶部导航栏 -->
    <!-- v-show="is_Bar" -->
    <div id="topNav"
         v-show="is_Bar">
      <van-row>
        <van-col span="6"
                 style="padding:0.1rem 0rem"
                 v-for="(item,index) in topTitle"
                 :key="item.id"
                 :class="item.isclick==true?'is_scroll_active':''"
                 @click="changeScorll(item,index)">
          <span>{{item.title}}</span>
        </van-col>
      </van-row>
    </div>
    <!-- 日历弹窗 -->
    <div>
      <van-calendar :class="is_showList!=3?'':'esports_hotel'"
                    v-model="calendarShow"
                    color="#36e0c9"
                    type="range"
                    @confirm="onConfirm"
                    :default-date="defaultDate"
                    :formatter="formatter" />
    </div>
    <div class="ActionSheet">
      <ActionSheet :Actionshow='Actionshow'
                   :WhichOne='WhichOne'
                   :roomIndexData='roomIndexData'
                   @clickOverlay='clickOverlay'></ActionSheet>
    </div>
  </div>
</template>

<script>
// import coordtransform from 'coordtransform';
import { getJSSDK } from '@/api/JSSDk.js'
import { postCollect, getCollect, delCollect } from '@/api/collect' //eslint-disable-line no-unused-vars
import wx from 'weixin-js-sdk'
import { getHotelDetail, getRoomDatePrice, QRcode } from '../../../api/hotel.js' //getRoom_theme
import { aesKey } from "../../../commom/aesKey"

// import NavBar from '../../../components/NavBar.vue'
import ShowList from '../../../components/ShowList.vue'
import ActionSheet from '../../../components/ActionSheet.vue'
import { ImagePreview, Toast } from "vant";

// 百度地图
import BaiduMap from 'vue-baidu-map/components/map/Map'
import BmNavigation from 'vue-baidu-map/components/controls/Navigation'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation'
import BmCityList from 'vue-baidu-map/components/controls/CityList'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'
import { Notify } from 'vant';
// import BmInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow'
export default {
  components: {
    // NavBar,
    ShowList,
    ActionSheet,
    BaiduMap: BaiduMap,
    BmNavigation: BmNavigation,
    BmGeolocation: BmGeolocation,
    BmCityList: BmCityList,
    BmMarker: BmMarker,
    // BmInfoWindow: BmInfoWindow
  },
  data () {
    return {
      step: 2, // 数值越大速度滚动越快
      limitMoveNum: 3, // 开启无缝滚动的数据量 设置(数值<=页面展示数据条数不滚)(超过页面展示条数滚动)
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 40,
      waitTime: 2500, // 单行停顿时间(singleHeight,waitTime)
      // ————————————————滚动条组件


      showMap: true,
      lng: null,
      lat: null,
      city_name: '',
      Collect_id: null,
      star: 'star-o',
      ticket: null,
      NavBarTitle: '酒店详情',
      hotelId: '',//酒店ID
      hotelInfo: '',//酒店基本信息
      roomList: [],//房型信息
      roomDatePrice: [],//
      roomIndexData: {},//那个房型的信息
      aesKey: aesKey(),//接口通过密文来获取数据
      room_pic: [],
      dateArray: [],//日期数据
      calendarShow: false,//日历是否出现
      defaultDate: [],//默认日期
      ArrivalDate: '',//入住日期的文字数据
      LeavelDate: '',//离开日期的文字数据
      ArrivalDateStamp: '',
      LeavelDateStamp: '',
      dateLength: '',//
      advertising: false,
      url: '',
      typeData: [
        // {
        //   text: '特价优惠',
        //   isclick: false
        // },
        // {
        //   text: '钟点房',
        //   isclick: false
        // },
        // {
        //   text: '大床房',
        //   isclick: false
        // },
        // {
        //   text: '双床房',
        //   isclick: false
        // },
        // {
        //   text: '积分兑换',
        //   isclick: false
        // },
        // {
        //   text: '积分兑换',
        //   isclick: false
        // },
        // {
        //   text: '积分兑换',
        //   isclick: false
        // },
        // {
        //   text: '积分兑换',
        //   isclick: false
        // },


      ],
      is_showList: '2',//判断需要显示那个列表
      is_Bar: false,//顶部导航栏是否出现
      topTitle: [
        {
          title: '预订',
          isclick: true
        },
        {
          title: '酒店政策',
          isclick: false
        },
        {
          title: '酒店设施',
          isclick: false
        },
        {
          title: '交通娱乐',
          isclick: false
        },
      ],
      ifMore: true,//是否看到'查看更多'的按钮
      ifMore2: true,//是否看到'查看更多'的按钮
      activeInfo: 1,//交通娱乐标签页
      FacImgList: [
        {
          title: '中式餐厅',
          img: ['https://img0.baidu.com/it/u=2189369201,1864745545&fm=26&fmt=auto',
            'https://img2.baidu.com/it/u=2389596207,3631129498&fm=26&fmt=auto']
        },
        {
          title: '西式餐厅',
          img: ['https://img2.baidu.com/it/u=2667493278,838379061&fm=26&fmt=auto',
            'https://img0.baidu.com/it/u=3049210045,121887870&fm=26&fmt=auto']
        },
        {
          title: '自助餐',
          img: ['https://img0.baidu.com/it/u=3174223830,2128560585&fm=26&fmt=auto']
        }
      ],//虚拟设施服务的数据
      FirstImg: [],//存放设施服务中图片的第一张和有几张

      Actionshow: false,//弹窗出现
      WhichOne: 1,//那个弹窗出现
      room_list: null,//用来遍历房型主题
      typeList: [],
      abc: '',
    }
  },
  beforeCreate () {


  },
  created () {
    this.shareWechat()

    this.showadvertising()
    this.url = window.location.href.split('#')[0]
    this.shareWechat()
    // this.dealDate()
    const newArry = this.FacImgList.map(item => {
      return {
        img: item.img[0],
        length: item.img.length,
        title: item.title
      }
    })
    this.FirstImg = newArry
    this.hotelId = this.$route.query.hotelId
    this.getHotelId()
    this.getQR()
  },
  //分享时重新获得hotel_id
  beforeMount () {
    this.hotelId = this.$route.params.hotelId || this.$route.query.hotelId
    // 处理时间
    this.dealDate()

  },

  mounted () {
    this.getCollect()
    this.share()
    window.scrollTo(0, 0);
    if (sessionStorage.getItem('hotelRefresh') == 1) {
      // this.$router.go(0)
      // console.log(123)
      sessionStorage.removeItem("hotelRefresh");
      console.log('滚动高度', window.pageYOffset)
      // console.log('距离顶部高度', this.$refs.obtain.getBoundingClientRect().top)
    }
    // window.addEventListener('scroll', this.handleScroll, true) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    let data = {
      hotel_id: this.hotelId
    }
    this.aesKey = aesKey(data)
    // this.getRoom_theme()

  },

  watch: {
    test () {
      console.log("watch");
    },
    dateArray () {
      this.roomDatePrice = []
      let data = {
        hotel_id: this.hotelId
      }
      this.aesKey = aesKey(data)
      this.getHotelDetail(this.aesKey)
    },
    roomDatePrice () {
      this.roomDatePrice.forEach(item1 => {
        this.roomList.forEach(item2 => {
          if (item1.room_id == item2.room_id) {
            item2.salesCeil_price = Math.ceil(Number(item1.sales_price))
          }
        })
      })
      // console.log(this.roomList, "====roomList")
    },

  },
  methods: {
    getQR () {
      let data = {
        id: this.hotelId
      }
      QRcode(data).then(res => {
        this.ticket = res.data.data.ticket
      })
    },
    QRcode () {
      ImagePreview([
        'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + `${this.ticket}`,

      ]);
      console.log("点击了二维码");
    },
    goEvaluate () {
      this.$router.push({ path: '/allHotel/HotelEvaluate' })

    },
    getHotelId () {
      console.log(this.hotelId, "hotelId=====");
      sessionStorage.setItem('hotelId', this.hotelId)
    },
    showadvertising () {
      let advertising = sessionStorage.getItem('advertising')
      if (this.is_showList == '3' && advertising == undefined) {
        this.advertising = true
      } else if (this.is_showList == '3' && advertising != 1) {
        this.advertising = false
      }

    },
    close () {
      this.advertising = false
      sessionStorage.setItem('advertising', 1)
    },
    shareWechat () {
      let data = {
        url: this.url,
        apis: ['openLocation']
      }
      // console.log(data.url, "======url=======");
      this.aesKey = aesKey(data)
      getJSSDK(this.aesKey).then(res => {
        wx.config({
          debug: false,
          appId: res.data.data.appId,
          timestamp: res.data.data.timestamp,
          nonceStr: res.data.data.nonceStr,
          signature: res.data.data.signature,
          jsApiList: ['openLocation', 'updateAppMessageShareData']
        })
      })
    },

    HotelAddress () {

      console.log("点击了酒店位置");
      let longitude = this.lng
      let latitude = this.lat
      console.log(longitude, latitude);
      // let bd09togcj02 = coordtransform.bd09togcj02(longitude, latitude)
      let name = this.hotelInfo.name
      let address = this.hotelInfo.describe
      console.log(longitude, latitude, name, address);
      // console.log(name, address, "====酒店详情====name,address");
      // latitude = bd09togcj02[1]
      // longitude = bd09togcj02[0]
      wx.ready(function () {
        wx.openLocation({
          latitude: latitude,
          longitude: longitude,
          scale: 15,
          name: name,
          address: address, // 地址详情说明
          success: function (res) {
            console.log(res, "====");
          },
        });
      })
    },
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    },
    //接口获取数据
    //根据酒店id获取酒店详情数据
    getHotelDetail (data) {
      getHotelDetail(data).then(res => {
        let lng = res.data.data.hotel_info.lng_lat[0]
        this.lng = lng
        this.lng = Number(this.lng)
        this.lat = Number(res.data.data.hotel_info.lng_lat[1])
        this.city_name = res.data.data.hotel_info.city_name
        // console.log(this.lng, this.lat, this.city_name, "-=========");
        // console.log("酒店详情", res.data);
        sessionStorage.setItem('hotelDetail', JSON.stringify(res.data.data.hotel_info))
        // this.room_list = res.data.data.room_list
        // let test = res.data.data.room_list

        this.room_list = res.data.data.room_list
        // console.log(this.room_list);
        let room_list = this.room_list
        localStorage.setItem('roomList', JSON.stringify(room_list))
        // sessionStorage.setItem('roomList', JSON.stringify(this.room_list))
        let test = res.data.data.room_list
        // // let test_roomList = JSON.stringify(this.room_list)
        // // sessionStorage.setItem('roomList', test_roomList)
        // // sessionStorage.setItem('roomList', test_roomList)
        // console.log(test);
        test.map(item => {
          // console.log(item, "======");
          if (item.room_theme == 12) {
            this.is_showList = '3'
            this.showadvertising()
            let esports = "电竞房"
            sessionStorage.setItem('esports', esports)
            // sessionStorage.setItem('esports', item.room_tags)
          } else if (this.is_showList != '3') {
            sessionStorage.removeItem('esports')
            // console.log("删除了电竞房");
          }
        })
        let typeRoom = []
        test.map(item => {
          typeRoom.push(item.room_tags[0])
        })
        typeRoom = Array.from(new Set(typeRoom))
        let newArr = []
        for (let i = 0; i < typeRoom.length; i++) {
          let thisArr = { text: typeRoom[i], isclick: false }
          newArr.push(thisArr)
        }
        this.typeData = newArr
        // console.log(this.typeData);
        // console.log(typeRoom, "typeRoom");
        // let obj = {
        //   text: null,
        //   isclick: false
        // }
        // typeRoom.forEach(item => {

        //   // console.log(item);
        // })
        let arr = res.data.data

        arr.room_list.forEach(async item => {
          // item.salesCeil_price = Math.ceil(Number(item.sales_price))
          // console.log(item, "价格");
          let data = {
            room_id: item.room_id,
            s_time: this.ArrivalDateStamp,
            e_time: this.ArrivalDateStamp
          }
          console.log(data);
          this.aesKey = aesKey(data)

          await getRoomDatePrice(this.aesKey).then(res => {
            item.datePrice = res.data.data[0].sales_price
            item.sales_price = res.data.data[0].sales_price
          })

        })

        this.hotelInfo = arr.hotel_info

        this.roomList = arr.room_list
        console.log(arr.room_list);
        // this.roomList = JSON.parse(sessionStorage.getItem("roomData"));
        // console.log(JSON.parse(sessionStorage.getItem("roomData")));
        // console.log(this.roomList);
        this.roomList.sort((a, b) => { return a.salesCeil_price - b.salesCeil_price })

      })
    },
    //酒店房型根据日期获取价格
    getRoomDatePrice (data) {
      // console.log(data);
      getRoomDatePrice(data).then(res => {
        // console.log(res, "日期价");
        res.data.data.forEach(item => {
          if (item.date == this.dateArray[0] / 1000) {
            this.roomDatePrice.push(item)
            console.log(this.roomDatePrice, " this.roomDatePrice");
          }
        })
      })

    },
    //按钮返回
    onClickLeft () {
      this.$router.go(-1)
    },
    //移动端右滑返回
    onSwipeRight () {
      // this.$router.push({ path: '/Home' })
      // console.log(123)
    },
    //滚动条
    //滚动条滚动时有动画效果
    scrollAnimation (currentY, targetY) {
      //currentY当前位置     targetY目标位置
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY
      let _currentY = currentY
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10)
        _currentY += dist
        window.scrollTo(_currentY, currentY)
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY)
        } else {
          window.scrollTo(_currentY, targetY)
        }
      }, 1)
    },
    // 获取滚动条的滚动距离
    // handleScroll () {
    //   let opacity
    //   let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
    //   if (scrollTop > 110) {
    //     this.is_Bar = true
    //     opacity = scrollTop / 300
    //     document.querySelector('#topNav').style.opacity = opacity
    //     let PolicyTop = document.querySelector('.Policy').offsetTop - 20
    //     let FacTop = document.querySelector('.Facilities').offsetTop - 20
    //     let TraEteTop = document.querySelector('.TraEte').offsetTop - 20
    //     if (scrollTop >= PolicyTop && scrollTop <= FacTop) {
    //       this.topTitle.forEach(item => {
    //         item.isclick = false
    //       })
    //       this.topTitle[1].isclick = true
    //     } else if (scrollTop >= FacTop && scrollTop <= TraEteTop) {
    //       this.topTitle.forEach(item => {
    //         item.isclick = false
    //       })
    //       this.topTitle[2].isclick = true
    //     } else {
    //       this.topTitle.forEach(item => {
    //         item.isclick = false
    //       })
    //       this.topTitle[0].isclick = true
    //     }
    //   } else {
    //     this.is_Bar = false

    //   }

    // },


    //日期
    //计算日期差处理， 返回时间戳
    dateBetweenDeal (startDate, endDate) {
      let st = new Date(startDate).getTime();
      let et = new Date(endDate).getTime();
      let retArr = [];
      const oneDay = 1000 * 60 * 60 * 24

      while (st != et) {
        retArr.push(st);
        st = st + oneDay
      }
      retArr.push(et);

      return retArr
    },
    //点击日期
    changeDate () {
      this.calendarShow = true
    },
    //日历上的显示
    formatter (day) {
      if (day.type === 'start') {
        day.bottomInfo = '入住';
      } else if (day.type === 'end') {
        day.bottomInfo = '退房';
      }
      return day;
    },
    //日历确认
    onConfirm (date) {
      // const [start, end] = date;
      this.calendarShow = false;
      this.dateArray = this.dateBetweenDeal(new Date(date[0]).getTime(), new Date(date[1]).getTime())
      sessionStorage.setItem('dateArray', this.dateArray)
      this.ArrivalDateStamp = Number(this.dateArray[0]) / 1000
      this.LeavelDateStamp = Number(this.dateArray[this.dateArray.length - 1]) / 1000
      this.ArrivalDate = new Date(Number(this.dateArray[0])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[0])).getDate() + '日'
      this.LeavelDate = new Date(Number(this.dateArray[this.dateArray.length - 1])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[this.dateArray.length - 1])).getDate() + '日'
      this.dateLength = this.dateArray.length - 1
    },
    //设施/详情
    goHotel () {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let goHotel = document.querySelector('.sellingPoint').offsetTop

      this.scrollAnimation(scrollTop, goHotel)
    },
    //顶部导航点击
    changeScorll (item, index) {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let PolicyTop = document.querySelector('.Policy').offsetTop
      let FacTop = document.querySelector('.Facilities').offsetTop
      let TraEteTop = document.querySelector('.TraEte').offsetTop
      if (index == 0) {
        this.scrollAnimation(scrollTop, 0)
      } else if (index == 1) {
        this.scrollAnimation(scrollTop, PolicyTop)
      } else if (index == 2) {
        this.scrollAnimation(scrollTop, FacTop)
      } else if (index == 3) {
        this.scrollAnimation(scrollTop, TraEteTop)
      }
      this.topTitle.forEach(item2 => {
        item2.isclick = false
      })
      item.isclick = true
    },
    //底部预定房间按钮
    returnHome () {
      let homeListTop = document.querySelector('.DetailList').offsetTop
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.scrollAnimation(scrollTop, homeListTop)
    },

    //处理日期数据
    dealDate () {
      this.dateArray = sessionStorage.getItem('dateArray')

      this.dateArray = this.dateArray.split(",")
      this.dateLength = this.dateArray.length - 1
      this.ArrivalDateStamp = Number(this.dateArray[0]) / 1000
      this.LeavelDateStamp = Number(this.dateArray[this.dateArray.length - 1]) / 1000
      this.ArrivalDate = new Date(Number(this.dateArray[0])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[0])).getDate() + '日'
      this.LeavelDate = new Date(Number(this.dateArray[this.dateArray.length - 1])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[this.dateArray.length - 1])).getDate() + '日'
    },
    //类型
    getType (item, index) {
      // console.log(this.typeData);
      let type_room = []
      if (item.isclick == false) {
        this.roomList = JSON.parse(localStorage.getItem('roomList'))
        this.roomList.forEach(item => {
          item.salesCeil_price = item.sales_price
        })
        // console.log(this.roomList);
        // console.log(this.roomList[index].sales_price);
        // console.log("点击");
        for (let i = 0; i < this.typeData.length; i++) {
          this.typeData.isclick = false
        }
        this.typeData.filter(item => {
          if (item.isclick == true) {
            item.isclick = false
          }
          this.typeData[index].isclick = true

        })
        for (let i = 0; i < this.roomList.length; i++) {
          if (this.roomList[i].room_tags[0] == item.text) {
            type_room.push(this.roomList[i])
          }
        }
        this.roomList = type_room
      } else if (item.isclick == true) {
        this.roomList = JSON.parse(localStorage.getItem('roomList'))
        this.roomList.forEach(item => {
          item.salesCeil_price = item.sales_price
        })
        // console.log("取消");
        item.isclick = false
      }

      // let type_room = []
      // if (item.isclick == false) {
      //   this.typeList = this.typeList.concat(item.text)
      //   // console.log(typeList);
      //   for (let i = 0; i < this.roomList.length; i++) {
      //     console.log();
      //     if (this.roomList[i].room_tags[0] == item.text) {
      //       console.log("123");
      //       type_room.push(this.roomList[i])
      //     }
      //   }
      //   this.roomList = type_room
      //   item.isclick = true
      //   console.log("1111");
      // } else if (item.isclick == true) {

      //   this.typeList.find(data => {
      //     if (item.text == data) {
      //       let index = this.typeList.indexOf(item.text)
      //       this.typeList.splice(index, 1)
      //     }
      //   })
      //   this.roomList = JSON.parse(sessionStorage.getItem('roomList'))
      //   item.isclick = false
      // }
      // console.log(this.typeList);
    },
    getCollect () {
      let data = {
        product_id: this.hotelId,
        type: 2
      }
      getCollect(data).then(res => {
        if (res.data.data.list[0] != undefined) {
          this.Collect_id = res.data.data.list[0].id
          if (res.data.data.list.length > 0) {
            this.star = "star"
          }

        }
        // console.log(res.data.data.list.length);
      })
    },
    collect () {
      if (this.star == "star-o") {
        this.star = "star"
        let data = {
          product_id: this.hotelId,
          type: 2
        }
        postCollect(data).then(res => {
          Notify({ type: 'success', message: '收藏成功' });
          console.log(res);
        })
        this.getCollect()
        // console.log("点击了1");

      }
      //取消收藏
      else if (this.star == "star") {
        this.star = "star-o"
        let data = {
          id: this.Collect_id,
          product_id: this.hotelId,
          type: 2
        }
        delCollect(data).then(res => {
          Notify({ type: 'warning', message: '取消收藏' });

          console.log(res);
        })
      }
    },
    //查看更多按钮
    seeMore (index) {
      if (index == 1) {
        this.ifMore = false
        document.querySelector('.img').style.height = 100 + '%'
      } else if (index == 2) {
        this.ifMore2 = false
        document.querySelector('.contentF').style.height = 100 + '%'
      }
    },
    //收起按钮
    packUp (index) {
      if (index == 1) {
        this.ifMore = true
        document.querySelector('.img').style.height = 200 + 'px'
      } else if (index == 2) {
        this.ifMore2 = true
        document.querySelector('.contentF').style.height = 200 + 'px'
      }
    },
    //预览照片
    getImg (index) {
      ImagePreview({
        images: this.FacImgList[index].img,//this.images为图片资源
        startPosition: 0
      });
    },
    //看酒店房型详情
    getHomeID (index) {
      // console.log(index, "房型详情");
      // console.log(this.ArrivalDate, "日期");
      // console.log(this.roomList, "酒店房型");
      // console.log(this.hotelInfo, "房型数据");
      this.roomIndexData = this.roomList[index]
      this.roomIndexData.deposit = this.hotelInfo.deposit
      this.roomIndexData.entry_departure_time = this.hotelInfo.entry_departure_time
      this.roomIndexData.meal_explanation = this.hotelInfo.meal_explanation
      this.roomIndexData.pets = this.hotelInfo.pets
      this.roomIndexData.unsubscribe = this.hotelInfo.unsubscribe
      this.roomIndexData.add_beds = this.hotelInfo.add_beds
      this.roomIndexData.id = this.hotelInfo.id
      //
      this.roomIndexData.buy_notice = this.hotelInfo.buy_notice
      let roomData = JSON.stringify(this.roomIndexData)
      if (this.ArrivalDate == '') {
        Toast.fail('请选择日期');
        return false
      }
      sessionStorage.setItem('roomData', roomData)
      this.Actionshow = true
    },
    // getRoom_theme () {
    //   setTimeout(() => {
    //     getRoom_theme().then(res => {
    //       let resultArr = []
    //       // console.log(res.data.data);
    //       for (let i = 0; i < res.data.data.length; i++) {
    //         // console.log(res.data.data[i].name, "获取的房型主题");
    //         for (let j = 0; j < this.room_list.length; j++) {
    //           // console.log(this.room_list[j].room_tags[0], "该酒店的房型主题");
    //           if (res.data.data[i].name == this.room_list[j].room_tags[0]) {
    //             // console.log(res.data.data[i], "===================");
    //             resultArr.push(res.data.data[i].name);
    //           }
    //         }
    //         // res.data.data[i]
    //         // console.log(res.data.data[i]);
    //       }
    //       console.log(resultArr);
    //     })
    //     // this.room_list.forEach(item => {
    //     //   this.typeData.push(item.room_tags)
    //     // })
    //   }, 500);
    //   // console.log(this.typeData);

    // },
    //跳转到酒店订单页面
    getOrderId (index) {
      this.roomIndexData = this.roomList[index]
      this.roomIndexData.deposit = this.hotelInfo.deposit
      this.roomIndexData.entry_departure_time = this.hotelInfo.entry_departure_time
      this.roomIndexData.meal_explanation = this.hotelInfo.meal_explanation
      this.roomIndexData.pets = this.hotelInfo.pets
      this.roomIndexData.unsubscribe = this.hotelInfo.unsubscribe
      this.roomIndexData.add_beds = this.hotelInfo.add_beds
      this.roomIndexData.buy_notice = this.hotelInfo.buy_notice
      let roomData = JSON.stringify(this.roomIndexData)
      let wechatUser = JSON.parse(localStorage.getItem('wechatUser'));
      // console.log(wechatUser, "wechatUser========================");
      if (wechatUser == null) {
        // console.log("wechatUser空的");
        Toast.fail('未登录,稍后跳转');
        let url = this.$route.path
        // console.log(this.$route.path);
        let arrUrl = this.url.split('?')
        // console.log(arrUrl[1], "hotelId");
        let hotelUrl = url + '?' + arrUrl[1]
        // console.log(hotelUrl, "hotelUrl===");
        sessionStorage.setItem('hotelUrl', hotelUrl)
        setTimeout(() => {
          window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa68cd9e5c0b30448&redirect_uri=https%3A%2F%2Fnewapi.guansj.com%2Fapi%2Fhome%2Fwechat-login-redirect&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
        }, 1500);
        return
      }
      if (this.ArrivalDate == '') {
        Toast.fail('请选择日期');
        return false
      }
      this.Actionshow = false
      sessionStorage.setItem('roomData', roomData)
      sessionStorage.setItem('refresh', 1)

      this.$router.push({ path: '/allHotel/HotelOrder' })
    },
    //去充值页面
    goPay () {
      this.$router.push({ path: '/allPerInfo/balance' })
      sessionStorage.setItem('PayUrl', true)
      sessionStorage.setItem('advertising', 1)
    },
    //房型弹窗的操作事件
    clickOverlay () {
      this.Actionshow = false
    },
    // 分享
    share () {
      let imgUrl = require('../../../assets/HomeIcon/guanLOGO.png')
      wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: '酒店房型测试', // 分享标题
          desc: '酒店房型描述测试', // 分享描述
          link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: imgUrl, // 分享图标
          success: function () {
            // console.log("分享设置成功");
            // 设置成功
          }
        })
      });
    }

  }
}
</script>

<style lang="less" scoped>
#QRcode {
  position: absolute;
  top: 1.5%;
  z-index: 1000;
  font-size: 0.5rem;
  color: #000;
  left: 80%;
}
body {
  width: 100%;
}
.icon {
  z-index: 1000;
  position: absolute;
  top: 2%;
  left: 90%;
  color: red;
}
.bm-view {
  width: 100%;
  height: 300px;
}
#HotelDatail {
  font-size: 0.36rem;
  .header {
    width: 100%;
    height: 100%;
    position: relative;
    // background-color: aqua;
    .headerImg {
      width: 100%;
      height: 4rem;
      position: relative;
      .transparentW {
        width: 100%;
        height: 0.7rem;
        position: absolute;
        bottom: 0%;
        z-index: 10;
        background-image: linear-gradient(
          to top,
          white 0%,
          white 20%,
          rgba(0, 0, 0, 0.2) 100%,
          #ffffff 100%
        );
      }
      img {
        width: 100%;
        height: 4rem;
      }
    }
    .InfoShow {
      text-align: left;
      position: relative;
      top: 1.6rem;
      // width: 88%;
      // margin-left: 0.25rem;
      border-radius: 10px;
      z-index: 20;
      margin-top: -1.8519rem;
      background-color: white;
      box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.035),
        -10px 0px 10px 0px rgba(0, 0, 0, 0.035),
        10px 0px 10px 0px rgba(0, 0, 0, 0.035),
        0px 10px 10px 0px rgba(0, 0, 0, 0.035);
      padding-top: 0.2rem;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      padding-bottom: 0.3rem;
      .InfoShow_bottom {
        overflow: hidden;
        width: 100%;
        .evaluate_hotel {
          width: 25%;
          height: 1.4815rem;
          position: relative;
          display: inline-block;
          text-align: center;
          float: left;
          background-color: #00e8c8;
          // background: linear-gradient(136.2deg, #a02176 3.15%, #4532e2 98.6%);
          margin-top: 0.1852rem;
          border-radius: 12px;
          .evaluate_lv {
            font-size: 30px;
            margin-top: 0.23rem;
            display: inline-block;
            color: white;
          }
          .evaluate_grade {
            font-size: 14px;
            display: inline-block;
            color: white;
            font-weight: 400;
          }
          .evaluate_total {
            display: flex;
            font-size: 12px;
            color: white;
          }
          .iconfont {
            display: flex;
          }

          .evaluate_1 {
            position: absolute;
            top: 20%;
            left: 50%;
            font-size: 14px;
          }
          .evaluate_2 {
            // padding: 5px;
            position: absolute;
            top: 50%;
            font-size: 12px;
            color: #b2b4b8;
          }
        }
        .evaluate_sprots {
          width: 1.7593rem;
          height: 1.4815rem;
          position: relative;
          display: inline-block;
          box-shadow: 0px 2px 2px 0px #979797;
          float: left;
          // background-color: #f5f8ff;
          background: linear-gradient(136.2deg, #a02176 3.15%, #4532e2 98.6%);
          margin-top: 0.1852rem;
          border-radius: 12px;
          text-align: center;
          .evaluate_lv {
            font-size: 30px;
            margin-top: 0.23rem;
            display: inline-block;
            color: white;
          }
          .evaluate_grade {
            font-size: 14px;
            display: inline-block;
            color: white;
            font-weight: 400;
          }
          .evaluate_total {
            display: flex;
            margin-left: 20px;
            font-size: 12px;
            color: white;
          }
          .iconfont {
            display: flex;
          }
        }
        .address {
          width: 73%;
          position: relative;
          float: right;
          .address_info {
            margin-left: 0.3704rem;
            padding: 0 10 10 20;
            margin-top: 0.35rem;
            // padding 超过换行后不生效
            // 必须设置文本长度
            width: 70%;
            height: 1.5185rem;
            // line-height: 1.5185rem;
            overflow: hidden;
            // text-overflow: -o-ellipsis-lastline;
            word-break: break-all;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 2; //行数
            -webkit-box-orient: vertical; //盒子中内容竖直排列
            // line-clamp: 2;

            // display: inline-block;
          }
          .address_position {
            padding-left: 0.3704rem;
            left: 75%;
            top: 15%;
            position: absolute;
            width: 10%;
            // margin-left: 5px;
          }

          // float: right;
          display: inline-block;
          height: 1.5185rem;
          margin-top: 0.1852rem;
          background-color: #f5f8ff;
          border-radius: 10px;
        }
      }
      .van-tag--medium {
        font-size: 0.23rem;
        // padding: 0.03rem 0.2rem;
        border-radius: 3px;
        margin-right: 0.1rem;
      }
      .shortDesc {
        width: 4.5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .bottom {
        font-size: 0.2rem;
        margin-top: 0.1rem;
        position: relative;
        .icon {
          position: absolute;
          right: 0%;
          bottom: 0%;
          img {
            width: 30%;
            margin-left: 0.27rem;
          }
        }
      }
      .tag {
        margin-right: 0.0926rem;
      }
    }
  }
  .DetailList {
    text-align: left;
    margin-top: 2rem;

    /deep/ .date {
      width: 90%;
      margin-left: 0.1852rem;
      border-radius: 10px;
      // background-color: #f2fcfd;
      font-weight: bold;
      padding: 0.1852rem;
      background-color: #f2fcfd;
      // padding-left: 0.35rem;
      span {
        font-size: 14px;
        border-radius: 15px;
        padding: 0.037rem 0.0926rem;
        border-style: solid;
        border-width: 1px;
        margin: 0 0.1852rem;
        border-color: #60e4de;
        color: #60e4de;
      }
      /deep/.van-icon {
        color: #60e4de;
      }
    }
    /deep/ .date_esports {
      width: 90%;
      margin-left: 0.1852rem;
      border-radius: 10px;
      // background-color: #f2fcfd;
      font-weight: bold;
      padding: 0.1852rem;
      background: #f2fcfd;
      // padding-left: 0.35rem;
      span {
        font-size: 14px;
        border-radius: 15px;
        padding: 0.037rem 0.0926rem;
        border-style: solid;
        border-width: 1px;
        margin: 0 0.1852rem;
        border-color: #5b2ec7;
        color: #5b2ec7;
        background-color: #f2fcfd;
      }
      /deep/.van-icon {
        color: #60e4de;
      }
    }
    .type {
      position: relative;
      // text-align: center;
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      margin-top: 0.2rem;
      padding-left: 0.2rem;
      width: 7rem;
      .type_btn {
        font-size: 0.21rem;
        padding: 0.15rem 0.25rem;
        background-color: #f8f8f8;
        margin-left: 0.1rem;
        border-radius: 20px;
        white-space: nowrap;
        display: inline-block;
      }
      .type_btn.is_type_active {
        background-color: #cdf7f2;
        color: #36e0c9;
        // font-weight: bold;
        position: relative;
        .checked {
          position: absolute;
          bottom: -7%;
          right: 0%;
          width: 20%;
        }
        img {
          width: 100%;
        }
      }
    }
    .type_esports {
      position: relative;
      // text-align: center;
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      margin-top: 0.2rem;
      padding-left: 0.2rem;
      width: 7rem;
      .type_btn {
        font-size: 0.21rem;
        padding: 0.15rem 0.25rem;
        background-color: #f8f8f8;
        margin-left: 0.1rem;
        border-radius: 20px;
        white-space: nowrap;
        display: inline-block;
      }
      .type_btn.is_type_active {
        background: #f6f9ff linear-gradient(144deg, #a02176 0%, #4532e2 100%);
        color: white;
        // font-weight: bold;
        position: relative;
        .checked {
          position: absolute;
          bottom: -7%;
          right: 0%;
          width: 20%;
        }
        img {
          width: 100%;
        }
      }
    }
    .homeList {
      background-color: white;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 5%;
    }
    .sellingPoint {
      background-color: white;
      padding-right: 0.3rem;
      padding-top: 0.5rem;
      padding-bottom: 0.3rem;
      // margin-top: -0.25rem;
      padding-left: 0.3rem;
      .img {
        width: 100%;
        height: 3.7037rem;
        overflow: hidden;
        /deep/ img {
          width: 100%;
        }
        /deep/div {
          width: 6.5rem;
          p {
            width: 110%;
          }
        }
      }
      .btn {
        width: 100%;
        text-align: center;
        margin-top: 0.2rem;
        font-size: 0.2rem;
        /deep/.van-button--normal {
          height: 0.6667rem;
          padding: 0.03rem 1rem;
          border-radius: 4px;
        }
      }
    }
    .ReserImport {
      padding: 0.2rem 0rem;
      background-color: white;
      font-size: 0.3rem;
      margin-top: 0.14rem;
      padding-bottom: 0.2rem;
      img {
        width: 4%;
        position: absolute;
        top: 15%;
      }
      span {
        margin-left: 0.35rem;
        font-weight: bold;
      }
      /deep/p {
        font-size: 0.28rem;
        padding-right: 0.3rem;
      }
    }
    .Policy {
      margin-top: 0.14rem;
      background-color: white;
      padding: 0.2rem 0;
      .content {
        .title {
          margin-top: 0.2rem;
          position: relative;
          img {
            width: 4%;
            position: absolute;
            bottom: 0%;
          }
          span {
            font-size: 0.25rem;
            font-weight: bold;
            margin-left: 0.4rem;
          }
        }
        .cont {
          width: 6.5rem;
          padding-left: 0.4rem;
          font-size: 0.24rem;
        }
        .table {
          font-size: 0.24rem;
          color: gray;
          margin-top: 0.2rem;
          th {
            background-color: #f7f7f7;
            padding: 0.15rem 0.4rem;
            border: none;
            text-align: center;
          }
          td {
            text-align: center;
            border: 1px solid #f7f7f7;
            padding: 0.2rem 0.3rem;
          }
        }
        .meal {
          .cont {
            color: black;
            font-size: 0.24rem;
            div {
              margin-top: 0.2rem;
              .right {
                margin-left: 0.2rem;
              }
            }
          }
        }
      }
    }
    .Facilities {
      margin-top: 0.14rem;
      background-color: white;
      padding: 0.2rem 0;
      .title {
        font-size: 0.3rem;
        font-weight: bold;
        margin-left: 0.3rem;
      }
      .imageList {
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        margin-top: 0.2rem;
        padding-left: 0.2rem;
        width: 7rem;
        span {
          position: relative;
          div {
            position: absolute;
            font-size: 0.1rem;
            bottom: 45%;
            left: 4%;
            color: white;
            opacity: 0.5;
            padding: 0.05rem 0.11rem;
            border-radius: 20px;
            background-color: #1f1f20;
          }
          img {
            width: 40%;
            margin-right: 0.1rem;
            height: 2rem;
          }
        }
      }
      .contentF {
        overflow: hidden;
        height: 3.7037rem;
        .title {
          font-size: 0.25rem;
          position: relative;
          margin-top: 0.2rem;
          span {
            margin-left: 0.4rem;
          }
          img {
            position: absolute;
          }
        }
        .cont {
          margin-left: 0.7rem;
          color: gray;
          font-size: 0.22rem;
          .van-col--6 {
            width: 33.3%;
            margin-top: 0.1rem;
          }
        }
      }
      .btn {
        width: 100%;
        text-align: center;
        margin-top: 0.2rem;
        font-size: 0.2rem;
        /deep/.van-button--normal {
          height: 0.6667rem;
          padding: 0.03rem 1rem;
          border-radius: 4px;
        }
      }
    }
    .TraEte {
      margin-top: 0.14rem;
      background-color: white;
      padding: 0.2rem 0;
      .title {
        font-size: 0.3rem;
        font-weight: bold;
        margin-left: 0.3rem;
      }
      .location {
        margin-top: 0.2rem;
        margin-left: 0.3rem;
        img {
          width: 95%;
        }
      }

      .info {
        width: 80%;
        margin-top: 0.4rem;
        margin-left: -0.1rem;
        /deep/.van-tab__text--ellipsis {
          font-size: 0.24rem;
        }
        /deep/.van-tabs__nav--line {
          padding-left: 0.3rem;
        }
        /deep/.van-tabs__content {
          padding-left: 0.35rem;
        }
        /deep/.van-tab--active {
          color: #39cbc5;
        }
        /deep/.van-tabs__line {
          background-color: #39cbc5;
          bottom: 0.4259rem;
        }
      }
    }
    .reserv {
      overflow: hidden;
      background-color: white;
      padding: 0.2rem 0;
      div {
        // overflow: hidden;
        background-color: aqua;
        width: 94%;
        .btn {
          float: right;
          padding: 0.2rem 0.4rem;
          color: white;
          font-size: 0.3rem;
          border-radius: 5px;
          background-image: linear-gradient(to right, #fba952, #fe6162);
          margin-bottom: 0.2rem;
        }
        .btn_sports {
          float: right;
          padding: 0.2rem 0.4rem;
          color: white;
          font-size: 0.3rem;
          border-radius: 5px;
          background-image: linear-gradient(to right, #a02176, #4532e2);
          margin-bottom: 0.2rem;
        }
      }
    }
  }
  .van-tag--medium {
    padding: 0.02rem 0.03rem;
  }
  #topNav {
    background-color: white;
    width: 100%;
    font-size: 0.25rem;
    position: fixed;
    z-index: 200;
    top: 0%;
    opacity: 0;
    /deep/.van-col {
      span {
        display: inline-block;
        padding-bottom: 0.1rem;
      }
    }
    .is_scroll_active {
      span {
        color: #36e0c9;
        border-bottom: 0.037rem solid #36e0c9;
      }
    }
  }
}
.advertising {
  /deep/.van-popup {
    background-color: #fff0 !important;
    width: 270px;
    height: 500px;
    .btn {
      float: right;
      width: 35px;
      height: 35px;
    }
    img {
      // background-color: rgba(255, 192, 203, 0.2);
      width: 100%;
    }
  }
}
.esports_hotel {
  /deep/.van-calendar__day--start,
  .van-calendar__day--multiple-middle,
  .van-calendar__day--multiple-selected,
  .van-calendar__day--end {
    background: #f6f9ff linear-gradient(144deg, #a02176 0%, #4532e2 100%) !important;
  }
  /deep/.van-calendar__day--end,
  .van-calendar__day--multiple-middle,
  .van-calendar__day--multiple-selected,
  .van-calendar__day--end {
    background: #f6f9ff linear-gradient(35deg, #4532e2 0%, #a02176 100%) !important;
  }
  /depp/.van-calendar__footer .van-button {
    background: linear-gradient(35deg, #a02176 0%, #4532e2 100%) !important;

    .van-button--danger
      .van-button--normal
      .van-button--block
      .van-button--round
      .van-calendar__confirm {
      background: #f6f9ff linear-gradient(35deg, #a02176 0%, #4532e2 100%) !important;
    }
  }
  //日历按钮
  /deep/ .van-calendar__footer {
    .van-button {
      background: linear-gradient(35deg, #a02176 0%, #4532e2 100%) !important;
      border-color: white !important;
    }
  }
}
</style>