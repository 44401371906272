// dplayer.vue
 
<template>
  <div>
    <div id="dplayer"
         :style="dplayerData.style"></div>
  </div>
</template>
 
<script>
import DPlayer from 'dplayer'
export default {
  name: 'DPlayer',
  props: {
    dplayerData: Object,
  },
  data () {
    return {}
  },
  mounted () {

    this.dp = new DPlayer({
      container: document.getElementById('dplayer'),
      theme: '#b7daff', // 风格颜色，例如播放条，音量条的颜色
      loop: true, // 是否自动循环,
      lang: 'zh-cn', // 语言，'en', 'zh-cn', 'zh-tw'
      hotkey: false, // 是否支持热键，调节音量，播放，暂停等
      preload: 'auto', // 自动预加载
      mutex: true,
      volume: 0,
      autoplay: true,
      video: {
        url: 'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202305/11/video.mp4', // 播放视频的路径
        defaultQuality: 0, // 默认是HD
        pic: '', // 视频封面图片
      },
    })
    setTimeout(() => {

      this.dp.play()

    }, 3000)
  },
  created () {

  },
  methods: {
    // 切换视频源事件
    switchVideo (item) {
      console.log(item);
      this.dp.switchVideo({
        url: item.video,
        pic: item.imgUrl,
      })

    },
    // 暂停事件
    pause () {
      console.log("点击了暂停");
      this.dp.pause()
    },
  },
}
</script>
 
<style lang="less" scoped></style>