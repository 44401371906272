<template>
  <div id="shopOrderDetail"
       :style="{height:innerHeight}">
    <!-- <NavBar NavBarTitle='订单详情'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div v-for="item in goodsData"
         :key="item.id">
      <div class="headerStatus">
        <div class="staText"
             v-if="item.status==0">
          等待买家付款
          <div class="time">
            剩
            <van-count-down :time="time"
                            @finish='finishConutDown'
                            format="HH 小时 mm 分 ss 秒" />自动关闭
          </div>
        </div>
        <div class="staText"
             v-if="item.status==6">
          卖家处理中
          <div class="time">
            24小时内发货，请耐心等待
          </div>
        </div>
        <div class="staText"
             v-if="item.status==1">
          完成付款
          <div class="time">
            等待发货
          </div>
        </div>
        <div class="staText"
             v-if="item.status==-1">
          交易已取消
          <div class="time">
            欢迎下次购买
          </div>
        </div>
      </div>
      <div class="addressInfo">
        <div class="text">
          <div>
            {{item.real_name}}<span>{{item.mobile}}</span>
          </div>
          <div class="address">{{item.address}}</div>
        </div>
        <van-icon name="location"
                  color="#00ECAF" />
      </div>
      <div class="shopCard">
        <div class="supplier">{{item.supplied}}</div>
        <van-card v-for="goods in item.shop_order_detail[0].shop_order_goods"
                  :key='goods.id'
                  :price="goods.total_price"
                  :desc="goods.goods_sku_name"
                  :title="goods.goods_name "
                  :thumb="goods.goods_image">
          <template #footer
                    class="numAdd">
            <span class="num">x{{goods.quantity}}</span>
          </template>
        </van-card>
        <div class="under">
          <div class="underInfo">
            商品总价
            <a>￥{{item.total_price}}</a>
          </div>
          <div class="underInfo">
            运费
            <a>￥0.00</a>
          </div>
          <div class="underInfo">
            积分折扣
            <a>-￥10.00</a>
          </div>
          <div class="underInfo">
            实付款
            <span>￥{{item.total_price}}</span>
          </div>
          <div class="AfterSales">
            <div>售后服务</div>
            <div class="btn">
              <van-button>确认收货</van-button>
              <van-button @click="refund(item)">退换</van-button>
            </div>
          </div>
        </div>

      </div>
      <div class="orderInfo">
        <div class="title">订单信息</div>
        <div class="info">
          订单编号:<span>{{item.no}}</span>
          <a>复制</a>
        </div>
        <div class="info">
          创建时间:<span>{{item.create_time}}</span>
        </div>
        <div class="btn">
          <van-row>
            <van-col span="12">
              <van-icon name="comment-o" />
              <span>联系卖家</span>
            </van-col>
            <van-col span="12">
              <van-icon name="phone-o" />
              <span>拨打电话</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="operation"
           v-if="item.status==0">
        &nbsp;
        <div class="btn"
             v-if="item.status==0">
          <van-button plain
                      round
                      @click="cancelOrder(item)">取消订单</van-button>
          <van-button plain
                      color="#FE6529"
                      round
                      @click="payOrder(item)">付款</van-button>
        </div>
        <div class="btn"
             v-if="item.status==1">
          <van-button plain
                      round>退换</van-button>
          <van-button plain
                      color="#FE6529"
                      round
                      @click="payOrder">确认收货</van-button>
        </div>
        <div class="btn"
             v-if="item.status==2">
          <van-button plain
                      round>申请售后</van-button>
          <van-button plain
                      color="#FE6529"
                      round
                      @click="payOrder">评价</van-button>
        </div>
        <div class="btn"
             v-if="item.status==3">
          <van-button plain
                      round>删除订单</van-button>
          <van-button plain
                      round
                      @click="payOrder">退款成功</van-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { payGoodsOrder, cancelGoodsOrder, ShopOrderdetail, ShopOrderRefund, checkPayStatus } from '../../../api/shop.js' //eslint-disable-line no-unused-vars


import wx from 'weixin-js-sdk'
import { PAYJSSDK, getJSSDK } from '../../../api/JSSDk.js';
import { Toast } from 'vant';
import { aesKey } from "../../../commom/aesKey"
// import NavBar from '@/components/NavBar'
export default {
  components: {
    // NavBar
  },
  data () {
    return {
      address: null,
      innerHeight: 0,
      time: 24 * 60 * 60 * 1000,//等待买家付款的倒计时
      goodsData: [],//商品数据
      aesKey: null,
    }
  },
  created () {
    this.goodsData = this.$route.query.goodsInfo
    this.shopOrderdetail()
  },
  mounted () {
    //没有接口，数据展示，等接入数据后可删
    // this.goodsData[0].real_name = this.address.real_name
    console.log(this.goodsData[0], " this.goodsData");
    console.log(this.address, " this.address");
    if (this.$route.query.goodsInfo == undefined) {
      this.goodsData = [
        {
          supplied: '观视界自营',
          goodsName: 'sguai小水怪智能保温杯女士高颜值316不锈钢温度显示彩屏水杯子M2',
          price: 178.80,
          img: 'https://g-search1.alicdn.com/img/bao/uploaded/i4/i4/3919667498/O1CN01Wu83o825G8Q14i6oG_!!0-item_pic.jpg_460x460Q90.jpg_.webp',
          desc: '颜色分类: 银色   容量: 300ml',
          num: 1,
          status: 0
        },
      ]
    } else {
      if (this.goodsData.length == 1) {
        // console.log(this.goodsData)
      } else {
        this.goodsData = [
          {
            supplied: '观视界自营',
            goodsName: 'sguai小水怪智能保温杯女士高颜值316不锈钢温度显示彩屏水杯子M2',
            price: 178.80,
            img: 'https://g-search1.alicdn.com/img/bao/uploaded/i4/i4/3919667498/O1CN01Wu83o825G8Q14i6oG_!!0-item_pic.jpg_460x460Q90.jpg_.webp',
            desc: '颜色分类: 银色   容量: 300ml',
            num: 1,
            status: 0
          },
        ]
      }
    }

    this.innerHeight = window.innerHeight + 'px'
  },
  watch: {
  },
  methods: {
    refund (item) {
      console.log(item);
      this.$router.push({ path: '/allShop/shopAfterSale', query: { goodsInfo: [item] } })

      // let data2 = {
      //   product_order_no: this.goodsData[0].no
      // }
      // checkPayStatus(data2)
      // console.log(this.goodsData, "goodsData");

      // let data = {
      //   quantity: this.goodsData[0].shop_order_detail[0].shop_order_goods[0].quantity,
      //   // images: this.goodsData[0].shop_order_detail[0].shop_order_goods[0].goods_image,  
      //   shop_order_goods_id: this.goodsData[0].shop_order_detail[0].shop_order_goods[0].id,
      //   id: this.goodsData[0].id
      // }
      // console.log(data);
      // this.aesKey = aesKey(data)
      // ShopOrderRefund(this.aesKey)
    },
    async shopOrderdetail () {
      console.log(this.goodsData, "goodsDatagoodsDatagoodsDatagoodsData");
      let data = {
        id: this.goodsData[0].id
      }
      await ShopOrderdetail(data).then(res => {
        console.log(res, "shopOrderdetail");
        this.address = res.data.data
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    //倒计时结束
    finishConutDown () {
      // console.log('我是靓仔')
    },
    //取消订单
    async cancelOrder (data) {
      const DATA = {
        order_no: data.no
      }
      this.aesKey = aesKey(DATA)
      let res = await cancelGoodsOrder(this.aesKey)
      // console.log(res)
      if (res.data.code == 200) {
        Toast.success(res.data.message);
        this.$router.push({ path: '/allShop/shopMyOrder' })
      } else {
        Toast.fail(res.data.message);
      }
      // console.log(data)
    },
    //支付订单
    async payOrder (data) {
      const DATA = {
        order_no: data.no
      }
      this.aesKey = aesKey(DATA)
      let res = await payGoodsOrder(this.aesKey)
      // console.log(res)
      if (res.data.code == 200) {
        Toast.success(res.data.message);
        let data = {
          prepay_id: res.data.data.payinfo.prepay_id
        }
        this.aesKey = aesKey(data)
        let res = await PAYJSSDK(this.aesKey)
        this.$router.push({ path: '/allShop/shopMyOrder' })
        if (res.data.code == 200) {
          // this.res2 = res2
          Toast.fail('支付中');
          // this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
          // this.onBridgeReady(this.res2.data.data.payinfo)
          wx.chooseWXPay({
            timestamp: res.data.data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: res.data.data.nonceStr, // 支付签名随机串，不长于 32 位
            package: res.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: res.data.data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: res.data.data.paySign, // 支付签名
            success: function (res) {
              // 支付成功后的回调函数
              console.log(res);
            }
          });
          this.$router.push({ path: '/allShop/shopMyOrder' })
          // 
        }
      } else {
        Toast.fail(res.data.message);
      }
      // console.log(data)
    },
    shareWechat () {
      this.url = window.location.href.split('#')[0]
      let data = {
        url: this.url,
        apis: ['chooseWXPay'],
      }
      console.log(data);
      this.aesKey = aesKey(data)
      getJSSDK(this.aesKey).then(res => {
        this.jsSdk = res.data.data
        console.log(this.jsSdk, "JSSDK");
        wx.config({
          debug: false,
          appId: res.data.data.appId,
          timestamp: res.data.data.timestamp,
          nonceStr: res.data.data.nonceStr,
          signature: res.data.data.signature,
          jsApiList: ['chooseWXPay']
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
#shopOrderDetail {
  text-align: left;
  background-color: #f5f5f5;
  .headerStatus {
    height: 2rem;
    color: white;
    font-size: 0.35rem;
    padding: 0 0.3rem;
    background-image: linear-gradient(to right, #5ee3de, #00ecaf);
    .staText {
      padding-top: 0.5rem;
      .time {
        font-size: 0.23rem;
        padding-top: 0.1rem;
        .van-count-down {
          font-size: 0.23rem;
          color: white;
          display: inline-block;
        }
      }
    }
  }
  .addressInfo {
    font-size: 0.3rem;
    padding: 0.2rem 0.3rem;
    position: relative;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .text {
      margin-left: 0.5rem;
      span {
        margin-left: 0.3rem;
        font-size: 0.25rem;
        color: #999999;
      }
      .address {
        font-size: 0.25rem;
      }
    }
    .van-icon {
      position: absolute;
      font-size: 0.45rem;
      top: 31%;
      left: 3%;
    }
  }
  .shopCard {
    border-radius: 10px;
    background-color: white;
    font-size: 0.35rem;
    margin-top: 0.2rem;
    .supplier {
      padding: 0 0.3rem;
      font-size: 0.28rem;
      padding: 0.2rem 0.3rem;
    }
    .van-card {
      background-color: white;
      border-radius: 10px;
      font-size: 0.2rem;
      padding: 0 0.3rem;
      margin-top: 5px;
      position: relative;
      .van-card__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 4.5rem;
        font-size: 0.3rem;
        height: auto;
        display: inline-block;
        line-height: inherit;
      }
      .van-card__thumb {
        width: 1.5rem;
        height: 1.5rem;
      }
      .van-card__desc {
        display: inline;
        padding: 0.05rem 0.1rem;
        background-color: #f5f5f5;
        color: #999999;
      }
      .van-card__price {
        font-size: 0.3rem;
        font-weight: bold;
        color: #fe6529;
        .van-card__price-currency {
          font-size: 0.2rem;
        }
        .van-card__price-integer {
          font-size: 0.3rem;
        }
      }
      .van-card__footer {
        font-size: 0.3rem;
        position: absolute;
        right: 5%;
        bottom: 0%;
        color: #fe6529;
      }
    }
    .under {
      padding: 0.1rem 0.3rem;
      color: #999999;
      .underInfo {
        font-size: 0.27rem;
        padding: 0.15rem 0;
        a {
          float: right;
          color: #999999;
        }
        span {
          float: right;
          color: #fe6529;
          font-size: 0.3rem;
        }
      }
      .AfterSales {
        font-size: 0.27rem;
        .btn {
          display: flex;
        }
        .van-button {
          font-size: 12px;
        }
      }
      .AfterSales {
        .btn {
          display: flex;
          justify-content: flex-end;
          .van-button {
            border-radius: 20px;
            margin-right: 20px;
          }
          .van-button__content {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
  .orderInfo {
    border-radius: 10px;
    background-color: white;
    padding: 0.2rem 0.3rem;
    font-size: 0.3rem;
    margin-top: 0.2rem;
    .title {
      margin-bottom: 0.2rem;
    }
    .info {
      font-size: 0.27rem;
      padding: 0.1rem 0;
      span {
        margin-left: 0.6rem;
      }
      a {
        float: right;
        color: #fe6529;
      }
    }
    .btn {
      text-align: center;
      font-size: 0.27rem;
      padding-top: 0.2rem;
      padding-bottom: 0.1rem;
      position: relative;
      span {
        margin-left: 0.45rem;
      }
      .van-icon {
        padding-right: 0.1rem;
        color: #0086f7;
        font-size: 0.33rem;
        line-height: 10px;
        position: absolute;
        top: 45%;
      }
    }
  }
  .operation {
    background-color: white;
    position: fixed;
    bottom: 0%;
    font-size: 0.5rem;
    width: 100%;
    .btn {
      float: right;
      margin-right: 0.1rem;
      padding-bottom: 0.1rem;
      .van-button {
        font-size: 0.25rem;
        width: 2rem;
        height: 0.6rem;
        margin-right: 0.2rem;
      }
    }
  }
}
</style>