<template>
  <div class="container">
    <div class="content"></div>
    <div class="content_black">
      <div class="content_white"
           :class="{content_white:ys1,content_white_right:ys2}">
        <div class="top">
          <div class="top_left">往返</div>
          <div class="top_right">单程</div>
        </div>
        <div class="place">
          <span class="here">乌鲁木齐</span>
          <span class="arrows"><img src="../../assets/tour/arrows.png"
                 alt=""></span>
          <span class="destination">三亚</span>
          <van-divider />
        </div>
        <div class="date">
          <div class="fiirst_Date_top">今天</div>
          <div class="fiirst_Date">7月5日</div>
          <div class="totalDay">共3天</div>
          <div class="Last_Date_top">周三</div>
          <div class="Last_Date">7月8日</div>
          <van-divider />
        </div>
        <div class="Number_people">
          <span>2成人</span>
          <span>0婴儿</span>
          <span>0儿童</span>
          <span>1间</span>
          <van-icon name="arrow" />
        </div>
        <div class="btn">
          <van-button round
                      type="info">机+酒出行</van-button>
        </div>
        <div class="bottom"> <img src="../../assets/Plane_Hotel/content_bottom.png"
               alt=""></div>
      </div>

    </div>
    <div class="content_bottom">
      <span><img src="../../assets/Plane_Hotel/bottom_1.png"
             alt=""></span>
      <span><img src="../../assets/Plane_Hotel/bottom_2.png"
             alt=""></span>
      <span><img src="../../assets/Plane_Hotel/bottom_3.png"
             alt=""></span>
      <span><img src="../../assets/Plane_Hotel/bottom_4.png"
             alt=""></span>
    </div>
    <div class="bottom_order">
      <span>
        <van-divider />
      </span>

      <b>航班助手</b>
      <b>我的助手</b>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.container {
  width: 100%;

  .content {
    width: 100%;
    height: 210px;
    background-image: url("../../assets/Plane_Hotel/民宿banner.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .content_black {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../assets/Plane_Hotel/content_black.png");
    // background: rgba(51, 51, 51, 0.59);
    // border-radius: 32px;
    background-size: 100%;
    width: 380px;
    height: 300px;
    margin-top: -12%;
    .content_white {
      background-image: url("../../assets/Plane_Hotel/content_left.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      width: 400px;
      height: 325px;
      top: -16px;
      left: -9px;
      .top {
        overflow: hidden;
        .top_left {
          font-size: 16px;
          font-weight: 500;
          position: absolute;
          top: 9%;
          overflow: hidden;
          left: 23%;
        }
        .top_right {
          font-size: 16px;
          font-weight: 500;
          position: absolute;
          top: 9%;
          color: white;
          left: 71%;
        }
      }
      .place {
        position: absolute;
        font-size: 20px;
        top: 24%;
        left: 10%;
        .here {
          display: inline-block;
        }
        .arrows {
          margin-left: 52px;
        }
        .destination {
          margin-left: 85px;
          display: inline-block;
          color: #cbcbcb;
          font-weight: 400;
        }
      }
      .date {
        position: absolute;
        font-size: 18px;
        top: 45%;
        left: 15%;
        .fiirst_Date_top {
          position: absolute;
          top: -28%;
          font-size: 12px;
          color: #b3b3b3;
          left: 0%;
        }
        .fiirst_Date {
          display: inline-block;
        }
        .totalDay {
          margin-left: 47px;
          display: inline-block;
          font-size: 12px;
        }
        .Last_Date_top {
          position: absolute;
          font-size: 12px;
          color: #b3b3b3;
          top: -28%;
          right: 11%;
        }
        .Last_Date {
          display: inline-block;
          margin-left: 68px;
        }
      }
      .btn {
        position: absolute;
        top: 63%;
        width: 100%;

        .van-button--round {
          border: none;
          background: linear-gradient(to left, #00ecaf 0%, #5ee3de 100%);
          width: 90%;
          color: rgba(255, 255, 255, 1);
        }
      }
      .Number_people {
        position: absolute;
        font-size: 14px;
        left: 10%;
        bottom: 32%;
        span {
          margin-right: 10px;
        }
      }
      .bottom {
        position: absolute;
        bottom: 0%;
        img {
          width: 60%;
        }
      }
    }
  }
  .content_bottom {
    overflow: hidden;
    width: 96%;
    margin-top: 20px;
    margin-left: 10px;
    height: 175px;
    background-image: url("../../assets/Plane_Hotel/bottom.png");
    background-size: cover;
    background-repeat: no-repeat;
    span {
      margin-left: 7px;
      display: inline-block;
      img {
        margin-top: 50px;
        width: 90px;
      }
    }
  }
  .bottom_order {
    width: 100%;
    margin-top: 10px;
    overflow: hidden;
    display: flex;
    border-block-start: #f9f9f9 solid;
    border-width: 1px;
    .van-divider--hairline {
      margin-bottom: 0;
    }
    b {
      font-size: 16px;
      margin-right: 20px;
      width: 50%;
      top: 20px;
      line-height: 62px;
      font-weight: 400;
      margin-left: 50px;
      color: #545353;
      text-align: center;
    }
  }
}
</style>