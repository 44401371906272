<template>
  <div id="user">
    <div class="theme">
      观视界用户服务协议
    </div>
    <span style="font-size: .15rem;">欢迎访问观视界平台！</span>
    <span class="foreword">本协议缔约方为使用观视界平台的用户（以下亦称“您”）与观视界网络科技有限公司（下称“观视界网络科技有限”）。双方本着高效、便捷的原则，就用户使用观视界服务事项签署
      <div style="color:#3388ff;display: initial;">
        《观视界用户协议》
      </div>
      如下。
      您在使用观视界服务之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。如果您不同意本服务条款的任意内容，或者无法准确理解观视界网络科技有限公司对条款的解释，请不要进行后续操作。如您对协议有任何疑问，可向观视界咨询。
      如果您开始使用本服务则表示您确认接受本
      <div style="color:#3388ff;display: initial;">
        《观视界用户协议》
      </div>
      ，并在协议有效期内接受协议约束；如您不接受本
      <div style="color:#3388ff;display: initial;">
        《观视界用户协议》
      </div>
      ，请不要使用本服务；如果您中止/终止使用本服务的，则您在使用本服务过程中的行为受本 <div style="color:#3388ff;display: initial;">
        《观视界用户协议》
      </div>的约束。
      当您使用观视界的服务功能时，用户确认已详细阅读本协议的每一条款，充分理解本协议的全部内容，具有浏览观视界平台信息、使用观视界服务等相应权利能力和行为能力，并可以对自己的行为承担法律责任，且完全接受本协议全部条款及 <div style="color:#3388ff;display: initial;">
        《观视界用户协议》、
      </div>
      <div style="color:#3388ff;display: initial;">《观视界网络科技有限免责声明》、</div>
      <div style="color:#3388ff;display: initial;">《观视界网络科技有限知识产权声明》、</div>
      <div style="color:#3388ff;display: initial;">《观视界网络科技有限隐私保护声明》</div>等观视界网络科技有限平台规则内容。
      我们会根据有关法律法规的变化、公司经营状况、经营策略调整和软件升级等原因修订本协议。更新后的协议条款公布后即有效代替原来的协议条款，您可随时使用本服务页面查阅最新版协议条款。如果您不同意协议改动内容，请您立即停止使用本软件及服务。如果您继续使用本服务，则视为接受本协议的改动。
      若您是18周岁以下的未成年人，建议您请您的监护人仔细阅读本协议，并在征得您的监护人同意的前提下使用我们的产品和/或服务或向我们提供信息。在您开始使用我们的服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
    </span>
    <div class="main">
      <div class="title">一、协议的相关定义</div>
      <div class="main_body">在本协议及本网站其它相关规定中所使用的下列词语，除另有说明以外，应具有以下含义：
        1、用户：指使用观视界平台提供的各项服务的自然人或法人。
        2、网络商品/服务经营者：指符合本协议所规定的条件，同意遵守包括但不限于 <div style="color:#3388ff;display: initial;">《观视界网络科技有限推广服务合同》</div>、观视界平台规则、本协议在内的观视界网络科技有限各种规则及条款，并通过观视界平台发布招商信息，或提供有关服务的经营者。用户在入驻观视界平台之后成为网络商品/服务经营者，网络商品/服务经营者也应当遵守用户协议的全部内容。
        3、观视界平台：是观视界网络科技有限旗下为用户和网络商品/服务提供者提供招商信息公布、加盟信息交互的互联网信息平台，观视界作为B2B垂直搜索引擎仅提供信息展示服务，具体产品或服务由网络商品/服务经营者提供。
        4、观视界平台规则：包括在所有观视界平台规则频道内已经发布及后续发布的全部规则、解读、流程说明、公告等内容。</div>
    </div>
    <div class="main">
      <div class="title">
        二、观视界平台服务内容
      </div>
      <div class="main_body">
        1、观视界网络科技有限公司通过观视界平台依法为用户提供信息展示服务，用户在完全同意本协议及符合本网站规定的情况下，方有权使用本网站的相关服务。
        2、本网站上的产品或服务信息为网络商品/服务经营者自行提供，在观视界上浏览、咨询产品或服务时，用户应当仔细核对确认产品或服务的具体信息，观视界不对产品或服务做任何明示或暗示的承诺。
        3、如用户与网络商品/服务经营者产生争议时，用户应和网络商品/服务经营者直接进行联系，并针对争议事项进行沟通，根据法律、法规、政策性文件及双方的约定进行解决。
      </div>
    </div>
    <div class="main">
      <div class="title">三、观视界的权利和义务</div>
      <div class="main_body">
        1、为履行本合同，观视界网络科技有限有权在观视界网络科技有限及其关联公司的服务器上保存用户的信息；观视界网络科技有限在法律法规所允许的范围内，有权使用和对外披露网络商品/服务经营者信息，包括但不限于为观视界平台升级、抽样测试、更新的目的，出于保护网民权益提升互联网诚信环境的目的、依据司法和/或行政的要求使用或披露此信息。
        2、观视界网络科技有限有权随时删除含有任何危害国家安全、淫秽色情、虚假、侮辱、诽谤、恐吓或骚扰、侵犯他人知识产权或人身权或其他合法权益等违法或有违社会公序良俗的内容。
        3、观视界网络科技有限保留对“观视界平台”进行业务性调整或产品性调整（包括但不限于收取资质标准、平台规则等）的权利，观视界网络科技有限会对调整后的规则或标准进行公示，如您不同意修改后的规则，应当停止使用观视界平台服务。
      </div>
      <div class="main">
        <div class="title">
          四、用户的权利和义务
        </div>
        <div class="main_body">
          1、用户有权随时对观视界平台的服务提出批评、建议。
          2、用户须对自身账户和/或密码下发生的所有活动（包括但不限于信息披露、发布信息、网上点击同意各类规则协议等）承担责任。用户同意：
          （1）如发现任何人未经授权使用您的账户和/或密码，或发生违反保密规定的任何其他情况，您会立即通知观视界网络科技有限公司；
          （2）用户确保您在每个上网时段结束时，以正确步骤离开网站，观视界网络科技有限公司不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。
          用户理解观视界网络科技有限公司对您的请求采取行动需要合理时间，观视界网络科技有限公司对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。
          4、用户同意，观视界网络科技有限公司拥有通过邮件、短信电话等形式，向在本站注册、使用用户发送服务信息、验证码等告知信息的权利。
          5、用户可能参与的在线沟通是实时发生的，没有经过任何来自本网站的编辑、压缩、或任何其他形式的控制，本网站不能甄别所有用户提供的内容，但本网站保留对本网站上内容的监控权，并对本网站认为具有危害性、易引起争议性的或违反本条下述操作规则的内容保留进行删除的权利。当您使用本网站服务时，您不可以发布以下内容：
          （1）违反国家法律法规禁止性规定的；
          （2）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
          （3）欺诈、虚假、不准确或存在误导性的；
          （4）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；
          （5）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；
          （6）存在可能破坏、篡改、删除、影响观视界平台任何系统正常运行或未经授权秘密获取观视界平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；
          （7）其他违背社会公共利益或公共道德或依据相关观视界规则的规定不适合在本平台上发布的。
        </div>
      </div>
      <div class="main">
        <div class="title">五、项目方的权利和义务</div>
        <div class="main_body">1、项目方应遵守包括本协议及其他入驻观视界平台时需签署的全部规定，采取符合观视界规定的方式入驻观视界平台，享受观视界平台提供的服务。
          2、项目方应当办理工商登记，并在其网站首页或者从事经营活动的主页面醒目位置公开营业执照登载的信息或者其营业执照的电子链接标识。从事的服务如属于法律、行政法规或者国务院决定规定应当取得行政许可的，应当依法取得有关许可。观视界网络科技有限公司有权对项目方的主体资质真实性进行核查，项目方拒绝核查或观视界网络科技有限有理由怀疑其真实合法性的，观视界网络科技有限有权拒绝为其提供服务。
          3、项目方应遵守法律、法规等政策性文件进行合法经营；全面、真实、准确披露商品或服务信息，保证商品或服务的完整性，保障消费者的合法权益；不得采取不正当竞争的手段扰乱市场秩序；项目方应当遵守市场服务原则及双方的约定，及时履行义务（包括但不限于提供企业信息及资质、项目信息及对应的商标等资质信息等义务）；项目方应保证其提供的项目信息、品牌商信息真实、准确、合法。
          4、项目方保证提交的信息真实、准确、及时、详尽和完整并与相关情况一致，没有任何可能引人误解的陈述和虚假信息。客户同意接受观视界网络科技有限公司电话、邮件或上门拜访等方式确认。
          5、项目方应保证对其上传至观视界平台的一切信息（包括但不限于注册信息、账号信息、项目信息、品牌信息等）拥有合法的权利，因此类信息导致的一切投诉、举报、处罚、诉讼等，都将由项目方承担所有的责任，如因此导致观视界网络科技有限公司承担任何责任的，项目方须赔偿观视界网络科技有限公司所遭受的全部损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），无论项目方与观视界网络科技有限公司的合作协议、本协议等是否终止。</div>
      </div>
      <div class="main">
        <div class="title">六、用户信息的保护</div>
        <div class="main_body">
          1、保护用户及项目方的个人信息是观视界网络科技有限公司的一项基本原则，观视界网络科技有限公司将会采取合理的措施保护用户的个人信息。除法律法规规定的情形外，未经用户许可观视界网络科技有限公司不会向第三方公开、透露用户个人信息。在您使用观视界提供的服务时，您同意观视界按照在观视界平台上公布的 <div style="color:#3388ff;display: initial;">《观视界隐私政策》</div>收集、存储、使用、披露和保护您的个人信息。观视界网络科技有限公司对相关信息采用专业加密存储与传输方式，保障用户个人信息的安全。
          2、您在申请本服务过程中，需要填写一些必要的信息，请保持这些信息的真实、准确、合法、有效并注意及时更新，以便观视界网络科技有限公司向您提供及时有效的帮助，或更好地为您提供服务。根据相关法律法规和政策，请您填写真实的身份信息。若您填写的信息不完整或不准确，则可能无法使用本服务或在使用过程中受到限制。
          3、一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如帐号申诉服务）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
          4、观视界网络科技有限公司将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。
          5、未经您的同意，观视界网络科技有限公司不会向观视界网络科技有限公司以外的任何公司、组织和个人披露您的个人信息，但法律法规另有规定的除外。
          6、观视界网络科技有限公司非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用观视界的服务前，应事先取得您家长或法定监护人的书面同意。
          7、您应对通过本服务了解、接收或可接触到的包括但不限于其他用户在内的任何人的个人信息予以充分尊重，您不应以搜集、复制、存储、传播或以其他任何方式使用其他用户的个人信息，否则，由此产生的后果由您自行承担。
        </div>
      </div>
      <div class="main">
        <div class="title">七、知识产权</div>
        <div class="main_body">1、各方承诺各自对其在依据本协议中提供的内容、资料、信息和文件享有完整的知识产权、物权等合法权利，并不会或不将侵犯任何他方合法权利。
          2、除法律另有强制性规定外，未经观视界网络科技有限公司明确的书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本网站的信息内容，否则，观视界网络科技有限公司有权追究其法律责任。
          3、观视界网络科技有限公司在服务中开发以及提供的所有的产品、技术与所有程序的权利均归属观视界网络科技有限公司所有（包括但不限于著作权、商标权、专利权、商业信息等），用户或项目方不因任何合同或协议的签署而获得观视界网络科技有限公司的任何知识产权及相关权利。
          4、未经观视界网络科技有限公司许可，客户不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用，或通过非常规方式（如：恶意干预观视界网络科技有限公司数据）影响观视界网络科技有限公司的正常服务，或者擅自委托其他人使用，不得擅自以软件程序自动获得观视界网络科技有限公司相关数据。
          5、本条款不因本 <div style="color:#3388ff;display: initial;">《观视界用户协议》</div>的无效、解除、提前终止、期满或不具操作性而失效。
          6、观视界网络科技有限公司及其关联企业尊重他人之知识产权。如您发现观视界网站上的信息，侵犯了您的合法权益，您可以依据 <div style="color:#3388ff;display: initial;">《观视界网络科技有限权利保护声明》</div>提供相关资料或将相关资料发送至向观视界网络科技有限公司进行投诉，观视界网络科技有限公司将根据中国法律法规和政府规范性文件采取措施移除相关内容或屏蔽相关链接。</div>
      </div>
      <div class="main">
        <div class="title">
          八、责任限制
        </div>
        <div class="main_body">
          1、鉴于观视界平台提供的服务的性质，观视界平台上的企业、项目信息（包括但不限于公司基本信息、项目基本信息、项目详细信息，包括图片和文字介绍等）均由项目方自行提供并上传，由项目方对其提供并上传的所有信息承担相应法律责任。
          2、观视界平台为信息展示平台，用户在相应信息展示页面填写信息后将直接提供给相应项目方。除非另有明确的书面说明，观视界网络科技有限公司不对本网站的运营及包含在本网站上的信息、内容、材料、产品或服务作任何形式的明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
          3、观视界平台展示信息内容出于传递更多信息之目的，并不意味观视界网络科技有限公司赞同其观点或已经证实其内容的真实性。
          4、对用户使用本网站或与本网站相关的任何内容、服务或其它链接至本网站的站点、内容等观视界网络科技有限公司均不作直接、间接、法定、约定的保证。所导致的任何直接的、间接的、相关的、后果性的声誉上或金钱上的损失观视界网络科技有限公司均不承担任何责任。
          5、观视界平台仅向您提供平台内服务，观视界平台不能控制用户或项目方发布的包括但不限于招商信息等各类信息的真实性、准确性、合法性。观视界网络科技有限公司不能也不会控制各方能否履行协议义务。此外，您应注意到，与外国国民、未成年人或以欺诈手段行事的人进行交易的风险是客观存在的。观视界平台在向用户提供服务前会尽所能检查其信息的合法性，但仍可能不可避免地出现意外情况。对此您应谨慎判断。如您发现存在危害信息和行为，请您及时同观视界进行联系和反馈，观视界将对有害信息和行为及时进行处理。
        </div>
      </div>
      <div class="main">
        <div class="title">
          九、不可抗力
        </div>
        <div class="main_body">
          1、不可抗力是指协议各方不能合理控制、不可预见或即使预见亦无法避免的事件，该事件使任何一方根据本协议履行其全部或部分义务已不可能。该事件包括但不限于政府行为、地震、台风、洪水、火灾或其它天灾、战争或任何其它类似事件。
          2、鉴于互联网之特殊性质，不可抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、病毒侵袭等影响互联网正常运行之情形。
          3、如遭受不可抗力事件，各方有权暂停或终止服务，不视为违约。在不可抗力事件影响结束后，应当继续按本协议履行其义务。
          4、一方违约后发生不可抗力的，不可抗力时间发生前的违约行为，违约方不予免责。
        </div>
      </div>
      <div class="main">
        <div class="title">十、协议的变更</div>
        <div class="main_body">
          观视界可根据国家法律法规变化及维护平台秩序、保护用户权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过法定程序并以本协议第十一条约定的方式通知您。
          如您不同意变更事项，您有权于变更事项确定的生效日前联系观视界反馈意见。如反馈意见得以采纳，观视界将酌情调整变更事项。
          如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用观视界服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用观视界服务，则视为您同意已生效的变更事项。
        </div>
      </div>
      <div class="main">
        <div class="title">
          十一、通知
        </div>
        <div class="main_body">
          1、您在使用或注册观视界平台账号使用观视界服务时，您应该向观视界网络科技有限提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被有效联系的状态。
          2、您在使用或注册观视界平台时生成的用于登陆观视界平台接收站内信、系统消息等即时信息的账户，亦作为您的有效联系方式。
          3、观视界网络科技有限将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。
          4、您有权通过您注册时填写的手机号码或者电子邮箱获取您感兴趣的产品/服务广告信息等商业性信息。
          5、观视界将通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在观视界平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账户发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。
        </div>
      </div>
      <div class="main">
        <div class="title">十二、法律适用及争议解决</div>
        <div class="main_body">1、本协议的订立、执行、解释及争议解决均应适用在中华人民共和国法律（但不包括其冲突法规则）。 如发生本协议部分条款与适用法律相抵触时，则这些条款被视为无效，而其它条款继续有效。
          2、对本条款的理解与解释应依据条款目的和文本原义以及业界通行的理解和惯例进行，标题仅供参考，不应在解释本条款时加以援引。
          3、如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向广东市海淀区有管辖权的人民法院提起诉讼。</div>
      </div>
      <div class="main">
        <div class="title">十三、其他</div>
        <div class="main_body">1、观视界网络科技有限公司尊重用户和消费者的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本协议内容中以黑体、加粗、下划线等方式显著标识的条款，请用户着重阅读。
          2、如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响其余任何条款的有效性和可执行性。
          3、您使用观视界“提交”或签署协议时相应的按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。
          4、观视界平台规则中如对此协议所涉及事项另有详细规定的，依照其规定。
          5、本协议自公布之日起生效。</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
#user {
  .theme {
    font-size: 0.4364rem;
  }
  span {
    display: block;
  }
  .main {
    .title {
      font-size: 0.3rem;
      text-align: left;
    }
    .main_body {
      font-size: 0.3rem;
      text-indent: 0.2rem;
      text-align: left;
    }
  }
  .foreword {
    font-size: 0.2rem;
    text-align: left;
    text-indent: 0.2rem;
  }
}
</style>