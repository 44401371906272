<template>
  <div id='HotelMyOrder'
       :style="{height:innerHeight}">
    <!-- <NavBar NavBarTitle='特权订单'
            @onClickLeft='onClickLeft'></NavBar> -->
    <van-tabs v-model="active"
              @change='changeStatus'>
      <van-tab v-for="item in orderStatus"
               :key="item.status"
               :title='item.title'>
        <div style="padding-bottom:80px;background-color: #f4f9f8;">
          <van-empty v-if="orderData.length==0"
                     description="还没有订单哦" />
          <div class="content"
               v-for="item in orderData"
               :key="item.id">
            <div class="shopCard">
              <div class="title">
                {{item.real_name}}
                <span>
                  {{item.order_status == 0 ?"待支付":
                  item.order_status == 1 ?"已完成":"已取消"}}
                </span>
              </div>
              <van-card :price="item.total_price"
                        :desc="'充值号码:'+item.number"
                        :title="item.order_title"
                        :thumb="item.logo"
                        @click="getSpecID(item)">
                <template #footer
                          class="numAdd">
                  <span class="num">x1</span>
                </template>
              </van-card>
            </div>
            <div class="operation"
                 v-if="item.order_status==0||item.order_status==1">
              <div class="btn"
                   v-if="item.order_status==0">
                <van-button plain
                            round
                            @click="cancelOrder(item)">取消订单</van-button>
                <van-button plain
                            color="#FE6529"
                            round
                            @click="payOrder(item)">付款</van-button>
              </div>
              <div class="btn"
                   v-if="item.order_status==1">
                <van-button plain
                            round
                            @click="getSpecID(item)">查看订单</van-button>
              </div>
            </div>
          </div>
        </div>

      </van-tab>
    </van-tabs>

    <van-popup v-model="fromShow">
      <div class="title">订单详情</div>
      <div class="cont">
        <div style="display:flex">订单信息: <span style="width:73%">{{detailTitle}}</span></div>
        <div>充值号码: {{number}}</div>
        <div>金额: <span style="font-size:0.4rem;font-weight:bold">￥{{total_price}}</span></div>
        <div>下单时间: {{create_time}}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getSpecOrderList, paySpecOrder, cancelSpecOrder, getSpecOrderDetail } from '../../../api/vip'
import { Toast } from 'vant';
import { aesKey } from "../../../commom/aesKey"
// import NavBar from '@/components/NavBar'
export default {
  components: {
    // NavBar,
  },
  data () {
    return {
      innerHeight: 0,
      aesKey: aesKey(),//接口通过密文来获取数据
      routerFrom: '',//用于判断那个路由过来的
      page: 1,//页数
      active: 0,//顶部标签
      order_status: '',//订单状态
      orderStatus: [
        {
          title: '全部',
        },
        {
          title: '待付款',
        },
        {
          title: '已支付',
        },
        {
          title: '已取消',
        },
      ],
      orderData: [],//订单数据

      //弹窗
      fromShow: false,
      detailTitle: '',//订单信息
      number: '',//充值号码
      total_price: '',//订单金额
      create_time: '',//创建时间
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    this.innerHeight = window.innerHeight + 'px'
    this.refreshDesktop()
  },
  beforeRouteEnter (to, from, next) { // 这个路由钩子函数比生命周期beforeCreate函数先执行，所以this实例还没有创建出来
    // this.routerFrom = from.name
    // console.log(this.routerFrom)
    next((vm) => {
      vm.routerFrom = from.name
    })
  },
  methods: {

    onClickLeft () {
      // this.$router.go(-1)
      if (this.routerFrom == 'personalHome') {
        this.$router.push({ path: '/allPerInfo/personalHome' })
      } else {
        this.$router.push({ path: '/Home' })
      }
    },

    //获取特权订单列表
    getSpecOrderList (data) {
      getSpecOrderList(data).then(res => {
        this.orderData = res.data.data.list
      })
    },
    //刷新桌面
    refreshDesktop () {
      let data = {
        page: this.page,
        status: this.order_status
      }
      this.aesKey = aesKey(data)
      this.getSpecOrderList(this.aesKey)
    },

    // 获取滚动条的滚动距离
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      // // //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

      // // //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight == scrollHeight) {
        this.page++
        let data = {
          page: this.page,
          order_status: this.order_status
        }
        this.aesKey = aesKey(data)
        // console.log(data)
        getSpecOrderList(this.aesKey).then(res => {
          // console.log(res)
          this.orderData = this.orderData.concat(res.data.data.list)
        })
      }

    },
    //切换状态
    changeStatus (name) {
      this.page = 1
      if (name == 0) {
        this.order_status = ''
      } else {
        this.order_status = name - 1
      }
      let data = {
        order_status: this.order_status
      }
      this.aesKey = aesKey(data)
      this.getSpecOrderList(this.aesKey)
    },
    //获取点击的订单详情
    async getSpecID (item) {
      // console.log(item)
      this.fromShow = true
      let data = {
        order_no: item.order_no
      }
      this.aesKey = aesKey(data)
      let res = await getSpecOrderDetail(this.aesKey)
      const DATA = res.data.data
      this.detailTitle = DATA.order_title
      this.number = DATA.number
      this.total_price = DATA.total_price
      this.create_time = DATA.create_time
    },
    //订单选择服务
    goShopService (item) {
      // console.log(item)
      this.$router.push(
        {
          path: '/allShop/shopService',
          query: { goodsInfo: [item] }
        }
      )
    },
    //取消订单
    async cancelOrder (data) {
      const DATA = {
        order_no: data.order_no
      }
      this.aesKey = aesKey(DATA)
      let res = await cancelSpecOrder(this.aesKey)
      // console.log(res)
      if (res.data.code == 200) {
        Toast.success(res.data.message);
        this.refreshDesktop()
      } else {
        Toast.fail(res.data.message);
      }
      // console.log(data)
    },
    //支付订单
    async payOrder (data) {
      const DATA = {
        order_no: data.order_no
      }
      this.aesKey = aesKey(DATA)
      let res = await paySpecOrder(this.aesKey)
      // console.log(res)
      if (res.data.code == 200) {
        Toast.success(res.data.message);
        this.refreshDesktop()
      } else {
        Toast.fail(res.data.message);
      }
      // console.log(data)
    },

  }
}
</script>

<style lang="less" scoped>
#HotelMyOrder {
  background-color: #f4f9f8;
  /deep/.van-tabs__nav {
    padding-right: 0.25rem;
  }
  /deep/.van-tab {
    font-size: 0.25rem;
  }
  /deep/.van-tab--active {
    color: #24c5af;
  }
  /deep/.van-tabs__line {
    background-color: #36e0c9;
    height: 2px;
  }
  .content {
    text-align: left;
    background-color: #f4f9f8;
    // padding-bottom: 100px;
    margin-top: 0.2rem;
    .shopCard {
      border-radius: 10px;
      background-color: white;
      font-size: 0.35rem;
      padding-bottom: 0.1rem;
      .title {
        font-size: 0.27rem;
        padding: 0.2rem 0.3rem;
        span {
          float: right;
          color: #fe6529;
          font-size: 0.22rem;
        }
      }
      .van-card {
        background-color: white;
        border-radius: 10px;
        font-size: 0.2rem;
        padding: 0 0.3rem;
        margin-top: 5px;
        position: relative;
        .van-card__title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 4.8rem;
          font-size: 0.3rem;
          height: auto;
          display: inline-block;
          line-height: inherit;
        }
        .van-card__thumb {
          width: 1.5rem;
          height: 1.5rem;
        }
        .van-card__desc {
          display: inline;
          // padding: 0.05rem 0.1rem;
          // background-color: #f5f5f5;
          color: #999999;
          width: 3rem;
        }
        .van-card__price {
          font-size: 0.3rem;
          font-weight: bold;
          color: #fe6529;
          .van-card__price-currency {
            font-size: 0.2rem;
          }
          .van-card__price-integer {
            font-size: 0.3rem;
          }
        }
        .van-card__footer {
          font-size: 0.3rem;
          position: absolute;
          right: 5%;
          bottom: 0%;
          color: #fe6529;
        }
      }
    }
    .operation {
      font-size: 0.6rem;
      height: 1rem;
      background-color: white;
      .btn {
        float: right;
        margin-right: 0.1rem;
        .van-button {
          font-size: 0.25rem;
          width: 2rem;
          height: 0.6rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
  .van-popup--center {
    font-size: 0.26rem;
    border-radius: 10px;
    .title {
      font-size: 0.35rem;
      padding: 0.3rem 0;
    }
    .cont {
      // margin-top: 0.2rem;
      padding: 0 0.3rem;
      padding-bottom: 0.5rem;
      div {
        margin-top: 0.2rem;
        width: 5rem;
        text-align: left;
      }
      .van-cell {
        margin-top: 0rem;
        padding: 0;
        /deep/.van-field__label {
          width: auto;
        }
      }
    }
    .btn {
      .van-button {
        width: 50%;
      }
    }
  }
}
</style>