<template>
  <div id="realName">
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="content">
      <van-cell-group>
        <van-form validate-first>
          <van-field v-model="name"
                     name="pattern"
                     label="认证人"
                     placeholder="请输入姓名"
                     @input="$emit('input',$event.target.value)"
                     :rules="[{ pattern:/[\u4e00-\u9fa5_a-zA-Z0-9]{2,10}/ }]" />
        </van-form>
        <div class="nameId">
          <van-form validate-first>
            <van-field readonly
                       clickable
                       name="pattern"
                       :value="nameId"
                       label="身份证"
                       placeholder="请输入身份证"
                       @touchstart.native.stop="nameShow = true"
                       :rules="[{ pattern:/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/ }]" />
          </van-form>
          <van-number-keyboard v-model="nameId"
                               :show="nameShow"
                               :maxlength="11"
                               extra-key="X"
                               close-button-text="完成"
                               @blur="nameShow = false" />
        </div>
      </van-cell-group>
      <!-- <div class="nameId">
        <van-field readonly
                   clickable
                   :value="nameId"
                   placeholder="请输入手机号码"
                   @touchstart.native.stop="nameShow = true" />
        <van-number-keyboard v-model="nameId"
                             :show="nameShow"
                             :maxlength="11"
                             @blur="nameShow = false" />
      </div> -->
      <van-popup v-model="showBlack"
                 :get-container="getContainer">
        <div><img src="@/assets/nameId/realName.png"
               style="width:100%"
               alt=""></div>
      </van-popup>
      <div class="btn">
        <van-button type="primary"
                    size="large"
                    round
                    color='#38e0c9'
                    @click="submit">立即认证</van-button>
      </div>
      <div class="bottom">
        <div class="text">
          已阅读并同意
          <span>《用户服务协议》</span>、
          <span>《隐私政策》</span>及
          <span>《内容平台协议》</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import NavBar from '../../components/NavBar.vue'
export default {
  components: {
    // NavBar,
  },
  data () {
    return {
      innerHeight: 0,
      NavBarTitle: '实名认证',
      nameShow: false,//号码键盘弹窗
      nameId: '',//身份证键盘
      name: '',
      showBlack: false
    }
  },
  mounted () {
    this.innerHeight = window.innerHeight + 'px'
  },
  methods: {
    onClickLeft () {
      this.$router.push({ path: '/Home' })
    },
    submit () {
      this.show = true != false
    },
    getContainer () {
      return document.querySelector('.my-container');
    },
  }
}
</script>

<style lang="less" scoped>
#realName {
  font-size: 0.21rem;
  .content {
    margin-top: 0.6rem;
    padding: 0 0.2rem;
    // background-color: aqua;
    .title {
      font-size: 0.45rem;
      font-weight: bold;
    }
    .nameId {
      // margin-top: 1rem;
      z-index: 10;
      /deep/.van-cell {
        // font-size: 0.3rem;
      }
    }
    .btn {
      margin-top: 0.4rem;
      padding: 0 0.4rem;
      .van-button {
        font-size: 0.28rem;
      }
    }
    .bottom {
      position: absolute;
      width: 100%;
      bottom: 5%;
      .text {
        color: gray;
        width: 4.6rem;
        margin-left: 1.3rem;
        span {
          color: #24c5af;
        }
      }
    }
  }
}
</style>