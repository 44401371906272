<template>
  <div id="shopList">
    <div class="fixed">
      <NavBar :NavBarTitle="`${$route.query.keyword}的搜索结果`"
              @onClickLeft='onClickLeft'></NavBar>
      <div class="header">
        <div id="search">
          <van-search v-model="searchValue"
                      shape="round"
                      @search='getSearch(searchValue)'
                      placeholder="搜索商品/美食" />
        </div>
        <div class="screen">
          <van-dropdown-menu active-color="#24C5AF">
            <van-dropdown-item v-model="value1"
                               :options="option1" />
            <van-dropdown-item v-model="value2"
                               :options="option2" />
            <van-dropdown-item title="筛选"
                               @open="openDrawer"
                               @close='closeDrawer' />
          </van-dropdown-menu>
        </div>
      </div>
    </div>

    <div class="ListWater">
      <WaterFall :imgsArr='imgsArr'
                 @getwatId='getwatId'></WaterFall>
    </div>
    <div class="drawer">
      <el-drawer title="我是标题"
                 size="80%"
                 :visible.sync="drawer"
                 :direction="direction"
                 :show-close='false'
                 :with-header="false">
        <div class="content">
          <div class="Service_discount">
            <div class="title">服务/折扣</div>
            <div class="choice_btn">
              <van-row type="flex"
                       justify="space-between">
                <van-col span="8"
                         v-for="item in ServiceBtn"
                         :key="item.id">
                  <div class="btn"
                       :class="item.isclick==true?'is_type_active':''"
                       @click="getSerType(item)">
                    {{item.name}}
                  </div>
                </van-col>
              </van-row>
            </div>

          </div>
          <div class="priceRange">
            <div class="title">价格区间</div>
            <div style="position: relative;">
              <van-field v-model="minPrice"
                         type="digit"
                         placeholder="最低价"
                         input-align="center"
                         style="margin-right: 0.5rem;" />
              <van-field v-model="maxPrice"
                         type="digit"
                         placeholder="最高价"
                         input-align="center" />
              <div class="line">—</div>
            </div>
          </div>
          <div class="Service_discount">
            <div class="title">品牌</div>
            <div class="choice_btn">
              <van-row type="flex"
                       justify="space-between">
                <van-col span="8"
                         v-for="item in brandBtn"
                         :key="item.id">
                  <div class="btn"
                       :class="item.isclick==true?'is_type_active':''"
                       @click="getBraType(item)">
                    {{item.name}}
                  </div>
                </van-col>
              </van-row>
            </div>

          </div>
          <div class="Service_discount">
            <div class="title">服饰</div>
            <div class="choice_btn">
              <van-row type="flex"
                       justify="space-between">
                <van-col span="8"
                         v-for="item in dressBtn"
                         :key="item.id">
                  <div class="btn"
                       :class="item.isclick==true?'is_type_active':''"
                       @click="getDreType(item)">
                    {{item.name}}
                  </div>
                </van-col>
              </van-row>
            </div>
          </div>
          <div class="bottom">
            <van-button type="primary"
                        round
                        @click="reset"
                        style="margin-right: 0.5rem;background-color:white;color:black;border:1px solid gray">
              重置
            </van-button>
            <van-button type="primary"
                        round
                        color="#36E0C9"
                        @click="confirm">确认</van-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { getGoodsList } from '../../../api/shop.js'
import { aesKey } from "../../../commom/aesKey"
import NavBar from '@/components/NavBar'
import WaterFall from '@/components/WaterFall'
export default {
  components: {
    NavBar,
    WaterFall
  },
  data () {
    return {
      searchValue: '',
      categoryId: '',//分类Id
      aesKey: aesKey(),//接口通过密文来获取数据s
      page: 1,//页数
      pageCount: '',//可翻页的次数
      imgsArr: [],//瀑布流数据
      //筛选 综合排序
      value1: 0,
      option1: [
        { text: '综合排序', value: 0 },
        { text: '新品', value: 1 },
        { text: '价格降序', value: 2 },
        { text: '价格升序', value: 3 },
      ],
      //筛选 销量排序
      value2: 0,
      option2: [
        { text: '销量升序', value: 0 },
        { text: '销量降序', value: 1 },
      ],
      //侧边抽屉
      drawer: false,
      direction: 'rtl',
      //服务折扣
      ServiceBtn: [
        {
          name: '积分折扣',
          isclick: false,
        },
        {
          name: '会员特权',
          isclick: false,
        },
        {
          name: '抵用金',
          isclick: false,
        },
        {
          name: '售后服务',
          isclick: false,
        },
      ],
      //价格区间
      minPrice: '',
      maxPrice: '',
      //品牌
      brandBtn: [
        {
          name: 'dior',
          isclick: false,
        },
        {
          name: 'LV',
          isclick: false,
        },
        {
          name: 'dasabi',
          isclick: false,
        },
      ],
      //服饰
      dressBtn: [
        {
          name: '出行用品',
          isclick: false,
        },
        {
          name: '居家日用',
          isclick: false,
        },
        {
          name: '母婴用品',
          isclick: false,
        },
        {
          name: '旅行用品',
          isclick: false,
        },
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.searchValue = sessionStorage.getItem("searchValue")
    this.categoryId = sessionStorage.getItem('categoryId')
    // console.log(this.categoryId)
    let data = {
      goods_category_top_ids: this.categoryId
    }
    this.aesKey = aesKey(data)
    this.getGoodsList(this.aesKey)
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    // getGoodsList (data) {
    //   getGoodsList(data).then(res => {
    //     let arr = []
    //     res.data.data.list.forEach(item => {

    //     })
    //   })
    // }
    getGoodsList (data) {
      getGoodsList(data).then(res => {
        let arr = []
        res.data.data.list.forEach(item => {
          if (item.name.includes(this.searchValue)) {
            // if (item.name.includes(this.searchValue)) {
            arr.push(item)
            // }
          }
          // console.log(item, "===搜索页面商品数据")
        })
        this.pageCount = Math.ceil(res.data.data.total / 15)
        let dataArr = arr.map(item => {
          return {
            id: item.id,
            img: item.big_images,
            describe: item.name,
            type: item.brand_name,
            // 价格展示
            price: Math.ceil(
              Number(
                item.sales_price *
                (this.discount ? this.discount : 1)
              )
            ),
          }
        })
        this.imgsArr = this.imgsArr.concat(dataArr)
      })
    },
    // 获取滚动条的滚动距离
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      if (scrollTop % 50 == 0 && this.page <= this.pageCount) {
        this.page++
        this.aesKey = aesKey(this.page)
        let data = {
          page: this.page,
          goods_category_top_ids: this.categoryId
        }
        this.aesKey = aesKey(data)
        this.getGoodsList(this.aesKey)
      }
    },
    //瀑布流点击
    getwatId (data) {
      console.log(data)
      this.$router.push({ path: '/allShop/shopDetail' })
      sessionStorage.setItem('goodsId', data.id)
    },
    //侧边抽屉出现
    openDrawer () {
      this.drawer = true
      console.log(34)
    },
    //侧边抽屉消失
    closeDrawer () {
      let arr = []
      this.ServiceBtn.forEach(item2 => {
        if (item2.isclick == true) {
          arr.push(item2)
        }
      })
      this.brandBtn.forEach(item2 => {
        if (item2.isclick == true) {
          arr.push(item2)
        }
      })
      this.dressBtn.forEach(item2 => {
        if (item2.isclick == true) {
          arr.push(item2)
        }
      })
      arr.push(this.minPrice)
      arr.push(this.maxPrice)
      console.log(arr)
    },
    //服务/折扣
    getSerType (item) {
      console.log(item)
      this.ServiceBtn.forEach(item2 => {
        item2.isclick = false
      })
      item.isclick = true
    },
    //品牌
    getBraType (item) {
      this.brandBtn.forEach(item2 => {
        item2.isclick = false
      })
      item.isclick = true
    },
    //服饰
    getDreType (item) {
      this.dressBtn.forEach(item2 => {
        item2.isclick = false
      })
      item.isclick = true
    },
    //确认按钮
    confirm () {
      let arr = []
      this.ServiceBtn.forEach(item2 => {
        if (item2.isclick == true) {
          arr.push(item2)
        }
      })
      this.brandBtn.forEach(item2 => {
        if (item2.isclick == true) {
          arr.push(item2)
        }
      })
      this.dressBtn.forEach(item2 => {
        if (item2.isclick == true) {
          arr.push(item2)
        }
      })
      arr.push(this.minPrice)
      arr.push(this.maxPrice)
      console.log(arr)
      this.drawer = false
    },
    //重置按钮
    reset () {
      this.minPrice = ''
      this.maxPrice = ''
      this.ServiceBtn.forEach(item2 => {
        item2.isclick = false
      })
      this.brandBtn.forEach(item2 => {
        item2.isclick = false
      })
      this.dressBtn.forEach(item2 => {
        item2.isclick = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
#shopList {
  .fixed {
    position: fixed;
    z-index: 10;
    width: 100%;
    top: 0%;
    .header {
      #search {
        /deep/.van-search {
          padding-top: 0.2rem;
          z-index: 10;
          // background-color: aqua;
          .van-search__content {
            background-color: #fafafa;
          }
          .van-field__body {
            padding-left: 0.2rem;
            font-size: 0.24rem;
          }
        }
        width: 100%;
      }
      .screen {
        /deep/.van-ellipsis {
          overflow: inherit;
        }
        /deep/.van-dropdown-menu__bar {
          box-shadow: none;
          .van-dropdown-menu__title {
            font-size: 0.24rem;
          }
        }
      }
    }
  }

  .ListWater {
    padding-left: 0.25rem;
    margin-top: 150px;
    /deep/.vue-waterfall-easy {
      a.img-inner-box {
        box-shadow: none;
        a {
          height: 100% !important;
          width: auto !important;
        }
      }
      img {
        border-radius: 5px;
        width: 93%;
      }
      .img-box .default-card-animation {
        padding: 0;
      }
    }
    /deep/.vue-waterfall-easy-container
      .vue-waterfall-easy-scroll[data-v-ded6b974] {
      overflow-x: visible;
      overflow-y: visible;
    }
  }
  .drawer {
    font-size: 0.25rem;
    text-align: left;
    .content {
      padding: 0rem 0.2rem;
      width: 100%;
      position: relative;
      height: 96%;
      .title {
        font-size: 0.3rem;
        color: black;
        font-weight: bold;
      }
      .Service_discount {
        margin-top: 0.4rem;
        .choice_btn {
          .van-col--8 {
            width: 30%;
            margin-top: 0.2rem;
          }
          .btn {
            display: inline-block;
            background-color: #f9f9f9;
            width: 1.85rem;
            height: 0.65rem;
            border-radius: 20px;
            text-align: center;
            line-height: 0.65rem;
            // margin-right: 0.2rem;
          }
          .btn.is_type_active {
            background-color: #36e0c9;
            color: white;
          }
        }
      }
      .priceRange {
        margin-top: 0.4rem;
        .van-cell {
          width: 2.55rem;
          display: inline-block;
          background-color: #f9f9f9;
          margin-top: 0.2rem;
          font-size: 0.25rem;
          border-radius: 20px;
          padding: 0.1rem 0;
        }
        .line {
          position: absolute;
          left: 48%;
          top: 39%;
        }
      }
      .bottom {
        position: absolute;
        bottom: 0%;
        width: 95%;
        .van-button--round {
          width: 45%;
        }
      }
    }
  }
}
</style>