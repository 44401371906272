<template>
  <div>
    <div class="content"
         v-for="item in ShowHotelHotData"
         :key="item.id"
         @click="getID(item)">
      <div class="left"
           :style="{
          background: 'url(' + item.img + ') no-repeat center top',
          backgroundSize: cover,
        }"></div>
      <div class="right">
        <div class="H_one">
          {{ item.title }}
        </div>
        <div class="H_two">
          <van-tag type="primary"
                   size="medium"
                   color="#60d7d2"
                   v-for="hotel_tags in item.hotel_tags"
                   :key="hotel_tags.id">{{ hotel_tags }}</van-tag>
          <span></span>
        </div>
        <!-- <div class="H_bottom">
          <van-tag text-color="#fd8d64"
                   plain> 天天特惠 </van-tag>
          <div style="float: right">
            <span class="old_price"
                  v-if="item.old_price">￥{{item.old_price}}</span>
            <span class="new_price">￥{{ item.pirce == 0 ? '0' : item.starting_price }}</span>
            <span class="icon"
                  style="color: #fd8d64; font-size: 0.2rem">起<img src="../assets/HotelImg/rightIcon.png"
                   alt="" /></span>
          </div>
        </div> -->
      </div>
    </div>
    <div v-if="is_showList == '1'">

      <div class="HotelListAsb1"
           v-for="item in ShowHotelData"
           @click="getHotelId(item)"
           :key="item.id">
        <div class="left"
             :style="{
            background: 'url(' + item.img + ') no-repeat center top',
            backgroundSize: cover,
          }"></div>

        <div class="right">
          <div class="H_one">
            {{ item.title }}
          </div>
          <div class="H_two">
            <!-- 点评位置 -->

            <!-- 城市位置 -->
            <div class="address"
                 style="color: gray; font-size: 0.25rem; ">
              <!-- <van-icon name="location-o"
                        style="margin-right: 0.05rem" /> -->
              <span style="color:black;font-weight: 400;">{{ item.address }}</span>
            </div>
            <!-- <div style="font-size: .25rem;margin-top: .05rem;">
              <span class="tags2"
                    v-if="item.discount[0]">{{
                item.discount[0]
              }}</span>
              <span class="tags2"
                    v-if="item.discount[1]">{{
                item.discount[1]
              }}</span>
              <span class="tags2"
                    v-if="item.discount[2]">{{
                item.discount[2]
              }}</span>
              <span class="tags2"
                    v-if="item.discount[3]">{{
                item.discount[3]
              }}</span>
              <span class="tags2"
                    v-if="item.discount[4]">{{
                item.discount[4]
              }}</span>
              <span class="tags2"
                    v-else-if="item.hotel_tags == ''"><br /></span>
            </div> -->
            <div style="margin-top: .05rem;">
              <span style="font-size: .25rem; color: #10b27c">入住当天18:00前可免费取消</span>
            </div>
            <div class="H_tag">
              <!-- 酒店卫生服务 -->
              <van-tag plain
                       v-if="item.discount[0]"
                       class="tags"
                       color="#10b27c">{{ item.discount[0] }}</van-tag>
              <van-tag plain
                       v-if="item.discount[1]"
                       class="tags"
                       color="#10b27c">{{ item.discount[1] }}</van-tag>
              <van-tag plain
                       v-if="item.discount[2]"
                       class="tags"
                       color="#10b27c">{{ item.discount[2] }}</van-tag>
              <van-tag plain
                       v-if="item.discount[3]"
                       class="tags"
                       color="#10b27c">{{ item.discount[3] }}</van-tag>
            </div>
            <!-- 人数预定展示位置 -->
            <div style="font-size: 0.17rem; color: gray; margin-top: 0.1rem"></div>
          </div>
          <div class="H_bottom">
            <div style="display: flex;flex-direction: column;">
              <div class="price"
                   style="margin-right: 0.0741rem">
                <span class="old_price">{{ item.old_price }}</span>
                <!-- 折扣价格 -->
                <!-- <span class="old_price">$9999</span> -->
                <span class="new_price"><span style="font-size: 0.2rem">￥ </span>
                  {{ item.starting_price == 0 ? '0' : item.starting_price }}
                  <span style="font-size: 0.2rem"> 起</span></span>
              </div>
              <div class="discounts">
                <span>会员优惠17</span>
                <van-icon name="arrow"
                          style="font-size: .15rem;" />
              </div>
            </div>

            <!-- <div class="tag"
                 style="margin-top: 0.2rem">
              <van-tag type="primary"
                       size="medium"
                       color="#fd7644">
                <span class="tags-big">{{item.level}}</span>
                <span class="tags-big"
                      v-if="item.level == 1">一星</span>
                <span class="tags-big"
                      v-if="item.level == 2">二星</span>
                <span class="tags-big"
                      v-if="item.level == 3">三星</span>
                <span class="tags-big"
                      v-if="item.level == 4">四星</span>
                <span class="tags-big"
                      v-if="item.level == 5">五星</span>
                <span class="tags-big"
                      v-if="item.level == 6">六星</span>
                <span class="tags-big"
                      v-if="item.level == 7">七星</span>
              </van-tag>
              <span style="float: right; right: 0%; position: absolute">
                <van-tag type="primary"
                         size="medium"
                         plain
                         color="#FD7644">
                  <span class="tags-big">天天特惠</span>
                </van-tag>
                <van-tag type="primary"
                         size="medium"
                         color="#FD7644">
                  <span class="tags-big">共减￥17</span>
                </van-tag>
              </span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="is_showList == '2'">
      <div class="HomeListAsb2"
           v-for="(item, index) in roomList"
           :key="item.id">
        <div class="left"
             v-if="item.room_theme == 12"
             @click="showPic(item)"
             :style="{
            background: 'url(' + item.room_pic + ') no-repeat center top',
            backgroundSize: cover,
          }">
          <img style="width: 50px; position: absolute; top: 0%; left: 0%"
               src="../assets/Esports/e_sports_tag.png" />
        </div>
        <div class="left"
             v-else
             @click="showPic(item)"
             :style="{
            background: 'url(' + item.room_pic + ') no-repeat center top',
            backgroundSize: cover,
          }"></div>

        <div class="right"
             style="">
          <div class="H_one"
               @click="getHomeID(index)">
            {{ item.title }}
          </div>
          <div class="H_two"
               @click="getHomeID(index)">
            <div style=" font-size: 0.25rem">
              <span style="margin-right: 0.1rem">{{ item.bed_num }}张床</span>
              <span style="margin-right: 0.1rem">房间{{ item.room_size }}m²</span>
              <span style="margin-right: 0.1rem">{{ item.window }}</span>
            </div>
            <div style="margin-top: 0.05rem">
              <!-- <van-tag type="primary"
                       plain
                       color="#148AEE"
                       style="margin-right:0.07rem;"
                       v-for="tag in item.room_tags"
                       :key="tag.id">{{tag}}</van-tag> -->
            </div>
            <div style="font-size: 0.25rem; color: #3cc094; margin-top: 0.1rem">
              <span style="margin-right: 0.1rem; color: black">{{
                item.meals
              }}</span>

              <span>30分钟内免费取消</span>
            </div>
          </div>
          <div class="H_three">
            <span style="margin-right: 0.1rem"
                  class="border"><van-tag color="#10b27c"
                       plain
                       type="primary"
                       style="padding: 2px 4px; border-radius: 3px">立即确认</van-tag></span>
          </div>
          <!-- 6.22 因为body class="van-overflow-hidden" 问题 无法滑动-->
          <!-- <span class="colorChange"
                @click="getHomeID(index)">会员特惠价</span> -->
          <div class="order"
               @click="getOrderId(index)">
            <div>
              <span style="text-decoration"></span>
              <span>
                <div class="price">
                  <!-- <span class="old_price">￥{{item.oldPrice}}</span> -->
                  <span class="new_price"><span style="font-size: 0.2rem; font-weight: 600">￥
                    </span>
                    {{ item.sales_price }}
                    <!-- <span style="font-size:0.2rem">
                      起</span> -->
                  </span>
                  <!-- <span style="margin-top:-0.1rem;display:inline-block">
                    <van-tag type="primary"
                             size="medium"
                             plain
                             color="#FD7644">天天特惠</van-tag>
                    <van-tag type="primary"
                             size="medium"
                             color="#FD7644">共减￥17</van-tag>
                  </span> -->
                </div>
              </span>
              <span class="pay"
                    @click="getOrderId(index)">
                <img src="../assets/HotelImg/pay_img.png"
                     alt="" />
              </span>
            </div>
          </div>
        </div>
        <!-- 预览缩略图 -->
        <van-image-preview v-model="show"
                           :images="[room_pic]">
          <!-- <template v-slot:index>第{{ pic_index }}页</template> -->
        </van-image-preview>
      </div>
    </div>
    <div v-if="is_showList == '3'">
      <div style="width: 100%;margin-bottom: ;"
           @click="Pay">
        <img style="width: 6.7519rem;margin-bottom: .15rem;"
             src="../assets/HotelImg/Pay.png"
             alt="" />
      </div>
      <div class="HomeListAsb2"
           v-for="(item, index) in roomList"
           :key="item.id">
        <div class="left"
             v-if="item.room_theme == 12"
             @click="showPic(item)"
             :style="{
            background: 'url(' + item.room_pic + ') no-repeat center top',
            backgroundSize: cover,
          }">

          <img style="width: 50px; position: absolute; top: 0%; left: 0%"
               src="../assets/Esports/e_sports_tag.png" />

        </div>
        <div class="left"
             v-else
             @click="showPic(item)"
             :style="{
            background: 'url(' + item.room_pic + ') no-repeat center top',
            backgroundSize: cover,
          }"></div>

        <div class="right"
             style="">
          <div class="H_one_3"
               @click="getHomeID(index)">
            {{ item.title }}
          </div>
          <div class="H_two"
               @click="getHomeID(index)">
            <div style="margin-top: 0.1rem; font-size: 0.25rem;height: .3rem;width: 90%;overflow: hidden;">
              <span style="margin-right: 0.1rem;"
                    v-if="item.computer_config && item.computer_config.gpu">3060显卡</span>

              <span style="margin-right: 0.1rem">{{ item.bed_num }}张床</span>
              <span style="margin-right: 0.1rem">房间{{ item.room_size }}m²</span>
              <span style="margin-right: 0.1rem">{{ item.window }}</span>
            </div>
            <div style="margin-top: 0.05rem">
              <!-- <van-tag type="primary"
                       plain
                       color="#148AEE"
                       style="margin-right:0.07rem;"
                       v-for="tag in item.room_tags"
                       :key="tag.id">{{tag}}</van-tag> -->
            </div>
            <div style="font-size: 0.25rem; color: #3cc094; margin-top: 0.2rem">
              <!-- <span style="margin-right: 0.1rem; color: black">{{
                item.meals
              }}</span> -->
              <span style="margin-right: 0.1rem"
                    class="border"><van-tag color="#10b27c"
                         plain
                         type="primary"
                         style="padding: 2px 4px; border-radius: 3px">立即确认</van-tag></span>
              <span>30分钟内免费取消</span>
            </div>
          </div>
          <!-- <div class="H_three">
            
          </div> -->
          <!-- 6.22 因为body class="van-overflow-hidden" 问题 无法滑动-->
          <!-- <span class="colorChange"
                @click="getHomeID(index)">会员特惠价</span> -->
          <div class="order"
               @click="getOrderId(index)">
            <div>
              <span style="text-decoration"></span>
              <span>
                <div class="price">
                  <!-- <span class="old_price">￥{{item.oldPrice}}</span> -->
                  <span class="new_price"><span style="font-size: 0.2rem; font-weight: 600">￥
                    </span>
                    {{ item.sales_price}}
                    <!-- <span style="font-size:0.2rem">
                      起</span> -->
                  </span>
                  <!-- <span style="margin-top:-0.1rem;display:inline-block">
                    <van-tag type="primary"
                             size="medium"
                             plain
                             color="#FD7644">天天特惠</van-tag>
                    <van-tag type="primary"
                             size="medium"
                             color="#FD7644">共减￥17</van-tag>
                  </span> -->
                </div>
              </span>
              <span class="pay"
                    @click="getOrderId(index)">
                <img src="../assets/HotelImg/pay_img.png"
                     alt="" />
              </span>
            </div>
          </div>
        </div>
        <!-- 预览缩略图 -->
        <van-image-preview v-model="show"
                           :images="[room_pic]">
          <!-- <template v-slot:index>第{{ pic_index }}页</template> -->
        </van-image-preview>
      </div>
      <!-- 预览缩略图 -->
      <van-image-preview v-model="show"
                         :images="[room_pic]">
        <!-- <template v-slot:index>第{{ pic_index }}页</template> -->
      </van-image-preview>
    </div>
    <!-- 旧 -->
    <!-- <div v-if="is_showList=='3'">
      <div style="width:100%"
           @click="Pay"><img style="width:6.7519rem"
             src="../assets/HotelImg/Pay.png"
             alt=""></div>
      <div class="hotelRoomList"
           v-for="(item,index) in roomList"
           :key="item.id">
        <div class="photo"
             @click="showPic(item)">
          <img :src="item.room_pic"
               alt="">
        </div>
        <div class="room_info"
             @click="getHomeID(index)">
          <span>{{item.title}}</span>
          <span style="font-size:12px;margin-top: 10px;">{{item.meals}}</span>
          <span style="font-size:12px">{{item.bed_num}}张床</span>
          <span>{{item.price}}</span>
        </div>
        <div class="bottom">
          <div class="price">
            <div style="font-size:12px;color: #ff6327; display: inline-block;">¥</div> {{item.salesCeil_price}}
          </div>
          <div class="room_btn">
            <van-button @click="getOrderId(index)">立即购</van-button>
          </div>
        </div>

      
        <van-image-preview v-model="show"
                           :images="[room_pic]">
           <template v-slot:index>第{{ pic_index }}页</template>
        </van-image-preview>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  props: {
    ShowHotelHotData: Array, //热门推荐的酒店数据
    is_showList: String,
    ShowHotelData: Array, //酒店列表的数据
    roomList: Array, //酒店房型数据
  },
  data () {
    return {
      cover: 'cover',
      hotelList: [],
      imagesList: [],
      show: false,
      pic_index: 0,
      room_pic: '',
    }
  },
  created () {
    // this.getWeiXinPIM()
    this.$forceUpdate()
  },

  mounted () { },

  watch: {
    ShowHotelHotData () {
      this.ShowHotelHotData.forEach((item) => {
        console.log(item, "123");
        if (item.img === '') {
          if (item.id % 2 == 0) {
            item.img =
              'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202203/04/1646395064053wutu2.jpg'
          } else {
            item.img =
              'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202203/04/1646394881325wutu1.jpg'
          }
        }
      })
    },
    ShowHotelData () {
      this.ShowHotelData.forEach((item) => {
        // console.log(item, "312");

        if (item.img === '') {
          if (item.id % 2 == 0) {
            item.img =
              'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202203/04/1646395064053wutu2.jpg'
          } else {
            item.img =
              'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202203/04/1646394881325wutu1.jpg'
          }
        }
      })
    },
    roomList () {
      this.roomList.forEach((item) => {
        if (item.room_pic === '') {
          if (item.id % 2 == 0) {
            item.img =
              'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202203/04/1646395064053wutu2.jpg'
          } else {
            item.img =
              'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202203/04/1646394881325wutu1.jpg'
          }
        }
      })
    },
  },
  methods: {
    //存储微信个人用户信息
    // getWeiXinPIM () {
    //   let PIMdata = JSON.parse(localStorage.getItem('wechatUser'))
    //   // 判断等级
    //   if (PIMdata.com_wah_is_vip == '1') {
    //     // this.com_wah_is_vip = data.com_wah_is_vip
    //     this.vip = '1'
    //   }
    // },
    onChange (pic_index) {
      this.pic_index = pic_index
    },

    showPic (item) {
      this.show = true
      console.log(item.room_pic)
      this.room_pic = item.room_pic
      console.log(item, '点击了缩略图')
    },
    getID (data) {
      this.$emit('getID', data)
    },
    //酒店详情
    getHotelId (data) {
      console.log(data, "12312312321312");
      this.$emit('getHotelId', data)
    },
    //酒店房型详情
    getHomeID (index) {
      console.log(index)
      this.$emit('getHomeID', index)
    },
    //酒店订单
    getOrderId (index) {
      console.log(index)
      this.$emit('getOrderId', index)
    },
    // 去充值
    Pay () {
      this.$emit('goPay')
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  margin-top: 0.2rem;
  display: flex;
  .left {
    width: 30%;
    background-size: auto 2.9rem;
    border-radius: 16px;
  }
  .right {
    width: 60%;
    margin-left: 0.2rem;
    .H_one {
      width: 4rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      margin-left: 0.04rem;
    }
    .H_two {
      .border {
      }
      // margin-top: 0.1rem;
    }

    .tags2 {
      margin-right: 0.3704rem;
    }
    .H_bottom {
      .old_price {
        color: gray;
        font-size: 0.25rem;
        text-decoration: line-through;
      }
      .new_price {
        color: #fd8d64;
        font-size: 0.35rem;
        font-weight: bold;
      }
      .icon {
        position: relative;
        img {
          position: absolute;
          top: -30%;
          left: 120%;
          width: 151%;
        }
      }
    }
  }
  /deep/.van-tag {
    margin-left: 0.04rem;
  }
}
.HotelListAsb1 {
  display: flex;
  margin-bottom: 0.25rem;
  height: 2.9259rem;
  width: 100%;
  .left {
    width: 2.3rem;
    background-size: auto 2.9rem;
    border-radius: 16px;
  }
  .right {
    width: 60%;
    margin-left: 0.2rem;
    height: 2.9259rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
    .H_one {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.32rem;
      margin-left: 0.04rem;
      font-weight: 600;
    }
    .H_two {
      .address {
        height: 0.4rem;
        overflow: hidden;
      }
      .tags2 {
        margin-right: 0.1rem;
      }
      .H_tag {
        // margin-top: 0.05rem;
        .van-tag {
          font-size: 0.2rem;
        }
      }
    }
    .van-tag--medium {
      padding: 0.02rem 0.03rem;
    }

    .H_bottom {
      margin-top: -0.2rem;
      overflow: hidden;
      display: flex;
      // margin-top: 0.15rem;

      flex-direction: row-reverse;
      .discounts {
        font-size: 0.25rem;
        background: #ffe0d5;
        border-radius: 4px;
        color: #fd7644;
        padding: 0rem 0.05rem 0.03rem 0.1rem;
      }
      // margin-top: 0.2rem;
      .price {
        float: right;
        .old_price {
          color: gray;
          font-size: 0.15rem;
          text-decoration: line-through;
        }
        .new_price {
          color: #fd8d64;
          font-size: 0.4rem;
          font-weight: 600;
        }
      }
      .tag {
        // margin-top: 1rem;
      }
    }
  }
}
.HomeListAsb2 {
  display: flex;
  position: relative;
  width: 100%;
  height: 2.2rem;
  margin-bottom: 0.25rem;
  .left {
    width: 30%;
    background-size: auto 2.9rem;
    border-radius: 8px;
    // margin-left: 0.3rem;
  }
  .right {
    // width: 64%;
    margin-left: 0.2rem;
    height: 100%;
    .H_one_3 {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      //
      width: 4.3rem;
      height: 0.75rem;

      // white-space: nowrap;

      font-size: 0.27rem;
      font-weight: 600;
      margin-left: 0.04rem;
    }
    .H_one {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      //
      width: 4.3rem;
      // white-space: nowrap;
      font-size: 0.27rem;
      font-weight: 600;
      margin-left: 0.04rem;
      height: 0.57rem;
    }
    .H_two {
      // margin-top: 10px;
    }
    .H_three {
      margin-top: 5px;
    }
    .H_bottom {
      // position: relative;
      margin-top: 1.15rem;
      .tag {
        position: absolute;
        width: 100%;
        bottom: 0%;
        .colorChange {
          position: absolute;
          bottom: 0%;
          font-size: 0.17rem;
          padding: 0.04rem 0.1rem;
          color: white;
          border-radius: 3px;
          background-image: linear-gradient(to right, #fba952, #fe6162);
        }
        .price {
          display: inline-block;
          width: 2.5rem;
          margin-left: 1rem;
          text-align: right;
          margin-top: 0.27rem;
          .old_price {
            height: 0;
            color: gray;
            font-size: 0.15rem;
            text-decoration: line-through;
          }
          .new_price {
            color: #fd8d64;
            font-weight: bold;
          }
        }
        .pay {
          display: inline-block;
          padding-top: 0.1rem;
          position: relative;
          width: 20%;
          height: 100%;
          img {
            position: absolute;
            right: 0%;
            bottom: -115%;
            width: 88%;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
.tags {
  margin-right: 0.0556rem;
  // padding: 0.0556rem;
}
.order {
  font-weight: 600;
  .pay {
    position: absolute;
    bottom: -30%;
  }
  position: absolute;
  float: right;
  bottom: 0%;
  right: 15%;
  .tag {
    width: 1.1111rem;
    height: 0.2593rem;
    position: absolute;
    display: inline-block;
    font-size: 12px;
  }
  .price {
    display: inline-block;
    color: #fd7644;
    margin-right: 6px;
  }
  img {
    width: 0.6481rem;
    height: 0.6481rem;
  }
}
.colorChange {
  position: absolute;
  bottom: 0%;
  font-size: 0.17rem;
  padding: 0.04rem 0.1rem;
  color: white;
  border-radius: 3px;
  background-image: linear-gradient(to right, #fba952, #fe6162);
}
.tags-big {
  margin: 0.037rem 0.0741rem;
}
.hotelRoomList {
  box-shadow: 0px 6px 10px 2px rgba(224, 224, 224, 0.5);
  border-radius: 16px;
  width: 95%;
  // height: 9rem;
  margin-bottom: 0.3rem;
  // background-color: #fe6162;
  .photo {
    margin-top: 0.3rem;
    img {
      width: 100%;
      height: 4.3rem;
      border-radius: 8px 8px 0px 0px;
    }
  }
  .room_info {
    width: 100%;
    display: inline-block;
    font-size: 18px;
    span {
      margin-left: 0.2rem;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; //元素几行显示
      -webkit-box-orient: vertical;
    }
  }
  .bottom {
    .room_btn {
      // float: right;
      padding-bottom: 0.2rem;
      display: inline-block;
      .van-button {
        float: right;
        color: white;
        width: 1.5rem;
        height: 0.6rem;
        background: #f6f9ff linear-gradient(144deg, #a02176 0%, #4532e2 100%);
        border-radius: 28px;
      }
      width: 60%;
    }
    .price {
      float: left;
      width: 30%;
      line-height: 0.76rem;
      display: inline-block;
      margin-left: 5%;
      margin-bottom: 0.3rem;
      color: #ff6327;
    }
  }
}
</style>
