<template>
  <div id='orderDtra'
       :style="{height:innerHeight}">
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="header">
      <div class="swipeColor">
        <div>
          <img src="@/assets/HotelImg/confirm_icon.png"
               alt="">
          <span>等待确认</span>
        </div>
      </div>
      <div class="block">
        <div>卖家一般会在5分钟内为您反馈结果，请耐心等待～</div>
        <div class="btn">
          <van-row type="flex"
                   justify="space-between">
            <van-col span="6">
              <van-button type="primary"
                          plain
                          @click="goHotelList">酒店列表</van-button>
            </van-col>
            <van-col span="6">
              <van-button type="primary"
                          plain
                          @click="goOrderDetail">订单详情</van-button>
            </van-col>
            <van-col span="6">
              <van-button type="primary"
                          color='#36E0C9'>联系我们</van-button>
            </van-col>
          </van-row>
        </div>

      </div>
      <div class="bottom">
        <img src="@/assets/HotelImg/vip_banner.png"
             alt="">
      </div>
    </div>
  </div>
</template>
<script>
// import NavBar from '../../../components/NavBar.vue'
export default {
  components: {
    // NavBar,
  },
  data () {
    return {
      NavBarTitle: '订单详情',
      innerHeight: 0
    }
  },
  created () {
  },
  mounted () {
    this.innerHeight = window.innerHeight + 'px'
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    //点击酒店列表按钮
    goHotelList () {
      this.$router.push({ path: '/allHotel/HotelList' })
    },
    //点击订单详情按钮
    goOrderDetail () {
      this.$router.push({ path: '/allHotel/HotelOrderDetail' })
    }
  }
}
</script>
<style lang="less" scoped>
#orderDtra {
  background-color: #f5f5f5;
  // height: 14rem;
  .header {
    width: 100%;
    height: 3.7037rem;
    position: relative;
    .swipeColor {
      width: 100%;
      height: 4rem;
      background: linear-gradient(to top, white, #36e0c9 30%, #36e0c9);
      div {
        position: relative;
        padding-top: 0.5rem;
        span {
          font-size: 0.45rem;
          color: white;
          margin-left: 0.6rem;
        }
        img {
          position: absolute;
          width: 6%;
          bottom: 10%;
        }
      }
    }
    .block {
      background-color: white;
      font-size: 0.27rem;
      width: 90%;
      height: 2rem;
      border-radius: 7px;
      padding: 0.2rem 0.1rem;
      margin-top: -1.5rem;
      margin-left: 0.25rem;
      .btn {
        margin-top: 0.6rem;
        width: 90%;
        margin-left: 0.2rem;
        .van-button--normal {
          width: 122%;
          border-radius: 50px;
          height: 164%;
          color: #36e0c9;
          border: 1px solid #36e0c9;
          font-size: 0.25rem;
        }
      }
    }
    .bottom {
      padding: 0 0.2rem;
      margin-top: 0.4rem;
      img {
        width: 98%;
      }
    }
  }
}
</style>