import {
  request
} from './request.js'


//热门地址
export function getHotAddress() {
  return request({
    url: '/common/tour-area/search',
    method: 'get'
  })
}
//添加收货地址
export function postAddress(params) {
  return request({
    url: '/home/user/setting/address',
    method: 'POST',
    params
  })
}
//收货地址列表
export function getAddress(params) {
  return request({
    url: '/home/user/setting/address',
    method: 'GET',
    params
  })
}
//编辑收货地址
export function EditAddress(params) {
  return request({
    url: '/home/user/setting/address',
    method: 'PUT',
    params
  })
}
// 删除收货地址
export function DelAddress(params) {
  return request({
    url: '/home/user/setting/address',
    method: 'DELETE',
    params
  })
}
//地区三级信息
export function Address_three(params) {
  return request({
    url: '/common/get-area-detail',
    method: 'GET',
    params
  })
}