<template>
  <div class="container">
    <div class="title">观视界平台用户充值协议</div>
    <div class="content">
      <p>欢迎您使用观视界提供的公众号充值余额服务！
        本协议是经过观视界认证的用户（下称“认证会员”）与观视界之间关于虚拟货币“余额”使用及使用观视界提供的增值服务所订立的协议。
        在成为认证会员并使用本协议约定充值服务之前，您须先成为观视界普通注册用户之后，才能进一步认证为观视界的认证会员，通过充值程序购买观视界平台发行的虚拟货币，即“余额”后，方可通过消费余额购买观视界平台的增值服务。</p>
    </div>

    <div class="tab">1. 服务条款的接受与修改</div>
    <div class="content">
      <div class="tab">1.1</div>
      <p>
        本协议的条款可由观视界随时变更，用户须定期审阅本协议。本协议条款一旦发生变动，观视界将在相关页面上提示变更内容或以其他方式进行公告。变更后的协议一经公布即有效代替原来的协议。用户如不同意观视界对本协议所作的任何变更，应立即停止使用观视界提供的相关服务。如用户在本协议变更后继续使用观视界相关服务的，即视为用户已完全同意变更后的协议
      </p>

      <div class="tab">1.2</div>
      <p>您确认，在选择本协议项下虚拟货币充值服务时，您应当是具备民事行为能力和拥有完全民事权利能力的自然人。若您是未成年人，应在法定监护人陪同下审阅和履行。您在享受观视界平台提供的增值服务时必须完全、严格遵守本协议条款</p>
    </div>
    <div class="tab">2. 服务说明</div>
    <div class="content">
      <div class="tab">2.1</div>
      <p>
        观视界享有对您使用本协议充值服务及使用平台增值服务时的一切活动的监督、提示、检查的权利，如果您的行为违反有关法律法规或违反本协议条款的规定，观视界有权要求您纠正及追究您责任的权利。给观视界造成损失的，观视界有权要求其承担相应的赔偿责任。
      </p>

      <div class="tab">2.2</div>
      <p>
        观视界向您提供的全部服务，均仅限于您在观视界平台中使用，任何以恶意破坏等非法手段将观视界提供的服务内容与观视界平台分离的行为，均不属于本协议中约定的观视界提供的服务。由此引起的一切法律后果均由行为人负责，观视界将依法追究行为人的法律责任。
      </p>
      <div class="tab">2.3</div>
      <p>当您使用观视界平台提供的各单项服务时，您的使用行为视为您对各单项服务的服务条款以及观视界在该单项服务中发出各类公告的同意。</p>
      <div class="tab">2.4</div>
      <p>
        您明确了解并同意，您的充值方式为代收费运营商托收的付款方式，您通过此种付费方式付费可能存在一定的商业风险，包括但不限于不法分子利益您账户或银行卡等有价卡等进行违法活动，该等风险均会给您造成相应的经济损失。您应自行承担向侵权方追究侵权责任和追究责任不能的后果。
      </p>
      <div class="tab">2.5</div>
      <p>
        您应自行负责妥善且正确地保管、使用、维护在观视界平台申请取得的账号、账号信息及账号密码。您应对其账号信息和账号密码采取必要和有效的保密措施。非观视界原因致使您账号密码泄漏以及因您保管、使用、维护不当造成损失的，观视界无须承担与此有关的任何责任。同时，为了保护您的安全和隐私，请勿与他人共享账号，请勿将您的账号和密码告诉他人，否则观视界有权对异常使用的账号进行冻结。
      </p>
    </div>
    <div class="tab">3. 充值</div>
    <div class="content">
      <div class="tab">3.1</div>
      <p>
        您理解并同意，观视界有权免费或要求付费提供其平台的服务。您通过充值所得的虚拟货币“余额”，可用于观视界平台提供的增值服务，除此之外，不得用于其他任何用途。该等增值服务的价格均以余额为单位，具体价格信息将由观视界自行决定并在相关服务页面上显示。
      </p>

      <div class="tab">3.2</div>
      <p>在观视界平台支付系统中，用户通过所用客户端（包括但不限于手机、网站及其他第三方支付渠道）相应支付方式（具体支付方式请见充值页面）为其账号充值获得余额。
        1余额=人民币【1.0】元。
        观视界有权根据运营情况随时变更上述兑换比例，并将在用户购买余额相关渠道服务页面显示。 请用户认准平台官方充值平台为唯一充值平台，由用户通过其他非官方渠道进行充值而造成的充值异常等一切不利后果，由用户自行承担。</p>
      <div class="tab">3.3</div>
      <p>用户仅有在成为观视界认证会员后，方可进行余额购买（下称“充值”）和消费。用户可在设置页面查询到余额、购买记录和消费记录。余额相关信息将不作为公开信息。</p>
    </div>
    <div class="tab">4. 充值</div>
    <div class="content">
      <div class="tab">4.1</div>
      <p>用户在确认进行充值前应仔细确认过自己的账号并仔细选择了相关操作选项，若因用户自身输入账号错误、操作不当或不了解充值计费方式等造成充错账号、错选充值种类等情形而损害自身权益的，观视界将不会作出任何补偿或赔偿。</p>

      <div class="tab">4.2</div>
      <p>用户确认，余额一经充值成功，除法律法规明确规定外，在任何情况下不能兑换为法定货币，不能转让他人。除法律法规明确规定外，余额账户充值完成后，观视界不予退款。</p>
      <div class="tab">4.3</div>
      <p>
        用户确认，余额只能用于购买观视界平台上的各类增值服务，任何情况下不得与观视界以外的第三方进行余额交易，亦不得在除观视界平台外的第三方平台进行交易；如违反前述约定，造成用户或第三方任何损失，观视界不负任何责任，且如观视界有理由怀疑用户的余额账户或使用情况有作弊或异常情况，观视界将拒绝该用户使用余额进行支付，直至本协议约定采用相关封禁措施。
      </p>
      <div class="tab">4.4</div>
      <p>观视界有权基于交易安全等方面的考虑不时设定或修改设计交易的相关事项，包括但不限于交易限额、交易次数等。用户了解并确认观视界的前述设定或修改可能对用户的交易产生一定的不便，用户对此没有异议。</p>
      <div class="tab">4.5</div>
      <p>用户确认，除法律法规明确规定或本协议另有约定外，用户已购买的任何收费服务不能以任何理由退购（即退换成余额或法定货币）或调换成其他服务。</p>
      <div class="tab">4.6</div>
      <p>观视界不鼓励未成年人使用虚拟货币服务，未成年人应请监护人操作或在监护人明示同意下操作，否则不得使用本服务。</p>
      <div class="tab">4.7</div>
      <p>观视界不鼓励未成年人使用虚拟货币服务，未成年人应请监护人操作或在监护人明示同意下操作，否则不得使用本服务。</p>
      <div class="tab">4.7.1</div>
      <p>观视界不鼓励未成年人使用虚拟货币服务，未成年人应请监护人操作或在监护人明示同意下操作，否则不得使用本服务。</p>
      <div class="tab">4.7.2</div>
      <p>用户将密码告知他人导致的财产损失；</p>
      <div class="tab">4.7.3</div>
      <p>因用户绑定的第三方支付机构账户的原因导致的任何损失或责任；</p>
      <div class="tab">4.7.4</div>
      <p>其他用户故意或者重大过失或者违反法律法规导致的财产损失。</p>
      <div class="tab">4.8</div>
      <p>
        用户在使用观视界提供的服务时，如出现违反国家法律法规、本协议约定或其他本平台对用户的管理规定的情形，观视界有权暂时或永久封禁用户的账号。账号封禁后至解禁（如有）前，用户账户上的剩余余额将被暂时冻结或全部扣除，不可继续用于购买平台上的虚拟产品或服务，同时不予返还用户购买余额时的现金价值。
      </p>
      <div class="tab">4.9</div>
      <p>用户确认并同意如用户主动注销账号，则用户已充值到账的余额，购买的会员权益等视为自动放弃，观视界不予返还相应的现金价值，也不会作出任何补偿。</p>
      <div class="tab">4.10</div>
      <p>若因观视界平台充值系统自身故障原因造成用户充值失实，在平台恢复、存有有效数据和用户提供合法有效凭证的情况下，观视界平台将根据用户充值情况作出变动措施。</p>
      <div class="tab">4.10.1</div>
      <p>因充值系统自身故障原因，造成系统充值额小于用户实际充值额的，平台予以补其差额。</p>
      <div class="tab">4.10.2</div>
      <p>因充值系统自身故障原因，造成系统充值额大于用户实际充值额的，平台有权追回差额。</p>
    </div>

    <div class="tab">5. 免责声明</div>
    <div class="content">
      <div class="tab">5.1</div>
      <p>观视界平台系统因下列状况无法正常运作，使您无法使用各项服务或任何虚拟财产丢失时，观视界不承担损害赔偿责任，该状况包括但不限于：</p>
      <div class="tab">5.1.1</div>
      <p>在观视界平台公告之系统停机维护、升级、调整期间；</p>
      <div class="tab">5.1.2</div>
      <p>电信通讯或设备出现故障不能进行数据传输的；</p>
      <div class="tab">5.1.3</div>
      <p>因台风、地震、海啸、洪水、停电、战争、恐怖袭击、政府管制等不可抗力之因素，造成观视界平台系统障碍不能执行业务的；</p>
      <div class="tab">5.1.4</div>
      <p>由于黑客攻击、电信部门技术调整或故障、网站升级、相关第三方的问题等原因而造成的服务中断或者延迟。</p>
    </div>
    <div class="tab">6. 免责声明</div>
    <div class="content">
      <div class="tab">6.1</div>
      <p>如发生下列任何一种情形，观视界有权随时中断或终止向您提供本协议项下的服务而无需通知您：</p>
      <div class="tab">6.1.1</div>
      <p>您提供的个人认证资料不真实；</p>
      <div class="tab">6.1.2</div>
      <p>您违反本协议中的任一规定。除前述所述情形外，观视界同时保留在不事先通知您的情况下随时中断或终止部分或全部网络充值服务的权利，对该账户采取包括但不限于封禁、账户余额清零等措施，对于充值服务的中断或终止而造成的任何损失，观视界无需对您或任何第三方承担任何责任。</p>
      <div class="tab">6.2</div>
      <p>如果观视界发现或收到他人举报或投诉用户违反本协议约定的，观视界有权不经通知随时对相关内容，包括但不限于用户资料、聊天记录进行审查、删除，并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁、设备封禁、功能封禁、扣除余额的处罚，且通知用户处理结果</p>
      <div class="tab">6.3</div>
      <p>因违反用户协议被封禁的用户，可以自行联系观视界平台客服查询封禁期限，并在封禁期限届满后自助解封。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。被封禁用户可向观视界网站相关页面提交申诉，观视界将对申诉进行审查，并自行合理判断决定是否变更处罚措施。</p>
      <div class="tab">6.4</div>
      <p>用户理解并同意，观视界有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。</p>
      <div class="tab">6.5</div>
      <p>用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿观视界与合作公司、关联公司，并使之免受损害。</p>
    </div>
    <div class="tab">7. 生效及其他</div>
    <div class="tab">7.1</div>
    <div class="content">
      <p>本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和观视界之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交观视界住所地有管辖权的人民法院管辖。</p>
      <div class="tab">7.2</div>
      <p>本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
      <div class="tab">7.3</div>
      <p>用户如有任何需要说明条款的要求，请立即停止使用服务，同时立即发送邮件至观视界官方客服人员（邮箱地址:yangchunyan@guansj.com ），若用户未发送内含说明条款要求的邮件至观视界官方客服人员而选择同意该协议，则双方在此确认观视界已依法履行了根据用户要求对相关条款进行说明的法定义务，观视界已给予用户充足的时间与充分的选择权来决定是否缔结本协议。</p>
      <div class="tab">7.4</div>
      <p>在充值过程中遇到的与充值相关的问题可联系观视界微信客服:liu-vipss</p>
      <p></p>

    </div>
    <div class="btn">
      <van-button @click="go_balance">已阅读</van-button>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    go_balance () {
      this.$router.push({ path: '/allPerInfo/balance', query: { agreement: 'agreement' } })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;

  .title {
    margin-top: 0.2222rem;
    font-size: 0.5185rem;
    margin-bottom: 12px;
    text-align: center;
  }

  .tab {
    font-size: 0.3333rem;
    text-align: left;
    margin-left: 0.2222rem;
    display: block;
  }

  .content {
    .tab {
      display: block;
      color: #898989;
      margin-left: 0;
    }

    p {
      text-align: left;
      color: #898989;
      margin-top: 0;
      display: flex;
    }

    font-size: 0.2593rem;
    padding: 0 0.3704rem;
  }
  .btn {
    .van-button {
      width: 90%;
      border-radius: 20px;
      margin-bottom: 20px;
      color: aquamarine;
    }
  }
}
</style>