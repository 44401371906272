<template>
  <div id="vipHome"
       :style="{ height: innerHeight }">
    <!-- <NavBar NavBarTitle="会员权益"
            @onClickLeft="onClickLeft"></NavBar> -->
    <van-search v-model="searchValue"
                shape="round"
                placeholder="搜索更多优惠品牌" />
    <div class="header">
      <div class="headerImg">
        <!-- <img src="@/assets/shopImg/shop_banner1.png"
             alt=""> -->
        <van-swipe class="my-swipe"
                   :autoplay="3000"
                   indicator-color="white">
          <van-swipe-item>
            <img style="width:100%"
                 src="@/assets/vipImg/banner1.jpg"
                 alt="" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="@/assets/vipImg/banner2.jpg"
                 alt="" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="@/assets/vipImg/banner3.jpg"
                 alt="" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="icon">
        <div class="title">特惠出行</div>
        <van-row>
          <van-col span="6">
            <img src="@/assets/vipImg/lvyou_icon@3x.png"
                 alt="" />
            <div>旅游</div>
          </van-col>
          <van-col span="6">
            <img src="@/assets/vipImg/jiudian_icon@3x.png"
                 alt="" />
            <div>酒店</div>
          </van-col>
          <van-col span="6">
            <img src="@/assets/vipImg/VRmingsu_icon@3x.png"
                 alt="" />
            <div>VR民宿</div>
          </van-col>
          <van-col span="6">
            <img src="@/assets/vipImg/ji+jiu_icon@3x.png"
                 alt="" />
            <div style="margin-left:0.2rem">机+酒</div>
          </van-col>
        </van-row>
      </div>
    </div>
    <div id="topNav">
      <!-- <van-row swipeable>
        <van-col span="6"
                 style="padding:0.1rem 0rem"
                 v-for="(item,index) in topTitle"
                 :key="item.id"
                 :class="item.isclick==true?'is_scroll_active':''"
                 @click="changeScorll(item,index)">
          <span>{{item.title}}</span>
        </van-col>
      </van-row> -->
      <van-tabs v-model="active"
                scrollspy
                sticky>
        <van-tab v-for="index in topTitle"
                 :key="index"
                 :title="index">
          <div class="vipContent">
            <div class="prl"
                 v-if="index === '视频会员'">
              <div class="title">视频会员</div>
              <van-row>
                <van-col span="4"
                         v-for="item in vipVideoList"
                         :key="item.id"
                         @click="getPriv(item)">
                  <img :src="item.logo"
                       :onerror="defaultImg"
                       alt="" />
                  <div>{{ item.name }}</div>
                </van-col>
                <!-- <van-col span="4"
                         @click="getMore(1)">
                  <van-icon name="more-o" />
                  <div style="margin-top:0rem;color:gray">更多</div>
                </van-col> -->
              </van-row>
              <div class="thinner-border"></div>
            </div>
            <div class="qq"
                 v-if="index === 'QQ特权'">
              <div class="title">QQ特权</div>
              <van-row>
                <van-col span="4"
                         v-for="item in vipQQList"
                         :key="item.id"
                         @click="getPriv(item)">
                  <img :src="item.logo"
                       :onerror="defaultImg"
                       alt="" />
                  <div>{{ item.name }}</div>
                </van-col>
                <!-- <van-col span="4"
                         @click="getMore(1)">
                  <van-icon name="more-o" />
                  <div style="margin-top:0rem;color:gray">更多</div>
                </van-col> -->
              </van-row>
              <div class="thinner-border"></div>
            </div>
            <div class="book"
                 v-if="index === '知识阅读'">
              <div class="title">知识阅读</div>
              <van-row>
                <van-col span="4"
                         v-for="item in vipBookList"
                         :key="item.id"
                         @click="getPriv(item)">
                  <img :src="item.logo"
                       alt="" />
                  <div>{{ item.name }}</div>
                </van-col>
                <!-- <van-col span="4"
                         @click="getMore(1)">
                  <van-icon name="more-o" />
                  <div style="margin-top:0rem;color:gray">更多</div>
                </van-col> -->
              </van-row>
              <div class="thinner-border"></div>
            </div>
            <div class="play"
                 v-if="index === '生活娱乐'">
              <div class="title">生活娱乐</div>
              <van-row>
                <van-col span="4"
                         v-for="item in vipPlayList"
                         :key="item.id"
                         @click="getPriv(item)">
                  <img :src="item.logo"
                       :onerror="defaultImg"
                       alt="" />
                  <div>{{ item.name }}</div>
                </van-col>
                <!-- <van-col span="4"
                         @click="getMore(1)">
                  <van-icon name="more-o" />
                  <div style="margin-top:0rem;color:gray">更多</div>
                </van-col> -->
              </van-row>
              <div class="thinner-border"></div>
            </div>
            <div class="Travel"
                 v-if="index === '出行优惠'">
              <div class="title">出行优惠</div>
              <van-row>
                <van-col span="4"
                         v-for="item in vipTravelList"
                         :key="item.id"
                         @click="getPriv(item)">
                  <img :src="item.logo"
                       alt="" />
                  <div>{{ item.name }}</div>
                </van-col>
                <!-- <van-col span="4"
                         @click="getMore(1)">
                  <van-icon name="more-o" />
                  <div style="margin-top:0rem;color:gray">更多</div>
                </van-col> -->
              </van-row>
              <div class="thinner-border"></div>
            </div>
            <div class="pay "
                 v-if="index === '充值优惠'">
              <div class="title">充值优惠</div>
              <van-row>
                <van-col span="4"
                         v-for="item in vipPayList"
                         :key="item.id"
                         @click="getPriv(item)">
                  <img :src="item.logo"
                       :onerror="defaultImg"
                       alt="" />
                  <div>{{ item.name }}</div>
                </van-col>
                <!-- <van-col span="4"
                         @click="getMore(1)">
                  <van-icon name="more-o" />
                  <div style="margin-top:0rem;color:gray">更多</div>
                </van-col> -->
              </van-row>
              <div class="thinner-border"></div>
            </div>
            <div class="hotel"
                 v-if="index === '酒店民宿'">
              <div class="title">酒店民宿</div>
              <van-row>
                <van-col span="4"
                         v-for="item in vipHotelList"
                         :key="item.id"
                         @click="getPriv(item)">
                  <img :src="item.logo"
                       alt="" />
                  <div>{{ item.name }}</div>
                </van-col>
                <!-- <van-col span="4"
                         @click="getMore(1)">
                  <van-icon name="more-o" />
                  <div style="margin-top:0rem;color:gray">更多</div>
                </van-col> -->
              </van-row>
              <div class="thinner-border"></div>
            </div>
            <div class="ocv"
                 v-if="index === '线上卡卷'">
              <div class="title">线上卡券</div>
              <van-row>
                <van-col span="4"
                         v-for="item in OnlineCardVoucher"
                         :key="item.id"
                         @click="getPriv(item)">
                  <img :src="item.logo"
                       :onerror="defaultImg"
                       alt="" />
                  <div>{{ item.name }}</div>
                </van-col>
                <!-- <van-col span="4"
                         @click="getMore(0)">
                  <van-icon name="more-o" />
                  <div style="margin-top:0rem;color:gray">更多</div>
                </van-col> -->
              </van-row>
              <div class="thinner-border"></div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!-- <div id="topNav"
         v-if="is_Fix"
         :class="{'isFixed':is_Fix}">
      <van-row>
        <van-col span="12"
                 style="padding:0.1rem 0rem"
                 v-for="(item,index) in topTitle"
                 :key="item.id"
                 :class="item.isclick==true?'is_scroll_active':''"
                 @click="changeScorll(item,index)">
          <spa>{{item.title}}</spa n>
        </van-col>
      </van-row>
    </div> -->
  </div>
</template>

<script>
import { getVIPList } from '../../../api/vip'
import { aesKey } from '../../../commom/aesKey'
// import NavBar from '../../../components/NavBar.vue'

export default {
  name: "imgError",
  components: {
    // NavBar
  },
  data () {
    return {
      defaultImg: 'this.src="' + require('@/assets/vipImg/default.png') + '"',//默认图地址
      searchValue: '', //搜索框的内容
      innerHeight: '',
      page1: 1, //优惠充值页数
      page2: 1, //线上卡券页数
      OnlineCardVoucher: [], //线上卡券特权数据
      vipVideoList: [], //视频特权数据
      vipQQList: [], //QQ特权数据
      vipBookList: [], //知识阅读数据
      vipPlayList: [], //生活娱乐数据
      vipTravelList: [], //出行优惠数据
      vipPayList: [], //充值优惠数据
      vipHotelList: [], //酒店民宿数据
      active: 0, //标签页active
      aesKey: aesKey(), //接口通过密文来获取数据
      is_Fix: false, //导航栏是否吸附
      topTitle: [
        '视频会员',
        'QQ特权',
        '知识阅读',
        '生活娱乐',
        '出行优惠',
        '充值优惠',
        '酒店民宿',
        '线上卡卷'
      ] //对应后台特权产品顺序1-8
    }
  },
  computed: {

  },

  mounted () {
    window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    this.innerHeight = window.innerHeight + 'px'
    //线上卡券特权数据
    let data1 = {
      privilege_type: 0
    }
    this.aesKey = aesKey(data1)
    this.getOCVList(this.aesKey)
    //视频会员特权数据
    let data2 = {
      privilege_type: 1
    }
    this.aesKey = aesKey(data2)
    this.getVideoList(this.aesKey)

    //QQ特权
    let data3 = {
      privilege_type: 2
    }
    this.aesKey = aesKey(data3)
    this.getQQList(this.aesKey)
    //知识阅读
    let data4 = {
      privilege_type: 3
    }
    this.aesKey = aesKey(data4)
    this.getBookList(this.aesKey)
    //生活娱乐
    let data5 = {
      privilege_type: 4
    }
    this.aesKey = aesKey(data5)
    this.getPlayList(this.aesKey)
    //出行优惠
    let data6 = {
      privilege_type: 5
    }
    this.aesKey = aesKey(data6)
    this.getTraveList(this.aesKey)
    //充值优惠
    let data7 = {
      privilege_type: 6
    }
    this.aesKey = aesKey(data7)
    this.getPayList(this.aesKey)
    //酒店民宿
    let data8 = {
      privilege_type: 7
    }
    this.aesKey = aesKey(data8)
    this.getHotelList(this.aesKey)
  },
  methods: {
    //线上卡券特权数据
    getOCVList (data) {
      getVIPList(data).then(res => {
        let dataArr = res.data.data.list.map(item => {
          return {
            id: item.privilege_id,
            name: item.name,
            logo: item.logo
          }
        })
        this.OnlineCardVoucher = this.OnlineCardVoucher.concat(dataArr)
      })
    },
    //视频特权数据
    getVideoList (data) {
      getVIPList(data).then(res => {
        let dataArr = res.data.data.list.map(item => {
          return {
            id: item.privilege_id,
            name: item.name,
            logo: item.logo
          }
        })
        this.vipVideoList = this.vipVideoList.concat(dataArr)
      })
    },
    //QQ特权数据
    getQQList (data) {
      getVIPList(data).then(res => {
        let dataArr = res.data.data.list.map(item => {
          return {
            id: item.privilege_id,
            name: item.name,
            logo: item.logo
          }
        })
        this.vipQQList = this.vipQQList.concat(dataArr)
      })
    },
    //知识阅读数据
    getBookList (data) {
      getVIPList(data).then(res => {
        let dataArr = res.data.data.list.map(item => {
          return {
            id: item.privilege_id,
            name: item.name,
            logo: item.logo
          }
        })
        this.vipBookList = this.vipBookList.concat(dataArr)
      })
    },
    //生活娱乐数据
    getPlayList (data) {
      getVIPList(data).then(res => {
        let dataArr = res.data.data.list.map(item => {
          return {
            id: item.privilege_id,
            name: item.name,
            logo: item.logo
          }
        })
        this.vipPlayList = this.vipPlayList.concat(dataArr)
      })
    },
    //出行优惠数据
    getTraveList (data) {
      getVIPList(data).then(res => {
        let dataArr = res.data.data.list.map(item => {
          return {
            id: item.privilege_id,
            name: item.name,
            logo: item.logo
          }
        })
        this.vipTravelList = this.vipTravelList.concat(dataArr)
      })
    },
    //充值优惠数据
    getPayList (data) {
      getVIPList(data).then(res => {
        let dataArr = res.data.data.list.map(item => {
          return {
            id: item.privilege_id,
            name: item.name,
            logo: item.logo
          }
        })
        this.vipPayList = this.vipPayList.concat(dataArr)
      })
    },
    //酒店民宿数据
    getHotelList (data) {
      getVIPList(data).then(res => {
        let dataArr = res.data.data.list.map(item => {
          return {
            id: item.privilege_id,
            name: item.name,
            logo: item.logo
          }
        })
        this.vipHotelList = this.vipHotelList.concat(dataArr)
      })
    },

    //滚动条
    //滚动条滚动时有动画效果
    // scrollAnimation (currentY, targetY) {
    //   //currentY当前位置     targetY目标位置
    //   // 计算需要移动的距离
    //   let needScrollTop = targetY - currentY
    //   let _currentY = currentY
    //   setTimeout(() => {
    //     // 一次调用滑动帧数，每次调用会不一样
    //     const dist = Math.ceil(needScrollTop / 10)
    //     _currentY += dist
    //     window.scrollTo(_currentY, currentY)
    //     // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
    //     if (needScrollTop > 10 || needScrollTop < -10) {
    //       this.scrollAnimation(_currentY, targetY)
    //     } else {
    //       window.scrollTo(_currentY, targetY)
    //     }
    //   }, 1)
    // },
    // 获取滚动条的滚动距离
    // handleScroll () {
    //   let scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop // 滚动条偏移量
    //   let topNavTop = document.querySelector('#topNav').offsetTop
    //   this.is_Fix = scrollTop > topNavTop ? true : false // 如果滚动到顶部了，this.isFixed就为true

    //   let prlTop = document.querySelector('.prl').offsetTop - 20 //视频会员列表到顶部的高度
    //   let ocvTop = document.querySelector('.ocv').offsetTop - 20 //线上卡券列表到顶部的高度
    //   // let qqTop = document.querySelector('.qq').offsetTop - 20 //线上卡券列表到顶部的高度

    //   // console.log(scrollTop)
    //   // console.log(prlTop, ocvTop)
    //   if (scrollTop >= prlTop && scrollTop <= ocvTop) {
    //     this.topTitle.forEach(item => {
    //       item.isclick = false
    //     })
    //     this.topTitle[0].isclick = true
    //   } else if (scrollTop >= ocvTop) {
    //     this.topTitle.forEach(item => {
    //       item.isclick = false
    //     })
    //     this.topTitle[1].isclick = true
    //   }
    // },
    //顶部导航点击
    // changeScorll (item, index) {
    //   let scrollTop =
    //     document.documentElement.scrollTop || document.body.scrollTop
    //   let prlTop = document.querySelector('.prl').offsetTop
    //   let ocvTop = document.querySelector('.prl').offsetTop
    //   if (index == 0) {
    //     this.scrollAnimation(scrollTop, prlTop)
    //   } else if (index == 1) {
    //     this.scrollAnimation(scrollTop, ocvTop)
    //   }
    //   this.topTitle.forEach(item2 => {
    //     item2.isclick = false
    //   })
    //   item.isclick = true
    // },

    //更多按钮
    getMore (idx) {
      // if (idx == 0) {
      //   console.log(123)
      //   let res = await getVIPList()
      // }
      if (idx == 1) {
        this.page1++
        let data = {
          privilege_type: 1,
          page: this.page1
        }
        this.aesKey = aesKey(data)
        this.getPRList(this.aesKey)
      } else if (idx == 0) {
        this.page2++
        let data2 = {
          privilege_type: 0,
          page: this.page2
        }
        this.aesKey = aesKey(data2)
        this.getOCVList(this.aesKey)
      }
    },
    //每个特权产品点击
    getPriv (data) {
      // console.log(data)
      this.$router.push({
        path: '/allVip/vipSpec',
        query: {
          privilege_id: data.id
        }
      })
    },

    onClickLeft () {
      this.$router.go(-1)
    }
  },
  beforeCreate () {
    //因公共样式less,会导致当前页面会继承上一个页面样式,后期项目脚手架搭建用scss
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
  },
}
</script>
<style lang="less" scoped>
#vipHome {
  background-color: #f6f3f7;
  .header {
    padding: 0 0.3rem;
    font-size: 0.3rem;
    background-color: white;
    padding-bottom: 0.3rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .headerImg {
      .van-swipe {
        border-radius: 20px;
        .van-swipe-item {
          height: 2.5rem;
          overflow: hidden;
        }
        img {
          width: 100% !important;
        }
      }
    }
    .icon {
      margin-top: 0.2rem;
      .title {
        font-size: 0.34rem;
        font-weight: bold;
      }
      .van-row {
        font-size: 0.25rem;
        margin-top: 0.2rem;
        img {
          width: 55%;
        }
      }
    }
  }
  #topNav {
    background-color: white;
    width: 100%;
    font-size: 0.25rem;
    // position: fixed;
    // z-index: 200;
    // top: 0%;
    // opacity: 0;
    /deep/.van-col {
      span {
        display: inline-block;
        padding-bottom: 0.1rem;
      }
    }
    .is_scroll_active {
      span {
        color: #dea260;
        border-bottom: 2px solid #dea260;
      }
    }
  }
  #topNav.isFixed {
    position: fixed;
    z-index: 10;
    top: 0%;
  }
  .vipContent {
    margin-top: 0.1rem;
    text-align: left;
    font-size: 0.3rem;
    background-color: white;
    padding: 0 0.3rem;
    .van-row {
      padding-bottom: 0.3rem;
      .van-col {
        width: 25%;
        margin-top: 0.1rem;
        text-align: center;
        img {
          width: 55%;
        }
        div {
          margin-top: -0.1rem;
          font-size: 0.23rem;
        }
        .van-icon {
          font-size: 0.6rem;
          color: gray;
          margin-top: 0.3rem;
        }
      }
    }
  }
}
.thinner-border {
  position: relative;
  width: 100%;
  height: 0.2rem;
}
.thinner-border:after {
  content: "";
  position: absolute;
  width: 1000%;
  border: 1px solid #979797;
  transform-origin: 0 0;
  transform: scale(0.1, 0.1);
  box-sizing: border-box;
  margin-top: -0.05rem;
}
</style>
