<template>
  <div class="container">
    <div class="bg"></div>
    <div class="top">
      <div class="search"
           id="search">
        <form action="/">
          <van-search v-model="HomeSearchValue"
                      @search="goHotelSearch(HomeSearchValue)"
                      left-icon=""
                      shape="round"
                      placeholder="请输入搜索关键词" />

        </form>
        <van-button size="small"
                    class="search_btn"
                    @click="goHotelSearch(HomeSearchValue)"
                    color="linear-gradient(to right, #00ECAF, #5EE3DE)">搜索</van-button>
      </div>
    </div>
    <div class="hisContent">
      <div v-for="item in  HomeContent"
           :key="item"
           style="padding:5px 10px "
           @click="getContent(item)">{{item}}</div>
    </div>
    <div class="banner">
      <van-swipe class="my-swipe"
                 width="auto"
                 :autoplay="3000"
                 indicator-color="white">
        <van-swipe-item>
          <img src="@/assets/img/banner1@2x.png"
               alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/img/banner2@2x.png"
               alt="" />
        </van-swipe-item>
        <van-swipe-item>
          <img src="@/assets/img/banner4@2x.png"
               alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div style="margin-left: -0.2rem; margin-top: 0.7rem; z-index: 10">
      <van-grid :column-num="5"
                :border="false">
        <van-grid-item>
          <router-link :to="{ name: 'Hotel' }"
                       class="router">
            <img src="@/assets/HomeIcon/hotel.png"
                 alt="" />
            <p>酒店</p>
          </router-link>
        </van-grid-item>
        <van-grid-item>
          <router-link :to="{ name: 'vipHome' }"
                       class="router">
            <img src="@/assets/HomeIcon/VVIP.png"
                 alt="" />
            <p>会员特权</p>
          </router-link>
        </van-grid-item>
        <van-grid-item>
          <router-link :to="{ name: 'Hotel' }"
                       class="router">
            <img src="@/assets/HomeIcon/VR.png"
                 alt="" />
            <p>VR民宿</p>
          </router-link>
        </van-grid-item>
        <van-grid-item>
          <router-link :to="{ name: 'shopHome' }"
                       class="router">
            <img src="@/assets/HomeIcon/shop.png"
                 alt="" />
            <p>甄选商城</p>
          </router-link>
        </van-grid-item>
        <van-grid-item>
          <router-link :to="{ name: 'custom_home' }"
                       class="router">
            <img src="@/assets/HomeIcon/customTour.png"
                 alt="" />
            <p>定制游</p>
          </router-link>
        </van-grid-item>
        <!-- <van-grid-item>
          <router-link :to="{ name: 'neighboring_home' }"
                       class="router">
            <div class="small_img">
              <img src="@/assets/HomeIcon/travelAround.png"
                   alt="" />
            </div>
            <p>周边游</p>
          </router-link>
        </van-grid-item>
        <van-grid-item>
          <router-link :to="{ name: 'inland_home' }"
                       class="router">
            <div class="small_img">
              <img src="@/assets/HomeIcon/DomesticTour.png"
                   alt="" />
            </div>
            <p>国内游</p>
          </router-link>
        </van-grid-item>
        <van-grid-item>
          <router-link :to="{ name: 'Alloutbound_home' }"
                       class="router">
            <div class="small_img">
              <img src="@/assets/HomeIcon/OutboundTour.png"
                   alt="" />
            </div>
            <p>出境游</p>
          </router-link>
        </van-grid-item>
        <van-grid-item>
          <router-link :to="{ name: 'visa_home' }"
                       class="router">
            <div class="small_img">
              <img src="@/assets/HomeIcon/visa.png"
                   alt="" />
            </div>
            <p>签证</p>
          </router-link>
        </van-grid-item>
        <van-grid-item>
          <router-link :to="{ name: 'vipHome' }"
                       class="router">
            <div class="small_img">
              <img src="@/assets/HomeIcon/VIP.png"
                   alt=""
                   style="width: 58%" />
            </div>
            <p>会员权益</p>
          </router-link>
        </van-grid-item> -->
      </van-grid>
    </div>
    <div class="VIP">
      <div class="VIP4"
           @click="esport">

        <img src="@/assets/HomeIcon/baseMap1.png"
             alt="" />
      </div>
      <div class="VIP4"
           @click="goTest">
        <img src="@/assets/HomeIcon/baseMap2.png"
             alt="" />
      </div>
      <div class="VIP4">
        <img src="@/assets/HomeIcon/baseMap3.png"
             alt="" />
      </div>
    </div>
    <div class="VIPlevel">
      <img src="@/assets/HomeIcon/vip 12.png"
           alt="">
    </div>
    <div class="waterfall"
         style="padding-left: 0.1rem">
      <van-tabs v-model="activeName"
                @click="switchTab"
                type="card"
                title-active-color="white"
                id="boxFixed">
        <van-tab title="猜你喜欢"
                 name="first">
          <WaterFall :imgsArr="imgsArr"
                     @getwatId="getwatId"></WaterFall>
        </van-tab>
        <van-tab title="酒店客栈"
                 name="second">
          <WaterFall :imgsArr="imgsArr"
                     @getwatId="getwatId"></WaterFall>
        </van-tab>
        <van-tab title="国内游"
                 name="third"> </van-tab>
        <van-tab title="景点门票"
                 name="fourth"> </van-tab>
        <van-tab title="周边游"
                 name="fifth"> </van-tab>
      </van-tabs>
    </div>
    <div class="bottom">
      <van-tabbar v-model="bottomActive"
                  @change="switchBottomTab"
                  active-color="#58e3dd">
        <van-tabbar-item>
          <img v-if="bottomActive == 0"
               src="@/assets/HomeIcon/bottom1active.png"
               alt="" />
          <img v-else
               src="@/assets/HomeIcon/bottom1.png"
               alt="" />
          <div>首页</div>
        </van-tabbar-item>
        <van-tabbar-item icon="">
          <img v-if="bottomActive == 1"
               src="@/assets/HomeIcon/bottom2active.png"
               alt="" />
          <img v-else
               src="@/assets/HomeIcon/bottom2.png"
               alt="" />
          <div>目的地</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 2"
               src="@/assets/HomeIcon/bottom3active.png"
               alt="" />
          <img v-else
               src="@/assets/HomeIcon/bottom3.png"
               alt="" />
          <div>商城</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 3"
               src="@/assets/HomeIcon/bottom4active.png"
               alt="" />
          <img v-else
               src="@/assets/HomeIcon/bottom4.png"
               alt="" />
          <div>我的</div>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <div v-show="showMap">
      <baidu-map :scroll-wheel-zoom="true"
                 @ready="initMap"
                 ak='VFc7NsfU7amcxYbEiqypp5a3gHPLaiOO'
                 @click="handler">

      </baidu-map>
    </div>
  </div>
</template>

<script>
import coordtransform from 'coordtransform';
import { Address_three } from '@/api/address.js'
import VConsole from 'vconsole/dist/vconsole.min.js'
import { postLogin, getHomeData, postWeachatLogin } from "@/api/hotel.js";
import wx from 'weixin-js-sdk'
import { getJSSDK } from '@/api/JSSDk.js'
import { aesKey } from "@/commom/aesKey";
import WaterFall from "@/components/WaterFall.vue";
import { Toast } from 'vant';
import Bar_address from '@/utils/address'

export default {
  components: {
    WaterFall,
  },
  data () {
    return {
      showMap: false,
      url: '',
      code: "", //url取出的code
      // code: 123,//url取出的code
      swiperImg: [
        "@/assets/img/1.jpg",
        "@/assets/img/2.jpg",
        "@/assets/img/2.jpg",
      ],
      cityInfo: {
        child: Array(0),
        city_id: 29422,
        code: 441900000000,
        first: "D",
        level: 2,
        name: "东莞市",
        pcode: 440000000000,
      },
      activeName: "first",
      imgsArr: [],
      city: null,
      HomeSearchValue: "",
      bottomActive: 0,
      HomeContent: [],
      isFixed: false, //标签页是否固定
      is_searFix: false, //搜索框是否固定
      aesKey: aesKey(), //接口通过密文来获取数据
      page: 1, //页数
      pageCount: "", //可翻页的次数
      vipDiscount: null,//折扣价格
      latitude: null,
      longitude: null,
      cities: null,
      searchAddress: null
    };
  },
  created () {

    // this.refresh()
    // this.testConsole()
    this.url = window.location.href.split('#')[0]
    console.log(this.url, "=========this.url========");
    this.shareWechat()
    if (sessionStorage.getItem("dateArray") == null) {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      tomorrow.setDate(tomorrow.getDate() + 1);
      let dateArray = [today.getTime(), tomorrow.getTime()];
      sessionStorage.setItem("dateArray", dateArray);
      console.log(dateArray, "=====dateArray====");
    } else if (sessionStorage.getItem("dateArray") == '') {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      tomorrow.setDate(tomorrow.getDate() + 1);
      let dateArray = [today.getTime(), tomorrow.getTime()];
      sessionStorage.setItem("dateArray", dateArray);
      console.log(dateArray, "=====dateArray====");
    }
    this.getRoute()


    window.addEventListener('pageshow', function (event) {
      console.log('pageshow:');
      console.log(event.persisted);
    });
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      console.log(from.name, 'from')
      //  这里的vm指的就是vue实例，可以用来当做this使用
      vm.fromPath = from.path  //获取上一级路由的路径
      if (from.name == 'router_Attract') {
        vm.pathUrl = from.fullPath;
      }
    })
    // 在渲染该组件的对应路由被 confirm 前调用
    // 不！能！获取组件实例 `this`
    // 因为当守卫执行前，组件实例还没被创建
    // console.log(from);
    // next();
  },

  mounted () {
    this.goAttract_investment()
    this.setCityInfo()
    window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    sessionStorage.setItem("hotelRefresh", 1);
    sessionStorage.setItem("shopRefresh", 1);
    this.login();
    let data = {
      status: 1,
    };
    this.aesKey = aesKey(data);

    this.code = this.$route.query.code;
    if (this.code != undefined) {
      let loginData = {
        code: this.code,
        appid: "wxa68cd9e5c0b30448",
      };
      this.aesKey = aesKey(loginData);
      this.postWeachatLogin(this.aesKey);
    } else {
      setTimeout(() => {
        this.getHomeData();

      }, 500);
    }
    if (localStorage.getItem('HomeContent') == null) {
      this.HomeContent = []
    } else {
      this.HomeContent = JSON.parse(localStorage.getItem('HomeContent'))
    }
    if (this.HomeContent.length >= 5) {
      this.HomeContent.pop()
    }

  },
  // computed: {
  //   vipDiscount() {
  //     const wechatUser = localStorage.getItem("wechatUser")
  //       ? JSON.parse(localStorage.getItem("wechatUser"))
  //       : {};
  //     let discount = 1;
  //     if (wechatUser.vip === 1) {
  //       discount = 0.8;
  //     } else if (wechatUser.vip === 0) {
  //       discount = 1;
  //     }
  //     return discount;
  //   },
  // },
  methods: {



    getRoute () {
      let hotelUrl = sessionStorage.getItem('hotelUrl')
      if (hotelUrl != undefined) {
        this.$router.push({ path: hotelUrl })
      }
    },
    //跳转电竞板块
    esport () {
      this.$router.push({ path: '/allHotel/Esports' })
    },
    setCityInfo () {
      let sessionCityData = []
      sessionCityData = JSON.stringify(this.cityInfo)
      sessionStorage.setItem('sessionCityData', sessionCityData)
    },
    goAttract_investment () {
      console.log(this.pathUrl);
      // this.$router.push({
      //   path: "/allHotel/Attract_investment"
      // });
      if (this.pathUrl == '/allHotel/router_Attract') {
        setTimeout(() => {
          this.$router.push({
            path: "/allHotel/Attract_investment"
          });
        }, 2000);
      }
    },
    goPhone () {
      this.$router.push({ path: '/bindPhone' })
    },
    goTest () {
      this.$router.push({ path: '/allHotel/Attract_investment' })
    },
    Search () {
      this.$router.push({ path: '/Search' })
    },
    // 代理商渠道的公众号授权登录
    postWeachatLogin (data) {
      postWeachatLogin(data).then((res) => {
        console.log(res, "====res=== ===postWeachatLogin===");
        // mcok的数据
        // res = {
        //   data: {
        //     message: "登录成功",
        //     code: 200,
        //     data: {
        //       token: {
        //         access_token:
        //           "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJhdWQiOiIiLCJpYXQiOjE2NDgzNTA1MTEsIm5iZiI6MTY0ODM1MDUxMSwiZXhwIjoxNjQ4OTU1MzExLCJyZWYiOjE2NTA5NDI1MTEsInNjb3BlcyI6ImFjY2Vzc190b2tlbiIsImRhdGEiOnsidWlkIjo3MjIsInd4X29wZW5pZCI6Im8zOTdoMG5KSktlTXhJaFc3ZC1ZSEpBVlZuWkEiLCJkYXRhX2FnZW50X2lkIjoiMSJ9fQ.AQMWBJZtjHG3_5Ki9gS35OrkpkEqoAMnIlVm1IQ_byo",
        //         refresh_token:
        //           "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIiLCJhdWQiOiIiLCJpYXQiOjE2NDgzNTA1MTEsIm5iZiI6MTY0ODM1MDUxMSwiZXhwIjoxNjUwOTQyNTExLCJyZWYiOjE2NTA5NDI1MTEsInNjb3BlcyI6InJlZnJlc2hfdG9rZW4iLCJkYXRhIjp7InVpZCI6NzIyLCJ3eF9vcGVuaWQiOiJvMzk3aDBuSkpLZU14SWhXN2QtWUhKQVZWblpBIiwiZGF0YV9hZ2VudF9pZCI6IjEifX0.UgHRNQIydj0YNLqMExjUobnmlM_eRV6_E-LWPB33Pn8",
        //         token_type: "Bearer",
        //         expires_in: 604800,
        //       },
        //       user: {
        //         id: 722,
        //         data_agent_id: 0,
        //         phone: "",
        //         nickname: "David.Li",
        //         name: "",
        //         avatar:
        //           "gsj_new/system/202203/12/bd289e39855d3747cf9be41e04cde064.png",
        //         sex: 0,
        //         openid: "",
        //         wx_openid: "o397h0nJJKeMxIhW7d-YHJAVVnZA",
        //         vip: 1,
        //         wallet: "0.00",
        //         tour_money: "0.00",
        //         integral: 0,
        //         referrer_uid: 0,
        //         facilitator_id: 0,
        //         wechat_info: { nickname: "" },
        //         birthday: 0,
        //         email: "",
        //         login_name: "",
        //         last_login_time: 0,
        //         referrer_time: 0,
        //         status: 1,img-box default-ca
        //         create_time: "2022-03-12 10:21:46",
        //         update_time: "2022-03-12 14:39:29",
        //         admin_id: 1,
        //       },
        //       wx_openid: "o397h0nJJKeMxIhW7d-YHJAVVnZA",
        //     },
        //     uuid: "log_89e26827-40ec-7de0-65ab-5f040b3cb58d",
        //   },
        // };

        if (res.data.code === 200) {
          window.localStorage.setItem(
            "authorization",
            res.data.data.token.access_token
          );
          //存储微信个人用户信息
          let wechatUser = JSON.stringify(res.data.data.user);
          localStorage.setItem("wechatUser", wechatUser);
          // 判断等级
          if (res.data.data.user.com_wah_is_vip === 1) {
            this.vipDiscount = 0.8;
          } else if (res.data.data.user.com_wah_is_vip === 0) {
            this.vipDiscount = 1;
          }
        }
        this.getHomeData();
      });
    },
    testConsole () {
      let vConsole = new VConsole()
      console.log(vConsole);
    },
    //首页
    async getHomeData (data) {
      if (data) {
        await getHomeData(data).then((res) => {
          let arr = [];
          res.data.data.list.forEach((item) => {
            if (item.status == 1) {
              arr.push(item);
            }
          });
          this.pageCount = Math.ceil(res.data.data.total / 10);
          let dataArr = arr.map((item) => {
            return {
              id: item.hotel_id,
              img: item.hotel_pic,
              describe: item.name,
              type: item.mode,
              oldPrice: item.starting_price,
              price: Math.ceil(
                Number(
                  item.starting_price *
                  (this.vipDiscount ? this.vipDiscount : 1)
                )
              ),
            };
          });
          this.imgsArr = this.imgsArr.concat(dataArr);

        });
      } else {
        await getHomeData().then((res) => {
          let arr = [];
          res.data.data.list.forEach((item) => {
            if (item.status == 1) {
              arr.push(item);
            }
          });
          this.pageCount = Math.ceil(res.data.data.total / 10);
          this.imgsArr = arr.map((item) => {
            return {
              id: item.hotel_id,
              img: item.hotel_pic,
              describe: item.name,
              type: item.mode,
              oldPrice: item.starting_price,
              price: Math.ceil(
                Number(
                  item.starting_price *
                  (this.vipDiscount ? this.vipDiscount : 1)
                )
              ),
            };
          });
          // console.log(this.imgsArr)
        });
      }
    },
    getwatId (data) {
      this.$router.push({
        path: "/allHotel/HotelDatail",
        query: { hotelId: data.id },
      });
    },
    beforeMount () {

    },
    // 获取滚动条的滚动距离
    handleScroll () {
      // console.log("执行滚动条方法");
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      // // // //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

      // // // //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (document.querySelector("#boxFixed") == null) {
        return;
      }
      let offsetTop = document.querySelector("#boxFixed").offsetTop; // 要滚动到顶部吸附的元素的偏移量
      let searchTop = document.querySelector("#search").offsetTop;
      // console.log("scrollTop", scrollTop);
      // console.log("windowHeight", windowHeight);
      // console.log("scrollHeight", scrollHeight);
      // console.log("offsetTop", offsetTop);
      // console.log("searchTop", searchTop);
      this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
      this.is_searFix = scrollTop > searchTop ? true : false;

      if (scrollTop + windowHeight >= scrollHeight - 1 && this.page <= this.pageCount) {
        // console.log(scrollTop, "scrollTop");
        // console.log(windowHeight, "windowHeight");
        // console.log(scrollHeight, "scrollHeight");
        // console.log("到底了");
        this.page++;
        this.aesKey = aesKey(this.page);
        let data = {
          page: this.page,
          status: 1,
        };
        // this.aesKey = aesKey(data);
        this.getHomeData(data);
      }
    },

    switchTab () {
      // console.log(this.activeName);
    },
    switchBottomTab () {
      // console.log(this.bottomActive);
      if (this.bottomActive == 1) {
        this.$router.push({ path: "/allDest/destination" });
      } else if (this.bottomActive == 2) {
        this.$router.push({ path: "/allShop/shopHome" });
      } else if (this.bottomActive == 3) {
        this.$router.push({ path: "/allPerInfo/personalHome" });
      }
    },
    login () {
      postLogin("POST", this.aesKey).then((res) => {
        const resData = res.data;
        console.log(resData);
      });
    },
    //轮播图第二张或者第四张点击
    clickSwipe () {
      window.location =
        "https://ssl.gongyi.qq.com/m/weixin/detail.htm?pid=221366";
    },
    //轮播图第一张或者第三张点击
    goWeiaiHome () {
      this.$router.push({ path: "/weiaiHome" });
    },
    //分享JSSDK
    shareWechat () {
      let data = {
        url: this.url,
        apis: ['updateAppMessageShareData', 'onMenuShareAppMessage', 'getLocation', 'openLocation']
      }
      console.log(data);
      this.aesKey = aesKey(data)
      getJSSDK(this.aesKey).then(res => {
        console.log(res, "JSSDK");
        wx.config({
          debug: false,
          appId: res.data.data.appId,
          timestamp: res.data.data.timestamp,
          nonceStr: res.data.data.nonceStr,
          signature: res.data.data.signature,
          jsApiList: ['updateAppMessageShareData', 'onMenuShareAppMessage', 'getLocation', 'openLocation']
        })
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          wx.getLocation({
            altitude: true,
            isHighAccuracy: true,
            type: "gcj02", // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
            success: function (res) {
              console.log(res, "获取微信用户位置");
              const latitude = res.latitude;
              const longitude = res.longitude;
              // wx.openLocation({
              //   latitude,
              //   longitude,
              //   scale: 18,
              //   name: '当前位置',
              //   address: '地址详情说明', // 地址详情说明
              //   infoUrl: '跳转',// 在查看位置界面底部显示的超链接,可点击跳转
              //   success: function (res) {
              //     console.log(res);
              //   },
              // });
              console.log(latitude, longitude, "原gcj02经纬度");
              //国测局坐标转百度经纬度坐标
              let gcj02tobd09 = coordtransform.gcj02tobd09(longitude, latitude)
              console.log(gcj02tobd09);
              localStorage.setItem("latitude", gcj02tobd09[1]);
              localStorage.setItem("longitude", gcj02tobd09[0]);
              this.latitude = gcj02tobd09[1]
              this.longitude = gcj02tobd09[0]
              // alert('gcj02tobd09', coordtransform.gcj02tobd09(longitude, latitude))
              //wgs84转国测局坐标
              // console.log('wgs84togcj02', coordtransform.wgs84togcj02(longitude, latitude))
              //国测局坐标转wgs84坐标
              // console.log('gcj02towgs84', coordtransform.gcj02towgs84(longitude, latitude))

              // let  latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
              // let  longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
              // let  speed = res.speed; // 速度，以米/每秒计
            },
          });
          let imgUrl = "https://pt.guansj.com/img/guanLOGO.png"
          // this.url = window.location.href.split('#')[0]
          if (wx.updateAppMessageShareData) {
            wx.updateAppMessageShareData({
              title: "观视界首页", // 分享标题
              desc: "观视界-分享旅游", // 分享描述
              link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: imgUrl, // 分享图标
              success: function () {
                console.log(imgUrl)
                // 设置成功
                console.log("设置成功");
              }
            })
          } else {
            wx.onMenuShareAppMessage({
              title: 312, // 分享标题
              desc: 666, // 分享描述
              link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: this.imgUrl, // 分享图标
              success: function () {
                console.log("设置成功");
                // 设置成功
              }
            })
          }

        })
      })


    },
    goHotelSearch (HomeSearchValue) {
      if (HomeSearchValue == 'VConsole') {
        this.testConsole()
        return
      }
      if (HomeSearchValue == '') {
        Toast.fail('搜索不能为空')
        return
      }
      this.cities = Bar_address
      this.cities.filter(item => {
        let newSeachValue = HomeSearchValue + '市' //eslint-disable-line no-unused-vars
        if (item.name == newSeachValue) {
          console.log(item);

          this.searchAddress = item
          // this.$router.push({ path: '/allHotel/HotelList' })
        }
      })
      // this.HomeContent = new Array (4)
      // console.log(this.HomeContent.length);
      // console.log(HomeSearchValue, "主页搜索");
      this.HomeContent.unshift(HomeSearchValue)
      localStorage.setItem("HomeValue", HomeSearchValue)
      this.HomeContent = this.HomeContent.filter((item, index) => {
        return this.HomeContent.indexOf(item, 0) === index
      })
      localStorage.setItem("searchValue", HomeSearchValue)
      localStorage.setItem("HomeContent", JSON.stringify(this.HomeContent))
      let HomeValue = {
        search: this.HomeSearchValue
      }
      // console.log(HomeValue);
      this.getAddressInfo()
      this.$router.push({ path: '/allHotel/HotelList', query: { SearchValue: HomeValue, status: 1 } })
    },


    getContent (data) {
      // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!");
      this.HomeSearchValue = data
    },

    //获取定位后,百度地图逆解析获得
    handler (e) {
      this.city = e.address.city
      localStorage.setItem('longitude', e.longitude)
      localStorage.setItem('latitude', e.latitude)
      // console.log(e, '获取定位后,百度地图逆解析获得');
      // console.log(this.city, "====this.city====");
      const myGeo = new window.BMap.Geocoder({ extensions_town: true })
      myGeo.getLocation(new window.BMap.Point(e.point.lng, e.point.lat),
      )
    },
    initMap ({ BMap }) {
      this.BMap = BMap
      // 通过百度map获取经纬度再传给地图组件
      const that = this
      try {
        const geolocation = new window.BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            // console.log(r);
            that.center = { lat: r.point.lat, lng: r.point.lng }
            that.point = { lat: r.point.lat, lng: r.point.lng }
            that.handler(r)
          },
          { enableHighAccuracy: true }
        )
      } catch (error) {
        this.$toast('请打开定位或退出重试')
      }
    },
    getAddressInfo () {

      if (this.searchAddress != null) {
        console.log(this.searchAddress);
        let sessionCityData = []
        sessionCityData = JSON.stringify(this.searchAddress)
        sessionStorage.setItem('sessionCityData', sessionCityData)
      } else {
        let data = {
          name: this.city
        }
        console.log(data, "======data======");
        Address_three(data).then(res => {
          this.cityInfo = res.data.data
          this.cityInfo = {
            child: Array(0),
            city_id: this.cityInfo.id,
            code: this.cityInfo.code,
            level: this.cityInfo.level,
            name: this.cityInfo.name,
            pcode: this.cityInfo.pcode,
          }
          let sessionCityData = []
          sessionCityData = JSON.stringify(this.cityInfo)
          sessionStorage.setItem('sessionCityData', sessionCityData)
          console.log(this.cityInfo, "获取地址信息");
          // alert(res)
        })
      }

    },
    // ios頁面不刷新
    // refresh () {
    //   var isPageHide = false;
    //   window.addEventListener('pageshow', function () {
    //     console.log('ios頁面不刷新1');
    //     if (isPageHide) {
    //       window.location.reload();
    //     }
    //   });
    //   window.addEventListener('pagehide', function () {
    //     console.log('ios頁面不刷新2');
    //     isPageHide = true;
    //   });
    // }
  },
};
</script>

<style lang="less" scoped>
.container {
  .bg {
    background-image: url(../assets/HomeIcon/bg.png);
    position: absolute;
    z-index: 0;

    width: 100%;
    height: 4rem;
    background-repeat: no-repeat;
    background-size: contain;
  }
  // .top {
  //   .search {
  //     position: relative;
  //     border-radius: 20px;
  //   }
  // }
  .banner {
    // margin-top: 20px;
    width: 100%;
    .van-swipe {
      width: 100%;
    }
    /deep/ .my-swipe .van-swipe-item {
      color: #fff;
      font-size: 20px;
      // line-height: 150px;
      text-align: center;
      // background-color: #39a9ed;
      img {
        width: 95%;
      }
    }
  }
  .van-grid {
    padding-top: 0.1852rem;
    padding-left: 8%;
    margin-left: -0.25rem;
    margin-right: 0.13rem;
    .router {
      display: inline-block;
      color: black;
    }
    .van-grid-item {
      /deep/.van-grid-item__content {
        padding: 0;
        // height: 80%;
      }
      margin-top: -35px;
      img {
        width: 0.9259rem;
      }
      p {
        font-size: 0.25rem;
        margin-top: -10px;
        padding-bottom: 0.02rem;
      }
      .small_img {
        margin-top: -0.25rem;
        img {
          width: 68%;
        }
      }
    }
  }
  .hisContent {
    z-index: 11;
    // position: absolute;
    overflow: hidden;
    // height: 40px;
    // margin: 0.2rem 0;
    div {
      float: left;
      margin-bottom: 0.25rem;
      margin-left: 0.2rem;
      width: 1rem;
      text-align: center;
      border-radius: 20px;
      // width: 104px;
      // height: 37px;
      opacity: 0.6;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      // line-height: 37px;
      // color: gray;
      background-color: #f8f8f8;
      // margin-right: 0.1rem;
      width: 1rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      // overflow: hidden;
      z-index: 999;
    }
  }
  .VIP {
    display: flex;
    margin-top: 0.0625rem;
    margin-left: 0.1rem;
    padding-right: 0.1rem;
    padding-left: 0.1rem;
    .VIP1 {
      // position: relative;
      transition: all 1s;
      width: 33%;
      img {
        margin-top: 40px;
        width: 100%;
      }
    }
    .VIP1:hover {
      // transform: rotate(360deg) translate(80px, 80px);
      transform: translate(140px, -25px) scale(1.5);
    }
    .VIP2 {
      width: 60%;
      margin-top: 5px;
      img {
        width: 90%;
      }
    }
    .VIP3 {
      // position: relative;
      transition: all 1s;
      width: 33%;
      img {
        margin-top: 40px;
        width: 100%;
      }
    }
    .VIP3:hover {
      // transform: rotate(360deg) translate(80px, 80px);
      transform: translate(-140px, -25px) scale(1.5);
    }
    .VIP4 {
      width: 33.3333vw;
      margin-top: 5px;
      img {
        width: 90%;
      }
    }
  }
  .VIPlevel {
    margin-left: 0.1852rem;
    float: left;
    img {
      width: 95.6667vw;
    }
  }
  .bottom {
    text-align: center;
    img {
      width: 30%;
    }
  }
}
/deep/ .van-button--small {
  position: absolute;
  top: 1.5%;
  right: 3.2%;
  border-radius: 20px;
  width: 60px;
}
/deep/.van-search .van-cell {
  background-color: white;
  border-radius: 20px;

  .van-field__body {
    margin-left: 10px;
  }
}
/deep/ .van-search__content {
  padding-left: 0;
}
/deep/.van-swipe__indicators {
  bottom: 10%;
  left: 85%;
}
/deep/.van-tabs__line {
  background-color: #36e0c9;
  width: 0.6rem;
}
/deep/.vue-waterfall-easy {
  margin-top: 10px;
  margin-bottom: 20px;
  a.img-inner-box {
    box-shadow: none !important;
    a {
      height: 100% !important;
    }
  }

  img {
    border-radius: 5px;
    width: 97% !important;
  }
  .waterFall {
    font-size: 0.25rem;
    text-align: left;
    margin-top: 20px;
    .headerW {
      font-size: 0.26rem;
      margin-top: -10px;
    }
    .van-search .bottomW {
      position: relative;
      padding-top: 0.15rem;
      .price {
        font-size: 0.35rem;
        font-weight: bold;
        color: #ff5c4c;
      }
      .type {
        font-size: 0.2rem;
        font-weight: bold;
        position: absolute;
        right: 5%;
        top: 36%;
      }
    }
  }
}
/deep/.van-tab--active {
  background-color: #36e0c9 !important;
  border: none !important;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
/deep/.van-tabs__nav--card {
  border: none !important;
  .van-tab {
    border-right: none;
    color: #b0b0b2;
  }
}
/deep/.vue-waterfall-easy-container
  .vue-waterfall-easy-scroll[data-v-ded6b974] {
  overflow-x: visible;
  overflow-y: visible;
}
/deep/.vue-waterfall-easy {
  margin-top: 0.1852rem;
  margin-bottom: 0.3704rem;
  a.img-inner-box {
    box-shadow: none;
    a {
      height: 100% !important;
    }
    .img-box {
      .default-card-animation {
        position: none !important;
        display: inline-block;
      }
    }
  }

  img {
    border-radius: 5px;
    width: 93%;
  }
  .waterFall {
    font-size: 0.25rem;
    text-align: left;
    margin-top: 0.3704rem;
    .headerW {
      font-size: 0.26rem;
      margin-top: -0.1852rem;
    }
    .van-search .bottomW {
      position: relative;
      padding-top: 0.15rem;
      .price {
        font-size: 0.35rem;
        font-weight: bold;
        color: #ff5c4c;
      }
      .type {
        font-size: 0.2rem;
        font-weight: bold;
        position: absolute;
        right: 5%;
        top: 36%;
      }
    }
  }
}
/deep/.vue-waterfall-easy-container
  .vue-waterfall-easy
  > .img-box[data-v-ded6b974] {
  position: static !important;
  display: inline-block;
}
.waterfall {
  margin-top: 100px;
}
// /deep/.vue-waterfall-easy-container
//   .vue-waterfall-easy-scroll[data-v-ded6b974] {
//   overflow-x: visible !important;
//   overflow-y:visible  !important;
// }
</style>