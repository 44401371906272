<template>
  <div class="container">
    <div class="bg"></div>
    <div class="content">
      <div class="address">
        <span class="left"
              v-if="hotelInfo">{{hotelInfo.name}}</span>
        <span class="right">
          <van-icon name="location-o" />
          我的位置
        </span>
      </div>
      <van-divider style="width: 90%; margin-left: 5%;" />
      <div class="date"
           @click="changeDate">
        <div class="top">
          <span>今天</span>
          <span style="margin-right:0.5rem">明天</span>
        </div>
        <div class="time">
          <span class="today">{{ArrivalDate}}</span>
          <span class="total">共{{dateLength}}晚</span>

          <span class="leave_day">{{LeavelDate}}</span>
        </div>
        <van-divider style="width: 90%; margin-left: 5%;" />
      </div>
      <div class="search">
        <div class="top"> <span>关键字/位置/民宿名</span></div>
        <div class="bottom">
          <van-button round
                      color="#f5f5f5"
                      size="mini"
                      class="history">松山湖</van-button>
        </div>
        <van-button>搜索酒店</van-button>
      </div>
    </div>
    <!-- 日历弹窗 -->
    <div>
      <van-calendar v-model="calendarShow"
                    color="#36e0c9"
                    type="range"
                    @confirm="onConfirm"
                    :default-date="defaultDate"
                    :formatter="formatter" />
    </div>
  </div>
</template>

<script>
import { getHotelDetail } from '@/api/hotel.js'
import { aesKey } from "@/commom/aesKey"

export default {
  data () {
    return {
      hotelInfo: null,
      aesKey: aesKey(),//接口通过密文来获取数据
      defaultDate: [],//默认日期
      calendarShow: false,//日历是否出现
      ArrivalDate: null,
      LeavelDate: null,
      dateLength: null

    }
  },
  beforeCreate () {

  },
  created () {
    let data = {
      hotel_id: 9175
    }
    this.aesKey = aesKey(data)
    this.getHotelDetail(this.aesKey)
  },
  mounted () {

  },
  beforeMount () {
    // 处理时间
    this.dealDate()
  },
  watch: {
  },
  methods: {

    getHotelDetail (data) {
      getHotelDetail(data).then(res => {
        console.log(res);
        let arr = res.data.data
        console.log(arr);
        arr.room_list.forEach(item => {
          // item.salesCeil_price = Math.ceil(Number(item.sales_price))
          item.salesCeil_price = item.sales_price
        })
        // console.log(arr, "====arr")
        this.hotelInfo = arr.hotel_info
        console.log(this.hotelInfo);
      })
    },
    dateBetweenDeal (startDate, endDate) {
      let st = new Date(startDate).getTime();
      let et = new Date(endDate).getTime();
      let retArr = [];
      const oneDay = 1000 * 60 * 60 * 24

      while (st != et) {
        retArr.push(st);
        st = st + oneDay
      }
      retArr.push(et);

      return retArr
    },
    //点击日期
    changeDate () {
      this.calendarShow = true
    },
    //日历上的显示
    formatter (day) {
      if (day.type === 'start') {
        day.bottomInfo = '入住';
      } else if (day.type === 'end') {
        day.bottomInfo = '退房';
      }
      return day;
    },
    //日历确认
    onConfirm (date) {
      // const [start, end] = date;
      this.calendarShow = false;
      this.dateArray = this.dateBetweenDeal(new Date(date[0]).getTime(), new Date(date[1]).getTime())
      sessionStorage.setItem('dateArray', this.dateArray)
      this.ArrivalDate = new Date(Number(this.dateArray[0])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[0])).getDate() + '日'
      this.LeavelDate = new Date(Number(this.dateArray[this.dateArray.length - 1])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[this.dateArray.length - 1])).getDate() + '日'
      this.dateLength = this.dateArray.length - 1
    }, //处理日期数据
    dealDate () {
      this.dateArray = sessionStorage.getItem('dateArray')
      this.dateArray = this.dateArray.split(",")
      this.dateLength = this.dateArray.length - 1
      this.ArrivalDate = new Date(Number(this.dateArray[0])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[0])).getDate() + '日'
      this.LeavelDate = new Date(Number(this.dateArray[this.dateArray.length - 1])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[this.dateArray.length - 1])).getDate() + '日'
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  .bg {
    background-image: url(../../../assets/HomeIcon/bg_2.png);
    height: 4rem;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .content {
    width: 90%;
    height: 280px;
    background: #ffffff;
    box-shadow: 0px 7px 18px 6px rgba(229, 229, 229, 0.5);
    border-radius: 23px;
    position: absolute;
    top: 1rem;
    margin-left: 5%;
    .address {
      font-size: 18px;
      display: flex;
      margin-top: 10px;
      margin-left: 0.5rem;
      justify-content: space-between;
      .left {
        font-family: PingFangSC-Medium, PingFang SC;
      }
      .right {
        font-size: 12px;
        margin-right: 20px;
        color: #24c5af;
        line-height: 30px;
      }
    }
    .date {
      .top {
        width: 50%;
        margin-left: 0.5rem;
        display: flex;
        justify-content: space-between;
        color: #999999;
        font-size: 12px;
      }
      .time {
        font-size: 18px;
        width: 50%;
        margin-left: 0.5rem;

        display: flex;
        justify-content: space-between;
        .total {
          font-size: 12px;
          border-radius: 16px;
          border: 1px solid #a6f2ff;
          background: #f6f6f8;
          padding: 0 5px;
          line-height: 23px;
        }
      }
    }
    .search {
      .van-button--normal {
        margin-top: 0.3rem;
        position: absolute;
        width: 85%;
        background-color: #2ee8c6;
        color: white;
        left: 0.55rem;
        border-radius: 20px;
      }
      .top {
        display: flex;
        margin-left: 0.5rem;

        font-size: 14px;
        color: #999999;
      }
      .bottom {
        margin-top: 10px;
        display: flex;
        margin-left: 0.5rem;

        font-size: 12px;
        .history {
          color: #666666 !important;
          padding: 10px 5px;
        }
      }
    }
  }
}
</style>