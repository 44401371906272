import { request } from './request.js'
// import qs from 'qs'


// 商品列表
export function getGoodsList (params) {
  return request({
    url: '/agent/shop/goods',
    method: 'POST',
    params
  })
}

// 商品分类查询
export function getGoodcategory (params) {
  return request({
    url: '/agent/shop/goods/category',
    method: 'POST',
    params
  })
}

// 商品详情查询
export function getGoodsDetail (params) {
  return request({
    url: '/agent/shop/goods/read',
    method: 'POST',
    params
  })
}

// 提交商品订单
export function submitGoodsOrder (params) {
  return request({
    url: '/home/shop/order/checkout',
    method: 'POST',
    params
  })
}
// export function submitGoodsOrder (params) {
//   return request({
//     url: '/agent/shop/order/checkout',
//     method: 'POST',
//     params
//   })
// }
// 商品订单列表
export function getGoodsOrderList (params) {
  return request({
    url: '/home/shop/order',
    method: 'GET',
    params
  })
}
// export function getGoodsOrderList (params) {
//   return request({
//     url: '/agent/shop/order',
//     method: 'POST',
//     params
//   })
// }
// 商品订单支付
// export function payGoodsOrder (params) {
//   return request({
//     url: '/agent/shop/order/pay',
//     method: 'POST',
//     params
//   })
// }

export function payGoodsOrder (params) {
  return request({
    url: '/home/user/prepay',
    method: 'POST',
    params
  })
}
// 商品订单取消
export function cancelGoodsOrder (params) {
  return request({
    url: '/agent/shop/order/cancel',
    method: 'POST',
    params
  })
}
// 查询订单支付状态
export function checkPayStatus (params) {
  return request({
    url: '/home/user/check-pay-status',
    method: 'POST',
    params
  })
}
export function ShopOrderdetail (params) {
  return request({
    url: '/home/shop/order/detail',
    method: 'GET',
    params
  })
}
export function ShopOrderRefund (params) {
  return request({
    url: 'agent/shop/order/goods/refund',
    method: 'POST',
    params
  })
}