import { request } from './request.js'
// import qs from 'qs'

//基础数据

// 地址区域
export function getArea (params) {
  return request({
    url: '/agent/area-gsj',
    method: 'POST',
    params
  })
}

// 旅游区域
export function getTourArea (params) {
  return request({
    url: '/common/tour-area',
    method: 'GET',
    params
  })
}