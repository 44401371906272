<template>
  <div class="water">
    <vue-waterfall-easy :imgsArr="imgsArr"
                        :mobileGap="10"
                        height="1030px"
                        :loadingDotCount='0'
                        :imgWidth='180'
                        srcKey='img'
                        @scrollReachBottom="getData"
                        @click="getwatId">
      <div slot-scope="props"
           class="waterFall">
        <div class="headerW">{{props.value.describe}}</div>
        <div class="bottomW">
          <!-- <span>微爱汇ID:{{com_wah_is_vip}}</span> -->
          <span class="old_price"
                v-if="vip=='1'  ">￥{{props.value.oldPrice}}</span>
          <span class="price"
                v-show="price">￥{{props.value.price}}</span>
          <span class="type"
                v-if="vip=='1' ">会员价</span>
          <!-- <span class="type">
            {{props.value.type==0?'不限':
            props.value.type==1?'民宿':
            props.value.type==2?'酒店':props.value.type}}
          </span> -->
        </div>
      </div>
    </vue-waterfall-easy>
  </div>
</template>

<script>
import vueWaterfallEasy from 'vue-waterfall-easy'
export default {
  data () {
    return {
      vip: '',
      price: true,
      com_wah_is_vip: ''
    }
  },
  props: {
    imgsArr: Array,
  },
  components: {
    vueWaterfallEasy
  },
  watch: {

    imgsArr () {
      this.imgsArr.forEach(item => {
        if (item.img === '') {
          if (item.id % 2 == 0) {
            item.img = 'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202203/04/1646395064053wutu2.jpg'
          } else {
            item.img = 'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202203/04/1646394881325wutu1.jpg'
          }
        }
      })
    },
    newPrice () {
      this.newPrice.forEach(item => {
        if (item.img === '') {
          if (item.id % 2 == 0) {
            item.img = 'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202203/04/1646395064053wutu2.jpg'
          } else {
            item.img = 'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202203/04/1646394881325wutu1.jpg'
          }
        }
      })
    },

  },
  methods: {
    getData () {
      this.$emit('getData')
    },
    // wah () {
    //   if (this.com_wah_is_vip == 1)
    //     return this.is_wah = true
    // },
    getwatId (index, value) {
      this.$emit('getwatId', value.value)
    },
    // getUserId () {
    //   let data = JSON.parse(localStorage.getItem('wechatUser'));
    //   if (data.com_wah_is_vip == '1') {
    //     // this.com_wah_is_vip = data.com_wah_is_vip
    //     this.vip = '1'
    //   }
    // },
  },
  created () {
    // this.getUserId()
  },
  computed: {

  },
  mounted () {
  },
}
</script>
<style lang="less" scoped>
.vipPrice {
  // font-size: 4px;
  // border: 1px solid;
  // color: #ff5c4c;
  // padding: 2px;
  // border-radius: 3px;
  // left: 100%;
  -webkit-transform-origin-x: 0;
  -webkit-transform: scale(1.5);
  // font-size: 0.2rem;
  // font-weight: bold;
  // position: absolute;
  // right: 5%;
  // top: 36%;
}
.old_price {
  color: gray;
  font-size: 0.25rem;
  text-decoration: line-through;
}
.water {
  /deep/.vue-waterfall-easy {
    .waterFall {
      font-size: 0.25rem;
      text-align: left;
      margin-top: 20px;
      .headerW {
        font-size: 0.26rem;
        margin-top: -10px;
        width: 90%;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .bottomW {
        position: relative;
        padding-top: 0.15rem;
        .price {
          font-size: 0.35rem;
          font-weight: bold;
          color: #ff5c4c;
        }
        .type {
          font-size: 0.2rem;
          // font-weight: bold;
          position: absolute;
          right: 5%;
          top: 36%;
          border: 1px solid;
          color: #ff5c4c;
          padding: 2px;
          border-radius: 3px;
        }
      }
    }
  }
}
</style>