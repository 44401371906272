<template>
  <div id="shopCart"
       :style="{height:innerHeight}">
    <!-- <NavBar NavBarTitle='购物车'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="header">
      &nbsp;
      <span v-show='is_edit'
            @click="whetherEdit">编辑</span>
      <span v-show='!is_edit'
            @click="whetherEdit">完成</span>
    </div>
    <div style="margin-top:0.8rem">
      <div class="shopCard"
           v-for="(shop,index) in carData"
           :key="shop.id">
        <div class="supplier">
          {{shop.shopName}}
          <div class="outside">
            <van-checkbox v-model="shop.is_select"
                          checked-color="#2EE8C6"
                          @click='checkTitle(shop,index)'></van-checkbox>
          </div>
        </div>
        <div v-for="item in shop.shopData"
             :key="item.id">
          <van-swipe-cell>
            <van-card :price="item.price"
                      :desc="item.desc"
                      :title="item.goodsName"
                      :thumb="item.img">
              <template #tag>
                <van-checkbox v-model="item.is_select"
                              checked-color="#2EE8C6"
                              @click='checkGoods(item,index)'></van-checkbox>
              </template>
              <template #footer
                        class="numAdd">
                <van-icon name="minus"
                          @click="reduceNum(item)" />
                <span class="num">{{item.num}}</span>
                <van-icon name="plus"
                          @click="addNum(item)" />
              </template>
            </van-card>
            <template #right>
              <van-button square
                          text="删除"
                          type="danger"
                          class="delete-button" />
            </template>
          </van-swipe-cell>
        </div>

      </div>
    </div>
    <div class="settlementOrder">
      <van-submit-bar :price="totalPrice()"
                      :button-text="totalNum()"
                      @submit="onSubmit"
                      v-show="is_edit">
        <van-checkbox v-model="checkedAll"
                      checked-color="#2EE8C6"
                      @change="changeCheckedAll">全选</van-checkbox>
      </van-submit-bar>
      <div class="btn"
           v-show="!is_edit">
        <van-checkbox v-model="checkedAll"
                      checked-color="#2EE8C6"
                      @change="changeCheckedAll">全选</van-checkbox>
        <van-button plain
                    round>移至收藏</van-button>
        <van-button plain
                    color="#FE6529"
                    round
                    @click="deleteGoods">删除</van-button>
      </div>

    </div>
    <div class="bottom">
      <van-tabbar v-model="bottomActive"
                  @change="switchBottomTab"
                  active-color='#58e3dd'>
        <van-tabbar-item>
          <img v-if="bottomActive == 0"
               src="@/assets/shopImg/shopping_active.png"
               alt="">
          <img v-else
               src="@/assets/shopImg/shopping_icon.png"
               alt="">
          <div>商城</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 1"
               src="@/assets/shopImg/classification_active.png"
               alt="">
          <img v-else
               src="@/assets/shopImg/classification_icon.png"
               alt="">
          <div>分类</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 2"
               src="@/assets/shopImg/ShoppingCar _active.png"
               alt="">
          <img v-else
               src="@/assets/shopImg/ShoppingCar.png"
               alt="">
          <div>购物车</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 3"
               src="@/assets/shopImg/order_active.png"
               alt="">
          <img v-else
               src="@/assets/shopImg/order_icon.png"
               alt="">
          <div>订单</div>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>


<script>
// import NavBar from '@/components/NavBar'
export default {
  components: {
    // NavBar
  },
  data () {
    return {
      innerHeight: 0,
      bottomActive: 2,
      is_edit: true,
      checkedAll: false,//下方结算栏是否全选
      carData: [  //模拟购物车数据
        {
          shopName: '观视界',
          shopData: [
            {
              goodsName: 'sguai小水怪智能保温杯女士高颜值316不锈钢温度显示彩屏水杯子M2',
              desc: '颜色分类: 紫色   容量: 300ml',
              img: 'https://g-search1.alicdn.com/img/bao/uploaded/i4/i4/3919667498/O1CN01Wu83o825G8Q14i6oG_!!0-item_pic.jpg_460x460Q90.jpg_.webp',
              price: 178.00,
              num: 1,
              is_select: false
            },
            {
              goodsName: 'sguai小水怪智能保温杯女士高颜值316不锈钢温度显示彩屏水杯子M2',
              desc: '颜色分类: 粉色   容量: 300ml',
              img: '//img11.360buyimg.com/n1/jfs/t1/220027/36/376/48182/6168e613Eb6c8d73b/f17cd21e455edae0.jpg',
              price: 190.00,
              num: 1,
              is_select: false
            },
          ],
          is_select: false
        },
        {
          shopName: '观视界',
          shopData: [
            {
              goodsName: 'sguai小水怪智能保温杯女士高颜值316不锈钢温度显示彩屏水杯子M2',
              desc: '颜色分类: 冰川绿   容量: 300ml',
              img: '//img13.360buyimg.com/n1/jfs/t1/115197/10/22908/154706/61b47b1bEa01ca4c3/b7ad1f6eedffac6d.jpg',
              price: 200.75,
              num: 1,
              is_select: false
            },
          ],
          is_select: false
        },
      ]
    }
  },
  mounted () {
    this.innerHeight = window.innerHeight + 'px'
    this.carData.forEach(item => {
      item.shopData.forEach(item2 => {
        item2.price = item2.price.toFixed(2)
      })
    })
  },
  methods: {
    totalPrice: function () {
      let totalPri = 0
      this.carData.forEach(item => {
        item.shopData.forEach(item2 => {
          if (item2.is_select == true) {
            totalPri += Number(item2.price)
          }
        })
      })
      totalPri = totalPri * 100
      return totalPri
    },
    totalNum: function () {
      let totalN = 0
      this.carData.forEach(item => {
        item.shopData.forEach(item2 => {
          if (item2.is_select == true) {
            totalN += Number(item2.num)
          }
        })
      })
      totalN = '结算(' + totalN + ')'
      return totalN
    },
    onClickLeft () {
      this.$router.go(-1)
      // this.$router.push({ path: '/allShop/shopHome' })
    },
    //底部按钮
    switchBottomTab () {
      console.log(this.bottomActive)
      if (this.bottomActive == 0) {
        this.$router.push({ path: '/allShop/shopHome' })
      } else if (this.bottomActive == 1) {
        this.$router.push({ path: '/allShop/shopSort' })
      } else if (this.bottomActive == 2) {
        this.$router.push({ path: '/allShop/shopCart' })
      } else if (this.bottomActive == 3) {
        this.$router.push({ path: '/allShop/shopMyOrder' })
      }
    },
    //是否编辑购物车的商品
    whetherEdit () {
      if (this.is_edit) {
        this.is_edit = false
      } else {
        this.is_edit = true
      }
    },
    //删除被选中商品
    deleteGoods () {
      this.carData.forEach((item, index1) => {
        if (item.is_select == true) {
          this.carData.splice(index1, 1)
        }
        item.shopData.forEach((item2, index2) => {
          if (item2.is_select == true) {
            item.shopData.splice(index2, 1)
          }
        })
      })
    },
    //减少商品数量
    reduceNum (item) {
      if (item.num == 1) {
        this.$toast({
          message: '数量不能小于1',
        });
        return
      }
      let priceDra = item.price / item.num
      item.num--
      item.price = (priceDra * item.num).toFixed(2)
    },
    //增加商品数量
    addNum (item) {
      let priceDra = item.price / item.num
      item.num++
      item.price = (priceDra * item.num).toFixed(2)
    },
    //店铺名称旁边的复选框
    checkTitle (item, index) {
      if (item.is_select) {
        this.carData[index].shopData.forEach(item => {
          item.is_select = true
        })
      } else {
        this.carData[index].shopData.forEach(item => {
          item.is_select = false
        })
      }
    },
    //商品旁边的复选框
    checkGoods (item, index) {
      console.log(item, index);
      let a = this.carData[index].shopData.findIndex(target => target.is_select === false)
      if (a == -1) {
        this.carData[index].is_select = true
      } else {
        this.carData[index].is_select = false
      }
    },
    //结算栏下的全选框
    changeCheckedAll (checked) {
      // let a = this.carData.findIndex(target => target.is_select === false)
      console.log(checked)
      if (checked) {
        this.carData.forEach(item => {
          item.is_select = true
          item.shopData.forEach(item2 => {
            item2.is_select = true
          })
        })
      } else {
        this.carData.forEach(item => {
          item.is_select = false
          item.shopData.forEach(item2 => {
            item2.is_select = false
          })
        })
      }
    },
    //结算订单
    onSubmit () {
      console.log(123)
    }
  }
}
</script>

<style lang="less" scoped>
#shopCart {
  background-color: #f5f5f5;
  .header {
    font-size: 0.5rem;
    text-align: left;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: fixed;
    z-index: 10;
    width: 100%;
    span {
      float: right;
      font-size: 0.25rem;
      margin-top: 0.1rem;
      margin-right: 0.3rem;
      border-left: 1px solid #cccccc;
      padding-left: 0.2rem;
    }
  }
  .shopCard {
    text-align: left;
    border-radius: 10px;
    background-color: white;
    font-size: 0.35rem;
    margin-top: 0.2rem;
    padding-left: 0.5rem;
    /deep/.van-checkbox__icon {
      font-size: 0.3rem;
    }
    .supplier {
      font-size: 0.28rem;
      padding: 0.2rem 0.3rem;
      position: relative;
      .outside {
        position: absolute;
        left: -3%;
        top: 35%;
      }
    }
    .van-swipe-cell {
      margin-left: -0.3rem;
      .van-button--square {
        height: 100%;
      }
    }
    .van-card {
      background-color: white;
      border-radius: 10px;
      font-size: 0.2rem;
      padding: 0 0.3rem;
      padding-bottom: 0.3rem;
      margin-left: 0.3rem;
      position: relative;
      .van-card__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 4rem;
        font-size: 0.3rem;
        height: auto;
        display: inline-block;
        line-height: inherit;
      }
      .van-card__thumb {
        width: 1.5rem;
        height: 1.5rem;
      }
      .van-card__desc {
        display: inline;
        padding: 0.05rem 0.1rem;
        background-color: #f5f5f5;
        color: #999999;
      }
      .van-card__price {
        font-size: 0.3rem;
        font-weight: bold;
        color: #fe6529;
        .van-card__price-currency {
          font-size: 0.2rem;
        }
        .van-card__price-integer {
          font-size: 0.3rem;
        }
      }
      .van-card__footer {
        font-size: 0.3rem;
        position: absolute;
        right: 5%;
        bottom: 15%;
        .num {
          display: inline-block;
          padding: 0.05rem 0.15rem;
          background-color: #f5f5f5;
          margin-left: 0.15rem;
        }
        .van-icon-plus {
          margin-left: 0.15rem;
        }
      }
      .van-card__tag {
        top: 37%;
        left: -34.1%;
      }
    }
  }
  .settlementOrder {
    position: fixed;
    width: 100%;
    bottom: 0.8889rem;
    .van-submit-bar {
      bottom: 0.8889rem;
    }
    .btn {
      float: right;
      width: 100%;
      text-align: right;
      background-color: white;
      font-size: 0.25rem;
      position: relative;
      height: 0.9rem;
      .van-checkbox {
        position: absolute;
        top: 31%;
        left: 4%;
      }
      .van-button {
        font-size: 0.25rem;
        width: 2rem;
        height: 0.6rem;
        margin-right: 0.2rem;
        margin-top: 0.15rem;
      }
    }
  }
  .bottom {
    text-align: center;
    img {
      width: 60%;
    }
  }
}
</style>