import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 阻止router重复点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

//首页
import Home from '@/views/Home.vue'
//首页搜索
import Search from '../views/Search.vue'

//登录
import Login from '../views/Login/Login.vue'
import Verify from '../views/Login/Verify.vue'

//微信登录
import wechatLogin from '@/views/wechatLogin'


//酒店 hotel_input_accomplish
import allHotel from '@/views/Hotel/allHotel'
import Hotel from '@/views/Hotel/children/Hotel'
import SearchHotelList from '@/views/Hotel/children/SearchHotelList'
import HotelList from '@/views/Hotel/children/HotelList'
import NewHotelList from '@/views/Hotel/children/NewHotelList'
import HotelDatail from '@/views/Hotel/children/HotelDatail'
import HotelOrder from '@/views/Hotel/children/HotelOrder'
import orderDtra from '@/views/Hotel/children/orderDtra'
import HotelOrderDetail from '@/views/Hotel/children/HotelOrderDetail'
import HotelMyOrder from '@/views/Hotel/children/HotelMyOrder'
import Esports from '@/views/Hotel/children/Esports'
import hotel_input from '@/views/Hotel/children/hotel_input'
import hotel_input_accomplish from '@/views/Hotel/children/hotel_input_accomplish'
import hotel_headquarters from '@/views/Hotel/children/hotel_headquarters'
import HotelPay from '@/views/Hotel/children/HotelPay'
import hotelMap from '@/views/Hotel/children/hotelMap'
import WorkerBind from '@/views/Hotel/children/WorkerBind'
import Hoteltest from '@/views/Hotel/children/Hoteltest'
import HotelComment from '@/views/Hotel/children/HotelComment'
import HotelEvaluate from '@/views/Hotel/children/HotelEvaluate'
import Attract_investment from '@/views/Hotel/children/Attract_investment'
import router_Attract from '@/views/Hotel/children/router_Attract'
//商城
import allShop from '@/views/Shop/allShop'
import shopHome from '@/views/Shop/children/shopHome'
import shopHome_two from '@/views/Shop/children/shopHome_2.vue'
import shopSort from '@/views/Shop/children/shopSort'
import shopSearch from '@/views/Shop/children/shopSearch'
import shopList from '@/views/Shop/children/shopList'
import shopDetail from '@/views/Shop/children/shopDetail'
import shopAddress from '@/views/Shop/children/shopAddress'
import shopConfirmOrder from '@/views/Shop/children/shopConfirmOrder'
import shopCart from '@/views/Shop/children/shopCart'
import shopOrderDetail from '@/views/Shop/children/shopOrderDetail'
import shopMyOrder from '@/views/Shop/children/shopMyOrder'
import shopService from '@/views/Shop/children/shopService'
import shopAfterSale from '@/views/Shop/children/shopAfterSale'
import shopAfterSale_money from '@/views/Shop/children/shopAfterSale_money'

//会员权益
import allVip from '@/views/Vip/allVip'
import vipHome from '@/views/Vip/children/vipHome'
import vipSpec from '@/views/Vip/children/vipSpec'
import vipMyOrder from '@/views/Vip/children/vipMyOrder'

//目的地
import allDest from '@/views/Destination/allDest'
import destination from '@/views/Destination/children/destination'

//个人中心
import allPerInfo from '@/views/PersonalCenter/allPerInfo'
import personalHome from '@/views/PersonalCenter/children/personalHome'
import wallet from '@/views/PersonalCenter/children/wallet'
import coupons from '@/views/PersonalCenter/children/coupons'
import balance from '@/views/PersonalCenter/children/balance'
import details from '@/views/PersonalCenter/children/details'
import agreement from '@/views/PersonalCenter/children/agreement'
import collect from '@/views/PersonalCenter/children/collect'
import evaluation from '@/views/PersonalCenter/children/evaluation'

//微爱
import weiaiHome from '@/views/weiai/weiaiHome'
import weiaiRegister from '@/views/weiai/weiaiRegister'
import registerSuccess from '@/views/weiai/registerSuccess'
//实名认证
import realName from '@/views/realName/realName.vue'
import test from '@/views/test/test.vue'
//银行卡\
import bankSetting from '@/views/bankSetting/bankSetting.vue'
//绑定手机号
import bindPhone from '@/views/Phone/bindPhone.vue'
//飞机+酒店
import Plane_Hotel from '@/views/Plane_Hotel/Plane_Hotel.vue'
//国内游
import Allinland from '@/views/inland/Allinland.vue'
import inland_home from '@/views/inland/children/inland_home.vue'
// 签证
import visa_home from '@/views/visa/visa_home.vue'
//周边游
import Allneighboring from '@/views/neighboring/Allneighboring.vue'
import neighboring_home from '@/views/neighboring/children/neighboring_home.vue'
// 出境游
import Alloutbound from '@/views/outboundTrip/Alloutbound.vue'
import Alloutbound_home from '@/views/outboundTrip/children/outbound_trip_home.vue'
//定制游
import AllCustom from '@/views/custom/AllCustom.vue'
import custom_home from '@/views/custom/children/custom_home.vue'
import custom_destination from '@/views/custom/children/custom_destination.vue'
import custom_serve from '@/views/custom/children/custom_serve.vue'
// 协议
import Allagreement from '@/views/agreement/Allagreement.vue'
import user from '@/views/agreement/children/user.vue'
import privacy from '@/views/agreement/children/privacy.vue'

const routes = [
  // //部署的正式环境
  {
    path: '/',
    redirect: {
      name: 'wechatLogin'
    }
  },
  // 用谷歌浏览器调式时
  // {
  //   path: '/',
  //   redirect: {
  //     name: 'Home'
  //   }
  // },
  {
    path: '/Attract_investment',
    redirect: {
      name: 'Attract_investment'
    }
  },
  {
    path: 'Attract_investment',
    name: 'Attract_investment',
    component: Attract_investment,
    meta: {
      title: "电竞招商界面",
      index: 6
    },
  },
  {
    path: '/bindPhone',
    name: 'bindPhone',
    component: bindPhone,
    meta: {
      title: "绑定手机",
      index: 14
    }
  },
  {
    path: '/bankSetting',
    name: 'bankSetting',
    component: bankSetting,
    meta: {
      title: "银行绑定",
      index: 13
    }
  },
  {
    path: '/test',
    name: 'test',
    component: test,
    meta: {
      title: "测试",
      index: 12
    }
  },
  {
    path: '/Search',
    name: 'Search',
    component: Search,
    meta: {
      title: "搜索",
      index: 11
    }
  },
  //实名认证
  {
    path: '/realName',
    name: 'realName',
    component: realName,
    meta: {
      title: "实名认证",
      index: 10
    }
  },
  //首页
  {
    path: '/Home',
    name: 'Home',
    component: Home,

    meta: {
      title: "观视界",
      // requireAuth:true,
      index: 1,
      keepAlive: false
    }
  },
  //微信登录
  {
    path: '/wechatLogin',
    name: 'wechatLogin',
    component: wechatLogin,
    meta: {
      title: "登录",
      // requireAuth:true,
      index: 1
    }
  },
  //登录
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      title: "登录",
      index: 2
    }
  },
  {
    path: '/Login/Verify',
    name: 'Verify',
    component: Verify,
    meta: {
      title: "验证",
      index: 3
    }
  },
  //酒店
  {
    path: '/allHotel',
    name: 'allHotel',
    component: allHotel,
    children: [{
        path: 'Hotel',
        name: 'Hotel',
        component: Hotel,
        meta: {
          title: "酒店首页",
          index: 3
        },
      },
      {
        path: 'HotelList',
        name: 'HotelList',
        component: HotelList,
        meta: {
          title: "酒店",
          index: 4,
          keepAlive: false, //此组件需要被缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },

      {
        path: 'HotelDatail',
        name: 'HotelDatail',
        component: HotelDatail,
        meta: {
          title: "酒店详情",
          index: 5,
        },
      },
      {
        path: 'HotelOrder',
        name: 'HotelOrder',
        component: HotelOrder,
        meta: {
          title: "酒店订单",
          index: 6
        },
      },
      {
        path: 'orderDtra',
        name: 'orderDtra',
        component: orderDtra,
        meta: {
          title: "订单详情",
          index: 7
        },
      },
      {
        path: 'HotelOrderDetail',
        name: 'HotelOrderDetail',
        component: HotelOrderDetail,
        meta: {
          title: "订单详情",
          index: 9,
        },
      },
      {
        path: 'HotelMyOrder',
        name: 'HotelMyOrder',
        component: HotelMyOrder,
        meta: {
          title: "我的订单",
          index: 8
        },
      },
      {
        path: 'Esports',
        name: 'Esports',
        component: Esports,
        meta: {
          title: "电竞板块",
          index: 4
        },
      },
      {
        path: 'WorkerBind',
        name: 'WorkerBind',
        component: WorkerBind,
        meta: {
          title: "酒店工作人员绑定",
          index: 4
        },
      },
      {
        path: 'Hoteltest',
        name: 'Hoteltest',
        component: Hoteltest,
        meta: {
          title: "酒店测试页面",
          index: 4
        },
      },
      {
        path: 'HotelComment',
        name: 'HotelComment',
        component: HotelComment,
        meta: {
          title: "酒店个人评价",
          index: 10
        },
      },
      {
        path: 'HotelEvaluate',
        name: 'HotelEvaluate',
        component: HotelEvaluate,
        meta: {
          title: "酒店评价",
          index: 6
        },
      },
      {
        path: 'Attract_investment',
        name: 'Attract_investment',
        component: Attract_investment,
        meta: {
          title: "电竞招商界面",
          index: 6
        },
      },
      {
        path: 'router_Attract',
        name: 'router_Attract',
        component: router_Attract,
        meta: {
          title: "招商跳转",
          index: 5
        },
      },
      {
        path: 'hotel_input',
        name: 'hotel_input',
        component: hotel_input,
        meta: {
          title: "酒店信息录入",
          index: 11
        },
      },
      {
        path: 'HotelPay',
        name: 'HotelPay',
        component: HotelPay,
        meta: {
          title: "免密支付",
          index: 10
        },
      },
      {
        path: 'hotelMap',
        name: 'hotelMap',
        component: hotelMap,
        meta: {
          title: "酒店位置",
          index: 10
        },
      },
      {
        path: 'SearchHotelList',
        name: 'SearchHotelList',
        component: SearchHotelList,
        meta: {
          title: "酒店",
          index: 12,
          keepAlive: false, //此组件需要被缓存
          isBack: false, //用于判断上一个页面是哪个
        },
      },
      {
        path: 'NewHotelList',
        name: 'NewHotelList',
        component: NewHotelList,
        meta: {
          title: "酒店详情new",
          index: 13
        },
      },
      {
        path: 'hotel_input_accomplish',
        name: 'hotel_input_accomplish',
        component: hotel_input_accomplish,
        meta: {
          title: "填写完成",
          index: 14
        },
      },
      {
        path: 'hotel_headquarters',
        name: 'hotel_headquarters',
        component: hotel_headquarters,
        meta: {
          title: "某品牌酒店",
          index: 14
        },
      },
    ]
  },
  //目的地
  // allDest/destination
  {
    path: '/allDest',
    name: 'allDest',
    component: allDest,
    children: [{
      path: 'destination',
      name: 'destination',
      component: destination,
      meta: {
        title: "目的地",
        index: 3
      },
    }]
  },
  //商城
  {
    path: '/allShop',
    name: 'allShop',
    component: allShop,
    children: [{
        path: 'shopHome',
        name: 'shopHome',
        component: shopHome,
        meta: {
          title: "商城首页",
          index: 3
        },
      },
      {
        path: 'shopHome_two',
        name: 'shopHome_two',
        component: shopHome_two,
        meta: {
          title: "商城改版",
          index: 21
        },
      },
      {
        path: 'shopSort',
        name: 'shopSort',
        component: shopSort,
        meta: {
          title: "商城分类",
          index: 4
        },
      },
      {
        path: 'shopSearch',
        name: 'shopSearch',
        component: shopSearch,
        meta: {
          title: "商城搜索",
          index: 5
        },
      },
      {
        path: 'shopList',
        name: 'shopList',
        component: shopList,
        meta: {
          title: "商品列表",
          index: 6
        },
      },
      {
        path: 'shopDetail',
        name: 'shopDetail',
        component: shopDetail,
        meta: {
          title: "商品详情",
          index: 7
        },
      },
      {
        path: 'shopAddress',
        name: 'shopAddress',
        component: shopAddress,
        meta: {
          title: "我的收货地址",
          index: 8
        },
      },
      {
        path: 'shopCart',
        name: 'shopCart',
        component: shopCart,
        meta: {
          title: "购物车",
          index: 9
        },
      },
      {
        path: 'shopConfirmOrder',
        name: 'shopConfirmOrder',
        component: shopConfirmOrder,
        meta: {
          title: "确认订单",
          index: 10
        },
      },
      {
        path: 'shopOrderDetail',
        name: 'shopOrderDetail',
        component: shopOrderDetail,
        meta: {
          title: "订单详情",
          index: 10
        },
      },
      {
        path: 'shopMyOrder',
        name: 'shopMyOrder',
        component: shopMyOrder,
        meta: {
          title: "订单",
          index: 11
        },
      },
      {
        path: 'shopService',
        name: 'shopService',
        component: shopService,
        meta: {
          title: "选择服务",
          index: 12
        },
      },
      {
        path: 'shopAfterSale',
        name: 'shopAfterSale',
        component: shopAfterSale,
        meta: {
          title: "商城产品售后",
          index: 13
        },
      },
      {
        path: 'shopAfterSale_money',
        name: 'shopAfterSale_money',
        component: shopAfterSale_money,
        meta: {
          title: "商城产品售后_退款",
          index: 14
        },
      },
    ]
  },
  //国内游
  {
    path: '/Allinland',
    name: 'Allinland',
    component: Allinland,
    children: [{
      path: 'inland_home',
      name: 'inland_home',
      component: inland_home,
      meta: {
        title: "国内游",
        index: 3
      },
    }, ]
  },
  //签证
  {
    path: '/visa_home',
    name: 'visa_home',
    component: visa_home,

  },
  //周边游
  {
    path: '/Allneighboring',
    name: 'Allneighboring',
    component: Allneighboring,
    children: [{
      path: 'neighboring_home',
      name: 'neighboring_home',
      component: neighboring_home,
      meta: {
        title: "周边游",
        index: 3
      },
    }, ]
  },

  //出境游
  {
    path: '/Alloutbound',
    name: 'Alloutbound',
    component: Alloutbound,
    children: [{
      path: 'Alloutbound_home',
      name: 'Alloutbound_home',
      component: Alloutbound_home,
      meta: {
        title: "出境游",
        index: 3
      },
    }, ]
  },
  //定制游
  {
    path: '/AllCustom',
    name: 'AllCustom',
    component: AllCustom,
    children: [{
        path: 'custom_home',
        name: 'custom_home',
        component: custom_home,
        meta: {
          title: "定制游",
          index: 3
        },
      },
      {
        path: 'custom_destination',
        name: 'custom_destination',
        component: custom_destination,
        meta: {
          title: "定制游-目的地",
          index: 4
        },
      },
      {
        path: 'custom_serve',
        name: 'custom_serve',
        component: custom_serve,
        meta: {
          title: "定制游-定制服务",
          index: 5
        },
      },
    ]
  },
  // 平台协议
  {
    path: '/Allagreement',
    name: 'Allagreement',
    component: Allagreement,
    children: [{
        path: 'user',
        name: 'user',
        component: user,
        meta: {
          title: "用户协议",
          index: 10
        },
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: privacy,
        meta: {
          title: "隐私政策",
          index: 10
        },
      }
    ]
  },
  //会员权益
  {
    path: '/allVip',
    name: 'allVip',
    component: allVip,
    children: [{
        path: 'vipHome',
        name: 'vipHome',
        component: vipHome,
        meta: {
          title: "会员权益",
          index: 3
        },
      },
      {
        path: 'vipSpec',
        name: 'vipSpec',
        component: vipSpec,
        meta: {
          title: "规格明细",
          index: 4
        },
      },
      {
        path: 'vipMyOrder',
        name: 'vipMyOrder',
        component: vipMyOrder,
        meta: {
          title: "特权订单",
          index: 5
        },
      },
    ]
  },
  //个人中心
  {
    path: '/allPerInfo',
    name: 'allPerInfo',
    component: allPerInfo,
    children: [{
        path: 'personalHome',
        name: 'personalHome',
        component: personalHome,
        meta: {
          title: "个人中心",
          index: 3
        },
      },
      {
        path: 'wallet',
        name: 'wallet',
        component: wallet,
        meta: {
          title: "钱包",
          index: 11
        },
      },
      {
        path: 'coupons',
        name: 'coupons',
        component: coupons,
        meta: {
          title: "优惠卷",
          index: 5
        },
      },
      {
        path: 'balance',
        name: 'balance',
        component: balance,
        meta: {
          title: "余额",
          index: 6
        },
      },
      {
        path: 'details',
        name: 'details',
        component: details,
        meta: {
          title: "余额",
          index: 7
        },
      },
      {
        path: 'agreement',
        name: 'agreement',
        component: agreement,
        meta: {
          title: "观视界充值协议",
          index: 7
        },
      },
      {
        path: 'collect',
        name: 'collect',
        component: collect,
        meta: {
          title: "个人收藏",
          index: 7
        },
      },
      {
        path: 'evaluation',
        name: 'evaluation',
        component: evaluation,
        meta: {
          title: "我的评价",
          index: 7
        },
      }
    ]
  },

  //微爱
  {
    path: '/weiaiHome',
    name: 'weiaiHome',
    component: weiaiHome,
    meta: {
      title: "观视界",
      // requireAuth:true,
      index: 99
    }
  },
  {
    path: '/weiaiRegister',
    name: 'weiaiRegister',
    component: weiaiRegister,
    meta: {
      title: "观视界",
      // requireAuth:true,
      index: 100
    }
  },
  {
    path: '/registerSuccess',
    name: 'registerSuccess',
    component: registerSuccess,
    meta: {
      title: "注册成功",
      // requireAuth:true,
      index: 100
    }
  },
  {
    path: '/Plane_Hotel',
    name: 'Plane_Hotel',
    component: Plane_Hotel,
    meta: {
      title: "飞机+酒店",
      // requireAuth:true,
      index: 100
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router