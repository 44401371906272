<template>
  <div>
    <div class="address_action_sheet">
      <van-action-sheet v-model="addressShow"
                        title="目的地选择"
                        @cancel='cancelA'
                        @click-overlay='clickOverlayA'>
        <div class="content">
          <form action="/">
            <van-search v-model="SearchValue"
                        placeholder="请输入搜索关键词"
                        @search="goSearch(SearchValue)" />
          </form>
          <van-tabs v-model="active">
            <van-tab title="国内(含港澳台)">
              <div class="myAddress">
                <div>
                  <span class="myA_left">我的位置</span>
                  <span class="myA_right"
                        @click="reposition">
                    <van-icon name="replay" />重新定位
                  </span>
                </div>
                <div class="myA_text">
                  <van-icon name="location" />{{address}}-{{business}}
                </div>
                <div style="margin-top:0.25rem">
                  <div id="btn_tag"
                       @click="getbtn_Address2"
                       :class="{'is_active':isActive}">{{city}}</div>
                </div>
              </div>
              <div class="histChose">
                <div>
                  <span class="hiC_left">历史选择</span>
                  <span class="hiC_right">清空</span>
                </div>
                <div style="margin-top:0.25rem;">
                  <div id="btn_tag"
                       v-for="item in historyChoice"
                       :key="item.id"
                       @click="getbtn_Address1(item)"
                       :class="{'is_active':false}">
                    <div class="button">{{item.name}}</div>
                  </div>
                </div>
              </div>
              <div class="histChose">
                <div>
                  <span class="hiC_left">热门城市</span>
                </div>
                <div style="margin-top:0.25rem;">
                  <div id="btn_tag"
                       :class="{'is_active':false}"
                       v-for="item in hotCity"
                       :key="item.id"
                       @click="getbtn_Address1(item)">
                    <div class="button">{{item.name}}</div>
                  </div>
                </div>
              </div>
              <div class="letterChose">
                <div>
                  <span class="ltC_left">字母索引</span>
                </div>
                <div class="indexBar">
                  <van-index-bar :sticky='false'
                                 :index-list="FirstPin">
                    <div v-for="(item,index) in cityName"
                         :key="index">
                      <van-index-anchor :index="index">
                        <b>{{index}}</b>

                        <van-cell v-for="(it,i) in item"
                                  @click="getCityInfo(it)"
                                  :key="i">{{it.name}}</van-cell>
                      </van-index-anchor>
                    </div>
                  </van-index-bar>
                </div>
              </div>

            </van-tab>
            <van-tab title="海外">
              <div class="overseaPart">
                <van-index-bar :index-list="indexList"
                               :sticky='false'>
                  <van-index-anchor class="active"
                                    index="历史/热门">我的位置</van-index-anchor>
                  <div class="myAddress">
                    <div>

                      <span class="myA_right">重新定位</span>
                    </div>
                    <div class="myA_text">广东省东莞市莞城街道创客谷</div>
                  </div>
                  <div style="margin-top:0.25rem;margin-left: 0.25rem;">
                    <div style="font-size:0.23rem;font-weight:bold">热门城市</div>
                    <div id="btn_tag"
                         @click="getbtn_Address1"
                         :class="{'is_active':false}"
                         v-for="item in 12"
                         :key="item.id">
                      <div class="button">东莞</div>
                    </div>
                  </div>
                  <!-- <div class="OP_letter">
                    <van-index-anchor index="字母索引">字母索引
                      <letterIndex></letterIndex>
                    </van-index-anchor>
                  </div> -->
                </van-index-bar>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </van-action-sheet>
      <div v-show="showMap">
        <baidu-map :center="center"
                   :scroll-wheel-zoom="true"
                   @ready="initMap"
                   ak='VFc7NsfU7amcxYbEiqypp5a3gHPLaiOO'
                   @click="handler">
          <bm-marker :position="point"
                     :dragging="true">
            <bm-label :label-style="{ color: 'red', fontSize: '14px' }"
                      :offset="{ width: 20, height: -15 }"
                      content="当前位置" />
          </bm-marker>
        </baidu-map>
      </div>
    </div>
  </div>
</template>
<script>
import Bar_address from '@/utils/address'
// import letterIndex from "./letterIndex";
// import { BMPGL } from '@/commom/bmpgl.js'
import { getHomeData } from '@/api/hotel.js'
import { Address_three } from '@/api/address.js'

import { Toast } from 'vant';
export default {
  components: {
    // letterIndex,
  },
  props: {
    addressShow: Boolean
  },
  data () {
    return {

      //百度地图
      BMap: '',
      address: '',
      business: '',
      city: '',
      ak: 'VFc7NsfU7amcxYbEiqypp5a3gHPLaiOO',
      point: {},
      center: {
        lat: '',
        lng: ''
      },
      showMap: false,
      //地址弹窗
      active: 0,//form表单的标签页默认选中第一个
      SearchValue: '',//搜索框的关键字
      isActive: true,
      bar_city: null,
      indexList: ["历史/热门", "字母索引"],
      hotCity: [ //热门城市
        {
          child: Array(0),
          city_id: 10800,
          code: 310100000000,
          first: "S",
          level: 2,
          name: "上海市",
          pcode: 310000000000,
        },
        {
          child: Array(0),
          city_id: 2,
          code: 110100000000,
          first: "B",
          level: 2,
          name: "北京市",
          pcode: 110000000000,
        },
        {
          child: Array(0),
          city_id: 12706,
          code: 330100000000,
          first: "H",
          level: 2,
          name: "杭州市",
          pcode: 330000000000,
        },
        {
          child: Array(0),
          city_id: 32508,
          code: 510100000000,
          first: "C",
          level: 2,
          name: "成都市",
          pcode: 510000000000,
        },
        {
          child: Array(0),
          city_id: 27813,
          code: 440100000000,
          first: "G",
          level: 2,
          name: "广州市",
          pcode: 440000000000,
        },
        {
          child: Array(0),
          city_id: 28128,
          code: 440300000000,
          first: "S",
          level: 2,
          name: "深圳市",
          pcode: 440000000000,
        },
        {
          child: Array(0),
          city_id: 24027,
          code: 420100000000,
          first: "W",
          level: 2,
          name: "武汉市",
          pcode: 420000000000,
        },
        {
          child: Array(0),
          city_id: 31438,
          code: 500100000000,
          first: "Z",
          level: 2,
          name: "重庆市",
          pcode: 500000000000,
        },
        {
          child: Array(0),
          city_id: 39979,
          code: 610100000000,
          first: "X",
          level: 2,
          name: "西安市",
          pcode: 610000000000,
        },
        {
          child: Array(0),
          city_id: 31213,
          code: 460200000000,
          first: "S",
          level: 2,
          name: "三亚市",
          pcode: 460000000000,
        },
        {
          child: Array(0),
          city_id: 29422,
          code: 441900000000,
          first: "D",
          level: 2,
          name: "东莞市",
          pcode: 440000000000,
        },
        {
          child: Array(0),
          city_id: 358,
          code: 120100000000,
          first: "T",
          level: 2,
          name: "天津市",
          pcode: 120000000000,
        }
      ],
      FirstPin: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "J",
        "K",
        "L",
        "M",
        "N",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "W",
        "X",
        "Y",
        "Z"
      ],
      cities: {},
      searchAddress: null,
      cityName: null,
      historyChoice: [],
      sheetData: null,
      cityInfo: {
        child: Array(0),
        city_id: 29422,
        code: 441900000000,
        first: "D",
        level: 2,
        name: "东莞市",
        pcode: 440000000000,
      }
    }
  },
  mounted () {
    if (JSON.parse(localStorage.getItem('historyChoice')) != null) {
      this.historyChoice = JSON.parse(localStorage.getItem('historyChoice'))
      if (this.historyChoice.length > 4) {
        this.historyChoice.splice(0, this.historyChoice.length - 4)
        let localData = JSON.stringify(this.historyChoice)
        localStorage.setItem('historyChoice', localData)
      }
    }

    // this.getAddressInfo()
  },
  created () {
    this.getBar_address()
  },

  methods: {
    getCityInfo (it) {
      this.$emit('getCityInfo', it)
      console.log(it);
      let data2 = {
        city_id: it.city_id,
        status: 1
      }
      getHomeData(data2).then(res => {
        this.sheetData = res.data.data.list

        this.$emit('getsheet_Data', this.sheetData)
        this.cityInfo = {
          child: Array(0),
          city_id: it.city_id,
          code: it.code,
          level: it.level,
          name: it.name,
          pcode: it.pcode,
        }
        this.$emit("getbtn_Address1", this.cityInfo)
      })
    },
    getBar_address () {
      this.cities = Bar_address
      let cityName = {};
      //循环26个字母的数组
      this.FirstPin.forEach(item => {
        cityName[item] = [];
        this.cities.forEach(el => {
          //对比开头字母是否对应
          let first = el.first.substring(0, 1).toUpperCase();
          if (first == item) {
            cityName[item].push(el);
          }
        });
      });
      this.cityName = cityName
      // console.log(Bar_address, "===cityName====s");
      // console.log(this.cities, "=== this.cities====s");

    },


    handler (e) {
      // console.log(e);
      const that = this
      this.city = e.address.city
      const latitude = localStorage.getItem("latitude")
      const longitude = localStorage.getItem("longitude")

      const myGeo = new window.BMap.Geocoder({ extensions_town: true })
      myGeo.getLocation(new window.BMap.Point(longitude, latitude),
        function (result) {
          console.log(result);
          that.address = result.address
          that.business = result.business
          // console.log(this.address);
        })
      this.point = { lat: latitude, lng: longitude }
    },
    initMap ({ BMap }) {
      this.BMap = BMap
      // 通过百度map获取经纬度再传给地图组件
      const that = this
      try {
        const geolocation = new window.BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            // console.log(r);
            const latitude = localStorage.getItem("latitude")
            const longitude = localStorage.getItem("longitude")
            that.center = { lat: latitude, lng: longitude }
            that.point = { lat: latitude, lng: longitude }
            that.handler(r)
          },
          { enableHighAccuracy: true }
        )
      } catch (error) {
        this.$toast('请打开定位或退出重试')
      }
    },
    reposition () {
      this.$router.go(0)
    },

    // getAddress () {
    //   BMPGL(this.ak).then((BMapGL) => {
    //     const that = this
    //     const geoc = new BMapGL.Geocoder({ extensions_town: true })
    //     let latitude = sessionStorage.getItem('latitude')
    //     let longitude = sessionStorage.getItem('longitude')
    //     let lat = latitude
    //     let lng = longitude
    //     geoc.getLocation(new BMapGL.Point(lng, lat), function (rs) {
    //       console.log(rs);
    //       const addComp = rs.addressComponents;
    //       // console.log(addComp, "==========")
    //       // this.city = addComp.city
    //       that.address = addComp.province + addComp.city + addComp.street
    //       console.log(that.address, "======= address======");
    //     })
    //   })
    // },
    //按钮获取地址
    goSearch (SearchValue) {
      if (SearchValue == '') {
        Toast.fail('搜索不能为空')
        return
      }
      // console.log(SearchValue, "====SearchValue====");
      // this.cities = Bar_address
      this.cities.filter(item => {
        let newSeachValue = SearchValue + '市' //eslint-disable-line no-unused-vars
        if (item.name == newSeachValue) {
          console.log(item);

          this.searchAddress = item
          this.$router.push({ path: '/allHotel/HotelList' })
          let data = {
            city_id: item.city_id,
            status: 1
          }
          getHomeData(data).then(res => {
            this.sheetData = res.data.data.list
            this.$emit('getsheet_Data', this.sheetData)
          })
          this.historyChoice.push(item)
          let obj = {}
          this.historyChoice = this.historyChoice.reduce(function (a, b) {
            obj[b.city_id] ? '' : obj[b.city_id] = true && a.push(b)
            return a
          }, [])
          let localData = JSON.stringify(this.historyChoice)
          localStorage.setItem('historyChoice', localData)
          if (this.historyChoice.length > 4) {
            this.historyChoice.splice(0, this.historyChoice.length - 4)
            let localData22 = JSON.stringify(this.historyChoice)
            localStorage.setItem('historyChoice', localData22)
          }
          this.$emit('getbtn_Address1', item)
        }
      })

      // // this.HotelSearchValue = new Array (4)
      // // console.log(this.HotelSearchValue.length);
      // // console.log(SearchValue, "主页搜索");
      // localStorage.setItem("HomeValue", SearchValue)

      // sessionStorage.setItem("searchValue", SearchValue)
      // sessionStorage.setItem("sessionCityData", JSON.stringify(this.searchAddress))


      // let HomeValue = {
      //   search: this.SearchValue
      // }
      // console.log(this.cityInfo);
      // let sessionCityData = []
      // sessionCityData = JSON.stringify(this.cityInfo)
      // sessionStorage.setItem('sessionCityData', sessionCityData)
      // console.log(HomeValue);
      // // this.getAddressInfo()
      // this.$router.push({ path: '/allHotel/HotelList', query: { SearchValue: HomeValue, status: 1 } })
      // // console.log(HomeValue);

    },
    getbtn_Address2 () {
      let data = {
        name: this.city
      }
      console.log(data, "======data======");
      Address_three(data).then(res => {
        this.cityInfo = res.data.data
        this.cityInfo = {
          child: Array(0),
          city_id: this.cityInfo.id,
          code: this.cityInfo.code,
          level: this.cityInfo.level,
          name: this.cityInfo.name,
          pcode: this.cityInfo.pcode,
        }
        let sessionCityData = []
        sessionCityData = JSON.stringify(this.cityInfo)
        sessionStorage.setItem('sessionCityData', sessionCityData)
        console.log(this.cityInfo, "获取地址信息");
        // alert(res)
        let data2 = {
          city_id: this.cityInfo.city_id,
          status: 1
        }
        getHomeData(data2).then(res => {
          this.sheetData = res.data.data.list
          this.$emit('getsheet_Data', this.sheetData)
        })
      })
      console.log("点击了", this.cityInfo);
      this.$emit("getbtn_Address1", this.cityInfo)


    },
    getbtn_Address1 (cityInfo) {
      console.log(cityInfo);
      if (this.$route.path == "/allHotel/HotelList") {
        let data = {
          city_id: cityInfo.city_id,
          status: 1
        }
        getHomeData(data).then(res => {
          this.sheetData = res.data.data.list
          this.$emit('getsheet_Data', this.sheetData)
        })
      }
      this.historyChoice.push(cityInfo)
      let obj = {}
      this.historyChoice = this.historyChoice.reduce(function (a, b) {
        obj[b.city_id] ? '' : obj[b.city_id] = true && a.push(b)
        return a
      }, [])
      let localData = JSON.stringify(this.historyChoice)
      localStorage.setItem('historyChoice', localData)
      if (this.historyChoice.length > 4) {
        this.historyChoice.splice(0, this.historyChoice.length - 4)
        let localData22 = JSON.stringify(this.historyChoice)
        localStorage.setItem('historyChoice', localData22)
      }
      this.$emit('getbtn_Address1', cityInfo)
    },
    //关闭按钮
    cancelA () {
      this.$emit('cancelA')
    },
    //点击遮罩层
    clickOverlayA () {
      this.$emit('clickOverlayA')
    },
    getCity (data, historyChoice) {
      console.log("点击了");
      this.historyChoice = historyChoice
      let obj = {}
      this.historyChoice = this.historyChoice.reduce(function (a, b) {
        obj[b.city_id] ? '' : obj[b.city_id] = true && a.push(b)
        return a
      }, [])
      if (this.historyChoice.length > 4) {
        this.historyChoice.splice(0, this.historyChoice.length - 4)
        let localData22 = JSON.stringify(this.historyChoice)
        localStorage.setItem('historyChoice', localData22)
      }
      this.$emit('getCity', data)
    }
  }
}
</script>
<style lang="less" scoped>
.address_action_sheet {
  .van-popup--bottom {
    top: 10%;
  }
  .van-action-sheet {
    max-height: 90%;
    .van-action-sheet__header {
      font-size: 0.3rem;
    }
    .content {
      height: 100%;
      font-size: 0.18rem;
      position: relative;
      /deep/.van-tabs__nav--line {
        position: fixed;
        width: 7rem;
        height: 0.323rem;
        z-index: 10;
        top: 23%;
        // background-color: aqua;
      }
      /deep/.van-tab__text--ellipsis {
        overflow: visible;
        padding-top: 20px;
        // width: 100%;
      }
      .van-search {
        position: fixed;
        width: 100%;
        top: 15%;
        z-index: 10;
      }
      .van-search__content {
        border-radius: 20px;
      }
      /deep/.van-tab {
        font-size: 0.25rem;
        padding-top: 0.1rem;
      }
      /deep/.van-tab--active {
        font-weight: bold;
      }
      /deep/.van-tabs__line {
        background-color: #36e0c9;
        bottom: -5px;
        width: 0.4rem;
      }
      /deep/.van-tabs__content {
        width: 97% !important;
        margin-left: 0px !important;
        box-shadow: none !important;
        padding-left: 0.2rem;
      }
      .myAddress {
        margin-top: 0.65rem;
        .myA_left {
          font-size: 0.24rem;
          font-weight: bold;
        }
        .myA_right {
          float: right;
          margin-right: 0.5rem;
          color: #0d9ff0;
        }
        .myA_text {
          font-size: 0.24rem;
          margin-top: 0.15rem;
        }
      }
      .histChose {
        margin-top: 0.3rem;
        .hiC_left {
          font-size: 0.24rem;
          font-weight: bold;
        }
        .hiC_right {
          float: right;
          margin-right: 0.5rem;
          color: #999999;
        }
      }
      .letterChose {
        margin-top: 0.3rem;
        .ltC_left {
          font-weight: bold;
          font-size: 0.24rem;
        }
        /deep/.van-index-anchor {
          font-weight: bold;
        }
        #btn_tag {
          width: 5%;
        }
        .indexBar {
          margin-top: 0.5rem;
          /deep/.van-index-bar__sidebar {
            top: 62% !important;
          }
          /deep/.van-index-bar__index {
            margin-top: 0.1rem;
            color: #666666;
          }
        }
      }
    }
    #btn_tag {
      padding-right: 0.25rem;
      width: 11%;
    }
    .overseaPart {
      margin-top: 30px;
      margin-left: 1rem;

      /deep/.van-index-bar__sidebar {
        left: 0%;
        top: 78%;
        width: 18%;
        height: 100%;
        background: #f7f8fa;
        .van-index-bar__index {
          text-align: right;
          font-size: 0.23rem;
          padding-top: 0.15rem;
          padding-bottom: 0.15rem;
          padding-right: inherit;
          text-align: center;
        }
        .van-index-bar__index--active {
          color: black;
          background-color: white;
          font-weight: bold;
        }
        /deep/.van-index-anchor--sticky {
          top: 12%;
        }
      }
      .myAddress {
        margin-top: 0;
        margin-left: 0.25rem;
      }
      #letter_tag {
        display: inline-block;
        border: 1px solid #dedede;
        text-align: center;
        padding: 0.15rem 0.3rem;
        border-radius: 6px;
        font-size: 0.25rem;
        margin-left: 0.1rem;
        width: 5%;
        font-weight: 500;
        margin-top: 0.05rem;
      }
      .OP_letter {
        font-size: 0.1rem;
        .van-cell {
          font-size: 0.1rem;
          color: gray;
        }
        /deep/.van-index-bar__sidebar {
          left: 84% !important;
          height: 0% !important;
          top: 40% !important;
        }
        /deep/.van-index-bar__index {
          padding-bottom: 0rem;
          padding-top: 0.05rem;
          font-size: 0.1rem;
          color: #929292;
          font-weight: 300;
        }
      }
    }
  }
}
#btn_tag {
  display: inline-block;
  border: 1px solid #dedede;
  text-align: center;
  padding: 0.15rem 0.3rem;
  border-radius: 6px;
  font-size: 0.25rem;
  margin-left: 0.1rem;
  width: 13%;
  margin-top: 0.05rem;
  .button {
    // width: 0.8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.is_active {
  color: white;
  background-color: #36e0c9;
  border: none;
}
::v-deep .van-index-anchor {
  // font-weight: 700;
  padding: 0;
  padding-left: 10px;
  padding-top: 10px;
  b {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
  }
  .van-cell {
    font-weight: 400;
  }
}
</style>