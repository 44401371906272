import axios from 'axios'
import { request } from '@/api/request.js'


//获取阿里云OSS相关配置
export function getAliyunOssinfo (method) {
  return request({
    url: '/common/oss-upload-init',
    method,
  })
}
// export function client() {
//   var client = new OSS({
//     EndPoint: 'oss.shanci.tech',
//   //   region: 'oss.shanci.tech',
//     accessKeyId: 'LTAI4G1J3hg9zku6Y3SjoDtP',
//     accessKeySecret: 'oqBS5ljgXNjendslO9VC3FQvzKzKR8',
//     bucket: 'shanci'
//   })  //后端提供数据
//   return client
// }

//通过获取相关配置，开始上传图片
export function uploadFile (images) {
  return new Promise((resolve, reject) => {  
    getAliyunOssinfo('GET').then((res) => {
      const { accessid, policy, signature, dir, host } = res.data.data
      let img_name=null
      let ArrImg=null
      images.forEach(item => {
        // console.log(item);
        img_name=item.file.name
        ArrImg=item.file
      });
      const key = dir +img_name;
      // const key = dir + images.uid + images.name;
      console.log(key,"key");
      const formData = new FormData()
      formData.append('key', key)
      formData.append('OSSAccessKeyId', accessid)
      formData.append('policy', policy)
      formData.append('signature', signature)
      formData.append('success_action_status', '200')
      formData.append('file', ArrImg)
      axios.post(host, formData).then((res) => {
        const { status } = res
        if (status === 200) {
          const data = host + "/" + key;
          resolve(data)
        } else {
          reject(res)
        }
      }).catch(err => {
        reject(err)
      })
    }).catch(err => {
      console.log(err)
    })
  })
}
