<template>
  <div id="allShop">
    <router-view></router-view>
    <div class="IMGbottom"
         style="height: 50px;"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    vipDiscount () {
      const wechatUser = localStorage.getItem("wechatUser")
        ? JSON.parse(localStorage.getItem("wechatUser"))
        : {};
      let discount = 1;
      if (wechatUser.vip === 1) {
        discount = 0.8;
      } else if (wechatUser.vip === 0) {
        discount = 1;
      }
      return discount;
    },
  }
}
</script>

<style lang="less" scoped>
#allShop {
}
</style>