<template>
  <div class="container">
    <div class="content">
    </div>
    <div class="content_black">
      <div class="content_white"
           :class="{content_white:ys1,content_white_right:ys2}">
        <div class="top">
          <div class="top_left"
               @click="whole">个人定制</div>
          <div class="top_right"
               @click="whole">团队定制</div>
        </div>
        <div class="place">
          <span class="here">东莞</span>
          <span class="arrows"><img src="../../../assets/tour/arrows.png"
                 alt=""></span>
          <span class="destination">目的地</span>
          <van-divider />
        </div>
        <div class="date">
          <div class="fiirst_Date">2022-05</div>
          <div class="totalDay">共6天</div>
          <div class="Last_Date">2022-05</div>
          <van-divider />
        </div>
        <div class="btn">
          <van-button round
                      type="info">免费获取专属行程</van-button>
        </div>
      </div>
    </div>
    <div class="VIP"><img src="../../../assets/custom/VIP.png"
           alt=""></div>
    <div class="leave"><img src="../../../assets/custom/leave.png"
           alt=""></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      ys1: true,
      ys2: false,
      count: 0,
    }
  },
  created () {
    this.PreLoad_img()
  },
  //预加载图片
  beforeCreate () {
    let count = 0; // eslint-disable-line no-unused-vars
    let imgs = [
      //用require的方式添加图片地址，直接添加图片地址的话，在build打包之后会查找不到图片，因为打包之后的图片名称会有一个加密的字符串
      require('../../../assets/custom/bg_white_right.png')
    ]
    for (let img of imgs) {
      let image = new Image();
      image.onload = () => {
        count++;
      };
      image.src = img;
    }
  },

  methods: {

    //预加载图片
    PreLoad_img () {
      let imgs = [
        { right: '../../../assets/custom/bg_white_right.png' }
      ]
      for (let img of imgs) {
        let image = new Image()
        image.src = img
        image.onload = () => {
          this.count++
        }
      }
    },// eslint-disable-line no-unused-vars
    whole () {
      // 当点击第一次时，ys1从true变为false,Style样式type就会隐藏
      // 再点击第二次的时候由false,变为true,显示样式
      // !为取反
      this.ys1 = !this.ys1
      this.ys2 = !this.ys2
    },
    test () {
    },
    test2 () { }
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 800px;
  // background-color: aqua;
  .content {
    background-image: url("../../../assets/custom/bg.png");
    width: 100%;
    height: 222px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .content_black {
    top: -9%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../../assets/custom/bg_black.png");
    // background: rgba(51, 51, 51, 0.59);
    // border-radius: 32px;
    background-size: 100%;
    width: 352px;
    height: 248px;
    .content_white {
      background-image: url("../../../assets/custom/bg_white_left.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      width: 365px;
      height: 254px;
      top: -11px;
      left: -9px;
      .top {
        overflow: hidden;
        .top_left {
          font-size: 16px;
          font-weight: 500;
          position: absolute;
          top: 9%;
          overflow: hidden;
          left: 18%;
        }
        .top_right {
          font-size: 16px;
          font-weight: 500;
          position: absolute;
          top: 9%;
          color: white;
          left: 66%;
        }
      }
      .place {
        position: absolute;
        font-size: 20px;
        top: 30%;
        left: 10%;
        .here {
          display: inline-block;
        }
        .arrows {
          margin-left: 80px;
        }
        .destination {
          margin-left: 60px;
          display: inline-block;
          color: #cbcbcb;
          font-weight: 400;
        }
      }
      .date {
        position: absolute;
        font-size: 18px;
        top: 55%;
        left: 15%;
        .fiirst_Date {
          display: inline-block;
        }
        .totalDay {
          margin-left: 47px;
          display: inline-block;
          font-size: 12px;
        }
        .Last_Date {
          display: inline-block;
          margin-left: 68px;
        }
      }
      .btn {
        position: absolute;
        top: 63%;
        width: 100%;

        .van-button--round {
          border: none;
          background: linear-gradient(to left, #00ecaf 0%, #5ee3de 100%);
          width: 90%;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
    .content_white_right {
      background-image: url("../../../assets/custom/bg_white_right.png");
      position: absolute;
      width: 365px;
      height: 254px;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: -11px;
      left: -9px;
      .top {
        overflow: hidden;
        .top_left {
          font-size: 16px;
          font-weight: 500;
          position: absolute;
          top: 9%;
          left: 18%;
        }
        .top_right {
          font-size: 16px;
          font-weight: 500;
          position: absolute;
          top: 9%;
          color: black;
          left: 66%;
        }
      }
    }
  }
  .VIP {
    top: -9%;
    position: relative;
    img {
      width: 95%;
    }
  }
  .leave {
    top: -11%;
    position: relative;
    img {
      width: 95%;
    }
  }
}
</style>