
const state={
  wechatUser:'' || JSON.parse(localStorage.getItem("wechatUser"))
}
const getters={

  user_id:(state)=> state.wechatUser.id
}
const mutations={

}
const actions={

}
export default {
  getters,
  state,
  mutations,
  actions
}