<template>
  <div id="Attract">

    <div class="content">

      <van-popup class="Attract_phone"
                 closeable
                 v-model="show">
        <div class="logo">
          <img src="../../../assets/Attract/logo.png"
               alt="">
        </div>
        <div class="warn">为方便联系你,请填入您的手机号码</div>
        <div class="cell">
          <van-field v-model="nickname"
                     required
                     label="称呼"
                     placeholder="请输入称呼" />
          <van-field v-model="phone"
                     required
                     label="手机号"
                     placeholder="请输入手机号" />
          <van-field v-model="address"
                     label="加盟地区"
                     placeholder="如:**省**市" />
          <van-field v-model="remarks"
                     label="留言"
                     placeholder="可填写留言" />

        </div>
        <div>
          <van-button style="width:80%;border-radius: 8px;"
                      @click="sumbit"
                      color="linear-gradient(to right, #db058d, #2328c4)"> 同意并提交</van-button>
          <div class="text_2rem">点击代表你同意
            <div style="color: #50b2ff;"
                 @click="go_userAgreement">

              《用户协议》

            </div>
            和
            <div style="color: #50b2ff;"
                 @click="goPrivacy">

              《隐私协议》

            </div>
          </div>
        </div>
      </van-popup>

      <div class="Attract_top">
        <img src="../../../assets/Attract/top.jpg"
             alt="">
      </div>
      <div class="Attract_video">
        <div class="video_border">
          <img src="../../../assets/Attract/video_border.png"
               alt="">
        </div>
        <!-- <d-player ref="player"
                  :options="options"></d-player> -->
        <Dplayer ref="dplayer"
                 :dplayerData="dplayerData"></Dplayer>
        <!-- <video ref="videoPlayer"
               id="videoPlayer"
               class="video"
               width="100%"
               height="100%"
               muted
               loop
               controls
               src="https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202305/11/video.mp4">
        </video> -->
      </div>
      <div class="Attract_bottom">
        <img src="../../../assets/Attract/bottom.jpg"
             alt="">
      </div>
      <div class="To_join">
        <van-button color="linear-gradient(to right, #db058d,#2328c4)"
                    @click="showPopup"
                    style="width: 80%;border-radius: 20px;">全网加盟咨询</van-button>

      </div>
    </div>
  </div>
</template>

<script>

import Dplayer from '@/components/Dplayer'
import { Attract } from '@/api/hotel.js'
import { Toast } from 'vant';

export default {
  components: {
    Dplayer,

  },
  data () {
    return {
      show: false,
      nickname: '',
      address: '',
      remarks: '',
      phone: '',
      dplayerData: {
        style:
          'width:90%;height:4.5rem;bottom: 0;right: 0;z-index:100;top:0;left:0; margin-left:5%;position: absolute;',

      },
      info: { video: 'https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202305/11/video.mp4', pic: '../../../assets/Attract/微信图片_20230511151522.jpg' },
    }
  },
  created () {

    setTimeout(() => {
      this.switchVideo()
    }, 500)
  },
  methods: {
    go_userAgreement () {
      this.$router.push({
        path: '/Allagreement/user'
      })
    },
    goPrivacy () {
      this.$router.push({
        path: '/Allagreement/privacy'
      })
    },
    sumbit () {
      let data = {
        nickname: this.nickname,
        phone: this.phone,
        remarks: this.remarks,
        address: this.address
      }
      Attract(data).then(res => {
        if (res.data.code == 400) {
          Toast.fail(res.data.message);
        } else {
          if (res.data.message == '保存成功') {
            Toast('提交成功');
            this.show = false
            this.nickname = ''
            this.phone = ''
          }
        }
      })
    },
    switchVideo () {
      this.$refs.dplayer.switchVideo(this.info)
    },
    showPopup () {
      this.show = true;
    },
  }
}
</script>

<style lang="less" scoped>
.content {
  .To_join {
    background-image: url("../../../assets/Attract/video_bg.png");
    background-size: 100%;

    width: 100%;
    padding-bottom: 0.5rem;
    .van-button {
      position: fixed;
      left: 10%;
      bottom: 5%;
    }
  }
  .about {
    width: 100%;
    height: 800px;
    margin: 0 auto;
    text-align: center;
  }
  width: 100%;
  height: 1200px;
  .Attract_phone {
    width: 90%;
    height: 8rem;
    border-radius: 10px;
    .warn {
      font-size: 0.2em;
      color: #a1a1a1;
    }
    .cell {
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 0.15rem;
      .van-cell {
      }
    }
    .logo {
      img {
        width: 70%;
      }
      height: 1rem;
      margin-top: 0.5rem;
      overflow: hidden;
    }
  }
  .Attract_top {
    width: 100%;
    height: 800px;
    overflow: hidden;
    img {
      width: 100%;
      height: 800px;

      overflow: hidden;
    }
  }
  .Attract_video {
    position: relative;
    width: 100%;
    height: 5rem;
    background-image: url("../../../assets/Attract/video_bg.png");
    background-size: 100%;
    .video_border {
      position: absolute;
      z-index: 200;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    img {
      width: 100%;
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
  .Attract_bottom {
    width: 100%;
    height: 41rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 41rem;

      overflow: hidden;
    }
  }
  .text_2rem {
    margin-top: 10px;
    color: #d1d1d1;
    font-size: 0.2rem;
    width: 100%;
    height: 0.5rem;
    div {
      display: inline-block;
    }
  }
}
</style>