<template>
  <div id="shopDetail">
    <!-- <NavBar NavBarTitle='商品详情'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="header">
      <van-swipe class="my-swipe"
                 :autoplay="0"
                 indicator-color="transparent">
        <van-swipe-item v-for="item in goodsData.images"
                        :key="item.id">
          <img :src="item"
               alt="">
        </van-swipe-item>
      </van-swipe>
      <div style="padding:0.15rem 0.3rem">
        <div class="price">
          <a style="font-size:0.5rem">￥</a><a href=""
             class="price"
             style="font-size:0.6rem;font-weight:600">{{goodsData.sales_price}}</a><a class="vipPrice">会员价格</a>
          <!-- <span>月销5000+</span> -->
        </div>
        <!-- <van-tag type="primary"
                 size="medium">
          积分抵扣满300减20
        </van-tag>
        <van-tag type="primary"
                 size="medium">
          免费不用钱
        </van-tag> -->

        <div class="shopName">
          {{goodsData.name}}
        </div>
        <div class="operation">
          <van-row type="flex"
                   justify="space-between">
            <van-col span="12"
                     @click="collect">
              <van-icon color="red"
                        :name="like" /> 收藏
            </van-col>
            <van-col span="12">
              <van-icon name="share-o" /> 分享
            </van-col>
          </van-row>
        </div>
      </div>
    </div>

    <div class="discount">
      <div class="text">
        优惠<span>会员折扣价</span> <a>会员{{discount*10}}折</a>
      </div>
      <van-tag type="primary"
               size="medium">
        积分折扣
      </van-tag>
      <van-tag plain
               color="#FE6529"
               type="primary">
        满300减200
      </van-tag>
      <van-icon name="arrow" />
    </div>
    <div class="choiceType">
      <div class="service">服务
        <span>付款后,24小时内发货 快递包邮</span>
      </div>
      <div class="choice"
           @click="getSku">
        选择<span>{{goods_name}}
          <van-icon name="arrow" />
        </span>
      </div>
      <div class="choice"
           @click="goAddress">
        送至<span>{{addressInfo}}
          <van-icon name="arrow" />
        </span>
      </div>
    </div>
    <!-- <div class="evaluate">
      <div class="title">宝贝评价(2)
        <span>查看全部
          <van-icon name="arrow" />
        </span>
      </div>
      <div class="content">
        <div class="name">
          <img src="https://img2.baidu.com/it/u=1157738052,3077594134&fm=26&fmt=auto"
               alt="">
          <span>刘德华</span>
        </div>
        <div class="evaContent">
          杯子太好用了,去年放的热水,现在还热着
        </div>
      </div>
      <div class="content">
        <div class="name">
          <img src="https://img2.baidu.com/it/u=1157738052,3077594134&fm=26&fmt=auto"
               alt="">
          <span>刘德华</span>
        </div>
        <div class="evaContent">
          杯子太好用了,去年放的热水,现在还热着
        </div>
      </div>
    </div> -->
    <div class="shopImg">
      <div class="title">
        <span>——</span> 商品详情 <span>——</span>
      </div>
      <div v-html="goodsData.introduce"></div>
    </div>
    <div class="shopImg">
      <div class="title">
        <span>——</span> 为您推荐 <span>——</span>
      </div>
      <div class="ListWater">
        <WaterFall :imgsArr='imgsArr'
                   @getwatId='getwatId'></WaterFall>
      </div>
    </div>
    <div class="bottom">
      <van-goods-action>
        <van-goods-action-icon icon="like-o"
                               text="收藏" />
        <van-goods-action-icon icon="chat-o"
                               text="客服"
                               color="#ee0a24" />
        <van-goods-action-icon icon="cart-o"
                               text="购物车"
                               badge="10"
                               @click="goShopCart" />
        <van-goods-action-button type="warning"
                                 text="加入购物车"
                                 @click="addCar" />
        <van-goods-action-button type="danger"
                                 text="立即购买"
                                 @click="goConfirmOrder" />
      </van-goods-action>
    </div>
    <div class="sku">
      <van-popup v-model="skuShow"
                 position="bottom"
                 @close='closeSku'
                 :style="{ height: '50%' }"
                 class="skuPopup">
        <van-sku v-model="skuShow"
                 :sku="sku"
                 :goods="goods_info"
                 :hide-stock="sku.hide_stock"
                 :quota="quota"
                 :goods-id="goods_id"
                 :quota-used="quota_used"
                 :initial-sku="initialSku"
                 reset-stepper-on-hide
                 reset-selected-sku-on-hide
                 disable-stepper-input
                 :custom-sku-validator="customSkuValidator"
                 @stepper-change='stepperChange'
                 @sku-selected='skuSelected'
                 @buy-clicked="onBuyClicked"
                 @add-cart="onAddCartClicked">
        </van-sku>
      </van-popup>

    </div>
  </div>
</template>

<script>
import { getGoodsDetail, getGoodsList } from '../../../api/shop.js'
import { aesKey } from "../../../commom/aesKey"
// import NavBar from '@/components/NavBar'
import WaterFall from '@/components/WaterFall'
import { postCollect, getCollect, delCollect } from '@/api/collect' //eslint-disable-line no-unused-vars
import { Notify } from 'vant';
export default {
  components: {
    // NavBar,
    WaterFall
  },
  data () {
    return {
      like: "like-o",
      discount: null, //折扣价
      //瀑布流数据
      imgsArr: [],
      aesKey: aesKey(),//接口通过密文来获取数据
      page: 1,//页数
      addressInfo: '',//地址信息
      shopId: '',//商品id
      goodsData: {},//商品数据
      choiceSku: '',//选择规格规格后用于默认规格
      routeName: '',//进入此页面的路由名字
      //商品规格
      goods_name: '请选择规格',//商品名字
      skuShow: false,//商品规格弹窗
      initialSku: {
        // s0: 0,
        // selectedNum: 1
      },
      customSkuValidator: () => '请选择规格!',
      sku: {
        // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
        // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
        tree: [],
        // 所有 sku 的组合列表，如下是：冰川绿1、冰川绿2、冰川绿3,黑色1、黑色2、
        list: [],
        price: '100-130',//价格范围
        stock_num: '', // 商品总库存
        none_sku: false,  // 是否无规格商品 
        hide_stock: false  // 是否隐藏剩余库存
      },
      goods_id: '946755',
      quota: 0, //限购数量 
      quota_used: 0,  //已经购买过的数量
      Collect_id: null,//当前商品收藏id
      goods_info: {
        // 默认商品 sku 缩略图
        picture: ''
      },
    }
  },
  created () {
    this.shopId = sessionStorage.getItem('goodsId')
    let data = {
      id: this.shopId
    }
    this.aesKey = aesKey(data)
    this.getGoodsDetail(this.aesKey)
    this.vipDiscount()
  },
  mounted () {
    this.getCollect()
    // this.wechatShare({
    //   title: '你重置的分享标题',
    //   success: () => { //你重置分享成功后的回调
    //     this.htm = '你的小伙伴已收到'
    //     this.showDialog = true
    //   }
    // })
    window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    if (this.$route.query.addressInfo === undefined) {
      this.addressInfo = '选择收货地址'
    } else {
      this.addressInfo = this.$route.query.addressInfo
    }
    //切换路由后规格信息默认存储
    let initialSku = JSON.parse(sessionStorage.getItem('initialSku'))
    if (initialSku == null || initialSku.shopId != this.shopId) {
      this.initialSku = {}
    } else {
      this.initialSku = initialSku
      this.goods_name = initialSku.goods_name


      console.log(this.initialSku)
    }
    this.getGoodsList()
  },
  watch: {
    goodsData (newData) {
      this.goods_info.picture = newData.images[0]
      this.sku.stock_num = newData.stock_count
      this.sku.price = newData.sales_price
    },

  },
  //路由钩子，用于判断返回上一步路由
  beforeRouteEnter (to, from, next) { // 这个路由钩子函数比生命周期beforeCreate函数先执行，所以this实例还没有创建出来
    next((vm) => { //vm,可以这个vm这个参数来获取this实例,接着就可以做修改了
      vm.routeName = from.name
    })
  },
  methods: {
    getCollect () {
      let data = {
        product_id: this.shopId,
        type: 3
      }
      getCollect(data).then(res => {
        console.log(res);
        if (res.data.data.total > 0) {
          this.Collect_id = res.data.data.list[0].id
        }
        if (res.data.data.list.length > 0) {
          this.like = "like"
        }
        console.log(res.data.data.list.length);
      })
    },

    vipDiscount () {
      let data = JSON.parse(localStorage.getItem('wechatUser'));
      if (data.com_wah_is_vip === 1) {
        this.discount = 0.8
      } else if (data.com_wah_is_vip === 0) {
        this.discount = 1
      }
    },
    //根据商品id获取商品详情
    getGoodsDetail (data) {
      getGoodsDetail(data).then(res => {
        // console.log(res.data.data)
        this.goodsData = res.data.data
        this.goodsData.sales_price = Math.ceil(
          Number(
            this.goodsData.sales_price *
            (this.discount ? this.discount : 1)
          )
        )
      })
    },
    //获取商城列表数据
    getGoodsList (data) {
      if (data) {
        getGoodsList(data).then(res => {
          let arr = []
          res.data.data.list.forEach(item => {
            if (item.status == 2) {
              arr.push(item)
            }
          })
          let dataArr = arr.map(item => {
            return {
              id: item.id,
              img: item.big_images,
              describe: item.name,
              type: item.brand_name,
              // 价格展示
              price: Math.ceil(
                Number(
                  item.sales_price *
                  (this.discount ? this.discount : 1)
                )
              ),
              cost_price: item.cost_price,
              oldPrice: item.sales_price
            }
          })
          this.imgsArr = this.imgsArr.concat(dataArr)
        })
      } else {
        getGoodsList(this.aesKey).then(res => {
          let arr = []
          res.data.data.list.forEach(item => {
            if (item.status == 2) {
              arr.push(item)
            }
          })
          this.imgsArr = arr.map(item => {
            return {
              id: item.id,
              img: item.big_images,
              describe: item.name,
              type: item.brand_name,
              // 价格展示
              price: Math.ceil(
                Number(
                  item.sales_price *
                  (this.discount ? this.discount : 1)
                )
              ),
              cost_price: item.cost_price,
              oldPrice: item.sales_price
            }
          })
        })
      }
    },
    //收藏和取消收藏
    collect () {
      if (this.like == "like-o") {
        console.log("点击了收藏");
        let data = {
          product_id: this.shopId,
          type: 3
        }
        postCollect(data).then(res => {
          if (res.data.message == "添加成功!") {
            Notify({ type: 'success', message: '收藏成功' });

            this.like = 'like'
          }
          this.getCollect()

          console.log(res);
        })
      } else if (this.like == "like") {
        let data = {
          id: this.Collect_id,
          product_id: this.hotelId,
          type: 3
        }
        delCollect(data).then(res => {
          if (res.data.message == '删除成功!') {
            Notify({ type: 'warning', message: '取消收藏' });
            this.like = "like-o"

          }
          console.log(res);
        })
      }

    },
    onClickLeft () {
      // if (this.routeName == 'shopConfirmOrder') {
      //   this.$router.push({ path: '/allShop/shopHome' })
      // } else {
      this.$router.go(-1)
      // }
    },

    // 获取滚动条的滚动距离
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

      //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight == scrollHeight) {
        this.page++
        this.aesKey = aesKey(this.page)
        let data = {
          page: this.page
        }
        this.aesKey = aesKey(data)
        this.getGoodsList(this.aesKey)
      }
    },
    //瀑布流点击
    getwatId (data) {
      sessionStorage.setItem('goodsId', data.id)
      let DATA = {
        id: data.id
      }
      this.aesKey = aesKey(DATA)
      this.getGoodsDetail(this.aesKey)
      window.scrollTo(0, 0);
    },

    //商品规格
    //处理规格数据
    dealSkuData () {
      //处理sku_type以至于vantUI的规格组件可以使用
      let skuType = []
      this.goodsData.goods_sku_type.forEach((item, index) => {
        console.log(item, "===item,===");
        console.log(index, "===index===");
        skuType.push({
          k: item.name,
          name: item.name,
          k_s: 's' + index,
          v: item.value
        })
      })
      skuType.forEach((item1) => {
        let dataArr = item1.v.map((item2, index2) => {
          return {
            id: index2,
            name: item2,
          }
        })
        item1.v = dataArr
      })
      this.sku.tree = skuType

      //处理规格数据以至于组件使用
      let goodsList = []
      this.goodsData.goods_sku.forEach((item) => {
        goodsList.push({
          goods_id: item.id,
          goods_name: item.name,
          pendingName: item.name,
          imgUrl: item.image,
          stock_num: item.stock_count,
          price: Math.ceil(
            Number(
              item.sales_price *
              (this.discount ? this.discount : 1) * 100
            )
          ),
          cost_price: Number(item.cost_price) * 100
        })
      })
      goodsList.forEach(item1 => {
        item1.pendingName = item1.pendingName.split(';')
        item1.pendingName.pop()
        item1.pendingName.forEach(item2 => {
          let name = item2.substring(0, item2.indexOf(':'))
          let value = item2.substring(item2.indexOf(':') + 1)
          skuType.forEach(item3 => {
            if (item3.name == name) {
              item3.v.forEach(item4 => {
                if (item4.name == value) {
                  item1[item3.k_s] = item4.id
                }
              })
            }
          })
        })
      })
      console.log(goodsList)
      this.sku.list = goodsList
    },
    //选择商品规格
    getSku () {
      this.dealSkuData()
      this.skuShow = true
    },
    //切换商品属性时触发
    skuSelected (data) {
      console.log(data)
      this.goods_name = data.selectedSkuComb.goods_name
      document.querySelector('.van-image__img').src = data.selectedSkuComb.imgUrl
      this.choiceSku = data.selectedSku
    },
    //购买数量变化时触发
    stepperChange (value) {
      this.choiceSku.selectedNum = value
    },
    //关闭商品规格弹窗
    closeSku () {
      this.initialSku = this.choiceSku
      let initialSku = this.initialSku
      initialSku.shopId = this.shopId
      initialSku.goods_name = this.goods_name
      sessionStorage.setItem('initialSku', JSON.stringify(initialSku))
    },
    //加入购物车
    onAddCartClicked (data) {
      this.goods_id = this.shopId
      console.log(data)
      this.skuShow = false
    },
    //立即购买
    onBuyClicked (data) {
      // this.closeSku()
      // console.log(this.initialSku.goods_name)
      if (this.initialSku.goods_name != undefined) {
        this.closeSku()
      }
      this.skuShow = false
      let orderData = {}
      console.log(this.goodsData)
      if (data.selectedSkuComb == undefined) {
        orderData.goods_id = this.shopId
        orderData.num = data.selectedNum
        orderData.name = this.goodsData.name
        orderData.image = this.goodsData.images[0]
        orderData.sales_price = this.goodsData.sales_price
        orderData.cost_price = this.goodsData.cost_price
      } else {
        orderData.selectedSkuComb = data.selectedSkuComb
        orderData.num = data.selectedNum
        orderData.name = this.goodsData.name
      }
      sessionStorage.setItem('orderData', JSON.stringify(orderData))
      this.$router.push({ path: '/allShop/shopConfirmOrder' })
    },


    //选择收货地址
    goAddress () {
      this.$router.push({ path: '/allShop/shopAddress' })
    },
    //底部购物车icon点击
    goShopCart () {
      this.$router.push({ path: '/allShop/shopCart' })
    },

    //底部‘加入购物车’点击
    addCar () {
      this.dealSkuData()
      this.skuShow = true
    },
    //底部‘立即购买’点击
    goConfirmOrder () {
      // this.$router.push({ path: '/allShop/shopConfirmOrder' })
      this.dealSkuData()
      this.skuShow = true
    },
  },
  computed: {

  },
}
</script>
<style lang="less" scoped>
.vipPrice {
  font-size: 12px;
  left: 30%;
  top: 20%;
  position: absolute;
  border: 1px solid;
  // color: #ff5c4c;
  // top: 100%;
  padding: 2px;
  border-radius: 3px;
}
#shopDetail {
  text-align: left;
  background-color: #f5f5f5;
  font-size: 0.2rem;
  .header {
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .my-swipe {
      .van-swipe-item {
        img {
          // height: 250px;
          width: 100%;
          color: transparent;
        }
      }
      /deep/.van-swipe__indicators {
        display: none;
      }
    }
    .price {
      font-size: 0.5rem;
      color: #fe6529;
      position: relative;
      span {
        color: #999999;
        font-size: 0.27rem;
        position: absolute;
        right: 0%;
        bottom: 10%;
      }
    }
    .van-tag {
      font-size: 0.21rem;
      padding: 0.05rem 0.1rem;
      margin-top: 0.15rem;
      background-image: linear-gradient(to right, #fba952, #fe6162);
      margin-right: 0.1852rem;
    }
    .shopName {
      font-size: 0.27rem;
      color: rgb(17, 17, 17);
      font-weight: bold;
      margin-top: 0.1rem;
    }
    .operation {
      text-align: center;
      font-size: 0.24rem;
      color: #999999;
      padding-top: 0.15rem;
      padding-bottom: 0.1rem;
    }
  }
  .discount {
    background-color: white;
    margin-top: 0.2rem;
    padding: 0.2rem 0.3rem;
    font-size: 0.27rem;
    position: relative;
    border-radius: 10px;
    .text {
      span {
        margin-left: 0.8rem;
      }
      a {
        color: #fe6529;
      }
    }
    .van-tag {
      font-size: 0.21rem;
      padding: 0.05rem 0.1rem;
      margin-top: 0.15rem;
      margin-right: 0.3rem;
    }
    .van-tag--medium {
      background-image: linear-gradient(to right, #fba952, #fe6162);
    }
    .van-icon {
      font-size: 0.4rem;
      color: #999999;
      position: absolute;
      right: 4%;
      top: 36%;
    }
  }
  .choiceType {
    background-color: white;
    margin-top: 0.2rem;
    padding: 0.2rem 0.3rem;
    font-size: 0.27rem;
    border-radius: 10px;
    .service {
      span {
        margin-left: 0.75rem;
        color: #666666;
      }
    }
    .choice {
      margin-top: 0.3rem;
      span {
        margin-left: 0.8rem;
        color: #999999;
        display: inline-block;
        width: 79%;
        // background-color: aqua;
        .van-icon {
          float: right;
          line-height: 0.3704rem;
        }
      }
    }
  }
  .evaluate {
    background-color: white;
    margin-top: 0.2rem;
    padding: 0.2rem 0.3rem;
    font-size: 0.27rem;
    border-radius: 10px;
    .title {
      font-size: 0.29rem;
      width: 98%;
      span {
        font-size: 0.24rem;
        float: right;
        color: #fe6529;
      }
    }
    .content {
      margin-top: 0.4rem;
      .name {
        position: relative;
        img {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 40px;
          margin-right: 0.1rem;
          position: absolute;
          top: -25%;
        }
        span {
          margin-left: 0.7rem;
          font-size: 0.25rem;
        }
      }
      .evaContent {
        margin-top: 0.3rem;
        color: #333333;
      }
    }
  }
  .shopImg {
    font-size: 0.3rem;
    text-align: center;
    .title {
      padding: 0.2rem 0;
      span {
        color: #d8d8d8;
      }
    }
    img {
      width: 100%;
    }
  }
  .ListWater {
    padding-left: 0.25rem;
    background-color: white;
    /deep/.vue-waterfall-easy {
      a.img-inner-box {
        box-shadow: none;
        a {
          height: 100% !important;
          width: auto !important;
        }
      }
      img {
        border-radius: 5px;
        width: 93%;
      }
      .img-box .default-card-animation {
        padding: 0;
      }
    }
    /deep/.vue-waterfall-easy-container
      .vue-waterfall-easy-scroll[data-v-ded6b974] {
      overflow-x: visible;
      overflow-y: visible;
    }
  }
  .bottom {
    .van-goods-action-icon {
      font-size: 0.23rem;
    }
    .van-goods-action {
      height: 1rem;
    }
    /deep/.van-goods-action-icon__icon {
      font-size: 0.35rem;
    }
    /deep/.van-button__content {
      font-size: 0.24rem;
    }
  }
  .sku {
    /deep/.van-sku__goods-price {
      color: #fe6529;
    }
    /deep/.van-sku-row__item {
      font-size: 0.25rem;
      padding: 0.1rem 0.2rem;
      border-radius: 20px;
    }
    /deep/.van-sku-row__item--active {
      background-color: #36e0c9;
      color: white;
    }
  }
}
</style>