<template>
  <div class="container">
    <!-- 充值 -->
    <div class="details">
      <van-icon :name="details_img"
                class="details_icon" />
      <span class="details_text"
            @click="go_details">

        明细
      </span>
    </div>
    <div class="balance">
      <span class="balance_1">余额(元)</span>
      <span class="balance_2"
            v-if="user_info">{{user_info.wallet}}</span>
      <!-- <span class="balance_3">去提现
        <van-icon name="arrow" />
      </span> -->
    </div>
    <div class="topUp">
      <div class="topUp_tab">余额账户充值</div>
      <div class="content">

        <van-grid :column-num="3">
          <van-grid-item v-for="(item,index) in discount"
                         :key="item.min_amount">
            <van-button :class="index==iswrite?'topUp_balance_pickon':'topUp_balance'"
                        @click="Pay(item,index)">
              <img :src="balance_esprots==false?topUp:topUp_esprots"
                   alt="">
              <div class="price"> {{item.min_amount}}元</div>
              <div class="givePrice">赠送<div>{{item.integral}}元</div>赠送金</div>
            </van-button>

          </van-grid-item>
          <!-- <van-grid-item>
            <van-button class="topUp_balance">
              <div class="another">
                <van-field v-model="other_price"
                           type="price"
                           placeholder="其他余额" />
              </div>
            </van-button>
          </van-grid-item> -->
        </van-grid>

        <!-- <van-button class="topUp_balance"
                    @click="Pay_300">
          <img :src="this.balance_esprots==false?topUp:topUp_esprots"
               alt="">
          <div class="price"> 300元</div>
          <div>赠送<div style="color:#ff6c0a">30元</div>赠送金</div>
        </van-button>
        <van-button class="topUp_balance"
                    @click="Pay_500()">
          <img :src="this.balance_esprots==false?topUp:topUp_esprots"
               alt="">
          <div class="price"> 500元</div>
          <div>赠送<div style="color:#ff6c0a">50元</div>赠送金</div>
        </van-button> -->

      </div>
      <!-- <div class="content">
        <van-button class="topUp_balance"
                    @click="Pay_700"
                    v-for="item in discount_two"
                    :key="item">
          <img :src="balance_esprots==false?topUp:topUp_esprots"
               alt="">
          <div class="price"> {{item.min_amount}}元</div>
          <div>赠送<div>{{item.integral}}元</div>赠送金</div>
        </van-button>
        <van-button class="topUp_balance_pickon"
                    @click="Pay_1000">
          <img :src="balance_esprots==false?topUp:topUp_esprots"
               alt="">
          <div class="price"> 1000元</div>
       <div class="givePrice">赠送<div div
                 :style="{color:topUp_balance_pickon==false?'balck':'white'}">300元</div>赠送金</div> 
          <div class="givePrice">赠送<div>300元</div>赠送金</div>
        </van-button>
        <van-button class="topUp_balance">
          <div class="another">
            <van-field v-model="price"
                       type="price"
                       placeholder="其他余额" />
          </div>
        </van-button>
      </div> -->
      <div :class="balance_esprots==false?'bottom':'bottom_esports'">
        <div class="top">
          <van-radio-group v-model="radio">
            <van-radio name="1"
                       @click="radio_btn">
              <div @click="go_agreement"> 本人阅读并同意《观视界充值须知》</div>
            </van-radio>
          </van-radio-group>
        </div>
        <van-button @click="pay">确认充值</van-button>
      </div>

      <van-popup v-model="showPay">
        <div style="font-size:27px;padding-top: 5.4px;">是否已支付</div>
        <div class="btn">
          <van-button type="primary">已支付</van-button>
          <van-button type="warning"
                      @click="reloadPay">未支付</van-button>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { Pay, payUserBalance, checkPayStatus } from '@/api/pay'
import { getJSSDK, PAYJSSDK } from '@/api/JSSDk.js'
import { aesKey } from "@/commom/aesKey"
import wx from 'weixin-js-sdk'
import { Toast } from 'vant';
import { getUser_info } from '@/api/user.js'
import { Payselect } from '@/api/pay.js'

export default {
  data () {
    return {
      showPay: false,
      radio: false,
      details_img: require('../../../assets/personalimg/details.png'),
      price: null,
      other_price: null,
      aesKey: aesKey(),//接口通过密文来获取数据
      user_info: null,
      no: null,
      balance_esprots: true,
      topUp_esprots: require('../../../assets/Esports/topUp.png'),
      topUp: require('../../../assets/personalimg/topUp.png'),
      topUp_balance_pickon: true,
      discount: null,//优惠数据
      discount_two: null,
      iswrite: null,
      btn_radio: false,
    }
  },
  created () {
    this.getAgreement()
    this.shareWechat()
    this.getUser()
  },
  mounted () {
    this.Payselect()
  },
  methods: {
    //充值协议返回按钮
    getAgreement () {
      let agreement = this.$route.query.agreement
      console.log(agreement, "====agreement===");
      if (agreement != undefined) {
        this.radio = '1'
      }
    },
    //固定额度充值
    Payselect () {
      Payselect().then(res => {
        this.discount = res.data.data
        // this.discount = arr.slice(0, 3)
        // this.discount_two = arr.slice(3, 5)
        console.log(this.discount, "discount_two");
      })
    },
    //重新调起充值
    async fixed_pay (data) {
      console.log(this.price);
      if (this.price == '') {
        console.log("走进充值");
        Toast.fail('充值不能为空')
        return
      }
      console.log(data);
      let pay_data = {
        amount: data
      }
      let res = await Pay(pay_data)
      console.log("创建订单成功");
      if (res.data.message == "未绑定手机号码") {
        Toast.fail('未绑定手机号码');
        sessionStorage.setItem('balanceRoute', this.$route.path)
        setTimeout(() => {
          this.$router.push({ path: '/login' })
        }, 1500);
      } else if (res.data.code != 200) {
        Toast.fail(res.data.data.message);
      }
      else if (res.data.code == 200) {
        let data2 = {
          pay_for: 5,
          pay_provider_id: 1,
          pay_method: "W02",
          product_order_no: res.data.data.no
        }
        console.log("创建支付订单", data2);
        this.no = res.data.data.no
        let res2 = await payUserBalance(data2)
        console.log(res2, "第二步");
        if (res2 == undefined) {
          this.reloadPay()
        } else {
          let data3 = {
            prepay_id: res2.data.data.payinfo.prepay_id
          }
          console.log(data3, "data3");
          this.aesKey = aesKey(data3)
          let res3 = await PAYJSSDK(this.aesKey)
          console.log(res3, "第三步");
          console.log("正在支付");
          if (res3.status == 200) {
            // this.res2 = res2
            Toast.fail('支付中');
            console.log("1111");
            // this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
            // this.onBridgeReady(this.res2.data.data.payinfo)
            wx.chooseWXPay({
              timestamp: res3.data.data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: res3.data.data.nonceStr, // 支付签名随机串，不长于 32 位
              package: res3.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: res3.data.data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: res3.data.data.paySign, // 支付签名
              success: function (res) {
                // 支付成功后的回调函数
                console.log(res, "支付成功1111111!!!!!!!!!!!!!");
                let PayUrl = sessionStorage.getItem('PayUrl')
                let hotelId = sessionStorage.getItem('hotelId')
                if (PayUrl == "true") {
                  sessionStorage.setItem('PayUrl', false)

                  window.location.href = "https://pt.guansj.com/allHotel/HotelDatail?" + 'hotelId=' + hotelId


                  // this.$router.push(
                  //   {
                  //     path: '/allHotel/HotelDatail',
                  //     query: { hotelId: hotelId }
                  //   }
                  // )
                }
                // this.$router.push({ path: '/allShop/shopMyOrder' })
              },
              cancel: function (res) {
                console.log(res);
              },
              // fail (res) {
              //   console.log(res);
              //   This.$toast('支付失败');
              //   window.location.href = "https://pt.guansj.com/allShop/shopMyOrder"
              // }
            });
            // 
          }

        }


      }
      console.log(res);
    },
    radio_btn () {
      if (this.btn_radio == false) {
        this.radio = '1'
        this.btn_radio = true
      } else if (this.btn_radio == true) {
        console.log();
        this.radio = ''
        this.btn_radio = false
      }
    },
    go_agreement () {
      console.log("123");
      this.$router.push({ path: '/allPerInfo/agreement' })
    },
    async pay () {
      console.log(this.price);
      if (this.radio == '') {
        Toast.fail('阅读充值须知')
        return
      }
      if (this.price == '') {
        console.log("走进充值");
        Toast.fail('充值不能为空')
        return
      }
      let data = {
        amount: this.price || this.other_price
      }
      let res = await Pay(data)
      console.log("创建订单成功");
      console.log(res.data.data, "res.data.data");
      console.log(res.data, "res.data");
      if (res.data.message == "未绑定手机号码") {
        Toast.fail('未绑定手机号码');
        sessionStorage.setItem('balanceRoute', this.$route.path)
        setTimeout(() => {
          this.$router.push({ path: '/login' })
        }, 1500);
      } else if (res.data.message == '参数amount必填') {

        Toast.fail("请选择充值规格");
        return
      } else if (res.status != 200) {
        Toast.fail("无法充值，请联系客服");
        return
      }
      else if (res.data.message == '创建成功') {
        console.log("走到这里了创建支付订单");
        let data2 = {
          pay_for: 5,
          pay_provider_id: 1,
          pay_method: "W02",
          product_order_no: res.data.data.no
        }
        console.log("创建支付订单", data2);
        this.no = res.data.data.no
        let res2 = await payUserBalance(data2)
        console.log(res2, "第二步");
        if (res2 == undefined) {
          this.reloadPay()
        } else {
          let data3 = {
            prepay_id: res2.data.data.payinfo.prepay_id
          }
          console.log(data3, "data3");
          this.aesKey = aesKey(data3)
          let res3 = await PAYJSSDK(this.aesKey)
          console.log(res3, "第三步");
          console.log("正在支付");
          if (res3.status == 200) {
            // this.res2 = res2
            Toast.fail('支付中');
            console.log("2222");
            // this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
            // this.onBridgeReady(this.res2.data.data.payinfo)
            wx.chooseWXPay({
              timestamp: res3.data.data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: res3.data.data.nonceStr, // 支付签名随机串，不长于 32 位
              package: res3.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: res3.data.data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: res3.data.data.paySign, // 支付签名
              success: function (res) {
                // 支付成功后的回调函数
                // console.log(res, "支付成功2222222!!!!!!!!!!!!!");
                console.log(res);
                // this.$router.push({ path: '/allShop/shopMyOrder' })
                let PayUrl = sessionStorage.getItem('PayUrl')
                let hotelId = sessionStorage.getItem('hotelId')
                console.log(PayUrl, "PayUrl");
                console.log(hotelId, "hotelId");

                if (PayUrl == 'true') {
                  sessionStorage.setItem('PayUrl', false)

                  window.location.href = "https://pt.guansj.com/allHotel/HotelDatail?" + 'hotelId=' + hotelId
                  console.log("https://pt.guansj.com/allHotel/HotelDatail?" + 'hotelId=' + hotelId);

                  // this.$router.push(
                  //   {
                  //     path: '/allHotel/HotelDatail',
                  //     query: { hotelId: hotelId }
                  //   }
                  // )
                }
              },
              cancel: function (res) {
                console.log(res);
              },
              // fail (res) {
              //   console.log(res);
              //   This.$toast('支付失败');
              //   window.location.href = "https://pt.guansj.com/allShop/shopMyOrder"
              // }
            });
            // 
          }

        }


      }
    },
    go_details () {
      this.$router.push({ path: '/allPerInfo/details' })
    },
    async reloadPay () {
      this.showPay = true
      let data2 = {
        product_order_no: this.no,
      }
      console.log(data2, "data2");
      let res = await checkPayStatus(data2)
      console.log(res, "第一,重新调起支付");
      console.log(res, "payStatus订单状态");
      this.prepay_id = res.data.data.pay.prepay_id
      console.log(this.prepay_id, " this.prepay_id");
      if (res.data.code == 200) {
        console.log("订单状正常");
        let data3 = {
          prepay_id: this.prepay_id
        }

        this.aesKey = aesKey(data3)
        console.log("准备进入微信支付", this.aesKey);
        let res3 = await PAYJSSDK(this.aesKey)
        console.log(res, "第一,重新调起支付");

        console.log("弹出微信支付");
        console.log("====res3", res3);
        if (res3.data.code == 200) {
          console.log(res3);
          // this.res2 = res2
          Toast.fail('支付中');
          console.log("3333");
          // this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
          // this.onBridgeReady(this.res2.data.data.payinfo)
          wx.chooseWXPay({
            timestamp: res3.data.data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: res3.data.data.nonceStr, // 支付签名随机串，不长于 32 位
            package: res3.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: res3.data.data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: res3.data.data.paySign, // 支付签名
            success: function (res) {
              // 支付成功后的回调函数
              let hotelId = sessionStorage.getItem('hotelId')
              console.log(res, "支付成功3333!!!!!!!!!!!!!");
              console.log(res);
              // this.$router.push({ path: '/allShop/shopMyOrder' })
              let PayUrl = sessionStorage.getItem('PayUrl')
              if (PayUrl == 'true') {
                sessionStorage.setItem('PayUrl', false)
                window.location.href = "https://pt.guansj.com/allHotel/HotelDatail?" + 'hotelId=' + hotelId
                console.log("https://pt.guansj.com/allHotel/HotelDatail?" + 'hotelId=' + hotelId);


                // this.$router.push(
                //   {
                //     path: '/allHotel/HotelDatail',
                //     query: { hotelId: hotelId }
                //   }
                // )
              }
            },
            cancel: function (res) {
              console.log(res);
            },
            // fail (res) {
            //   console.log(res);
            //   This.$toast('支付失败');
            //   window.location.href = "https://pt.guansj.com/allShop/shopMyOrder"
            // }
          });
          // 
        }


      }
    },
    shareWechat () {
      this.url = window.location.href.split('#')[0]
      let data = {
        url: this.url,
        apis: ['chooseWXPay'],
      }
      // console.log(data);
      this.aesKey = aesKey(data)
      getJSSDK(this.aesKey).then(res => {
        this.jsSdk = res.data.data
        // console.log(this.jsSdk, "JSSDK");
        wx.config({
          debug: false,
          appId: res.data.data.appId,
          timestamp: res.data.data.timestamp,
          nonceStr: res.data.data.nonceStr,
          signature: res.data.data.signature,
          jsApiList: ['chooseWXPay']
        })
      })
    },
    getUser () {
      getUser_info().then(res => {
        console.log(res, "获取用户信息");
        this.user_info = res.data.data
      })
    },
    Pay (item, index) {
      this.iswrite = index
      console.log(item, index);
      this.price = item.min_amount
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  // background-color: aqua;
  position: relative;
  height: 11.1111rem;
  .details {
    // font-size: .2963rem;
    float: right;
    display: flex;
    margin-top: 20.0016px;
    margin-right: 20.0016px;
    .details_icon {
      font-size: 11.9988px;
      margin-top: 3.0024px;
      margin-right: 5.0004px;
    }
    .details_text {
      font-weight: bold;
      font-size: 16.0002px;
      line-height: 20.0016px;
      color: #666666;
    }
  }
  .balance {
    width: 100%;

    // margin-top: 50%;
    top: 10%;
    // left: 40%;
    position: absolute;
    font-size: 16.0002px;
    display: flex;
    flex-direction: column;
    .balance_1 {
      color: #848484;
      font-weight: 600;
      margin-bottom: 10.0008px;
    }
    .balance_2 {
      font-size: 49.9986px;
      font-weight: 600;
      margin-bottom: 10.0008px;
    }
  }
  .topUp {
    position: absolute;
    width: 100%;
    top: 30%;
    .topUp_tab {
      font-size: 20.0016px;
      display: flex;
      font-weight: 600;
      margin-left: 0.2407rem;
    }
    .content {
      display: flex;
      .van-button--normal {
        padding: 0 !important;
      }
      flex-direction: row;
      width: 100%;
      margin-top: 10.0008px;
      // height: 9.2593rem;
      // background-color: antiquewhite;

      /deep/ .van-grid-item__content {
        padding: 0 !important;
        margin-bottom: 0.3704rem;
        .topUp_balance {
          height: 90.0018px;
          margin: 0 10.0008px;
          background-color: #f6f6f6;
          // background-image: url(../../../assets/Esports/btn_bg.png);
          // background-repeat: no-repeat;
          // background-size: contain;
          font-size: 17.9982px;
          border-radius: 0.1852rem;
          .price {
            font-size: 0.3704rem;
            margin-bottom: 0.0926rem;
          }
          div {
            display: inline-block;
            font-size: 11.9988px;
          }
          img {
            width: 50%;
            display: flex;
            margin-bottom: 10.8px;
          }
          .another {
            font-size: 17.9982px;
            text-align: center;
            line-height: 90.0018px;
            .van-field {
              height: 90.0018px;
              background-color: #f6f6f6;
              font-size: 17.9982px;
              border-radius: 0.1852rem;
              line-height: 54px;
            }
          }
        }
        .topUp_balance_pickon {
          height: 90.0018px;
          margin: 0 10.0008px;
          background-image: url(../../../assets/Esports/btn_bg.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          // background-color: green;
          font-size: 17.9982px;
          border-radius: 0.1852rem;
          .price {
            font-size: 0.3704rem;
            margin-bottom: 0.0926rem;
            color: white;
          }
          .givePrice {
            color: white;
          }
          div {
            display: inline-block;
            font-size: 11.9988px;
          }
          img {
            width: 50%;
            display: flex;
            margin-bottom: 10.8px;
          }
          .another {
            font-size: 17.9982px;
            text-align: center;
            line-height: 90.0018px;
            .van-field {
              height: 90.0018px;
              font-size: 17.9982px;
              border-radius: 0.1852rem;
              line-height: 54px;
            }
          }
        }
      }
    }
  }
  .bottom {
    margin-top: 1.0741rem;
    .top {
      margin-left: 5%;
      font-size: 0.2963rem;
    }
    .van-button {
      width: 90%;
      border-radius: 0.3704rem;
      background: linear-gradient(150deg, #ff6c0a 0%, #ffc454 100%);
      color: white;
    }
    .van-button_esports {
      width: 90%;
      border-radius: 0.3704rem;
      background: linear-gradient(150deg, #a02176 0%, #4532e2 100%);
      color: white;
    }
  }
  .bottom_esports {
    margin-top: 1.0741rem;
    .top {
      margin-left: 5%;
      font-size: 0.2963rem;
    }
    .van-button {
      width: 90%;
      border-radius: 0.3704rem;
      background: linear-gradient(150deg, #a02176 0%, #4532e2 100%);
      color: white;
    }
  }
}
/deep/ .van-popup {
  border-radius: 0.1852rem;
  width: 229.5px;
  height: 162px;
  padding-left: 27px;
  padding-right: 27px;
  .btn {
    // position: absolute;
    margin-top: 54px;

    display: flex;
    justify-content: space-between;
    .van-button {
      border-radius: 0.1852rem;
    }
  }
}

/deep/ [class*="van-hairline"]::after {
  content: none !important;
}
</style>