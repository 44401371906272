<template>
  <div class="container">

    <div class="content_top"
         id="search">
      <van-swipe class="my-swipe"
                 :autoplay="3000"
                 indicator-color="white">
        <van-swipe-item><img src="../../../assets/shopImg/shop_banner_1.png"
               alt=""></van-swipe-item>
        <van-swipe-item><img src="../../../assets/shopImg/shop_banner_2.png"
               alt=""></van-swipe-item>
      </van-swipe>
      <!-- <img class="banner_hot"
           src="../../../assets/shopImg/banner_hot.png"
           alt=""> -->
      <img class="everyday"
           src="../../../assets/shopImg/everyday.png"
           alt="">
      <form action="/">
        <van-search class="search"
                    v-model="Searchvalue"
                    shape="round"
                    left-icon=""
                    right-icon=""
                    @search="searchShop"
                    placeholder="请输入搜索关键词">
        </van-search>
        <i class="search_icon"><img style=" position: absolute; top:2.5%;display: flex;right: 6%; width: 4%;"
               @click="goShopSearch"
               src="../../../assets/inland/搜索icon.png"
               alt="">
        </i>
      </form>
    </div>
    <div class="shop_date">
      <div class="shop_date_left"><img src="../../../assets/shopImg/shop_date_left.png"
             alt=""></div>
      <div class="shop_date_right"><img src="../../../assets/shopImg/shop_date_right.png"
             alt="">
      </div>
    </div>
    <div class="gilt">
      <div class="top">
        <span class="top_left"
              style="font-size:22px; font-weight: 600; ">优品闪购</span>
        <span class="top_right"
              style="font-size:14px;color:#a8b2b0;line-height: 220%; ">正品大牌</span>
        <span class="top_right"
              style="font-size:14px;color:#a8b2b0; line-height: 220%; margin-right: 10px; ">包邮包税</span>
      </div>
      <div class="tabList">
        <vueSeamless :class-option="optionLeft"
                     class="seamless-warp2">
          <ul class="item">
            <li v-for="item in shopList"
                :key="item.name">
              <div class="tabImg">
                <img :src="item.img"
                     alt="">
              </div>
              <div class="depreciate_img">
                <img src="../../../assets/shopImg/price (1).png"
                     alt="">
                <span class="depreciate_price">直降{{item.depreciate_price}}元</span>
              </div>
              <div class="after_price">{{item.after_price}}</div>
            </li>

          </ul>
        </vueSeamless>
      </div>
    </div>
    <div class="ShopList">
      <van-tabs v-model="activeName"
                @click="switchTab"
                id="boxFixed">
        <van-tab title="热度新品"
                 name="first">
          <WaterFall :imgsArr="imgsArr"
                     @getwatId="getwatId"></WaterFall>
        </van-tab>
        <van-tab title="趋势新品"
                 name="second">
          <WaterFall :imgsArr="imgsArr"
                     @getwatId="getwatId"></WaterFall>
        </van-tab>
        <van-tab title="美食"
                 name="third"> </van-tab>
        <van-tab title="户外"
                 name="fourth"> </van-tab>
        <van-tab title="箱包"
                 name="fifth"> </van-tab>
        <van-tab title="男装"
                 name="fifth"> </van-tab>
      </van-tabs>

    </div>
    <div class="bottom">
      <van-tabbar v-model="bottomActive"
                  @change="switchBottomTab"
                  active-color="#58e3dd">
        <van-tabbar-item>
          <img v-if="bottomActive == 0"
               src="@/assets/shopImg/shopping_active.png"
               alt="" />
          <img v-else
               src="@/assets/shopImg/shopping_icon.png"
               alt="" />
          <div>商城</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 1"
               src="@/assets/shopImg/classification_active.png"
               alt="" />
          <img v-else
               src="@/assets/shopImg/classification_icon.png"
               alt="" />
          <div>分类</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 2"
               src="@/assets/shopImg/ShoppingCar _active.png"
               alt="" />
          <img v-else
               src="@/assets/shopImg/ShoppingCar.png"
               alt="" />
          <div>购物车</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 3"
               src="@/assets/shopImg/order_active.png"
               alt="" />
          <img v-else
               src="@/assets/shopImg/order_icon.png"
               alt="" />
          <div>订单</div>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { getGoodsList } from '@/api/shop.js'
import vueSeamless from 'vue-seamless-scroll'

import { aesKey } from '@/commom/aesKey'
import WaterFall from '@/components/WaterFall'

export default {
  components: {
    // NavBar,
    WaterFall,
    vueSeamless: vueSeamless,
  },
  computed: {
    optionLeft () {
      return {
        step: 0, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.bbsData.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 0 // 单步运动停止的时间(默认值1000ms)
        // limitMoveNum: 1,
        // step: 0,
        // autoPlay: true,
        // switchSingleStep: 140,
        // limitMoveNum: 40,
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 40,
        // waitTime: 2500 // 单行停顿时间(singleHeight,waitTime)
      }
    }
  },
  data () {
    return {
      shopList: [
        {
          name: 'bao',
          img: require('../../../assets/shopImg/bao.png'),
          depreciate_price: '300',
          after_price: '¥2390'
        },
        {
          name: 'kouhong',
          img: require('../../../assets/shopImg/kouhong.png'),
          depreciate_price: '160',
          after_price: '¥256'
        },
        {
          name: 'shuangfushui',
          img: require('../../../assets/shopImg/shuangfushui.png'),
          depreciate_price: '150',
          after_price: '¥590'
        },
        {
          name: 't-shit',
          img: require('../../../assets/shopImg/t-shit.png'),
          depreciate_price: '120',
          after_price: '¥453'
        },
        {
          name: 'bao_2',
          img: require('../../../assets/shopImg/bao.png'),
          depreciate_price: '300',
          after_price: '¥2390'
        },
        {
          name: 'kouhong_2',
          img: require('../../../assets/shopImg/kouhong.png'),
          depreciate_price: '160',
          after_price: '¥256'
        },
        {
          name: 'shuangfushui_2',
          img: require('../../../assets/shopImg/shuangfushui.png'),
          depreciate_price: '150',
          after_price: '¥590'
        },
        {
          name: 't-shit_2',
          img: require('../../../assets/shopImg/t-shit.png'),
          depreciate_price: '120',
          after_price: '¥453'
        },

      ],
      activeName: 'first',
      Searchvalue: '',
      searchContent: [],
      bottomActive: '',
      isFixed: false, //标签页是否固定
      aesKey: aesKey(), //接口通过密文来获取数据
      imgsArr: [
        {
          id: 1,
          img: require('../../../assets/shopImg/wallfull_banner.png'),
          price: null,
        }
      ],
      pageCount: '', //可翻页的次数
      page: 1, //页数
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    if (sessionStorage.getItem('shopRefresh') == 1) {
      sessionStorage.removeItem('shopRefresh')
      this.$router.go(0)
    }
    this.getGoodsList()
  },
  created () {
    this.getGoods_recommend()

  },
  methods: {
    switchBottomTab () {
      if (this.bottomActive == 0) {
        this.$router.push({ path: '/allShop/shopHome' })
      } else if (this.bottomActive == 1) {
        this.$router.push({ path: '/allShop/shopSort' })
      } else if (this.bottomActive == 2) {
        this.$router.push({ path: '/allShop/shopCart' })
      } else if (this.bottomActive == 3) {
        this.$router.push({ path: '/allShop/shopMyOrder' })
      }
    },
    searchShop () {
      this.searchContent.push(this.Searchvalue)
      //去重
      this.searchContent = this.searchContent.filter((item, index) => {
        return this.searchContent.indexOf(item, 0) === index
      })
      sessionStorage.setItem("searchValue", this.Searchvalue)
      localStorage.setItem("searchContent", JSON.stringify(this.searchContent))
      // this.$router.push({ path: '/allShop/shopList' })
      this.Searchvalue = ''
      this.$router.push({ path: '/allShop/shopList', query: { keyword: this.Searchvalue } })
    },
    goShopSearch () {
      if (this.Searchvalue != '') {
        this.searchShop()
      } else {

        this.$router.push({ path: '/allShop/shopSearch' })
      }
    },
    getwatId (data) {
      this.$router.push({ path: '/allShop/shopDetail' })
      sessionStorage.setItem('goodsId', data.id)
    },
    switchTab () {
      console.log(this.activeName)
    },
    handleScroll () {
      // console.log("执行滚动条方法");
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      // // // //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

      // // // //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      // console.log(scrollHeight);
      if (document.querySelector("#boxFixed") == null) {
        return;
      }
      let offsetTop = document.querySelector("#boxFixed").offsetTop; // 要滚动到顶部吸附的元素的偏移量
      let searchTop = document.querySelector("#search").offsetTop;
      // console.log("scrollTop", scrollTop);
      // console.log("windowHeight", windowHeight);
      // console.log("scrollHeight", scrollHeight);
      // console.log("offsetTop", offsetTop);
      // console.log("searchTop", searchTop);
      this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
      this.is_searFix = scrollTop > searchTop ? true : false;
      // console.log(scrollTop + windowHeight, '=======scrollTop + windowHeight=====');
      if (scrollTop + windowHeight >= scrollHeight && this.page <= this.pageCount) {
        // console.log(scrollHeight);
        // console.log("到底了");
        this.page++
        this.aesKey = aesKey(this.page)
        let data = {
          page: this.page
        }
        this.aesKey = aesKey(data)
        this.getGoodsList(this.aesKey)
      }
    },
    // 获取商城推荐商品
    getGoods_recommend () {
      let data = {
        is_recommend: 1
      }
      this.aesKey = aesKey(data)
      getGoodsList(this.aesKey).then(res => {
        console.log(res.data.data.list, "===");
        let arr = []
        res.data.data.list.forEach(item => {
          if (item.status == 2) {
            arr.push(item)
          }
        })
        let dataArr = arr.map(item => {  // eslint-disable-line no-unused-vars
          return {
            id: item.id,
            img: item.big_images,
            name: item.name,
            type: item.brand_name,
            // 价格展示
            after_price: item.sales_price,
            depreciate_price: item.sales_price - item.cost_price
          }
        })
        dataArr.splice(8)
        this.shopList = dataArr
        // console.log(this.shopList);
        console.log(dataArr, "=====dataArr====");
      })

    },

    //获取商城列表数据
    getGoodsList (data) {
      console.log(data, "=====data=====");
      if (data) {
        console.log("第一个方法");
        getGoodsList(data).then(res => {
          let arr = []
          res.data.data.list.forEach(item => {
            if (item.status == 2) {
              arr.push(item)
            }
          })
          this.pageCount = Math.ceil(res.data.data.total / 15)
          let dataArr = arr.map(item => {
            return {
              id: item.id,
              img: item.big_images,
              describe: item.name,
              type: item.brand_name,
              // 价格展示
              price: Math.ceil(
                Number(
                  item.sales_price *
                  (this.discount ? this.discount : 1)
                )
              ),
              oldPrice: item.sales_price
            }
          })
          this.imgsArr = this.imgsArr.concat(dataArr)
        })
      } else {

        console.log("第二个方法");
        getGoodsList(this.aesKey).then(res => {
          let arr = []
          res.data.data.list.forEach(item => {
            if (item.status == 2) {
              arr.push(item)
            }
          })
          this.pageCount = Math.ceil(res.data.data.total / 15)
          let imgsArr = arr.map(item => {
            return {
              id: item.id,
              img: item.big_images,
              describe: item.name,
              type: item.brand_name,
              // 价格展示
              price: Math.ceil(
                Number(
                  item.sales_price *
                  (this.discount ? this.discount : 1)
                )
              ),
              oldPrice: item.sales_price
            }
          })
          this.imgsArr = this.imgsArr.concat(imgsArr)

        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  // overflow: hidden;
  width: 100%;
  // height: 800px;
  background-color: #f7f7f7;
  .content_top {
    .my-swipe .van-swipe-item {
      font-size: 20px;
      // line-height: 150px;
      text-align: center;
      // background-color: #39a9ed;
      img {
        width: 100%;
      }
    }
    .search {
      position: absolute;
      width: 100%;
      background: none;
      top: 0;
    }
    width: 100%;
    height: auto;
    // background-image: url("../../../assets/shopImg/shop_banner.png");
    background-repeat: no-repeat;
    background-size: contain;
    .banner_hot {
      width: 100%;
      top: 25%;
      left: 0%;
      position: absolute;
      img {
        width: 100%;
      }
    }
    .everyday {
      width: 95%;
      // margin-top: 60%;
    }
  }
  .shop_date {
    // margin-top: 20%;
    width: 93%;
    margin-left: 3.5%;
    .shop_date_left {
      display: inline-block;
      width: 48%;
      margin-right: 3.5%;
      img {
        width: 100%;
      }
    }
    .shop_date_right {
      display: inline-block;

      width: 48%;
      img {
        width: 100%;
      }
    }
  }
  .gilt {
    padding-top: 5px;
    background-image: url("../../../assets/shopImg/shopping.png");
    width: 93%;
    height: 206px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 3.5%;
    .top {
      overflow: hidden;
      margin: 0px 10px 10px 10px;

      .top_left {
        float: left;
      }
      .top_right {
        float: right;
        margin-right: 10px;
      }
    }

    .tabList {
      margin-right: 20px;
      .seamless-warp2 {
        // height: 140px;
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
        ul.item {
          overflow: hidden;
          // width: 100%;

          li {
            float: left;
            width: 69px;
            margin-left: 16px;

            .depreciate_img {
              height: 100%;
              overflow: hidden;
              display: flex;
              .depreciate_price {
                position: absolute;
                font-size: 12px;
                margin-left: 10px;
                transform: scale(0.7);
                color: white;
              }
            }
            img {
              width: 100%;
              display: block;
              text-align: center;
            }
            .after_price {
              color: #fe2051;
              font-size: 16px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .ShopList {
    /deep/ .van-tab--active {
      color: #2dd2b5 !important;
      font-weight: 600;
      font-size: 16px !important;
    }
    padding-bottom: 20px;

    background-color: white;
    height: 800px;
    width: 100%;
    margin-top: 20px;
    border-radius: 20px;
    margin-bottom: 2rem;
    /deep/ .van-tabs__wrap {
      border-radius: 20px;
    }
    .is_fixed {
      position: fixed;
      top: 0%;
      width: 100%;
    }
    #boxFixed {
      /deep/.van-tab__text--ellipsis {
        // font-size: 0.25rem;
        overflow: visible;
      }
      /deep/.van-tabs__line {
        background-color: #36e0c9;
        width: 0.6rem;
      }
      /deep/.van-tab--active {
        color: #333333;
        font-size: bold;
      }
      /deep/.vue-waterfall-easy {
        a.img-inner-box {
          box-shadow: none;
          a {
            height: 100% !important;
            width: auto !important;
          }
        }
        img {
          border-radius: 5px;
          width: 93%;
        }
      }
      .img-box .default-card-animation {
        padding: 0;
      }
    }
    /deep/.vue-waterfall-easy-container
      .vue-waterfall-easy-scroll[data-v-ded6b974] {
      overflow-x: visible;
      overflow-y: visible;
      padding-bottom: 50px;
      /deep/ .img-box .default-card-animation {
        padding-bottom: 50px;
      }
    }
  }
  .bottom {
    text-align: center;
    img {
      width: 60%;
    }
  }
}
</style>