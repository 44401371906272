<template>
  <div id="shopConfirmOrder"
       :style="{ height: innerHeight }">
    <!-- <NavBar NavBarTitle='确认订单'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="addressInfo">
      <!-- 点击获得地址 -->
      <div v-if="showInfo">
        <div class="Info"
             v-for="(item) in addressInfo"
             :key="item.id">
          <div style="width:95%"
               @click="getAddressInfo(item)">
            <div class="town">
              <van-tag type="danger"
                       v-if="item.is_default">默认</van-tag>
              <span>{{ item.area }}</span>

            </div>
            <div class="detail">
              {{ item.address }}
            </div>
            <div class="perInfo">
              {{ item.name }}<span>{{ item.phone }}</span>
            </div>
          </div>
          <van-icon name="arrow" />
        </div>
      </div>
      <!-- 从本地存储拿到的数据 -->
      <div class="Info"
           v-if="showAddressInfo">
        <div style="width:95%"
             @click="getAddressInfo">
          <div class="town">
            <van-tag type="danger">默认</van-tag>
            <span>{{ shopAddress.area }}</span>

          </div>
          <div class="detail">
            {{ shopAddress.address}}
          </div>
          <div class="perInfo">
            {{ shopAddress.name }}<span>{{ shopAddress.phone }}</span>
          </div>
        </div>
        <van-icon name="arrow" />
      </div>
      <!--无收货地址时展示 -->
      <div class="no_Info"
           v-show="showAddress"
           @click="getAddressInfo">

        <img src="../../../assets/shopImg/shopAddress.png"
             alt="">
        <h4>请设置收货地址</h4>

      </div>
    </div>

    <div class="shopCard">
      <div class="supplier">观视界旅行</div>
      <van-card :price="shopTotalPrice"
                :desc="goodsData.goods_name"
                :title="goodsData.name"
                :thumb="goodsData.image">
        <template #footer
                  class="numAdd">
          <van-icon name="minus"
                    @click="reduceNum" />
          <span class="num">{{ shopNum }}</span>
          <van-icon name="plus"
                    @click="addNum" />
        </template>
      </van-card>
      <div class="under">
        <div class="underInfo">
          配送<span>普通配送</span>
          <a>快递免邮</a>
        </div>
        <div class="underInfo">
          积分特惠<span>省20元:1</span>
          <a style="color:#FE6529">-￥1.00
            <van-icon name="arrow" />
          </a>
        </div>
        <div class="underInfo">
          订单备注<span>选填请先和商家协商一致</span>
        </div>
        <div class="numPriceInfo">
          &nbsp;
          <div class="numPrice">
            共{{ shopNum }}件<span>小计：<a>￥{{ shopTotalPrice }}</a></span>
          </div>
        </div>
      </div>

    </div>
    <van-cell is-link
              @click="showPopup">展示弹出层</van-cell>
    <van-popup v-model="showPay">
      <div style="font-size:0.5rem;padding-top: .1rem;">是否已支付</div>
      <div class="btn">
        <van-button type="primary"
                    @click="goShopOrder">已支付</van-button>
        <van-button type="warning"
                    @click="reloadPay">未支付</van-button>
      </div>
    </van-popup>
    <van-submit-bar :price="shopTotalPrice * 100"
                    :disabled="isDisabled"
                    button-text="提交订单"
                    button-color="#fe6529"
                    @submit="onSubmit" />
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { getJSSDK, PAYJSSDK } from '@/api/JSSDk.js'
import { submitGoodsOrder, payGoodsOrder, checkPayStatus } from '../../../api/shop.js'
import { aesKey } from "../../../commom/aesKey"
import { Toast } from 'vant';
// import NavBar from '@/components/NavBar'
// import { getAddress } from '../../../api/address.js'
export default {
  components: {
    // NavBar
  },
  data () {
    return {
      isDisabled: false,
      showPay: false,//支付是否成功
      showAddress: true,//无收货地址时展示
      showInfo: false,//从后台获取到的地址
      showAddressInfo: false,//本地存储地址  
      addressInfo: [],//后台获取地址信息数据
      shopAddress: [],//本地存储地址信息数据
      url: '',
      jsSdk: null,
      fieldValue: '',
      innerHeight: 0,
      aesKey: aesKey(),//接口通过密文来获取数据

      goodsData: {},//商品数据
      goods_id: '',//商品id 当该商品的数据没有规格时
      goods_sku_id: '',//商品id 当该商品的数据有规格时
      shopNum: 1,//商品数量
      shopPrice: 129.00,//商品价格 中转使用
      shopTotalPrice: 129.00,//商品总价格
      product_order_no: null,//订单号
      prepay_id: null,
    }
  },
  mounted () {
    this.show()
    this.shopTotalPrice = this.shopTotalPrice.toFixed(2)
    this.innerHeight = window.innerHeight + 'px'
    if (this.$route.query.addressInfo === undefined) {
      this.addressInfo = this.shopAddress
    } else {
      this.addressInfo = this.$route.query.addressInfo
    }


    //对商品数据进行处理判断
    let orderData = JSON.parse(sessionStorage.getItem('orderData'))
    // console.log(orderData)
    if (orderData.selectedSkuComb == undefined) {
      this.goods_id = orderData.goods_id
      this.shopNum = orderData.num
      this.shopPrice = orderData.sales_price || orderData.price
      this.shopTotalPrice = orderData.sales_price || orderData.price
      this.goodsData.image = orderData.image
      this.goodsData.name = orderData.name
    } else {
      this.goods_sku_id = orderData.selectedSkuComb.goods_id
      this.shopNum = orderData.num
      this.shopPrice = orderData.selectedSkuComb.sales_price || orderData.selectedSkuComb.price / 100
      this.shopTotalPrice = orderData.selectedSkuComb.sales_price || orderData.selectedSkuComb.price / 100
      this.goodsData.image = orderData.selectedSkuComb.imgUrl
      this.goodsData.name = orderData.name
      this.goodsData.goods_name = orderData.selectedSkuComb.goods_name
    }
  },
  watch: {
    shopNum (newName) {
      this.shopTotalPrice = this.shopPrice
      this.shopTotalPrice = (newName * this.shopTotalPrice).toFixed(2)
    }
  },
  created () {
    this.getAddress()
    this.addressShow()
    // this.shopAddress = JSON.parse(localStorage.getItem('shopAddress'))
    // console.log(this.shopAddress, "=====");
    // this.addressInfo = JSON.parse(localStorage.getItem('shopAddress'))
    // this.getAddress()
    this.shareWechat()
    this.addressInfo = this.$route.query.addressInfo
    // this.getAddress()
    // setTimeout(() => {


    // }, 500);
    // setTimeout(() => {
    //   if (this.addressInfo.length != 0) {
    //     return
    //   } else {


    //   }
    // }, 1000);
  },
  beforeCreate () {
  },
  methods: {

    goShopOrder () {
      this.$router.push({ path: '/allShop/shopMyOrder' })
    },
    //localStorage获取的本地存储数据
    getAddress () {
      // console.log(this.addressInfo);
      if (localStorage.getItem("shopAddress") == null) {
        console.log("shopAddress不存在");
      } else {
        this.shopAddress = JSON.parse(localStorage.getItem('shopAddress'))
      }
      // console.log(this.shopAddress, "=====shopAddress");
      let address = this.shopAddress
      console.log(address);
      if (address == null) {
        // console.log(this.showAddressInfo, "当前ls状态");
        this.showAddressInfo = false
        return
      }
      else if (address.area.parent.level == 2) {
        // console.log(address.area.parent.level);
        this.fieldValue =
          address.area.parent.parent.name +
          address.area.parent.name +
          address.area.name;
      } else {
        this.fieldValue =
          address.area.parent.parent.parent.name +
          address.area.parent.parent.name +
          address.area.parent.name +
          address.area.name;
      }
      address.area = this.fieldValue
      //   let data = {
      //     is_default: 1
      //   }
      //   // console.log("获取地址");
      //   await getAddress(data).then((res) => {
      //     // console.log(res);
      //     const Data = res.data;
      //     this.addressInfo = Data.data.list;
      //     console.log(this.addressInfo);
      //     // this.addressInfo = JSON.parse(Data)
      //     console.log(this.addressInfo);
      //     this.addressInfo.map((item) => {
      //       // console.log(item);
      //       if (item.area.parent.level == 2) {
      //         this.fieldValue =
      //           item.area.parent.parent.name +
      //           item.area.parent.name +
      //           item.area.name;
      //       } else {
      //         this.fieldValue =
      //           item.area.parent.parent.parent.name +
      //           item.area.parent.parent.name +
      //           item.area.parent.name +
      //           item.area.name;
      //       }
      //       item.area = this.fieldValue
      //     })
      //   });
    },

    //无收货地址时
    addressShow () {
      // console.log(this.shopAddress);
      // console.log("=====shopAddress====", this.shopAddress);
      if (this.shopAddress == null) {
        this.showAddress = true
        this.showAddressInfo = false
        this.showInfo = false
      } else {
        this.showAddress = false
        this.showAddressInfo = true
      }
    },
    //本地存储的收货地址
    show () {
      // console.log(typeof (this.addressInfo));
      if (this.addressInfo === undefined) {
        this.showAddressInfo = true
        this.showInfo = false
        // console.log("1111111");
      } else if (typeof (this.addressInfo) === 'string') {
        this.showAddressInfo = true
        this.showInfo = false
        // console.log("2222222");

      } else {
        this.showInfo = true
        this.showAddressInfo = false
        // console.log("333333");

      }
    },

    onClickLeft () {
      // this.$router.push({ path: '/allShop/shopDetail' })
      this.$router.go(-1)
    },

    //上面地址信息点击时
    getAddressInfo () {
      this.$router.push({ path: '/allShop/shopAddress' })
    },
    //减少商品数量
    reduceNum () {
      if (this.shopNum == 1) {
        this.$toast({
          message: '数量不能小于1',
        });
        return
      }
      this.shopNum--
    },
    //增加商品数量
    addNum () {
      this.shopNum++
    },


    //提交订单
    async onSubmit () {
      this.isDisabled = true
      console.log("点击了按钮");
      console.log(this.shopAddress.phone);
      console.log(this.addressInfo);
      let data = {
        balance: 0,
        mobile: this.addressInfo.phone || this.shopAddress.phone,
        // mobile : this.addressInfo.phone =
        real_name: this.addressInfo.name || this.shopAddress.name,
        address: this.addressInfo.area + this.addressInfo.address || this.shopAddress.area + this.shopAddress.address,
        area_code: this.addressInfo.area_code || this.shopAddress.area_code
      }
      // console.log(data, "11111111111");
      // console.log(this.addressInfo);
      let buy_list = []
      //用于判断该商品是否有规格，来判断buy_list中的id是那种类型
      if (this.goods_id == '') {
        //有规格的
        buy_list.push({
          goods_sku_id: this.goods_sku_id,
          num: this.shopNum
        })
        buy_list = JSON.stringify(buy_list)
        data.buy_list = buy_list
      } else {
        //无规格的
        buy_list.push({
          goods_id: this.goods_id,
          num: this.shopNum
        })
        buy_list = JSON.stringify(buy_list)
        data.buy_list = buy_list
      }
      // this.aesKey = aesKey(data)
      console.log(data);
      let res = await submitGoodsOrder(data)
      this.product_order_no = res.data.data.no
      console.log(res)
      if (res.data.code != 200) {
        Toast.fail("下单失败,请返回上一页下单或者来联系客服");
      }
      else if (res.data.code == 200) {
        console.log(res, "===res")
        this.product_order_no = res.data.data.no
        //微信支付订单
        let data2 = {
          pay_for: 3,//酒店
          pay_provider_id: 1,//1收银宝 //2云商通
          pay_method: 'W02',//微信js
          product_order_no: res.data.data.no,
        }
        console.log(data2, "data2");
        let res2 = await payGoodsOrder(data2)
        console.log(res2, "===res2")
        if (res2 == undefined) {
          this.reloadPay()
        }
        else if (res2.data.code != 200) {
          Toast.fail(res2.data.message);
        }
        else if (res2.data.code == 200) {
          let data3 = {
            prepay_id: res2.data.data.payinfo.prepay_id
          }
          this.aesKey = aesKey(data3)
          let res3 = await PAYJSSDK(this.aesKey)
          console.log("====res3", res3);
          if (res3.data.code == 200) {
            // this.res2 = res2
            Toast.fail('支付中');
            // this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
            // this.onBridgeReady(this.res2.data.data.payinfo)
            wx.chooseWXPay({
              timestamp: res3.data.data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: res3.data.data.nonceStr, // 支付签名随机串，不长于 32 位
              package: res3.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: res3.data.data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: res3.data.data.paySign, // 支付签名
              success: function (res) {
                // 支付成功后的回调函数
                console.log(res);
                // this.$router.push({ path: '/allShop/shopMyOrder' })
                window.location.href = "https://pt.guansj.com/allShop/shopMyOrder"
              },
              cancel: function (res) {
                console.log(res);
                window.location.href = "https://pt.guansj.com/allShop/shopMyOrder"
              },
              // fail (res) {
              //   console.log(res);
              //   This.$toast('支付失败');
              //   window.location.href = "https://pt.guansj.com/allShop/shopMyOrder"
              // }
            });
            // 
          }
        }
      } else {
        if (res.data.message == "未绑定手机号码") {
          Toast.fail(res.data.message);
          setTimeout(() => {
            this.$router.push({ path: '/bindPhone' })
          }, 2000)
        }
      }
    },
    //重新调出支付
    async reloadPay () {
      this.showPay = true
      let data2 = {
        product_order_no: this.product_order_no
      }
      console.log(data2, "data2");
      checkPayStatus(data2).then(res => {

        console.log(res, "payStatus订单状态");
        this.prepay_id = res.data.data.pay.prepay_id
        console.log(this.prepay_id, " this.prepay_id");
        if (res.data.code == 200) {
          console.log("订单状正常");
          let data3 = {
            prepay_id: this.prepay_id
          }

          this.aesKey = aesKey(data3)
          console.log("准备进入微信支付", this.aesKey);
          PAYJSSDK(this.aesKey).then(res3 => {
            console.log("弹出微信支付");
            console.log("====res3", res3);
            if (res3.data.code == 200) {
              // this.res2 = res2
              Toast.fail('支付中');
              // this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
              // this.onBridgeReady(this.res2.data.data.payinfo)
              wx.chooseWXPay({
                timestamp: res3.data.data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                nonceStr: res3.data.data.nonceStr, // 支付签名随机串，不长于 32 位
                package: res3.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                signType: res3.data.data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                paySign: res3.data.data.paySign, // 支付签名
                success: function (res) {
                  // 支付成功后的回调函数
                  console.log(res);
                  // this.$router.push({ path: '/allShop/shopMyOrder' })
                  window.location.href = "https://pt.guansj.com/allShop/shopMyOrder"
                },
                cancel: function (res) {
                  console.log(res);
                  window.location.href = "https://pt.guansj.com/allShop/shopMyOrder"
                },
                // fail (res) {
                //   console.log(res);
                //   This.$toast('支付失败');
                //   window.location.href = "https://pt.guansj.com/allShop/shopMyOrder"
                // }
              });
              // 
            }
          })

        }
      })
    },
    //JsSDK
    onBridgeReady (data) {
      window.WeixinJSBridge.invoke(
        console.log(data),
        'getBrandWCPayRequest', {
        "appId": data.appid,     //公众号ID，由商户传入     
        "timeStamp": data.timeStamp,         //时间戳，自1970年以来的秒数     
        "nonceStr": data.nonceStr, //随机串     
        "package": data.package,
        "signType": data.signType,         //微信签名方式：     
        "paySign": data.paySign //微信签名 
      },
        function (res) {
          // get_brand_wcpay_request:ok	支付成功
          // get_brand_wcpay_request:cancel	支付过程中用户取消
          // get_brand_wcpay_request:fail	支付失败
          if (res.err_msg == "get_brand_wcpay_request:cancel" || res.err_msg == "get_brand_wcpay_request:fail") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.res3 = res
            Toast.fail('支付失败');
            window.location.href = "https:///pt.guansj.com/allShop/shopMyOrder"

          } else {
            // //用户支付成功后，代理商账户扣钱
            // let data = {
            //   order_no: this.order_no
            // }
            // this.aesKey = aesKey(data)
            // this.payHotelOrder(this.aesKey)
            Toast.success('支付成功');
            window.location.href = "https://pt.guansj.com/allShop/shopMyOrder"
          }
        });
    },
    shareWechat () {
      this.url = window.location.href.split('#')[0]
      let data = {
        url: this.url,
        apis: ['chooseWXPay'],
      }
      // console.log(data);
      this.aesKey = aesKey(data)
      getJSSDK(this.aesKey).then(res => {
        this.jsSdk = res.data.data
        // console.log(this.jsSdk, "JSSDK");
        wx.config({
          debug: false,
          appId: res.data.data.appId,
          timestamp: res.data.data.timestamp,
          nonceStr: res.data.data.nonceStr,
          signature: res.data.data.signature,
          jsApiList: ['chooseWXPay']
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
#shopConfirmOrder {
  background-color: #f5f5f5;

  .van-ellipsis {
    overflow: visible;
  }

  .addressInfo {
    .Info {
      text-align: left;
      background-color: white;
      padding: 0.2rem 0.4rem;
      font-size: 0.3rem;
      margin-bottom: 0.1852rem;
      border-end-end-radius: 10px;
      border-end-start-radius: 10px;
      display: flex;
      .town {
        font-size: 0.24rem;
        color: #666666;

        span {
          margin-right: 0.1rem;
        }
      }

      .detail {
        font-size: 0.3rem;
        font-weight: bold;
      }

      .perInfo {
        font-size: 0.24rem;
        color: #666666;

        span {
          margin-left: 0.3704rem;
        }
      }

      .van-icon {
        font-size: 0.3rem;
        line-height: 1.1111rem;
        color: gray;
      }
    }
    .no_Info {
      text-align: left;
      background-color: white;
      padding: 0.2rem 0.4rem;
      font-size: 0.3rem;
      margin-bottom: 0.1852rem;
      border-end-end-radius: 10px;
      border-end-start-radius: 10px;
      display: flex;
      position: relative;
      img {
        width: 5%;
        position: absolute;
        top: 30%;
      }
      h4 {
        display: inline-block;
        line-height: 0;
        padding-left: 0.5556rem;
      }
      .btn {
        text-align: center;
        width: 75%;
        left: 13%;
        font-size: 0.27rem;
        height: 0.7rem;
      }
    }
  }

  .shopCard {
    text-align: left;
    border-radius: 10px;
    background-color: white;
    font-size: 0.35rem;

    .supplier {
      padding: 0 0.3rem;
      font-size: 0.28rem;
      padding: 0.2rem 0.3rem;
    }

    .van-card {
      background-color: white;
      border-radius: 10px;
      font-size: 0.2rem;
      padding: 0 0.3rem;
      margin-top: 0px;
      position: relative;

      .van-card__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 4.5rem;
        font-size: 0.3rem;
        height: auto;
        display: inline-block;
        line-height: inherit;
      }

      .van-card__thumb {
        width: 1.5rem;
        height: 1.5rem;
      }

      .van-card__desc {
        display: inline;
        padding: 0.05rem 0.1rem;
        background-color: #f5f5f5;
        color: #999999;
        // width: 1rem;
      }

      .van-card__price {
        font-size: 0.3rem;
        font-weight: bold;
        color: #fe6529;

        .van-card__price-currency {
          font-size: 0.2rem;
        }

        .van-card__price-integer {
          font-size: 0.3rem;
        }
      }

      .van-card__footer {
        font-size: 0.3rem;
        position: absolute;
        right: 5%;
        bottom: 0%;

        .num {
          display: inline-block;
          padding: 0.05rem 0.15rem;
          background-color: #f5f5f5;
          margin-left: 0.15rem;
        }

        .van-icon-plus {
          margin-left: 0.15rem;
        }
      }
    }

    .under {
      padding: 0.2rem 0.3rem;

      .underInfo {
        font-size: 0.27rem;
        padding: 0.2rem 0;

        span {
          margin-left: 0.3rem;
          color: #999999;
        }

        a {
          float: right;
          color: #999999;
        }
      }

      .numPriceInfo {
        background-color: white;

        .numPrice {
          font-size: 0.27rem;
          color: #999999;
          float: right;

          span {
            margin-left: 0.2rem;
            color: black;

            a {
              color: #fe6529;
            }
          }
        }
      }
    }
  }

  .van-submit-bar__bar {
    height: auto;
    padding: 0.1rem 0.3rem;

    .van-submit-bar__text {
      font-size: 0.26rem;
    }

    .van-submit-bar__price {
      font-size: 0.3rem;
      font-weight: bold;
      color: #fe6529;
    }

    .van-submit-bar__button {
      font-size: 0.25rem;
      width: 1.9rem;
      height: 0.7rem;
    }
  }
}
/deep/ .van-popup {
  border-radius: 10px;
  width: 4.25rem;
  height: 3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  .btn {
    // position: absolute;
    margin-top: 1rem;

    display: flex;
    justify-content: space-between;
    .van-button {
      border-radius: 10px;
    }
  }
}
</style>