<template>
  <div id="shopSort">
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="sidebar">
      <van-sidebar v-model="activeKey">
        <van-sidebar-item v-for="item in categoryData"
                          :key="item.id"
                          :title="item.category_name"
                          @click="choiceSort(item.id)" />
      </van-sidebar>
      <div class="content">
        <div v-for="item in sortData"
             :key="item.id">

          <div class="title">{{item.category_name}}
            <img style="width:30%;"
                 :src="item.category_pic"
                 alt="">
          </div>
          <div class="sortGird">
            <div v-for="sort in item.child"
                 :key="sort.id"
                 @click="getGoods(sort)">
              <img :src="sort.category_pic"
                   alt="">
              <span class="sortName">{{sort.category_name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <van-tabbar v-model="bottomActive"
                  @change="switchBottomTab"
                  active-color='#58e3dd'>
        <van-tabbar-item>
          <img v-if="bottomActive == 0"
               src="@/assets/shopImg/shopping_active.png"
               alt="">
          <img v-else
               src="@/assets/shopImg/shopping_icon.png"
               alt="">
          <div>商城</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 1"
               src="@/assets/shopImg/classification_active.png"
               alt="">
          <img v-else
               src="@/assets/shopImg/classification_icon.png"
               alt="">
          <div>分类</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 2"
               src="@/assets/shopImg/ShoppingCar _active.png"
               alt="">
          <img v-else
               src="@/assets/shopImg/ShoppingCar.png"
               alt="">
          <div>购物车</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 3"
               src="@/assets/shopImg/order_active.png"
               alt="">
          <img v-else
               src="@/assets/shopImg/order_icon.png"
               alt="">
          <div>订单</div>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { getGoodcategory, getGoodsList } from '../../../api/shop.js'
import { aesKey } from "../../../commom/aesKey"
// import NavBar from '@/components/NavBar'
export default {
  components: {
    // NavBar
  },
  data () {
    return {
      bottomActive: 1,
      NavBarTitle: '商品分类',//顶部导航名称
      aesKey: aesKey(),//接口通过密文来获取数据
      activeKey: 0,//侧边导航
      categoryData: [],//左边分类数据
      sortData: [],//右边显示的数据

    }
  },
  mounted () {
    this.getGoodcategory()
  },
  watch: {
    categoryData (newData) {
      newData.forEach(item => {
        if (item.id == 49) {
          this.sortData = item.child
        }
      })
    }
  },
  methods: {
    //查询商品分类信息
    getGoodcategory () {
      getGoodcategory(this.aesKey).then(res => {
        let arr = res.data.data
        this.categoryData = arr
      })
    },
    getGoodsList (data) {
      getGoodsList(data).then(res => {
        this.sortData = res.data.data.list
      })
    },
    onClickLeft () {
      // this.$router.push({ path: '/allShop/shopHome' })
      this.$router.go(-1)
    },
    //底部按钮
    switchBottomTab () {
      // console.log(this.bottomActive)
      if (this.bottomActive == 0) {
        this.$router.push({ path: '/allShop/shopHome' })
      } else if (this.bottomActive == 1) {
        this.$router.push({ path: '/allShop/shopSort' })
      } else if (this.bottomActive == 2) {
        this.$router.push({ path: '/allShop/shopCart' })
      } else if (this.bottomActive == 3) {
        this.$router.push({ path: '/allShop/shopMyOrder' })
      }
    },
    //选择侧边导航
    choiceSort (index) {
      this.categoryData.forEach(item => {
        if (item.id == index) {
          this.sortData = item.child
        }
      })
    },
    //点击右边的数据
    getGoods (data) {
      this.$router.push({ path: '/allShop/shopList' })
      sessionStorage.setItem('categoryId', data.id)
      console.log(data)
    }
  }
}
</script>

<style lang="less" scoped>
#shopSort {
  height: 830px;
  .sidebar {
    height: 100%;
    position: relative;
    /deep/.van-sidebar {
      width: 2rem;
      height: 100%;
      background-color: #f5f5f5;
      .van-sidebar-item--select::before {
        background-color: white;
      }
      .van-sidebar-item {
        font-size: 0.25rem;
        padding: 0.25rem 0;
      }
    }
    .content {
      font-size: 0.25rem;
      position: absolute;
      left: 30%;
      top: 0%;
      width: 68%;
      .title {
        text-align: left;
        font-weight: bold;
        font-size: 0.28rem;
        margin-top: 0.2rem;
        img {
          display: flex;
        }
      }
      .sortGird {
        margin-top: 20px;
        text-align: left;
        div {
          width: 33%;
          display: inline-block;
          margin-top: 0.1rem;
          .sortName {
            font-size: 0.26rem;
            width: 90%;
            text-align: center;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          img {
            width: 1.3rem;
            height: 1.3rem;
          }
        }
      }
    }
  }
  .bottom {
    text-align: center;
    img {
      width: 60%;
    }
  }
}
</style>