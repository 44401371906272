import { request } from './request.js'

export function postCode(params){
  return request({
    url:'/agent/sms/bind-phone',
    method: 'POST',
    params
  })
}

export function postPhone(params){
  return request({
    url:'/agent/user/bind-phone',
    method: 'POST',
    params
  })
}