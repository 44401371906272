<template>
  <div style="width: 100%;height: 17rem;"
       class="bg">
    <van-button color="linear-gradient(to right, #db058d,#2328c4)"
                @click="goAttract"
                style="width: 80%;border-radius: 20px;">全网加盟咨询</van-button>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {
    goAttract () {
      this.$router.push({
        path: "/allHotel/Attract_investment"
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bg {
  background: url("../../../assets/Attract/bg.png");
  background-size: 100%;
  position: relative;
  .van-button {
    position: fixed;
    bottom: 5%;
    left: 10%;
  }
}
</style>