<template>
  <div id="realName">
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="content">
      <van-cell-group>
        <van-form validate-first>
          <div class="phone">
            <van-field v-model="phone"
                       name="pattern"
                       label="绑定手机号"
                       placeholder="请输入手机号"
                       :rules="[{ pattern:/^[1][3,4,5,6,7,8,9][0-9]{9}$/ }]">

            </van-field>
            <div class="code">
              <van-button v-if="isShow"
                          type="primary"
                          size="small"
                          color="#24c5af"
                          @click="postCode">发送验证码</van-button>
              <van-button v-if="!isShow"
                          type="primary"
                          disabled
                          size="small"
                          color="#24c5af">{{count}}秒</van-button>
            </div>

          </div>

          <van-field label="手机验证码"
                     v-model="verification_code"
                     placeholder="请输入验证码"></van-field>
        </van-form>
      </van-cell-group>
      <!-- <div class="nameId">
        <van-field readonly
                   clickable
                   :value="nameId"
                   placeholder="请输入手机号码"
                   @touchstart.native.stop="nameShow = true" />
        <van-number-keyboard v-model="nameId"
                             :show="nameShow"
                             :maxlength="11"
                             @blur="nameShow = false" />
      </div> -->

      <div class="btn">
        <van-button type="primary"
                    size="large"
                    round
                    color='#38e0c9'
                    @click="submit">立即绑定手机</van-button>
      </div>
      <div class="bottom">
        <div class="text">
          已阅读并同意
          <span>《用户服务协议》</span>、
          <span>《隐私政策》</span>及
          <span>《内容平台协议》</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { postCode, postPhone } from '@/api/phone.js'
// import NavBar from '../../components/NavBar.vue'
import { aesKey } from '../../commom/aesKey'
import { Toast } from 'vant';
export default {
  components: {
    // NavBar,
  },
  data () {
    return {
      verification_code: null,
      NavBarTitle: '绑定手机号',
      phone: '',
      count: 60,
      isShow: true,
      user_id: this.$store.getters.user_id,
      verification_key: ''
    }
  },
  computed: {
    getUserId () {
      return this.$store.state.user_id
    }
  },
  created () {

  },

  methods: {
    onClickLeft () {
      // this.$router.push({ path: '/Home' })
      this.$router.go(-1)
    },
    async postCode () {
      this.isShow = false
      let times = setInterval(() => {
        this.count--
        if (this.count <= 0) {
          this.isShow = true
          clearInterval(times)
        }
      }, 1000)
      let data = {
        user_id: this.user_id,
        phone: this.phone,
        type: 0
      }
      this.aesKey = aesKey(data)
      let res = await postCode(this.aesKey)
      console.log(res);
      let verification_key = res.data.data.verification_key
      this.verification_key = verification_key
      // console.log(res.data.verification_key, "====res.data.verification_key");
      // console.log(" ====this.verification_key", this.verification_key);
    },
    //绑定手机
    async submit () {
      // console.log(" ====this.verification_key", this.verification_key);
      let data = {
        verification_code: this.verification_code,
        verification_key: this.verification_key,
        phone: this.phone,
        user_id: this.user_id,
      }
      console.log(data);
      this.aesKey = aesKey(data)
      let res = await postPhone(this.aesKey)
      console.log(res);
      if (res.data.code == 200) {
        Toast.fail(res.data.message);
        setTimeout(() => {
          this.$router.go(-1)
        }, 1500)
      } else if (res.data.code == 400) {
        if (res.data.message == "验证码已过期") {
          Toast.fail("已经绑定手机");
          setTimeout(() => {
            // this.$router.go(-1)
            this.$router.push({ path: '/Home' })
          }, 1500)
        }
      }
      console.log("点击了提交");
    }
  }
}
</script>

<style lang="less" scoped>
#realName {
  font-size: 0.21rem;
  .content {
    margin-top: 0;
    .phone {
      position: relative;
      button {
        position: absolute;
        left: 80%;
        z-index: 10;
        top: 25%;
      }
    }
    margin-top: 0.6rem;
    padding: 0 0.2rem;
    // background-color: aqua;
    .title {
      font-size: 0.45rem;
      font-weight: bold;
    }
    .nameId {
      // margin-top: 1rem;
      z-index: 10;
      /deep/.van-cell {
        // font-size: 0.3rem;
      }
    }
    .btn {
      margin-top: 0.4rem;
      padding: 0 0.4rem;
      .van-button {
        font-size: 0.28rem;
      }
    }
    .bottom {
      position: absolute;
      width: 100%;
      bottom: 5%;
      .text {
        color: gray;
        width: 4.6rem;
        margin-left: 1.3rem;
        span {
          color: #24c5af;
        }
      }
    }
  }
}
</style>