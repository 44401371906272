  <template>
  <div class="container">
    <van-tabs v-model="active_tab"
              @click="tabCollect"
              title-active-color="#36e0c9"
              color="#36e0c9">
      <van-tab title="酒店">
        <div class="top">
          <span style="font-size: 14px; float: left">最近收藏</span>
          <span style="font-size: 14px; float: right"
                @click="edit">{{
            text_edit
          }}</span>
        </div>
        <div class="hotelList">
          <div class="hotel"
               v-for="(item, index) in hotelData"
               :key="item.id">
            <van-checkbox v-show="edit_show"
                          @click="hotelCheckbox(item, index)"
                          checked-color="#2EE8C6"
                          v-model="item.is_select"></van-checkbox>
            <div class="hotel_img"><img :src="item.hotel_pic"
                   alt="" /></div>
            <div class="hotel_text">
              <div class="hotel_title"
                   style="font-size: 18px">
                {{ item.describe }}
              </div>
              <div class="collect_total"
                   style="font-size: 12px">
                {{ item.favorite_count }}收藏
              </div>
              <div class="hotel_address"
                   style="font-size: 12px">
                {{ item.city_name }}
              </div>
              <div class="hotel_tab"
                   v-if="item.hotel_tags">
                <div class="tab">{{ item.hotel_tags[0] }}</div>
                <div class="tab">{{ item.hotel_tags[1] }}</div>
                <div class="tab">{{ item.hotel_tags[2] }}</div>
                <div class="price">
                  <span style="font-size: 0.1455rem">¥</span><span style="font-size: 20px">{{ item.starting_price }}</span><span style="font-size: 0.2455rem">起</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="hotel">
            <div class="hotel_img"><img src="../../../assets/HotelImg/collect_hotel.png"
                   alt=""></div>
            <div class="hotel_text">
              <div class="hotel_title"
                   style="font-size:18px">广州酒店</div>
              <div class="collect_total"
                   style="font-size:12px">152点评·111收藏</div>
              <div class="hotel_address"
                   style="font-size:12px">广州地区</div>
              <div class="hotel_tab">
                <div class="tab">主题房</div>
                <div class="tab">主题房</div>
                <div class="price"><span style="font-size: .1455rem;">¥</span><span style="font-size:20px">456</span><span style="font-size: .2455rem;">起</span></div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="nomore">没有更多了</div>
        <div class="del_collect"
             v-show="edit_show">
          <van-checkbox v-model="Allchecked"
                        checked-color="#2EE8C6"
                        @change="changeCheckedAll">全选</van-checkbox>
          <van-button plain
                      hairline
                      type="info"
                      @click="delCollect">取消收藏</van-button>
        </div>
      </van-tab>
      <van-tab title="旅游">内容 2</van-tab>
      <van-tab title="商城"
               @click="tabCollect">
        <div class="top">
          <span style="font-size: 14px; float: left">最近收藏</span>
          <span style="font-size: 14px; float: right"
                @click="edit">{{
            text_edit
          }}</span>
        </div>
        <div class="hotelList">
          <div class="hotel"
               v-for="(item, index) in shopData"
               :key="item.id">
            <van-checkbox v-show="edit_show"
                          @click="hotelCheckbox(item, index)"
                          checked-color="#2EE8C6"
                          v-model="item.is_select"></van-checkbox>
            <div class="hotel_img"><img :src="item.big_images"
                   alt="" /></div>
            <div class="hotel_text">
              <div class="hotel_title"
                   style="font-size: 18px">
                {{ item.describe }}
              </div>
              <!-- <div class="collect_total"
                   style="font-size: 12px">
                {{ item.favorite_count }}收藏
              </div> -->
              <div class="hotel_address"
                   style="font-size: 12px">
                {{ item.name }}
              </div>
              <div class="shop_tab"
                   v-if="item.goods_category_top">
                <div class="tab">{{ item.goods_category_top[0].category_name }}</div>
                <!-- <div class="tab">{{ item.goods_category_top[1] }}</div>
                <div class="tab">{{ item.goods_category_top[2] }}</div> -->
                <div class="price">
                  <span style="font-size: 0.1455rem">¥</span><span style="font-size: 20px">{{ item.sales_price }}</span><span style="font-size: 0.2455rem">起</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="hotel">
            <div class="hotel_img"><img src="../../../assets/HotelImg/collect_hotel.png"
                   alt=""></div>
            <div class="hotel_text">
              <div class="hotel_title"
                   style="font-size:18px">广州酒店</div>
              <div class="collect_total"
                   style="font-size:12px">152点评·111收藏</div>
              <div class="hotel_address"
                   style="font-size:12px">广州地区</div>
              <div class="hotel_tab">
                <div class="tab">主题房</div>
                <div class="tab">主题房</div>
                <div class="price"><span style="font-size: .1455rem;">¥</span><span style="font-size:20px">456</span><span style="font-size: .2455rem;">起</span></div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="nomore">没有更多了</div>
        <div class="del_collect"
             v-show="edit_show">
          <van-checkbox v-model="Allchecked"
                        checked-color="#2EE8C6"
                        @change="changeCheckedAll">全选</van-checkbox>
          <van-button plain
                      hairline
                      type="info"
                      @click="delCollect">取消收藏</van-button>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
  
  <script>
import { getCollect, delCollect } from "@/api/collect";
import { getHomeData } from "@/api/hotel";
import { aesKey } from "@/commom/aesKey"//eslint-disable-line no-unused-vars

import { getGoodsList } from "@/api/shop";//eslint-disable-line no-unused-vars
// import DataList from "./1.js";
export default {
  data () {
    return {
      active_tab: 0,
      Allchecked: false,
      edit_show: false,
      text_edit: "编辑",
      aesKey: null,
      selectedData: [],
      hotelArr: null,
      checkedItem_hotel: 0, // 设置选中全选复选框后重新给选项复选框赋值,即选中所有的选项复选框
      checkedItem_shop: 0, // 设置选中全选复选框后重新给选项复选框赋值,即选中所有的选项复选框
      hotelData: [],
      shopArr: null,
      shopData: []
    };
  },
  created () {

    this.getCollect(2)
    // this.getHotel()
  },
  mounted () {
    // this.hotelDataAdd();
  },
  watch: {
    // hotelData () {
    //   console.log(this.hotelData);
    //   this.hotelData.forEach(item => {
    //     console.log(item);
    //   })
    // }
    // Allchecked () {
    //   if (this.checkedItem_hotel == this.hotelData.length) {
    //     console.log("watch");
    //     this.Allchecked = true
    //   }
    // }
  },
  computed: {
    // 全选复选框
  },
  methods: {
    // 查询用户产品收藏数量
    test () {
      console.log(123);
    },
    //切换
    tabCollect () {
      if (this.active_tab == 2) {
        console.log("切换商城");
        this.getCollect(3)
      }
    },
    getCollect (type) {
      let data = {
        type: type,
      };
      getCollect(data).then((res) => {
        console.log(res.data.data.list);
        if (res.data.data.total != 0) {
          this.shopArr = res.data.data.list
          console.log(res.data.data.list[0].product_id);
          let arr = [];
          if (this.active_tab == 0) {
            this.hotelArr = res.data.data.list;
            this.hotelArr.forEach((item) => {
              arr.push(item.product_id);
              // console.log(item.product_id);
            });
            let newArr = arr.toString();
            let hotelArr = {
              id: newArr,
            };
            getHomeData(hotelArr).then((res) => {
              this.hotelData = res.data.data.list;

              // for (let i in this.hotelData) {
              //   this.hotelData[i].is_select = null
              // }
              this.hotelData.forEach((item) => {
                this.$set(item, "is_select", false);
              });
              // console.log(this.hotelData);
            });
          } else if (this.active_tab == 2) {
            let arr = []
            this.shopArr.forEach(item => {
              arr.push(item.product_id);
            })
            let newArr = arr.toString();
            let ShopArr = {
              id: newArr,
            };
            // let data = {
            //   id: this.shopArr
            // }
            // console.log(data);
            this.aesKey = aesKey(ShopArr)
            getGoodsList(this.aesKey).then(res => {
              this.shopData = res.data.data.list
              this.shopData.forEach((item) => {
                this.$set(item, "is_select", false);
              });
              console.log(res);
            })
          }
        }


      });
    },
    getHotel () {
      console.log(...this.hotelData);
    },
    delCollect () {
      if (this.active_tab == 0) {
        let newArr = [] //删除选中的数组
        this.hotelData.filter(item => {
          if (item.is_select == true) {
            console.log(item.hotel_id);
            newArr.push(item.hotel_id)
          }
        })
        let delList = this.hotelArr.filter(item => {
          if (newArr.includes(item.product_id)) {
            return item
          }
        })
        let hotelId = []
        delList.forEach(item => {
          hotelId.push(item.id)
        })
        let hotel_id = hotelId.toString()
        console.log(hotel_id);
        let data = {
          id: hotel_id,
          type: 2
        }
        console.log(data);
        delCollect(data).then(res => {
          if (res.data.message == '删除成功!') {
            this.getCollect()
          }
        })
      } else if (this.active_tab == 2) {
        let newArr = [] //删除选中的数组
        this.shopData.filter(item => {
          console.log(item);
          if (item.is_select == true) {
            newArr.push(item.id)
          }
        })
        let delList = this.shopArr.filter(item => {
          if (newArr.includes(item.product_id)) {
            return item
          }
        })
        let shopId = []
        delList.forEach(item => {
          shopId.push(item.id)
        })
        let shop_Id = shopId.toString()
        console.log(shop_Id);
        let data = {
          id: shop_Id,
          type: 3
        }
        console.log(data);
        delCollect(data).then(res => {
          if (res.data.message == '删除成功!') {
            this.getCollect()
          }
        })
      }

    },
    // 产品的复选框
    hotelCheckbox (item, index) {
      if (this.active_tab == 0) {
        if (item.is_select) {
          console.log(this.checkedItem_hotel);
          this.hotelData[index].is_select = true;
          this.checkedItem_hotel++
        } else {
          this.hotelData[index].is_select = false;
          this.checkedItem_hotel--
        }
        if (this.checkedItem_hotel == this.hotelData.length) {
          this.Allchecked = true
        }
      } else if (this.active_tab == 2) {
        if (item.is_select) {
          this.shopData[index].is_select = true;
          this.checkedItem_shop++
        } else {
          this.shopData[index].is_select = false;
          this.checkedItem_shop--
        }
        if (this.checkedItem_shop == this.shopData.length) {
          this.Allchecked = true
        }
      }

    },

    changeCheckedAll (checked) {
      //eslint-disable-line no-unused-vars
      // let a = this.carData.findIndex(target => target.is_select === false)
      console.log(checked);
      if (this.active_tab == 0) {
        if (checked) {
          this.hotelData.forEach((item) => {
            item.is_select = true;
            this.checkedItem_hotel = this.hotelData.length
          });
        } else {
          this.hotelData.forEach((item) => {
            item.is_select = false;
            this.checkedItem_hotel = 0
          });
        }
      }
      else if (this.active_tab == 2) {
        if (checked) {
          this.shopData.forEach((item) => {
            item.is_select = true;
            this.checkedItem_hotel = this.shopData.length
          });
        } else {
          this.shopData.forEach((item) => {
            item.is_select = false;
            this.checkedItem_hotel = 0
          });
        }
      }

    },
    hotelDataAdd () {
      // console.log(12);
      this.hotelData.forEach((item) => {
        this.$set(item, "is_select", false);
      });
      console.log(this.hotelData);
    },
    edit () {
      if (this.text_edit == "完成") {
        this.text_edit = "编辑";
        this.edit_show = false;
      } else if (this.text_edit == "编辑") {
        this.text_edit = "完成";
        this.edit_show = true;
      }
      console.log("点击了编辑");
    },
    remove (arr, val) {
      var index = arr.indexOf(val);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },
  },
};
</script>
  
  <style lang="less"  scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0;
}
/deep/.van-tabs__wrap {
}
.container {
  background-color: #f8f8f8;
  .top {
    span {
      display: inline-block;
      /deep/ .van-button {
        display: inline-block;
      }
    }
    width: 90%;
    padding: 0.3091rem 0.2727rem;
  }
  .hotelList {
    margin-top: 0.3636rem;
    padding: 0.2182rem 0.2909rem;
    background-color: white;
    .hotel {
      width: 380px;
      height: 112px;
      // background-color: #36e0c9;
      .van-checkbox {
        display: inline-block;
        float: left;
        margin-right: 10px;
        margin-top: 0.6364rem;
        top: 20%;
      }
      .hotel_img {
        img {
          width: 1.5273rem;
          height: 1.5273rem;
        }
        width: 1.5273rem;
        height: 1.5273rem;
        background-color: antiquewhite;
        float: left;
      }
      .hotel_text {
        overflow: hidden;
        padding-left: 14px;

        .hotel_title {
          text-align: left;
          line-height: 20px;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .collect_total {
          text-align: left;
          margin-top: 0.0545rem;
        }
        .hotel_address {
          margin-top: 0.0545rem;

          text-align: left;
          color: #666666;
        }
        .hotel_tab {
          margin-top: 0.0909rem;
          // display: flex;

          .price {
            float: right;
            display: inline-block;
          }

          .tab {
            float: left;
            display: inline-block;

            margin-right: 0.0909rem;
            border: 1px solid #83e4df;
            font-size: 14px;
            border-radius: 5px;
            padding: 0 2px;
            // text-align: left;
            color: #83e4df;
          }
        }
        .shop_tab {
          margin-top: 0.9091rem;
          // display: flex;

          .price {
            float: right;
            display: inline-block;
          }

          .tab {
            float: left;
            display: inline-block;

            margin-right: 0.0909rem;
            border: 1px solid #83e4df;
            font-size: 14px;
            border-radius: 5px;
            padding: 0 2px;
            // text-align: left;
            color: #83e4df;
          }
        }
      }
    }
  }
  .nomore {
    width: 100%;
    height: 50px;
    font-size: 12px;
    line-height: 50px;
  }
  /deep/.del_collect {
    width: 100%;
    height: 0.9091rem;
    font-size: 12px;
    bottom: 0%;
    position: fixed;
    background-color: white;
    .van-checkbox {
      display: inline-block;
      float: left;
      margin-top: 0.2727rem;
      margin-left: 0.1455rem;
      div {
        display: inline-block;
      }
      .van-checkbox__label {
        font-size: 12px;
      }
    }
    .van-button {
      float: right;
      width: 135px;
      height: 35px;
      border-radius: 18px;
      background: #2ee8c6;
      margin-right: 0.2909rem;
      top: 20%;
    }
  }

  /deep/.van-button__text {
    color: white;
    font-size: 12px !important;
  }
  /deep/.van-tabs__line {
    background-color: #36e0c9;
  }
  /deep/.van-tab--active {
    color: #36e0c9;
  }
  /deep/.van-tab__pane {
    background-color: #f8f8f8;
    height: 800px;
  }
}
/deep/ .van-button--plain.van-button--info {
  border: 0px;
  border-style: none !important;
  color: #ffffff00;
}
</style>