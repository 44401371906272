<template>
  <div id='HotelMyOrder'
       :style="{height:innerHeight}">
    <!-- <NavBar NavBarTitle='我的订单'
            @onClickLeft='onClickLeft'></NavBar> -->
    <van-tabs v-model="active"
              @change='changeStatus'>
      <van-tab v-for="item in orderStatus"
               :key="item.status"
               :title='item.title'>
        <div style="padding-bottom:80px;background-color: #f4f9f8;">
          <van-empty v-if="orderData.length==0"
                     description="还没有订单哦" />
          <div class="content"
               v-for="item in orderData"
               :key="item.id">
            <div class="shopCard">
              <div class="title">
                {{item.real_name}}
                <span>
                  {{item.status == 0 ?"待支付":
                  item.status == -1 ?"已取消":"已支付"}}
                </span>
              </div>
              <van-card v-for="goods in item.shop_order_detail[0].shop_order_goods"
                        :key='goods.id'
                        :price="goods.total_price"
                        :desc="goods.goods_sku_name"
                        :title="goods.goods_name "
                        :thumb="goods.goods_image"
                        @click="getGoodsID(item)">
                <template #footer
                          class="numAdd">
                  <span class="num">x{{goods.quantity}}</span>
                </template>
              </van-card>
            </div>
            <div class="operation"
                 v-if="item.status==0||item.status==6">
              <div class="btn"
                   v-if="item.status==0">
                <van-button plain
                            round
                            @click="cancelOrder(item)">取消订单</van-button>
                <van-button plain
                            color="#FE6529"
                            round
                            @click="payOrder(item)">付款</van-button>
              </div>
              <div class="btn"
                   v-if="item.status==6">
                <van-button plain
                            round
                            @click="getGoodsID(item)">查看订单</van-button>
              </div>
            </div>
          </div>
        </div>

      </van-tab>
    </van-tabs>
    <div class="bottom">
      <van-tabbar v-model="bottomActive"
                  @change="switchBottomTab"
                  active-color='#58e3dd'>
        <van-tabbar-item>
          <img v-if="bottomActive == 0"
               src="@/assets/shopImg/shopping_active.png"
               alt="">
          <img v-else
               src="@/assets/shopImg/shopping_icon.png"
               alt="">
          <div>商城</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 1"
               src="@/assets/shopImg/classification_active.png"
               alt="">
          <img v-else
               src="@/assets/shopImg/classification_icon.png"
               alt="">
          <div>分类</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 2"
               src="@/assets/shopImg/ShoppingCar _active.png"
               alt="">
          <img v-else
               src="@/assets/shopImg/ShoppingCar.png"
               alt="">
          <div>购物车</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 3"
               src="@/assets/shopImg/order_active.png"
               alt="">
          <img v-else
               src="@/assets/shopImg/order_icon.png"
               alt="">
          <div>订单</div>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { getGoodsOrderList, payGoodsOrder, cancelGoodsOrder, } from '../../../api/shop.js'
import { PAYJSSDK } from '../../../api/JSSDk.js';
import { Toast } from 'vant';
import { aesKey } from "../../../commom/aesKey"
// import NavBar from '@/components/NavBar'
export default {
  components: {
    // NavBar,
  },
  data () {
    return {
      innerHeight: 0,
      addressInfo: null,
      aesKey: aesKey(),//接口通过密文来获取数据
      routerFrom: '',//用于判断那个路由过来的
      page: 1,//页数
      bottomActive: 3,//底部标签
      active: 0,//顶部标签
      order_status: '',//订单状态
      orderStatus: [
        {
          title: '全部',
        },
        {
          title: '待付款',
          status: 0
        },
        {
          title: '已支付',
          status: 1
        },
        {
          title: '已取消',
          status: -1
        },
      ],
      orderData: [],//订单数据
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    this.innerHeight = window.innerHeight + 'px'
    this.refreshDesktop()
  },
  beforeRouteEnter (to, from, next) { // 这个路由钩子函数比生命周期beforeCreate函数先执行，所以this实例还没有创建出来
    // this.routerFrom = from.name
    // console.log(this.routerFrom)
    next((vm) => {
      vm.routerFrom = from.name
    })
  },
  methods: {
    onClickLeft () {
      // if (this.routerFrom == 'personalHome') {
      //   this.$router.push({ path: '/allPerInfo/personalHome' })
      // } else {
      //   this.$router.push({ path: '/allShop/shopHome' })
      // }
      this.$router.go(-1)
    },
    //获取商品订单列表
    getGoodsOrderList (data) {
      console.log(data);
      getGoodsOrderList(data).then(res => {
        // console.log(res)
        this.orderData = res.data.data.list
      })
    },
    //刷新桌面
    refreshDesktop () {
      let data = {
        page: this.page,
        status: this.order_status
      }
      // this.aesKey = aesKey(data)
      this.getGoodsOrderList(data)
    },

    // 获取滚动条的滚动距离
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      // // //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

      // // //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight == scrollHeight) {
        this.page++
        let data = {
          page: this.page,
          order_status: this.order_status
        }
        // this.aesKey = aesKey(data)
        // console.log(data)
        getGoodsOrderList(data).then(res => {
          // console.log(res)
          this.orderData = this.orderData.concat(res.data.data.list)
        })
      }

    },
    //底部按钮
    switchBottomTab () {
      // console.log(this.bottomActive)
      if (this.bottomActive == 0) {
        this.$router.push({ path: '/allShop/shopHome' })
      } else if (this.bottomActive == 1) {
        this.$router.push({ path: '/allShop/shopSort' })
      } else if (this.bottomActive == 2) {
        this.$router.push({ path: '/allShop/shopCart' })
      } else if (this.bottomActive == 3) {
        this.$router.push({ path: '/allShop/shopMyOrder' })
      }
    },
    //切换状态
    changeStatus (name) {
      console.log("点击切换了");
      this.page = 1
      if (name == 1) {
        this.order_status = 0
      } else if (name == 2) {
        this.order_status = 6
      } else if (name == 3) {
        this.order_status = -1
      } else {
        this.order_status = ''
      }
      let data = {
        status: this.order_status
      }
      // this.aesKey = aesKey(data)
      this.getGoodsOrderList(data)
    },
    //获取点击的订单shopOrderdetail 信息
    getGoodsID (item) {
      console.log(item)
      this.$router.push(
        {
          path: '/allShop/shopOrderDetail',
          query: { goodsInfo: [item], addressInfo: this.addressInfo }
        }
      )
    },
    //订单选择服务
    goShopService (item) {
      // console.log(item)
      this.$router.push(
        {
          path: '/allShop/shopService',
          query: { goodsInfo: [item] }
        }
      )
    },
    orderStatus_click (data) {
      console.log("点击了", data);
    },
    //取消订单
    async cancelOrder (data) {
      const DATA = {
        order_no: data.no
      }
      this.aesKey = aesKey(DATA)
      let res = await cancelGoodsOrder(this.aesKey)
      // console.log(res)
      if (res.data.code == 200) {
        Toast.success(res.data.message);
        this.refreshDesktop()
      } else {
        Toast.fail(res.data.message);
      }
      // console.log(data)
    },
    //微信浏览器的sdk
    onBridgeReady (data) {
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId": data.data.data.appId,     //公众号ID，由商户传入     
        "timeStamp": data.data.data.timeStamp,         //时间戳，自1970年以来的秒数     
        "nonceStr": data.data.data.nonceStr, //随机串     
        "package": data.data.data.package,
        "signType": data.data.data.signType,         //微信签名方式：     
        "paySign": data.data.data.paySign //微信签名 
      },
        function (res) {
          // get_brand_wcpay_request:ok	支付成功
          // get_brand_wcpay_request:cancel	支付过程中用户取消
          // get_brand_wcpay_request:fail	支付失败
          if (res.err_msg == "get_brand_wcpay_request:cancel" || res.err_msg == "get_brand_wcpay_request:fail") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.res3 = res
            Toast.fail('支付失败');
            // this.$router.push({ path: '/allHotel/HotelOrderDetail' })
          } else {
            // //用户支付成功后，代理商账户扣钱
            // let data = {
            //   order_no: this.order_no
            // }
            // this.aesKey = aesKey(data)
            // this.payHotelOrder(this.aesKey)
            Toast.success('支付成功');
            window.location.href = "https://pt.guansj.com/allShop/shopMyOrder"
          }
        });
    },
    //支付订单
    async payOrder (item) {
      console.log(item);
      this.order_no = item.order_no
      let data2 = {
        pay_for: 3,//1:酒店 3:商城
        pay_provider_id: 1,//收银宝
        pay_method: 'W02',//微信js
        product_order_no: item.no,
      }
      let res2 = await payGoodsOrder(data2)
      if (res2.data.code == 200) {
        let data3 = {
          prepay_id: res2.data.data.payinfo.prepay_id
        }
        this.aesKey = aesKey(data3)
        let res3 = await PAYJSSDK(this.aesKey)

        this.res3 = res3

        // console.log(res2, "====res2");
        Toast.fail('支付中');
        // this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
        this.onBridgeReady(this.res3)

        // sessionStorage.setItem('wxPayData', this.res2.data.data.payinfo)
        // this.$router.push({ path: '/wxPay' })

        // sessionStorage.setItem("order_no", item.order_no);
        // window.location = res2.data.data.payinfo
      } else {
        Toast.fail(res2.data.message);
      }
    }
  }
}
</script>

<style lang="less" scoped>
#HotelMyOrder {
  background-color: #f4f9f8;
  /deep/.van-tabs__nav {
    padding-right: 0.25rem;
  }
  /deep/.van-tab {
    font-size: 0.25rem;
  }
  /deep/.van-tab--active {
    color: #24c5af;
  }
  /deep/.van-tabs__line {
    background-color: #36e0c9;
    height: 2px;
  }
  .content {
    text-align: left;
    background-color: #f4f9f8;
    // padding-bottom: 100px;
    margin-top: 0.2rem;
    .shopCard {
      border-radius: 10px;
      background-color: white;
      font-size: 0.35rem;
      padding-bottom: 0.1rem;
      .title {
        font-size: 0.27rem;
        padding: 0.2rem 0.3rem;
        span {
          float: right;
          color: #fe6529;
          font-size: 0.22rem;
        }
      }
      .van-card {
        background-color: white;
        border-radius: 10px;
        font-size: 0.2rem;
        padding: 0 0.3rem;
        margin-top: 5px;
        position: relative;
        .van-card__title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 4.5rem;
          font-size: 0.3rem;
          height: auto;
          display: inline-block;
          line-height: inherit;
        }
        .van-card__thumb {
          width: 1.5rem;
          height: 1.5rem;
        }
        .van-card__desc {
          display: inline;
          padding: 0.05rem 0.1rem;
          background-color: #f5f5f5;
          color: #999999;
        }
        .van-card__price {
          font-size: 0.3rem;
          font-weight: bold;
          color: #fe6529;
          .van-card__price-currency {
            font-size: 0.2rem;
          }
          .van-card__price-integer {
            font-size: 0.3rem;
          }
        }
        .van-card__footer {
          font-size: 0.3rem;
          position: absolute;
          right: 5%;
          bottom: 0%;
          color: #fe6529;
        }
      }
    }
    .operation {
      font-size: 0.6rem;
      height: 1rem;
      background-color: white;
      .btn {
        float: right;
        margin-right: 0.1rem;
        .van-button {
          font-size: 0.25rem;
          width: 2rem;
          height: 0.6rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
  .bottom {
    text-align: center;
    img {
      width: 60%;
    }
  }
}
</style>