<template>
  <div class="top">
    <van-nav-bar :title="NavBarTitle"
                 left-text=""
                 right-text="按钮"
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight">
      <template #right>
        <van-icon name="ellipsis" />
      </template>
    </van-nav-bar>
  </div>
</template>
<script>
export default {
  props: {
    NavBarTitle: String
  },
  methods: {
    onClickLeft () {
      // console.log('返回');
      this.$emit('onClickLeft')
    },
    onClickRight () {
      // console.log('right')
    },
  },
}
</script>
<style lang="less" scoped>
.top {
  // position: fixed;
  // z-index: 200;
  // width: 100%;
  // top: 0%;
  /deep/.van-nav-bar__content {
    .van-nav-bar__title {
      font-size: 0.28rem;
      font-weight: bold;
    }
  }
  /deep/.van-ellipsis {
    overflow: visible;
  }
  /deep/.van-nav-bar__left {
    i {
      font-size: 0.35rem;
      color: black;
    }
  }
  /deep/.van-nav-bar__right {
    i {
      font-size: 0.45rem;
      color: black;
      font-weight: bold;
    }
  }
}
</style>