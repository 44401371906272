<template>
  <div class="container">
    <div :class="money_show">
      <div class="money_left"
           @click="goPay"><span class="price"><span class="price_text">余额 <i class="iconfont">&#xe709;</i></span><span class="price_num"
                v-if="user_info">{{user_info.wallet}}</span></span></div>
      <div class="money_right"><span class="price"><span class="price_text">赠送金</span><span class="price_num"
                v-if="user_info">{{user_info.integral}}</span></span></div>
    </div>
    <div class="content">
      <div class="info">
        <img :src="this.wallet_esprots == false?bank:bank_esports"
             alt="">
        <span>银行卡</span>
        <span style="display:flex;color:#999999; text-align: center; justify-content: center;">0张</span>
      </div>
      <div class="info">
        <img :src="this.wallet_esprots==false?jifen:jifen_esprots"
             alt="">
        <span>积分</span>
        <span style="display:flex;color:#999999; text-align: center; justify-content: center;">0</span>
      </div>
      <div class="info">
        <img :src="this.wallet_esprots==false?money:money_esprots"
             alt="">
        <span>抵用金</span>
        <span style="display:flex;color:#999999; text-align: center; justify-content: center;">0</span>
      </div>
      <div class="info">
        <img :src="this.wallet_esprots==false?coupon:coupon_esprots"
             alt="">
        <span>优惠卷</span>
        <span style="display:flex;color:#999999; text-align: center; justify-content: center;">0张</span>
      </div>
    </div>
    <!-- <van-swipe class="my-swipe"
               :autoplay="3000"
               indicator-color="white">
      <van-swipe-item><img style="width:95%"
             src="../../../assets/personalimg/banner.png"
             alt=""></van-swipe-item>
    </van-swipe> -->
  </div>
</template>

<script>
import { getUser_info } from '@/api/user.js'
export default {
  data () {
    return {
      user_info: null,
      wallet_esprots: true,
      bank: require('../../../assets/personalimg/bank_2.png'),
      bank_esports: require('../../../assets/Esports/bank.png'),
      jifen_esprots: require('../../../assets/Esports/integral.png'),
      jifen: require('../../../assets/personalimg/jifen.png'),
      money: require('../../../assets/personalimg/diyongjin.png'),
      money_esprots: require('../../../assets/Esports/money_esprots.png'),
      coupon: require('../../../assets/personalimg/xianjinquan.png'),
      coupon_esprots: require('../../../assets/Esports/coupon_esprots.png')
    }
  },
  computed: {
    money_show () {
      return {
        money: !this.wallet_esprots,
        money_two: this.wallet_esprots
      }
    },

  },
  created () {
    this.getUser()
  },
  methods: {
    goPay () {
      this.$router.push({ path: '/allPerInfo/balance' })
    },
    getUser () {
      getUser_info().then(res => {
        console.log(res, "获取用户信息");
        this.user_info = res.data.data
      })
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 30px;

  .money {
    width: 94%;
    border-radius: 20px;
    height: 160px;
    background: linear-gradient(150deg, #ff6c0a 0%, #ffc454 100%);
    margin-left: 3%;
    display: flex;
    flex-direction: row-reverse;
    .price_text {
      font-size: 16px;
      margin-top: 10%;
      text-align: justify;
      margin-left: 27%;
      .right {
        width: 0.28rem;
      }
    }
    .money_left {
      width: 50%;
      span {
        display: block;
      }
    }
    .money_right {
      width: 50%;
      span {
        display: block;
      }
    }
    .price {
      font-size: 30px;
      color: white;
      .price_num {
        margin-top: 5px;
        text-align: justify;
        margin-left: 1rem;
      }
    }
  }
  .money_two {
    width: 94%;
    border-radius: 20px;
    height: 160px;
    background-image: url("../../../assets/Esports/bG_Esprots.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 3%;
    display: flex;
    flex-direction: row-reverse;
    .price_text {
      font-size: 16px;
      margin-top: 10%;
      text-align: justify;
      margin-left: 27%;
      .right {
        width: 0.28rem;
      }
    }
    .money_left {
      width: 50%;
      span {
        display: block;
      }
    }
    .money_right {
      width: 50%;
      span {
        display: block;
      }
    }
    .price {
      font-size: 30px;
      color: white;
      .price_num {
        margin-top: 5px;
        text-align: justify;
        margin-left: 1rem;
      }
    }
  }
  .content {
    width: 95%;
    justify-content: center;
    display: flex;
    margin-left: 3%;
    img {
      width: 0.7rem;
      display: flex;
      vertical-align: middle;
      margin-left: 30%;
    }
    .info {
      margin-top: 20px;
      line-height: 20px;
      margin-right: 1%;
      font-size: 15px;
      width: 25%;
      justify-content: center;
    }
  }
  .my-swipe .van-swipe-item {
    margin-top: 20px;
    color: #fff;
    font-size: 20px;
    width: 90%;

    // line-height: 150px;
    text-align: center;
    // background-color: #39a9ed;
    img {
      width: 90%;
    }
  }
  .iconfont {
    font-size: 24px;
    position: absolute;
  }
  .my-swipe .van-swipe {
    width: 100%;
    // margin-left: 5%;
  }
}
.waterFall {
  font-size: 0.25rem;
  text-align: left;
  margin-top: 0.3704rem;
  .headerW {
    font-size: 0.26rem;
    margin-top: -0.1852rem;
  }
  .van-search .bottomW {
    position: relative;
    padding-top: 0.15rem;
    .price {
      font-size: 0.35rem;
      font-weight: bold;
      color: #ff5c4c;
    }
    .type {
      font-size: 0.2rem;
      font-weight: bold;
      position: absolute;
      right: 5%;
      top: 36%;
    }
  }
}
</style>