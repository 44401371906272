<template>
  <div id="shopSearch">
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="header">
      <div id="search">
        <van-search v-model="searchValue"
                    shape="round"
                    @search='getSearch(searchValue)'
                    placeholder="搜索酒店/民宿" />
        <div class="sear_btn"
             @click="getSearch(searchValue)">搜索</div>
        <div class="historySear">
          <div class="title">
            历史搜索
            <span>
              <van-icon name="delete-o"
                        @click="deleteHis" />
            </span>
          </div>
          <div class="hisContent">
            <div v-for="item in searchHome"
                 :key="item.id"
                 @click="getContent(item)">{{item}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavBar from '@/components/NavBar'
import { Toast } from 'vant';
export default {
  components: {
    // NavBar,
  },
  data () {
    return {
      NavBarTitle: '',
      searchValue: '',
      searchHome: [],
      isShowTip: false, //搜索结果 
      keyword: "",
    }
  },
  mounted () {
    //历史搜索
    if (localStorage.getItem('searchHome') == null) {
      this.searchHome = []
    } else {
      this.searchHome = JSON.parse(localStorage.getItem('searchHome'))
    }

  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    //   getSearch () {
    //     if (this.keyword == "") {
    //       Toast.fail('搜索不能为空')
    //       return
    //     }
    //   }
    // },
    getSearch (searchValue) {
      if (searchValue == "") {
        Toast.fail('搜索不能为空')
        return
      }
      this.$router.push({ path: '/allShop/shopList', query: { keyword: searchValue } })
      // this.searchContent.push(searchValue)
      // //去重
      // this.searchContent = this.searchContent.filter((item, index) => {
      //   return this.searchContent.indexOf(item, 0) === index
      // })
      sessionStorage.setItem("searchValue", searchValue)
      // localStorage.setItem("searchContent", JSON.stringify(this.searchContent))
      // this.$router.push({ path: '/allShop/shopList' })
      // this.searchValue = ''
    },
    //搜索按钮
    getContent (data) {
      this.searchValue = data
    },
    //删除历史
    deleteHis () {
      // console.log(123)
      this.searchHome = []
      localStorage.removeItem('searchHome')
    }
  }
}
</script>

<style lang="less" scoped>
#shopSearch {
  .header {
    position: relative;
    #search {
      /deep/.van-search {
        padding-top: 0.2rem;
        z-index: 10;
        background-color: #fafafa;
        padding-right: 1rem;
        .van-search__content {
          background-color: white;
        }
        .van-field__body {
          padding-left: 0.2rem;
          font-size: 0.24rem;
        }
      }
      width: 100%;
      height: 20px;
      .sear_btn {
        position: absolute;
        font-size: 0.2rem;
        border-radius: 20px;
        padding: 0.1rem 0.2rem;
        background-color: #36e0c9;
        color: white;
        display: inline-block;
        right: 1%;
        top: 80%;
      }
      .historySear {
        font-size: 0.3rem;
        text-align: left;
        padding: 0 0.3rem;
        margin-top: 0.3rem;
        .title {
          font-weight: bold;
          span {
            float: right;
            font-size: 0.4rem;
            color: gray;
          }
        }
        .hisContent {
          margin: 0.2rem 0;
          div {
            font-size: 0.26rem;
            display: inline-block;
            width: 1rem;
            text-align: center;
            padding: 5px 0;
            border-radius: 20px;
            color: gray;
            background-color: #f8f8f8;
            margin-right: 0.1rem;
            width: 1rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>