<template>
  <div id="shopAfterSale">
    <div class="container">
      <div class="goods_info">
        <span class="tab">退款商品</span>
        <van-card num="2"
                  v-for="item in goodsData[0].shop_order_detail[0].shop_order_goods "
                  :key="item.id"
                  :price="item.total_price"
                  :desc="item.goods_sku_name"
                  :title="item.goods_name "
                  :thumb="item.goods_image" />
      </div>
      <div class="AfterSale">
        <span class="tab">退款商品</span>
        <van-cell-group inset>
          <van-cell value=""
                    label="没收到货,或与卖家协商同意不退款只换货"
                    is-link>
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="custom-title">我要退款</span>
            </template>
          </van-cell>
          <van-cell value=""
                    label="已收到货,需退还收到的货物"
                    is-link>
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="custom-title">我要退货退款</span>
            </template>
          </van-cell>
          <van-cell value=""
                    label="商品存在质量问题,联系卖家协商换货"
                    is-link>
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="custom-title">我要换货</span>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
  </div>
</template>

<script>
// import   NavBar from '@/components NavBar'
export default {
  components: {
    //  NavBar
  },
  data () {
    return {
      goodsData: null
    }
  },
  created () {
    this.goodsData = this.$route.query.goodsInfo
    sessionStorage.setItem("goodsData", JSON.stringify(this.goodsData))
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 5rem;
  padding-top: 0.1rem;
  background-color: #fafafa;
  .van-card {
    background-color: white;
    border-radius: 20px;
    /deep/ .van-card__desc {
      display: inline;
      padding: 0.05rem 0.1rem;
      background-color: #f5f5f5;
      color: #999999;
      border-radius: 5px;
    }
  }
  .goods_info {
    border-radius: 20px;
    text-align: left;
    background-color: white;
    .tab {
      font-size: 18px;
      font-weight: 600;
      padding-left: 0.3rem;
    }
  }
  .AfterSale {
    .tab {
      font-size: 18px;
      font-weight: 600;
      background-color: white;
      display: block;
      text-align: left;
      padding: 0.2rem 0rem 0.2rem 0.3rem;
    }
    margin-top: 0.3704rem;
    .van-cell-group--inset {
      margin: 0;
    }
    .van-cell__title {
      text-align: left;
      // padding-left: 0.3rem;
      .van-cell__label {
        width: 130%;
      }
    }
  }
}
</style>