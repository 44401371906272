<template>
  <div class="container">
    <div class="search_hot">
      <form action="/">
        <van-search v-model="value"
                    show-action
                    :left-icon="search_icon"
                    placeholder="搜索酒店/景点/城市"
                    @search="onSearch"
                    @cancel="onCancel" />
      </form>
      <div class="hot">热门推荐</div>
      <div class="history">三亚</div>
    </div>
    <div class="address">
      <van-sidebar v-model="activeKey">
        <van-sidebar-item title="热门推荐" />
        <van-sidebar-item title="海南/福建" />
        <van-sidebar-item title="云南/贵州" />
        <van-sidebar-item title="广东/广西" />
        <van-sidebar-item title="川渝藏" />
        <van-sidebar-item title="江浙泸" />
        <van-sidebar-item title="京津翼" />
        <van-sidebar-item title="湖南/湖北" />
        <van-sidebar-item title="大西北" />
        <van-sidebar-item title="东北" />
        <van-sidebar-item title="中国港澳台" />
      </van-sidebar>
    </div>
    <div class="adress_info">
      <div class="top">热门推荐</div>
      <div class="hot">
        <div class="photo">
          <img src="../../../assets/custom/上海.png"
               alt="">
          <span>上海</span>
        </div>
        <div class="photo">
          <img src="../../../assets/custom/厦门.png"
               alt="">
          <span>厦门</span>
        </div>
        <div class="photo">
          <img src="../../../assets/custom/dd104ada43a637e866761185d7876de.png"
               alt="">
          <span>鼓浪屿</span>
        </div>
      </div>
      <div class="top"
           style="display: flex;">海南</div>
      <div class="place">
        <div>三亚</div>
        <div>海口</div>
        <div>万宁</div>
        <div>三亚</div>
        <div>海口</div>
        <div>万宁</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data () {
    return {
      value: '',
      search_icon: require('../../../assets/custom/搜索icon.png'),
      activeKey: 0,
    };
  },
  methods: {
    onSearch (val) {
      Toast(val);
    },
    onCancel () {
      Toast('取消');
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 1000px;
  // background-color: aquamarine;
  .search_hot {
    height: 130px;
    background: #ffffff;
    box-shadow: 0px 20px 25px -24px rgba(180, 178, 178, 0.5);
    margin-bottom: 20px;
    /deep/ .van-search__content {
      border-radius: 20px;
    }
    .hot {
      font-size: 14px;
      color: #a3a3a3;
      display: flex;
      margin-left: 20px;
    }
    .history {
      margin-left: 20px;
      margin-top: 10px;
      font-size: 16px;
      width: 52px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background: #f8f8f8;
      border-radius: 4px;
    }
  }
  .address {
    background-color: white;
    width: 100px;
    // padding-top: 20px;
    .van-sidebar {
      width: 100px;
      float: left;
    }
  }
  /deep/ .van-sidebar-item--select::before {
    background-color: white;
  }
}
.adress_info {
  padding-left: 20px;
  overflow: hidden;
  .top {
    font-size: 16px;
    float: left;
    display: block;
    margin-bottom: 5px;
  }
  .hot {
    float: left;
    .photo {
      display: inline-block;
      width: 30%;
      margin-right: 3%;
      span {
        //flex布局文字居中
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        font-size: 18px;
      }
      img {
        width: 100%;
      }
    }
  }
  .place {
    width: 100%;
    overflow: hidden;

    .top {
      font-size: 16px;
    }
    div {
      width: 30%;
      display: inline-block;
      padding: 5px 0 5px 0;
      border-radius: 5px;
      float: left;
      background-color: #f8f8f8;
      margin-right: 3%;
      font-size: 18px;
      margin-top: 10px;
    }
  }
}
/deep/ .van-sidebar-item--select {
  color: #7fffd4;
}
/deep/.van-field__left-icon {
  margin-top: 5px;
}
/deep/.van-search__action {
  padding: 0px 15px;
}
</style>