<template>
  <div class="container">
    <div class="content">
      <div class="tab">出行人数 <span class="tab_samll">·必填</span></div>
      <div class="people_num">
        <span>2成人</span>
        <span>2儿童</span>
        <span>2老人</span>
      </div>
      <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 5px 0 0' }" />
    </div>
    <!--  -->
    <div class="content">
      <div class="tab">人均预算<span class="tab_samll">·必填</span></div>
      <div class="people_num">
        <span>价格</span>
      </div>
      <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 5px 0 0' }" />
    </div>
    <!--  -->
    <div class="content">
      <div class="tab">游玩节奏<span class="tab_samll">·可多选</span></div>
      <div class="play">
        <van-button class="btn">懒人轻松玩法:游览时间不超过4小时/天</van-button>
        <van-button class="btn">常规玩法:游览时间4~6小时</van-button>
        <van-button class="btn">行程紧凑玩法：游览时间超6小时/天</van-button>
      </div>

    </div>
    <!--  -->
    <div class="content">
      <div class="
         tab">酒店类型<span class="tab_samll">·必填</span></div>
      <div class="play">
        <van-button class="btn"
                    @click="test(index)">经济型</van-button>
        <van-button class="btn--active">高档型</van-button>
        <van-button class="btn">豪华型</van-button>
        <van-button class="btn">民宿</van-button>
      </div>

    </div>
    <!--  -->
    <div class="content">
      <div class="
         tab">指定景点<span class="tab_samll">·可多选</span></div>
      <div class="play">
        <van-button class="btn">亚龙湾</van-button>
        <van-button class="btn--active">蜈支洲岛</van-button>
        <van-button class="btn">三亚湾</van-button>
      </div>

    </div>
    <!--  -->
    <div class="content">
      <div class="
         tab">所需资源<span class="tab_samll">·可多选</span></div>
      <div class="play">
        <van-button class="btn">机票</van-button>
        <van-button class="btn--active">火车</van-button>
        <van-button class="btn">酒店</van-button>
        <van-button class="btn">门票</van-button>
        <van-button class="btn">导游</van-button>
        <van-button class="btn">包车</van-button>
        <van-button class="btn">地铁</van-button>
        <van-button class="btn">接送机</van-button>
      </div>

    </div>
    <!--  -->
    <div class="content">
      <div class="
         tab">所需资源<span class="tab_samll">·0点-6点出发或抵达的航班为红眼航班</span></div>
      <div class="play">
        <van-button class="btn">拒绝红眼航班</van-button>
        <van-button class="btn--active">拒绝转机</van-button>
      </div>

    </div>
    <!--  -->
    <div class="content"
         v-for="item in screen_1"
         :key="item.id">
      <div class=" tab">{{item.title}}<span class="tab_samll">·可多选</span></div>
      <div class="play">
        <van-button class="btn"
                    v-for="item2 in item.data"
                    :key="item2.content">{{item2.content}}</van-button>

      </div>

    </div>
    <!--  -->
    <div class="content">
      <div class="
         tab">其他要求</div>
      <div class="play">
        <van-field v-model="message"
                   rows="2"
                   autosize
                   type="textarea"
                   maxlength="100"
                   placeholder="  选填,输入更多个性化需求"
                   show-word-limit />
      </div>

    </div>
    <van-button class="submit">获得方案</van-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      message: '',
      screen_1: [{
        title: '个人偏好',
        data: [
          {
            content: '自驾',
            isclick: false,
          },
          {
            content: '自驾',
            isclick: false,
          },
          {
            content: '美食',
            isclick: false,
          },
          {
            content: '购物',
            isclick: false,
          },
          {
            content: '烧烤',
            isclick: false,
          },
        ]
      }]
    }
  },
  methods: {
    test (index) {
      console.log("点击了", index);
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  background-color: white;
  height: 900px;
  .content {
    margin-left: 10px;
    .play {
      overflow: hidden;
      width: 100%;
    }
    .btn {
      background-color: #f7f7f7;
      font-size: 16px;
      padding: 10px 15px;
      border-radius: 20px;
      float: left;
      display: block;
      margin-top: 10px;
      margin-right: 10px;
    }
    .btn--active {
      background: linear-gradient(270deg, #00ecaf 0%, #5ee3de 100%);
      color: white;
      font-size: 16px;
      padding: 10px 15px;
      border-radius: 20px;
      float: left;
      display: block;
      margin-top: 10px;
      margin-right: 10px;
      box-shadow: 0px 2px 7px 0px rgba(28, 233, 189, 0.59);
    }
    .tab {
      padding-top: 20px;
      font-size: 16px;
      display: flex;
      .tab_samll {
        font-size: 12px;
        color: #44e5d0;
        line-height: 23px;
        transform: scale(0.7);
      }
    }
    .people_num {
      font-size: 22px;
      display: flex;
      margin-top: 10px;
      span {
        margin-right: 5px;
      }
    }
  }
  .submit {
    width: 90%;
    border-radius: 20px;
    background: linear-gradient(270deg, #00ecaf 0%, #5ee3de 100%);
    box-shadow: 0px 2px 7px 0px rgba(28, 233, 189, 0.59);
    color: white;
  }
}
/deep/ .van-field__control {
  background-color: #f2fcfb;
  border-radius: 5px;
}
</style>