<template>
  <div class="container">

    <div class="content_top"
         id="search">

      <img class="banner_hot"
           src="../../../assets/shopImg/banner_hot.png"
           alt="">
      <img class="everyday"
           src="../../../assets/shopImg/everyday.png"
           alt="">
    </div>
    <div class="shop_date">
      <div class="shop_date_left"><img src="../../../assets/shopImg/shop_date_left.png"
             alt=""></div>
      <div class="shop_date_right"><img src="../../../assets/shopImg/shop_date_right.png"
             alt=""></div>
    </div>
    <div class="gilt">
      <div class="top">
        <span class="top_left"
              style="font-size:22px; font-weight: 600;">优品闪购</span>
        <span class="top_right"
              style="font-size:14px;color:#a8b2b0;line-height: 220%; ">正品大牌</span>
        <span class="top_right"
              style="font-size:14px;color:#a8b2b0; line-height: 220%; margin-right: 10px; ">包邮包税</span>
      </div>
      <div class="shop">
        <div class="shop_one">
          <div><img src="../../../assets/shopImg/bao.png"
                 alt=""></div>
          <div class="price"> <img src="../../../assets/shopImg/price (1).png"
                 alt=""></div>
          <div class="depreciate">直降300元</div>
          <div class="price_now"
               style="">¥2390</div>
        </div>
        <div class="shop_two">
          <div><img src="../../../assets/shopImg/shuangfushui.png"
                 alt=""></div>
          <div class="price"> <img src="../../../assets/shopImg/price (1).png"
                 alt=""></div>
          <div class="depreciate">直降150元</div>
          <div class="price_now">¥559</div>
        </div>
        <div class="shop_three">
          <div><img src="../../../assets/shopImg/kouhong.png"
                 alt=""></div>
          <div class="price"> <img src="../../../assets/shopImg/price (1).png"
                 alt=""></div>
          <div class="depreciate">直降160元</div>
          <div class="price_now">¥256</div>
        </div>
        <div class="shop_four">
          <div><img src="../../../assets/shopImg/t-shit.png"
                 alt=""></div>
          <div class="price"> <img src="../../../assets/shopImg/price (1).png"
                 alt=""></div>
          <div class="depreciate">直降120元</div>
          <div class="price_now">¥453</div>
        </div>
      </div>
    </div>
    <div class="ShopList">
      <van-tabs v-model="activeName"
                @click="switchTab"
                id="boxFixed">
        <van-tab title="热度新品"
                 name="first">
          <WaterFall :imgsArr="imgsArr"
                     @getwatId="getwatId"></WaterFall>
        </van-tab>
        <van-tab title="趋势新品"
                 name="second">
          <WaterFall :imgsArr="imgsArr"
                     @getwatId="getwatId"></WaterFall>
        </van-tab>
        <van-tab title="美食"
                 name="third"> </van-tab>
        <van-tab title="户外"
                 name="fourth"> </van-tab>
        <van-tab title="箱包"
                 name="fifth"> </van-tab>
        <van-tab title="男装"
                 name="fifth"> </van-tab>
      </van-tabs>
      <van-tabs v-model="activeName"
                @click="switchTab"
                id="boxFixed"
                v-if="isFixed"
                :class="{ is_fixed: isFixed }">
        <van-tab title="猜你喜欢"
                 name="first"> </van-tab>
        <van-tab title="酒店客栈"
                 name="second"> </van-tab>
        <van-tab title="国内游"
                 name="third"> </van-tab>
        <van-tab title="景点门票"
                 name="fourth"> </van-tab>
        <van-tab title="周边游"
                 name="fifth"> </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { getGoodsList } from '@/api/shop.js'

import { aesKey } from '@/commom/aesKey'
import WaterFall from '@/components/WaterFall'

export default {
  components: {
    // NavBar,
    WaterFall
  },
  data () {
    return {
      activeName: 'first',
      isFixed: false, //标签页是否固定
      aesKey: aesKey(), //接口通过密文来获取数据
      imgsArr: [
        {
          id: 1,
          img: require('../../../assets/shopImg/wallfull_banner.png')
        }
      ],
      pageCount: '', //可翻页的次数
      page: 1, //页数
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    if (sessionStorage.getItem('shopRefresh') == 1) {
      sessionStorage.removeItem('shopRefresh')
      this.$router.go(0)
    }
    this.getGoodsList()
  },
  methods: {
    getwatId (data) {
      this.$router.push({ path: '/allShop/shopDetail' })
      sessionStorage.setItem('goodsId', data.id)
    },
    switchTab () {
      console.log(this.activeName)
    },
    handleScroll () {
      // console.log("执行滚动条方法");
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      // // // //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

      // // // //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      // console.log(scrollHeight);
      if (document.querySelector("#boxFixed") == null) {
        return;
      }
      let offsetTop = document.querySelector("#boxFixed").offsetTop; // 要滚动到顶部吸附的元素的偏移量
      let searchTop = document.querySelector("#search").offsetTop;
      // console.log("scrollTop", scrollTop);
      // console.log("windowHeight", windowHeight);
      // console.log("scrollHeight", scrollHeight);
      // console.log("offsetTop", offsetTop);
      // console.log("searchTop", searchTop);
      this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
      this.is_searFix = scrollTop > searchTop ? true : false;
      // console.log(scrollTop + windowHeight, '=======scrollTop + windowHeight=====');
      if (scrollTop + windowHeight >= scrollHeight && this.page <= this.pageCount) {
        // console.log(scrollHeight);
        // console.log("到底了");
        this.page++
        this.aesKey = aesKey(this.page)
        let data = {
          page: this.page
        }
        this.aesKey = aesKey(data)
        this.getGoodsList(this.aesKey)
      }
    },
    //获取商城列表数据
    getGoodsList (data) {
      console.log(data, "=====data=====");
      if (data) {
        console.log("第一个方法");
        getGoodsList(data).then(res => {
          let arr = []
          res.data.data.list.forEach(item => {
            if (item.status == 2) {
              arr.push(item)
            }
          })
          this.pageCount = Math.ceil(res.data.data.total / 15)
          let dataArr = arr.map(item => {
            return {
              id: item.id,
              img: item.big_images,
              describe: item.name,
              type: item.brand_name,
              // 价格展示
              price: Math.ceil(
                Number(
                  item.sales_price *
                  (this.discount ? this.discount : 1)
                )
              ),
              oldPrice: item.sales_price
            }
          })
          this.imgsArr = this.imgsArr.concat(dataArr)
        })
      } else {

        console.log("第二个方法");
        getGoodsList(this.aesKey).then(res => {
          let arr = []
          res.data.data.list.forEach(item => {
            if (item.status == 2) {
              arr.push(item)
            }
          })
          this.pageCount = Math.ceil(res.data.data.total / 15)
          let imgsArr = arr.map(item => {
            return {
              id: item.id,
              img: item.big_images,
              describe: item.name,
              type: item.brand_name,
              // 价格展示
              price: Math.ceil(
                Number(
                  item.sales_price *
                  (this.discount ? this.discount : 1)
                )
              ),
              oldPrice: item.sales_price
            }
          })
          this.imgsArr = this.imgsArr.concat(imgsArr)

        })
      }
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  // overflow: hidden;
  width: 100%;
  // height: 800px;
  background-color: #f7f7f7;
  .content_top {
    width: 100%;
    height: 222px;
    background-image: url("../../../assets/shopImg/shop_banner.png");
    background-repeat: no-repeat;
    background-size: contain;
    .banner_hot {
      width: 100%;
      top: 23%;
      left: 0%;
      position: absolute;
      img {
        width: 100%;
      }
    }
    .everyday {
      width: 95%;
      margin-top: 60%;
    }
  }
  .shop_date {
    margin-top: 20%;
    width: 93%;
    margin-left: 3.5%;
    .shop_date_left {
      display: inline-block;
      width: 48%;
      margin-right: 3.5%;
      img {
        width: 100%;
      }
    }
    .shop_date_right {
      display: inline-block;

      width: 48%;
      img {
        width: 100%;
      }
    }
  }
  .gilt {
    .top {
      overflow: hidden;
      margin-bottom: 10px;
      .top_left {
        float: left;
      }
      .top_right {
        float: right;
        margin-right: 10px;
      }
    }
    padding: 3.5%;
    background-image: url("../../../assets/shopImg/shopping.png");
    width: auto;
    height: 155px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 3.5%;
    .shop {
      .depreciate {
        font-size: 14px;
        color: white;
        z-index: 20;
        top: 67%;
        left: 6px;
        line-height: 60%;
        margin-left: 11%;
        transform: scale(0.7);
      }
      .price_now {
        margin-top: 9%;
        font-size: 16px;
        color: #fe2051;
      }
      overflow: hidden;
      position: relative;
      height: 120px;
      .price {
        position: absolute;
        width: 20%;
        top: 39%;
      }
      .shop_one {
        float: left;
        width: 20%;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
      .shop_two {
        width: 20%;
        float: left;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
      .shop_three {
        width: 20%;
        margin-right: 20px;
        float: left;
        img {
          width: 100%;
        }
      }
      .shop_four {
        width: 20%;
        float: left;

        img {
          width: 100%;
        }
      }
    }
  }
  .ShopList {
    /deep/ .van-tab--active {
      color: #2dd2b5 !important;
      font-weight: 600;
      font-size: 16px !important;
    }
    background-color: white;
    height: 800px;
    width: 100%;
    margin-top: 20px;
    border-radius: 20px;
    margin-bottom: 2rem;
    /deep/ .van-tabs__wrap {
      border-radius: 20px;
    }
    .is_fixed {
      position: fixed;
      top: 0%;
      width: 100%;
    }
    #boxFixed {
      /deep/.van-tab__text--ellipsis {
        // font-size: 0.25rem;
        overflow: visible;
      }
      /deep/.van-tabs__line {
        background-color: #36e0c9;
        width: 0.6rem;
      }
      /deep/.van-tab--active {
        color: #333333;
        font-size: bold;
      }
      /deep/.vue-waterfall-easy {
        a.img-inner-box {
          box-shadow: none;
          a {
            height: 100% !important;
            width: auto !important;
          }
        }
        img {
          border-radius: 5px;
          width: 93%;
        }
      }
      .img-box .default-card-animation {
        padding: 0;
      }
    }
    /deep/.vue-waterfall-easy-container
      .vue-waterfall-easy-scroll[data-v-ded6b974] {
      overflow-x: visible;
      overflow-y: visible;
    }
  }
}
</style>