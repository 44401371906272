<template>
  <div id="weiaiHome">
    <NavBar NavBarTitle='观视界'
            @onClickLeft='onClickLeft'></NavBar>
    <div class="img">
      <img src="@/assets/weiaiImg/weiaiHome.png"
           alt="">
    </div>
    <div class="img"
         @click='goRegister'>
      <img src="@/assets/weiaiImg/bottom.png"
           alt="">
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
export default {
  components: {
    NavBar,
  },
  data () {
    return {

    }
  },
  methods: {
    onClickLeft () {
      this.$router.push({ path: '/allShop/shopHome' })
    },
    goRegister () {
      this.$router.push({ path: '/weiaiRegister' })
    }
  }
}
</script>

<style lang="less" scoped>
#weiaiHome {
  background-color: #bc9c66;
  .img {
    img {
      width: 100%;
    }
  }
}
</style>