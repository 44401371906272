<template>
  <div id="Alloutbound">
    <router-view></router-view>

  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
#allShop {
}
</style>