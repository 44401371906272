<template>
  <div id="allHotel">
    <!-- <keep-alive>
      <transition :name="transitionName">
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </transition>
    </keep-alive>

    <transition :name="transitionName">
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </transition> -->
    <div v-if="toName=='HotelDetail'&&fromName=='HotelList'">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive">
        </router-view>
      </keep-alive>

      <router-view v-if="!$route.meta.keepAlive">
      </router-view>
    </div>
    <div v-else-if="toName=='HotelList'&&fromName=='HotelDetail'">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive">
        </router-view>
      </keep-alive>

      <router-view v-if="!$route.meta.keepAlive">
      </router-view>
    </div>
    <div v-else>
      <transition :name="transitionName">
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
          <router-view v-if="!$route.meta.keepAlive"
                       :key="'time'+new Date().getTime()"></router-view>
        </keep-alive>
      </transition>
    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {
      transitionName: "",
      toName: '',
      fromName: ''
    }
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route (to, from) {
      // console.log(to.name, from.name)
      this.toName = to.name
      this.fromName = from.name
      //实现路由跳转动画
      if (to.meta.index > from.meta.index)
        this.transitionName = "slide-left";
      if (to.meta.index < from.meta.index)
        this.transitionName = "slide-right";
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ body {
  .van-overflow-hidden {
    overflow: visible !important;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  color: #2c3e50;
  .slide-right-enter-active,
  .slide-right-leave-active,
  .slide-left-enter-active,
  .slide-left-leave-active {
    will-change: transform;
    transition: ease 500ms;
    position: absolute;
  }

  .slide-right-enter {
    opacity: 0;
    transform: translateX(-100%);
  }

  .slide-right-leave-active {
    opacity: 0;
    transform: translateX(100%);
  }

  .slide-left-enter {
    opacity: 0;
    transform: translateX(100%);
  }

  .slide-left-leave-active {
    opacity: 0;
    transform: translateX(-100%);
  }
}
</style>
