<template>
  <div id="shopSort">
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="sidebar">
      <van-sidebar v-model="activeKey">
        <van-sidebar-item v-for="item in categoryData"
                          :key="item.id"
                          :title="item.name"
                          @click="choiceSort(item.id)" />
      </van-sidebar>
      <div class="content">
        <div v-for="item in sortData"
             :key="item.id">
          <div class="title">{{item.name}}</div>
          <div class="sortGird">
            <div v-for="sort in item.child"
                 :key="sort.id"
                 @click="getTour(sort)">
              <img :src="sort.img"
                   alt="">
              <span class="sortName">{{sort.name}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <van-tabbar v-model="bottomActive"
                  @change="switchBottomTab"
                  active-color='#58e3dd'>
        <van-tabbar-item>
          <img v-if="bottomActive == 0"
               src="@/assets/HomeIcon/bottom1active.png"
               alt="">
          <img v-else
               src="@/assets/HomeIcon/bottom1.png"
               alt="">
          <div>首页</div>
        </van-tabbar-item>
        <van-tabbar-item icon="">
          <img v-if="bottomActive == 1"
               src="@/assets/HomeIcon/bottom2active.png"
               alt="">
          <img v-else
               src="@/assets/HomeIcon/bottom2.png"
               alt="">
          <div>目的地</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 2"
               src="@/assets/HomeIcon/bottom3active.png"
               alt="">
          <img v-else
               src="@/assets/HomeIcon/bottom3.png"
               alt="">
          <div>商城</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 3"
               src="@/assets/HomeIcon/bottom4active.png"
               alt="">
          <img v-else
               src="@/assets/HomeIcon/bottom4.png"
               alt="">
          <div>我的</div>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { getGoodcategory } from '../../../api/shop.js'
import { getTourArea } from '../../../api/common'
import { aesKey } from "../../../commom/aesKey"
// import NavBar from '@/components/NavBar'
export default {
  components: {
    // NavBar
  },
  data () {
    return {
      bottomActive: 1,
      NavBarTitle: '目的地',//顶部导航名称
      aesKey: aesKey(),//接口通过密文来获取数据
      activeKey: 9,//侧边导航
      categoryData: [],//左边分类数据
      sortData: [],//右边显示的数据

    }
  },
  mounted () {
    // this.getGoodcategory()
    this.getTourArea()
  },
  watch: {
    categoryData (newData) {
      newData.forEach(item => {
        if (item.id == 13) {
          this.sortData = item.child
        }
      })
    }
  },
  methods: {
    //查询商品分类信息
    getGoodcategory () {
      getGoodcategory(this.aesKey).then(res => {
        let arr = res.data.data
        this.categoryData = arr
      })
    },
    //获取基础旅游区域
    getTourArea () {
      getTourArea().then(res => {
        this.categoryData = res.data.data
        console.log(this.categoryData)
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    //底部导航切换
    switchBottomTab () {
      console.log(this.bottomActive)
      if (this.bottomActive == 0) {
        this.$router.push({ path: '/Home' })
      } else if (this.bottomActive == 1) {
        this.$router.push({ path: '/allDest/destination' })
      } else if (this.bottomActive == 2) {
        this.$router.push({ path: '/allShop/shopHome' })
      } else if (this.bottomActive == 3) {
        this.$router.push({ path: '/allPerInfo/personalHome' })
      }

    },
    //选择侧边导航
    choiceSort (index) {
      this.categoryData.forEach(item => {
        if (item.id == index) {
          this.sortData = item.child
          console.log(this.sortData)
        }
      })
    },
    //点击右边的数据
    getTour (data) {
      // this.$router.push({ path: '/allShop/shopList' })
      // sessionStorage.setItem('categoryId', data.id)
      console.log(data)
    }
  }
}
</script>

<style lang="less" scoped>
#shopSort {
  height: 830px;
  .sidebar {
    height: 100%;
    position: relative;
    /deep/.van-sidebar {
      width: 2rem;
      height: 100%;
      background-color: #f5f5f5;
      .van-sidebar-item--select::before {
        background-color: white;
      }
      .van-sidebar-item {
        font-size: 0.25rem;
        padding: 0.25rem 0;
      }
    }
    .content {
      font-size: 0.25rem;
      position: absolute;
      left: 30%;
      top: 0%;
      width: 68%;
      .title {
        text-align: left;
        font-weight: bold;
        font-size: 0.28rem;
        margin-top: 0.2rem;
      }
      .sortGird {
        margin-top: 20px;
        text-align: left;
        div {
          width: 33%;
          display: inline-block;
          margin-top: 0.1rem;
          .sortName {
            font-size: 0.26rem;
            width: 80%;
            text-align: center;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          img {
            width: 1.3rem;
            height: 1.3rem;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .bottom {
    text-align: center;
    img {
      width: 30%;
    }
  }
}
</style>