<template>
  <div id="HotelList">
    <!-- 顶部 -->
    <div style="position: relative;height:180px">
      <div class="isFix">
        <!-- <NavBar :NavBarTitle='NavBarTitle'
                @onClickLeft='onClickLeft'></NavBar> -->
        <div class="header">
          <div class="search">
            <div class="left">
              <div class="address"
                   @click="changeAddress">{{ cityInfo.name }}</div>
              <span class="date"
                    @click="changeDate">
                <div>
                  <span>住</span>
                  <span style="color:#1bc7b0;margin-left:0.04rem;font-weight:bold">{{ ArrivalDate }}</span>
                </div>
                <div>
                  <span>离</span>
                  <span style="color:#1bc7b0;margin-left:0.04rem;font-weight:bold">{{ LeavelDate }}</span>
                </div>
              </span>
            </div>
            <div class="right">
              <form action="/">
                <van-search v-model="hotelKey"
                            shape="round"
                            @search="getSearch(hotelKey)"
                            placeholder="关键词/位置/品牌" />
              </form>

            </div>
          </div>
          <div class="icon">
            <img src="@/assets/HotelImg/Map_icon.png"
                 alt="">
          </div>
        </div>
        <div class="screen">
          <van-dropdown-menu active-color='#24C5AF'>
            <van-dropdown-item v-model="value1"
                               @change="filters_first(value1)"
                               :options="option1" />
            <van-dropdown-item title="位置距离">
              <TreeSelect @coordinates="getCoordinates"></TreeSelect>
            </van-dropdown-item>
            <van-dropdown-item title="价格/星级"
                               @open="openPrice"
                               @change="clickPrice(item)"
                               ref="price">
              <van-sidebar v-model="activeKey">
                <PriceSheet :PriceSheet='PriceSheet'
                            @getPrice='getPrice'
                            style="font-size:0.2rem"></PriceSheet>
              </van-sidebar>
            </van-dropdown-item>
            <van-dropdown-item title="筛选">
              <Screen @changehotel="getchanghotel"></Screen>
            </van-dropdown-item>

          </van-dropdown-menu>
        </div>
        <div class="type">
          <div class="type_btn"
               v-for="(item, index) in typeData"
               :key="item.id"
               :class="item.isclick == true ? 'is_type_active' : ''"
               @click="getType(item, index)">
            {{ item.text }}
            <div v-if="item.isclick"
                 class="checked">
              <img src="@/assets/HotelImg/is_active.png"
                   alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 酒店列表 -->
    <div class="HotelList_none"
         v-show="ShowHotel">该城市酒店上新中~~~~~
    </div>
    <van-list v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="setTimeout">
      <div class="HotelList">
        <ShowList :is_showList='is_showList'
                  :ShowHotelData='ShowHotelData'
                  @getHotelId='getHotelId'></ShowList>
      </div>
    </van-list>

    <!-- 地址弹窗 -->
    <AddressSheet :addressShow='addressShow'
                  @cancelA='cancelA'
                  @clickOverlayA='clickOverlayA'
                  @getbtn_Address1='getbtn_Address1'
                  @getsheet_Data="getsheetData"
                  @getCity='getCity'></AddressSheet>
    <!-- 日历弹窗 -->
    <div>
      <van-calendar v-model="calendarShow"
                    color="#36e0c9"
                    type="range"
                    @confirm="onConfirm"
                    :default-date="defaultDate"
                    :formatter="formatter" />
    </div>
  </div>
</template>
<script>

import { getHomeData, getHotelDetail } from '../../../api/hotel.js'
// import { aesKey } from "../../../commom/aesKey"
import { Toast } from 'vant';
// import NavBar from '../../../components/NavBar.vue'
import TreeSelect from '../../../components/TreeSelect.vue'
import PriceSheet from '../../../components/PriceSheet.vue'
import Screen from '../../../components/Screen.vue'
import ShowList from '../../../components/ShowList.vue'
import AddressSheet from '../../../components/AddressSheet.vue'
export default {
  components: {
    // NavBar,
    TreeSelect,
    PriceSheet,
    Screen,
    ShowList,
    AddressSheet
  },
  data () {
    return {
      //下拉刷新
      HotelSearchData: null,
      ShowHotel: false,
      list: [],
      searchValue: '',//搜索
      loading: false,
      finished: false,
      page: 1,//页数
      pageCount: '',//可翻页的次数
      // aesKey: aesKey(),//接口通过密文来获取数据
      NavBarTitle: '酒店',
      //上一个路由传来的信息之后的接受
      cityInfo: '',//地址数据
      dateArray: [],//日期数据
      ArrivalDate: '',//入住日期的文字数据
      LeavelDate: '',//离开日期的文字数据
      hotelKey: '',//酒店搜索的数据

      //按智能排序筛选数据
      value1: 0,
      HomelValue: '',//酒店首页搜索数据
      option1: [
        { text: '智能排序', value: 0 },
        { text: '低价优先', value: 3 },
        { text: '高价优先', value: 4 },
        { text: '直线距离 近--远', value: 5 },
      ],
      activeKey: 0,
      //点击价格/类型下拉菜单时所需的数据
      newTypeArr: '',//价格/类型中的类型
      priceValue: '',//价格/类型中的价格
      PriceSheet: '1',//是否为价格弹窗
      type: [],
      //下拉滚动时要的参数
      search: '',//总搜索（酒店名称/标签）
      brand: '', //品牌名称
      longitude: null,
      latitude: null,
      distance: null,
      is_hot: null,
      meals: null,

      typeData: [
        {
          text: '特价优惠',
          isclick: false
        },
        {
          text: '钟点房',
          isclick: false
        },
        {
          text: '大床房',
          isclick: false
        },
        {
          text: '双床房',
          isclick: false
        },
        {
          text: '积分兑换',
          isclick: false
        },
        {
          text: '到点付款',
          isclick: false
        },
        {
          text: '4.5分以上',
          isclick: false
        },
      ],

      //酒店列表数据
      is_showList: '1',
      ShowHotelData: [],

      //地址弹窗
      addressShow: false,

      //日历弹窗
      calendarShow: false,
      defaultDate: [],//默认日期
      //选择日期时间戳
      start_date: this.ArrivalDate,
      end_date: this.LeavelDate,
      HomeValue: '',
      HomeSearchValue: '',
      total: null,
    }
  },
  created () {
    this.Hoteltime()
    this.HotelSearchData = JSON.parse(sessionStorage.getItem('HotelSearchData'))
    this.isFirstEnter = true;

    this.dealData()
    this.getHotelTag()
  },
  mounted () {
    console.log(this.pageCount, "酒店页数有多少");
    window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    // sessionStorage.setItem('hotelRefresh', 1)
    this.dateArray = sessionStorage.getItem("dateArray")
    let Hoteldate = this.dateArray.split(",")
    let start_date = Hoteldate[0].substring(0, Hoteldate[0].length - 3);
    let end_date = Hoteldate[1].substring(0, Hoteldate[1].length - 3);
    let data = {
      mode: 2,
      city_id: this.cityInfo.city_id,
      status: 1,
      start_date: start_date,
      end_date: end_date,
      // page: 2
    }
    // this.aesKey = aesKey(data)
    this.getHotelData(data)
    // this.test2()
  },
  beforeRouteEnter (to, from, next) {
    // if (from.name == 'HotelDatail') {
    //   to.meta.isBack = true;
    //   //判断是从哪个路由过来的，
    //   //如果是page2过来的，表明当前页面不需要刷新获取新数据，直接用之前缓存的数据即可
    // }
    //继续执行
    // next();
    if (from.name == 'Home') {
      console.log("来自首页开始执行方法");
    }
    next(vm => {
      // vm.test()
      vm
    })
  },
  activated () {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
      // 如果isFirstEnter是true，表明是第一次进入此页面或用户刷新了页面，需获取新数据
      // 执行自己写的页面的初始化
      // this.updataTableData();
      // console.log(999999)
    }
    // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
    this.$route.meta.isBack = false
    // 恢复成默认的false，避免isBack一直是true，导致每次都获取新数据
    this.isFirstEnter = false;
  },
  watch: {
    // cityInfo (newName) {
    //   let  data = { 
    //     mode: 2,
    //     city_id: newName.city_id,
    //     status: 1,
    //     page: this.pageCount + 1
    //   }
    //   // this.aesKey = aesKey(data)
    //   this.getHotelData(data)
    // }
  },
  methods: {
    // onLoad () {
    //   // 异步更新数据
    //   // setTimeout 仅做示例，真实场景中一般为 ajax 请求
    //   setTimeout(() => {
    //     for (let i = 0; i < 10; i++) {
    //       this.list.push(this.list.length + 1);
    //     }

    //     // 加载状态结束
    //     this.loading = false;

    //     // 数据全部加载完成
    //     if (this.list.length >= 40) {
    //       this.finished = true;
    //     }
    //   }, 1000);
    // },
    //获取酒店搜索数据
    test () {
      if (this.$route.query.HomeValue != '') {
        this.HomelValue = this.$route.query.HomeSearchValue.search
      }
      let HomelValue = this.HomelValue
      let data = {
        search: HomelValue
      }
      getHomeData(data).then(res => {
        console.log(res);
        let arr = []
        res.data.data.list.forEach(item => {
          // console.log(item);
          if (item.status == 1) {
            arr.push(item)
          }
        })
        this.pageCount = Math.ceil(res.data.data.total / 10)
        console.log(this.pageCount, "  this.pageCount ");
        let dataArr = arr.map(item => {
          // console.log(item);
          return {
            id: item.hotel_id,
            img: item.hotel_pic,
            title: item.name,
            mode: item.mode,
            starting_price: Math.ceil(Number(item.starting_price)),
            city_name: item.city_name,
            hotel_tags: item.hotel_tags,
            discount: item.hotel_tags,
            page: 1
          }
        })
        console.log(dataArr, "首页进来");
        // console.log(this.ShowHotelData);
      })
    },
    getHotelSearchData () {
      // console.log(HotelSearchData);
      this.ShowHotelData = this.HotelSearchData.map(item => {
        // console.log(item);
        return {
          id: item.hotel_id,
          img: item.hotel_pic,
          title: item.name,
          mode: item.mode,
          starting_price: Math.ceil(Number(item.starting_price)),
          city_name: item.city_name,
          hotel_tags: item.hotel_tags,
          discount: item.hotel_tags
        }
      })
      this.dateArray = sessionStorage.getItem('dateArray')
      this.dateArray = this.dateArray.split(',')
      this.ArrivalDate = new Date(Number(this.dateArray[0])).getMonth() + 1 + '-' + new Date(Number(this.dateArray[0])).getDate()
      this.LeavelDate = new Date(Number(this.dateArray[this.dateArray.length - 1])).getMonth() + 1 + '-' + new Date(Number(this.dateArray[this.dateArray.length - 1])).getDate()
    },

    //判断酒店数据为空时
    // estimateHotel () {
    //   if (this.ShowHotelData == []) {
    //     console.log(this.ShowHotelData);
    //     this.ShowHotel = true

    //   }
    // },
    //搜索酒店getSearch
    getSearch (hotelKey) {
      if (hotelKey == "") {
        Toast.fail('搜索不能为空')
        return
      }
      let data = {
        city_id: this.cityInfo.city_id,
        search: hotelKey
      }
      getHomeData(data).then(res => {
        console.log(res);
        if (res.data.data.total == 0) {
          this.ShowHotel = true
        }
        let searchData = res.data.data.list
        this.ShowHotelData = searchData.map(item => {
          // console.log(item);
          return {
            id: item.hotel_id,
            img: item.hotel_pic,
            title: item.name,
            mode: item.mode,
            starting_price: Math.ceil(Number(item.starting_price)),
            city_name: item.city_name,
            hotel_tags: item.hotel_tags,
            discount: item.hotel_tags
          }
        })
      })
    },

    getsheetData (value) {
      console.log(value, "从sheet");
      let sheetData = value
      this.ShowHotelData = sheetData.map(item => {
        // console.log(item);
        return {
          id: item.hotel_id,
          img: item.hotel_pic,
          title: item.name,
          mode: item.mode,
          starting_price: Math.ceil(Number(item.starting_price)),
          city_name: item.city_name,
          hotel_tags: item.hotel_tags,
          discount: item.hotel_tags
        }
      })
    },
    Hoteltime () {
      this.dateArray = sessionStorage.getItem('dateArray')
      this.dateArray = this.dateArray.split(",")
      console.log(this.dateArray, "=====筛选3=====");
      let today = this.dateArray[0]
      // today = today.substring(0, today.length - 3)
      today = Number(today)
      this.start_date = today
      console.log("====today====", today);
      let tomorrow = this.dateArray[1]
      // tomorrow = tomorrow.substring(0, tomorrow.length - 3)
      tomorrow = Number(tomorrow)
      this.end_date = tomorrow
    },
    filters_first (value) {
      console.log(this.start_date);
      console.log("点击筛选", value);
      if (value == 0) {
        // this.aesKey = aesKey()
        getHomeData().then((res) => {
          console.log(res);
        })
      } else if (value == 3) {
        this.order_by = ['sales_price']
        let data = {
          order_by: ['sales_price'],
          city_id: this.cityInfo.city_id,
          start_date: this.start_date / 1000,
          end_date: this.end_date / 1000,
        }
        console.log(data, "33333");
        getHomeData(data).then((res) => {
          console.log(res);
          let arr = []
          res.data.data.list.forEach(item => {
            if (item.status == 1) {
              arr.push(item)
            }
          })
          // console.log(Math.ceil(res.data.data.total / 15))
          this.pageCount = Math.ceil(res.data.data.total / 10)
          this.ShowHotelData = arr.map(item => {
            return {
              id: item.hotel_id,
              img: item.hotel_pic,
              title: item.name,
              mode: item.mode,
              starting_price: Math.ceil(Number(item.starting_price)),
              city_name: item.city_name,
              hotel_tags: item.hotel_tags,
              discount: item.hotel_tags
            }
          })
          console.log(this.ShowHotelData);
        })
      }
      else if (value == 4) {
        this.order_by = ['sales_price desc']
        let data = {
          order_by: ['sales_price desc'],
          city_id: this.cityInfo.city_id,
          start_date: this.start_date / 1000,
          end_date: this.end_date / 1000,
        }
        getHomeData(data).then((res) => {
          console.log(res);
          let arr = []
          res.data.data.list.forEach(item => {
            if (item.status == 1) {
              arr.push(item)
            }
          })
          // console.log(Math.ceil(res.data.data.total / 15))
          this.pageCount = Math.ceil(res.data.data.total / 10)
          this.ShowHotelData = arr.map(item => {
            return {
              id: item.hotel_id,
              img: item.hotel_pic,
              title: item.name,
              mode: item.mode,
              starting_price: Math.ceil(Number(item.starting_price)),
              city_name: item.city_name,
              hotel_tags: item.hotel_tags,
              discount: item.hotel_tags
            }
          })
          console.log(this.ShowHotelData);
        })
      }
      else if (value == 5) {
        let today = new Date().getDate()
        let tomorrow = new Date().getDate() + 1
        console.log(today, tomorrow);
        let longitude = localStorage
          .getItem('longitude')
        let latitude = localStorage
          .getItem('latitude')
        console.log(this.start_date);
        console.log(this.end_date);
        let data = {
          longitude: longitude,
          latitude: latitude,
          distance: 3000,
          start_date: this.start_date / 1000,
          end_date: this.end_date / 1000,
        }
        getHomeData(data).then((res) => {
          console.log(res);
          let arr = []
          res.data.data.list.forEach(item => {
            if (item.status == 1) {
              arr.push(item)
            }
          })
          // console.log(Math.ceil(res.data.data.total / 15))
          this.pageCount = Math.ceil(res.data.data.total / 10)
          this.ShowHotelData = arr.map(item => {
            return {
              id: item.hotel_id,
              img: item.hotel_pic,
              title: item.name,
              mode: item.mode,
              starting_price: Math.ceil(Number(item.starting_price)),
              city_name: item.city_name,
              hotel_tags: item.hotel_tags,
              discount: item.hotel_tags
            }
          })
          console.log(this.ShowHotelData);
        })
      }
      // console.log(this.option1);

    },
    //处理数据
    dealData () {
      // this.cityInfo = ''
      this.cityInfo = JSON.parse(sessionStorage.getItem('sessionCityData'))
      this.dateArray = sessionStorage.getItem('dateArray')
      this.dateArray = this.dateArray.split(',')
      this.hotelKey = this.$route.query.hotelKey
      this.newTypeArr = this.$route.query.newTypeArr
      this.priceValue = this.$route.query.priceValue
      this.ArrivalDate = new Date(Number(this.dateArray[0])).getMonth() + 1 + '-' + new Date(Number(this.dateArray[0])).getDate()
      this.LeavelDate = new Date(Number(this.dateArray[this.dateArray.length - 1])).getMonth() + 1 + '-' + new Date(Number(this.dateArray[this.dateArray.length - 1])).getDate()
      // console.log(this.priceValue)
      if (this.priceValue == undefined) {
        return
      }
      if (this.priceValue[0] == 0 && this.priceValue[1] == 1200) {
        this.priceValue = []
      }
      console.log(this.cityInfo, "==this.cityInfo");
    },

    //获得酒店标签
    getHotelTag () {
      getHotelDetail(this.aesKey).then((res) => {
        console.log(res.data, "酒店标签");
      })
    },
    getCoordinates (value) {
      console.log(value, "====value===");
      let ScreenData = value
      this.ShowHotelData = ScreenData.map(item => {
        // console.log(item);
        return {
          id: item.hotel_id,
          img: item.hotel_pic,
          title: item.name,
          mode: item.mode,
          starting_price: Math.ceil(Number(item.starting_price)),
          city_name: item.city_name,
          hotel_tags: item.hotel_tags,
          discount: item.hotel_tags
        }
      })
      console.log("父组件获取到的子组件Coordinates数据", this.ShowHotelData);
    },


    //筛选获得的数据
    getchanghotel (value) {
      let ScreenData = value
      this.ShowHotelData = ScreenData.map(item => {
        // console.log(item);
        return {
          id: item.hotel_id,
          img: item.hotel_pic,
          title: item.name,
          mode: item.mode,
          starting_price: Math.ceil(Number(item.starting_price)),
          city_name: item.city_name,
          hotel_tags: item.hotel_tags,
          discount: item.hotel_tags
        }
      })
      console.log("父组件获取到的子组件changhotel数据", this.ShowHotelData);
    },
    // test2 () {
    //   this.HomeValue = this.$route.query.HomeSearchValue.search
    //   console.log(this.HomeValue, "============test2============");
    // },
    //获取酒店列表推荐数据
    async getHotelData (data) {
      this.HomeSearchValue = this.$route.query.HomeSearchValue
      console.log(this.HomeSearchValue, "=====this.HomeSearchValue");
      // console.log(this.$route.query.HomeSearchValue.search, "==================");
      if (this.HomeSearchValue != undefined) {
        console.log("====执行了no.1===");
        console.log(this.HomeSearchValue, "===this.HomeSearchValue===");
        let arr = []
        getHomeData(this.HomeSearchValue).then(res => {
          res.data.data.list.forEach(item => {
            if (item.status == 1) {
              arr.push(item)
              this.pageCount = Math.ceil(res.data.data.total / 10)
              this.ShowHotelData = arr.map(item => {
                // console.log(item);
                return {
                  id: item.hotel_id,
                  img: item.hotel_pic,
                  title: item.name,
                  mode: item.mode,
                  starting_price: Math.ceil(Number(item.starting_price)),
                  city_name: item.city_name,
                  hotel_tags: item.hotel_tags,
                  discount: item.hotel_tags,
                  page: 1
                }
              }
              )
            }
          })
        })
      }
      else if (data) {

        console.log("执行了no.2");

        console.log(data, "=====data=====");
        await setTimeout(() => {
          getHomeData(data).then(res => {
            this.total = res.data.data.total
            // console.log(this.total, "======res.data.data.total=====");
            // console.log(res);

            let arr = []
            res.data.data.list.forEach(item => {
              // console.log(item);
              if (item.status == 1) {
                arr.push(item)
              }
            })
            this.pageCount = Math.ceil(res.data.data.total / 10)
            console.log(this.pageCount, "  this.pageCount ");
            let dataArr = arr.map(item => {
              // console.log(item);
              return {
                id: item.hotel_id,
                img: item.hotel_pic,
                title: item.name,
                mode: item.mode,
                starting_price: Math.ceil(Number(item.starting_price)),
                city_name: item.city_name,
                hotel_tags: item.hotel_tags,
                discount: item.hotel_tags,
                page: 1
              }
            })
            // console.log(dataArr);
            this.ShowHotelData = this.ShowHotelData.concat(dataArr)

            // console.log(this.ShowHotelData, "========ShowHotelData============");
            console.log(this.ShowHotelData.length, "=====this.ShowHotelData.length=====");
            console.log(this.total, "=====this.total.length=====");
            if (this.ShowHotelData.length == this.total) {
              this.finished = true;
            }
          })
        }, 1000);

      }
      else {
        getHomeData().then(res => {
          console.log("执行了no.3");
          let arr = []
          res.data.data.list.forEach(item => {
            if (item.status == 1) {
              arr.push(item)
            }
          })
          // console.log(Math.ceil(res.data.data.total / 15))
          this.pageCount = Math.ceil(res.data.data.total / 10)
          this.ShowHotelData = arr.map(item => {
            // console.log(item);
            return {
              id: item.hotel_id,
              img: item.hotel_pic,
              title: item.name,
              mode: item.mode,
              starting_price: Math.ceil(Number(item.starting_price)),
              city_name: item.city_name,
              hotel_tags: item.hotel_tags,
              discount: item.hotel_tags,
              page: 1
            }
          }
          )
          // console.log(this.ShowHotelData);
        })
      }
    },


    // getHotelData (data) {
    //     if (data) {
    //       getHomeData(data).then(res => {
    //         let  arr = []
    //         res.data.data.list.forEach(item => {
    //           if (item.status == 1) {
    //             arr.push(item)
    //           }
    //         })
    //         this.pageCount = Math.ceil(res.data.data.total / 15)
    //         let  dataArr = arr.map(item => {
    //           return {
    //             id: item.hotel_id,
    //             img: item.hotel_pic,
    //             title: item.name,
    //             mode: item.mode,
    //             starting_price: Math.ceil(Number(item.starting_price)),
    //             city_name: item.city_name,
    //             hotel_tags: item.hotel_tags,
    //             discount: item.hotel_tags
    //           }
    //         })
    //         // console.log(this.ShowHotelData);
    //         this.ShowHotelData = this.ShowHotelData.concat(dataArr)
    //       })
    //     } else {
    //       getHomeData(this.aesKey).then(res => {
    //         console.log();
    //         let  arr = []
    //         res.data.data.list.forEach(item => {
    //           if (item.status == 1) {
    //             arr.push(item)
    //           }
    //         })
    //         // console.log(Math.ceil(res.data.data.total / 15))
    //         this.pageCount = Math.ceil(res.data.data.total / 15)
    //         this.ShowHotelData = arr.map(item => {
    //           // console.log(item);
    //           return {
    //             id: item.hotel_id,
    //             img: item.hotel_pic,
    //             title: item.name,
    //             mode: item.mode,
    //             starting_price: Math.ceil(Number(item.starting_price)),
    //             city_name: item.city_name,
    //             hotel_tags: item.hotel_tags,
    //             discount: item.hotel_tags
    //           }
    //         }
    //         )
    //         console.log(this.ShowHotelData);
    //       })
    //     }
    //   },
    // 获取滚动条的滚动距离
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      // // // //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

      // // // //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight == scrollHeight) {
        console.log(scrollHeight, "到底");
        //写后台加载数据的函数
      }

      if (scrollTop + windowHeight >= scrollHeight - 1 && this.page <= this.pageCount) {
        console.log("到底后执行的方法");
        this.page++
        this.dateArray = sessionStorage.getItem("dateArray")
        let Hoteldate = this.dateArray.split(",")
        let start_date = Hoteldate[0].substring(0, Hoteldate[0].length - 3);
        let end_date = Hoteldate[1].substring(0, Hoteldate[1].length - 3);
        let data = {
          start_date: start_date,
          end_date: end_date,
          page: this.page,
          city_id: this.cityInfo.city_id,
          status: 1,
          search: this.search,
          brand: this.brand,
          order_by: this.order_by,
          longitude: this.longitude,
          latitude: this.latitude,
          distance: this.distance,
          room_layout: this.room_layout
        }
        // console.log(data)
        this.getHotelData(data)
      }


    },
    //计算日期差处理， 返回时间戳
    dateBetweenDeal (startDate, endDate) {
      let st = new Date(startDate).getTime();
      let et = new Date(endDate).getTime();
      let retArr = [];
      const oneDay = 1000 * 60 * 60 * 24

      while (st != et) {
        retArr.push(st);
        st = st + oneDay
      }
      retArr.push(et);

      return retArr
    },
    //返回键
    onClickLeft () {
      this.$router.push({ path: '/allHotel/Hotel' })
      // this.$router.go(0)
    },
    //地址弹窗
    //点击地址
    changeAddress () {
      console.log(123)
      this.addressShow = true
    },
    //点击地址弹窗的关闭按钮
    cancelA () {
      this.addressShow = false
    },
    //点击地址弹窗时遮罩层
    clickOverlayA () {
      this.addressShow = false
    },
    //上面按钮部分
    getbtn_Address1 (data) {
      this.cityInfo = data
      sessionStorage.setItem('sessionCityData', JSON.stringify(data))
      this.addressShow = false
      this.page = 1
      window.scrollTo(0, 0);
    },
    //下面字母索引的城市部分
    getCity (data) {
      this.cityInfo = data
      sessionStorage.setItem('sessionCityData', JSON.stringify(data))
      this.addressShow = false
      this.page = 1
      window.scrollTo(0, 0);
    },

    //日期
    //点击日期
    changeDate () {
      // console.log('date')
      this.calendarShow = true
    },
    //日历上的显示
    formatter (day) {
      if (day.type === 'start') {
        day.bottomInfo = '入住';
      } else if (day.type === 'end') {
        day.bottomInfo = '退房';
      }
      return day;
    },
    //初始化日期
    // getNowDate () {
    //   this.dateArray = this.dateBetweenDeal(new Date().getTime())
    //   console.log("===this.dateArray===", this.dateArray);
    //   this.start_date = this.dateArray[0]
    //   this.end_date = this.dateArray[this.dateArray.length - 1]
    //   console.log("===初始化日期====", this.start_date, this.end_date);
    // },
    //日历确认
    onConfirm (date) {
      // const [start, end] = date;
      console.log(date);
      this.calendarShow = false;
      this.dateArray = this.dateBetweenDeal(new Date(date[0]).getTime(), new Date(date[1]).getTime())
      sessionStorage.setItem('dateArray', this.dateArray)
      console.log(this.dateArray, "====this.dateArray====")
      this.ArrivalDate = new Date(Number(this.dateArray[0])).getMonth() + 1 + '-' + new Date(Number(this.dateArray[0])).getDate()
      this.LeavelDate = new Date(Number(this.dateArray[this.dateArray.length - 1])).getMonth() + 1 + '-' + new Date(Number(this.dateArray[this.dateArray.length - 1])).getDate()
      // 储存时间戳
      // console.log(this.ArrivalDate)
      this.start_date = this.dateArray[0]
      this.end_date = this.dateArray[this.dateArray.length - 1]
    },
    clickPrice (item) {
      console.log(item);
    },
    //点击下拉列表中的‘价格类型’
    openPrice () {
      this.priceShow = true
    },
    //价格/类型中的完成
    getPrice (priceKey, priceValue, newTypeArr) {
      console.log(priceKey, priceValue, newTypeArr, "=====priceKey,priceValue,newTypeArr====");
      this.dateArray = sessionStorage.getItem("dateArray")
      let Hoteldate = this.dateArray.split(",")
      let start_date = Hoteldate[0].substring(0, Hoteldate[0].length - 3);
      let end_date = Hoteldate[1].substring(0, Hoteldate[1].length - 3);
      // console.log(Hoteldate, "===== this.dateArray======");
      // console.log(priceKey)
      this.priceValue = priceValue
      this.newTypeArr = newTypeArr
      console.log(this.priceValue);
      this.$refs.price.toggle();
      let data = {
        city_id: this.cityInfo.city_id,
        start_price: this.priceValue[0],
        end_price: this.priceValue[1],
        start_date: start_date,
        end_date: end_date,
        status: 1
      }
      console.log(data);
      getHomeData(data).then((res) => {
        console.log("执行了价格");
        let arr = []
        res.data.data.list.forEach(item => {
          if (item.status == 1) {
            arr.push(item)
          }
        })
        // console.log(Math.ceil(res.data.data.total / 15))
        this.pageCount = Math.ceil(res.data.data.total / 10)
        this.ShowHotelData = arr.map(item => {
          // console.log(item);
          return {
            id: item.hotel_id,
            img: item.hotel_pic,
            title: item.name,
            mode: item.mode,
            starting_price: Math.ceil(Number(item.starting_price)),
            city_name: item.city_name,
            hotel_tags: item.hotel_tags,
            discount: item.hotel_tags,
            page: 1
          }
        }
        )
      })
    },
    //选择价格按钮
    getBtnVa (data, arr) {
      this.priceArray.forEach(item => {
        item.isclick = false
      })
      arr.isclick = true
      this.priceValue = data
    },
    //类型
    getType (data, index) {
      console.log(data, index);
      if (data.isclick == true) {
        this.type.find(item => {
          console.log(item, "item");
          if (data.text == item) {
            let index = this.type.indexOf(data.text)
            this.type.splice(index, 1)
          }
        })
        data.isclick = false
        console.log(this.type, "====type_no====");
        let typedata = {
          search: this.type.toString(),
          city_id: this.cityInfo.city_id
        }
        getHomeData(typedata).then(res => {
          let arr = []
          res.data.data.list.forEach(item => {
            if (item.status == 1) {
              arr.push(item)
            }
          })
          // console.log(Math.ceil(res.data.data.total / 15))
          this.pageCount = Math.ceil(res.data.data.total / 10)
          this.ShowHotelData = arr.map(item => {
            return {
              id: item.hotel_id,
              img: item.hotel_pic,
              title: item.name,
              mode: item.mode,
              starting_price: Math.ceil(Number(item.starting_price)),
              city_name: item.city_name,
              hotel_tags: item.hotel_tags,
              discount: item.hotel_tags
            }
          })
          console.log(this.ShowHotelData);
        })
      } else if (data.isclick == false) {
        this.type = this.type.concat(data.text)
        data.isclick = true
        console.log(this.type, "====type====");
        let typedata = {
          search: this.type.toString(),
          city_id: this.cityInfo.city_id
        }
        console.log(data);
        getHomeData(typedata).then(res => {
          let arr = []
          res.data.data.list.forEach(item => {
            if (item.status == 1) {
              arr.push(item)
            }
          })
          // console.log(Math.ceil(res.data.data.total / 15))
          this.pageCount = Math.ceil(res.data.data.total / 10)
          this.ShowHotelData = arr.map(item => {
            return {
              id: item.hotel_id,
              img: item.hotel_pic,
              title: item.name,
              mode: item.mode,
              starting_price: Math.ceil(Number(item.starting_price)),
              city_name: item.city_name,
              hotel_tags: item.hotel_tags,
              discount: item.hotel_tags
            }
          })
          console.log(this.ShowHotelData);
        })
      }
    },

    //根据酒店ID跳转到酒店详情页面
    getHotelId (data) {
      // console.log(data.id)
      this.$router.push(
        {
          path: '/allHotel/HotelDatail',
          query: { hotelId: data.id }
        }
      )
    }
  }
}
</script>
<style lang="less" scoped>
#HotelList {
  width: 100%;
  height: 100%;
  font-size: 0.3rem;
  text-align: left;
  position: relative;

  .isFix {
    position: fixed;
    background-color: white;
    z-index: 1000;
    top: 0%;
    width: 100%;

    .header {
      position: relative;
      height: 100%;
      // background-color: aqua;
      display: flex;

      // margin-top: 0.2rem;
      .search {
        width: 84%;
        background-color: #f8f8f8;
        margin-left: 0.3rem;
        border-radius: 50px;
        padding: 0.05rem 0rem;
        display: flex;
        position: relative;

        .left {
          display: inline-block;
          position: relative;
          font-size: 0.17rem;

          .address {
            display: inline-block;
            color: #1bc7b0;
            font-weight: bold;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 50%;
            width: 50%;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .date {
            float: right;
            font-size: 0.21rem;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            border-right: 0.5px solid #c0c0c0;
            margin-left: 1rem;
            padding-right: 0.2rem;
          }
        }

        .right {
          width: 60%;

          .van-search {
            padding: 0;
            border-radius: 50px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            /deep/.van-field__value {
              font-size: 0.2rem;
            }

            /deep/.van-field__left-icon {
              i {
                font-size: 0.2rem;
              }
            }
          }
        }
      }

      .icon {
        width: 6.5%;
        margin-left: 0.2rem;
        margin-top: 0.05rem;

        img {
          width: 100%;
        }
      }
    }

    .screen {
      position: relative;
      margin-top: 0.1rem;

      /deep/.van-dropdown-menu__bar {
        box-shadow: none;

        .van-dropdown-menu__title {
          font-size: 0.2rem;
        }

        .van-ellipsis {
          overflow: visible;
          font-size: 0.23rem;
        }
      }

      /deep/.van-sidebar {
        width: 100%;

        .van-sidebar-item--select {
          color: #24c5af;
        }

        .van-sidebar-item__text {
          font-size: 0.22rem;
        }

        .van-sidebar-item--select::before {
          display: none;
        }
      }

      /deep/.van-tree-select__nav {
        // padding-left: 0.5rem;
        flex: 0.7;

        .van-tree-select__nav-item {
          padding-left: 0.5rem;
        }
      }

      /deep/.van-tree-select__item {
        font-size: 0.23rem;
      }

      /deep/.van-tree-select__item--active {
        color: #24c5af;
      }

      /deep/.van-cell__title {
        font-size: 0.22rem;
        padding-left: 0.3rem;
      }

      /deep/.van-cell__value {
        font-size: 0.15rem;
        padding-right: 0.3rem;
      }
    }

    .type {
      position: relative;
      text-align: center;
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      margin-left: 0.3rem;

      .type_btn {
        font-size: 0.21rem;
        padding: 0.15rem 0.25rem;
        background-color: #f8f8f8;
        margin-left: 0.1rem;
        border-radius: 5px;
        white-space: nowrap;
        display: inline-block;
      }

      .type_btn.is_type_active {
        background-color: #cdf7f2;
        color: #36e0c9;
        // font-weight: bold;
        position: relative;

        .checked {
          position: absolute;
          bottom: -7%;
          right: 0%;
          width: 20%;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .HotelList {
    position: relative;
    // margin-top: 3rem;
    // top: 20%;
    margin-left: 0.4rem;
  }
  .HotelList_none {
    color: #666363f7;
    position: relative;
    // margin-top: 3rem;
    // top: 20%;
    margin-left: 0.4rem;
    position: absolute;
    top: 250%;
    left: 25%;
  }
}
</style>