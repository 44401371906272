  let bar_address=[
    {
        "city_id": 2,
        "code": 110100000000,
        "level": 2,
        "name": "北京市",
        "pcode": 110000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 358,
        "code": 120100000000,
        "level": 2,
        "name": "天津市",
        "pcode": 120000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 676,
        "code": 130100000000,
        "level": 2,
        "name": "石家庄市",
        "pcode": 130000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 983,
        "code": 130200000000,
        "level": 2,
        "name": "唐山市",
        "pcode": 130000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 1254,
        "code": 130300000000,
        "level": 2,
        "name": "秦皇岛市",
        "pcode": 130000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 1369,
        "code": 130400000000,
        "level": 2,
        "name": "邯郸市",
        "pcode": 130000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 1638,
        "code": 130500000000,
        "level": 2,
        "name": "邢台市",
        "pcode": 130000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 1860,
        "code": 130600000000,
        "level": 2,
        "name": "保定市",
        "pcode": 130000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 2241,
        "code": 130700000000,
        "level": 2,
        "name": "张家口市",
        "pcode": 130000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 2507,
        "code": 130800000000,
        "level": 2,
        "name": "承德市",
        "pcode": 130000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 2742,
        "code": 130900000000,
        "level": 2,
        "name": "沧州市",
        "pcode": 130000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 2972,
        "code": 131000000000,
        "level": 2,
        "name": "廊坊市",
        "pcode": 130000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 3108,
        "code": 131100000000,
        "level": 2,
        "name": "衡水市",
        "pcode": 130000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 3252,
        "code": 140100000000,
        "level": 2,
        "name": "太原市",
        "pcode": 140000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 3377,
        "code": 140200000000,
        "level": 2,
        "name": "大同市",
        "pcode": 140000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 3533,
        "code": 140300000000,
        "level": 2,
        "name": "阳泉市",
        "pcode": 140000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 3585,
        "code": 140400000000,
        "level": 2,
        "name": "长治市",
        "pcode": 140000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 3768,
        "code": 140500000000,
        "level": 2,
        "name": "晋城市",
        "pcode": 140000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 3860,
        "code": 140600000000,
        "level": 2,
        "name": "朔州市",
        "pcode": 140000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 3948,
        "code": 140700000000,
        "level": 2,
        "name": "晋中市",
        "pcode": 140000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 4113,
        "code": 140800000000,
        "level": 2,
        "name": "运城市",
        "pcode": 140000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 4294,
        "code": 140900000000,
        "level": 2,
        "name": "忻州市",
        "pcode": 140000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 4515,
        "code": 141000000000,
        "level": 2,
        "name": "临汾市",
        "pcode": 140000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 4707,
        "code": 141100000000,
        "level": 2,
        "name": "吕梁市",
        "pcode": 140000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 4888,
        "code": 150100000000,
        "level": 2,
        "name": "呼和浩特市",
        "pcode": 150000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 4990,
        "code": 150200000000,
        "level": 2,
        "name": "包头市",
        "pcode": 150000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 5093,
        "code": 150300000000,
        "level": 2,
        "name": "乌海市",
        "pcode": 150000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 5120,
        "code": 150400000000,
        "level": 2,
        "name": "赤峰市",
        "pcode": 150000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 5333,
        "code": 150500000000,
        "level": 2,
        "name": "通辽市",
        "pcode": 150000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 5487,
        "code": 150600000000,
        "level": 2,
        "name": "鄂尔多斯市",
        "pcode": 150000000000,
        "child": [],
        "first": "E"
    },
    {
        "city_id": 5593,
        "code": 150700000000,
        "level": 2,
        "name": "呼伦贝尔市",
        "pcode": 150000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 5772,
        "code": 150800000000,
        "level": 2,
        "name": "巴彦淖尔市",
        "pcode": 150000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 5870,
        "code": 150900000000,
        "level": 2,
        "name": "乌兰察布市",
        "pcode": 150000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 5994,
        "code": 152200000000,
        "level": 2,
        "name": "兴安盟",
        "pcode": 150000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 6090,
        "code": 152500000000,
        "level": 2,
        "name": "锡林郭勒盟",
        "pcode": 150000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 6206,
        "code": 152900000000,
        "level": 2,
        "name": "阿拉善盟",
        "pcode": 150000000000,
        "child": [],
        "first": "A"
    },
    {
        "city_id": 6250,
        "code": 210100000000,
        "level": 2,
        "name": "沈阳市",
        "pcode": 210000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 6457,
        "code": 210200000000,
        "level": 2,
        "name": "大连市",
        "pcode": 210000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 6621,
        "code": 210300000000,
        "level": 2,
        "name": "鞍山市",
        "pcode": 210000000000,
        "child": [],
        "first": "A"
    },
    {
        "city_id": 6728,
        "code": 210400000000,
        "level": 2,
        "name": "抚顺市",
        "pcode": 210000000000,
        "child": [],
        "first": "F"
    },
    {
        "city_id": 6809,
        "code": 210500000000,
        "level": 2,
        "name": "本溪市",
        "pcode": 210000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 6866,
        "code": 210600000000,
        "level": 2,
        "name": "丹东市",
        "pcode": 210000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 6964,
        "code": 210700000000,
        "level": 2,
        "name": "锦州市",
        "pcode": 210000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 7077,
        "code": 210800000000,
        "level": 2,
        "name": "营口市",
        "pcode": 210000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 7155,
        "code": 210900000000,
        "level": 2,
        "name": "阜新市",
        "pcode": 210000000000,
        "child": [],
        "first": "F"
    },
    {
        "city_id": 7248,
        "code": 211000000000,
        "level": 2,
        "name": "辽阳市",
        "pcode": 210000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 7307,
        "code": 211100000000,
        "level": 2,
        "name": "盘锦市",
        "pcode": 210000000000,
        "child": [],
        "first": "P"
    },
    {
        "city_id": 7364,
        "code": 211200000000,
        "level": 2,
        "name": "铁岭市",
        "pcode": 210000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 7477,
        "code": 211300000000,
        "level": 2,
        "name": "朝阳市",
        "pcode": 210000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 7649,
        "code": 211400000000,
        "level": 2,
        "name": "葫芦岛市",
        "pcode": 210000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 7785,
        "code": 220100000000,
        "level": 2,
        "name": "长春市",
        "pcode": 220000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 8021,
        "code": 220200000000,
        "level": 2,
        "name": "吉林市",
        "pcode": 220000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 8185,
        "code": 220300000000,
        "level": 2,
        "name": "四平市",
        "pcode": 220000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 8271,
        "code": 220400000000,
        "level": 2,
        "name": "辽源市",
        "pcode": 220000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 8325,
        "code": 220500000000,
        "level": 2,
        "name": "通化市",
        "pcode": 220000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 8440,
        "code": 220600000000,
        "level": 2,
        "name": "白山市",
        "pcode": 220000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 8515,
        "code": 220700000000,
        "level": 2,
        "name": "松原市",
        "pcode": 220000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 8663,
        "code": 220800000000,
        "level": 2,
        "name": "白城市",
        "pcode": 220000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 8794,
        "code": 222400000000,
        "level": 2,
        "name": "延边朝鲜族自治州",
        "pcode": 220000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 8920,
        "code": 230100000000,
        "level": 2,
        "name": "哈尔滨市",
        "pcode": 230000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 9274,
        "code": 230200000000,
        "level": 2,
        "name": "齐齐哈尔市",
        "pcode": 230000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 9502,
        "code": 230300000000,
        "level": 2,
        "name": "鸡西市",
        "pcode": 230000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 9618,
        "code": 230400000000,
        "level": 2,
        "name": "鹤岗市",
        "pcode": 230000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 9700,
        "code": 230500000000,
        "level": 2,
        "name": "双鸭山市",
        "pcode": 230000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 9824,
        "code": 230600000000,
        "level": 2,
        "name": "大庆市",
        "pcode": 230000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 9949,
        "code": 230700000000,
        "level": 2,
        "name": "伊春市",
        "pcode": 230000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 10013,
        "code": 230800000000,
        "level": 2,
        "name": "佳木斯市",
        "pcode": 230000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 10162,
        "code": 230900000000,
        "level": 2,
        "name": "七台河市",
        "pcode": 230000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 10214,
        "code": 231000000000,
        "level": 2,
        "name": "牡丹江市",
        "pcode": 230000000000,
        "child": [],
        "first": "M"
    },
    {
        "city_id": 10319,
        "code": 231100000000,
        "level": 2,
        "name": "黑河市",
        "pcode": 230000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 10488,
        "code": 231200000000,
        "level": 2,
        "name": "绥化市",
        "pcode": 230000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 10748,
        "code": 232700000000,
        "level": 2,
        "name": "大兴安岭地区",
        "pcode": 230000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 10800,
        "code": 310100000000,
        "level": 2,
        "name": "上海市",
        "pcode": 310000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 11052,
        "code": 320100000000,
        "level": 2,
        "name": "南京市",
        "pcode": 320000000000,
        "child": [],
        "first": "N"
    },
    {
        "city_id": 11208,
        "code": 320200000000,
        "level": 2,
        "name": "无锡市",
        "pcode": 320000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 11311,
        "code": 320300000000,
        "level": 2,
        "name": "徐州市",
        "pcode": 320000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 11509,
        "code": 320400000000,
        "level": 2,
        "name": "常州市",
        "pcode": 320000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 11583,
        "code": 320500000000,
        "level": 2,
        "name": "苏州市",
        "pcode": 320000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 11719,
        "code": 320600000000,
        "level": 2,
        "name": "南通市",
        "pcode": 320000000000,
        "child": [],
        "first": "N"
    },
    {
        "city_id": 11866,
        "code": 320700000000,
        "level": 2,
        "name": "连云港市",
        "pcode": 320000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 11984,
        "code": 320800000000,
        "level": 2,
        "name": "淮安市",
        "pcode": 320000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 12109,
        "code": 320900000000,
        "level": 2,
        "name": "盐城市",
        "pcode": 320000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 12275,
        "code": 321000000000,
        "level": 2,
        "name": "扬州市",
        "pcode": 320000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 12383,
        "code": 321100000000,
        "level": 2,
        "name": "镇江市",
        "pcode": 320000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 12452,
        "code": 321200000000,
        "level": 2,
        "name": "泰州市",
        "pcode": 320000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 12573,
        "code": 321300000000,
        "level": 2,
        "name": "宿迁市",
        "pcode": 320000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 12706,
        "code": 330100000000,
        "level": 2,
        "name": "杭州市",
        "pcode": 330000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 12917,
        "code": 330200000000,
        "level": 2,
        "name": "宁波市",
        "pcode": 330000000000,
        "child": [],
        "first": "N"
    },
    {
        "city_id": 13089,
        "code": 330300000000,
        "level": 2,
        "name": "温州市",
        "pcode": 330000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 13294,
        "code": 330400000000,
        "level": 2,
        "name": "嘉兴市",
        "pcode": 330000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 13375,
        "code": 330500000000,
        "level": 2,
        "name": "湖州市",
        "pcode": 330000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 13453,
        "code": 330600000000,
        "level": 2,
        "name": "绍兴市",
        "pcode": 330000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 13564,
        "code": 330700000000,
        "level": 2,
        "name": "金华市",
        "pcode": 330000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 13722,
        "code": 330800000000,
        "level": 2,
        "name": "衢州市",
        "pcode": 330000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 13830,
        "code": 330900000000,
        "level": 2,
        "name": "舟山市",
        "pcode": 330000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 13872,
        "code": 331000000000,
        "level": 2,
        "name": "台州市",
        "pcode": 330000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 14019,
        "code": 331100000000,
        "level": 2,
        "name": "丽水市",
        "pcode": 330000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 14204,
        "code": 340100000000,
        "level": 2,
        "name": "合肥市",
        "pcode": 340000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 14369,
        "code": 340200000000,
        "level": 2,
        "name": "芜湖市",
        "pcode": 340000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 14461,
        "code": 340300000000,
        "level": 2,
        "name": "蚌埠市",
        "pcode": 340000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 14555,
        "code": 340400000000,
        "level": 2,
        "name": "淮南市",
        "pcode": 340000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 14659,
        "code": 340500000000,
        "level": 2,
        "name": "马鞍山市",
        "pcode": 340000000000,
        "child": [],
        "first": "M"
    },
    {
        "city_id": 14727,
        "code": 340600000000,
        "level": 2,
        "name": "淮北市",
        "pcode": 340000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 14770,
        "code": 340700000000,
        "level": 2,
        "name": "铜陵市",
        "pcode": 340000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 14839,
        "code": 340800000000,
        "level": 2,
        "name": "安庆市",
        "pcode": 340000000000,
        "child": [],
        "first": "A"
    },
    {
        "city_id": 15016,
        "code": 341000000000,
        "level": 2,
        "name": "黄山市",
        "pcode": 340000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 15134,
        "code": 341100000000,
        "level": 2,
        "name": "滁州市",
        "pcode": 340000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 15269,
        "code": 341200000000,
        "level": 2,
        "name": "阜阳市",
        "pcode": 340000000000,
        "child": [],
        "first": "F"
    },
    {
        "city_id": 15452,
        "code": 341300000000,
        "level": 2,
        "name": "宿州市",
        "pcode": 340000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 15578,
        "code": 341500000000,
        "level": 2,
        "name": "六安市",
        "pcode": 340000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 15734,
        "code": 341600000000,
        "level": 2,
        "name": "亳州市",
        "pcode": 340000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 15834,
        "code": 341700000000,
        "level": 2,
        "name": "池州市",
        "pcode": 340000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 15906,
        "code": 341800000000,
        "level": 2,
        "name": "宣城市",
        "pcode": 340000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 16018,
        "code": 350100000000,
        "level": 2,
        "name": "福州市",
        "pcode": 350000000000,
        "child": [],
        "first": "F"
    },
    {
        "city_id": 16227,
        "code": 350200000000,
        "level": 2,
        "name": "厦门市",
        "pcode": 350000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 16282,
        "code": 350300000000,
        "level": 2,
        "name": "莆田市",
        "pcode": 350000000000,
        "child": [],
        "first": "P"
    },
    {
        "city_id": 16346,
        "code": 350400000000,
        "level": 2,
        "name": "三明市",
        "pcode": 350000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 16508,
        "code": 350500000000,
        "level": 2,
        "name": "泉州市",
        "pcode": 350000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 16692,
        "code": 350600000000,
        "level": 2,
        "name": "漳州市",
        "pcode": 350000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 16852,
        "code": 350700000000,
        "level": 2,
        "name": "南平市",
        "pcode": 350000000000,
        "child": [],
        "first": "N"
    },
    {
        "city_id": 17003,
        "code": 350800000000,
        "level": 2,
        "name": "龙岩市",
        "pcode": 350000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 17146,
        "code": 350900000000,
        "level": 2,
        "name": "宁德市",
        "pcode": 350000000000,
        "child": [],
        "first": "N"
    },
    {
        "city_id": 17288,
        "code": 360100000000,
        "level": 2,
        "name": "南昌市",
        "pcode": 360000000000,
        "child": [],
        "first": "N"
    },
    {
        "city_id": 17438,
        "code": 360200000000,
        "level": 2,
        "name": "景德镇市",
        "pcode": 360000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 17503,
        "code": 360300000000,
        "level": 2,
        "name": "萍乡市",
        "pcode": 360000000000,
        "child": [],
        "first": "P"
    },
    {
        "city_id": 17566,
        "code": 360400000000,
        "level": 2,
        "name": "九江市",
        "pcode": 360000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 17811,
        "code": 360500000000,
        "level": 2,
        "name": "新余市",
        "pcode": 360000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 17849,
        "code": 360600000000,
        "level": 2,
        "name": "鹰潭市",
        "pcode": 360000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 17916,
        "code": 360700000000,
        "level": 2,
        "name": "赣州市",
        "pcode": 360000000000,
        "child": [],
        "first": "G"
    },
    {
        "city_id": 18245,
        "code": 360800000000,
        "level": 2,
        "name": "吉安市",
        "pcode": 360000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 18512,
        "code": 360900000000,
        "level": 2,
        "name": "宜春市",
        "pcode": 360000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 18745,
        "code": 361000000000,
        "level": 2,
        "name": "抚州市",
        "pcode": 360000000000,
        "child": [],
        "first": "F"
    },
    {
        "city_id": 18939,
        "code": 361100000000,
        "level": 2,
        "name": "上饶市",
        "pcode": 360000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 19182,
        "code": 370100000000,
        "level": 2,
        "name": "济南市",
        "pcode": 370000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 19363,
        "code": 370200000000,
        "level": 2,
        "name": "青岛市",
        "pcode": 370000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 19522,
        "code": 370300000000,
        "level": 2,
        "name": "淄博市",
        "pcode": 370000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 19620,
        "code": 370400000000,
        "level": 2,
        "name": "枣庄市",
        "pcode": 370000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 19693,
        "code": 370500000000,
        "level": 2,
        "name": "东营市",
        "pcode": 370000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 19754,
        "code": 370600000000,
        "level": 2,
        "name": "烟台市",
        "pcode": 370000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 19929,
        "code": 370700000000,
        "level": 2,
        "name": "潍坊市",
        "pcode": 370000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 20066,
        "code": 370800000000,
        "level": 2,
        "name": "济宁市",
        "pcode": 370000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 20238,
        "code": 370900000000,
        "level": 2,
        "name": "泰安市",
        "pcode": 370000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 20334,
        "code": 371000000000,
        "level": 2,
        "name": "威海市",
        "pcode": 370000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 20418,
        "code": 371100000000,
        "level": 2,
        "name": "日照市",
        "pcode": 370000000000,
        "child": [],
        "first": "R"
    },
    {
        "city_id": 20481,
        "code": 371300000000,
        "level": 2,
        "name": "临沂市",
        "pcode": 370000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 20652,
        "code": 371400000000,
        "level": 2,
        "name": "德州市",
        "pcode": 370000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 20801,
        "code": 371500000000,
        "level": 2,
        "name": "聊城市",
        "pcode": 370000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 20947,
        "code": 371600000000,
        "level": 2,
        "name": "滨州市",
        "pcode": 370000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 21047,
        "code": 371700000000,
        "level": 2,
        "name": "菏泽市",
        "pcode": 370000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 21228,
        "code": 410100000000,
        "level": 2,
        "name": "郑州市",
        "pcode": 410000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 21452,
        "code": 410200000000,
        "level": 2,
        "name": "开封市",
        "pcode": 410000000000,
        "child": [],
        "first": "K"
    },
    {
        "city_id": 21589,
        "code": 410300000000,
        "level": 2,
        "name": "洛阳市",
        "pcode": 410000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 21801,
        "code": 410400000000,
        "level": 2,
        "name": "平顶山市",
        "pcode": 410000000000,
        "child": [],
        "first": "P"
    },
    {
        "city_id": 21963,
        "code": 410500000000,
        "level": 2,
        "name": "安阳市",
        "pcode": 410000000000,
        "child": [],
        "first": "A"
    },
    {
        "city_id": 22112,
        "code": 410600000000,
        "level": 2,
        "name": "鹤壁市",
        "pcode": 410000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 22168,
        "code": 410700000000,
        "level": 2,
        "name": "新乡市",
        "pcode": 410000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 22355,
        "code": 410800000000,
        "level": 2,
        "name": "焦作市",
        "pcode": 410000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 22480,
        "code": 410900000000,
        "level": 2,
        "name": "濮阳市",
        "pcode": 410000000000,
        "child": [],
        "first": "P"
    },
    {
        "city_id": 22579,
        "code": 411000000000,
        "level": 2,
        "name": "许昌市",
        "pcode": 410000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 22693,
        "code": 411100000000,
        "level": 2,
        "name": "漯河市",
        "pcode": 410000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 22759,
        "code": 411200000000,
        "level": 2,
        "name": "三门峡市",
        "pcode": 410000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 22849,
        "code": 411300000000,
        "level": 2,
        "name": "南阳市",
        "pcode": 410000000000,
        "child": [],
        "first": "N"
    },
    {
        "city_id": 23121,
        "code": 411400000000,
        "level": 2,
        "name": "商丘市",
        "pcode": 410000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 23331,
        "code": 411500000000,
        "level": 2,
        "name": "信阳市",
        "pcode": 410000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 23568,
        "code": 411600000000,
        "level": 2,
        "name": "周口市",
        "pcode": 410000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 23788,
        "code": 411700000000,
        "level": 2,
        "name": "驻马店市",
        "pcode": 410000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 24008,
        "code": 419000000000,
        "level": 2,
        "name": "省直辖县级行政区划",
        "pcode": 410000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 24027,
        "code": 420100000000,
        "level": 2,
        "name": "武汉市",
        "pcode": 420000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 24240,
        "code": 420200000000,
        "level": 2,
        "name": "黄石市",
        "pcode": 420000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 24305,
        "code": 420300000000,
        "level": 2,
        "name": "十堰市",
        "pcode": 420000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 24452,
        "code": 420500000000,
        "level": 2,
        "name": "宜昌市",
        "pcode": 420000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 24580,
        "code": 420600000000,
        "level": 2,
        "name": "襄阳市",
        "pcode": 420000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 24719,
        "code": 420700000000,
        "level": 2,
        "name": "鄂州市",
        "pcode": 420000000000,
        "child": [],
        "first": "E"
    },
    {
        "city_id": 24752,
        "code": 420800000000,
        "level": 2,
        "name": "荆门市",
        "pcode": 420000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 24839,
        "code": 420900000000,
        "level": 2,
        "name": "孝感市",
        "pcode": 420000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 24976,
        "code": 421000000000,
        "level": 2,
        "name": "荆州市",
        "pcode": 420000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 25116,
        "code": 421100000000,
        "level": 2,
        "name": "黄冈市",
        "pcode": 420000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 25297,
        "code": 421200000000,
        "level": 2,
        "name": "咸宁市",
        "pcode": 420000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 25394,
        "code": 421300000000,
        "level": 2,
        "name": "随州市",
        "pcode": 420000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 25450,
        "code": 422800000000,
        "level": 2,
        "name": "恩施土家族苗族自治州",
        "pcode": 420000000000,
        "child": [],
        "first": "E"
    },
    {
        "city_id": 25551,
        "code": 429000000000,
        "level": 2,
        "name": "省直辖县级行政区划",
        "pcode": 420000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 25642,
        "code": 430100000000,
        "level": 2,
        "name": "长沙市",
        "pcode": 430000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 25825,
        "code": 430200000000,
        "level": 2,
        "name": "株洲市",
        "pcode": 430000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 25947,
        "code": 430300000000,
        "level": 2,
        "name": "湘潭市",
        "pcode": 430000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 26027,
        "code": 430400000000,
        "level": 2,
        "name": "衡阳市",
        "pcode": 430000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 26237,
        "code": 430500000000,
        "level": 2,
        "name": "邵阳市",
        "pcode": 430000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 26457,
        "code": 430600000000,
        "level": 2,
        "name": "岳阳市",
        "pcode": 430000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 26607,
        "code": 430700000000,
        "level": 2,
        "name": "常德市",
        "pcode": 430000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 26797,
        "code": 430800000000,
        "level": 2,
        "name": "张家界市",
        "pcode": 430000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 26876,
        "code": 430900000000,
        "level": 2,
        "name": "益阳市",
        "pcode": 430000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 26984,
        "code": 431000000000,
        "level": 2,
        "name": "郴州市",
        "pcode": 430000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 27159,
        "code": 431100000000,
        "level": 2,
        "name": "永州市",
        "pcode": 430000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 27369,
        "code": 431200000000,
        "level": 2,
        "name": "怀化市",
        "pcode": 430000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 27591,
        "code": 431300000000,
        "level": 2,
        "name": "娄底市",
        "pcode": 430000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 27687,
        "code": 433100000000,
        "level": 2,
        "name": "湘西土家族苗族自治州",
        "pcode": 430000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 27813,
        "code": 440100000000,
        "level": 2,
        "name": "广州市",
        "pcode": 440000000000,
        "child": [],
        "first": "G"
    },
    {
        "city_id": 28004,
        "code": 440200000000,
        "level": 2,
        "name": "韶关市",
        "pcode": 440000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 28128,
        "code": 440300000000,
        "level": 2,
        "name": "深圳市",
        "pcode": 440000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 28218,
        "code": 440400000000,
        "level": 2,
        "name": "珠海市",
        "pcode": 440000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 28254,
        "code": 440500000000,
        "level": 2,
        "name": "汕头市",
        "pcode": 440000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 28330,
        "code": 440600000000,
        "level": 2,
        "name": "佛山市",
        "pcode": 440000000000,
        "child": [],
        "first": "F"
    },
    {
        "city_id": 28378,
        "code": 440700000000,
        "level": 2,
        "name": "江门市",
        "pcode": 440000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 28469,
        "code": 440800000000,
        "level": 2,
        "name": "湛江市",
        "pcode": 440000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 28606,
        "code": 440900000000,
        "level": 2,
        "name": "茂名市",
        "pcode": 440000000000,
        "child": [],
        "first": "M"
    },
    {
        "city_id": 28739,
        "code": 441200000000,
        "level": 2,
        "name": "肇庆市",
        "pcode": 440000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 28855,
        "code": 441300000000,
        "level": 2,
        "name": "惠州市",
        "pcode": 440000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 28946,
        "code": 441400000000,
        "level": 2,
        "name": "梅州市",
        "pcode": 440000000000,
        "child": [],
        "first": "M"
    },
    {
        "city_id": 29074,
        "code": 441500000000,
        "level": 2,
        "name": "汕尾市",
        "pcode": 440000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 29144,
        "code": 441600000000,
        "level": 2,
        "name": "河源市",
        "pcode": 440000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 29253,
        "code": 441700000000,
        "level": 2,
        "name": "阳江市",
        "pcode": 440000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 29322,
        "code": 441800000000,
        "level": 2,
        "name": "清远市",
        "pcode": 440000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 29422,
        "code": 441900000000,
        "level": 2,
        "name": "东莞市",
        "pcode": 440000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 29458,
        "code": 442000000000,
        "level": 2,
        "name": "中山市",
        "pcode": 440000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 29483,
        "code": 445100000000,
        "level": 2,
        "name": "潮州市",
        "pcode": 440000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 29546,
        "code": 445200000000,
        "level": 2,
        "name": "揭阳市",
        "pcode": 440000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 29662,
        "code": 445300000000,
        "level": 2,
        "name": "云浮市",
        "pcode": 440000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 29741,
        "code": 450100000000,
        "level": 2,
        "name": "南宁市",
        "pcode": 450000000000,
        "child": [],
        "first": "N"
    },
    {
        "city_id": 29893,
        "code": 450200000000,
        "level": 2,
        "name": "柳州市",
        "pcode": 450000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 30023,
        "code": 450300000000,
        "level": 2,
        "name": "桂林市",
        "pcode": 450000000000,
        "child": [],
        "first": "G"
    },
    {
        "city_id": 30190,
        "code": 450400000000,
        "level": 2,
        "name": "梧州市",
        "pcode": 450000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 30265,
        "code": 450500000000,
        "level": 2,
        "name": "北海市",
        "pcode": 450000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 30301,
        "code": 450600000000,
        "level": 2,
        "name": "防城港市",
        "pcode": 450000000000,
        "child": [],
        "first": "F"
    },
    {
        "city_id": 30338,
        "code": 450700000000,
        "level": 2,
        "name": "钦州市",
        "pcode": 450000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 30415,
        "code": 450800000000,
        "level": 2,
        "name": "贵港市",
        "pcode": 450000000000,
        "child": [],
        "first": "G"
    },
    {
        "city_id": 30496,
        "code": 450900000000,
        "level": 2,
        "name": "玉林市",
        "pcode": 450000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 30615,
        "code": 451000000000,
        "level": 2,
        "name": "百色市",
        "pcode": 450000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 30766,
        "code": 451100000000,
        "level": 2,
        "name": "贺州市",
        "pcode": 450000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 30834,
        "code": 451200000000,
        "level": 2,
        "name": "河池市",
        "pcode": 450000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 30986,
        "code": 451300000000,
        "level": 2,
        "name": "来宾市",
        "pcode": 450000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 31065,
        "code": 451400000000,
        "level": 2,
        "name": "崇左市",
        "pcode": 450000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 31160,
        "code": 460100000000,
        "level": 2,
        "name": "海口市",
        "pcode": 460000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 31213,
        "code": 460200000000,
        "level": 2,
        "name": "三亚市",
        "pcode": 460000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 31223,
        "code": 460300000000,
        "level": 2,
        "name": "三沙市",
        "pcode": 460000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 31232,
        "code": 460400000000,
        "level": 2,
        "name": "儋州市",
        "pcode": 460000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 31251,
        "code": 469000000000,
        "level": 2,
        "name": "省直辖县级行政区划",
        "pcode": 460000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 31438,
        "code": 500100000000,
        "level": 2,
        "name": "重庆市",
        "pcode": 500000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 32114,
        "code": 500200000000,
        "level": 2,
        "name": "辖区县",
        "pcode": 500000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 32508,
        "code": 510100000000,
        "level": 2,
        "name": "成都市",
        "pcode": 510000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 32791,
        "code": 510300000000,
        "level": 2,
        "name": "自贡市",
        "pcode": 510000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 32889,
        "code": 510400000000,
        "level": 2,
        "name": "攀枝花市",
        "pcode": 510000000000,
        "child": [],
        "first": "P"
    },
    {
        "city_id": 32945,
        "code": 510500000000,
        "level": 2,
        "name": "泸州市",
        "pcode": 510000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 33080,
        "code": 510600000000,
        "level": 2,
        "name": "德阳市",
        "pcode": 510000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 33172,
        "code": 510700000000,
        "level": 2,
        "name": "绵阳市",
        "pcode": 510000000000,
        "child": [],
        "first": "M"
    },
    {
        "city_id": 33350,
        "code": 510800000000,
        "level": 2,
        "name": "广元市",
        "pcode": 510000000000,
        "child": [],
        "first": "G"
    },
    {
        "city_id": 33505,
        "code": 510900000000,
        "level": 2,
        "name": "遂宁市",
        "pcode": 510000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 33607,
        "code": 511000000000,
        "level": 2,
        "name": "内江市",
        "pcode": 510000000000,
        "child": [],
        "first": "N"
    },
    {
        "city_id": 33698,
        "code": 511100000000,
        "level": 2,
        "name": "乐山市",
        "pcode": 510000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 33843,
        "code": 511300000000,
        "level": 2,
        "name": "南充市",
        "pcode": 510000000000,
        "child": [],
        "first": "N"
    },
    {
        "city_id": 34096,
        "code": 511400000000,
        "level": 2,
        "name": "眉山市",
        "pcode": 510000000000,
        "child": [],
        "first": "M"
    },
    {
        "city_id": 34184,
        "code": 511500000000,
        "level": 2,
        "name": "宜宾市",
        "pcode": 510000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 34332,
        "code": 511600000000,
        "level": 2,
        "name": "广安市",
        "pcode": 510000000000,
        "child": [],
        "first": "G"
    },
    {
        "city_id": 34464,
        "code": 511700000000,
        "level": 2,
        "name": "达州市",
        "pcode": 510000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 34674,
        "code": 511800000000,
        "level": 2,
        "name": "雅安市",
        "pcode": 510000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 34780,
        "code": 511900000000,
        "level": 2,
        "name": "巴中市",
        "pcode": 510000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 34927,
        "code": 512000000000,
        "level": 2,
        "name": "资阳市",
        "pcode": 510000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 35022,
        "code": 513200000000,
        "level": 2,
        "name": "阿坝藏族羌族自治州",
        "pcode": 510000000000,
        "child": [],
        "first": "A"
    },
    {
        "city_id": 35214,
        "code": 513300000000,
        "level": 2,
        "name": "甘孜藏族自治州",
        "pcode": 510000000000,
        "child": [],
        "first": "G"
    },
    {
        "city_id": 35522,
        "code": 513400000000,
        "level": 2,
        "name": "凉山彝族自治州",
        "pcode": 510000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 35974,
        "code": 520100000000,
        "level": 2,
        "name": "贵阳市",
        "pcode": 520000000000,
        "child": [],
        "first": "G"
    },
    {
        "city_id": 36132,
        "code": 520200000000,
        "level": 2,
        "name": "六盘水市",
        "pcode": 520000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 36229,
        "code": 520300000000,
        "level": 2,
        "name": "遵义市",
        "pcode": 520000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 36498,
        "code": 520400000000,
        "level": 2,
        "name": "安顺市",
        "pcode": 520000000000,
        "child": [],
        "first": "A"
    },
    {
        "city_id": 36598,
        "code": 520500000000,
        "level": 2,
        "name": "毕节市",
        "pcode": 520000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 36886,
        "code": 520600000000,
        "level": 2,
        "name": "铜仁市",
        "pcode": 520000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 37078,
        "code": 522300000000,
        "level": 2,
        "name": "黔西南布依族苗族自治州",
        "pcode": 520000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 37228,
        "code": 522600000000,
        "level": 2,
        "name": "黔东南苗族侗族自治州",
        "pcode": 520000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 37460,
        "code": 522700000000,
        "level": 2,
        "name": "黔南布依族苗族自治州",
        "pcode": 520000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 37583,
        "code": 530100000000,
        "level": 2,
        "name": "昆明市",
        "pcode": 530000000000,
        "child": [],
        "first": "K"
    },
    {
        "city_id": 37746,
        "code": 530300000000,
        "level": 2,
        "name": "曲靖市",
        "pcode": 530000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 37895,
        "code": 530400000000,
        "level": 2,
        "name": "玉溪市",
        "pcode": 530000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 37981,
        "code": 530500000000,
        "level": 2,
        "name": "保山市",
        "pcode": 530000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 38065,
        "code": 530600000000,
        "level": 2,
        "name": "昭通市",
        "pcode": 530000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 38224,
        "code": 530700000000,
        "level": 2,
        "name": "丽江市",
        "pcode": 530000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 38296,
        "code": 530800000000,
        "level": 2,
        "name": "普洱市",
        "pcode": 530000000000,
        "child": [],
        "first": "P"
    },
    {
        "city_id": 38411,
        "code": 530900000000,
        "level": 2,
        "name": "临沧市",
        "pcode": 530000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 38505,
        "code": 532300000000,
        "level": 2,
        "name": "楚雄彝族自治州",
        "pcode": 530000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 38619,
        "code": 532500000000,
        "level": 2,
        "name": "红河哈尼族彝族自治州",
        "pcode": 530000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 38768,
        "code": 532600000000,
        "level": 2,
        "name": "文山壮族苗族自治州",
        "pcode": 530000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 38884,
        "code": 532800000000,
        "level": 2,
        "name": "西双版纳傣族自治州",
        "pcode": 530000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 38934,
        "code": 532900000000,
        "level": 2,
        "name": "大理白族自治州",
        "pcode": 530000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 39059,
        "code": 533100000000,
        "level": 2,
        "name": "德宏傣族景颇族自治州",
        "pcode": 530000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 39121,
        "code": 533300000000,
        "level": 2,
        "name": "怒江傈僳族自治州",
        "pcode": 530000000000,
        "child": [],
        "first": "N"
    },
    {
        "city_id": 39155,
        "code": 533400000000,
        "level": 2,
        "name": "迪庆藏族自治州",
        "pcode": 530000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 39190,
        "code": 540100000000,
        "level": 2,
        "name": "拉萨市",
        "pcode": 540000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 39273,
        "code": 540200000000,
        "level": 2,
        "name": "日喀则市",
        "pcode": 540000000000,
        "child": [],
        "first": "R"
    },
    {
        "city_id": 39496,
        "code": 540300000000,
        "level": 2,
        "name": "昌都市",
        "pcode": 540000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 39646,
        "code": 540400000000,
        "level": 2,
        "name": "林芝市",
        "pcode": 540000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 39710,
        "code": 540500000000,
        "level": 2,
        "name": "山南市",
        "pcode": 540000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 39807,
        "code": 540600000000,
        "level": 2,
        "name": "那曲市",
        "pcode": 540000000000,
        "child": [],
        "first": "N"
    },
    {
        "city_id": 39933,
        "code": 542500000000,
        "level": 2,
        "name": "阿里地区",
        "pcode": 540000000000,
        "child": [],
        "first": "A"
    },
    {
        "city_id": 39979,
        "code": 610100000000,
        "level": 2,
        "name": "西安市",
        "pcode": 610000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 40166,
        "code": 610200000000,
        "level": 2,
        "name": "铜川市",
        "pcode": 610000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 40210,
        "code": 610300000000,
        "level": 2,
        "name": "宝鸡市",
        "pcode": 610000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 40344,
        "code": 610400000000,
        "level": 2,
        "name": "咸阳市",
        "pcode": 610000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 40502,
        "code": 610500000000,
        "level": 2,
        "name": "渭南市",
        "pcode": 610000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 40651,
        "code": 610600000000,
        "level": 2,
        "name": "延安市",
        "pcode": 610000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 40780,
        "code": 610700000000,
        "level": 2,
        "name": "汉中市",
        "pcode": 610000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 40971,
        "code": 610800000000,
        "level": 2,
        "name": "榆林市",
        "pcode": 610000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 41169,
        "code": 610900000000,
        "level": 2,
        "name": "安康市",
        "pcode": 610000000000,
        "child": [],
        "first": "A"
    },
    {
        "city_id": 41320,
        "code": 611000000000,
        "level": 2,
        "name": "商洛市",
        "pcode": 610000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 41440,
        "code": 620100000000,
        "level": 2,
        "name": "兰州市",
        "pcode": 620000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 41567,
        "code": 620200000000,
        "level": 2,
        "name": "嘉峪关市",
        "pcode": 620000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 41574,
        "code": 620300000000,
        "level": 2,
        "name": "金昌市",
        "pcode": 620000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 41596,
        "code": 620400000000,
        "level": 2,
        "name": "白银市",
        "pcode": 620000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 41681,
        "code": 620500000000,
        "level": 2,
        "name": "天水市",
        "pcode": 620000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 41820,
        "code": 620600000000,
        "level": 2,
        "name": "武威市",
        "pcode": 620000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 41932,
        "code": 620700000000,
        "level": 2,
        "name": "张掖市",
        "pcode": 620000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 42018,
        "code": 620800000000,
        "level": 2,
        "name": "平凉市",
        "pcode": 620000000000,
        "child": [],
        "first": "P"
    },
    {
        "city_id": 42142,
        "code": 620900000000,
        "level": 2,
        "name": "酒泉市",
        "pcode": 620000000000,
        "child": [],
        "first": "J"
    },
    {
        "city_id": 42235,
        "code": 621000000000,
        "level": 2,
        "name": "庆阳市",
        "pcode": 620000000000,
        "child": [],
        "first": "Q"
    },
    {
        "city_id": 42364,
        "code": 621100000000,
        "level": 2,
        "name": "定西市",
        "pcode": 620000000000,
        "child": [],
        "first": "D"
    },
    {
        "city_id": 42495,
        "code": 621200000000,
        "level": 2,
        "name": "陇南市",
        "pcode": 620000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 42705,
        "code": 622900000000,
        "level": 2,
        "name": "临夏回族自治州",
        "pcode": 620000000000,
        "child": [],
        "first": "L"
    },
    {
        "city_id": 42845,
        "code": 623000000000,
        "level": 2,
        "name": "甘南藏族自治州",
        "pcode": 620000000000,
        "child": [],
        "first": "G"
    },
    {
        "city_id": 42957,
        "code": 630100000000,
        "level": 2,
        "name": "西宁市",
        "pcode": 630000000000,
        "child": [],
        "first": "X"
    },
    {
        "city_id": 43046,
        "code": 630200000000,
        "level": 2,
        "name": "海东市",
        "pcode": 630000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 43150,
        "code": 632200000000,
        "level": 2,
        "name": "海北藏族自治州",
        "pcode": 630000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 43187,
        "code": 632300000000,
        "level": 2,
        "name": "黄南藏族自治州",
        "pcode": 630000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 43225,
        "code": 632500000000,
        "level": 2,
        "name": "海南藏族自治州",
        "pcode": 630000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 43272,
        "code": 632600000000,
        "level": 2,
        "name": "果洛藏族自治州",
        "pcode": 630000000000,
        "child": [],
        "first": "G"
    },
    {
        "city_id": 43323,
        "code": 632700000000,
        "level": 2,
        "name": "玉树藏族自治州",
        "pcode": 630000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 43379,
        "code": 632800000000,
        "level": 2,
        "name": "海西蒙古族藏族自治州",
        "pcode": 630000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 43434,
        "code": 640100000000,
        "level": 2,
        "name": "银川市",
        "pcode": 640000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 43502,
        "code": 640200000000,
        "level": 2,
        "name": "石嘴山市",
        "pcode": 640000000000,
        "child": [],
        "first": "S"
    },
    {
        "city_id": 43544,
        "code": 640300000000,
        "level": 2,
        "name": "吴忠市",
        "pcode": 640000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 43601,
        "code": 640400000000,
        "level": 2,
        "name": "固原市",
        "pcode": 640000000000,
        "child": [],
        "first": "G"
    },
    {
        "city_id": 43673,
        "code": 640500000000,
        "level": 2,
        "name": "中卫市",
        "pcode": 640000000000,
        "child": [],
        "first": "Z"
    },
    {
        "city_id": 43725,
        "code": 650100000000,
        "level": 2,
        "name": "乌鲁木齐市",
        "pcode": 650000000000,
        "child": [],
        "first": "W"
    },
    {
        "city_id": 43865,
        "code": 650200000000,
        "level": 2,
        "name": "克拉玛依市",
        "pcode": 650000000000,
        "child": [],
        "first": "K"
    },
    {
        "city_id": 43890,
        "code": 650400000000,
        "level": 2,
        "name": "吐鲁番市",
        "pcode": 650000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 43929,
        "code": 650500000000,
        "level": 2,
        "name": "哈密市",
        "pcode": 650000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 43992,
        "code": 652300000000,
        "level": 2,
        "name": "昌吉回族自治州",
        "pcode": 650000000000,
        "child": [],
        "first": "C"
    },
    {
        "city_id": 44106,
        "code": 652700000000,
        "level": 2,
        "name": "博尔塔拉蒙古自治州",
        "pcode": 650000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 44146,
        "code": 652800000000,
        "level": 2,
        "name": "巴音郭楞蒙古自治州",
        "pcode": 650000000000,
        "child": [],
        "first": "B"
    },
    {
        "city_id": 44269,
        "code": 652900000000,
        "level": 2,
        "name": "阿克苏地区",
        "pcode": 650000000000,
        "child": [],
        "first": "A"
    },
    {
        "city_id": 44391,
        "code": 653000000000,
        "level": 2,
        "name": "克孜勒苏柯尔克孜自治州",
        "pcode": 650000000000,
        "child": [],
        "first": "K"
    },
    {
        "city_id": 44442,
        "code": 653100000000,
        "level": 2,
        "name": "喀什地区",
        "pcode": 650000000000,
        "child": [],
        "first": "K"
    },
    {
        "city_id": 44667,
        "code": 653200000000,
        "level": 2,
        "name": "和田地区",
        "pcode": 650000000000,
        "child": [],
        "first": "H"
    },
    {
        "city_id": 44779,
        "code": 654000000000,
        "level": 2,
        "name": "伊犁哈萨克自治州",
        "pcode": 650000000000,
        "child": [],
        "first": "Y"
    },
    {
        "city_id": 44943,
        "code": 654200000000,
        "level": 2,
        "name": "塔城地区",
        "pcode": 650000000000,
        "child": [],
        "first": "T"
    },
    {
        "city_id": 45079,
        "code": 654300000000,
        "level": 2,
        "name": "阿勒泰地区",
        "pcode": 650000000000,
        "child": [],
        "first": "A"
    },
    {
        "city_id": 45157,
        "code": 659000000000,
        "level": 2,
        "name": "自治区直辖县级行政区划",
        "pcode": 650000000000,
        "child": [],
        "first": "Z"
    }
  ]
  export default bar_address