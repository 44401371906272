<template>
  <div class="container">
    <span v-if="bind==true">绑定成功</span>
    <span v-else-if="bind ==false">绑定失败</span>
  </div>
</template>

<script>
import { WorkerBand } from '@/api/hotel.js'
export default {
  data () {
    return {
      url: null,
      code: null,
      state: null,
      bind: true,
    }
  },
  created () {
    this.getUrl()
    this.WorkerBand()

  },
  mounted () {
  },
  methods: {
    WorkerBand () {
      let data = {
        code: this.code,
        state: this.state
      }
      console.log(data, "要传递的");
      WorkerBand(data).then(res => {
        console.log('成了');
        console.log(res);
        if (res.data.data.message == "绑定成功！") {
          this.bind = true
        } else {
          this.bind = false
        }
        // res.data.message == '绑定成功!'
      })
    },
    getUrl () {
      this.url = window.location.href.split('#')[0]
      let splitArr = this.url.split('?')
      let newArr = splitArr[1].split('=')
      this.state = newArr[2]
      console.log(newArr);
      let code = newArr[1].split('&')
      this.code = code[0]
    }
  },
}
</script>

<style >
</style>