import {
  request
} from './request.js'
import qs from 'qs'

export function postLogin(method, datas) {
  return request({
    url: '/agent/account-info',
    method,
    data: qs.stringify(datas)
  })
}

// 代理商渠道的公众号授权登录
export function postWeachatLogin(params) {
  return request({
    url: '/agent/wechat-login-by-code',
    method: 'POST',
    params
  })
}

// 微信支付酒店
export function payWechatHotel(params) {
  return request({
    url: '/home/user/prepay',
    method: 'POST',
    params
  })
}
export function payWalletHotel(params) {
  return request({
    url: '/home/hotel/pay-order',
    method: 'POST',
    params
  })
}
export function QRcode(params) {
  return request({
    url: '/home/hotel/store/share',
    method: 'GET',
    params
  })
}

// 酒店首页
// 酒店列表
// export function getHomeData (params) {
//   return request({
//     url: '/agent/hotel/list',
//     method: 'POST',
//     params
//   })
// }

export function getHomeData(params) {
  return request({
    url: '/home/hotel',
    method: 'GET',
    params
  })
}
//酒店详情
export function getHotelDetail(params) {
  return request({
    url: '/agent/hotel/details',
    method: 'POST',
    params
  })
}

//酒店房型根据room_id或price_id获取价格
export function getRoomDatePrice(params) {
  return request({
    url: '/agent/hotel/date-price',
    method: 'POST',
    params
  })
}

//酒店房型提交订单
export function submitHotelOrder(params) {
  return request({
    url: '/home/hotel/order/submit',
    method: 'POST',
    params
  })
}
// export function submitHotelOrder (params) {
//   return request({
//     url: '/agent/hotel/sub-order',
//     method: 'POST',
//     params
//   })
// }
//酒店查看订单
// export function getHotelOrderList (params) {
//   return request({
//     url: '/agent/hotel/order-list',
//     method: 'POST',
//     params
//   })
// }
export function getHotelOrderList(params) {
  return request({
    url: '/home/hotel/order/list',
    method: 'GET',
    params
  })
}
//酒店支付订单
export function payHotelOrder(params) {
  return request({
    url: '/home/hotel/pay-order',
    method: 'POST',
    params
  })
}

//酒店订单详情
export function getHotelOrderDetail(params) {
  return request({
    url: '/agent/hotel/order-details',
    method: 'POST',
    params
  })
}

//取消酒店订单
export function cancelHotelOrder(params) {
  return request({
    url: '/agent/hotel/cancel-order',
    method: 'POST',
    params
  })
}

//酒店订单申请退款
export function refundHotelOrder(params) {
  return request({
    url: '/agent/hotel/refund-order',
    method: 'POST',
    params
  })
}
// 查询订单支付状态
export function checkPayStatus(params) {
  return request({
    url: '/home/user/check-pay-status',
    method: 'POST',
    params
  })
}

// 查询订单支付状态
export function getRoom_theme() {
  return request({
    url: '/common/room_theme',
    method: 'GET',
  })
}
//登录并绑定订单通知
export function WorkerBand(params) {
  return request({
    url: '/home/user/hotel-pms/bind-notification',
    method: 'PUT',
    params
  })
}
// 评论订单
export function commentOrder(params) {
  return request({
    url: '/home/hotel/comment-order',
    method: 'POST',
    params
  })
}
export function Attract(params) {
  return request({
    url: '/home/user/record',
    method: 'PUT',
    params
  })
}