<template>
  <div id="Screen">
    <van-index-bar :index-list="indexList"
                   :sticky='false'
                   v-model="screenShow">
      <!-- 热门筛选 -->
      <van-index-anchor v-for="(item) in screen1"
                        :key="item.id"
                        :index='item.title'
                        class="screenData">
        <div>
          <span style="font-size:0.21rem;margin-left:0.1rem">
            {{ item.title }}
            <van-icon name="arrow-down"
                      style="float: right;" />
          </span>
        </div>
        <div class="priceBtn">
          <van-row type="flex">
            <van-col span='7'
                     v-for="(item2, index2) in item.data"
                     :key="index2"
                     :class="item2.isclick == true ? 'is_type_active' : ''"
                     @click="getScreen1(item2,index2)">{{ item2.content }}
              <div v-if="item2.isclick"
                   class="checked">
                <img src="../assets/HotelImg/is_active.png"
                     alt="">
              </div>
            </van-col>
          </van-row>
        </div>
      </van-index-anchor>
      <!-- 酒店类型 -->
      <van-index-anchor v-for="(item) in screen2"
                        :key="item.id"
                        :index='item.title'
                        class="screenData">
        <div>
          <span style="font-size:0.21rem;margin-left:0.1rem">
            {{ item.title }}
            <van-icon name="arrow-down"
                      style="float: right;" />
          </span>
        </div>
        <div class="priceBtn">
          <van-row type="flex">
            <van-col span='7'
                     v-for="(item2, index2) in item.data"
                     :key="index2"
                     :class="item2.isclick == true ? 'is_type_active' : ''"
                     @click="getScreen2(item2)">{{ item2.content }}
              <div v-if="item2.isclick"
                   class="checked">
                <img src="../assets/HotelImg/is_active.png"
                     alt="">
              </div>
            </van-col>
          </van-row>
        </div>
      </van-index-anchor>
      <!--  品牌-->
      <van-index-anchor v-for="(item) in screen3"
                        :key="item.id"
                        :index='item.title'
                        class="screenData">
        <div>
          <span style="font-size:0.21rem;margin-left:0.1rem">
            {{ item.title }}
            <van-icon name="arrow-down"
                      style="float: right;" />
          </span>
        </div>
        <div class="priceBtn">
          <van-row type="flex">
            <van-col span='7'
                     v-for="(item2, index2) in item.data"
                     :key="index2"
                     :class="item2.isclick == true ? 'is_type_active' : ''"
                     @click="getScreen3(item2)">{{ item2.content }}
              <div v-if="item2.isclick"
                   class="checked">
                <img src="../assets/HotelImg/is_active.png"
                     alt="">
              </div>
            </van-col>
          </van-row>
        </div>
      </van-index-anchor>
      <!--  房型类型-->
      <van-index-anchor v-for="(item) in screen4"
                        :key="item.id"
                        :index='item.title'
                        class="screenData">
        <div>
          <span style="font-size:0.21rem;margin-left:0.1rem">
            {{ item.title }}
            <van-icon name="arrow-down"
                      style="float: right;" />
          </span>
        </div>
        <div class="priceBtn">
          <van-row type="flex">
            <van-col span='7'
                     v-for="(item2, index2) in item.data"
                     :key="index2"
                     :class="item2.isclick == true ? 'is_type_active' : ''"
                     @click="getScreen4(item2)">{{ item2.content }}
              <div v-if="item2.isclick"
                   class="checked">
                <img src="../assets/HotelImg/is_active.png"
                     alt="">
              </div>
            </van-col>
          </van-row>
        </div>
      </van-index-anchor>
      <!-- 特色房型 -->
      <van-index-anchor v-for="(item) in screen5"
                        :key="item.id"
                        :index='item.title'
                        class="screenData">
        <div>
          <span style="font-size:0.21rem;margin-left:0.1rem">
            {{ item.title }}
            <van-icon name="arrow-down"
                      style="float: right;" />
          </span>
        </div>
        <div class="priceBtn">
          <van-row type="flex">
            <van-col span='7'
                     v-for="(item2, index2) in item.data"
                     :key="index2"
                     :class="item2.isclick == true ? 'is_type_active' : ''"
                     @click="getScreen5(item2)">{{ item2.content }}
              <div v-if="item2.isclick"
                   class="checked">
                <img src="../assets/HotelImg/is_active.png"
                     alt="">
              </div>
            </van-col>
          </van-row>
        </div>
      </van-index-anchor>
      <!-- 早餐 -->
      <van-index-anchor v-for="(item) in screen6"
                        :key="item.id"
                        :index='item.title'
                        class="screenData">
        <div>
          <span style="font-size:0.21rem;margin-left:0.1rem">
            {{ item.title }}
            <van-icon name="arrow-down"
                      style="float: right;" />
          </span>
        </div>
        <div class="priceBtn">
          <van-row type="flex">
            <van-col span='7'
                     id="bt1"
                     v-for="(item2, index2) in item.data"
                     :key="index2"
                     :class="item2.isclick == true ? 'is_type_active' : ''"
                     @click="getScreen6(item2, index2)">{{ item2.content }}
              <div v-if="item2.isclick"
                   class="checked">
                <img src="../assets/HotelImg/is_active.png"
                     alt="">
              </div>
            </van-col>
          </van-row>
        </div>
      </van-index-anchor>
    </van-index-bar>
    <div class="bottom_btn">
      <van-button type="primary"
                  size="large"
                  plain
                  style="color:#36e0c9;
                      border:none;
                      border-top:1px solid #f6f6f6">重置</van-button>
      <van-button type="primary"
                  size="large"
                  color="#36e0c9"
                  @click="getRoom">完成</van-button>
    </div>
  </div>
</template>
<script>
import { getHomeData } from '@/api/hotel.js'
// import { aesKey } from '../commom/aesKey'
export default {
  props: {
    screenShow: Boolean,

  },
  data () {
    return {

      screenHotel: null,
      cityInfo: null,
      roomType: [],
      indexList: [
        "热门筛选", "酒店类型", "品牌", "房型类型", "特色房型", "早餐"
      ],//用于存放indexBar的索引
      screen1: [{
        title: '热门筛选',
        data: [
          {
            content: '情侣约会',
            isclick: false
          },
          {
            content: '电竞房',
            isclick: false
          },
          {
            content: '大床房',
            isclick: false
          },
          {
            content: '棋牌室',
            isclick: false
          },
          {
            content: '酒店公寓',
            isclick: false
          },
        ]
      }],
      screen2: [
        {
          title: '酒店类型',
          data: [
            {
              content: '酒店',
              isclick: false
            },
            {
              content: '酒店公寓',
              isclick: false
            },
            {
              content: '民宿',
              isclick: false
            },
            {
              content: '特色住宿',
              isclick: false
            },
            {
              content: '别墅',
              isclick: false
            },
          ]
        },
      ],
      screen3: [
        {
          title: '品牌',
          data: [
            {
              content: '凯悦酒店',
              isclick: false
            },
            {
              content: '高端连锁',
              isclick: false
            },
            {
              content: '铂尔曼',
              isclick: false
            },
          ]
        },
      ],
      screen4: [
        {
          title: '房型类型',
          data: [
            {
              content: '双床',
              isclick: false
            },
            {
              content: '多床',
              isclick: false
            },
            {
              content: '大床',
              isclick: false
            },
            {
              content: '单人床',
              isclick: false
            },
          ]
        },
      ],

      screen5: [
        {
          title: '特色房型',
          data: [
            {
              content: '钟点房',
              isclick: false
            },
            {
              content: '亲子房',
              isclick: false
            },
            {
              content: '酒店套餐',
              isclick: false
            }
          ]
        },
      ],
      screen6: [
        {
          title: '早餐',
          data: [
            {
              content: '所有 ',
              isclick: false
            },
            {
              content: '无早',
              isclick: false
            },
            {
              content: '多份',
              isclick: false
            }
          ]
        },
      ],

      search: [],//总搜索（酒店名称/标签）
      brand: [],//品牌名称
      meals: null,//早餐 空:所有 0:无早 1:一份早餐 2:多份
      is_true: 0
    }
  },
  created () {

  },
  methods: {
    getRoom () {
      console.log(this.meals);
      let brand = this.brand.toString()
      let roomType = this.roomType.toString()
      let search = this.search.toString()
      this.cityInfo = JSON.parse(sessionStorage.getItem('sessionCityData'))
      this.dateArray = sessionStorage.getItem("dateArray")
      let Hoteldate = this.dateArray.split(",")
      let start_date = Hoteldate[0].substring(0, Hoteldate[0].length - 3);
      let end_date = Hoteldate[1].substring(0, Hoteldate[1].length - 3);
      let data = {
        start_date: start_date,
        end_date: end_date,
        meals: this.meals,

        brand: brand,
        room_layout: roomType,
        city_id: this.cityInfo.city_id,
        search: search,
        // page: 1,
        status: 1
      }
      console.log(data);
      getHomeData(data).then(res => {
        console.log(res);
        this.screenHotel = res.data.data.list
        this.$emit('changehotel', this.screenHotel)
        console.log(this.screenHotel);
      })
      this.screenShow = false
    },
    //热门筛选按钮被点击
    getScreen1 (data, index2) {
      this.search = data.content
      console.log(this.search, "this.search==");
      console.log(data, index2, "123123");
      for (let i = 0; i < this.screen1[0].data.length; i++) {
        this.screen1[0].data.isclick = false
      }
      this.screen1.filter(item => {
        item.data.filter(item => {
          if (item.isclick == true) {
            item.isclick = false
          }
          this.screen1[0].data[index2].isclick = true
        })
      })
    },
    //酒店类型按钮被点击
    getScreen2 (data) {
      console.log("酒店类型按钮", data);
    },
    //品牌按钮被点击
    getScreen3 (data) {
      if (data.isclick == false) {
        this.brand = this.brand.concat(data.content)
        data.isclick = true
      } else if (data.isclick == true) {
        this.brand.find(item => {
          if (data.content == item) {
            let index = this.brand.indexOf(data.content)
            this.brand.splice(index, 1)
          }
        })
        data.isclick = false
      }
      console.log(this.brand.toString(), "总");
    },
    // 房型类型
    getScreen4 (data) {
      if (data.isclick == false) {
        this.roomType = this.roomType.concat(data.content)
        data.isclick = true
      } else if (data.isclick == true) {
        this.roomType.find(item => {
          if (data.content == item) {
            let index = this.roomType.indexOf(data.content)
            this.roomType.splice(index, 1)
          }
        })
        data.isclick = false
      }
    },
    //特色房型
    getScreen5 (data) {
      if (data.isclick == false) {
        this.roomType = this.roomType.concat(data.content)
        data.isclick = true
      } else if (data.isclick == true) {
        this.roomType.find(item => {
          if (data.content == item) {
            let index = this.roomType.indexOf(data.content)
            this.roomType.splice(index, 1)
          }
        })
        data.isclick = false
      }
    },
    //早餐
    getScreen6 (data, index2) {
      this.meals = index2
      console.log(this.meals, "早餐");
      console.log(data, index2, "123123");
      for (let i = 0; i < this.screen6[0].data.length; i++) {
        this.screen6[0].data.isclick = false
      }
      this.screen6.filter(item => {
        item.data.filter(item => {
          if (item.isclick == true) {
            item.isclick = false
          }
          this.screen6[0].data[index2].isclick = true
        })
      })
    },
  }
}
</script>
<style lang="less" scoped>
#Screen {
  .screenData {
    // background-color: red;
    margin-left: 1.3rem;
  }

  /deep/.van-index-bar__sidebar {
    width: 18%;
    left: 0%;
    top: 50%;
    height: 65%;
    background-color: #f7f8fa;

    .van-index-bar__index {
      font-size: 0.2rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-right: inherit;
      text-align: left;
    }

    .van-index-bar__index--active {
      color: #24c5af;
      background-color: white;
      font-weight: bold;
    }
  }

  /deep/.van-index-anchor {
    // padding-bottom: 40px;
    // background-color: aqua;
    padding-left: 0.2rem;

    .van-icon {
      // margin-right: 0.5rem;
      line-height: 31px;
    }
  }

  /deep/.van-index-anchor-last {
    padding-bottom: 40px;
    // background-color: aqua;
    padding-left: 0.2rem;
  }

  .priceBtn {
    margin-bottom: 0.3rem;

    .van-col {
      position: relative;
      background-color: #f6f6f6;
      margin-left: 0.1rem;
      text-align: center;
      padding: 0.1rem 0;
      font-size: 0.2rem;
      margin-top: 0.1rem;
      border-radius: 5px;
      width: 31.5%;
    }

    .van-col.is_type_active {
      background-color: #cdf7f2;
      color: #36e0c9;
      font-weight: bold;

      .checked {
        position: absolute;
        bottom: -25%;
        right: 0%;
        width: 20%;
      }

      img {
        width: 100%;
      }
    }
  }
}

.bottom_btn {
  position: fixed;
  z-index: 200;
  bottom: 12%;

  .van-button {
    width: 3.75rem;
  }
}
</style>