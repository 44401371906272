<template>
  <div>
    <van-action-sheet v-model="Actionshow"
                      v-if="WhichOne == 1"
                      @cancel="cancel"
                      @click-overlay="clickOverlay">
      <div class="content1">
        <!-- <van-icon name="clear"
                  @click="closeAction" /> -->
        <img class="bt"
             @click="clickOverlay"
             src="../assets/Esports/bt.png"
             alt="" />
        <div class="top">
          <van-swipe class="my-swipe"
                     :autoplay="0"
                     :show-indicators="false"
                     indicator-color="white">
            <van-swipe-item v-for="item in roomIndexData.images"
                            :key="item.id">
              <img :src="item"
                   alt="" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="detail">
          <!-- <img style="width:100%; display: block; "
               src="../assets/HotelImg/wutu1.jpg"
               alt=""> -->
          <div class="configuration"
               v-if="PCshow">
            <span class="configuration_name">
              {{ roomIndexData.title }}</span>

            <vueSeamless :data="configuration"
                         :class-option="optionLeft"
                         class="seamless-warp2">
              <ul class="item">
                <li v-for="item in configuration"
                    :key="item.name">
                  <span class="image">
                    <img style="width:.5556rem"
                         :src="item.src"
                         alt="" /></span>

                  <span class="text"> {{ item.text }}</span>
                </li>
              </ul>
            </vueSeamless>
          </div>
          <div style="background-color:white">
            <div class="title"
                 style="padding-top:.3704rem"
                 v-if="showPC">{{ roomIndexData.title }}</div>
            <div class="iconDetail">
              <van-row type="flex"
                       justify="space-between">
                <van-col span="7"
                         v-if="roomIndexData.bed_num">
                  <img src="../assets/HotelImg/bed_icon.png"
                       alt="" />
                  <span>{{ roomIndexData.bed_num }}张床</span>
                </van-col>
                <van-col span="7"
                         v-if="roomIndexData.room_size">
                  <img src="../assets/HotelImg/area_icon.png"
                       alt="" />
                  <span>{{ roomIndexData.room_size }}m²</span>
                </van-col>
                <van-col span="7"
                         v-if="roomIndexData.floor">
                  <img src="../assets/HotelImg/floor_icon.png"
                       alt="" />
                  <span>{{ roomIndexData.floor }}层</span>
                </van-col>
                <van-col span="7"
                         v-if="roomIndexData.window">
                  <img src="../assets/HotelImg/window_icon.png"
                       alt="" />
                  <span>{{ roomIndexData.window }}</span>
                </van-col>
                <van-col span="7"
                         v-if="roomIndexData.internet">
                  <img src="../assets/HotelImg/wifi_Icon.png"
                       alt="" />
                  <span>{{ roomIndexData.internet }}</span>
                </van-col>
                <van-col span="7"
                         v-if="roomIndexData.smoking">
                  <img src="../assets/HotelImg/nosmoke_icon.png"
                       alt="" />
                  <span>{{ roomIndexData.smoking }}</span>
                </van-col>
                <van-col span="7"
                         v-if="roomIndexData.live">
                  <img src="../assets/HotelImg/rec_icon.png"
                       alt="" />
                  <span>{{ roomIndexData.live }}人入住</span>
                </van-col>
                <!-- 早餐 -->
                <van-col span="7"
                         v-if="roomIndexData.meals">
                  <img src="../assets/HotelImg/meal_Icon.png"
                       alt="" />
                  <span>{{ roomIndexData.meals }}</span>
                </van-col>
                <!--    = == = = = = = = = = = = = = = = = == = = = = = = = = = = = = = = = -->

                <van-col span="7"
                         v-if="roomIndexData.meals">
                  <img src="../assets/HotelImg/meal_Icon.png"
                       alt="" />
                  <span>{{ roomIndexData.meals }}</span>
                </van-col>
              </van-row>
            </div>
            <div v-if="roomIndexData.facility">
              <div class="fac"
                   v-if="roomIndexData.facility.length">
                <div class="smTip"
                     v-for="fac in roomIndexData.facility"
                     :key="fac.id">
                  <div class="TipD">
                    <span class="left">{{ fac.name }}</span>
                    <div class="right">
                      <span v-for="item in fac.data"
                            :key="item.id">{{ item }},</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="roomIndexData.facility">
              <div class="btn"
                   v-if="roomIndexData.facility.length != 0">
                <button @click="openMore(isMore)"
                        v-if="isMore">
                  收起
                  <van-icon name="arrow-up" />
                </button>
                <button @click="openMore(isMore)"
                        v-else>
                  查看更多
                  <van-icon name="arrow-down" />
                </button>
              </div>
            </div>
          </div>
          <div class="homePoil"
               v-if="roomIndexData.entry_departure_time">
            <div class="title">房型政策</div>
            <div style="padding-left:0.3rem;margin-top:0.2rem">
              <div style="font-size:0.26rem;font-weight:bold">入离时间</div>
              <div style="font-size:0.22rem;color: gray;margin-top:0.1rem;width:6.5rem">
                {{ roomIndexData.entry_departure_time }}
              </div>
            </div>
            <div style="padding-left:0.3rem;margin-top:0.2rem">
              <div style="font-size:0.26rem;font-weight:bold">押金政策</div>
              <div style="font-size:0.22rem;color: gray;margin-top:0.1rem;width:6.5rem">
                {{ roomIndexData.deposit }}
              </div>
            </div>
            <div style="padding-left:0.3rem;margin-top:0.2rem">
              <div style="font-size:0.26rem;font-weight:bold">用餐说明</div>
              <div style="font-size:0.22rem;color: gray;margin-top:0.1rem;width:6.5rem">
                {{ roomIndexData.meal_explanation }}
              </div>
            </div>
            <div style="padding-left:0.3rem;margin-top:0.2rem">
              <div style="font-size:0.26rem;font-weight:bold">宠物政策</div>
              <div style="font-size:0.22rem;color: gray;margin-top:0.1rem;width:6.5rem">
                {{ roomIndexData.pets }}
              </div>
            </div>
            <div style="padding-left:0.3rem;margin-top:0.2rem">
              <div style="font-size:0.26rem;font-weight:bold">退订政策</div>
              <div style="font-size:0.22rem;color: gray;margin-top:0.1rem;width:6.5rem">
                {{ roomIndexData.unsubscribe }}
              </div>
            </div>
            <div style="padding-left:0.3rem;margin-top:0.2rem">
              <div style="font-size:0.26rem;font-weight:bold">加床政策</div>
              <div style="font-size:0.22rem;color: gray;margin-top:0.1rem;width:6.5rem">
                {{ roomIndexData.add_beds }}
              </div>
            </div>
          </div>
          <div class="disOffer">
            <div class="title">优惠说明</div>
            <div style="padding-left:0.3rem">
              <van-tag plain
                       type="primary"
                       color="#24C5AF">免费兑取消权益</van-tag>
              <div style="font-size:0.22rem;color: gray;margin-top:0.1rem;width:6.5rem">
                兑换即可享受此单入住，在入住当天20点免费取消订单权益
              </div>
            </div>
          </div>
          <div style="margin-top:0.85rem">&nbsp;</div>
          <div class="pay_bottom"
               v-if="showBottom">
            <div>
              <span @click="getOrderId(index)"
                    class="word">
                <span style="color:gray;font-size:0.2rem">新客特惠价</span>
                <span v-if="PCshow == false"
                      style="color:#FD7644;margin-left:0.1rem;font-size:0.4rem;font-weight:bold">
                  <span style="font-size:0.1rem">￥</span>{{ roomIndexData.sales_price }}</span>
                <span v-if="PCshow"
                      style="color:#A02176;margin-left:0.1rem;font-size:0.4rem;font-weight:bold">
                  <span style="font-size:0.1rem">￥</span>{{ roomIndexData.datePrice }}</span>
              </span>
              <span @click="goPay"
                    v-if="PCshow == false"
                    class="image">
                <img src="../assets/HotelImg/pay_img.png"
                     alt="" />
              </span>
              <span @click="goPay"
                    v-if="PCshow"
                    class="image">
                <img src="../assets/HotelImg/pay_img_esprots.png"
                     alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="Actionshow"
                      @cancel="cancel"
                      @click-overlay="clickOverlay"
                      v-if="WhichOne == 2"
                      title="订房必读">
      <div class="content2">
        <div class="thinner-border"></div>
        <div class="detail">
          <!-- <div class="smTip">
            <div class="TipD">
              <span class="left">酒店提示</span>
              <div class="right">
                <div>酒店所有房型可以加床，加床收费230/张。</div>
              </div>
            </div>
            <div class="TipD">
              <span class="left">预订提示</span>
              <div class="right">
                <div>订单需等酒店或者供应商确认后才生效，订单确认结
                  果以短信或邮件通知为准；请在订单生效后再至酒店
                  前台办理入住</div>
              </div>
            </div>
            <div class="TipD">
              <span class="left">入离时间</span>
              <div class="right">
                <div>入住时间14:00以后 离店时间12:00以前</div>
              </div>
            </div>
            <div class="TipD">
              <span class="left">膳食安排</span>
              <div class="right">
                <div>西式、中式早餐 RMB148</div>
              </div>
            </div>
            <div class="TipD">
              <span class="left">宠物携带</span>
              <div class="right">
                <div>不允许携带宠物</div>
              </div>
            </div>
          </div> -->
          <div v-html="roomIndexData.buy_notice"></div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="Actionshow"
                      v-if="WhichOne == 3"
                      @cancel="cancel"
                      @click-overlay="clickOverlay"
                      title="预计到店时间">
      <div class="content3">
        <div class="top">14:00前到店可能需要等房</div>
        <div class="thinner-border"></div>
        <div class="time">
          <div v-for="(item, index) in timeChoice"
               :key="item.id"
               :class="item.is_click == true ? 'is_active' : ''"
               @click="getTime(item, index)">
            {{ item.text }}
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="Actionshow"
                      v-if="WhichOne == 4"
                      @cancel="cancel"
                      @click-overlay="clickOverlay">
      <div class="content4Top">
        <van-nav-bar :title="selectTitle">
          <template #left>
            <span @click="cancel">取消</span>
          </template>
          <template #right>
            <span @click="emitName">完成</span>
          </template>
        </van-nav-bar>
      </div>
      <div class="content4">
        <div style="position: relative;margin-top:0.2rem"
             v-for="(item, index) in peopleName"
             :key="item.id">
          <van-checkbox v-model="item.checked"
                        checked-color="#24C5AF" />
          <div class="name">
            <div @click="getCheck(item)">{{ item.name }}</div>
            <img src="../assets/HotelImg/edit_icon.png"
                 alt=""
                 @click="editName(item, index)" />
          </div>
        </div>
        <div class="bottom">
          <van-button type="primary"
                      plain
                      size="large"
                      @click="addPassenger"><span style="font-size: 0.3rem;">+</span>手动增加</van-button>
        </div>
      </div>
    </van-action-sheet>
    <!-- 添加房间数量 -->
    <van-action-sheet v-model="Actionshow"
                      v-if="WhichOne == 5"
                      @cancel="cancel"
                      @click-overlay="clickOverlay"
                      title="选择房间数量">
      <div class="content3">
        <div class="top">14:00前到店可能需要等房</div>
        <div class="thinner-border"></div>
        <div class="time">
          <div v-for="(item, index) in roomNum"
               :key="item.id"
               :class="item.is_click == true ? 'is_active' : ''"
               @click="getRoomNum(item, index)">
            {{ item.text }}
          </div>
        </div>
      </div>
    </van-action-sheet>
    <div class="addSheet">
      <van-action-sheet v-model="addshow"
                        :round="false">
        <!-- <NavBar :NavBarTitle='NavBarTitle'
                @onClickLeft='onClickLeft'></NavBar> -->
        <div class="content">
          <div style="position: relative;">
            <van-form validate-first
                      v-if="ChorEn">
              <!-- 通过 pattern 进行正则校验 -->
              <van-field v-model="ChName"
                         label="中文姓名"
                         name="pattern"
                         placeholder="输入中文姓名"
                         :rules="[
                {
                  pattern: /^[\u4e00-\u9fa5]{2,8}/,
                  message: '请输入正确中文姓名'
                }
              ]" />
            </van-form>
            <div v-else>
              <van-form validate-first>
                <!-- 通过 pattern 进行正则校验 -->
                <div class="EnglishNa">
                  <span style="float:left">
                    <div>英文姓氏</div>
                    <div>Surname</div>
                  </span>
                  <van-field v-model="Surname"
                             name="pattern"
                             placeholder="例如:Han"
                             :rules="[
                    {
                      pattern: /^[a-zA-Z]{2,50}/,
                      message: '请输入正确英文姓氏'
                    }
                  ]">
                  </van-field>
                </div>
                <div class="EnglishNa">
                  <span style="float:left">
                    <div>英文名</div>
                    <div>Given names</div>
                  </span>
                  <van-field v-model="GivenNames"
                             name="pattern"
                             placeholder="例:Meimei"
                             :rules="[
                    {
                      pattern: /^[a-zA-Z]{2,50}/,
                      message: '请输入正确英文姓名'
                    }
                  ]">
                  </van-field>
                </div>
              </van-form>
            </div>

            <span id="switch"
                  :class="ChorEn == false ? 'switchEnName' : ''">
              <div v-if="ChorEn"
                   @click="swichName(1)">
                <span class="is_active">中</span>
                <span>英</span>
              </div>
              <div v-else
                   @click="swichName(2)">
                <span>中</span>
                <span class="is_active">英</span>
              </div>
            </span>
            <div class="bottom">
              <div v-if="this.type == 'add'">
                <van-button round
                            type="info"
                            @click="getName">完成</van-button>
              </div>
              <div v-else
                   class="delBtn">
                <van-button type="primary"
                            round
                            style="color:#37e0c9;background-color: white;"
                            @click="delName">删除
                </van-button>
                <van-button type="primary"
                            round
                            @click="getName">完成</van-button>
              </div>
            </div>
          </div>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
// import NavBar from './NavBar.vue'
import { ImagePreview } from 'vant'
import vueSeamless from 'vue-seamless-scroll'

export default {
  components: {
    // NavBar
    vueSeamless: vueSeamless
  },
  computed: {
    optionSwitch () {
      return {
        autoPlay: false,
        switchSingleStep: 100,
        switchDelay: 200,
      }
    },
    optionLeft () {
      return {
        direction: 2,
        limitMoveNum: 1,
        step: 0,
        autoPlay: false,
        switchSingleStep: 140
        // limitMoveNum: 40,
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 40,
        // waitTime: 2500 // 单行停顿时间(singleHeight,waitTime)
      }
    }
  },
  watch: {
    roomIndexData: {
      handler () {
        console.log(this.roomIndexData.computer_config, "this.roomIndexData.computer_config");
        if (this.roomIndexData.computer_config != null) {

          this.roomIndexData.computer_config
          let computer_config = []
          for (let key in this.roomIndexData.computer_config) {
            computer_config.push(this.roomIndexData.computer_config[key])
          }

          for (let item in this.configuration) {
            this.configuration[item].text = computer_config[item]
          }
          // this.configuration.forEach(item => {
          //   item.text = computer_config[item]
          // })
          console.log(this.configuration);
          sessionStorage.setItem('configuration', JSON.stringify(this.configuration))
          this.PCshow = true
          this.showPC = false
        } else if (this.roomIndexData.computer_config == null) {
          console.log(this.roomIndexData.computer_config)
          this.showPC = true

          this.PCshow = false
        }
      }
    }
    //  roomIndexData: {
    //   handler () {
    //     if (this.roomIndexData.computer_config != null) {
    //       this.roomIndexData.computer_config
    //       let computer_config = []
    //       for (let key in this.roomIndexData.computer_config) {
    //         computer_config.push(this.roomIndexData.computer_config[key])
    //       }
    //       let computer_config_temp = {}
    //       for (let item of computer_config) {
    //         computer_config_temp[item.name] = item.value
    //       }

    //       this.configuration.forEach(item => {
    //         item.text = computer_config_temp[item.name]
    //       })
    //       this.PCshow = true
    //     } else if (this.roomIndexData.computer_config == null) {
    //       console.log(this.roomIndexData.computer_config)

    //       this.PCshow = false
    //     }
    //   }
    // }
  },
  props: {
    Actionshow: Boolean,
    WhichOne: Number,
    roomIndexData: Object,
  },
  data () {
    return {
      showBottom: true,

      showPC: false,
      PCshow: false,
      configuration: [
        {
          id: 1,
          name: 'cpu',
          src: require('../assets/Esports/CPU.png'),
          text: 'i5 12400F CPU'
        },
        {
          id: 2,
          name: 'gpu',
          src: require('../assets/Esports/GPU.png'),
          text: 'GPU'
        },
        // 显示器
        {
          id: 3,
          name: 'dpi_logo',
          src: require('../assets/Esports/dpi_logo.png'),
          text: 'dpi_logo'
        },
        // 内存
        {
          id: 4,
          name: 'RAM',
          src: require('../assets/Esports/RAM.png'),
          text: 'RAM'
        },
        // 外设1
        {
          id: 5,
          name: 'headset',
          src: require('../assets/Esports/headset.png'),
          text: 'headset'
        },
        // 外设2
        {
          id: 6,
          name: 'keyboard',
          src: require('../assets/Esports/keyboard.png'),
          text: 'keyboard'
        },
        // 显示器分辨率
        {
          name: 'dpi',
          id: 7,
          src: require('../assets/Esports/dpi.png'),
          text: 'dpi'
        },
        // 显示器牌子
        {
          id: 8,
          name: 'crt',
          src: require('../assets/Esports/crt.png'),
          text: 'crt'
        },
        // 凳子
        {
          id: 9,
          src: require('../assets/Esports/chair.png'),
          text: 'chair'
        },
        // 显示器尺寸
        {
          id: 10,
          name: 'size',
          src: require('../assets/Esports/size.png'),
          text: 'size'
        },
        //显示器刷新率
        {
          id: 11,
          name: 'hz',
          src: require('../assets/Esports/hz.png'),
          text: 'hz'
        }
      ],
      newsList: [
        {
          title: 'A simple, seamless scrolling for Vue.js'
        },
        {
          title: 'A curated list of awesome things related to Vue.js'
        }
      ],
      isMore: false,
      img: [
        'https://ns-strategy.cdn.bcebos.com/ns-strategy/upload/fc_big_pic/part-00343-1487.jpg'
      ],
      today: '', //存放当天14:00时间的时间戳
      timeChoice: [
        {
          text: '14:00',
          is_click: false,
          timeStamp: ''
        },
        {
          text: '15:00',
          is_click: false,
          timeStamp: ''
        },
        {
          text: '16:00',
          is_click: false,
          timeStamp: ''
        },
        {
          text: '17:00',
          is_click: false,
          timeStamp: ''
        },
        {
          text: '18:00',
          is_click: false,
          timeStamp: ''
        },
        {
          text: '19:00',
          is_click: false,
          timeStamp: ''
        },
        {
          text: '20:00',
          is_click: false,
          timeStamp: ''
        },
        {
          text: '21:00',
          is_click: false,
          timeStamp: ''
        },
        {
          text: '22:00',
          is_click: false,
          timeStamp: ''
        },
        {
          text: '23:00',
          is_click: false,
          timeStamp: ''
        },
        {
          text: '0:00',
          is_click: false,
          timeStamp: ''
        }
      ],
      peopleName: [
        {
          name: '陈奕迅',
          checked: false,
          ChorEn: true
        },
        {
          name: '刘德华',
          checked: false,
          ChorEn: true
        },
        {
          name: 'Smith/leborm',
          checked: false,
          ChorEn: false
        }
      ],
      roomNum: [
        {
          text: '1间',
          is_click: false
        },
        {
          text: '2间',
          is_click: false
        },
        {
          text: '3间',
          is_click: false
        },
        {
          text: '4间',
          is_click: false
        },
        {
          text: '6间',
          is_click: false
        },
        {
          text: '7间',
          is_click: false
        },
        {
          text: '8间',
          is_click: false
        },
        {
          text: '9间',
          is_click: false
        },
        {
          text: '10间',
          is_click: false
        }
      ],
      addshow: false, //增加人的弹窗
      selectTitle: '已选1人',
      NavBarTitle: '新增常旅客',
      type: 'add', //新增还是编辑类型
      ChName: '', //中文名
      Surname: '', //英文姓氏
      GivenNames: '', //英文名
      editIndex: '', //用于存放修改那个人的下标
      ChorEn: true //填写的是中文名是还是英文名 true为中文，false为英文
    }
  },
  created () {
    this.today = new Date(new Date().toLocaleDateString()).getTime()
    this.today = this.today + 3600000 * 14
    // this.getComputer_config()
    this.route_path()
    // this.getConfigurationData()
  },
  mounted () {

  },
  methods: {
    // getConfigurationData () {
    //   if (this.$route.path == '/allHotel/HotelOrder') {
    //     console.log(123);
    //     this.configuration = JSON.parse(sessionStorage.getItem('configuration'))
    //     if (this.configuration != null) {
    //       this.PCshow = true

    //     } else {
    //       this.PCshow = false
    //     }
    //     // console.log(this.configuration, "configuration");
    //   }

    // },
    // 处理电脑配置数据
    getComputer_config () {
      console.log(this.roomIndexData)
      console.log(this.roomIndexData, '电脑配置数据')
    },
    //酒店订单
    getOrderId (index) {
      this.$emit('getOrderId', index)
      console.log(index, '==点击了下单')
    },
    // 详情页面下单
    goPay () {
      sessionStorage.setItem('refresh', 1)
      this.$router.push({ path: '/allHotel/HotelOrder' })
    },
    //关闭按钮
    cancel () {
      this.$emit('cancel')
    },
    //点击遮罩层
    clickOverlay () {
      this.$emit('clickOverlay')
    },
    //icon关闭按钮
    closeAction () {
      this.$emit('closeAction')
    },
    //点击收起，展开按钮
    openMore (isMore) {
      // console.log(1111);
      if (isMore == false) {
        this.isMore = true
        document.querySelector('.fac').style.height = 100 + '%'
      } else {
        this.isMore = false
        document.querySelector('.fac').style.height = 100 + 'px'
      }
      console.log(this.roomIndexData)
    },
    //预览照片
    getImg () {
      ImagePreview({
        images: this.img, //this.images为图片资源
        startPosition: 0
      })
    },
    //时间选择
    getTime (item, index) {
      this.timeChoice.forEach(item2 => {
        item2.is_click = false
      })
      item.is_click = true
      if (index == 0) {
        item.timeStamp = this.today
      } else {
        item.timeStamp = this.today + 3600000 * index
      }
      this.$emit('getTime', item.text, item.timeStamp)
    },
    // 获取房间数量
    getRoomNum (item, index) {
      this.roomNum.forEach(item2 => {
        item2.is_click = false
      })
      item.is_click = true
      if (index == 0) {
        item.roomNum = this.roomNum
      }
      this.$emit('getRoomNum', item.text)
    },
    //增加人数的按钮
    addPassenger () {
      this.addshow = true
      this.type = 'add'
      this.NavBarTitle = '新增常旅客'
    },
    //人名被点击
    getCheck (item) {
      let i = 0
      if (item.checked) {
        item.checked = false
      } else {
        item.checked = true
      }
      this.peopleName.forEach(item2 => {
        if (item2.checked == true) {
          i++
        }
      })
      this.selectTitle = '已选' + i + '人'
      // console.log(i)
    },
    //增加人数的弹窗上的返回按钮
    onClickLeft () {
      this.addshow = false
      //重置数据
      this.ChorEn = true
      this.ChName = '' //中文名
      this.Surname = '' //英文姓氏
      this.GivenNames = '' //英文名
    },
    //切换中英文
    swichName (index) {
      if (index == 1) {
        this.ChorEn = false
      } else if (index == 2) {
        this.ChorEn = true
      }
    },
    //获取姓名添加到数组peopleName
    getName () {
      if (this.type == 'add') {
        if (this.ChorEn) {
          if (this.ChName == '') {
            this.addshow = false
            return
          }
          this.peopleName.push({
            name: this.ChName,
            checked: false,
            ChorEn: true
          })
          this.ChName = ''
        } else if (this.ChorEn == false) {
          // console.log(this.ChorEn)
          if (this.Surname == '' || this.GivenNames == '') {
            this.addshow = false
            return
          }
          this.peopleName.push({
            name: this.Surname + '/' + this.GivenNames,
            checked: false,
            ChorEn: false
          })
          // console.log(this.peopleName)
          this.Surname = ''
          this.GivenNames = ''
        }
      } else if (this.type == 'edit') {
        if (this.ChorEn) {
          if (this.ChName == '') {
            this.addshow = false
            return
          }
          this.peopleName[this.editIndex].name = this.ChName
          // console.log(this.peopleName[this.editIndex])
          this.ChName = ''
        } else if (this.ChorEn == false) {
          // console.log(this.ChorEn)
          if (this.Surname == '' || this.GivenNames == '') {
            this.addshow = false
            return
          }
          // this.peopleName.push({
          //   name: this.Surname + '/' + this.GivenNames,
          //   checked: false,
          //   ChorEn: false
          // })
          this.peopleName[this.editIndex].name =
            this.Surname + '/' + this.GivenNames
          // console.log(this.peopleName)
          this.Surname = ''
          this.GivenNames = ''
        }
      }

      this.addshow = false
    },
    // 判断当前路由
    route_path () {
      if (this.$route.path == '/allHotel/HotelDatail') {
        this.showBottom = true
      } else {
        this.showBottom = false

      }
    },
    //删除姓名
    delName () {
      // console.log(this.peopleName[this.editIndex])
      this.peopleName.splice(this.editIndex, 1)
      this.addshow = false
    },
    //修改旅客信息
    editName (item, index) {
      this.type = 'edit'
      this.editIndex = index
      this.NavBarTitle = '修改常旅客'
      this.addshow = true
      if (item.ChorEn) {
        this.ChorEn = this.ChName = item.name
      } else if (item.ChorEn == false) {
        this.ChorEn = false
        let name = item.name.split('/')
        this.Surname = name[0]
        this.GivenNames = name[1]
      }
    },
    //提交被点击的名字
    emitName () {
      let arr = []
      this.peopleName.forEach(item => {
        if (item.checked == true) {
          arr.push(item)
        }
      })
      this.$emit('emitName', arr)
    }
  }
}
</script>
<style lang="less" scoped>
.van-action-sheet__header {
  font-size: 0.3rem;
  font-weight: bold;
}

.content1 {
  .bt {
    z-index: 999;
    position: absolute;
    width: 8%;
    top: 0.5%;
    right: 3%;
  }

  position: relative;

  .van-icon-clear {
    position: absolute;
    right: 4%;
    top: 1%;
    z-index: 10;
  }

  .top {
    img {
      width: 100%;
    }
  }

  .detail {
    text-align: left;
    background-color: #f7f7f7;

    // margin-top: 0.2rem;
    .title {
      font-weight: bold;
      font-size: 0.3rem;
      padding-left: 0.3rem;
    }

    .iconDetail {
      padding-left: 0.3rem;

      /deep/.van-col--7 {
        text-align: left;
        font-size: 0.23rem;
        color: black;
        width: 31%;
        position: relative;
        padding: 0.2rem 0;

        img {
          position: absolute;
          width: 18%;
          top: 23%;
        }

        span {
          margin-left: 0.4rem;
          display: inline-block;
          width: 1.8rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .fac {
      overflow: hidden;
      height: 1.8519rem;

      .smTip {
        font-size: 0.25rem;
        padding-left: 0.3rem;

        .TipD {
          margin-top: 0.25rem;

          .left {
            float: left;
            color: gray;
          }

          .right {
            font-size: 0.23rem;
            margin-left: 1.4rem;
            width: 5rem;
            font-weight: bold;
            line-height: 0.3519rem;
          }
        }
      }
    }

    .btn {
      text-align: center;
      padding: 0.4rem 0;

      button {
        background-color: white;
        border: 1px solid #36e0c9;
        color: #36e0c9;
        font-size: 0.25rem;
        padding: 0.1rem 0.3rem;
        border-radius: 20px;
      }
    }

    .homePoil {
      margin-top: 0.2rem;
      padding: 0.4rem 0rem;
      background-color: white;
    }

    .disOffer {
      margin-top: 0.2rem;
      padding: 0.4rem 0rem;
      background-color: white;

      // margin-bottom: 1.8rem;
      .van-tag--plain {
        font-size: 0.2rem;
        padding: 0.01rem 0.1rem;
        border-radius: 3px;
      }
    }

    .pay_bottom {
      padding-top: 0.2rem;
      background-color: white;
      position: fixed;
      bottom: 0%;
      width: 100%;

      div {
        width: 93%;

        .word {
          margin-left: 3.3rem;
          line-height: 0.8rem;
        }
      }

      .image {
        float: right;

        img {
          width: 0.7rem;
        }
      }
    }
  }
}

.content2 {
  .detail {
    font-size: 0.25rem;
    padding: 0 0.3rem;
    line-height: 0.4rem;
  }

  .smTip {
    font-size: 0.25rem;
    padding-left: 0.3rem;

    .TipD {
      margin-top: 0.25rem;

      .left {
        float: left;
        font-weight: bold;
      }

      .right {
        font-size: 0.23rem;
        margin-left: 1.4rem;
        width: 5rem;
        line-height: 0.3519rem;
        color: gray;
      }
    }
  }
}

.content3 {
  text-align: center;

  .thinner-border {
    text-align: left;
  }

  .top {
    font-size: 0.23rem;
    color: gray;
    padding-bottom: 0.2rem;
  }

  .time {
    padding: 0.1rem 0;

    div {
      padding: 0.15rem 0;
      color: gray;
      font-size: 0.27rem;
    }

    .is_active {
      color: #24c5af;
    }
  }
}

.content4 {
  height: 5rem;
  padding: 0 0.2rem;
  position: relative;

  .van-checkbox {
    display: inline-block;
  }

  .name {
    display: inline-block;
    margin-left: 0.3rem;
    width: 90%;
    position: absolute;
    top: -12%;

    div {
      display: inline-block;
      width: 95%;
    }

    img {
      float: right;
      width: 5%;
    }
  }

  .bottom {
    position: fixed;
    bottom: 0%;
    width: 93%;
    // background-color: aqua;
    padding: 0.2rem 0;

    .van-button--large {
      border-radius: 50px;
      border: 1px solid #36e0c9;
      color: #36e0c9;
    }
  }
}

.content4Top {
  /deep/.van-nav-bar__title {
    font-weight: bold;
    font-size: 0.3rem;
  }

  /deep/.van-nav-bar__left {
    font-weight: bold;
    font-size: 0.25rem;
    top: 14%;
  }

  /deep/.van-nav-bar__right {
    font-weight: bold;
    font-size: 0.25rem;
    top: 14%;
  }
}

.addSheet {
  .van-action-sheet {
    max-height: 100%;
  }

  .van-popup--bottom {
    top: 0%;
  }

  .content {
    background-color: #f6f9f8;
    height: 94%;

    #switch {
      position: absolute;
      top: 9%;
      right: 4%;
      font-size: 0.25rem;

      span {
        border: 1px solid #36e0c9;
        padding: 0.05rem 0.15rem;
        color: #36e0c9;
      }

      .is_active {
        background-color: #36e0c9;
        color: white;
      }
    }

    .bottom {
      text-align: center;
      margin-top: 0.5rem;

      .van-button--round {
        padding: 0.05rem 2rem;
        font-size: 0.28rem;
        background-color: #37e0c9;
        border: none;
      }

      .delBtn {
        .van-button--primary {
          padding: 0.05rem 1.4rem;
          margin-right: 0.2rem;
        }
      }
    }

    .EnglishNa {
      position: relative;
      background-color: white;

      span {
        position: absolute;
        display: inline-block;
        height: 100%;
        z-index: 10;
        color: #646566;
        font-size: 0.22rem;
        top: 25%;
        left: 5%;
      }

      .van-cell {
        margin-left: 0.5rem;
        width: auto;
        padding: 0.3rem 0;

        /deep/.van-field__value {
          margin-left: 1.7rem;
        }

        /deep/.van-field__control {
          width: auto;
        }
      }
    }

    .switchEnName {
      top: 13% !important;
    }
  }
}

.thinner-border {
  position: relative;
  width: 100%;
  height: 0.0185rem;
}

.thinner-border:after {
  content: "";
  position: absolute;
  width: 1000%;
  border: 1px solid gray;
  transform-origin: 0 0;
  transform: scale(0.1, 0.1);
  box-sizing: border-box;
}

.configuration {
  padding-top: 0.5556rem;
  display: block;
  padding-bottom: 0.2rem;

  .configuration_name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-left: 0.5556rem;
    color: white;
  }

  width: 100%;
  height: 2.963rem;
  // margin-top: -5px;
  // vertical-align: auto;
  background-image: url("../assets/Esports/bg.png");

  .type {
    position: relative;
    text-align: center;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-top: 0.2rem;
    padding-left: 0.2rem;
    width: 7rem;

    .type_btn {
      font-size: 0.21rem;
      padding: 0.15rem 0.25rem;
      background-color: #f8f8f8;
      margin-left: 0.1rem;
      border-radius: 20px;
      white-space: nowrap;
      display: inline-block;
    }

    .type_btn.is_type_active {
      background-color: #cdf7f2;
      color: #36e0c9;
      // font-weight: bold;
      position: relative;

      .checked {
        position: absolute;
        bottom: -7%;
        right: 0%;
        width: 20%;
      }

      img {
        width: 100%;
      }
    }
  }
}

.van-swipe-item {
  img {
    display: block;
  }
}

.seamless-warp2 {
  margin-top: 0.3704rem;
  overflow: hidden;
  height: 1.4815rem;
  margin-left: 0.5556rem;
  // width: 24rem;
  color: white;
  .image {
    display: block;
    text-align: center;
    // vertical-align: middle;
  }

  ul.item {
    width: 24rem;

    li {
      float: left;
      margin-right: 0.5556rem;

      .text {
        display: block;
        font-size: 14px;
        // word-break: break-all;
        // flex-grow: 1;
        // min-width: 100px;
      }
    }
  }
}
</style>
