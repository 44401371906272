import { request } from './request.js'
export function Login_user (params){
  return request({
    url:'/home/sms/log-in-or-sign-up',
    method: 'POST',
    params
  })
}

export function LoginInfo (params){
    return request({
      url:'home/log-in-sign-up-by-phone',
      method: 'POST',
      params
    })
  }


  export function getUser_info (params){
    return request({
      url:'home/user',
      method: 'GET',
      params
    })
  }
  export function getUserPayOrder (){
    return request({
      url:'home/user/wallet/order',
      method: 'GET',
    })
  }
  // 查询用户产品收藏数量
  export function getUserProductCount (){
    return request({
      url:'home/user/favorite/product/count',
      method: 'GET',
    })
  }