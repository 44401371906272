<template>
  <div class="container">
    <div class="content">
      <div class="tapSearch">
        <van-search v-model="value"
                    placeholder="请输入搜索关键词" />
      </div>
    </div>
    <div class="place">
      <div>
        <van-tabs v-model="active"
                  title-active-color="#2ce8c3">
          <van-tab title="热门"
                   name="first">
            <van-grid :border="false"
                      :gutter="10"
                      :column-num="4">
              <van-grid-item>
                <span style="position: absolute; font-size: 16px; color:white; bottom: 10%; z-index: 2;">泰国</span>
                <van-image :src="img_tai" />
              </van-grid-item>
              <van-grid-item>
                <span style="position: absolute; font-size: 16px; color:white; bottom: 10%; z-index: 2;">日本</span>
                <van-image :src="img_ri" />
              </van-grid-item>
              <van-grid-item>
                <span style="position: absolute; font-size: 16px; color:white; bottom: 10%; z-index: 2;">新加波</span>
                <van-image :src="img_xin" />
              </van-grid-item>
              <van-grid-item>
                <span style="position: absolute; font-size: 16px; color:white; bottom: 10%; z-index: 2;">法国</span>
                <van-image :src="img_french" />
              </van-grid-item>
              <van-grid-item>
                <span style="position: absolute; font-size: 16px; color:white; bottom: 10%; z-index: 2;">泰国</span>
                <van-image :src="img_tai" />
              </van-grid-item>
              <van-grid-item>
                <span style="position: absolute; font-size: 16px; color:white; bottom: 10%; z-index: 2;">日本</span>
                <van-image :src="img_ri" />
              </van-grid-item>
              <van-grid-item>
                <span style="position: absolute; font-size: 16px; color:white; bottom: 10%; z-index: 2;">新加波</span>
                <van-image :src="img_xin" />
              </van-grid-item>
              <van-grid-item>
                <span style="position: absolute; font-size: 16px; color:white; bottom: 10%; z-index: 2;">法国</span>
                <van-image :src="img_french" />
              </van-grid-item>
            </van-grid>
          </van-tab>
          <van-tab title="亚洲"
                   name="second">
            <div>内容</div>
          </van-tab>
          <van-tab title="美洲"
                   name="third"> </van-tab>
          <van-tab title="欧洲"
                   name="fourth"> </van-tab>
          <van-tab title="大洋洲"
                   name="fifth"> </van-tab>
          <van-tab title="大洋洲"
                   name="sixth"> </van-tab>
        </van-tabs>
        <div class="other">
          <van-grid :border="false"
                    style="margin-top:10px"
                    :gutter="10"
                    :column-num="4">
            <van-grid-item v-for="item in other_place"
                           :key="item.name"><span>{{item.name}}</span></van-grid-item>
          </van-grid>
          <div style="font-size:18px;margin-top: 15px;">更多国家/地区
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
    <div><img style="width:100%;margin-top:10px;bottom: 0;"
           src="../../assets/visa/visa_bottom.png"
           alt=""></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: null,
      img_ri: require('../../assets/visa/ri.png'),
      img_tai: require('../../assets/visa/tai.png'),
      img_xin: require('../../assets/visa/xin.png'),
      img_french: require('../../assets/visa/french.png'),
      other_place: [{
        name: '菲律宾'
      }, { name: '越南' }, { name: '英国' }, { name: '俄罗斯' }, { name: '西班牙' }, { name: '意大利' }, { name: '德国' }, { name: '土耳其' }]
    }

  }
}
</script>

<style  lang="less" scoped>
.container {
  overflow: hidden;
  background-color: #f5f5f5;
  .content {
    width: 100%;
    height: 209px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../assets/visa/BG.png");
    .van-search {
      background-color: rgba(255, 255, 255, 0);
      .van-search__content {
        border-radius: 20px;
      }
    }
  }
  .place {
    width: 100%;
    height: 400px;
    background-color: white;
    border-end-end-radius: 20px;
    border-end-start-radius: 20px;

    /deep/ .van-tabs__line {
      background-color: aquamarine;
      bottom: 20px;
      width: 30px;
    }
    /deep/ .van-grid-item__content--center {
      padding: 0;
    }
    .other {
      span {
        border-width: 1px;
        border-style: solid;
        border-radius: 10px;
        font-size: 16px;
        width: 85px;
        height: 39px;
        line-height: 39px;
        border-color: #ddd2d2;
        // padding: 10px 20px;
      }
    }
  }
}
</style>