import { request } from './request.js'
export function Pay (params){
  return request({
    url:'/home/user/wallet/order',
    method: 'POST',
    params
  })
}

// 微信用户余额充值
export function payUserBalance (params) {
  return request({
    url: '/home/user/prepay',
    method: 'POST',
    params
  })
}
export function checkPayStatus (params) {
  return request({
    url: '/home/user/check-pay-status',
    method: 'POST',
    params
  })
}
// 获取充值优惠数据
export function Payselect(){
  return request({
    url:'/home/add-balance-gift',
    method:'GET'
  })
}