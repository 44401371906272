<template>
  <div id="registerSuccess">
    <!-- <NavBar NavBarTitle='注册成功'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="successHome">
      <van-icon name="checked"
                color='#37EAB4' />
      <div class="text">您已成功注册</div>
      <van-button type="primary"
                  size="large"
                  color='#37EAB4'
                  plain
                  @click="goShopHome">回到首页</van-button>
    </div>
  </div>
</template>

<script>
// import NavBar from '@/components/NavBar'
export default {
  components: {
    // NavBar,
  },
  data () {
    return {

    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    goShopHome () {
      this.$router.push({ path: '/allShop/shopHome' })
    }
  }
}
</script>

<style lang="less" scoped>
#registerSuccess {
  .successHome {
    font-size: 1.1rem;
    margin-top: 1rem;
    .text {
      font-size: 0.3rem;
    }
    .van-button {
      width: 40%;
      border-radius: 999px;
      height: 0.7rem;
      font-size: 0.25rem;
    }
  }
}
</style>