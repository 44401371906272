import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import {
  Lazyload,
  Notify
} from 'vant'
import touch from 'vue-directive-touch';
import 'vant/lib/index.css'
import {
  Drawer
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
import axios from 'axios'
import '@/assets/iconfont/font_fxdybowbmbe/iconfont.css'
import '@/assets/iconfont/font_euobob2eetp/iconfont.css'
import VueImageSwipe from 'vue-image-swipe'
import 'vue-image-swipe/dist/vue-image-swipe.css'
// import VueDPlayer from 'vue-dplayer';
// import 'vue-dplayer/dist/vue-dplayer.css';

// Vue.use(VueDPlayer);
// import 'default-passive-events'
Vue.use(BaiduMap, {
  // ak密钥
  ak: 'VFc7NsfU7amcxYbEiqypp5a3gHPLaiOO'
})
// import wx from 'jweixin-module'
// import {wechatShare } from './commom/wechatShare.js'
// Vue.prototype.wechatShare  = wechatShare
Vue.use(Vant, VueImageSwipe)
Vue.use(Lazyload, Notify)
Vue.use(touch);
Vue.use(Drawer)
Vue.config.productionTip = false
// Vue.prototype.$wx = wx
//路由跳转,定位到顶部
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// router.afterEach(() => {
//   window.scrollTo(0,0);
// });
// 设置浏览器标题
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()

})

axios.defaults.retry = 4;
axios.defaults.retryDelay = 1000;
axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  var config = err.config;
  // If config does not exist or the retry option is not set, reject
  if (!config || !config.retry) return Promise.reject(err);

  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0;

  // Check if we've maxed out the total number of retries
  if (config.__retryCount >= config.retry) {
    // Reject with the error
    return Promise.reject(err);
  }

  // Increase the retry count
  config.__retryCount += 1;

  // Create new promise to handle exponential backoff
  var backoff = new Promise(function (resolve) {
    setTimeout(function () {
      resolve();
    }, config.retryDelay || 1);
  });

  // Return the promise in which recalls axios to retry the request
  return backoff.then(function () {
    return axios(config);
  });
});