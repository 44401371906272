<template>
  <div class="content">
    <!-- 图片 -->
    <div class="imagesList"
         v-for="item in imagesList"
         :key="item"
         @click="img_click">
      <img v-if="imagesList !=''"
           :src="item"
           alt="">
      <van-image-preview v-model="showImage"
                         :images="imagesList">
      </van-image-preview>
    </div>

    <!-- 视频 -->
    <div class="video"
         v-if="video !=''">
      <van-icon name="play-circle-o"
                @click="video_click" />
      <video :src="video"
             @click="video_click">
      </video>
      <van-popup v-model="videoShow"><video :src="video"
               controls="controls"
               style="width:100%;height: 100%;"></video></van-popup>
    </div>

  </div>
</template>

<script>
import matchFileType from '@/commom/matchFileType' //eslint-disable-line no-unused-vars

export default {
  components: {
  },
  props: { previewArry: String },
  watch: {
    preview () {


    }
  },
  data () {
    return {
      index: null,
      videoShow: false,
      showImage: false,
      showPreview: false,
      traceability: null,
      url: [],
      // url: [],
      imagesList: [],
      video: '',
    }
  },
  created () {
    this.manageType()

  },
  mounted () {

  },

  // 处理图片还是
  methods: {
    img_click () {
      console.log("子组件，点击了图片");
      this.showImage = true
    },
    video_click () {
      console.log(this.video);
      this.videoShow = true
      console.log("子组件，点击了视频");

    },
    manageType () {
      if (matchFileType(this.previewArry) == 'image') {
        this.imagesList.push(this.previewArry)
        // console.log("是图片");
      } else if (matchFileType(this.previewArry) == 'video') {
        this.video = this.previewArry
        // console.log("是视频");
      }
      // })
    }
  },
}
</script>

<style lang="less" scoped>
.content {
  float: left;
  .imagesList {
    display: inline-block;
    img {
      margin-right: 10px;
      width: 1.4545rem;
      height: 1.4545rem;
    }
  }

  .video {
    overflow: hidden;
    width: 100%;
    // margin-top: 0.1818rem;
    border-radius: 5px;
    display: inline-block;
    // width: 1.4545rem;
    // height: 1.4545rem;
    // background-color: #01e7c8;
    // margin-right: 20px;
    position: relative;
    /deep/ .van-icon {
      position: absolute !important;
      top: 20%;
      left: 20%;
    }
    video {
      width: 1.4545rem;
      height: 1.4545rem;
      .image {
        float: left;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border: 1px solid #ebebeb;
        margin: 5px;
      }
    }
  }
}
</style>