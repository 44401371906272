<template>
  <div>
    <div class="localAddress">
      <baidu-map class="localAddress"
                 ak='VFc7NsfU7amcxYbEiqypp5a3gHPLaiOO'
                 :center="position"
                 :zoom="zoom"
                 @ready="initMap"
                 :scroll-wheel-zoom="true">
        <!-- <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                            :showAddressBar="true"
                            :autoLocation="true"></bm-geolocation> -->
        <bm-marker :position="position"
                   :dragging="false">
        </bm-marker>
      </baidu-map>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      zoom: 13,
      position: { lng: null, lat: null },
    }
  },
  created () {
    this.getPosition()
  },

  methods: {
    getPosition () {
      this.position = this.$route.query.position
      console.log(this.$route.query.position);
    },
    // 定位
    handler (e) {
      // this.city = e.address.city
      // this.center.lng = e.longitude
      // this.center.let = e.latitude
      console.log(e, '获取定位后,百度地图逆解析获得');
      // console.log(this.city, "====this.city====");
      const myGeo = new window.BMap.Geocoder({ extensions_town: true })
      myGeo.getLocation(new window.BMap.Point(e.point.lng, e.point.lat),
      )
    },
    initMap ({ BMap }) {
      this.BMap = BMap
      // 通过百度map获取经纬度再传给地图组件
      const that = this
      try {
        const geolocation = new window.BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            // console.log(r);
            // that.center = { lat: r.point.lat, lng: r.point.lng }
            that.point = { lat: r.point.lat, lng: r.point.lng }
            that.handler(r)
          },
          { enableHighAccuracy: true }
        )
      } catch (error) {
        this.$toast('请打开定位或退出重试')
      }
    },
  }
}
</script>

<style lang="less" scoped>
.localAddress {
  width: 100%;
  height: 800px;
}
</style>