<template>
  <div id="shopService"
       :style="{height:innerHeight}">
    <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar>
    <div class="shopCard"
         v-for="item in goodsData"
         :key="item.id">
      <div class="title">
        退款商品
      </div>
      <van-card :price="item.price"
                :desc="item.desc"
                :title="item.goodsName "
                :thumb="item.img"
                @click="getGoodsID(item)">
        <template #footer
                  class="numAdd">
          <span class="num">x{{item.num}}</span>
        </template>
      </van-card>
    </div>
    <!-- 选择那项退款 -->
    <div class="choiceService"
         v-if="isclick && isNum==0">
      <div class="title">退款商品</div>
      <div class="myRefund"
           @click="onlyRefund">
        我要退款
        <div class="tip">没收到货，或与卖家协商同意不用退货只退款</div>
        <div class="icon">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="myRefund"
           @click="refund_goods">
        我要退货退款
        <div class="tip">已收到货，需要退还已收到的货物</div>
        <div class="icon">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="myRefund"
           @click="onlyGoods">
        我要退货
        <div class="tip">商品存在质量问题联系卖家协商换货</div>
        <div class="icon">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
    <!-- '我要退款' -->
    <div class="choiceService"
         v-if="!isclick && isNum==1">
      <div class="title">退款信息</div>
      <div class="myRefund"
           @click="choiceRefundReason">
        退款原因
        <div class="icon">
          {{refundText}}
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="myRefund">
        退款金额
        <div class="tip">可修改，最多￥178.00，含发货邮费￥10.00</div>
        <div class="icon">
          <span class="price">￥178.00</span>
        </div>
      </div>
      <div class="supplement">
        <div class="title">补充描述和凭证</div>
        <van-field v-model="supplementMessage"
                   type="textarea"
                   maxlength="100"
                   placeholder="补充描述，有助于卖家更好的处理售后问题"
                   show-word-limit />
        <div class="uploader">
          <div style="position: relative;">
            <van-uploader v-model="fileList"
                          multiple
                          :max-count="3"
                          :after-read="afterRead" />
            <div class="text">上传凭证1～3张</div>
          </div>
        </div>
      </div>
      <div class="btn">
        <van-button type="primary"
                    size="large"
                    round
                    color="#37e1c9"
                    @click="submitReason">提交</van-button>
      </div>
    </div>
    <!-- 我要退货退款 -->
    <div class="choiceService"
         v-if="!isclick && isNum==2">
      <div class="title">退款信息</div>
      <div class="myRefund"
           @click="choiceGoodsStatus">
        货物状态
        <div class="icon">
          {{goodsStatusText}}
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="myRefund"
           @click="choiceRefundReason">
        退款原因
        <div class="icon">
          {{refundText}}
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="myRefund">
        退款金额
        <div class="tip">可修改，最多￥178.00，含发货邮费￥10.00</div>
        <div class="icon">
          <span class="price">￥178.00</span>
        </div>
      </div>
      <div class="supplement">
        <div class="title">补充描述和凭证</div>
        <van-field v-model="supplementMessage"
                   type="textarea"
                   maxlength="100"
                   placeholder="补充描述，有助于卖家更好的处理售后问题"
                   show-word-limit />
        <div class="uploader">
          <div style="position: relative;">
            <van-uploader v-model="fileList"
                          multiple
                          :max-count="3"
                          :after-read="afterRead" />
            <div class="text">上传凭证1～3张</div>
          </div>
        </div>
      </div>
      <div class="btn">
        <van-button type="primary"
                    size="large"
                    round
                    color="#37e1c9"
                    @click="submitReason">提交</van-button>
      </div>
    </div>
    <!-- 我要换货 -->
    <div class="choiceService"
         v-if="!isclick && isNum==3">
      <div class="title">退款信息</div>
      <div class="myRefund"
           @click="choiceExchangeGoods">
        换货原因
        <div class="icon">
          {{exchangeGoodsText}}
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="myRefund">
        <van-field v-model="exchangeValue"
                   label="换货说明 :"
                   placeholder="选填" />
      </div>
      <div class="supplement">
        <div class="title">补充描述和凭证</div>
        <van-field v-model="supplementMessage"
                   type="textarea"
                   maxlength="100"
                   placeholder="补充描述，有助于卖家更好的处理售后问题"
                   show-word-limit />
        <div class="uploader">
          <div style="position: relative;">
            <van-uploader v-model="fileList"
                          multiple
                          :max-count="3"
                          :after-read="afterRead" />
            <div class="text">上传凭证1～3张</div>
          </div>
        </div>
      </div>
      <div class="btn">
        <van-button type="primary"
                    size="large"
                    round
                    color="#37e1c9"
                    @click="submitReason">提交</van-button>
      </div>
    </div>
    <div class="refundAction">
      <van-action-sheet v-for="reason in sheetData"
                        :key="reason.id"
                        v-model="refundShow"
                        :title="reason.title">
        <div class="content">
          <div class="reason"
               v-for="item in reason.reasonData"
               :key="item.id"
               @click="getReason(item)">
            {{item.reason}}
            <van-checkbox v-model="item.ischeck"
                          checked-color='#36E0C9' />
          </div>
          <van-button type="primary"
                      size="large"
                      color='#37e1c9'
                      round
                      @click="submitSheetReason">确定</van-button>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
export default {
  components: {
    NavBar
  },
  data () {
    return {
      innerHeight: 0,
      NavBarTitle: '选择服务',//头部
      goodsData: [],//商品信息
      isclick: true,//用于判断头部向左的方法和‘退款商品’模块是否出现
      isNum: 0,//用于判断‘退款商品’中选择的哪项服务，对应出那个信息
      supplementMessage: '',//补充描述和凭证
      fileList: [],//补充描述和凭证中的图片


      refundShow: true,//退款或换货时的弹窗
      sheetType: 'refund',//判断弹窗类型
      sheetData: [],//点击什么类型，弹窗有不同数据
      refundData: [
        {
          title: '退款原因',
          reasonData: [
            {
              reason: '7天无理由退换货',
              ischeck: false,
            },
            {
              reason: '使用后过敏',
              ischeck: false,
            },
            {
              reason: '生产日期/保质期与商品描述不符',
              ischeck: false,
            },
            {
              reason: '版本/批次/颜色/容量等于商品描述不符',
              ischeck: false,
            },
            {
              reason: '少件/漏发',
              ischeck: false,
            },
          ]
        }
      ],//退款原因的弹窗数据
      refundText: '请选择',//退款原因的文字信息
      goodsStatusData: [
        {
          title: '货物状态',
          reasonData: [
            {
              reason: '未收到货',
              ischeck: false,
            },
            {
              reason: '已收到货',
              ischeck: false,
            },
          ]
        }
      ],//货物状态的弹窗数据
      goodsStatusText: '请选择',//货物状态的文字信息
      exchangeGoodsData: [
        {
          title: '换货原因',
          reasonData: [
            {
              reason: '其他',
              ischeck: false,
            },
            {
              reason: '质量问题',
              ischeck: false,
            },
            {
              reason: '大小尺寸与商品描述不符',
              ischeck: false,
            },
            {
              reason: '拍照/不喜欢/效果不好',
              ischeck: false,
            },
            {
              reason: '卖家发错货',
              ischeck: false,
            },
          ]
        }
      ],//换货原因的弹窗数据
      exchangeGoodsText: '请选择',
      exchangeValue: '',//换货说明
    }
  },
  mounted () { //没有接口，数据展示，等接入数据后可删
    this.goodsData = this.$route.query.goodsInfo
    if (this.$route.query.goodsInfo == undefined) {
      this.goodsData = [
        {
          supplied: '观视界自营',
          goodsName: 'sguai小水怪智能保温杯女士高颜值316不锈钢温度显示彩屏水杯子M2',
          price: 178.80,
          img: 'https://g-search1.alicdn.com/img/bao/uploaded/i4/i4/3919667498/O1CN01Wu83o825G8Q14i6oG_!!0-item_pic.jpg_460x460Q90.jpg_.webp',
          desc: '颜色分类: 银色   容量: 300ml',
          num: 1,
          status: 0
        },
      ]
    } else {
      if (this.goodsData.length == 1) {
        // console.log(this.goodsData)
      } else {
        this.goodsData = [
          {
            supplied: '观视界自营',
            goodsName: 'sguai小水怪智能保温杯女士高颜值316不锈钢温度显示彩屏水杯子M2',
            price: 178.80,
            img: 'https://g-search1.alicdn.com/img/bao/uploaded/i4/i4/3919667498/O1CN01Wu83o825G8Q14i6oG_!!0-item_pic.jpg_460x460Q90.jpg_.webp',
            desc: '颜色分类: 银色   容量: 300ml',
            num: 1,
            status: 0
          },
        ]
      }
    }
    this.innerHeight = window.innerHeight + 'px'
  },
  methods: {
    onClickLeft () {
      if (this.isclick) {
        this.$router.go(-1)
      } else {
        this.NavBarTitle = '选择服务'
        this.isNum = 0
        this.isclick = true
      }
    },
    //图片上传之后的操作
    afterRead () {
      // console.log(123)
    },
    //'我要退款'
    onlyRefund () {
      this.NavBarTitle = '申请'
      this.isclick = false
      this.isNum = 1
    },
    //'我要退货退款'
    refund_goods () {
      this.NavBarTitle = '申请'
      this.isclick = false
      this.isNum = 2
    },
    //'我要换货'
    onlyGoods () {
      this.NavBarTitle = '申请'
      this.isclick = false
      this.isNum = 3
    },
    //选择退款原因
    choiceRefundReason () {
      this.refundShow = true
      this.sheetType = 'refund'
      this.sheetData = this.refundData
    },
    //选择货物状态
    choiceGoodsStatus () {
      this.refundShow = true
      this.sheetType = 'GoodsStatus'
      this.sheetData = this.goodsStatusData
    },
    //选择换货原因
    choiceExchangeGoods () {
      this.refundShow = true
      this.sheetType = 'exchangeGoods'
      this.sheetData = this.exchangeGoodsData
    },
    //弹窗中选项被点击
    getReason (data) {
      this.sheetData[0].reasonData.forEach(item => {
        item.ischeck = false
      })
      data.ischeck = true
    },
    //弹窗提交原因
    submitSheetReason () {
      if (this.sheetType == 'refund') {
        this.sheetData[0].reasonData.forEach(item => {
          if (item.ischeck == true) {
            this.refundText = item.reason
          }
        })
      } else if (this.sheetType == 'GoodsStatus') {
        this.sheetData[0].reasonData.forEach(item => {
          if (item.ischeck == true) {
            this.goodsStatusText = item.reason
          }
        })
      } else if (this.sheetType == 'exchangeGoods') {
        this.sheetData[0].reasonData.forEach(item => {
          if (item.ischeck == true) {
            this.exchangeGoodsText = item.reason
          }
        })
      }
      this.refundShow = false
    },
    //标题为选择服务时下面的‘提交’按钮
    submitReason () {
      // console.log(123)
    }
  }
}
</script>

<style lang="less" scoped>
#shopService {
  background-color: #f5f5f5;
  text-align: left;
  position: relative;
  .shopCard {
    border-radius: 10px;
    background-color: white;
    font-size: 0.35rem;
    margin-top: 0.2rem;
    .title {
      font-size: 0.3rem;
      padding: 0.2rem 0.3rem;
      font-weight: bold;
    }
    .van-card {
      background-color: white;
      border-radius: 10px;
      font-size: 0.2rem;
      padding: 0 0.3rem;
      padding-bottom: 0.2rem;
      margin-top: 0px;
      position: relative;
      .van-card__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 4rem;
        font-size: 0.3rem;
        height: auto;
        display: inline-block;
        line-height: inherit;
      }
      .van-card__thumb {
        width: 1.5rem;
        height: 1.5rem;
      }
      .van-card__desc {
        display: inline;
        padding: 0.05rem 0.1rem;
        background-color: #f5f5f5;
        color: #999999;
      }
      .van-card__price {
        font-size: 0.3rem;
        font-weight: bold;
        color: #fe6529;
        .van-card__price-currency {
          font-size: 0.2rem;
        }
        .van-card__price-integer {
          font-size: 0.3rem;
        }
      }
      .van-card__footer {
        font-size: 0.3rem;
        position: absolute;
        right: 5%;
        bottom: 11%;
        color: #fe6529;
      }
    }
  }
  .choiceService {
    border-radius: 10px;
    background-color: white;
    margin-top: 0.2rem;
    padding: 0.3rem 0.3rem;
    .title {
      font-size: 0.3rem;
      font-weight: bold;
    }
    .myRefund {
      font-size: 0.26rem;
      font-weight: bold;
      padding-top: 0.4rem;
      position: relative;
      .tip {
        font-weight: 500;
        font-size: 0.21rem;
        color: #999999;
        margin-top: 0.05rem;
      }
      .icon {
        font-size: 0.2rem;
        color: #999999;
        position: absolute;
        right: 0%;
        top: 54%;
        .van-icon {
          font-size: 0.26rem;
          color: #999999;
        }
        .price {
          color: #fe6529;
          font-size: 0.32rem;
        }
      }
      .van-cell {
        padding: 0;
        /deep/.van-field__label {
          font-size: 0.26rem;
          color: black;
          width: auto;
        }
        /deep/.van-field__control {
          font-size: 0.26rem;
          color: #999999;
        }
      }
    }
    .supplement {
      margin-top: 0.4rem;
      position: relative;
      .title {
        margin-bottom: 0.2rem;
      }
      .van-cell {
        padding: 0;
        background-color: #f7f4f8;
        border-radius: 10px;
        /deep/.van-field__value {
          padding: 0.1rem 0.2rem;
          font-size: 0.25rem;
        }
        /deep/.van-field__word-limit {
          color: #999999;
          font-size: 0.25rem;
        }
        /deep/.van-field__control {
          min-height: 3rem;
        }
      }

      .uploader {
        position: absolute;
        bottom: 6%;
        left: 3%;
        /deep/.van-uploader__preview {
          .van-uploader__preview-image {
            border-radius: 10px;
            width: 1.1rem;
            height: 1.1rem;
          }
          .van-uploader__preview-delete {
            width: 0.25rem;
            height: 0.25rem;
            .van-icon {
              font-size: 0.25rem;
            }
          }
        }
        /deep/.van-uploader__upload {
          background-color: transparent;
          border: 1px solid #999999;
          border-radius: 10px;
          width: 1.2rem;
          height: 1.2rem;
          .van-icon {
            font-size: 0.37rem;
          }
        }
        .text {
          font-size: 0.15rem;
          color: #999999;
          width: 1.9rem;
          text-align: left;
          position: absolute;
          bottom: -10%;
          left: 0%;
        }
      }
    }
    .btn {
      position: absolute;
      width: 90%;
      bottom: 5%;
      .van-button {
        height: 0.7rem;
        font-size: 0.28rem;
      }
    }
  }
  .refundAction {
    font-size: 0.28rem;
    .van-action-sheet {
      max-height: 100%;
      .van-action-sheet__header {
        font-size: 0.3rem;
      }
    }
    .content {
      padding: 0.2rem 0.3rem;
      position: relative;
      height: 4.5rem;
      .reason {
        position: relative;
        padding: 0.2rem 0;

        .van-checkbox {
          position: absolute;
          right: 0%;
          top: 30%;
          /deep/.van-checkbox__icon {
            font-size: 0.35rem;
          }
        }
      }
      .van-button {
        height: 0.7rem;
        font-size: 0.28rem;
        position: absolute;
        width: 92%;
        bottom: 3%;
      }
    }
  }
}
</style>