<template>
  <div id='HotelMyOrder'
       :style="{height:innerHeight}">
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->
    <van-tabs v-model="active"
              @click='changeStatus'>
      <van-tab v-for="item in orderStatus"
               :key="item.status"
               :title='item.title'>
        <div class="content">
          <div class="orderList"
               v-for="item in OrderData"
               :key="item.id">
            <div @click="getOrder(item)">
              <div class="title">
                <img src="@/assets/HotelImg/hotel_icon.png"
                     alt="">{{item.hotel_name}}
                <span>
                  {{item.order_status == 0 ?"待付款":
                item.order_status == 1?"待确认":
                item.order_status == 2?"待入住":
                item.order_status == 3?"待入住":
                item.order_status == 4 ?"已评价":
                item.order_status == 5?"退款中":
                  item.order_status == 7?"已取消":
                  item.order_status == 8?"取消中":
                item.order_status == 6 || 7?"已退款":"已取消"
                }}
                </span>
              </div>
              <div style="display:flex;margin:0.2rem 0">
                <div class="left">
                  <a>
                    <img :src="item.room_pic"
                         alt="">
                  </a>
                </div>
                <div class="right">
                  <div>
                    <span class="text">{{item.room_type}}</span>
                    <span style="float: right;">￥{{item.user_real_sum}}</span>
                  </div>
                  <div style="margin-top:0.25rem">
                    <span class="text"
                          style="font-size:0.25rem;color:#999999">下单时间:{{item.create_time}}</span>
                    <span style="float: right;color:#999999">{{item.room_num}}间房</span>
                  </div>
                  <div style="margin-top:0.25rem">
                    <span style="font-size:0.17rem;color:#999999">订单号:{{item.order_no}}</span>
                  </div>
                </div>
              </div>
              <div class="price">{{item.day_num}}天 合计
                <span>¥{{item.user_real_sum}}</span>
              </div>
            </div>
            <div class="btn"
                 v-if="item.order_status==0">
              <van-button type="default"
                          @click="payOrder(item)">付款</van-button>
            </div>
            <div class="btn"
                 v-if="item.order_status==3">
              <van-button type="default">评价有奖</van-button>
              <van-button type="default">删除订单</van-button>
            </div>
            <div class="btn"
                 v-if="item.order_status==5||item.order_status==6||item.order_status==7">
              <van-button type="default">删除订单</van-button>
            </div>
          </div>
          <van-empty v-if="OrderData.length==0"
                     description="还没有订单" />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getHotelOrderList, payHotelOrder, payWechatHotel } from '../../../api/hotel.js'
import { PAYJSSDK } from '../../../api/JSSDk.js';
import { aesKey } from "../../../commom/aesKey"
import { Toast } from 'vant';
// import NavBar from '../../../components/NavBar.vue'
export default {
  components: {
    // NavBar,
  },
  data () {
    return {
      innerHeight: 0,
      NavBarTitle: '我的订单',
      routerFrom: '',//用于判断那个路由过来的
      // aesKey: aesKey(),//接口通过密文来获取数据
      res2: '',//
      active: '',//标签页
      order_status: '',//订单状态
      order_no: '',//存放订单号
      hotelOrderData: [],//酒店订单列表数据
      newArry_3: [],
      page: 1,//第几页的数据
      orderStatus: [
        {
          title: '全部',

        },
        {
          title: '待付款',
        },
        {
          title: '待出行',
        },
        {
          title: '处理中',
        },
        {
          title: '待评价',
        },
        {
          title: '退款/售后',
        },
      ],
      OrderData: []
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    this.innerHeight = window.innerHeight + 'px'
    this.getHotelOrderList()
  },
  watch: {
    hotelOrderData (newData) {
      this.OrderData = newData
    }
  },
  beforeRouteEnter (to, from, next) { // 这个路由钩子函数比生命周期beforeCreate函数先执行，所以this实例还没有创建出来
    next((vm) => {
      vm.routerFrom = from.name
    })
  },
  methods: {
    //查看酒店订单列表
    getHotelOrderList (data) {
      if (data) {
        getHotelOrderList(data).then(res => {
          if (this.page > 1) {
            this.hotelOrderData = this.hotelOrderData.concat(res.data.data.list)
            this.hotelOrderData.forEach(item => {
              let newArry = item.order_title.split('-')
              let newArry_2 = {
                hotel_name: '',
                room_type: ''
              }
              newArry_2.hotel_name = newArry[0]
              newArry_2.room_type = newArry[1]
              // this.newArry_3.push(newArry_2)
              for (let key in newArry_2) {
                this.$set(item, key, newArry_2[key])
              }
            })
          } else {
            this.hotelOrderData = res.data.data.list
            this.hotelOrderData.forEach(item => {
              let newArry = item.order_title.split('-')
              let newArry_2 = {
                hotel_name: '',
                room_type: ''
              }
              newArry_2.hotel_name = newArry[0]
              newArry_2.room_type = newArry[1]
              // this.newArry_3.push(newArry_2)
              for (let key in newArry_2) {
                this.$set(item, key, newArry_2[key])
              }
            })
          }
        })
      } else {
        getHotelOrderList(data).then(res => {
          this.hotelOrderData = res.data.data.list
          this.hotelOrderData.forEach(item => {
            let newArry = item.order_title.split('-')
            let newArry_2 = {
              hotel_name: '',
              room_type: ''
            }
            newArry_2.hotel_name = newArry[0]
            newArry_2.room_type = newArry[1]
            // this.newArry_3.push(newArry_2)
            for (let key in newArry_2) {
              this.$set(item, key, newArry_2[key])
            }
          })
          // for (let item in this.hotelOrderData) {
          //   // this.hotelOrderData[item].assign(this.newArry_3[item])
          //   this.$set(item, key, newArry_2[key])
          //   // this.hotelOrderData[item].this.$set(this.newArry_3[item])
          //   // console.log(this.hotelOrderData[item], "====hotelOrderData");
          //   // console.log(this.newArry_3[item], "=====newArry_3");

          // }

        })
      }
    },
    //代理商账户扣钱
    payHotelOrder (data) {
      payHotelOrder(data).then(res => {
        // console.log(res)
        Toast.fail(res);
      })
    },
    onClickLeft () {
      if (this.routerFrom == 'personalHome') {
        this.$router.push({ path: '/allPerInfo/personalHome' })
      } else {
        this.$router.push({ path: '/allHotel/Hotel' })
      }
    },
    // 获取滚动条的滚动距离
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      // // //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

      // // //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight == scrollHeight) {
        this.page++
        let data = {
          page: this.page,
          order_status: this.order_status
        }
        // this.aesKey = aesKey(data)
        this.getHotelOrderList(data)
      }

    },
    //切换状态
    changeStatus () {
      this.OrderData = []
      this.hotelOrderData = []
      this.page = 1
      let data = {}
      //全部
      if (this.active == 0) {
        this.order_status = ''
        data = {
          order_status: this.order_status
        }
        // this.aesKey = aesKey(data)
        this.getHotelOrderList(data)
        //待付款
      } else if (this.active == 1) {
        this.order_status = 0
        data = {
          order_status: this.order_status
        }
        // this.aesKey = aesKey(data)
        this.getHotelOrderList(data)
        //待出行
      } else if (this.active == 2) {
        this.order_status = 2
        data = {
          order_status: this.order_status
        }
        // this.aesKey = aesKey(data)
        this.getHotelOrderList(data)
        //处理中
      } else if (this.active == 3) {
        this.order_status = "1, 5, 8"
        data = {
          order_status: this.order_status
        }
        // this.aesKey = aesKey(data)
        this.getHotelOrderList(data)
        //待评价
      } else if (this.active == 4) {
        console.log("5");
        this.order_status = 3
        data = {
          order_status: this.order_status
        }
        // this.aesKey = aesKey(data)
        this.getHotelOrderList(data)
        //退款/售后
      } else if (this.active == 5) {
        console.log("5");
        this.order_status = "4, 6, 7"
        data = {
          order_status: this.order_status
        }
        // this.aesKey = aesKey(data)
        this.getHotelOrderList(data)
      }
      this.OrderData = this.hotelOrderData
    },
    //支付之后刷新页面
    updateList () {
      this.order_status = 0
      let data = {
        order_status: this.order_status
      }
      // this.aesKey = aesKey(data)
      this.getHotelOrderList(data)
      this.OrderData = this.hotelOrderData
    },
    //获取点击的订单信息
    getOrder (item) {
      // console.log(item.order_no)
      sessionStorage.setItem("order_no", item.order_no);
      this.$router.push({ path: '/allHotel/HotelOrderDetail' })
    },
    //支付选择的订单
    async payOrder (item) {
      this.order_no = item.order_no
      let data2 = {
        pay_for: 1,//酒店
        pay_provider_id: 1,//收银宝
        pay_method: 'W02',//微信js
        product_order_no: item.order_no,
      }
      console.log(data2);
      let res2 = await payWechatHotel(data2)
      console.log(res2, "res2");
      if (res2.data.code != 200) {
        Toast.fail(res2.data.message);
      }
      let data3 = {
        prepay_id: res2.data.data.payinfo.prepay_id
      }
      this.aesKey = aesKey(data3)
      let res3 = await PAYJSSDK(this.aesKey)
      console.log(res3, "res3");
      if (res3.data.code == 200) {
        Toast.fail('支付中');
        // this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
        this.onBridgeReady(res3)

        // sessionStorage.setItem('wxPayData', this.res2.data.data.payinfo)
        // this.$router.push({ path: '/wxPay' })

        sessionStorage.setItem("order_no", item.order_no);
        // window.location = res2.data.data.payinfo
      } else {
        // Toast.fail(res3.data.message);
      }
    },

    //微信浏览器的sdk
    onBridgeReady (data) {
      console.log(data);
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId": data.data.data.appId,     //公众号ID，由商户传入     
        "timeStamp": data.data.data.timeStamp,         //时间戳，自1970年以来的秒数     
        "nonceStr": data.data.data.nonceStr, //随机串     
        "package": data.data.data.package,
        "signType": data.data.data.signType,         //微信签名方式：     
        "paySign": data.data.data.paySign //微信签名 
      },
        function (res) {
          // get_brand_wcpay_request:ok	支付成功
          // get_brand_wcpay_request:cancel	支付过程中用户取消
          // get_brand_wcpay_request:fail	支付失败

          if (res.err_msg == "get_brand_wcpay_request:cancel" || res.err_msg == "get_brand_wcpay_request:fail") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.res3 = res
            Toast.fail('支付失败');
            // this.$router.push({ path: '/allHotel/HotelOrderDetail' })
            window.location.href = "https://pt.guansj.com/allHotel/HotelOrderDetail"
          } else {
            // //用户支付成功后，代理商账户扣钱
            // let  data = {
            //   order_no: this.order_no
            // }
            // this.aesKey = aesKey(data)
            // this.payHotelOrder(this.aesKey)
            Toast.success('支付成功');
            window.location.href = "https://pt.guansj.com/allHotel/orderDtra"
          }
        });
    },
  }
}
</script>

<style lang="less" scoped>
#HotelMyOrder {
  background-color: #f4f9f8;
  /deep/.van-tabs__nav {
    padding-right: 0.25rem;
  }
  /deep/.van-tab {
    font-size: 0.25rem;
  }
  /deep/.van-tab--active {
    color: #24c5af;
  }
  /deep/.van-tabs__line {
    background-color: #36e0c9;
    height: 0.037rem;
  }
  .content {
    text-align: left;
    font-size: 0.27rem;
    padding: 0 0.3rem;
    background-color: #f4f9f8;
    .orderList {
      padding: 0.25rem;
      margin: 0.2rem 0;
      background-color: white;
      border-radius: 10px;
      .title {
        img {
          width: 4%;
          margin-right: 0.05rem;
        }
        span {
          float: right;
          color: #24c5af;
        }
      }
      .left {
        width: 30%;
        a {
          border-radius: 5px;
          overflow: hidden;
          background-color: aqua;
          display: inline-block;
          height: 1.65rem;
          width: 1.65rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .right {
        width: 70%;
        .text {
          display: inline-block;
          width: 3.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .price {
        font-size: 0.26rem;
        text-align: right;
        span {
          font-size: 0.36rem;
          margin-left: 0.1rem;
        }
      }
      .btn {
        text-align: right;
        margin-top: 0.2rem;
        .van-button {
          width: 1.6rem;
          height: 0.6rem;
          font-size: 0.24rem;
          line-height: 0.6rem;
          border-radius: 5px;
          margin-left: 0.2rem;
        }
      }
    }
  }
}
</style>