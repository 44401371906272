<template>
  <div>
    <div class="price_action_sheet">
      <div class="price_window"
           v-if="PriceSheet=='1'">
        <!-- v-if判断是否已弹窗的形式出现 -->
        <div class="content">
          <div class="price">
            <div style="margin-bottom:0.3rem">
              <span>价格</span>
              <span style="color:blue;font-size:0.23rem;">{{priceText}}</span>
            </div>
            <div><span>￥0</span><span style="float: right;">￥1000以上</span></div>
            <van-slider v-model="priceValue"
                        range
                        :min="0"
                        :max="1200"
                        :step="50"
                        active-color='#60E4DE' />
            <div class="priceBtn">
              <van-row type="flex">
                <van-col span="5"
                         v-for="(item,index) in priceArray"
                         :key="index"
                         :class="item.isclick==true?'is_type_active':''"
                         @click="getBtnVa(item.value,item)">{{item.name}}
                  <div v-if="item.isclick"
                       class="checked">
                    <img src="../assets/HotelImg/is_active.png"
                         alt="">
                  </div>
                </van-col>
              </van-row>
            </div>
          </div>
          <!-- <div class="typeBtn">
            <div>
              <span>类型</span>
              <span style="float: right;font-size:0.23rem;color:#0785DE">国内星级/类型说明 ></span>
            </div>
            <div class="chose_type">
              <div class="btn"
                   v-for="(item,index) in typeArray"
                   :key="item.index"
                   :class="item.ischeck==true?'is_type_active':''"
                   @click="getType(index,item)">
                <div v-if="item.ischeck"
                     class="checked">
                  <img src="../assets/HotelImg/is_active.png"
                       alt="">
                </div>
                {{item.text}}
              </div>
            </div>

          </div> -->
        </div>
        <div class="bottom_btn">
          <van-button type="primary"
                      size="large"
                      plain
                      style="width:50%;
                      color:#36e0c9;
                      border:none;
                      border-top:1px solid #f6f6f6"
                      @click="resetPrice">重置</van-button>
          <van-button type="primary"
                      size="large"
                      color="#36e0c9"
                      style="width:50%"
                      @click="getPrice">完成</van-button>
        </div>
      </div>
      <van-action-sheet v-model="priceShow"
                        title='选择价格/类型'
                        @cancel='cancel'
                        @click-overlay='clickOverlay'>
        <div class="price_window">
          <div class="content">
            <div class="price">
              <div style="margin-bottom:0.3rem">
                <span>价格</span>
                <span style="color:blue;font-size:0.23rem;">{{priceText}}</span>
              </div>
              <div><span>￥0</span><span style="float: right;">￥1000以上</span></div>
              <van-slider v-model="priceValue"
                          range
                          :min="0"
                          :max="1200"
                          :step="50"
                          active-color='#60E4DE' />
              <div class="priceBtn">
                <van-row type="flex">
                  <van-col span="5"
                           v-for="(item,index) in priceArray"
                           :key="index"
                           :class="item.isclick==true?'is_type_active':''"
                           @click="getBtnVa(item.value,item)">{{item.name}}
                    <div v-if="item.isclick"
                         class="checked">
                      <img src="../assets/HotelImg/is_active.png"
                           alt="">
                    </div>
                  </van-col>
                </van-row>
              </div>
            </div>
            <!-- <div class="typeBtn">
              <div>
                <span>类型</span>
                <span style="float: right;font-size:0.23rem;color:#0785DE">国内星级/类型说明 ></span>
              </div>
              <div class="chose_type">
                <div class="btn"
                     v-for="(item,index) in typeArray"
                     :key="item.index"
                     :class="item.ischeck==true?'is_type_active':''"
                     @click="getType(index,item)">
                  <div v-if="item.ischeck"
                       class="checked">
                    <img src="../assets/HotelImg/is_active.png"
                         alt="">
                  </div>
                  {{item.text}}
                </div>
              </div>

            </div> -->
          </div>
          <div class="bottom_btn">
            <van-button type="primary"
                        size="large"
                        plain
                        style="width:50%;
                      color:#36e0c9;
                      border:none;
                      border-top:1px solid #f6f6f6"
                        @click="resetPrice">重置</van-button>
            <van-button type="primary"
                        size="large"
                        color="#36e0c9"
                        style="width:50%"
                        @click="getPrice">完成</van-button>
          </div>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    priceShow: Boolean,
    PriceSheet: String
  },
  created () {
    // console.log(this.PriceSheet)
  },
  data () {
    return {
      priceValue: [0, 1200],//价格弹窗滑块的值
      priceArray: [
        {
          name: '￥0~150',
          value: [0, 150],
          isclick: false
        },
        {
          name: '￥150~300',
          value: [150, 300],
          isclick: false
        },
        {
          name: '￥300~450',
          value: [300, 450],
          isclick: false
        },
        {
          name: '￥450~600',
          value: [450, 600],
          isclick: false
        },
        {
          name: '￥600~1000',
          value: [600, 1000],
          isclick: false
        },
        {
          name: '￥1000以上',
          value: [1000, 1200],
          isclick: false
        },
      ],
      priceText: '',//用于判断价格等于0或等于1000时，输出的语句
      // typeArray: [ //类型按钮选择
      //   {
      //     text: '经济型',
      //     index: 1,
      //     ischeck: false
      //   },
      //   {
      //     text: '舒适型',
      //     index: 2,
      //     ischeck: false
      //   },
      //   {
      //     text: '高档型',
      //     index: 3,
      //     ischeck: false
      //   },
      //   {
      //     text: '豪华型',
      //     index: 4,
      //     ischeck: false
      //   }
      // ],
      newTypeArr: [],//用于存放点击之后的类型按钮数据
    }
  },
  watch: {
    //监听滑块的值变动
    priceValue (data) {
      if (data[0] == 0 && data[1] == 1200) {
        this.priceText = ' 价格不限'
      } else if (data[0] >= 1000 && data[1] == 1200) {
        this.priceText = '￥1000以上'
      } else if (data[0] == 0) {
        data[1] >= 1000 ? this.priceText = '￥1000以上' : this.priceText = '￥' + data[1] + '以下'
      } else if (data[1] >= 1000) {
        this.priceText = '￥' + data[0] + '以上'
      } else {
        this.priceText = '￥' + data[0] + '-' + data[1]
      }
    },
  },
  methods: {
    //选择价格按钮
    getBtnVa (data, arr) {
      this.priceArray.forEach(item => {
        item.isclick = false
      })
      arr.isclick = true
      this.priceValue = data
    },
    //选择类型按钮
    getType (index, item) {
      let arr = [...this.typeArray]
      let selarr = [...this.newTypeArr]
      if (arr[index].ischeck == false) {
        arr[index].ischeck = true;
        selarr.push(item)
      } else {
        arr[index].ischeck = false;
        let index11 = selarr.indexOf(index)
        selarr.splice(index11, 1)

      }
      this.typeArray = arr
      this.newTypeArr = selarr
      // console.log(this.newTypeArr)
    },
    //价格弹窗重置按钮
    resetPrice () {
      console.log("1111");
      //价格部分
      this.priceValue = [0, 1200]
      this.priceArray.forEach(item => {
        item.isclick = false
      })

      //类型部分
      this.typeArray.forEach(item => {
        item.ischeck = false
        this.newTypeArr = []
      })
    },
    //价格弹窗完成按钮
    getPrice () {
      console.log("1111");
      let textArr = []
      this.newTypeArr.forEach(item => {
        textArr.push(item.text)
      })
      textArr = textArr.join(',')
      let priceKey = this.priceText + '  ' + textArr
      this.$emit('getPrice', priceKey, this.priceValue, this.newTypeArr)
    },
    //关闭按钮
    cancel () {
      this.$emit('cancel')
    },
    //点击遮罩层
    clickOverlay () {
      this.$emit('clickOverlay')
    }
  }
}
</script>
<style lang="less" scoped>
#btn_tag {
  display: inline-block;
  border: 1px solid #dedede;
  text-align: center;
  padding: 0.15rem 0.3rem;
  border-radius: 6px;
  font-size: 0.25rem;
  margin-left: 0.1rem;
  width: 13%;
  margin-top: 0.05rem;
  .button {
    // width: 1rem;
  }
}
.is_active {
  color: white;
  background-color: #36e0c9;
  border: none;
}
.price_action_sheet {
  margin-top: 0rem;
  /deep/.van-popup--bottom {
    bottom: 0%;
  }
  .van-action-sheet__header {
    font-size: 0.3rem;
    font-weight: 545;
  }
  .content {
    color: #333333;
    padding: 0 0.23rem;
    .van-slider {
      margin-top: 0.2rem;
      width: 94%;
      margin-left: 0.3rem;
    }
    .priceBtn {
      margin-top: 0.3rem;
      .van-col {
        position: relative;
        background-color: #f6f6f6;
        margin-left: 0.1rem;
        text-align: center;
        padding: 0.3rem 0;
        font-size: 0.23rem;
        width: 23%;
        margin-top: 0.1rem;
      }
      .van-col.is_type_active {
        background-color: #cdf7f2;
        color: #36e0c9;
        font-weight: bold;
        .checked {
          position: absolute;
          bottom: -5%;
          right: 0%;
          width: 20%;
        }
        img {
          width: 100%;
        }
      }
    }
    .typeBtn {
      margin-top: 0.2rem;
      .chose_type {
        margin-top: 0.2rem;
        font-size: 0.23rem;
        .btn {
          position: relative;
          text-align: center;
          display: inline-block;
          background-color: #f6f6f6;
          padding: 0.32rem 0.48rem;
          margin-left: 0.1rem;
        }
        .btn.is_type_active {
          background-color: #cdf7f2;
          color: #36e0c9;
          font-weight: bold;
          .checked {
            position: absolute;
            bottom: -5%;
            right: 0%;
            width: 20%;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .bottom_btn {
    margin-top: 0.45rem;
  }
}
</style>