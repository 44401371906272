<template>
  <div>

    <div class="center">
      <div class="payDetail">
        <h5 style="font-size: 16px; margin-top:0">观视界支付</h5>
        <h4 class="pay"
            style="margin-top: 0px; margin-bottom: 0px">
          ¥{{$route.query.totalPrice}}
        </h4>
        <span style="font-size: 14px"> ¥{{$route.query.title}}</span>
      </div>
      <div class="PaySelect">
        <van-radio-group v-model="radio">
          <van-cell-group title="储蓄卡"
                          v-for='item in ShowBankData'
                          :key='item.card_type'>
            <img class="bank-icon"
                 style="float: left;"
                 src="../../../assets/HotelImg/yinhangka@2x.png" />
            <van-cell clickable
                      :title="item.bank_name"
                      :value="item.bank_card_desensitization">
              <template #right-icon>
                <van-radio :name="item.trance_num"
                           checked-color="#2ee8c6"></van-radio>
              </template>
            </van-cell>
          </van-cell-group>
          <van-cell-group v-model="radio">
            <!-- <van-cell clickable>
              <img class="img-icon"
                   src="../../../assets/HotelImg/yinhangka@2x.png" />
              <p>银行支付</p>
              <template #right-icon>
                <van-radio name="1"
                           checked-color="#2ee8c6"></van-radio>
              </template>
            </van-cell> -->
            <img class="img-icon"
                 src="../../../assets/HotelImg/wc 60@2x.png" />
            <van-cell clickable
                      title="微信支付">

              <!-- <p>即将开通</p> -->
              <template #right-icon>
                <van-radio name="WeChatPay"
                           checked-color="#2ee8c6" />
              </template>
            </van-cell>
            <img class="img-icon"
                 src="../../../assets/HotelImg/balance.png" />
            <van-cell clickable
                      title="余额支付">

              <template #right-icon>
                <van-radio name="balancePay"
                           checked-color="#2ee8c6" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
    </div>
    <div class="payButton">
      <van-button color="linear-gradient(to right, #ff6034, #ee0a24)"
                  @click="bankPay">
        免密支付
      </van-button>
      <!--    <van-overlay :show="show"
                   @click="show = false">
        <div class="wrapper"
             @click.stop>
          <div class="block">
            <div class="wrapper-top">
              <van-icon name="cross"
                        class="icon-left-top"
                        @click="close" />
              <span class="icon-right">支付</span>
            </div>
            <p class="wrapper-price"> ¥{{$route.query.totalPrice}}</p>
           <van-password-input :value="value"
                                :gutter="10"
                                :focused="showKeyboard"
                                @focus="showKeyboard = true" />
            <van-button color="#09c163"
                        class="price-button"
                        @click="Pay">免密支付</van-button>

          </div>
        </div>
      </van-overlay> -->

    </div>

  </div>
</template>

<script>
// import NavBar from "@/components  NavBar.vue";
import { payWechatHotel, ClondPay } from '@/api/hotel.js' //eslint-disable-line no-unused-vars


import { inquireBank } from '@/api/bank.js'
import { aesKey } from "@/commom/aesKey";
import { Toast } from 'vant';
export default {
  components: {
    ////  NavBar,
  },
  data () {
    return {
      show: false, //遮罩层
      radio: "",
      // // NavBarTitle: "选择支付方式",
      wcImg: "../../../assets/HotelImg/wc 60@2x.png",
      bankImg: "../../../assets/HotelImg/yinhangka@2x.png",
      ShowBankData: [],//获取到的用户银行卡信息
      trance_num: '',//和银行绑定的流水号
      showKeyboard: true
    };
  },
  created () {
    this.getBank()
  },
  methods: {
    close () {
      this.show = false;
    },
    //微信浏览器的sdk
    onBridgeReady (data) {
      window.WeixinJSBridge.invoke(
        //   'getBrandWCPayRequest', {
        //   "appId": data.appId,     //公众号ID，由商户传入     
        //   "timeStamp": data.timeStamp,         //时间戳，自1970年以来的秒数     
        //   "nonceStr": data.nonceStr, //随机串     
        //   "package": data.package,
        //   "signType": data.signType,         //微信签名方式：     
        //   "paySign": data.paySign //微信签名 
        // },
        'getBrandWCPayRequest', {
        "appId": data.data.data.appId,     //公众号ID，由商户传入     
        "timeStamp": data.data.data.timeStamp,         //时间戳，自1970年以来的秒数     
        "nonceStr": data.data.data.nonceStr, //随机串     
        "package": data.data.data.package,
        "signType": data.data.data.signType,         //微信签名方式：     
        "paySign": data.data.data.paySign //微信签名 
      },
        function (res) {
          // get_brand_wcpay_request:ok	支付成功
          // get_brand_wcpay_request:cancel	支付过程中用户取消
          // get_brand_wcpay_request:fail	支付失败
          if (res.err_msg == "get_brand_wcpay_request:cancel" || res.err_msg == "get_brand_wcpay_request:fail") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。

            this.res3 = res
            Toast.fail('支付失败');
            // this.$router.push({ path: '/allHotel/HotelOrderDetail' })
          } else {
            // //用户支付成功后，代理商账户扣钱
            // var data = {
            //   order_no: this.order_no
            // }
            // this.aesKey = aesKey(data)
            // this.payHotelOrder(this.aesKey)
            Toast.success('支付成功');
          }
        });
    },

    //付款
    async Pay () {
      // 微信支付
      if (this.radio == 'WeChatPay') {
        let product_order_no = sessionStorage.getItem('order_no')
        // console.log(product_order_no, "===product_order_no");
        const WeChatata = {
          pay_for: 1,//酒店
          pay_provider_id: 1,//收银宝 //2云商通
          pay_method: 'WECHAT_PUBLIC',//微信js
          product_order_no: product_order_no,
        }
        console.log(WeChatata, "data======");
        let res2 = await payWechatHotel(WeChatata)
        console.log(res2, "微信支付");
        if (res2.data.code == 200) {
          this.res2 = res2
          Toast.fail('支付中');
          this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
          this.onBridgeReady(this.res2.data.data.payinfo)
          sessionStorage.setItem("order_no", res2.data.data.order_no);
        }
        //免密银行支付
      } else if (this.radio == 'balancePay') {
        let product_order_no = sessionStorage.getItem('order_no')
        // console.log(product_order_no, "===product_order_no");
        const WeChatata = {
          pay_for: 5,//余额支付
          pay_provider_id: 1,//收银宝 //2云商通
          pay_method: 'WECHAT_PUBLIC',//微信js
          product_order_no: product_order_no,
        }
        console.log(WeChatata, "data======");
        let res2 = await payWechatHotel(WeChatata)
        console.log(res2, "微信支付");
        if (res2.data.code == 200) {
          this.res2 = res2
          Toast.fail('支付中');
          this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
          this.onBridgeReady(this.res2.data.data.payinfo)
          sessionStorage.setItem("order_no", res2.data.data.order_no);
        }
      }
      else {
        let product_order_no = sessionStorage.getItem('order_no')
        // console.log(product_order_no, "===product_order_no");
        const BankData = {
          pay_for: 1,//酒店
          pay_provider_id: 1,//收银宝 //2云商通
          pay_method: 'QUICKPAY_VSP',//银行支付
          product_order_no: product_order_no,
          trance_num: this.trance_num
        }
        console.log(BankData, "data======");
        let res = await payWechatHotel(BankData)
        console.log(res, "银行支付");
      }
    },
    onClickLeft () {
      this.$router.go(-1);
    },
    //获取用户的所有银行卡
    getBank () {
      let user_id = { user_id: this.$store.getters.user_id }
      this.aesKey = aesKey(user_id)
      inquireBank(this.aesKey).then(res => {
        let arr = []
        // console.log(res.data.data, "==res");
        res.data.data.forEach(item => {
          if (item.status == 1) {
            arr.push(item)
          }
        })
        this.ShowBankData = arr.map(item => {
          return {
            bank_card_desensitization: item.bank_card_desensitization,
            bank_name: item.bank_name,
            bank_code: item.bank_code,
            trance_num: item.trance_num //流水号
          }
        })
        console.log(this.ShowBankData, "=== this.ShowBankData");
      })
    },
    // bankPay () {
    //   // this.show = true;
    //   if (!this.radio == '') {
    //     console.log(this.radio, "选择了");
    //     this.trance_num = this.radio
    //     this.Pay()
    //     Toast.loading({
    //       message: '免密支付中...',
    //       forbidClick: true,
    //       duration: 5000
    //     });
    //     setTimeout(() => {
    //       console.log("过了5秒执行这个方法");
    //       let product_order_no = sessionStorage.getItem('order_no')
    //       const ClondData = { product_order_no: product_order_no }
    //       ClondPay(ClondData).then(res => {
    //         // console.log(res, "ClondPay");
    //         if (res.data.code == 200) {
    //           this.$router.push({ path: '/allHotel/HotelOrderDetail' })
    //         } else {
    //           Toast.fail('内部数据返回失败,请联系客服');
    //         }
    //       })
    //     }, 3000)
    //   } else {
    //     Toast.fail('请选择银行卡');
    //   }

    // },
  },
};
</script>

<style lang="less" scoped>
.payDetail {
  padding-top: 0.5556rem;
  padding-bottom: 0.5556rem;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .price-button {
    border-radius: 10px;
    width: 3.5re;
    bottom: 0.1852rem;
  }
  // .wrapper-price {
  // }
  .icon-left-top {
    // position: absolute;
    // bottom: 63%;
    left: 0.1852rem;
    top: 0.1852rem;
    float: left;
    font-size: 16px;
    z-index: 999;
    display: inline;
  }
  .icon-right {
    display: inline;
    float: right;
    font-size: 18px;
    margin-top: 0.0926rem;
    margin-right: 0.1852rem;
  }
}
.block {
  width: 5rem;
  height: 4.5rem;
  border-radius: 10px;
  background-color: #fff;
}
.center {
  .payDetail {
    background-color: #f7f7f7;
  }
  .van-cell {
    span {
      float: left;
    }
    .van-cell__title {
      margin-left: 0.963rem;
    }
  }
}
.bank-icon {
  width: 10%;
  position: absolute;
  left: 0.3704rem;
  z-index: 10;
}
.img-icon {
  width: 10%;
  position: absolute;
  left: 0.3148rem;
  z-index: 10;
  p {
    text-align: center;
    top: 100%;
  }
}
// .van-dialog__footer {
// }
.payButton {
  // bottom: 100%;
  // display: flex;
  // flex-direction: column;
  position: absolute;
  width: 100%;

  top: 90%;
  button {
    width: 100%;
  }
  // justify-content: center;
  // align-items: flex-end;
}
.PaySelect {
  p {
    display: inline;
    margin-left: 0.3125rem;
    line-height: 0.1852rem;
  }
  line-height: 0.7407rem;
}
.van-overlay {
  z-index: 100;
}
</style>