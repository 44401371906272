<template>
  <!-- 评价内容 -->
  <div class="container">
    <div class="hotel">
      <!-- <div class="hotel_img"><img src=""
             alt=""></div> -->
      <div class="hotel_title">{{ hotelDetail.name }}</div>
      <div class="hotel_tag">{{ orderDetailData.order_title }}</div>
    </div>
    <div class="evaluate">
      <div class="score_total">
        <div class="tag"
             style="line-height: 1.4182rem">描述评价</div>
        <div class="evaluate_img"
             v-for="(item, index) in evaluate_img"
             :key="item.name">
          <img @click="score_total(item, index)"
               :src="item.img"
               alt="" />
        </div>
        <div style="font-size: 12px; float: left; line-height: 1.4545rem">
          {{ evaluate_text }}
        </div>
      </div>
      <div class="other_score">
        <div class="env_score"
             style="font-size: 0.1818rem">卫生评分</div>
        <div class="score">
          <van-icon :name="item.star"
                    v-for="(item, index) in env_score"
                    :key="item.level"
                    :color="item.color"
                    @click="envScore(item, index)" />
        </div>
        <div style="
            color: #ff7b31;
            font-size: 12px;
            margin-left: 20px;
            line-height: 22px;
          ">
          <div v-if="env == 1">非常不推荐</div>
          <div v-if="env == 2">不推荐</div>
          <div v-if="env == 3">一般</div>
          <div v-if="env == 4">推荐</div>
          <div v-if="env == 5">非常推荐</div>
        </div>
      </div>
      <div class="other_score">
        <div class="facility_score"
             style="font-size: 0.1818rem">环境评价</div>
        <div class="score">
          <van-icon :name="item.star"
                    v-for="(item, index) in facility_score"
                    :key="item.level"
                    :color="item.color"
                    @click="facilityScore(item, index)" />
        </div>
        <div style="
            color: #ff7b31;
            font-size: 12px;
            margin-left: 20px;
            line-height: 22px;
          ">
          <div v-if="facility == 1">非常不推荐</div>
          <div v-if="facility == 2">不推荐</div>
          <div v-if="facility == 3">一般</div>
          <div v-if="facility == 4">推荐</div>
          <div v-if="facility == 5">非常推荐</div>
        </div>
      </div>

      <div class="other_score">
        <div class="service_score"
             style="font-size: 0.1818rem">服务程度</div>
        <div class="score">
          <van-icon :name="item.star"
                    v-for="(item, index) in service_score"
                    :key="item.level"
                    :color="item.color"
                    @click="serviceScore(item, index)" />
        </div>
        <div style="
            color: #ff7b31;
            font-size: 12px;
            margin-left: 20px;
            line-height: 22px;
          ">
          <div v-if="service == 1">非常不推荐</div>
          <div v-if="service == 2">不推荐</div>
          <div v-if="service == 3">一般</div>
          <div v-if="service == 4">推荐</div>
          <div v-if="service == 5">非常推荐</div>
        </div>
      </div>
      <div class="other_score">
        <div class="sanitation_score"
             style="font-size: 0.1818rem">
          设施评价
        </div>
        <div class="score">
          <van-icon :name="item.star"
                    v-for="(item, index) in sanitation_score"
                    :key="item.level"
                    :color="item.color"
                    @click="sanitatioScore(item, index)" />
        </div>
        <div style="
            color: #ff7b31;
            font-size: 12px;
            margin-left: 20px;
            line-height: 22px;
          ">
          <div v-if="sanitation == 1">非常不推荐</div>
          <div v-if="sanitation == 2">不推荐</div>
          <div v-if="sanitation == 3">一般</div>
          <div v-if="sanitation == 4">推荐</div>
          <div v-if="sanitation == 5">非常推荐</div>
        </div>
      </div>
      <!-- <div style="display: block; width: 100%; float: left">
        <div class="tag"
             style="line-height: 1.4182rem">评价标签</div>
        <div class="btn"
             v-for="item in hotel_tag"
             :key="item.type">
          <van-button @click="tag_btn(item)"
                      :class="item.isclick == true ? 'active' : ''">{{ item.type }}</van-button>
        </div>
      </div> -->
    </div>
    <div class="Field">
      <van-field v-model="comment"
                 style="height: 3.6364rem"
                 rows="1"
                 autosize
                 type="textarea"
                 placeholder="亲，房间入住满意吗，房间环境怎么样，前台服务满意吗？（写够15字，就有奖励哦！）" />
    </div>
    <div class="uploader">
      <!-- 图片上传 -->
      <van-uploader v-model="fileList"
                    multiple
                    :after-read="afterRead"
                    :max-count="9" />
      <!-- 视频上传 -->
      <van-uploader v-model="video"
                    :after-read="afterReadVideo"
                    :before-delete="delete_video"
                    accept="*"
                    :max-count="1"
                    upload-icon="play-circle-o">
      </van-uploader>
      <div class="user_video">
        <video @click="vantClick($event)"
               style="height: 88px;"
               :src="video_url"></video>
        <wen-vant-preview v-model="showPreview"
                          :list="previewList"
                          ref="wen-vant-preview"
                          :config="previewConfig"
                          :traceability.sync="traceability">
          <template v-slot="{ item, index }">
            <span :style="{
                color: `${item.color}`,
                display: 'flex',
                height: '100%',
                'align-items': 'center',
                'justify-content': 'center',
              }">
              {{ item.name }}----{{ index + 1 }}
            </span>
            <button @click="itemClick">click</button>
          </template>

          <template #cover="{ index }">
            <div>{{ index }}</div>
          </template>
        </wen-vant-preview>
      </div>
    </div>

    <!-- <div class="uploader_video">
      <van-uploader v-model="video"
                    accept="video"
                    multiple
                    :max-count="9" />
    </div> -->
    <div class="submit">
      <van-cell center
                title="匿名评价">
        <template #right-icon>
          <van-switch v-model="checked"
                      active-color="#00e0c2"
                      inactive-color="#ebebeb"
                      size="24" />
        </template>
      </van-cell>
      <div style="
          font-size: 12px;
          padding: 0 0.2909rem;
          text-align: left;
          color: #c0bfbf;
        ">
        选择匿名后，商家或其它用户将无法获取您的个人信息，您将无法获得本
        评价的积分或奖励
      </div>
      <van-button style="margin-bottom: 0.3636rem"
                  @click="sumbit">发布</van-button>
    </div>
  </div>
</template>

<script>
import "@/assets/iconfont/mood/style.css";
import { uploadFile } from "@/commom/aliyunOss";
import { WenVantPreview } from 'wen-vant-preview'
import { commentOrder } from '@/api/hotel.js'
import { Toast } from 'vant';
export default {
  components: {
    WenVantPreview,
  },
  data () {
    return {
      showPreview: false,
      traceability: null,
      video_url: "",
      previewList: [
        {
          id: 1,
          url: "https://guansj.oss-cn-shenzhen.aliyuncs.com/gsj_new/system/202209/30/WeChat_20220930142049.mp4",
          fileType: 2,
        },
      ],
      previewConfig: {
        type: "fileType", // 用于区分视频还是图片的字段
        videoType: 2, // 视频类型的判断值
        imageType: 1, // 图片类型的判断值
      },
      comment: "",
      fileList: [],
      video: [],
      videoSrc: "",
      env_score: [
        {
          level: 1,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 2,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 3,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 4,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 5,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
      ], //环境评分
      facility_score: [
        {
          level: 1,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 2,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 3,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 4,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 5,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
      ], //设施评分
      service_score: [
        {
          level: 1,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 2,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 3,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 4,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 5,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
      ], //服务评分
      sanitation_score: [
        {
          level: 1,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 2,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 3,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 4,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
        {
          level: 5,
          isclick: false,
          color: "#d9d9d9",
          star: "star",
        },
      ], //卫生评分
      env: null,
      facility: null,
      service: null,
      sanitation: null,
      tag_active: false,
      orderDetailData: null,
      hotelDetail: null,
      checked: true,
      imageList: [],
      score: null,//
      verynice_o: require("../../../assets/HotelImg/verynice-o.png"),
      nice_o: require("../../../assets/HotelImg/nice-o.png"),
      common_o: require("../../../assets/HotelImg/common-o.png"),
      bad_o: require("../../../assets/HotelImg/bad-o.png"),
      verybad_o: require("../../../assets/HotelImg/verybad-o.png"),
      verynice: require("../../../assets/HotelImg/verynice.png"),
      hotel_tag: [
        {
          type: "房间舒适",
          isclick: false,
        },
        {
          type: "床品干净",
          isclick: false,
        },
        {
          type: "停车方便",
          isclick: false,
        },
        {
          type: "流畅游戏",
          isclick: false,
        },
        {
          type: "服务很好",
          isclick: false,
        },
      ],
      evaluate_text: "强烈推荐",
      evaluate_img: [
        {
          name: "verybad",
          img: require("../../../assets/HotelImg/verybad.png"),
        },
        {
          name: "bad",
          img: require("../../../assets/HotelImg/bad.png"),
        },
        {
          name: "common",
          img: require("../../../assets/HotelImg/common.png"),
        },
        {
          name: "nice",
          img: require("../../../assets/HotelImg/nice.png"),
        },
        {
          name: "verynice",
          img: require("../../../assets/HotelImg/verynice.png"),
        },
      ],
      evaluate_img_default: [
        {
          name: "verybad",
          img: require("../../../assets/HotelImg/verybad.png"),
        },
        {
          name: "bad",
          img: require("../../../assets/HotelImg/bad.png"),
        },
        {
          name: "common",
          img: require("../../../assets/HotelImg/common.png"),
        },
        {
          name: "nice",
          img: require("../../../assets/HotelImg/nice.png"),
        },
        {
          name: "verynice",
          img: require("../../../assets/HotelImg/verynice.png"),
        },
      ],
    };
  },
  created () {
    this.getOrderDetail();
  },
  methods: {
    // 提交评论
    sumbit () {
      let hotelDetail = JSON.parse(sessionStorage.getItem('hotelDetail'))
      let orderDetailData = JSON.parse(sessionStorage.getItem('orderDetailData'))
      let data = {
        id: orderDetailData.order_id,
        product_id: hotelDetail.id,
        product_sub_id: orderDetailData.room_id,
        media_ids: this.imageList,
        score: this.score, //总评分
        env_score: this.env, //环境评分
        facility_score: this.facility, //设施评分
        service_score: this.service, //服务评分
        sanitation_score: this.sanitation, //卫生评分
        comment: this.comment
      }
      console.log(data);
      commentOrder(data).then(res => {
        console.log(res);
        if (res.data.message == "参数comment不能为空") {
          Toast('评论不能为空');
        } else if (res.data.message == "提交成功") {
          Toast('评论提交成功');
          this.$router.push({ path: '/allHotel/HotelMyOrder' })
        }
        else {
          Toast('评分不能为空');

        }
      })
    },
    // 删除视频
    delete_video () {
      this.video_url = ''
      this.video = []
      console.log("点击了删除");
    },
    vantClick (event) {
      this.traceability = event.target;
      this.showPreview = true;
    },
    previewClick (event, index) {
      console.log(event, index, "previewClick");
    },
    previewChange (index) {
      console.log(index, "previewChange");
    },
    // 评分点击
    other_score (item, index) {
      console.log(item, index);
    },
    envScore (item, index) {
      console.log(item, index);
      if (item.isclick == false) {
        for (let i = 0; i <= index; i++) {
          this.env_score[i].isclick = true;
          this.env_score[i].color = "#ff7b31";
          this.env_score[i].star = "star";
          this.env = i + 1;
        }
      } else if (item.isclick == true) {
        this.env_score.forEach((item2) => {
          item2.isclick = false;
          (item2.color = "#d9d9d9"), (item2.star = "star");
        });
        for (let i = 0; i <= index; i++) {
          this.env_score[i].isclick = true;
          this.env_score[i].color = "#ff7b31";
          this.env_score[i].star = "star";
          this.env = i + 1;
        }
      }
    },
    facilityScore (item, index) {
      console.log(item, index);
      if (item.isclick == false) {
        for (let i = 0; i <= index; i++) {
          this.facility_score[i].isclick = true;
          this.facility_score[i].color = "#ff7b31";
          this.facility_score[i].star = "star";
          this.facility = i + 1;
        }
      } else if (item.isclick == true) {
        this.facility_score.forEach((item2) => {
          item2.isclick = false;
          (item2.color = "#d9d9d9"), (item2.star = "star");
        });
        for (let i = 0; i <= index; i++) {
          this.facility_score[i].isclick = true;
          this.facility_score[i].color = "#ff7b31";
          this.facility_score[i].star = "star";
          this.facility = i + 1;
        }
      }
    },
    serviceScore (item, index) {
      console.log(item, index);
      if (item.isclick == false) {
        for (let i = 0; i <= index; i++) {
          this.service_score[i].isclick = true;
          this.service_score[i].color = "#ff7b31";
          this.service_score[i].star = "star";
          this.service = i + 1;
        }
      } else if (item.isclick == true) {
        this.service_score.forEach((item2) => {
          item2.isclick = false;
          (item2.color = "#d9d9d9"), (item2.star = "star");
        });
        for (let i = 0; i <= index; i++) {
          this.service_score[i].isclick = true;
          this.service_score[i].color = "#ff7b31";
          this.service_score[i].star = "star";
          this.service = i + 1;
        }
      }
    },

    sanitatioScore (item, index) {
      console.log(item, index);
      if (item.isclick == false) {
        for (let i = 0; i <= index; i++) {
          this.sanitation_score[i].isclick = true;
          this.sanitation_score[i].color = "#ff7b31";
          this.sanitation_score[i].star = "star";
          this.sanitation = i + 1;
        }
      } else if (item.isclick == true) {
        this.sanitation_score.forEach((item2) => {
          item2.isclick = false;
          (item2.color = "#d9d9d9"), (item2.star = "star");
        });
        for (let i = 0; i <= index; i++) {
          this.sanitation_score[i].isclick = true;
          this.sanitation_score[i].color = "#ff7b31";
          this.sanitation_score[i].star = "star";
          this.sanitation = i + 1;
        }
      }
    },
    score_total (item, index) {
      console.log(item, index);
      this.score = index + 1
      this.evaluate_img.forEach((item) => {
        for (let i = 0; i < this.evaluate_img_default.length; i++) {
          this.$set(item, "img", this.evaluate_img_default[i].img);
        }
      });
      if (index == 3) {
        for (let i = 0; i < index + 1; i++) {
          // this.evaluate_img[i].img = this.nice_o
          this.$set(this.evaluate_img[i], "img", this.nice_o);
        }
        this.evaluate_text = "推荐";
      } else if (index == 2) {
        for (let i = 0; i < index + 1; i++) {
          // console.log(this.evaluate_img[]);
          this.evaluate_img[i].img = this.common_o;
        }
        this.evaluate_text = "一般";
      } else if (index == 0) {
        for (let i = 0; i < index + 1; i++) {
          this.evaluate_img[i].img = this.verybad_o;
        }
        this.evaluate_text = "非常不推荐";
      } else if (index == 1) {
        for (let i = 0; i < index + 1; i++) {
          this.evaluate_img[i].img = this.bad_o;
        }
        this.evaluate_text = "不推荐";
      } else if (index == 4) {
        for (let i = 0; i < index + 1; i++) {
          this.evaluate_img[i].img = this.verynice_o;
        }
        this.evaluate_text = "强烈推荐";
      }
    },
    tag_btn (item) {
      console.log(item);
      if (item.isclick == false) {
        this.$set(item, "isclick", true);
      } else {
        item.isclick = false;
      }
    },
    getOrderDetail () {
      this.orderDetailData = JSON.parse(
        sessionStorage.getItem("orderDetailData")
      );
      this.hotelDetail = JSON.parse(sessionStorage.getItem("hotelDetail"));
      // console.log(this.orderDetailData);
    },
    afterRead () {
      console.log(this.fileList);
      uploadFile(this.fileList).then((res) => {
        this.imageList.push(res)
        console.log(this.imageList);
      });
    },
    afterReadVideo () {
      uploadFile(this.video).then((res) => {
        this.video_url = res
        this.imageList.push(res)
        console.log(res);
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/._progress {
  padding: 0px !important;
}
.container {
  overflow: hidden;
  width: 100%;

  /deep/ .submit {
    overflow: hidden;
    width: 100%;
    .van-cell__title {
      span {
        float: left !important;
        font-size: 14px;
        font-family: "PingFang SC";
        font-weight: 600;
      }
    }

    .van-button {
      width: 2.1818rem !important;
      height: 0.6364rem !important;
      background-color: #00e0c2;
      border-radius: 20px;
      color: white;
    }
  }

  .hotel {
    padding-top: 0.2909rem;
    padding-left: 0.2909rem;
    padding-right: 0.2909rem;

    // .hotel_img {
    //   // display: inline-block;
    //   position: absolute;
    //   // float: left;
    //   border-radius: 5px;
    //   width: 51px;
    //   height: 51px;
    //   background-color: #d9d9d9;
    //   margin-right: 10px;
    // }
    .hotel_title {
      text-align: left;
      // margin-left: 55px;
      font-size: 18px;
    }

    .hotel_tag {
      // margin-left: 55px;

      margin-top: 0.1455rem;
      text-align: left;
      font-size: 12px;
    }
  }

  /deep/.Field {
    .van-cell .van-field {
      height: 3.6364rem;
    }
  }

  .evaluate {
    .score_total {
      height: 60px;
      overflow: hidden;
    }

    .other_score {
      color: #999999;
      margin-top: 10px;
      display: flex;
      float: left;
      margin-left: 10px;

      .env_score {
        line-height: 20px;
      }

      .score {
        /deep/.van-icon {
          margin-left: 10px;
        }

        margin-left: 5px;
        font-size: 18px;
        line-height: 20px;
      }
    }

    overflow: hidden;
    width: 100%;
    // overflow: hidden;
    // height: 2.7273rem;

    .van-button--normal {
      padding: 0 0.1818rem;
    }

    margin-left: 0.2909rem;

    // height: 65px;
    // float: left;
    // display: flex;
    .tag {
      float: left;
      font-size: 12px;
      display: inline-block;
      margin-right: 0.2182rem;
      color: #999999;
    }

    /deep/ .btn {
      float: left;
      line-height: 0.6545rem;

      .van-button {
        height: 0.4909rem !important;
        border-radius: 20px !important;
        font-size: 0.1855rem !important;
        margin-left: 0.2182rem !important;
      }

      .active {
        height: 0.4909rem !important;
        border-radius: 20px !important;
        font-size: 0.1855rem !important;
        margin-left: 0.2182rem !important;
        background-color: #00e0c2;
        color: white;
      }
    }

    .label {
      height: 1.6rem;
    }

    .evaluate_img {
      float: left;
      display: inline-block;
      margin-right: 0.2909rem;

      img {
        width: 0.4364rem;
        height: 0.4364rem;
      }
    }
  }
  .uploader_video {
    margin-left: 100px;
    display: inline-block;
    overflow: hidden;
    .van-uploader {
      overflow: hidden;
    }
  }
  /deep/ .uploader {
    .user_video {
      z-index: 99999;
      float: left;
    }
    display: inline-block;
    float: left;
    overflow: hidden;
    padding: 0 0.2909rem;

    .van-uploader {
      float: left;

      .van-uploader__upload {
        border-radius: 10px;
        background-color: white;
        border: solid #d9d9d9 1px;
      }
    }
  }
}
</style>