import { request } from './request.js'
//共享JSSDK
export function getJSSDK(params){
  return request({
    url:'/agent/wx-js-sdk-config',
    method: 'POST',
    params
  })
}
//支付JSSDK
export function PAYJSSDK(params){
  return request({
    url:'/agent/wx-pay-js-sdk-config',
    method: 'POST',
    params
  })
}