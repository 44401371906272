<template>
  <div>
    正在跳转
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  mounted () {
    setTimeout(() => {

      this.goAttract()
    }, 2000);
  },
  methods: {
    goAttract () {
      this.$router.push({
        path: "/allHotel/Hotel"
      })
    }
  }
}
</script>

<style>
</style>