<template>
  <div id="Allagreement">
    <keep-alive>
      <transition :name="transitionName">
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </transition>
    </keep-alive>

    <transition :name="transitionName">
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {
      transitionName: "",
    }
  },
  watch: {
    $route (to, from) {
      //实现路由跳转动画
      if (to.meta.index > from.meta.index)
        this.transitionName = "slide-left";
      if (to.meta.index < from.meta.index)
        this.transitionName = "slide-right";
    }
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .slide-right-enter-active,
  .slide-right-leave-active,
  .slide-left-enter-active,
  .slide-left-leave-active {
    will-change: transform;
    transition: ease 500ms;
    position: absolute;
  }

  .slide-right-enter {
    opacity: 0;
    transform: translateX(-100%);
  }

  .slide-right-leave-active {
    opacity: 0;
    transform: translateX(100%);
  }

  .slide-left-enter {
    opacity: 0;
    transform: translateX(100%);
  }

  .slide-left-leave-active {
    opacity: 0;
    transform: translateX(-100%);
  }
}
</style>