<template>
  <div>
    <div class="container">
      <div class="content">
        <div class="topSearch">
          <form action="/">
            <span class="address">东莞
              <van-icon name="arrow-down" />
              <span style="color:#d9d9d9"> |</span>
            </span>
            <van-search class="search"
                        v-model="value"
                        shape="round"
                        left-icon=""
                        placeholder="请输入搜索关键词">
            </van-search>
            <i class="search_icon"><img src="../../../assets/inland/搜索icon.png"
                   alt="">
            </i>
          </form>
        </div>
      </div>
      <div class="place">
        <div style="font-size:18px;font-weight: 600;padding-left:20px ;width: 100% ; display: flex; margin-bottom:10px">热门目的地</div>
        <div style="padding-left: 0.2rem">
          <van-tabs type="card"
                    title-active-color="white"
                    id="boxFixed">
            <van-tab title="日本"
                     name="first">
              <vueSeamless :data="newsList"
                           :class-option="optionLeft"
                           class="seamless-warp2">
                <ul class="item">
                  <li v-for="item in newsList"
                      :key="item.id">
                    <div class="item_img"><img :src="item.img"
                           alt=""></div>
                    <div class="item_text">{{item.title}}</div>
                    <span class="item_tag">即时确认</span>
                    <br>
                    <div class="item_price">¥{{item.price}} <span style=" color: #999999; font-size: 12px;">起</span></div>
                  </li>

                </ul>
              </vueSeamless>
            </van-tab>
            <van-tab title="新加坡"
                     name="second">
            </van-tab>
            <van-tab title="棒子"
                     name="third"> </van-tab>
            <van-tab title="萨瓦迪卡"
                     name="fourth"> </van-tab>
            <van-tab title="法棍"
                     name="fifth"> </van-tab>
          </van-tabs>
        </div>
      </div>
      <div class="hot_place">
        <div style="font-size:18px;font-weight: 600;padding-left:20px ;width: 100% ; display: flex;">热门推荐</div>
        <span>
          <van-tabs v-model="active">
            <van-tab title="3-5天">
              <div class="hot_place_content">
                <div class="hot_place_left">
                  <img src="../../../assets/inland/hot_place_bg.png"
                       alt="">
                </div>
                <div class="hot_place_right">
                  <div class="hot_place_name">中国四川某某莫某地方旅游</div>
                  <span class="item_tag">即时确认</span>
                  <div class="hot_price">¥3333 <span style=" color: #999999; font-size: 12px;">起</span></div>
                </div>
              </div>
              <div class="hot_place_content">
                <div class="hot_place_left">
                  <img src="../../../assets/inland/hot_place_bg_2.png"
                       alt="">
                </div>
                <div class="hot_place_right">
                  <div class="hot_place_name">中国四川某某莫某地方旅游</div>
                  <span class="item_tag">即时确认</span>
                  <div class="hot_price">¥3333 <span style=" color: #999999; font-size: 12px;">起</span></div>
                </div>
              </div>

            </van-tab>
            <van-tab title="6-9天">内容 2</van-tab>
            <van-tab title="10天以上">内容 3</van-tab>
          </van-tabs>
        </span>

      </div>
    </div>
  </div>
</template>

<script>
import vueSeamless from 'vue-seamless-scroll'

export default {
  components: {
    vueSeamless: vueSeamless,

  },
  data () {
    return {
      value: "",

      newsList: [{
        id: 1,
        img: require('../../../assets/inland/bg_1.png'),
        title: '中国广东某某莫某地方旅游',
        price: 3131
      }, {
        id: 2,
        img: require('../../../assets/inland/bg_2.png'),
        title: '中国四川某某莫某地方旅游',
        price: 32131
      },
      {
        id: 3,
        img: require('../../../assets/inland/bg_3.png'),
        title: '中国广东某某莫某地方旅游',
        price: 3131
      }]
    }
  },
  computed: {
    optionLeft () {
      return {
        step: 0, // 数值越大速度滚动越快
        direction: 2, // 0向下 1向上 2向左 3向右
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        // hoverStop: true, // 是否开启鼠标悬停stop
        openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  }
}
</script>

<style lang="less" scoped >
.container {
  overflow: hidden;
  width: 100%;
  // height: 800px;
  position: relative;

  .content {
    width: 100%;
    height: 222px;
    background-image: url("../../../assets/inland/inland_bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    .topSearch {
      width: 100%;
      position: absolute;
      .search_icon {
        position: absolute;
        top: -30%;
        right: 5%;
        img {
          width: 50%;
        }
      }
      .address {
        position: absolute;
        left: 0%;
        font-size: 18px;
        margin-top: 14px;
        margin-left: 30px;
      }
      .van-search {
        background: none;
      }
      .van-field {
        left: 20%;
      }
    }
  }
  .place {
    overflow: hidden;
    #boxFixed {
      float: left;
      /deep/.van-tab__text--ellipsis {
        font-size: 0.25rem;
        overflow: visible;
        font-weight: 400;
      }
      /deep/ .van-tabs__nav--card {
        // border-radius: 20px;
        border: none;
        margin-left: 0;

        .van-tab {
          // border-right: none;
          border-radius: 20px;
          border: 20px;
          height: 25px;
          width: 75px;
          border: #333333;
          color: #999999;
          border: solid;
          border-width: 1px;
          margin-right: 5px;
        }
      }
      /deep/.van-tabs__line {
        background-color: #36e0c9;
        width: 0.6rem;
      }
      .van-tab {
        border-radius: 20px;
        margin-right: 20px;

        border-right: none;
      }
      /deep/.van-tab--active {
        background-color: #36e0c9;
        color: #333333;
        font-weight: bold;
        font-size: 0.28rem !important;
        border-radius: 20px;
      }
      /deep/.vue-waterfall-easy {
        margin-top: 10px;
        margin-bottom: 20px;
        a.img-inner-box {
          box-shadow: none;
          a {
            height: 100% !important;
          }
        }

        img {
          border-radius: 5px;
          width: 93%;
        }
        .waterFall {
          font-size: 0.25rem;
          text-align: left;
          margin-top: 20px;
          .headerW {
            font-size: 0.26rem;
            margin-top: -10px;
          }
          .van-search .bottomW {
            position: relative;
            padding-top: 0.15rem;
            .price {
              font-size: 0.35rem;
              font-weight: bold;
            }
            .type {
              font-size: 0.2rem;
              font-weight: bold;
              position: absolute;
              right: 5%;
              top: 36%;
            }
          }
        }
      }
    }
    .place_btn {
      float: left;
      .van-button {
        margin-right: 10px;
        height: 25px;
        width: 75px;
        .van-button__text {
        }
      }
    }
  }
  .hot_place {
    .hot_place_content {
      // background-color: #ff5c4c;
      // width: 90%;
      // height: 130px;
      // margin-left: 20px;
      .hot_place_left {
        width: 136px;
        height: 114px;
        // background-color: blueviolet;
        display: inline-block;
        img {
          width: 136px;
          height: 125px;
        }
      }
      .hot_place_right {
        position: relative;
        height: 114px;
        width: 222px;
        margin-left: 14px;
        // background-color: blueviolet;
        display: inline-block;
        .hot_place_name {
          position: absolute;
          display: flex;
          font-size: 14px;
        }
        .item_tag {
          border: #333333;
          color: #999999;
          border: solid;
          padding-left: 5px;
          padding-right: 5px;

          // -webkit-text-size-adjust: 1%;
          font-size: 12px;
          border-radius: 5px;
          border-width: 1px;
          display: flex;
          top: 20%;
          transform: scale(0.7);
          position: absolute;
        }
        .hot_price {
          position: absolute;
          font-size: 16px;
          bottom: 0;
          color: #ff5c4c;
        }
      }
    }
  }
  /deep/.van-tabs__line {
    background-color: #36e0c9;
    bottom: 20px;
  }
}
.seamless-warp2 {
  overflow: hidden;
  // height: 320px;
  width: 380px;
  font-size: 14px;
  ul.item {
    overflow: hidden;
    height: 200px;
    // width: 100%;
    li {
      float: left;
      margin-right: 10px;
      position: relative;
      // width: 300px;
      .item_img {
        width: 168px;
        height: 122px;
        img {
          width: 168px;
          height: 122px;
        }
      }
      .item_tag {
        border: #333333;
        color: #999999;
        border: solid;
        padding-left: 5px;
        padding-right: 5px;

        // -webkit-text-size-adjust: 1%;
        font-size: 12px;
        border-radius: 5px;
        border-width: 1px;
        display: inline-block;
        transform: scale(0.7);
        position: absolute;
        left: -5%;
      }
      .item_text {
        width: 168px;
        text-align: center;
        font-size: 14px;
      }
      .item_price {
        width: 168px;
        font-size: 16px;
        color: #ff5c4c;
        position: absolute;
        margin-top: 5px;
        left: -30%;
      }
    }
  }
}
</style>