<template>
  <div class="container">
    <div class="top">
      <van-button type="default"
                  v-for="item in top"
                  :key="item.id"
                  :class="item.isclick == true ? 'btn_true' : ''"
                  style="width: 25%;"
                  @click="test(item)">{{item.name}}</van-button>
    </div>
    <div class="info">
      <div class="coupons">
        <div class="left_img"><img src="../../../assets/personalimg/tour.png"
               alt=""></div>
        <div class="between">
          <span class="astrict_info">旅游线路 指定用券新客专享券</span>
          <!-- <br> -->
          <span class="time">2022.07.12-2022.07.1</span>
          <van-tag class="astrict">限制用于旅游</van-tag>
          <span style="color:#c5c5c5; margin-top: 20px;">详细信息</span>
        </div>
        <div class="right_price">
          <span style="font-size:30px; color:white">¥</span>
          <span class="price">30</span>
          <div class="limit">满100元可用</div>
          <van-button class="btn">立即使用</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      top: [{
        id: 0,
        name: '全部(3)',
        isclick: true
      },
      {
        id: 1,
        name: '商城(1)',
        isclick: false
      },
      {
        id: 2,
        name: '酒店(1)',
        isclick: false
      },
      {
        id: 3,
        name: '旅游(1)',
        isclick: false
      },
      ]
    }
  },
  methods: {
    test (index) {
      console.log(index.id);
      this.top.forEach(item => {
        // console.log(item);
        item.isclick = false
      })
      if (index.id == this.top[1].id) {
        this.top[1].isclick = true
      } if (index.id == this.top[0].id) {
        this.top[0].isclick = true
      } if (index.id == this.top[2].id) {
        this.top[2].isclick = true
      }
      if (index.id == this.top[3].id) {
        this.top[3].isclick = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  .btn_true {
    color: #fe8e3a;
  }
  /deep/ .van-button {
    border: none;
    // height: 0px !important;
  }
  .info {
    width: 100%;
    // height: 500px;
    background-color: #f8f8f8;
    .coupons {
      width: 100%;
      height: 140px;
      background-image: url("../../../assets/personalimg/coupons.png");
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
    }
    .left_img {
      top: 16%;
      left: 5%;
      display: flex;
      width: 25%;
      position: absolute;
      img {
        width: 90%;
      }
    }
    .between {
      position: absolute;
      padding: 0 5px;
      font-size: 12px;
      left: 28%;
      top: 15%;
      width: 38%;
      .astrict {
        font-size: 12px;
        padding: 3px 8px;
        // display: inline-flex;
        background: linear-gradient(150deg, #ff6c0a 0%, #ffc454 100%);
        color: white;
        border-radius: 20px;
        border-width: 1px;
        display: inline-flex;
        margin-top: 20px;
        left: -14%;
        transform: scale(0.8);
      }
      .astrict_info {
        // text-overflow: ellipsis;
        // display: -webkit-inline-box;
        // -webkit-line-clamp: 2;
        // line-clamp: 2;
        // -webkit-box-orient: vertical;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        text-align: left;
        display: inline-block;
      }
      .time {
        display: flex;
      }
    }
    .right_price {
      position: absolute;
      width: 30%;
      right: 3%;
      top: 0%;
      .price {
        font-size: 36px;
        font-weight: normal;
        color: #ffffff;
        font-style: italic;
        line-height: 78px;
      }
      .limit {
        font-size: 12px;
        right: 20%;
        top: 70%;
        color: white;
        position: absolute;
      }
      .btn {
        font-size: 12px;
        position: absolute;
        right: 14%;
        top: 100%;
      }
      /deep/ .van-button {
        height: 25px !important;
        color: #fe852f;
        border-radius: 20px;
      }
    }
  }
}
</style>