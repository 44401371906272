<template>
  <div id="vipSpec">
    <!-- <NavBar NavBarTitle='规格明细'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="content">
      <div class="title">可选充值权益</div>
      <div id="card"
           v-for="item in specData"
           :key="item.privilege_id"
           :class="item.isclick==true?'is_click':''"
           @click="getSpec(item)">
        <div class="name">{{item.spec_name}}
          <div class="price">￥{{item.official_price}}</div>
        </div>
        <div class="spec">{{item.spec_title}}</div>
      </div>
      <div class="instructions">
        <div class="title">使用说明</div>
        <div class="cont">
          <div style="color:#DB974D">1.携号转网的手机号无法使用，请勿拍!!!</div>
          <div>2.直接输入手机号即可充值</div>
          <div>3.请确保手机号下单正确并正常使用的手机号，错误 输入手机号/空号/查封号，下单不退款。170等虚拟号段暂时不支持充值</div>
          <div style="color:#DB974D">4.一般0-2小时左右到账(特殊情况稍有延迟)，如若超出12小时未到账请
            于超出后24小时内联系客服处理非质量问题不可退/换货，介意勿下，急
            单勿拍，谢谢!
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="icon">
        <div>
          <img src="@/assets/vipImg/kefu.png"
               alt="">
        </div>
        联系客服
      </div>
      <div class="icon"
           @click="getMyOrder">
        <div>
          <img src="@/assets/vipImg/wodedingdan.png"
               alt="">
        </div>
        我的订单
      </div>
      <div class="buyBtn"
           @click="bottomBuy">
        <span>立即购买</span>
      </div>
    </div>
    <van-popup v-model="specShow">
      <div class="title">订单信息</div>
      <div class="cont">
        <div>商品: {{specName}}</div>
        <div>金额: <span style="font-size:0.4rem;font-weight:bold">￥{{official_price}}</span></div>
        <div>
          <van-field v-model="phone"
                     type="number"
                     label="短信通知号码:"
                     placeholder="请输入短信通知号码" />
        </div>
        <div>
          <van-field v-model="number"
                     type="number"
                     label="充值号码:"
                     placeholder="(手机号/QQ号/邮箱)" />
        </div>
      </div>
      <div class="btn">
        <van-button type="default"
                    size="large"
                    style="color:#C0C4CC"
                    @click="cancelOrder">取消</van-button>
        <van-button type="primary"
                    size="large"
                    @click="sumbitOrder">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import NavBar from '@/components/NavBar'
import { Toast } from 'vant';
import { aesKey } from "../../../commom/aesKey"
import { getSpecList, submitSpecOrder } from '../../../api/vip'
export default {
  components: {
    // NavBar
  },
  data () {
    return {
      privilege_id: '',//特权id
      aesKey: aesKey(),
      specData: [],
      specShow: false,//弹窗是否出现

      //弹窗信息
      specName: '',//商品名
      official_price: '',//官方价格
      phone: '',//短信通知号码
      number: '',//充值号码
      spec_id: '',//规格ID
    }
  },
  mounted () {
    this.privilege_id = this.$route.query.privilege_id
    // console.log(this.privilege_id)
    let data = {
      privilege_id: this.privilege_id
      // privilege_id: 1
    }
    this.aesKey = aesKey(data)
    this.getSpecList(this.aesKey)
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    //获取规格数据
    getSpecList (data) {
      getSpecList(data).then(res => {
        // this.specData = res.data.data
        // console.log(this.specData)
        this.specData = res.data.data.map(item => {
          return {
            cost_price: item.cost_price,
            official_price: item.official_price,
            spec_id: item.spec_id,
            spec_name: item.spec_name,
            spec_title: item.spec_title,
            isclick: false
          }
        })
      })
    },
    //提交规格订单

    //规格卡片被点击
    getSpec (data) {
      this.specData.forEach(item => {
        item.isclick = false
      })
      data.isclick = true

      //弹窗
      // this.specShow = true
      this.spec_id = data.spec_id
      this.specName = data.spec_name
      this.official_price = data.official_price
    },
    //底部‘我的订单按钮’
    getMyOrder () {
      this.$router.push({ path: '/allVip/vipMyOrder' })
    },
    //底部‘立即购买’按钮被点击
    bottomBuy () {
      this.specData.forEach(item => {
        if (item.isclick == true) {
          this.specShow = true
        }
      })
    },

    //弹窗
    //取消按钮
    cancelOrder () {
      this.specShow = false
      this.phone = ''
      this.number = ''
    },
    //确定按钮
    async sumbitOrder () {
      let data = {
        spec_id: this.spec_id,
        phone: this.phone,
        number: this.number
      }
      console.log(data)
      this.aesKey = aesKey(data)

      let res = await submitSpecOrder(this.aesKey)
      if (res.data.code == 200) {
        Toast.success('下单成功');
        this.specShow = false
      } else {
        Toast.fail(res.data.message);
      }
    }

  },
}
</script>

<style lang="less" scoped>
#vipSpec {
  background-color: #d9d9d9;
  .content {
    text-align: left;
    font-size: 0.33rem;
    background-color: white;
    margin-top: 2rem;
    .title {
      font-weight: bold;
      padding: 0.5rem 0.3rem;
    }
    #card {
      // background-color: #fdf7e7;
      border: 1px solid #f7e5b4;
      border-radius: 10px;
      margin: 0 0.3rem;
      padding: 0.2rem 0.2rem;
      margin-bottom: 0.4rem;
      color: #db974d;
      .name {
        font-weight: bold;
        font-size: 0.4rem;
        .price {
          float: right;
        }
      }
      .spec {
        font-size: 0.25rem;
        margin-top: 0.1rem;
      }
    }
    #card.is_click {
      background-color: #fdf7e7;
    }
    .instructions {
      .title {
        padding: 0 0.3rem;
      }
      .cont {
        font-size: 0.2rem;
        padding: 0.2rem 0.3rem;
        div {
          margin-top: 0.1rem;
          color: gray;
          width: 6.3rem;
        }
      }

      padding-bottom: 1.5rem;
    }
  }
  .bottom {
    position: fixed;
    bottom: 0%;
    width: 100%;
    display: flex;
    background-color: white;
    font-size: 0.25rem;
    text-align: center;
    box-shadow: 0px -3.4px 7px rgba(0, 0, 0, 0.015),
      0px -20px 41px rgba(0, 0, 0, 0.04);
    .icon {
      width: 30%;
      padding: 0.2rem 0;
      img {
        width: 20%;
      }
    }
    .buyBtn {
      width: 40%;
      background-color: #ffd29b;
      position: relative;
      span {
        font-size: 0.38rem;
        font-weight: bold;
        color: #774208;
        position: absolute;
        top: 30%;
        left: 25%;
      }
    }
  }
  .van-popup--center {
    font-size: 0.26rem;
    border-radius: 10px;
    .title {
      font-size: 0.35rem;
      padding: 0.3rem 0;
    }
    .cont {
      // margin-top: 0.2rem;
      padding: 0 0.3rem;
      padding-bottom: 0.5rem;
      div {
        margin-top: 0.2rem;
        width: 5rem;
        text-align: left;
      }
      .van-cell {
        margin-top: 0rem;
        padding: 0;
        /deep/.van-field__label {
          width: auto;
        }
      }
    }
    .btn {
      .van-button {
        width: 50%;
      }
    }
  }
}
</style>