import { request } from './request.js'
// import qs from 'qs'

// 银行网点列表
export function getBankPoint (params) {
    return request({
      url: '/common/com/zsm/bank_point',
      method: 'GET',
      params
    })
  }

// 提交用户信息
export function submitWeiaiUser (params) {
    return request({
      url: '/agent/com/zsm/user',
      method: 'POST',
      params
    })
}



