<template>
  <div id="wechatLogin">
    <van-loading color="#1989fa" />
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
    //微信授权链接
    // window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcf46ad29ea2844c5&redirect_uri=https%3A%2F%2Fnapi.guansj.cn%2Fapi%2Fhome%2Fwechat-login-redirect&response_type=code&scope=snsapi_userinfo&state=2#wechat_redirect' //测试服
    window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa68cd9e5c0b30448&redirect_uri=https%3A%2F%2Fnewapi.guansj.com%2Fapi%2Fhome%2Fwechat-login-redirect&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'  // 正式服
  },
  methods: {

  }
}
</script>