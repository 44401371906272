<template>
  <div class="container">
    <div class="content">
      <div class="topSearch">
        <form action="/">
          <span class="address">东莞
            <van-icon name="arrow-down" />
            <span style="color:#d9d9d9"> |</span>
          </span>
          <van-search class="search"
                      v-model="value"
                      shape="round"
                      left-icon=""
                      placeholder="请输入搜索关键词">
          </van-search>
          <i class="search_icon"><img src="../../../assets/inland/搜索icon.png"
                 alt="">
          </i>
        </form>
      </div>
    </div>
    <div class="card">
      <van-grid :border="false"
                :column-num="5"
                :gutter="5">
        <van-grid-item>
          <van-image :src="buildings" />
          <span style="font-size:12px">景点门票</span>
        </van-grid-item>
        <van-grid-item>
          <van-image :src="date" />
          <span style="font-size:12px">一日游</span>

        </van-grid-item>
        <van-grid-item>
          <van-image :src="hotel" />
          <span style="font-size:12px">酒店</span>

        </van-grid-item>
        <van-grid-item>
          <van-image :src="team" />
          <span style="font-size:12px">周边跟团</span>

        </van-grid-item>
        <van-grid-item>
          <van-image :src="car" />
          <span style="font-size:12px">自驾游</span>

        </van-grid-item>
      </van-grid>
      <div class="hot">热搜: <span>东莞隐贤山庄</span></div>

    </div>
    <div class="recommend"><span style="display:flex; font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
      margin-left: 5%;">周边推荐</span>
      <div class="hotel_images">
        <vueSeamless :class-option="optionLeft"
                     class="seamless-warp2">
          <ul class="item">
            <li v-for="item in imageList"
                :key="item.name">
              <span>{{item.name}}</span>
              <img :src="item.image"
                   alt="">
            </li>
          </ul>
        </vueSeamless>

      </div>
      <div class="hot_place">
        <span class="place">港珠澳大桥</span>
        <span class="place">珠海长隆国际海洋度假区</span>
      </div>

    </div>
    <div class="List">
      <div class="top">
        <van-icon :name="xinIcon" />心选
      </div>
      <WaterFall :imgsArr="imgsArr"></WaterFall>
    </div>
  </div>
</template>

<script>
import WaterFall from "../../../components/WaterFall.vue";
import vueSeamless from 'vue-seamless-scroll'
export default {
  components: {
    WaterFall,
    vueSeamless: vueSeamless,
  },
  data () {
    return {
      value: '',
      xinIcon: require('../../../assets/neighboring/xinxuan_icon.png'),
      imgsArr: [{
        id: 1,
        describe: '三亚0购大玩家 国际五星版四天 三晚',
        img: require('../../../assets/neighboring/pexels-photo-10304563.jpeg'),
        price: 1234
      },
      {
        id: 2,
        describe: '一亚0购大玩家 国际五星版四天 三晚',
        img: require('../../../assets/neighboring/pexels-photo-705782.jpeg'),
        price: 1234
      },
      {
        id: 3,
        describe: '二亚0购大玩家 国际五星版四天 三晚',
        img: require('../../../assets/neighboring/pexels-photo-10304563.jpeg'),
        price: 1234
      },
      {
        id: 4,
        describe: '四亚0购大玩家 国际五星版四天 三晚',
        img: require('../../../assets/neighboring/pexels-photo-9297974.jpeg'),
        price: 1234
      }
      ],
      imageList: [
        { name: '一亚', image: require('../../../assets/neighboring/三亚.png') },
        { name: '二亚', image: require('../../../assets/neighboring/三亚.png') },
        { name: '三亚', image: require('../../../assets/neighboring/三亚.png') },
        { name: '四亚', image: require('../../../assets/neighboring/三亚.png') },
        { name: '五亚', image: require('../../../assets/neighboring/三亚.png') },

      ],

      date: require('../../../assets/neighboring/date.png'),
      hotel: require('../../../assets/neighboring/hotel.png'),
      team: require('../../../assets/neighboring/team.png'),
      car: require('../../../assets/neighboring/car.png'),
      buildings: require('../../../assets/neighboring/buildings.png'),
    }
  },
  computed: {
    optionLeft () {
      return {
        direction: 2,
        limitMoveNum: 2,
        step: 0, // 数值越大速度滚动越快
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  overflow: hidden;
  width: 100%;
  position: relative;
  .content {
    width: 100%;
    height: 222px;
    background-image: url("../../../assets/neighboring/bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    .topSearch {
      width: 100%;
      position: absolute;
      .search_icon {
        position: absolute;
        top: 38%;
        right: 5%;
        // width: 5%;
        display: flex;

        img {
          width: 50%;
        }
      }
      .address {
        position: absolute;
        left: 0%;
        font-size: 18px;
        margin-top: 14px;
        margin-left: 30px;
      }
      .van-search {
        background: none;
      }
      .van-field {
        left: 20%;
      }
    }
  }
  .card {
    width: 93%;
    height: 162px;
    background-color: white;
    margin-left: 3.5%;
    z-index: 2;
    border-radius: 24px;
    box-shadow: 0px 9px 16px 2px rgba(102, 102, 102, 0.16);
    top: 10%;
    position: absolute;
    /deep/ .van-grid {
      border-radius: 25px;
      margin-top: 10px;
    }
    .hot {
      font-size: 12px;
      color: #2ee8c7;
      margin-top: 10px;
      z-index: 5;
      float: left;
      margin-left: 20px;
      span {
        background-color: #2ee8c7;
        padding: 5px 10px;
        border-radius: 15px;
        color: white;
      }
    }
  }
  .recommend {
    margin-top: 130px;
    span {
    }
    .hotel_images {
      width: 109px;
      height: 144px;
      margin-left: 5%;
      span {
        position: absolute;
        font-size: 16px;
        color: white;
        top: 68%;
        left: 37%;
      }
      img {
        width: 109px;
        height: 144px;
      }
      .seamless-warp2 {
        overflow: hidden;
        // height: 320px;
        width: 380px;
        font-size: 14px;
        ul.item {
          overflow: hidden;
          height: 200px;
          // width: 100%;
          li {
            float: left;
            margin-right: 10px;
            position: relative;
            // width: 300px;
            .item_img {
              width: 168px;
              height: 122px;
              img {
                width: 168px;
                height: 122px;
              }
            }
            .item_tag {
              border: #333333;
              color: #999999;
              border: solid;
              padding-left: 5px;
              padding-right: 5px;

              // -webkit-text-size-adjust: 1%;
              font-size: 12px;
              border-radius: 5px;
              border-width: 1px;
              display: inline-block;
              transform: scale(0.7);
              position: absolute;
              left: -5%;
            }
            .item_text {
              width: 168px;
              text-align: center;
              font-size: 14px;
            }
            .item_price {
              width: 168px;
              font-size: 16px;
              color: #ff5c4c;
              position: absolute;
              margin-top: 5px;
              left: -30%;
            }
          }
        }
      }
    }
    .hot_place {
      margin-top: 10px;
      height: 40px;
      margin-left: 5%;
      .place {
        color: #000;
        font-size: 14px;
        height: 35px;
        padding: 5px 20px;
        line-height: 35px;
        margin-right: 8px;
        background: #ffffff;
        box-shadow: 0px 0px 9px 0px rgba(82, 82, 82, 0.11);
        border-radius: 8px;
        border: 0px solid #bcbcbc;
        float: left;
      }
    }
  }
  .List {
    margin-top: 70px;
    .vue-waterfall-easy-scroll {
      margin-left: 10px;
    }
    .top {
      margin-left: 20px;

      float: left;
      display: flex;
      font-size: 30px;
      line-height: 30px;
    }
    /deep/.img-box {
      padding: 0;
    }
    /deep/ .vue-waterfall-easy-scroll {
      overflow: hidden;
      padding: 0;
    }
  }
}
/deep/ .img-box .default-card-animation {
  padding: 0;
}
</style>