<template>
  <div class="Hotel"
       v-touch:right="onSwipeRight"
       id="top">
    <div>
      <!-- <NavBar :NavBarTitle='NavBarTitle'
              @onClickLeft='onClickLeft'></NavBar> -->
    </div>
    <!-- <div class="headerImg"></div>
    <div class="header">
      <div class="headerTab_black">
        <div class="headerTab_white"
             :class="{content_white:ys1,content_white_right:ys2}">
          <van-tabs v-model="active">
            <van-tab title="国内"
                     style="display: flex;">
              <div slot="title">国内</div>
              <div slot="default"
                   class="default">
                <div class="location">
                  <span class="current position">东莞</span>
                  <span class="myLocation">
                    <img src="../../../assets/HotelImg/location_icon.png"
                         alt="">
                    <span class="my">我的位置</span>
                  </span>
                </div>

                <van-cell is-link>
                  <div slot="title">酒店/地标/关键词</div>
                </van-cell>

              </div>
            </van-tab>
            <van-tab title="海外">
              <div slot="title">海外</div>
              <div slot="default"></div>
            </van-tab>

          </van-tabs>
      
        </div>
      </div>

    </div> -->

    <commonForm @getKeyword='getKeyword'></commonForm>
    <div class="VIP">
      <div class="VIP1">
        <img src="@/assets/HotelImg/baseMap1.png"
             alt="">
      </div>
      <div class="VIP1">
        <img src="@/assets/HotelImg/baseMap2.png"
             alt="">
      </div>
      <div class="VIP1">
        <img src="@/assets/HotelImg/baseMap3.png"
             alt="">
      </div>
    </div>
    <div class="preferential">
      <div class="word">
        <span class="one">精选特惠</span><span class="two">超值特惠等你选</span>
      </div>
      <div class="image">
        <div class="left">
          <img src="@/assets/HotelImg/Pimg1.png"
               alt="">
          <div class="word1">盛夏酒店榜</div>
          <div class="word2">追逐夏日晴空</div>
        </div>
        <div class="right">
          <div class="word">
            <img src="@/assets/HotelImg/Pimg2.png"
                 alt="">
            <div class="hot"><img src="@/assets/HotelImg/Picon.png"
                   alt=""></div>
          </div>
          <div class="wordII">
            <img src="@/assets/HotelImg/Pimg3.png"
                 alt="">
            <div class="hot"><img src="@/assets/HotelImg/Picon.png"
                   alt=""></div>
          </div>
        </div>
      </div>
    </div>
    <div class="hot_rec">
      <div class="H_header">
        <van-tabs v-model="active"
                  @click="tabTest"
                  id="boxFixed">
          <van-tab>

            <div slot="title"
                 slotdiv>
              <img :src="hotel_Tab_default"
                   width="88%">
            </div>
            <WaterFall :imgsArr="imgsArr"
                       @getwatId="getwatId"></WaterFall>
          </van-tab>
          <van-tab>

            <div slot="title">
              <img :src="VRhotel_default"
                   width="88%">
            </div>
            <!-- 内容 2 -->

          </van-tab>
          <van-tab>

            <div slot="title">
              <img :src="sports_default"
                   width="88%">
            </div>
            <!-- 内容 3 -->
            <WaterFall :imgsArr="esportsArr"
                       @getwatId="getwatId"></WaterFall>
          </van-tab>
          <van-tab>

            <div slot="title">
              <img :src="parents_default"
                   width="88%">
            </div>
            <!-- 内容 4 -->
          </van-tab>
        </van-tabs>
      </div>
      <!--       <div class="content">
        <ShowList :ShowHotelHotData='ShowHotelHotData'
                  @getID='getID'></ShowList>
      </div> -->

    </div>
    <div class="bottom">
      <van-tabbar v-model="bottomActive"
                  @change="switchBottomTab"
                  active-color='#58e3dd'>
        <van-tabbar-item>
          <img v-if="bottomActive == 0"
               src="@/assets/HotelImg/bottom1active.png"
               alt="">
          <img v-else
               src="@/assets/HotelImg/bottom1.png"
               alt="">
          <div>酒店首页</div>
        </van-tabbar-item>
        <van-tabbar-item icon="">
          <img v-if="bottomActive == 1"
               src="@/assets/HotelImg/bottom2active.png"
               alt="">
          <img v-else
               src="@/assets/HotelImg/bottom2.png"
               alt="">
          <div>收藏</div>
        </van-tabbar-item>
        <van-tabbar-item @click="goOrder">
          <img v-if="bottomActive == 2"
               src="@/assets/HotelImg/bottom3active.png"
               alt="">
          <img v-else
               src="@/assets/HotelImg/bottom3.png"
               alt="">
          <div>酒店订单</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 3"
               src="@/assets/HotelImg/bottom4active.png"
               alt="">
          <img v-else
               src="@/assets/HotelImg/bottom4.png"
               alt="">
          <div>我的</div>
        </van-tabbar-item>
      </van-tabbar>
      <!--创建地图容器-->
      <div v-show="showMap">
        <baidu-map :center="center"
                   :scroll-wheel-zoom="true"
                   @ready="initMap"
                   ak='VFc7NsfU7amcxYbEiqypp5a3gHPLaiOO'
                   @click="handler">
          <bm-marker :position="point"
                     :dragging="true">
            <bm-label :label-style="{ color: 'red', fontSize: '14px' }"
                      :offset="{ width: 20, height: -15 }"
                      content="当前位置" />
          </bm-marker>
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>
// import { BMPGL } from '../../../commom/bmpgl.js'
import { getHomeData } from '../../../api/hotel.js'
import { aesKey } from "../../../commom/aesKey"
// import NavBar from '../../../components/NavBar.vue'
import commonForm from "../../../components/commonForm"
import WaterFall from "@/components/WaterFall.vue";
// import ShowList from '../../../components/ShowList'
export default {
  components: {
    // NavBar,
    WaterFall,
    commonForm,
    // ShowList
  },
  data () {
    return {
      active: 0,
      pathUrl: '',
      ys1: true,
      ys2: false,
      hotel_Tab_default: require("../../../assets/HotelImg/Hotal_tab1.png"),
      sports_default: require("../../../assets/HotelImg/sports_2.png"),
      VRhotel_default: require("../../../assets/HotelImg/VRhotel_2.png"),
      parents_default: require("../../../assets/HotelImg/parents_2.png"),
      // 
      hotel_Tab1: require("../../../assets/HotelImg/Hotal_tab1.png"),
      hotel_Tab1_2: require("../../../assets/HotelImg/Hotal_tab1_2.png"),
      VRhotel_2: require("../../../assets/HotelImg/VRhotel_2.png"),
      VRhotel: require("../../../assets/HotelImg/VRhotel.png"),
      sports_2: require("../../../assets/HotelImg/sports_2.png"),
      sports: require("../../../assets/HotelImg/sports.png"),
      parents_2: require("../../../assets/HotelImg/parents_2.png"),
      parents: require("../../../assets/HotelImg/parents.png"),
      showMap: false,
      ak: 'VFc7NsfU7amcxYbEiqypp5a3gHPLaiOO',
      NavBarTitle: '酒店',//顶部导航名称
      bottomActive: 0,
      ShowHotelHotData: [],//热门推荐下的信息
      aesKey: aesKey(),//接口通过密文来获取数据
      cityInfo: '',
      BMap: '',
      active_2: 1,
      city: '',
      page: 1,
      title: 0,
      room_theme: null,
      imgsArr: [],
      esportsArr: [],
      point: {},
      address: '',
      center: {
        lat: '',
        lng: ''
      },
    }
  },
  //分享时重新获取
  beforeRouteEnter (to, from, next) {
    next(vm => {
      console.log(from.name, 'from')
      //  这里的vm指的就是vue实例，可以用来当做this使用
      vm.fromPath = from.path  //获取上一级路由的路径
      if (from.name == 'router_Attract') {
        vm.pathUrl = from.fullPath;
      }
    })
  },
  beforeMount () {

  },
  created () {
    // console.log(this.$store, "store======");
    this.getHomeData()
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    }
  },
  mounted () {
    // this.goAttract_investment()
    window.addEventListener("scroll", this.handleScroll)
    window.scrollTo(0, 0);
    sessionStorage.setItem('hotelRefresh', 1)
    let data = {
      page: 20,
      mode: 2
    }
    this.aesKey = aesKey(data)
    this.getHotelData()
  }, beforeCreate () {
    let count = 0; // eslint-disable-line no-unused-vars
    let imgs = [
      //用require的方式添加图片地址，直接添加图片地址的话，在build打包之后会查找不到图片，因为打包之后的图片名称会有一个加密的字符串
      require('../../../assets/HotelImg/white_right.png')
    ]
    for (let img of imgs) {
      let image = new Image();
      image.onload = () => {
        count++;
      };
      image.src = img;
    }
  },
  methods: {
    goAttract_investment () {
      console.log(this.pathUrl);
      // this.$router.push({
      //   path: "/allHotel/Attract_investment"
      // });
      if (this.pathUrl == '/allHotel/router_Attract') {
        setTimeout(() => {
          this.$router.push({
            path: "/allHotel/Attract_investment"
          });
        }, 2000);
      }
    },
    // 获取滚动条的滚动距离
    //预加载图片
    PreLoad_img () {
      let imgs = [
        { right: '../../../assets/HotelImg/white_right.png' }
      ]
      for (let img of imgs) {
        let image = new Image()
        image.src = img
        image.onload = () => {
          this.count++
        }
      }
    },
    handleScroll () {
      // console.log("执行滚动条方法");
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
      // // // //变量windowHeight是可视区的高度
      let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

      // // // //变量scrollHeight是滚动条的总高度
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (document.querySelector("#boxFixed") == null) {
        return;
      }
      if (this.$route.path == '/allHotel/Hotel') {
        let offsetTop = document.querySelector("#boxFixed").offsetTop; // 要滚动到顶部吸附的元素的偏移量
        let searchTop = document.querySelector("#top").offsetTop;
        // console.log("scrollTop", scrollTop);
        // console.log("windowHeight", windowHeight);
        // console.log("scrollHeight", scrollHeight);
        // console.log("offsetTop", offsetTop);
        // console.log("searchTop", searchTop);
        this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
        this.is_searFix = scrollTop > searchTop ? true : false;
        if (scrollTop + windowHeight >= scrollHeight - 10) {
          // console.log(scrollTop, "scrollTop");
          // console.log(windowHeight, "windowHeight");
          // console.log(scrollHeight, "scrollHeight");
          // console.log("到底了");
          this.page++;
          let data = {
            page: this.page,
            status: 1,
            room_theme: this.room_theme
          };
          console.log(data);
          // this.aesKey = aesKey(data);
          this.getHomeData(data);
        }
      }
    },
    // 精选酒店
    async getHomeData (data) {
      if (data) {
        await getHomeData(data).then((res) => {
          // console.log(123);
          let arr = [];
          res.data.data.list.forEach((item) => {
            if (item.status == 1) {
              arr.push(item);
            }
          });
          this.pageCount = Math.ceil(res.data.data.total / 10);
          let dataArr = arr.map((item) => {
            return {
              address: item.address,
              id: item.hotel_id,
              img: item.hotel_pic,
              describe: item.name,
              type: item.mode,
              oldPrice: item.starting_price,
              price: Math.ceil(
                Number(
                  item.starting_price *
                  (this.vipDiscount ? this.vipDiscount : 1)
                )
              ),
            };
          });
          this.imgsArr = this.imgsArr.concat(dataArr);
        });
      }
      else if (this.title == 2) {
        let data = {
          room_theme: 12
        }
        await getHomeData(data).then((res) => {
          console.log(789);

          let arr = [];
          res.data.data.list.forEach((item) => {
            if (item.status == 1) {
              arr.push(item);
            }
          });
          this.pageCount = Math.ceil(res.data.data.total / 10);
          this.esportsArr = arr.map((item) => {
            return {
              address: item.address,
              id: item.hotel_id,
              img: item.hotel_pic,
              describe: item.name,
              type: item.mode,
              oldPrice: item.starting_price,
              price: Math.ceil(
                Number(
                  item.starting_price *
                  (this.vipDiscount ? this.vipDiscount : 1)
                )
              ),
            };
          });
          // console.log(this.imgsArr)
        });
      }
      else {
        await getHomeData().then((res) => {
          // console.log(456);

          let arr = [];
          res.data.data.list.forEach((item) => {
            if (item.status == 1) {
              arr.push(item);
            }
          });
          this.pageCount = Math.ceil(res.data.data.total / 10);
          this.imgsArr = arr.map((item) => {
            return {
              address: item.address,
              id: item.hotel_id,
              img: item.hotel_pic,
              describe: item.name,
              type: item.mode,
              oldPrice: item.starting_price,
              price: Math.ceil(
                Number(
                  item.starting_price *
                  (this.vipDiscount ? this.vipDiscount : 1)
                )
              ),
            };
          });
          // console.log(this.imgsArr)
        });
      }
    },
    getwatId (data) {
      this.$router.push({
        path: "/allHotel/HotelDatail",
        query: { hotelId: data.id },
      });
    },
    //获取热门推荐数据
    getHotelData () {
      let data = {
        is_hot: 1
      }
      this.aesKey = aesKey(data)
      getHomeData(this.aesKey).then(res => {
        // console.log(res, "获取热门推荐数据");
        let arr = []
        res.data.data.list.forEach(item => {
          if (item.is_hot == 1) {
            arr.push(item)
          }
        })
        // console.log("===arr", arr);
        this.ShowHotelHotData = arr.map(item => {
          // console.log(item, "123123");
          return {
            address: item.address,
            id: item.hotel_id,
            img: item.hotel_pic,
            title: item.name,
            mode: item.mode,
            starting_price: Math.ceil(Number(item.starting_price)),
            city_name: item.city_name,
            hotel_tags: item.hotel_tags,
            discount: item.hotel_tags,
          }
        })
        // console.log("===  this.ShowHotelHotData", this.ShowHotelHotData);
      })

    },
    getKeyword (cityInfo, dateArray, hotelKey, newTypeArr, priceValue) {
      console.log(cityInfo, "城市信息");
      console.log(dateArray, "时间戳")
      // this.cityInfo=[],
      // this.dateArray=[],
      // this.hotelKey=[],
      // this.newTypeArr=[],
      // this.priceValue=[],
      this.$router.push(
        {
          path: '/allHotel/HotelList',
          query: {
            hotelKey: hotelKey,
            newTypeArr: newTypeArr,
            priceValue: priceValue,
            dateArray: dateArray,
            cityInfo: cityInfo
          }
        }

      )
      this.cityInfo = cityInfo.name
      let sessionCityData = []
      sessionCityData = JSON.stringify(cityInfo)
      sessionStorage.setItem('sessionCityData', sessionCityData)
      sessionStorage.setItem('dateArray', dateArray)
      // console.log(sessionCityData, "===sessionCityData");
      // console.log(dateArray, "===dateArray");
    },
    //获取后id跳转路由
    getID (data) {
      this.$router.push(
        {
          path: '/allHotel/HotelDatail',
          query: { hotelId: data.id }
        }
      )
    },
    switchBottomTab () {
      // console.log(this.bottomActive)
      if (this.bottomActive == 3) {
        this.$router.push({ path: '/allPerInfo/personalHome' })
      }
    },
    //按钮返回
    onClickLeft () {
      this.$router.push({ path: '/Home' })
    },
    //移动端右滑返回
    onSwipeRight () {
      // this.$router.push({ path: '/Home' })
      // console.log(123)
    },
    //订单点击
    goOrder () {
      // console.log(123)
      this.$router.push({ path: '/allHotel/HotelMyOrder' })
    },
    handler (e) {
      // console.log();
      const that = this
      // console.log(this, "=====this====");
      const myGeo = new window.BMap.Geocoder({ extensions_town: true })
      myGeo.getLocation(new window.BMap.Point(e.point.lng, e.point.lat), function (result) {
        // Toast.fail({
        //   message: result.address,
        // });
        // console.log(result);
        // console.log(result.addressComponents.city);
        result.surroundingPois[0] ? (that.address = result.surroundingPois[0].title) : (that.address = result.title)
        if (!result.surroundingPois[0] && result.title) {
          this.$toast('无法获取当前位置')
        }
        // 获取到位置后使用回调函数
        // emit()
      })
      this.point = { lat: e.point.lat, lng: e.point.lng }
      // console.log(this.point);
      // function emit () {
      //   // 把坐标和具体位置发给父元素
      //   that.$emit('onLocation', {
      //     coordinate: that.point,
      //     address: that.address
      //   })
      // }
    },
    initMap ({ BMap }) {
      this.BMap = BMap
      // 通过百度map获取经纬度再传给地图组件
      const that = this
      try {
        const geolocation = new window.BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            // console.log(r);
            that.center = { lat: r.point.lat, lng: r.point.lng }
            that.point = { lat: r.point.lat, lng: r.point.lng }
            that.handler(r)
          },
          { enableHighAccuracy: true }
        )
      } catch (error) {
        this.$toast('请打开定位或退出重试')
      }
    },
    tabTest (title) {
      console.log(title);
      if (title == 0) {
        this.title = title
        this.hotel_Tab_default = this.hotel_Tab1
        this.VRhotel_default = this.VRhotel_2
        this.sports_default = this.sports_2
        this.parents_default = this.parents_2
        this.getHomeData()
      } else if (title == 1) {
        this.title = title
        this.VRhotel_default = this.VRhotel
        this.hotel_Tab_default = this.hotel_Tab1_2
        this.sports_default = this.sports_2
        this.parents_default = this.parents_2
      } else if (title == 2) {
        this.title = title
        this.sports_default = this.sports
        this.VRhotel_default = this.VRhotel_2
        this.hotel_Tab_default = this.hotel_Tab1_2
        this.parents_default = this.parents_2
        this.getHomeData()
        this.room_theme = 12
      } else if (title == 3) {
        this.title = title
        this.parents_default = this.parents
        this.sports_default = this.sports_2
        this.VRhotel_default = this.VRhotel_2
        this.hotel_Tab_default = this.hotel_Tab1_2


      }
    }
  },
  // beforeRouteLeave (to, from, next) {
  //   console.log(to.meta.title)
  //   console.log(this.cityInfo)
  //   to.meta.title = this.cityInfo
  //   console.log(to.meta.title)
  //   next()
  // },
}
</script>
<style lang="less" scoped>
.allmap {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
}
.Hotel {
  /deep/.vue-waterfall-easy {
    margin-top: 0.1852rem;
    margin-bottom: 0.3704rem;
    .img-box {
      .default-card-animation {
        padding: 0px !important;
      }
    }
    a.img-inner-box {
      box-shadow: none;
      a {
        height: 100% !important;
      }
    }

    img {
      border-radius: 5px;
      width: 94% !important;
    }
    .waterFall {
      font-size: 0.25rem;
      text-align: left;
      margin-top: 20px;
      .headerW {
        font-size: 0.26rem;
        margin-top: -10px;
      }
      .van-search .bottomW {
        position: relative;
        padding-top: 0.15rem;
        .price {
          font-size: 0.35rem;
          font-weight: bold;
          color: #ff5c4c;
        }
        .type {
          font-size: 0.2rem;
          font-weight: bold;
          position: absolute;
          right: 5%;
          top: 36%;
        }
      }
    }
  }
  width: 100%;
  height: 100%;
  font-size: 0.3rem;
  text-align: left;
  .headerImg {
    width: 100%;
    height: 4.2593rem;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        to top,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0) 0%
      ),
      url("../../../assets/HomeIcon/formbeijing1.png");
    // border-end-end-radius: 58px;
    // border-bottom-left-radius: 58px;
  }
  .header {
    width: 100%;
    height: 100%;
    // margin-top: 0.6rem;
    .headerTab_black {
      top: -9%;
      width: 7rem;
      height: 5rem;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../../assets/custom/bg_black.png");
      // background: rgba(51, 51, 51, 0.59);
      // border-radius: 32px;
      background-size: 100%;
      .headerTab_white {
        background-image: url("../../../assets/custom/bg_white_left.png");
        position: absolute;
        width: 6.8rem;
        height: 5rem;
        padding: 0.25rem 0.3rem 0.3rem 0.28rem;
        margin-left: auto;
        margin-right: auto;
        background-size: cover;
        background-repeat: no-repeat;
        background-size: 100%;
        left: -2.5%;
        top: -6%;
        // /deep/ .van-tabs__nav {
        //   background-color: rgb(4 3 3 / 0%) !important;
        // }
        .default {
          padding: 0px 20px;
          .van-cell {
            color: #999999;
          }
        }
        .location {
          margin-top: 0.3rem;
          display: flex;
          font-size: 0.3rem;
          justify-content: space-between;
        }
        .myLocation {
          display: flex;
          color: #36e0c9;
          font-size: 0.2rem;
          img {
            width: 0.3rem;
            height: 0.4rem;
          }
          .my {
            line-height: 0.5rem;
            margin-left: 0.1rem;
          }
        }
      }
      .search {
        width: 100%;
        position: absolute;
        top: 0;
      }
      // /deep/ .van-tab {
      //   // background: rgba(51, 51, 51, 0.585758);
      //   color: white;
      // }
      // /deep/.van-tabs__wrap {
      //   // overflow: visible;
      //   // margin-top: 50px;
      //   width: 80%;
      // }
      /deep/ .van-tabs__nav {
        background: rgba(200, 54, 54, 0);
      }
      /deep/ .van-tab--active {
        color: black;
      }
      /deep/.van-tabs__line {
        background-color: #36e0c9;
        width: 0.5rem;
      }
      // /deep/.van-tabs__nav--card {
      //   height: 0.9259rem;
      //   border: none;
      //   border-top-left-radius: 22px;
      //   border-top-right-radius: 22px;
      //   background-color: transparent;
      //   width: 92%;
      //   .van-tab {
      //     color: white;
      //     width: 3.5rem;
      //     border: none;
      //     background-color: rgb(17, 16, 16);
      //     opacity: 0.4;
      //     font-size: 0.3rem;
      //     border-top-left-radius: 10px;
      //     border-top-right-radius: 10px;
      //     // font-weight: bold;
      //     .van-tab__text--ellipsis {
      //       overflow: visible;
      //     }
      //   }
      //   .van-tab--active {
      //     margin-top: -5px;
      //     background-color: white;
      //     color: black;
      //     opacity: 1;
      //     height: 1.0185rem;
      //   }
      // }
      // /deep/.van-tabs__content {
      //   width: 92%;
      //   height: 100%;
      //   padding-bottom: 20px;
      //   border-bottom-left-radius: 20px;
      //   border-bottom-right-radius: 20px;
      //   background-color: white;
      //   margin-top: 20px;
      //   margin-left: 16px;
      //   box-shadow: 0px 14.8px 8.1px -5px rgba(0, 0, 0, 0.022),
      //     0px 23.8px 18.5px -5px rgba(0, 0, 0, 0.025),
      //     0px 40.1px 41.3px -5px rgba(0, 0, 0, 0.026),
      //     0px 77px 126px -5px rgba(0, 0, 0, 0.03);
      // }
    }
  }
  .VIP {
    width: 100%;
    // margin-top: 5.7rem;
    display: flex;
    .VIP1 {
      width: 100%;
      img {
        width: 93%;
      }
    }
  }
  .preferential {
    margin-top: 0.3rem;
    padding-left: 0.2rem;
    .word {
      font-size: 0.3rem;
      .one {
        font-weight: bold;
      }
      .two {
        margin-left: 0.2rem;
        font-size: 0.18rem;
      }
    }
    .image {
      display: flex;
      margin-top: 0.2rem;
      .left {
        width: 50%;
        position: relative;
        .word1 {
          position: absolute;
          top: 55%;
          left: 23%;
          font-size: 0.4rem;
          color: white;
        }
        .word2 {
          position: absolute;
          top: 67%;
          left: 32%;
          font-size: 0.2rem;
          color: white;
        }
        img {
          width: 100%;
          // height: 50%;
        }
      }
      .right {
        margin-left: 0.15rem;
        img {
          width: 3.3rem;
          height: 2rem;
        }
        .word {
          position: relative;
          .hotText {
            position: absolute;
            top: 53%;
            left: 5%;
            color: white;
            // font-size: 1rem;
          }
          .hot {
            position: absolute;
            top: 71%;
            left: 5%;
            color: white;
            font-size: 0.2rem;
            img {
              width: 10%;
              height: 100%;
            }
          }
        }
        .wordII {
          margin-top: 0.2rem;
          position: relative;
          .hotText {
            position: absolute;
            top: 53%;
            left: 5%;
            color: white;
            font-size: 0.3rem;
          }
          .hot {
            position: absolute;
            top: 71%;
            left: 5%;
            color: white;
            font-size: 0.2rem;
            img {
              width: 10%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .hot_rec {
    margin-top: 0.3rem;
    padding-left: 0.3rem;
    margin-bottom: 1rem;
    padding-right: 0.13rem;
    /deep/.van-tabs__line {
      background-color: #36e0c9;
      width: 0.6rem;
    }
    .van-tab {
      border-radius: 20px;
      margin-right: 0.3704rem;
      border-right: none;
    }
    /deep/.van-tab--active {
      // background-color: #36e0c9;
      padding-left: 0.2778rem;
      padding-right: 0.2778rem;
      color: #333333;
      font-weight: bold;
      font-size: 0.28rem !important;
      border-radius: 20px;
    }
    .H_header {
      font-size: 0.35rem;
      font-weight: bold;
    }
  }
  .bottom {
    text-align: center;
    img {
      width: 60%;
    }
  }
  #boxFixed {
    /deep/.van-tab__text--ellipsis {
      font-size: 0.25rem;
      overflow: visible;
    }
    /deep/ .van-tabs__nav--card {
      // border-radius: 20px;
      border: none;
      margin-left: 0;

      .van-tab {
        // border-right: none;
        color: #999999;
        border-radius: 20px;
        border: 20px;
      }
    }

    /deep/.vue-waterfall-easy {
      margin-top: 0.1852rem;
      margin-bottom: 0.3704rem;
      a.img-inner-box {
        box-shadow: none;
        a {
          height: 100% !important;
        }
      }

      img {
        border-radius: 5px;
        width: 93%;
      }
      .waterFall {
        font-size: 0.25rem;
        text-align: left;
        margin-top: 0.3704rem;
        .headerW {
          font-size: 0.26rem;
          margin-top: -0.1852rem;
        }
        .van-search .bottomW {
          position: relative;
          padding-top: 0.15rem;
          .price {
            font-size: 0.35rem;
            font-weight: bold;
            color: #ff5c4c;
          }
          .type {
            font-size: 0.2rem;
            font-weight: bold;
            position: absolute;
            right: 5%;
            top: 36%;
          }
        }
      }
    }
  }
}

/deep/ .van-tabs__line {
  background-color: rgba(255, 255, 255, 0);
}
//瀑布流滚动条
/deep/.vue-waterfall-easy-container
  .vue-waterfall-easy-scroll[data-v-ded6b974] {
  overflow-x: visible;
  overflow-y: visible;
}
/deep/.vue-waterfall-easy-container
  .vue-waterfall-easy
  > .img-box[data-v-ded6b974] {
  position: static !important;
  display: inline-block;
}
</style>