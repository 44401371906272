import cryptoJs from 'crypto-js'
let  today = new Date()
today.setHours(0, 0, 0, 0)
let  timestamp = today.getTime()/1000
export function aesKey(encryptData) {
	if(encryptData==undefined){
		let data = {
			// "id": "2",
			// "name": "jimmmm",
			// "age": "25",
			// "value": "2",
			"timestamp": timestamp,
			'page_rows':15,
		}
		let data_json = JSON.stringify(data);
		let ivHex = '00000000000000000000000000000000';
		let iv = '';  
		for (let  i = 0; i < ivHex.length; i += 2) {
			iv += String.fromCharCode(parseInt(ivHex.substr(i, 2), 16));
		}
		// let key = 'dkvqi5xeYmYyyIrNTRLiz3ZV8LflQKzk'; //测试服
		let key = 'GgsKgI9qjg1qDXz1jNzONJxKOc98RkLL'; //正式服
		let sign_str = cryptoJs.AES.encrypt(
			data_json,
			cryptoJs.enc.Utf8.parse(key),
			{
				iv: cryptoJs.enc.Utf8.parse(iv),
				padding: cryptoJs.pad.Pkcs7,
				mode: cryptoJs.mode.CBC
			}
		).toString();
		let sign = sign_str.replace(/\+/g, '-');
		let loginData = {
      //appid
				// appid: 1,//正式服
        // appid: 2, //测试服
      //密钥
			// aes_key: key,
			timestamp: timestamp,
			sign: sign
		}
		return loginData;
	}else{
			let data = {
			// "id": "2",
			// "name": "jimmmm",
			// "age": "25",
			// "value": "2",
			"timestamp":timestamp,
			'page_rows':15,
			'code':encryptData.code,//公众号授权返回的code
			'appid':encryptData.appid,//公众号授权的微信id
			"page":encryptData.page,
			//酒店
			"status":encryptData.status,
			"city_id":encryptData.city_id,
			'mode':encryptData.mode,
			"hotel_id":encryptData.hotel_id,
			"room_id":encryptData.room_id,
			"room_num": encryptData.room_num,
			"real_name": encryptData.real_name,
			"phone": encryptData.phone,
			"price_id": encryptData.price_id,
			"order_status":encryptData.order_status,
			"order_no":encryptData.order_no,
      "is_hot":encryptData.is_hot,
      "start_price":encryptData.start_price,
      "end_price":encryptData.end_price,
      "room_layout":encryptData.room_layout,
			//商城
			"goods_category_top_ids":encryptData.goods_category_top_ids,
			"id":encryptData.id,//商品id
			"mobile":encryptData.mobile,
      "shopArr":encryptData.shopArr,
			"address":encryptData.address,
			"balance":encryptData.balance,
			"buy_list":encryptData.buy_list,
      "pcode":encryptData.pcode,
      "is_recommend":encryptData.is_recommend,
      "quantity":encryptData.quantity,
      "shop_order_goods_id":encryptData.shop_order_goods_id,
      "images":encryptData.images,
			//会员
			"privilege_type":encryptData.privilege_type,
			'privilege_id':encryptData.privilege_id,
			'number':encryptData.number,
			'spec_id':encryptData.spec_id,
			//微爱
			"bank_point_id":encryptData.bank_point_id,
			"name":encryptData.name,
			"id_card":encryptData.id_card,
      "user_id":encryptData.user_id,
         //JSSDK
      "url": encryptData.url,
      "apis":encryptData.apis,
      "prepay_id":encryptData.prepay_id,
      //手机验证码
      "type":encryptData.type,
      "verification_key":encryptData.verification_key,
      "verification_code":encryptData.verification_code,
      //保存地址
      "area_code":encryptData.area_code,
      "e_time":encryptData.e_time,
      "s_time":encryptData.s_time,
		}
    // aesKey时间戳
		// console.log(data)
		let data_json = JSON.stringify(data);
		let ivHex = '00000000000000000000000000000000';
		let iv = '';
		for (let  j = 0; j < ivHex.length; j += 2) {
			iv += String.fromCharCode(parseInt(ivHex.substr(j, 2), 16));
		}
    // let key = 'dkvqi5xeYmYyyIrNTRLiz3ZV8LflQKzk'; //测试服
		let key = 'GgsKgI9qjg1qDXz1jNzONJxKOc98RkLL'; //正式服
		let sign_str = cryptoJs.AES.encrypt(
			data_json,
			cryptoJs.enc.Utf8.parse(key),
			{
				iv: cryptoJs.enc.Utf8.parse(iv),
				padding: cryptoJs.pad.Pkcs7,
				mode: cryptoJs.mode.CBC
			}
		).toString();
		let sign = sign_str.replace(/\+/g, '-');
		let loginData = {
			appid: 1,//正式服
      // appid: 2, //测试服
			// aes_key: key,
			timestamp:timestamp,
			sign: sign
		}
		// console.log(loginData)
		return loginData;
	}
	

}