<template>
  <div class="container">
    <van-tabs v-model="activeName"
              line-width="width:60%">
      <van-tab title="酒店"
               name="a">
        <div class="content_bg">
          <div class="content">
            <van-tabs>
              <van-tab title="待评价">
                <div class="seller">
                  <div class="top">
                    <div class="seller_photo"></div>
                    <div class="seller_name">观视界自营</div>
                  </div>
                  <div class="goods_info">
                    <div class="goods_img"></div>
                    <div class="goods_name">喜喜喜喜喜喜喜喜喜喜</div>
                    <div class="goods_specification">
                      <span>颜色分类:黑色</span><span>容量:300ml</span>
                    </div>
                    <div class="btn">
                      <van-button size="mini">评价</van-button>
                    </div>
                  </div>
                </div>
                <div class="seller">
                  <div class="top">
                    <div class="seller_photo"></div>
                    <div class="seller_name">观视界自营</div>
                  </div>
                  <div class="goods_info">
                    <div class="goods_img"></div>
                    <div class="goods_name">喜喜喜喜喜喜喜喜喜喜</div>
                    <div class="goods_specification">
                      <span>颜色分类:黑色</span><span>容量:300ml</span>
                    </div>
                    <div class="btn">
                      <van-button size="mini">评价</van-button>
                    </div>
                  </div>
                </div>
              </van-tab>
              <van-tab title="已评价">
                <div class="pastEvaluate">
                  <div class="seller">
                    <div class="date">2022-09-23</div>
                    <div class="evaluate_content">
                      <div class="evaluate_text">钢化膜的质量和手感都不错，不怎么留痕迹清晰度也够，换了膜感觉又是新手机了</div>
                      <div class="evaluate_img"></div>
                      <div class="evaluate_img"></div>
                    </div>
                    <div class="goods">
                      <div class="goods_img"></div>
                      <div class="goods_info">
                        <div class="gods_title">适用荣耀畅玩20钢化膜全屏覆盖保护新畅...</div>
                        <div class="goods_specification_2">颜色分类: 银色 容量: 300ml</div>
                      </div>
                    </div>
                  </div>
                </div>
              </van-tab>

            </van-tabs>
          </div>
        </div>
      </van-tab>
      <van-tab title="商城"
               name="b">内容 2</van-tab>
      <van-tab title="旅游"
               name="c">内容 3</van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: 'a',
    }
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  background-color: #f5f5f5;

  /deep/ .van-tabs__line {
    background-color: black !important;
    width: 5%;
  }

  /deep/ .van-tab--active .van-tab__text--ellipsis {
    font-weight: 600 !important;
  }
  .content_bg {
    width: 100%;
    // height: 500px;
    background-color: #f5f5f5;
    padding-top: 15px;
    height: 800px;

    .content {
      overflow: hidden;
      width: 93%;
      background-color: white;
      margin-left: 3.5%;
      border-radius: 12px;
      /deep/ .van-tabs__nav--line {
        border-radius: 12px !important;
      }
      /deep/ .van-tabs__wrap {
        width: 50%;
      }
      // 已经评价的
      .pastEvaluate {
        margin-top: 10px;
        .evaluate_content {
          overflow: hidden;
        }
        .goods {
          overflow: hidden;
          margin-top: 10px;
          // height: 44px;
          font-size: 12px;
          width: 100%;
          background-color: yellow;
          .gods_title {
            float: left;
          }
          .goods_img {
            float: left;

            width: 44px;
            height: 44px;
            background-color: rgb(31, 109, 50);
          }
          .goods_specification_2 {
            float: left;

            color: #999999;
          }
        }
      }

      .seller {
        .date {
          font-size: 12px;
          display: flex;
          width: 100%;
        }
        .evaluate_text {
          font-size: 12px;
          text-align: left;
        }
        .evaluate_img {
          float: left;
          width: 60px;
          height: 60px;
          background-color: #32d1bb;
          display: inline-block;
          margin-right: 10px;
        }
        border-block-end: 5px solid #f5f5f5;
        width: 96%;
        padding: 2%;
        // height: 120px;
        overflow: hidden;
        background-color: antiquewhite;
        /deep/ .van-card {
          .van-card__content {
            padding: 0px;
            -webkit-box-flex: none !important;
            -webkit-flex: none !important;
            flex: none !important;
          }
        }

        .top {
          display: block;
          width: 100%;
          display: flex;
          .seller_photo {
            border-radius: 20px;
            width: 20px;
            height: 20px;
            background-color: #b4b4b4;
          }
          .seller_name {
            margin-left: 10px;
            font-size: 12px;
            line-height: 20px;
          }
        }
        .goods_info {
          margin-top: 10px;
          width: 100%;
          .goods_img {
            float: left;
            width: 82px;
            height: 82px;
            background-color: white;
            border-radius: 10px;
          }
          .goods_name {
            float: left;
            margin-left: 10px;
            font-size: 12px;
          }
          .goods_specification {
            float: left;
            margin-top: 10px;
            margin-left: 10px;

            font-size: 12px;
            color: #d8d8d8;
          }
          .btn {
            float: right;
            .van-button {
              width: 77px;
              padding: 0 5px;
              // border: #36e0c9;
              height: 30px;
              border-radius: 20px;
              color: #32d1bb;
            }
            /deep/ .van-button--default {
              border: #32d1bb 1px solid !important;
            }
          }
        }
      }
    }
  }
}
</style>
