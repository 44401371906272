import { request } from './request.js'
//申请绑定
export function postBankInfo(params){
return request({
  url:'/agent/user/bank-card/apply',
  method:'POST',
  params
})
}
//确认绑定
export function postBank(params){
  return request({
    url:'/agent/user/bank-card/bind',
    method:'POST',
    params
  })
  }
//查看银行卡列表
  export function inquireBank(params){
    return request({
      url:'/agent/user/bank-card/list',
      method:'POST',
      params
    })
    }