<template>
  <div class="container">
    <div class="details"
         v-for="item in payOrder"
         :key="item.id">
      <div class="record">
        <span>充值</span>
        <span style="color:#ffc300">+{{item.pay_money}}</span>
      </div>

      <div class="record">
        <span style="color:#999999">{{item.update_time}}</span>
        <span style="color:#999999">余额:{{item.user_wallet_record.last}}</span>
      </div>
      <van-divider dashed
                   :style="{ color: '#1989fa', borderColor: '#E5E5E5', padding: '0 16px' }" />
    </div>
  </div>
</template>

<script>
import { getUserPayOrder } from '@/api/user'
export default {
  data () {
    return {
      payOrder: null
    }
  },
  created () {
    this.getUserPayOrder()
  },
  methods: {
    getUserPayOrder () {
      getUserPayOrder().then(res => {
        this.payOrder = res.data.data.list
        console.log(this.payOrder);
      })
    }
  },
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  .details {
    .record {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      padding: 0 16px;
      margin-top: 10px;
    }
  }
}
</style>