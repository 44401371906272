<template>
  <div class="container">
    <div class="goods_info">
      <span class="tab">退款商品</span>
      <van-card num="2"
                v-for="item in goodsData[0].shop_order_detail[0].shop_order_goods "
                :key="item.id"
                :price="item.total_price"
                :desc="item.goods_sku_name"
                :title="item.goods_name "
                :thumb="item.goods_image" />
    </div>
    <div class="AfterSale">
      <span class="tab">退款信息</span>
      <van-cell-group inset>
        <van-cell value="请选择"
                  is-link>
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <span class="custom-title">退款原因</span>
          </template>
        </van-cell>
        <van-cell value=""
                  label="可修改,最多...含运费..."
                  is-link>
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <span class="custom-title">退款金额</span>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="AfterSale_images">
      <span class="tab">补充描述和凭证</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      goodsData: null
    }
  },
  created () {
    let goodsData = sessionStorage.getItem("goodsData")
    this.goodsData = JSON.parse(goodsData)
    console.log(this.goodsData);
  }
}
</script>

<style lang="less" scoped>
.container {
  overflow: hidden;
  width: 100%;
  height: 10rem;
  padding-top: 0.3rem;
  background-color: #fafafa;
  .van-card {
    background-color: white;
    border-radius: 20px;
    /deep/ .van-card__desc {
      display: inline;
      padding: 0.05rem 0.1rem;
      background-color: #f5f5f5;
      color: #999999;
      border-radius: 5px;
    }
  }
  .goods_info {
    border-radius: 20px;
    text-align: left;
    background-color: white;
    .tab {
      font-size: 18px;
      font-weight: 600;
      padding-left: 0.3rem;
    }
  }
  .AfterSale {
    .van-cell-group {
      padding-bottom: 0.5rem;
    }
    .tab {
      border-radius: 20px 20px 0 0;
      font-size: 18px;
      font-weight: 600;
      background-color: white;
      display: block;
      text-align: left;
      padding: 0.2rem 0rem 0.2rem 0.3rem;
    }
    margin-top: 20px;
    .van-cell-group--inset {
      margin: 0;
    }
    .van-cell__title {
      text-align: left;
      // padding-left: 0.3rem;
      .van-cell__label {
        width: 130%;
      }
    }
  }
  .AfterSale_images {
    .tab {
      border-radius: 20px 20px 0 0;
      font-size: 18px;
      font-weight: 600;
      background-color: white;
      display: block;
      text-align: left;
      padding: 0.2rem 0rem 0.2rem 0.3rem;
    }
    width: 100%;
    height: 4rem;
    background-color: white;
    margin-top: 10px;
    border-radius: 15px;
  }
}
</style>