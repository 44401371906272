<template>
  <div class="container">
    <div class="top">
      <div class="totalGrade">
        <div style="color:#00e0c2">
          <span style="font-size:28px">5.0</span><span style="font-size:14px;padding-top: .1455rem;">超棒</span>
        </div>
        <div style="color:#666666;display: flex;">
          <div style="font-size:12px; display: flex;
        justify-content: flex-start;">高于99%的同类</div>
        </div>
      </div>

      <div class="progress">
        <div class="progress_text">
          <span>卫生:5.0</span>
          <van-progress pivot-text="红色"
                        color="#01e7c8"
                        :show-pivot=false
                        :percentage="100" />
        </div>
        <div class="progress_text">
          <span>环境:5.0</span>
          <van-progress pivot-text="红色"
                        color="#01e7c8"
                        :show-pivot=false
                        :percentage="10" />
        </div>
        <div class="progress_text">
          <span>服务:5.0</span>
          <van-progress pivot-text="红色"
                        color="#01e7c8"
                        :show-pivot=false
                        :percentage="30" />
        </div>
        <div class="progress_text">
          <span>设施:5.0</span>
          <van-progress pivot-text="红色"
                        color="#01e7c8"
                        :show-pivot=false
                        :percentage="50" />
        </div>
      </div>
    </div>
    <div class="screen">
      <van-dropdown-menu active-color="#01e7c8">
        <van-tabs v-model="active">
          <van-tab title="全部">
            <div class="evaluate">
              <div class="tab_btn">
                <van-button>最新</van-button>
                <van-button>服务很好</van-button>
                <van-button>游戏流畅</van-button>
              </div>
              <!-- 评论内容 -->
              <div class="user_evaluate">
                <div class="user_info">
                  <div class="user_img"></div>
                  <div class="user_name">
                    <span>CHENJIXING</span>
                    <span class="new">新客点评</span>
                  </div>
                  <div class="user_evaluate_date">

                  </div>
                </div>
                <div class="user_evaluate_grade">
                  <div style="overflow: hidden;display:inline"> <img src="../../../assets/HotelImg/user_evaluate.png"
                         alt=""></div>

                  <span class="user_roomType">兄弟上分双排大床房
                    <van-icon name="arrow" />
                  </span>
                </div>
                <div class="text">第一次过来玩，按照导航过来，停车位还算充足，酒店免费停车，前台的服务员好热情，房间的床垫还可以吧，个人喜欢稍微硬点的，特别的地方是灯光比较有科技感，希望以后多点优惠活动</div>
                <div class="user_images"
                     style="overflow: hidden;">
                  <Preview></Preview>
                </div>
                <div class="hotel_reply">
                  <div class="reply"></div>
                </div>
              </div>
              <!-- 真实数据 -->
              <div class="user_evaluate"
                   v-for="item in commentList"
                   :key="item.id">
                <div class="user_info">
                  <div class="user_img"><img style="width:100%"
                         :src="item.user.avatar"
                         alt=""></div>
                  <div class="user_name">
                    <span>{{item.user.nickname}}</span>
                    <span class="new">新客点评</span>
                  </div>
                  <div class="user_evaluate_date">
                    2022年08月入住 | 发布于广东
                  </div>
                </div>
                <div class="user_evaluate_grade">
                  <div style="overflow: hidden;display:inline"> <img src="../../../assets/HotelImg/user_evaluate.png"
                         alt=""></div>

                  <span class="user_roomType">兄弟上分双排大床房
                    <van-icon name="arrow" />
                  </span>
                </div>
                <div class="text">{{item.comment}}</div>
                <!-- 用户上传的照片 -->
                <div class="user_images"
                     style="overflow: hidden;">
                  <div class="images"
                       v-for="(item2,index) in item.media_ids"
                       :key="(item2,index)">
                    <Preview :previewArry="item2"
                             @click="btn_media_ids(item2,index)"></Preview>
                  </div>
                  <div class="hotel_reply">
                    <div class="reply"></div>
                  </div>
                </div>
              </div>
            </div>

          </van-tab>
          <van-tab title="有图·视频">内容 2</van-tab>
          <van-tab title="差评">内容 3</van-tab>
        </van-tabs>
        <van-dropdown-item v-model="value"
                           ref="item"
                           title="筛选"
                           @change="changeType">
          <!-- 筛选1 -->
          <van-cell center
                    title="房型">
          </van-cell>
          <div style="position: relative;">
            <div class="roomType">
              <van-button>全部</van-button>
              <van-button>电竞双排</van-button>
              <van-button>全部</van-button>
              <van-button>电竞双排</van-button>
              <van-button>全部</van-button>
              <van-button>电竞双排</van-button>
              <div class="showMore"> <span @click="showMoreTpye">{{showMore}}
                  <van-icon :name="showMore_icon" />
                </span> </div>
            </div>
          </div>
          <!-- 筛选2 -->
          <div>
            <van-cell center
                      title="房型">
            </van-cell>
            <div class="hotelRoomType">
              <van-button>情侣</van-button>
              <van-button>亲子</van-button>
              <van-button>家庭</van-button>
              <van-button>出差</van-button>
              <van-button>独自出行</van-button>
            </div>
          </div>
          <div class="refresh">
            <div class="reset">重置</div>
            <div class="show">查看</div>
          </div>
        </van-dropdown-item>

      </van-dropdown-menu>
    </div>
  </div>
</template>

<script> 
import { commentList } from '@/api/collect.js'
import Preview from '@/components/Preview.vue'
export default {
  components: {
    Preview
  },
  data () {
    return {
      value: 0,
      active: 0,
      showMore_icon: 'arrow-down',
      isMore: false,
      commentList: null,
      total: null,
      page: null,
      showMore: '查看剩下房型',
      option: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 },
      ],
    };
  },
  created () {
    this.getHotelComment()
  },
  methods: {
    btn_media_ids () {
      console.log("父组件点击了媒体");
    },
    getHotelComment () {
      let hotelDetail = JSON.parse(sessionStorage.getItem('hotelDetail'))
      let data = {
        type: 2,
        product_id: hotelDetail.id,
      }
      commentList(data).then(res => {
        this.commentList = res.data.data.list
        this.page = res.data.data.page
        this.total = res.data.data.total
        console.log(res.data.data);
      })
    },
    onConfirm () {
      this.$refs.item.toggle();
    },
    changeType () {
      console.log("点击了筛选");
    },
    showMoreTpye () {
      if (this.isMore == false) {
        this.isMore = true
        document.querySelector('.roomType').style.height = 100 + '%'
        this.showMore_icon = 'arrow-up'

      } else {
        this.isMore = false
        this.showMore_icon = 'arrow-down'
        document.querySelector('.roomType').style.height = 100 + 'px'
      }
      console.log("点击了查看更多");
    }
  },
}
</script>

<style lang="less" scoped>
.container {
  // overflow: hidden;
  width: 100%;
  .top {
    padding: 0.2727rem;

    height: 0.9091rem;
    .totalGrade {
      float: left;
      div {
        display: flex;
        justify-content: flex-start;
        // float: left;
      }
    }
    /deep/ .progress {
      font-size: 12px;
      // float: right;
      .progress_text {
        display: inline-block !important;
        margin-left: 0.2909rem;
        margin-top: 0.1273rem;
      }
      .van-progress {
        display: inline-block !important;

        margin-bottom: 0.0364rem;
        margin-left: 0.0545rem;
        height: 0.1091rem;
        width: 0.7273rem !important;
      }
    }
  }
  /deep/.screen {
    padding-bottom: 0.9091rem;
    .evaluate {
      margin-left: 0.2727rem;

      // overflow: hidden;
      .tab_btn {
        width: 95%;
        height: 0.8rem;
        background-color: #f0f6f5;
        padding-top: 0.2182rem;
        padding-left: 0.2182rem;
        .van-button {
          margin-right: 0.3636rem;
          height: 0.5091rem;
          padding: 0.1455rem;
          font-size: 12px;
          float: left;
          color: #00e0c2;
        }
        .van-button--default {
          border: white !important;
        }
      }
      .user_evaluate {
        .hotel_reply {
          background-color: #f7f8fa;
          overflow: hidden;
          width: 95%;
          height: 2.1818rem;
          border-radius: 5px;
          margin-top: 0.1818rem;
        }
        .text {
          padding-right: 0.2727rem;
          margin-top: 0.1818rem;
          font-size: 12px;
          text-align: left;
          float: left;
        }
        .user_images {
          width: 100%;
          overflow: hidden;
          img {
            // width: 1.4545rem;
            // height: 1.4545rem;
          }

          .images {
            width: 100%;

            margin-top: 0.1818rem;
            border-radius: 5px;
            float: left;
            display: inline-block;
            width: 1.4545rem;
            height: 1.4545rem;
            // background-color: #01e7c8;
            margin-right: 15px;
          }
        }
        overflow: hidden;
        .user_evaluate_grade {
          width: 100%;
          margin-top: 0.1818rem;
          float: left;
          img {
            overflow: hidden;
            float: left;
            width: 0.9455rem;
            height: 0.2909rem;
          }
          .user_roomType {
            margin-left: 0.0909rem;
            float: left;
            font-size: 12px;
            display: inline-block;
          }
        }
        margin-top: 0.3636rem;
        .user_info {
          overflow: hidden;
          .user_img {
            background-color: #f7f8fa;
            float: left;
            position: relative;
            width: 0.5818rem;
            height: 0.5818rem;
            img {
              left: 0%;
              position: absolute;
              width: 0.5818rem;
              height: 0.5818rem;
            }
          }
        }

        .user_name {
          display: block;
          font-size: 12px;
          display: flex;
          width: 80%;
          .new {
            background-color: #d0fff9;
            padding: 0.0545rem 0.0909rem;
            border-radius: 20px;
            font-size: 0.1455rem;
            margin-left: 0.0909rem;
          }
        }
        .user_evaluate_date {
          // margin-right: 150px;
          display: block;
          float: left;
          font-size: 12px;
        }
      }
    }

    .roomType {
      border-bottom: 0.5455rem solid white;
      width: 100%;
      height: 1.8182rem;
      overflow: hidden;
      .showMore {
        font-size: 15px;
        color: #01e7c8;
        position: absolute;
        bottom: 3%;
        left: 38%;
      }
      .van-button {
        width: 45%;
        border-radius: 5px;
        margin-left: 3.5%;
        float: left;
        margin-bottom: 0.0727rem;
        background-color: #f5f6fa;
      }
      .van-button--default {
        border: white !important;
      }
    }
    .hotelRoomType {
      padding-bottom: 20px;
      overflow: hidden;
      width: 97%;
      padding-left: 3%;
      .van-button--default {
        border: white !important;
      }
      .van-button {
        width: 23%;
        margin-bottom: 0.0909rem;
        background-color: #f5f6fa;
        border-radius: 5px;
        margin-right: 6px;
        float: left;
      }
    }
    .refresh {
      text-align: center;
      font-size: 16px;
      width: 100%;
      height: 0.6545rem;
      .reset {
        display: inline-block;
        height: 0.6545rem;
        line-height: 36px;

        width: 30%;
      }
      .show {
        display: inline-block;
        height: 0.6545rem;
        line-height: 36px;

        width: 70%;
        background-color: #01e7c8;
        color: white;
      }
    }
    .van-cell__title {
      display: block;
      text-align: left;
    }
    .van-cell::after {
      border-bottom: 0px;
    }
    .van-dropdown-menu__bar {
      margin-right: 0.3636rem;
      margin-top: 0.1818rem;
      float: right;
      box-shadow: none !important;
      z-index: 99;
    }
    .van-tabs__wrap {
      justify-content: start;
      display: inline-block;
      width: 70%;
    }
    .van-tabs__line {
      width: 0.3636rem;
      border-radius: 20px;
      background-color: #00e0c2;
    }
    .van-tab--active {
      color: #00e0c2;
    }
  }
}
</style>