<template>
  <div class="container">
    <div class="content">
      <span><img src="../../../assets/HotelImg/gou.png"
             alt=""></span>
      <span>填写完成</span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.container {
  .content {
    margin-top: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    img {
      width: 20%;
    }
  }
}
</style>