<template>
  <div id="HotelOrder">
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="room_name">
      <div class="date">
        <div class="title">
          {{ this.ArrivalDate }} - {{ this.LeavelDate }}
          <span>共{{ dateLength }}晚</span>
        </div>
        <div class="thinner-border"
             style="margin-top: 0.2rem"></div>
      </div>
      <div class="rName">
        <div class="title">
          {{ roomData.title }}
          <span style="font-size: 0.2rem; color: #0086f7; float: right">房型详情
            <van-icon name="arrow" />
          </span>
        </div>
        <div style="font-size: 0.2rem; color: gray">
          <span style="margin-right: 0.1rem"
                v-if="roomData.bed_num">{{ roomData.bed_num }}张床 |</span>
          <span style="margin-right: 0.1rem"
                v-if="roomData.room_size">{{ roomData.room_size }}m² |</span>
          <span style="margin-right: 0.1rem"
                v-if="roomData.floor">{{ roomData.floor }}层 |</span>
          <span style="margin-right: 0.1rem"
                v-if="roomData.internet">{{ roomData.internet }} |</span>
          <span style="margin-right: 0.1rem"
                v-if="roomData.meals">{{ roomData.meals }} |</span>
        </div>
        <div style="font-size: 0.2rem; color: #24c5af">
          <span style="margin-right: 0.3rem">
            {{ this.ArrivalDate }}18:00前可免费取消订单</span>
          <span>可快速确认订单</span>
        </div>
      </div>
    </div>
    <div class="room_musRea">
      <div class="title">
        <img src="@/assets/HotelImg/tinme_icon.png"
             alt="" />
        <a style="margin-left: 0.4rem">订房必读</a>
        <span style="font-size: 0.2rem; color: #0086f7; float: right"
              @click="getMusrea">查看全部
          <van-icon name="arrow" />
        </span>
      </div>
      <!-- <ul>
        <li>该房型允许携带儿童入住</li>
        <li>18岁及以上客人视为成人</li>
        <li>每间客房最多容纳1名17岁及以下儿童</li>
      </ul> -->
    </div>
    <div class="room_intoInfo">
      <div style="padding-left: 0.3rem; width: 90%">
        <div class="title">
          入住信息
          <span style="font-size: 0.2rem; color: gray">
            姓名与证件保持一致，每间只需填1人
          </span>
        </div>
        <div class="thinner-border"></div>
        <div class="formInfo">
          <div @click="roomNum"
               style="height: 0.5rem; padding-top: 0.2rem">
            <span class="left">房间数</span>
            <span class="right">
              <span style="color: black; margin-right: 0.1rem; font-size: 0.27rem">{{ PeoNum }}间</span>
              <span>每间最多住2人</span>
              <van-icon style="float: right; line-height: 0.3704rem; font-size: 0.3rem"
                        name="arrow-down"
                        v-if="isShow" />
              <van-icon style="float: right; line-height: 0.3704rem; font-size: 0.3rem"
                        name="arrow-up"
                        v-else />
            </span>
          </div>
          <div class="choiceNum"
               v-show="!isShow">
            <span id="btn"
                  v-for="(item, index) in 9"
                  :key="item.id"
                  @click="getNum(item, index)"
                  :class="{ is_active: index == current }">{{ item }}</span>
          </div>
          <div class="thinner-border"></div>
        </div>
        <div class="formInfo"
             v-for="item in Rname"
             :key="item.id">
          <div>
            <span class="left">住客姓名</span>
            <span class="right">
              <van-form validate-first>
                <!-- 通过 pattern 进行正则校验 -->
                <van-field v-model="item.name"
                           name="pattern"
                           placeholder="输入住客姓名"
                           :rules="[{ pattern: /[\u4e00-\u9fa5_a-zA-Z0-9]{2,10}/ }]" />
              </van-form>
              <van-icon class="blue_icon"
                        name="user-circle-o"
                        @click="addPeople" />
            </span>
          </div>
          <div class="thinner-border"></div>
        </div>
        <div class="formInfo">
          <div>
            <span class="left">联系手机</span>
            <span class="right">
              <van-form validate-first>
                <!-- 通过 pattern 进行正则校验 -->
                <van-field v-model="phone"
                           name="pattern"
                           placeholder="输入手机号码"
                           :rules="[{ pattern: /^1[0-9]{10}$/ }]" />
              </van-form>
              <img src="@/assets/HotelImg/mailList.png"
                   alt="" />
            </span>
          </div>
          <div class="thinner-border"></div>
        </div>
        <div class="code"
             v-if="phone_show">
          <div>
            <span class="left">验证码</span>
            <span class="right">
              <van-form validate-first>
                <!-- 通过 pattern 进行正则校验 -->
                <van-field v-model="code"
                           maxlength="6"
                           placeholder="输入验证码" />
              </van-form>
              <div v-show="show_code"
                   class="code_btn"
                   @click="code_btn">
                获取验证码
              </div>
              <div v-show="!show_code"
                   class="code_btn">倒计时:{{ count }}</div>
            </span>
          </div>
          <div class="thinner-border"></div>
        </div>
        <div class="formInfo">
          <div style="height: 0.5rem; padding-top: 0.2rem"
               @click="getHomeTime">
            <span class="left">预计到店</span>
            <span class="right">
              <span style="color: black; margin-right: 0.1rem; font-size: 0.27rem">{{ estimateTime }}</span>
              <span>房间整晚保留</span>
              <van-icon style="float: right; line-height: 0.3704rem; font-size: 0.3rem"
                        name="arrow" />
            </span>
          </div>
          <div class="thinner-border"></div>
        </div>
      </div>
    </div>
    <!-- <div class="discount">
      <div class="room_intoInfo">
        <div style="padding-left:0.3rem;width:90%;">
          <div class="title">
            本单可享
          </div>
          <div class="thinner-border"></div>
          <div class="formInfo">
            <div style="height:0.5rem;padding-top:0.2rem">
              <span class="left">抵用券</span>
              <span class="right">
                <van-tag plain
                         type="primary"
                         color="#FC5253">8.5折减￥133</van-tag>
                <van-tag plain
                         type="primary"
                         color="#FC5253">满￥800减￥80</van-tag>
                <van-icon style="float: right;line-height:20px;font-size:0.3rem"
                          name="arrow" />
              </span>
            </div>
            <div class="thinner-border"></div>
          </div>
          <div class="formInfo">
            <div style="height:0.5rem;padding-top:0.1rem">
              <span class="left">积分兑换</span>
              <span class="right">
                <van-tag type="primary"
                         color="#FE653C">离店后送328积分</van-tag>
                <van-tag type="primary"
                         color="#FE653C">积分兑换商城</van-tag>
                <van-icon style="float: right;line-height:20px;font-size:0.3rem"
                          name="arrow" />
              </span>
            </div>
            <div class="thinner-border"></div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="moreServ">
      <div class="room_intoInfo">
        <div style="padding-left: 0.3rem; width: 90%">
          <div class="title">下单可享更多服务</div>
          <div class="thinner-border"></div>
          <div class="formInfo">
            <div style="height: 0.5rem; padding-top: 0.2rem">
              <span class="left">发票</span>
              <span class="right">
                <span>如需要发票，可向酒店索取</span>
              </span>
            </div>
            <div class="thinner-border"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="expense_detail">
      <div class="room_intoInfo">
        <div style="padding-left: 0.3rem; width: 90%">
          <div class="title">费用明细</div>
          <div class="thinner-border"></div>
          <div class="payment">
            <span class="left">在线支付</span>
            <div class="right">
              {{ PeoNum }}间{{ dateLength }}晚 共
              <span>￥{{ totalPrice * PeoNum }}</span>
            </div>
            <div class="thinner-border"
                 style="margin-top: 0.2rem"></div>
          </div>
          <div class="detail_content">
            <div style="margin-bottom:6px">支付方式 <div style="display:inline-block;font-size:2.4vw">可使用赠送金、余额支付</div>
            </div>
            <span style="margin-bottom:6px"
                  class="left"
                  v-if="user_info">赠送金:
              <div style="display: inline-block; color: #fe6529">
                ￥{{ user_info.integral }}
              </div>
              <div class="right">
                <van-radio-group v-model="integral"
                                 @change="integral_radio">
                  <van-radio name="integral"
                             @click="integralRadio()">
                  </van-radio>
                </van-radio-group>
              </div>
            </span>
            <span class="left"
                  style="margin-bottom:6px"
                  v-if="user_info">余额:
              <div style="display: inline-block; color: #fe6529">
                ￥{{ user_info.wallet }}
              </div>
              <div class="right">
                <van-radio-group v-model="wallet"
                                 @change="wallet_radio">
                  <van-radio name="wallet"
                             @click="walletRadio()"> </van-radio>
                </van-radio-group>
              </div>
            </span>
            <span class="left"
                  style="margin-bottom:6px">房费</span>
            <div class="right">
              <span>￥{{ totalPrice * PeoNum }}</span>
            </div>
            <div style="margin-top: 0.1rem; color: gray">
              <span class="left"
                    style="color: gray">{{ nowYear }}年{{ ArrivalDate }}无早餐</span>
              <div class="right">
                <span>{{ PeoNum }} x ￥{{ totalPrice }}</span>
              </div>
            </div>
            <div style="margin-top: 0.1rem; color: gray">
              <span class="left"
                    style="color: gray">{{ nowYear }}年{{ LeavelDate }} 无早餐</span>
            </div>
            <div class="thinner-border"
                 style="margin-top: 0.2rem"></div>
          </div>
          <div class="detail_content">
            <span class="left">优惠</span>
            <div class="right">
              <span>-￥0</span>
            </div>
            <div style="margin-top: 0.1rem; color: gray">
              <span class="left">初夏特惠红包</span>
              <!-- <div class="right"><span>-¥133</span></div> -->
            </div>
            <div style="margin-top: 0.1rem; color: gray">
              <span class="left"
                    style="color: gray">{{ nowYear }}-{{ ArrivalDate }}无早餐</span>
            </div>
            <div style="margin-top: 0.1rem; color: gray">
              <span class="left">豪华首单红包</span>
              <!-- <div class="right"><span>-¥80</span></div> -->
            </div>
            <div style="margin-top: 0.1rem; color: gray">
              <span class="left"
                    style="color: gray">{{ nowYear }}年{{ LeavelDate }}无早餐</span>
            </div>
            <div class="thinner-border"
                 style="margin-top: 0.2rem"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="expense_detail">
      <div class="room_intoInfo">
        <div style="padding-left: 0.3rem; width: 90%">
          <div class="title">重要提示</div>
          <div class="thinner-border"></div>
          <div class="detail_content">
            <span class="left">退款规则</span>
            <div style="margin-top: 0.1rem; color: gray">
              <span class="left"
                    style="color: gray">根据酒店政策，18点前可免费取消，逾期不可取消/变更，如未入住，酒店将扣除全部房费</span>
            </div>
            <div class="thinner-border"
                 style="margin-top: 0.2rem"></div>
          </div>
          <div class="detail_content">
            <span class="left">预订说明</span>
            <div style="margin-top: 0.1rem; color: gray">
              <span class="left"
                    style="color: gray">订单需等酒店或供应商确认后才生效，订单确认结果以短信或
                邮件通知为准；请在订单生效后再至酒店前台办理入住</span>
            </div>
            <div class="thinner-border"
                 style="margin-top: 0.2rem"></div>
          </div>
          <div class="detail_content">
            <span class="left">温馨提示</span>
            <div style="margin-top: 0.1rem; color: gray">
              <span class="left"
                    style="color: gray">酒店所有房型可以加床，加床收费230元/张</span>
            </div>
            <div class="thinner-border"
                 style="margin-top: 0.2rem"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="backGray">
      <span class="left">温馨提示</span>
      <div>预订服务由观视界合作酒店民宿提供，住宿服务由酒店提供</div>
      <div>
        提交订单即表示您已阅读并接受<span style="color: #24c5af; margin-left: 0.2rem">条款</span>
      </div>
    </div>
    <div class="bottom">
      <div class="left">
        <div style="color: #fd7644; font-size: 0.4rem">
          ￥{{ totalPrice * PeoNum }}
          <span style="
              color: gray;
              font-size: 0.2rem;
              margin-top: 0.1rem;
              float: right;
              line-height: 0.4815rem;
            "
                @click="movePayDetail">明细
            <van-icon name="arrow-up" />
          </span>
        </div>
        <!-- <div style="margin-left:0.1rem">已优惠￥213</div> -->
      </div>
      <div class="right">
        <van-button type="primary"
                    size="large"
                    :class="{ btn: esprots }"
                    :disabled="submitOrder_btn"
                    @click="submitOrder">去支付</van-button>
      </div>
      <!-- <van-loading size="24px">加载中...</van-loading> -->
    </div>
    <van-overlay :show="overlayshow"
                 @click="overlayshow = false">
      <div class="wrapper"
           @click.stop>
        <van-loading size="24px">正在为您创建订单...</van-loading>
      </div>
    </van-overlay>
    <!-- 日历弹窗 -->
    <div>
      <van-calendar v-model="calendarShow"
                    color="#36e0c9"
                    type="range"
                    @confirm="onConfirm"
                    :default-date="defaultDate"
                    :formatter="formatter" />
    </div>
    <div class="ActionSheet">
      <ActionSheet :Actionshow="Actionshow"
                   :WhichOne="WhichOne"
                   :roomIndexData="roomData"
                   :configurationData="configuration"
                   @cancel="cancel"
                   @clickOverlay="clickOverlay"
                   @getTime="getTime"
                   @emitName="emitName"
                   @getRoomNum="getRoomNum"></ActionSheet>
    </div>
    <div class="again_pay"></div>
    <van-popup v-model="showPay">
      <div style="font-size: 0.5rem; padding-top: 0.1rem">是否已支付</div>
      <div class="btn">
        <van-button type="primary"
                    @click="goHotelOrder">已支付</van-button>
        <van-button type="warning"
                    @click="reloadPay">未支付</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  getRoomDatePrice,
  submitHotelOrder,
  payWechatHotel,
  payWalletHotel,
  payHotelOrder,
  checkPayStatus,
} from "../../../api/hotel.js";
import { aesKey } from "../../../commom/aesKey";
// import NavBar from '../../../components/NavBar.vue'
import ActionSheet from "../../../components/ActionSheet.vue";
import { Toast, Notify } from "vant";
import { PAYJSSDK } from "@/api/JSSDk.js";
import { Login_user, LoginInfo, getUser_info } from "@/api/user"; //LoginInfo

export default {
  components: {
    // NavBar,
    ActionSheet,
  },
  data () {
    return {
      phone_show: false,
      esprots: false,
      showPay: false, //支付是否成功
      NavBarTitle: "酒店订单",
      submitOrder_btn: false,
      aesKey: aesKey(), //接口通过密文来获取数据
      roomData: [], //房型数据
      roomDatePrice: [], //房型日期价格数据\
      roomStock: [], //库存数量
      totalPrice: 0, //房型总价
      calendarShow: false, //日历是否出现
      dateArray: [], //日期数据
      timestamp: [], //时间戳
      nowYear: [], //当前年份
      ArrivalDate: "", //入住日期的文字数据
      LeavelDate: "", //离开日期的文字数据
      dateLength: "", //住的晚数
      isShow: true, //房间数右侧图标切换
      current: 0, //用于房间数目的样式切换
      PeoNum: 1, //房间数
      defaultDate: [], //默认日期
      order_no: null, //订单
      configuration: null,
      code: null,
      Rname: [
        {
          name: "",
        },
      ], //表单的姓名
      phone: null, //表单的手机号码
      Actionshow: false, //弹窗出现
      WhichOne: null, //哪一个弹窗出现WhichOne
      estimateTime: "14:00", //文本用于存放预计到店时间
      estimateTimeStamp: "", //存放预计到店时间的时间戳
      overlayshow: false, //点击提交订单后的遮罩层
      show_code: true,
      count: "",
      timer: null,
      radio: null,
      integral: "", //赠送金按鈕
      checkIntegralFlag: false,
      checkwalletFlag: false,
      wallet: "", //余额按鈕
      user_info: null,
    };
  },
  // beforeUpdate () {
  //   this.testclose()
  //   console.log('beforeUpdate');
  // },

  updated () { },
  beforeDestroy () {
    console.log("beforeDestroy");
  },

  destroyed: function () {
    console.log("destroyed离开了");
  },
  mounted () { },
  created () {
    this.toTop();
    this.getPhone();
    this.getRoom_tags();
    this.getUser();
    document.body.classList.remove("overflow-hidden");
    if (sessionStorage.getItem("refresh") == 1) {
      sessionStorage.removeItem("refresh");
      location.reload();
    }
    // if (sessionStorage.getItem('configuration').length != 0) {
    //   sessionStorage.removeItem('configuration')
    // }
    this.dealDate();
    let weixinData = JSON.parse(localStorage.getItem("wechatUser"));
    console.log(weixinData, "==weixinData");
    let data = {
      room_id: this.roomData.room_id,
    };
    this.aesKey = aesKey(data);
    this.getRoomDatePrice();
    this.getRomNum();
  },
  watch: {
    roomDatePrice (newData) {
      // console.log(newData, "===newData")
      newData.forEach((item) => {
        // console.log(item.stock_num, "====item")
        this.roomStock = item.stock_num;
        item.sales_price = Number(item.sales_price);
        this.totalPrice += Number(item.sales_price);
      });
    },
    code () {
      if (this.code.length == 6) {
        console.log(6666666666666);
        let data = {
          verification_key: this.verification_key,
          phone: this.phone,
          verification_code: this.code,
        };
        console.log(data);
        this.LoginInfo(data);
      }
    },
  },

  methods: {
    // 赠送按钮
    integralRadio () {
      if (this.checkIntegralFlag == false) {
        this.integral = "";
        this.checkIntegralFlag = true;
        console.log("false");
      } else if (this.checkIntegralFlag == true) {
        this.checkIntegralFlag = false;
        this.integral = "integral";
      }
    },
    integral_radio () {
      this.checkIntegralFlag = false;
      if (this.checkIntegralFlag == false) {
        this.checkIntegralFlag = true;
      } else {
        this.checkIntegralFlag = false;
      }
    },
    // 余额按钮
    walletRadio () {
      if (this.checkwalletFlag == false) {
        this.wallet = "";
        this.checkwalletFlag = true;
      } else if (this.checkwalletFlag == true) {
        this.checkwalletFlag = false;
        this.wallet = "wallet";
      }
    },
    wallet_radio () {
      this.checkwalletFlag = false;
      if (this.checkwalletFlag == false) {
        this.checkwalletFlag = true;
      } else {
        this.checkwalletFlag = false;
      }
    },
    getUser () {
      getUser_info().then((res) => {
        console.log(res, "获取用户信息");
        this.user_info = res.data.data;
      });
    },
    getRoom_tags () {
      let esports = sessionStorage.getItem("esports");
      if (esports == "电竞房") {
        this.esprots = true;
      } else {
        this.esprots = false;
      }
    },
    async LoginInfo (data) {
      let res = await LoginInfo(data);
      console.log(res, "手机绑定信息");
      if (res.data.code == 200) {
        console.log(res, "绑定成功");
        localStorage.setItem("authorization", res.data.data.token.access_token);
        Toast.fail("手机绑定成功");
      } else {
        Toast.fail("手机绑定失败");
      }
    },

    getPhone () {
      let wechatUser = JSON.parse(localStorage.getItem("wechatUser"));
      let phone = wechatUser.phone;
      if (phone == "") {
        this.phone_show = true;
      } else {
        this.phone_show = false;
      }
    },
    //验证码
    code_btn () {
      console.log("点击了");
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show_code = false;

        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show_code = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      let data = {
        phone: this.phone,
      };
      Login_user(data).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          console.log("verification_key", res.data.data.verification_key);
          this.verification_key = res.data.data.verification_key;
        } else {
          Toast.fail(res.data.message);
        }
      });
    },
    //返回顶部
    toTop () {
      document.documentElement.scrollTop = 0;
    },

    // 因为弹窗组件会导致body-overflow-hidden
    testclick () {
      //点击弹窗
      this.tan = true;
      document.body.classList.add("overflow-hidden");
      document.addEventListener("touchmove", this.touchStart, {
        passive: false,
      }); //一般第三个参数可直接填false,true -> 表示在捕获阶段调用事件处理程序, false -> 表示在冒泡阶段调用事件处理程序使用，但是touchmove会被浏览器忽略掉，并不会阻止默认行为，这里通过passive:false明确声明为不是被动的
    },
    testtouchStart () {
      event.preventDefault(); //通知 Web 浏览器不要执行与事件关联的默认动作
    },
    testclose () {
      //点击关闭弹窗
      this.tan = false;
      document.body.classList.remove("van-overflow-hidden");
      document.removeEventListener("touchmove", this.touchStart, {
        passive: false,
      });
    },
    //日期
    //点击日期
    changeDate () {
      this.calendarShow = true;
    },
    //计算日期差处理， 返回时间戳
    dateBetweenDeal (startDate, endDate) {
      let st = new Date(startDate).getTime();
      console.log(st, "开始时间");
      let et = new Date(endDate).getTime();
      let retArr = [];
      const oneDay = 1000 * 60 * 60 * 24;

      while (st != et) {
        retArr.push(st);
        st = st + oneDay;
      }
      retArr.push(et);

      return retArr;
    },
    //日历上的显示
    formatter (day) {
      if (day.type === "start") {
        console.log(day, "====day");
        day.topInfo = "入住";
        day.bottomInfo = this.roomStock + "间";
      } else if (day.type === "end") {
        day.topInfo = "退房";
        day.bottomInfo = this.roomStock + "间";
      }
      return day;
    },
    //日历确认
    onConfirm (date) {
      // const [start, end] = date;
      this.calendarShow = false;
      this.dateArray = this.dateBetweenDeal(
        new Date(date[0]).getTime(),
        new Date(date[1]).getTime()
      );
      sessionStorage.setItem("dateArray", this.dateArray);

      this.ArrivalDate =
        new Date(Number(this.dateArray[0])).getMonth() +
        1 +
        "月" +
        new Date(Number(this.dateArray[0])).getDate() +
        "日";
      this.LeavelDate =
        new Date(Number(this.dateArray[this.dateArray.length - 1])).getMonth() +
        1 +
        "月" +
        new Date(Number(this.dateArray[this.dateArray.length - 1])).getDate() +
        "日";
      this.dateLength = this.dateArray.length - 1;
    },
    //获取库存数量
    getRomNum () {
      // getRoomDatePrice(this.aesKey).then(res => {
      //   res.data.data.forEach(item => {
      //     console.log(item, "===item")
      //   })
      // })
    },
    //酒店房型根据日期获取价格
    getRoomDatePrice () {
      getRoomDatePrice(this.aesKey).then((res) => {
        res.data.data.forEach((item) => {
          this.timestamp.forEach((item2) => {
            if (item.date == item2) {
              console.log(item, "====酒店房型根据日期获取价格");
              // console.log(this.timestamp)
              this.roomDatePrice.push(item);
            }
          });
        });
      });
    },

    //滚动条滚动时有动画效果
    scrollAnimation (currentY, targetY) {
      //currentY当前位置     targetY目标位置
      // 计算需要移动的距离
      let needScrollTop = targetY - currentY;
      let _currentY = currentY;
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10);
        _currentY += dist;
        window.scrollTo(_currentY, currentY);
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY);
        } else {
          window.scrollTo(_currentY, targetY);
        }
      }, 1);
    },
    //返回上一个页面
    onClickLeft () {
      this.$router.go(-1);
    },
    //处理日期数据
    dealDate () {
      // 获取年月日
      let dateNow = new Date();
      this.nowYear = dateNow.getFullYear();

      this.dateArray = sessionStorage.getItem("dateArray");
      this.dateArray = this.dateArray.split(",");
      this.dateLength = this.dateArray.length - 1;
      this.ArrivalDate =
        new Date(Number(this.dateArray[0])).getMonth() +
        1 +
        "月" +
        new Date(Number(this.dateArray[0])).getDate() +
        "日";
      this.LeavelDate =
        new Date(Number(this.dateArray[this.dateArray.length - 1])).getMonth() +
        1 +
        "月" +
        new Date(Number(this.dateArray[this.dateArray.length - 1])).getDate() +
        "日";

      //因为没有选择的日期的时候，获取的时间是当前的时间，所以需要处理
      this.dateArray.forEach((item) => {
        let date = new Date(Number(item));
        date.setHours(0, 0, 0, 0);
        let seconds = date.getTime() / 1000; // 获取秒
        this.timestamp.push(seconds);
      });
      this.timestamp.pop();
      this.roomData = JSON.parse(sessionStorage.getItem("roomData"));
    },
    //房型详情被点击
    getRoomDetail () {
      this.WhichOne = 1;
      this.Actionshow = true;
    },
    //订房必读中查看全部被点击
    getMusrea () {
      this.WhichOne = 2;
      this.Actionshow = true;
    },

    //选择房间数目展开收起的按钮
    roomNum () {
      if (this.isShow) {
        this.isShow = false;
      } else {
        this.isShow = true;
      }
    },
    //选择房间数
    getNum (item, index) {
      this.current = index;
      this.PeoNum = item;
      this.isShow = true;
      this.Rname = [];
      for (let i = 0; i < this.PeoNum; i++) {
        this.Rname.push({ name: "" });
      }
      // console.log(this.Rname)
    },

    //icon添加人物
    addPeople () {
      this.Actionshow = true;
      this.WhichOne = 4;
    },
    //获取住客姓名
    emitName (arr) {
      arr.forEach((item, index) => {
        this.Rname[index].name = item.name;
      });
      this.Actionshow = false;
    },
    // 获取预定酒店房间数量
    //预计到店时间被点击
    getHomeTime () {
      this.WhichOne = 3;
      this.Actionshow = true;
    },
    getRoomNum () {
      this.WhichOne = 5;
      this.Actionshow = true;
    },
    //获取预计到店时间
    getTime (text, timeStamp) {
      this.estimateTime = text;
      this.estimateTimeStamp = timeStamp;
      // console.log(this.estimateTimeStamp)
      this.Actionshow = false;
    },

    //房型弹窗的操作事件
    cancel () {
      this.Actionshow = false;
    },
    clickOverlay () {
      this.Actionshow = false;
    },
    //代理商账户扣钱
    payHotelOrder (data) {
      payHotelOrder(data).then((res) => {
        // console.log(res)
        Toast.fail(res);
      });
    },
    //底部
    //明细点击
    movePayDetail () {
      let detailTop = document.querySelector(".expense_detail").offsetTop;
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollAnimation(scrollTop, detailTop);
    },
    //跳转已经支付过
    goHotelOrder () {
      this.$router.push({ path: "/allHotel/HotelOrderDetail" });
    },
    //重新调出支付
    async reloadPay () {
      this.showPay = true;
      let data2 = {
        product_order_no: this.order_no,
      };
      console.log(data2, "data2");
      checkPayStatus(data2).then((res) => {
        console.log(res, "payStatus订单状态");
        this.prepay_id = res.data.data.pay.prepay_id;
        console.log(this.prepay_id, " this.prepay_id");
        if (res.data.code == 200) {
          console.log("订单状正常");
          let data3 = {
            prepay_id: this.prepay_id,
          };

          this.aesKey = aesKey(data3);
          console.log("准备进入微信支付", this.aesKey);
          PAYJSSDK(this.aesKey).then((res3) => {
            console.log("弹出微信支付");
            console.log("====res3", res3);
            if (res3.data.code == 200) {
              console.log(res3);
              // this.res2 = res2
              Toast.fail("支付中");
              // this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
              // this.onBridgeReady(this.res2.data.data.payinfo)

              this.onBridgeReady(res3);
              //
            }
          });
        }
      });
    },
    //微信浏览器的sdk
    onBridgeReady (data) {
      console.log(data);
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: data.data.data.appId, //公众号ID，由商户传入
          timeStamp: data.data.data.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: data.data.data.nonceStr, //随机串
          package: data.data.data.package,
          signType: data.data.data.signType, //微信签名方式：
          paySign: data.data.data.paySign, //微信签名
        },
        function (res) {
          // get_brand_wcpay_request:ok	支付成功
          // get_brand_wcpay_request:cancel	支付过程中用户取消
          // get_brand_wcpay_request:fail	支付失败

          if (
            res.err_msg == "get_brand_wcpay_request:cancel" ||
            res.err_msg == "get_brand_wcpay_request:fail"
          ) {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.res3 = res;
            Toast.fail("支付失败");
            // this.$router.push({ path: '/allHotel/HotelOrderDetail' })
            // window.location.href = "https://pt.guansj.com/allHotel/HotelOrderDetail"
            window.location.href =
              "https://pt.guansj.com/allHotel/HotelOrderDetail";
          } else {
            // //用户支付成功后，代理商账户扣钱
            // let  data = {
            //   order_no: this.order_no
            // }
            // this.aesKey = aesKey(data)
            // this.payHotelOrder(this.aesKey)
            Toast.success("支付成功");

            sessionStorage.removeItem("configuration");
            window.location.href = "https://pt.guansj.com/allHotel/orderDtra";
          }
        }
      );
    },
    //提交订单
    async submitOrder () {
      //余额和抵用组合
      if (this.wallet == "wallet" && this.integral == "integral") {
        let priceIdArr = [];
        this.roomDatePrice.forEach((item) => {
          priceIdArr.push(item.price_id);
        });
        console.log(priceIdArr, "=====priceIdArr====");
        console.log(this.roomDatePrice, "===== this.roomDatePrice====");
        priceIdArr = priceIdArr.join(",");
        let data = null;
        if (
          this.user_info.wallet >= this.roomDatePrice[0].sales_price &&
          this.user_info.integral >= this.roomDatePrice[0].integral_price
        ) {
          data = {
            room_id: this.roomData.room_id,
            room_num: this.PeoNum,
            real_name: this.Rname[0].name,
            phone: this.phone,
            price_id: priceIdArr,
            balance:
              this.roomDatePrice[0].sales_price -
              this.roomDatePrice[0].integral_price,
            integral: this.roomDatePrice[0].integral_price,
          };
        } else if (this.user_info.wallet < this.roomDatePrice[0].sales_price) {
          data = {
            room_id: this.roomData.room_id,
            room_num: this.PeoNum,
            real_name: this.Rname[0].name,
            phone: this.phone,
            price_id: priceIdArr,
            balance: this.user_info.wallet,
            integral: Math.ceil(
              this.roomDatePrice[0].integral_price - this.user_info.wallet
            ),
          };
        } else if (
          this.user_info.wallet >= this.roomDatePrice[0].sales_price &&
          this.user_info.integral < this.roomDatePrice[0].integral_price
        ) {
          data = {
            room_id: this.roomData.room_id,
            room_num: this.PeoNum,
            real_name: this.Rname[0].name,
            phone: this.phone,
            price_id: priceIdArr,
            balance: Math.ceil(
              this.roomDatePrice[0].sales_price - this.user_info.integral
            ),
            integral: this.user_info.integral,
          };
        }

        console.log(data, "===data====");
        // this.aesKey = aesKey(data)
        // console.log(this.aesKey, "===this.aesKey")
        let res = await submitHotelOrder(data);

        this.order_no = res.data.data.order_no;
        // console.log(res, "submitHotelOrder的res数据")
        if (res.data.code == 400) {
          console.log(res, "===res");
          if (res.data.message == "phone is null") {
            Notify({ type: "danger", message: "手机号码为空" });
          } else if (res.data.message == "real_name is null") {
            Notify({ type: "danger", message: "姓名不能为空" });
          } else if (res.data.message == "2022-06-23-可接待人数0") {
            Notify({ type: "danger", message: "当日房间预定已满" });
          } else if (res.data.message == "17:30后不可下当天的订单") {
            Notify({
              type: "danger",
              message: "超过当天17:30无法下当天订单,请返回上一页选择日期",
            });
          } else {
            Notify({ type: "danger", message: "无法下单请联系客服" });
          }
        } else if (res.data.code == 200) {
          this.submitOrder_btn = true;
          sessionStorage.setItem("order_no", res.data.data.order_no);
          // console.log(res, "===res")
          //微信支付订单
          let data2 = {
            pay_for: 5, //酒店
            pay_provider_id: 1, //收银宝 //2云商通
            pay_method: "W02", //微信js
            order_no: res.data.data.order_no,
          };
          let res2 = await payWalletHotel(data2);
          console.log(res2, "走了余额支付");
          if (res2.data.message == "支付成功") {
            this.$router.push({ path: "/allHotel/HotelOrderDetail" });
          } else if (res2 == undefined) {
            this.$router.push({ path: "/allHotel/HotelOrderDetail" });
          }
        } else if (res.data.message == "用户未登录") {
          Toast.fail(res.data.message);
          console.log(res.data.message);
          setTimeout(() => {
            // window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa68cd9e5c0b30448&redirect_uri=https%3A%2F%2Fnewapi.guansj.com%2Fapi%2Fhome%2Fwechat-login-redirect&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
            localStorage.setItem(" historyRoute", this.$route.path);
          }, 1500);
        } else {
          Toast.fail(res.data.message);
          console.log(res.data.message);
          // if(res.data.data.message){}
          // Notify({
          //   message: res.data.data.message,
          //   type: 'danger',
          //   duration: 2000,
          // });
        }
      }
      // 只按了余额没按抵用金
      else if (this.wallet == "wallet" && this.integral == "") {
        let priceIdArr = [];
        this.roomDatePrice.forEach((item) => {
          priceIdArr.push(item.price_id);
        });
        console.log(priceIdArr, "=====priceIdArr====");
        console.log(this.roomDatePrice, "===== this.roomDatePrice====");
        priceIdArr = priceIdArr.join(",");
        let data = null;
        if (this.user_info.wallet > this.roomDatePrice[0].sales_price) {
          data = {
            room_id: this.roomData.room_id,
            room_num: this.PeoNum,
            real_name: this.Rname[0].name,
            phone: this.phone,
            price_id: priceIdArr,
            balance: this.roomDatePrice[0].sales_price,
          };
        } else if (this.user_info.wallet < this.roomDatePrice[0].sales_price) {
          Toast.fail("余额不足请充值");
          setTimeout(() => {
            this.$router.push({ path: "/allPerInfo/balance" });
            sessionStorage.setItem("PayUrl", true);
            sessionStorage.setItem("advertising", 1);
          }, 1500);
          return;
        }
        console.log(data, "===data====");
        // this.aesKey = aesKey(data)
        // console.log(this.aesKey, "===this.aesKey")
        let res = await submitHotelOrder(data);
        console.log(res, "submitHotelOrder");
        this.order_no = res.data.data.order_no;
        // console.log(res, "submitHotelOrder的res数据")
        if (res.data.code == 400) {
          console.log(res, "===res");
          if (res.data.message == "phone is null") {
            Notify({ type: "danger", message: "手机号码为空" });
          } else if (res.data.message == "real_name is null") {
            Notify({ type: "danger", message: "姓名不能为空" });
          } else if (res.data.message == "2022-06-23-可接待人数0") {
            Notify({ type: "danger", message: "当日房间预定已满" });
          } else if (res.data.message == "17:30后不可下当天的订单") {
            Notify({
              type: "danger",
              message: "超过当天17:30无法下当天订单,请返回上一页选择日期",
            });
          } else {
            Notify({ type: "danger", message: "无法下单请联系客服" });
          }
        } else if (res.data.code == 200) {
          this.submitOrder_btn = true;
          sessionStorage.setItem("order_no", res.data.data.order_no);
          // console.log(res, "===res")
          //微信支付订单
          let data2 = {
            pay_for: 5, //酒店
            pay_provider_id: 1, //收银宝 //2云商通
            pay_method: "W02", //微信js
            order_no: res.data.data.order_no,
          };
          let res2 = await payWalletHotel(data2);
          console.log(res2, "走了余额支付");
          if (res2.data.message == "支付成功") {
            this.$router.push({ path: "/allHotel/HotelOrderDetail" });
          } else if (res2 == undefined) {
            this.$router.push({ path: "/allHotel/HotelOrderDetail" });
          }
        } else if (res.data.message == "用户未登录") {
          Toast.fail(res.data.message);
          console.log(res.data.message);
          setTimeout(() => {
            // window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa68cd9e5c0b30448&redirect_uri=https%3A%2F%2Fnewapi.guansj.com%2Fapi%2Fhome%2Fwechat-login-redirect&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
            localStorage.setItem(" historyRoute", this.$route.path);
          }, 1500);
        } else {
          Toast.fail(res.data.message);
          console.log(res.data.message);
          // if(res.data.data.message){}
          // Notify({
          //   message: res.data.data.message,
          //   type: 'danger',
          //   duration: 2000,
          // });
        }
      }

      // 只用抵用金
      else if (this.wallet == "" && this.integral == "integral") {
        console.log("只用抵用");
        let priceIdArr = [];
        this.roomDatePrice.forEach((item) => {
          priceIdArr.push(item.price_id);
        });
        console.log(priceIdArr, "=====priceIdArr====");
        console.log(this.roomDatePrice, "===== this.roomDatePrice====");
        priceIdArr = priceIdArr.join(",");
        let data = null;
        this.roomDatePrice[0].integral_price; //房型可用积分
        priceIdArr; //房型价格
        if (priceIdArr > this.roomDatePrice[0].integral_price) {
          Toast.fail("当前房型价格不足抵扣抵用金");
          return;
        }
        if (this.user_info.integral >= this.roomDatePrice[0].integral_price) {
          console.log("判断1");
          data = {
            room_id: this.roomData.room_id,
            room_num: this.PeoNum,
            real_name: this.Rname[0].name,
            phone: this.phone,
            price_id: priceIdArr,
            integral: this.roomDatePrice[0].integral_price,
          };
        } else if (
          this.user_info.integral < this.roomDatePrice[0].integral_price
        ) {
          console.log("判断2");

          Toast.fail("抵用金不足抵扣房价");
          return;
        }
        console.log(data, "===data====");
        // this.aesKey = aesKey(data)
        // console.log(this.aesKey, "===this.aesKey")
        let res = await submitHotelOrder(data);
        console.log(res, "submitHotelOrder");
        this.order_no = res.data.data.order_no;
        // console.log(res, "submitHotelOrder的res数据")
        if (res.data.code == 400) {
          console.log(res, "===res");
          if (res.data.message == "phone is null") {
            Notify({ type: "danger", message: "手机号码为空" });
          } else if (res.data.message == "real_name is null") {
            Notify({ type: "danger", message: "姓名不能为空" });
          } else if (res.data.message == "2022-06-23-可接待人数0") {
            Notify({ type: "danger", message: "当日房间预定已满" });
          } else if (res.data.message == "17:30后不可下当天的订单") {
            Notify({
              type: "danger",
              message: "超过当天17:30无法下当天订单,请返回上一页选择日期",
            });
          } else {
            Notify({ type: "danger", message: "无法下单请联系客服" });
          }
        } else if (res.data.code == 200) {
          this.submitOrder_btn = true;
          sessionStorage.setItem("order_no", res.data.data.order_no);
          // console.log(res, "===res")
          //微信支付订单
          let data2 = {
            pay_for: 5, //酒店
            pay_provider_id: 1, //收银宝 //2云商通
            pay_method: "W02", //微信js
            order_no: res.data.data.order_no,
          };
          let res2 = await payWalletHotel(data2);
          console.log(res2, "走了余额支付");
          if (res2.data.message == "支付成功") {
            this.$router.push({ path: "/allHotel/HotelOrderDetail" });
          } else if (res2 == undefined) {
            this.$router.push({ path: "/allHotel/HotelOrderDetail" });
          }
        } else if (res.data.message == "用户未登录") {
          Toast.fail(res.data.message);
          console.log(res.data.message);
          setTimeout(() => {
            // window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa68cd9e5c0b30448&redirect_uri=https%3A%2F%2Fnewapi.guansj.com%2Fapi%2Fhome%2Fwechat-login-redirect&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
            localStorage.setItem(" historyRoute", this.$route.path);
          }, 1500);
        } else {
          Toast.fail(res.data.message);
          console.log(res.data.message);
          // if(res.data.data.message){}
          // Notify({
          //   message: res.data.data.message,
          //   type: 'danger',
          //   duration: 2000,
          // });
        }
      }

      ///走原生支付
      else if (this.wallet == "" && this.integral == "") {
        let priceIdArr = [];
        this.roomDatePrice.forEach((item) => {
          priceIdArr.push(item.price_id);
        });
        console.log(priceIdArr, "=====priceIdArr====");
        console.log(this.roomDatePrice, "===== this.roomDatePrice====");
        priceIdArr = priceIdArr.join(",");
        const data = {
          room_id: this.roomData.room_id,
          room_num: this.PeoNum,
          real_name: this.Rname[0].name,
          phone: this.phone,
          price_id: priceIdArr,
        };
        // this.aesKey = aesKey(data)
        // console.log(this.aesKey, "===this.aesKey")
        let res = await submitHotelOrder(data);
        this.order_no = res.data.data.order_no;
        // console.log(res, "submitHotelOrder的res数据")
        if (res.data.code == 400) {
          console.log(res, "===res");
          if (res.data.message == "phone is null") {
            Notify({ type: "danger", message: "手机号码为空" });
          } else if (res.data.message == "real_name is null") {
            Notify({ type: "danger", message: "姓名不能为空" });
          } else if (res.data.message == "2022-06-23-可接待人数0") {
            Notify({ type: "danger", message: "当日房间预定已满" });
          } else if (res.data.message == "17:30后不可下当天的订单") {
            Notify({
              type: "danger",
              message: "超过当天17:30无法下当天订单,请返回上一页选择日期",
            });
          } else {
            Notify({ type: "danger", message: "无法下单请联系客服" });
          }
        } else if (res.data.code == 200) {
          this.submitOrder_btn = true;
          sessionStorage.setItem("order_no", res.data.data.order_no);
          // console.log(res, "===res")
          //微信支付订单
          let data2 = {
            pay_for: 1, //酒店
            pay_provider_id: 1, //收银宝 //2云商通
            pay_method: "W02", //微信js
            product_order_no: res.data.data.order_no,
          };
          let res2 = await payWechatHotel(data2);
          console.log(res2, "===res2");
          if (res2 == undefined) {
            this.reloadPay();
          } else if (res2.data.code == 200) {
            let data3 = {
              prepay_id: res2.data.data.payinfo.prepay_id,
            };
            this.aesKey = aesKey(data3);
            let res3 = await PAYJSSDK(this.aesKey);

            this.res3 = res3;

            // console.log(res2, "====res2");
            Toast.fail("支付中");
            // this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
            this.onBridgeReady(this.res3);
            // sessionStorage.setItem('wxPayData', this.res2.data.data.payinfo)
            sessionStorage.setItem("order_no", res.data.data.order_no);
            // window.location = res2.data.data.payinfo
            // if (res3.data.code == 400) {
            //   this.$router.push({ path: '/allHotel/HotelOrderDetail' })
            //   // console.log(res2, "====res2")
            // }
            // if (res3.data.code == 200) {
            //   this.$router.push({ path: '/allHotel/orderDtra' })
            // }
          } else {
            Toast.fail(res2.data.message);
          }
        } else if (res.data.message == "用户未登录") {
          Toast.fail(res.data.message);
          console.log(res.data.message);
          setTimeout(() => {
            // window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa68cd9e5c0b30448&redirect_uri=https%3A%2F%2Fnewapi.guansj.com%2Fapi%2Fhome%2Fwechat-login-redirect&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
            localStorage.setItem(" historyRoute", this.$route.path);
          }, 1500);
        } else {
          Toast.fail(res.data.message);
          console.log(res.data.message);
          // if(res.data.data.message){}
          // Notify({
          //   message: res.data.data.message,
          //   type: 'danger',
          //   duration: 2000,
          // });
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
#HotelOrder {
  font-size: 0.3rem;
  text-align: left;
  background-color: #f8f8fa;
  .title {
    font-size: 0.29rem;
    font-weight: bold;
    width: 95%;
    span {
      font-weight: 500;
    }
  }
  .room_name {
    padding-top: 0.5rem;
    padding-left: 0.3rem;
    background-color: white;
    .date {
      padding-bottom: 0.2rem;
      width: 95%;
      span {
        float: right;
        font-size: 0.2rem;
        color: gray;
        line-height: 0.4444rem;
      }
    }
    .rName {
      padding-bottom: 0.2rem;
      div {
        margin-top: 0.2rem;
      }
    }
  }
  .room_musRea {
    background-color: white;
    margin-top: 0.15rem;
    padding: 0.2rem 0rem;
    padding-left: 0.3rem;
    .title {
      position: relative;
      img {
        position: absolute;
        width: 4%;
        top: 19%;
      }
    }
    ul {
      padding: 0.15rem 0.3rem;
      font-size: 0.2rem;
      color: gray;
      list-style-type: disc;
    }
  }
  .room_intoInfo {
    background-color: white;
    margin-top: 0.15rem;
    padding-bottom: 0.2rem;
    .title {
      padding: 0.2rem 0;
      width: 100%;
    }
    .formInfo {
      // padding-top: 0.2rem;
      color: gray;
      font-size: 0.27rem;
      height: 0.85rem;
      .left {
        display: inline-block;
        width: 25%;
        line-height: 20px;
      }
      .right {
        display: inline-block;
        width: 70%;
        position: relative;
        font-size: 0.25rem;
        .van-cell {
          padding-left: 0px;
        }
        .blue_icon {
          color: #0086f7;
          font-size: 0.4rem;
          position: absolute;
          right: 0%;
          bottom: 25%;
        }
        img {
          width: 8%;
          position: absolute;
          right: 0.5%;
          bottom: 25%;
        }
      }
      .choiceNum {
        background-color: #f8f9fd;
        position: absolute;
        z-index: 20;
        padding-bottom: 0.2rem;
        margin-top: 0.2rem;
        margin-bottom: 3.7037rem;
        #btn {
          display: inline-block;
          padding: 0.1rem 0.5rem;
          border-radius: 20px;
          background-color: white;
          margin-left: 0.15rem;
          margin-top: 0.2rem;
          border: 1px solid white;
        }
      }
      .is_active {
        border: 1px solid #39cbc5 !important;
      }
    }
    .code {
      .code_btn {
        position: absolute;
        top: 15%;
        right: -10%;
        border: 1px solid #0086f7;
        padding: 5px 5px;
        border-radius: 20px;
        font-size: 12px;
        color: #0086f7;
        -webkit-transform-origin-x: 0;
        -webkit-transform: scale(0.8);
      }
      // padding-top: 0.2rem;
      color: gray;
      font-size: 0.27rem;
      height: 0.85rem;
      .left {
        display: inline-block;
        width: 25%;
        line-height: 20px;
      }
      .right {
        display: inline-block;
        width: 70%;
        position: relative;
        font-size: 0.25rem;
        .van-cell {
          padding-left: 0px;
        }
        .blue_icon {
          color: #0086f7;
          font-size: 0.4rem;
          position: absolute;
          right: 0%;
          bottom: 25%;
        }
        img {
          width: 8%;
          position: absolute;
          right: 0.5%;
          bottom: 25%;
        }
      }
      .choiceNum {
        background-color: #f8f9fd;
        position: absolute;
        z-index: 20;
        padding-bottom: 0.2rem;
        margin-top: 0.2rem;
        margin-bottom: 3.7037rem;
        #btn {
          display: inline-block;
          padding: 0.1rem 0.5rem;
          border-radius: 20px;
          background-color: white;
          margin-left: 0.15rem;
          margin-top: 0.2rem;
          border: 1px solid white;
        }
      }
      .is_active {
        border: 1px solid #39cbc5 !important;
      }
    }
  }
  .discount {
    .van-tag {
      margin-right: 0.3rem;
      padding: 0 0.1rem;
    }
  }
  .expense_detail {
    .payment {
      padding: 0.2rem 0;
      .left {
        color: black;
      }
      .right {
        float: right;
        margin-left: 0.5rem;
      }
    }
    .detail_content {
      padding: 0.2rem 0;
      padding-top: 0.2rem;
      .left {
        color: black;
        display: block;
        font-size: 0.26rem;
      }
      .right {
        float: right;
        right: 0;
        // margin-left: 0.5rem;
        font-size: 0.26rem;
      }
    }
  }
  .backGray {
    padding: 0.2rem 0;
    padding-left: 0.3rem;
    margin-bottom: 1.2rem;
    .left {
      font-size: 0.26rem;
    }
    div {
      margin-top: 0.1rem;
      color: gray;
      font-size: 0.23rem;
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    background-color: white;
    padding: 0.2rem 0;
    position: fixed;
    bottom: 0%;
    div {
      flex: 1;
      // background-color: aqua;
    }
    .left {
      padding-left: 0.3rem;
      color: gray;
      font-size: 0.2rem;
    }
    .right {
      margin-right: 0.5rem;
      .van-button {
        border: none;
        margin-left: 0.1rem;
        background-image: linear-gradient(to right, #fba952, #fe6162);
        border-radius: 5px;
        height: 0.7rem;
        font-size: 0.25rem;
      }
      .btn {
        border: none;
        margin-left: 0.1rem;
        background-image: linear-gradient(to right, #a02176, #4532e2);
        border-radius: 5px;
        height: 0.7rem;
        font-size: 0.25rem;
      }
    }
  }
  .wrapper {
    width: 4rem;
    height: 2rem;
    background-color: white;
    position: absolute;
    top: 40%;
    left: 23%;
    border-radius: 10px;
    .van-loading {
      top: 38%;
      left: 14%;
      color: #60e4de;
      .van-loading__spinner--circular {
        width: 0.5rem !important;
        height: 0.5rem !important;
      }
      .van-loading__text {
        font-size: 0.25rem;
        color: black;
      }
    }
  }
  //比1px还小的border
  .thinner-border {
    position: relative;
    width: 100%;
    height: 1px;
  }
  .thinner-border:after {
    content: "";
    position: absolute;
    width: 1000%;
    border: 1px solid #979797;
    transform-origin: 0 0;
    transform: scale(0.1, 0.1);
    box-sizing: border-box;
    // margin-top: 0.2rem;
  }
}
/deep/ body {
  .van-overflow-hidden {
    // overflow: hidden;
  }
}
.again_pay {
  /deep/ .van-popup {
    border-radius: 10px;
    width: 4.25rem;
    height: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    .btn {
      // position: absolute;
      margin-top: 1rem;

      display: flex;
      justify-content: space-between;
      .van-button {
        border-radius: 10px;
      }
    }
  }
  /deep/.van-popup {
    // width: 100% !important;
    .van-button {
      margin-left: 10px;
      margin-right: 10px;
    }
    // height: 80% !important;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>