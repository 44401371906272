import { request } from './request.js'

// 会员产品列表
export function getVIPList (params) {
  return request({
    url: '/agent/privilege/list',
    method: 'POST',
    params
  })
}

// 会员产品规格列表
export function getSpecList (params) {
  return request({
    url: '/agent/privilege/spec',
    method: 'POST',
    params
  })
}

// 提交规格订单
export function submitSpecOrder (params) {
  return request({
    url: '/agent/privilege/sub-order',
    method: 'POST',
    params
  })
}

// 规格订单列表
export function getSpecOrderList (params) {
  return request({
    url: '/agent/privilege/order-list',
    method: 'POST',
    params
  })
}

// 支付规格订单
export function paySpecOrder (params) {
  return request({
    url: '/agent/privilege/pay-order',
    method: 'POST',
    params
  })
}

// 取消规格订单
export function cancelSpecOrder (params) {
  return request({
    url: '/agent/privilege/cancel-order',
    method: 'POST',
    params
  })
}

// 规格订单详情
export function getSpecOrderDetail (params) {
  return request({
    url: '/agent/privilege/order-details',
    method: 'POST',
    params
  })
}