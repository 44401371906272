<template>
  <div id="bankSetting">
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="content">
      <van-cell-group>
        <van-form validate>
          <van-field v-model="form.username"
                     name="pattern"
                     label="姓名"
                     placeholder="请输入姓名"
                     :rules="[{ pattern:/[\u4e00-\u9fa5_a-zA-Z0-9]{2,10}/ }]" />
          <van-field v-model="form.bankName"
                     name="pattern"
                     label="银行名称	"
                     placeholder="请输入银行名称	" />
          <span class="bank_dropdown">银行卡类型</span>
          <van-dropdown-menu>

            <van-dropdown-item v-model="value1"
                               :options="option1"></van-dropdown-item>
          </van-dropdown-menu>

          <van-field v-model="form.bankID"
                     name="pattern"
                     label="银行账户"
                     placeholder="请输入银行账户	" />

          <van-field v-model="form.phone"
                     name="pattern"
                     type="digit"
                     label="手机号"
                     placeholder="请输入手机号"
                     @input="onPhoneChange" />
          <van-field v-model="form.sms"
                     center
                     clearable
                     label="短信验证码"
                     placeholder="请输入短信验证码">
            <template #button>
              <van-button size="small"
                          type="primary"
                          @click.prevent="sendSmsCode"
                          :disabled="isSmsSend">{{ sendBtnText }}</van-button>
            </template>
          </van-field>

        </van-form>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
// import NavBar from '../../components/NavBar.vue'
export default {
  components: {
    // NavBar,
  },

  data () {
    return {
      value1: 0,
      option1: [
        { text: '请选择类型', value: 0 },
        { text: '信用卡', value: 1 },
        { text: '储蓄卡', value: 2 },
      ],
      NavBarTitle: '银行绑定',
      form: {
        username: '',
        sms: '',//验证码
        phone: '',
        bankID: '',
        bankName: '',
      },
      sendBtnText: '点击发送验证码',
    }
  },
  methods: {
    onClickLeft () {
      this.$router.push({ path: '/Home' })
    }
  },

}
</script>

<style lang="less" scoped>
.bank_dropdown {
  float: left;
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  margin-left: 0.3rem;
  padding-right: 30px;
}

#realName {
  font-size: 0.21rem;
  .content {
    margin-top: 0.6rem;
    padding: 0 0.2rem;
    // background-color: aqua;
    .title {
      font-size: 0.45rem;
      font-weight: bold;
    }
    .nameId {
      // margin-top: 1rem;
      z-index: 10;
      /deep/.van-cell {
        // font-size: 0.3rem;
      }
    }
    .btn {
      margin-top: 0.4rem;
      padding: 0 0.4rem;
      .van-button {
        font-size: 0.28rem;
      }
    }
    .bottom {
      position: absolute;
      width: 100%;
      bottom: 5%;
      .text {
        color: gray;
        width: 4.6rem;
        margin-left: 1.3rem;
        span {
          color: #24c5af;
        }
      }
    }
  }
}
</style>