<template>
  <div class="container">
    <div class="topSearch"
         id="search">
      <form action="/">
        <van-search v-model="HomeSearchValue"
                    @search="goHotelSearch(HomeSearchValue)"
                    left-icon=""
                    shape="round"
                    placeholder="请输入搜索关键词" />
      </form>
      <van-button size="small"
                  class="search_btn"
                  @click="goHotelSearch(HomeSearchValue)"
                  color="linear-gradient(to right, #00ECAF, #5EE3DE)">搜索</van-button>
    </div>
    <div class="content">
      <span class="top">已联系酒店</span>
      <div class="card">
        <div class="left"><img src="../../../assets/HotelImg/hotel_input@2x.png"
               alt=""></div>
        <div class="right">
          <span style="font-size:16px;font-weight:600; ">东莞富力万达文华酒店</span>
          <br>
          <span style="color:#0387f4">合作方式:OTA售卖</span>
          <br>
          <span style="color:#fe6529">上传时间:2022-03-05</span>
        </div>
      </div>
    </div>
    <div class="bottom">
      <van-button @click="showPopup"> 录入酒店信息</van-button>
    </div>
    <!-- 弹出层 -->

    <van-popup v-model="show"
               position="bottom">
      <div class="info_1">
        <span class="tab">酒店合作方式</span>
        <van-field v-model="message"
                   rows="1"
                   autosize
                   type="textarea"
                   placeholder="线下分发/线上OTA/电竞酒店改造" />

      </div>
      <van-divider style="width:90%; margin-left:5%; margin:0px 5% 0px 5% ; " />
      <div class="info_1">
        <van-cell-group>
          <span class="tab"
                style="margin-top:.1852rem;">填写酒店信息</span>
          <van-field v-model="hotel_name"
                     rows="1"
                     autosize
                     type="textarea"
                     label="酒店名称:"
                     placeholder="请输入酒店名称" />
          <van-field v-model="hotel_contacts"
                     rows="1"
                     autosize
                     type="textarea"
                     label="酒店联系人:"
                     placeholder="请输入联系人手机号码" />
          <van-field v-model="hotel_post"
                     rows="1"
                     autosize
                     type="textarea"
                     label="酒店联系人:"
                     placeholder="请输入联系人职位" />
          <van-field v-model="hotel_phone"
                     rows="1"
                     autosize
                     type="textarea"
                     label="酒店联系人:"
                     placeholder="请输入酒店联系电话" />

          <van-field v-model="hotel_RoomNum"
                     rows="1"
                     autosize
                     type="textarea"
                     label="酒店房间数:"
                     placeholder="请输入酒店房间数" />
          <!-- 图 -->
          <div>
            <span class="hotel_img">酒店主图</span>
            <van-uploader v-model="fileList"
                          multiple />
          </div>
          <van-divider :style="{ padding: '0 16px' }" />
          <van-field v-model="hotel_CTRP"
                     rows="1"
                     autosize
                     type="textarea"
                     label="酒店携程评分:"
                     placeholder="请输入酒店携程评分" />
          <van-field v-model="hotel_Meituan"
                     rows="1"
                     autosize
                     type="textarea"
                     label="酒店美团评分:"
                     placeholder="请输入酒店美团评分" />
          <van-field v-model="hotel_Fliggy"
                     rows="1"
                     autosize
                     type="textarea"
                     label="酒店飞猪评分:"
                     placeholder="请输入酒店飞猪评分" />
          <van-field v-model="hotel_setUp"
                     rows="1"
                     autosize
                     type="textarea"
                     label="酒店开业时间:"
                     placeholder="请输入酒店开业时间" />
          <van-field v-model="hotel_Decoration"
                     rows="1"
                     autosize
                     type="textarea"
                     label="酒店装修时间:"
                     placeholder="请输入装修时间" />
        </van-cell-group>

      </div>
      <div class="info_1"
           style="margin-top:.3704rem">
        <span class="tab">沟通人信息</span>
        <van-field v-model="hotel_Phone"
                   rows="1"
                   autosize
                   type="textarea"
                   label="沟通人电话:"
                   placeholder="请输入沟通人电话" />
        <van-field v-model="hotel_Name"
                   rows="1"
                   autosize
                   type="textarea"
                   label="沟通人名称:"
                   placeholder="请输入沟通人名称" />

      </div>
      <van-divider :style="{ padding: '0rem  .2963rem' ,margin:0}" />
      <van-button style="color:white;background-color: #0387f4; width:80%;border-radius: 20px; margin-bottom: .3704rem;">确认保存</van-button>
    </van-popup>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      text: '',
      fileList: [
        // Uploader 根据文件后缀来判断是否为图片文件
        // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
      ],
      HomeSearchValue: '',
      hotel_name: '',//酒店名称
      message: '',//酒店合作方式
      hotel_contacts: '',//酒店联系人
      hotel_phone: '',//酒店电话
      hotel_post: '',//酒店人职位
      hotel_RoomNum: '',//酒店房间数
      hotel_CTRP: '',//携程评分
      hotel_Meituan: '',//美团评分
      hotel_Fliggy: '',//飞猪评分
      hotel_setUp: '',//酒店开业
      hotel_Decoration: '',//酒店装修
      hotel_Phone: '',//沟通人电话
      hotel_Name: '',//沟通人名称
    };
  },

  methods: {
    showPopup () {
      this.show = true;
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  .topSearch {
    .van-button {
      position: absolute;
      top: 1.5%;
      right: 3%;
      border-radius: 20px;
      width: 1.1111rem;
      /deep/ .van-cell {
        // line-height: 26px;
      }
    }
    /deep/ .van-search__content {
      box-shadow: 0px 0px 20px -4px rgba(27, 25, 25, 0.15);
      // width: 59%;
    }
  }
  .content {
    .top {
      font-size: 20px;
      font-weight: 600;
      display: flex;
      margin: 0.1852rem 0.1852rem 0.1852rem 0.5556rem;
    }
    .card {
      width: 90%;
      height: 2.2222rem;
      background-color: white;
      margin-left: 5%;
      border-radius: 20px;
      box-shadow: 0px 3px 10px 4px rgba(221, 221, 221, 0.5);
      .left {
        width: 20%;
        height: 1.8519rem;
        padding-top: 0.2778rem;
        padding-left: 0.3704rem;
        display: inline-block;
        float: left;
        img {
          width: 100%;
        }
      }
      .right {
        display: inline-block;
        width: 70%;
        float: left;
        padding-top: 0.2778rem;
        font-size: 12px;
        height: 1.8519rem;
        margin-left: 0.1852rem;
        text-align: left;
        span {
          display: block;
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: 1.4815rem;
    position: absolute;
    bottom: 0%;
    justify-content: center;
    box-shadow: 0px 2px 10px 2px rgba(200, 200, 200, 0.5);
    /deep/ .van-button {
      width: 70%;
      border-radius: 20px;
      background-color: #0387f4;
      color: white;
    }
  }
  /deep/ .van-popup--bottom {
    border-radius: 20px 20px 0px 0px;
    padding-top: 0.3704rem;
    top: 1.8519rem;
    .van-cell__title {
      text-align: left;
    }
  }
  .tab {
    font-size: 16px;
    color: #dea260;
    float: left;
  }
  .info_1 {
    margin-left: 0.1852rem;
    .hotel_img {
      font-size: 14px;
      display: flex;
      margin-top: 0.3704rem;
      margin-left: 0.2963rem;
    }
  }
  /deep/ .van-field__label {
    width: 6.3em;
  }
}
</style>