<template>
  <div>
    <div class="container">
      <div class="content">
        <div class="topSearch">
          <form action="/">
            <span class="address">东莞
              <van-icon name="arrow-down" />
              <span style="color:#d9d9d9"> |</span>
            </span>
            <van-search class="search"
                        v-model="value"
                        shape="round"
                        left-icon=""
                        placeholder="请输入搜索关键词">
            </van-search>
            <i class="search_icon"><img src="../../../assets/inland/搜索icon.png"
                   alt="">
            </i>
          </form>
        </div>
      </div>
      <div class="tab">
        <van-grid :column-num="5"
                  style="border:none;">
          <van-grid-item>
            <img src="../../../assets/outboundTrip/customization.png"
                 alt="" />
            <span style="font-size:14px;margin-top:5px">定制游</span>
          </van-grid-item>

          <van-grid-item>
            <img src="../../../assets/outboundTrip/liner.png"
                 alt="" />
            <span style="font-size:14px;margin-top:5px">邮轮游</span>
          </van-grid-item>

          <van-grid-item>
            <img src="../../../assets/outboundTrip/team.png"
                 alt="" />
            <span style="font-size:14px;margin-top:5px">跟团游</span>
          </van-grid-item>
          <van-grid-item>
            <img src="../../../assets/outboundTrip/free.png"
                 alt="" />
            <span style="font-size:14px;margin-top:5px">自由行</span>
          </van-grid-item>
          <van-grid-item>
            <img src="../../../assets/outboundTrip/visa.png"
                 alt="" />
            <span style="font-size:14px;margin-top:5px">签证</span>
          </van-grid-item>
        </van-grid>
      </div>
      <div class="hotCountry">
        <div class="label">热门目的地</div>
        <van-grid :column-num="3">
          <van-grid-item v-for="item in country "
                         :key="item.name"><img :src="item.url"
                 alt="">
            <div class="country">
              <span>{{item.zcname}}</span>
              <br>
              <span style="font-size:400">{{item.name}}</span>
            </div>
          </van-grid-item>
        </van-grid>
        <div class="MoreCountry">更多国家/地区
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="recommend">
        <span class="tap">
          <van-icon :name="recommend_icon" />为您推荐
        </span>
        <div class="recommend_info">
          <div class="recommend_info_lfet">
            <img src="../../../assets/outboundTrip/place_1.png"
                 alt="">
            <div class="recommend_text">马尔代夫0购大玩家 国际五星 版四天三晚</div>
          </div>
          <div class="recommend_info_right">
            <img src="../../../assets/outboundTrip/place_1 (2).png"
                 alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {
      value: "",
      recommend_icon: require('../../../assets/outboundTrip/tuijian.png'),
      country: [
        {
          name: 'Maldives',
          zcname: '马尔代夫',
          url: require('../../../assets/outboundTrip/Maldives.png')
        },
        {
          name: 'Malaysia',
          zcname: '马来西亚',
          url: require('../../../assets/outboundTrip/Malaysia.png')
        },
        {
          name: 'Thailand',
          zcname: '泰国',
          url: require('../../../assets/outboundTrip/Thailand.png')
        },
        {
          name: 'Japan',
          zcname: '日本',
          url: require('../../../assets/outboundTrip/JP.png')
        },
        {
          name: 'New Zealand',
          zcname: '新西兰',
          url: require('../../../assets/outboundTrip/New Zealand.png')
        },
        {
          name: 'Singapore',
          zcname: '新加波',
          url: require('../../../assets/outboundTrip/Singapore.png')
        }
      ],
    }
  },
  computed: {
  }
}
</script>

<style lang="less" scoped >
.container {
  overflow: hidden;
  width: 100%;
  position: relative;

  .content {
    background-image: url("../../../assets/inland/inland_bg.png");
    width: 100%;
    height: 222px;
    background-repeat: no-repeat;
    background-size: contain;
    .topSearch {
      width: 100%;
      position: absolute;
      .search_icon {
        position: absolute;
        top: -30%;
        right: 5%;
        img {
          width: 50%;
        }
      }
      .address {
        position: absolute;
        left: 0%;
        font-size: 18px;
        margin-top: 14px;
        margin-left: 30px;
      }
      .van-search {
        background: none;
      }
      .van-field {
        left: 20%;
      }
    }
  }
  .tab {
    padding: 7px;
    position: absolute;
    margin-left: 3.5%;
    top: 17%;
    width: 90%;
    height: 95px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 9px 16px 2px rgba(102, 102, 102, 0.16);
    /deep/ .van-hairline--top::after {
      border: none;
    }
    /deep/ .van-hairline::after {
      border: none;
    }
    .van-grid-item {
      border-radius: 20px;
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
  .hotCountry {
    padding-left: 20px;
    padding-right: 20px;
    /deep/ .van-grid-item__content--center {
      padding: 0;
      // margin-left: 10px;
    }
    /deep/ .van-hairline--top::after {
      border: none;
    }
    /deep/ .van-hairline::after {
      border: none;
    }
    .label {
      display: flex;
      font-size: 20px;
      font-weight: 600;
      margin-left: 10px;
      margin-bottom: 20px;
    }
    .country_img {
      /deep/ .van-hairline {
        // padding: 0;
      }
    }
    img {
      width: 94%;
      height: 95px;
      margin-bottom: 5px;
    }
    .country {
      position: absolute;
      font-size: 18px;
      color: white;
    }
    margin-top: 90px;
    .MoreCountry {
      font-size: 14px;
      margin-top: 10px;
    }
  }
  .recommend {
    .tap {
      font-size: 16px;
      font-weight: 600;
      display: flex;
      margin-left: 20px;
      img {
        margin-right: 5px;
      }
    }
    .recommend_info {
      width: 90%;
      // background-color: aquamarine;
      height: 500px;
      margin-top: 10px;
      // margin-left: 5%;
      display: inline-flex;
      text-overflow: ellipsis;
      font-size: 14px;

      .recommend_info_lfet {
        img {
          width: 100%;
        }
        width: 49%;
        height: 300px;
        // background-color: coral;
        margin-right: 2%;
        .recommend_text {
          width: 98%;
          // margin-left: 1;
        }
      }
      .recommend_info_right {
        width: 49%;
        height: 300px;
        img {
          width: 100%;
        }

        // background-color: darkkhaki;
      }
    }
  }
}
</style>