<template>
  <div id="personalHome"
       :style="{ height: innerHeight }">
    <div class="header">
      <div class="back">
        <img src="@/assets/personalimg/greenBack.png"
             alt="">
      </div>
      <div class="content">
        <div class="left">
          <img :src="wechatUser.avatar"
               alt=""
               class="touxiang" />
          <div class="text">
            <div style="font-size:0.3rem">{{ wechatUser.nickname }}</div>
            <div style="margin-top:0.1rem">ID: {{ wechatUser.wx_openid }}</div>
            <div style="margin-top:0.1rem">观视界，以天下</div>
          </div>
          <img src="@/assets/personalimg/goldVip.png"
               alt=""
               class="vip">
        </div>
        <van-row>
          <van-col span="6">
            <div>1</div>
            <div>购物车</div>
          </van-col>
          <van-col span="6">
            <div>6</div>
            <div>信息</div>
          </van-col>
          <van-col span="6"
                   @click="gocollect">
            <div>{{collect}}</div>
            <div>收藏</div>
          </van-col>
          <van-col span="6">
            <div>4</div>
            <div>评价</div>
          </van-col>
        </van-row>

      </div>
      <div class="botImg">
        <img src="@/assets/personalimg/blackBack.png"
             alt="">
      </div>
    </div>
    <div class="orderBtn"
         style="margin-top:0.1rem">
      <div class="title">全部订单</div>
      <van-row>
        <van-col span="5">
          <img src="@/assets/personalimg/periphery_icon@3x.png"
               alt="">
          <div>旅游</div>
        </van-col>
        <van-col span="5">
          <img src="@/assets/personalimg/Custom tour_icon@3x.png"
               alt="">
          <div>定制游</div>
        </van-col>
        <van-col span="5">
          <img src="@/assets/personalimg/visa_icon@3x.png"
               alt="">
          <div>签证</div>
        </van-col>
        <van-col span="5"
                 @click="goHotelOrder">
          <img src="@/assets/personalimg/hotel_icon@3x.png"
               alt="">
          <div>民宿/酒店</div>
          <van-badge :content="hotelCount"
                     max="99">
          </van-badge>
        </van-col>
        <van-col span="5"
                 @click="getVipOrder">
          <img src="@/assets/personalimg/privilege_icon@3x.png"
               alt="">
          <div>特权</div>
          <van-badge :content="vipCount"
                     max="99">
          </van-badge>
        </van-col>
        <van-col span="5"
                 style="margin-top:0.1rem"
                 @click="goShop">
          <img src="@/assets/personalimg/shopping_icon@3x.png"
               alt="">
          <div>商城</div>
          <van-badge :content="goodsCount"
                     max="99">
          </van-badge>
        </van-col>
      </van-row>
    </div>
    <div class="myWallet">
      <div class="title">我的钱包
        <span>进入钱包
          <van-icon name="arrow" />
        </span>
      </div>
      <van-row>
        <van-col span="6">
          <img src="@/assets/personalimg/qianbao_icon@3x.png"
               alt="">
          <div class="Number">{{ wechatUser.wallet == 0 ? '0' : wechatUser.wallet }}</div>
          <div>余额</div>
        </van-col>
        <van-col span="6">
          <img src="@/assets/personalimg/diyongjin.png"
               alt="">
          <div class="Number">520.00</div>
          <div>抵用金</div>
        </van-col>
        <van-col span="6">
          <img src="@/assets/personalimg/jifen.png"
               alt="">
          <div class="Number">520.00</div>
          <div>积分</div>
        </van-col>
        <van-col span="6">
          <img src="@/assets/personalimg/xianjinquan.png"
               alt="">
          <div class="Number">520.00</div>
          <div>现金券</div>
        </van-col>
      </van-row>
    </div>
    <!-- 联系客服 -->
    <div class="comFuntion">
      <van-action-sheet v-model="showCustCare"
                        title="联系客服">
        <div class="CustCare">
          <div style="float:left">
            <span>
              <h3 class="phone">电话联系</h3>
            </span>
            <span class="time">服务时间24小时(7天)</span>
          </div>
          <div style="float: right;">
            <span>
              <img class="img"
                   @click="callTel()"
                   style="width:80%; "
                   src="@/assets/personalimg/phone@1x.png"
                   alt="">
            </span>
          </div>
        </div>
      </van-action-sheet>

      <div class="title">常用功能</div>
      <van-row>
        <van-col span="6">
          <img src="@/assets/personalimg/lainxi.png"
               alt="">
          <div class="commT">联系邀请人</div>
        </van-col>
        <van-col span="6"
                 @click="goAddress">
          <img src="@/assets/personalimg/address.png"
               alt="">
          <div class="commT">收货地址</div>
        </van-col>
        <van-col span="6">
          <img src="@/assets/personalimg/bangzhuzhongxin.png"
               alt="">
          <div class="commT">帮助中心</div>
        </van-col>
        <van-col span="6"
                 @click="CustCare">
          <img src="@/assets/personalimg/kefu.png"
               alt="">
          <div class="commT">客服咨询</div>
        </van-col>
        <van-col span="6">
          <img src="@/assets/personalimg/shezhi.png"
               alt="">
          <div class="commT">设置</div>
        </van-col>
        <van-col span="6">
          <img src="@/assets/personalimg/wodeshequ.png"
               alt="">
          <div class="commT">我的社区</div>
        </van-col>
        <van-col span="6">
          <img src="@/assets/personalimg/kaitongvip.png"
               alt="">
          <div class="commT">开通会员</div>
        </van-col>
        <van-col span="6"
                 @click="goBankIfno">
          <img src="@/assets/personalimg/bank.png"
               alt="">
          <div class="commT">银行卡</div>
        </van-col>
      </van-row>
    </div>
    <div class="bottom">
      <van-tabbar v-model="bottomActive"
                  @change="switchBottomTab"
                  active-color='#58e3dd'>
        <van-tabbar-item>
          <img v-if="bottomActive == 0"
               src="@/assets/HomeIcon/bottom1active.png"
               alt="">
          <img v-else
               src="@/assets/HomeIcon/bottom1.png"
               alt="">
          <div>首页</div>
        </van-tabbar-item>
        <van-tabbar-item icon="">
          <img v-if="bottomActive == 1"
               src="@/assets/HomeIcon/bottom2active.png"
               alt="">
          <img v-else
               src="@/assets/HomeIcon/bottom2.png"
               alt="">
          <div>目的地</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 2"
               src="@/assets/HomeIcon/bottom3active.png"
               alt="">
          <img v-else
               src="@/assets/HomeIcon/bottom3.png"
               alt="">
          <div>商城</div>
        </van-tabbar-item>
        <van-tabbar-item>
          <img v-if="bottomActive == 3"
               src="@/assets/HomeIcon/bottom4active.png"
               alt="">
          <img v-else
               src="@/assets/HomeIcon/bottom4.png"
               alt="">
          <div>我的</div>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { getGoodsOrderList } from '../../../api/shop.js'
import { getHotelOrderList } from '../../../api/hotel.js'
import { getSpecOrderList } from '../../../api/vip'
import { getUser_info } from '@/api/user.js'
import { aesKey } from "../../../commom/aesKey"
import { getCollect } from "@/api/collect";

export default {
  components: {
    // NavBar
  },
  data () {
    return {
      // is_uptodate: null,
      tel: 13268920665,
      showCustCare: false,//联系客服
      bottomActive: 3,//底部导航
      aesKey: aesKey(),//接口通过密文来获取数据
      wechatUser: '',//微信用户个人信息
      wallet: '',//余额
      hotelCount: '',//有多少个酒店订单
      goodsCount: '',//有多少个商品订单
      vipCount: '',//有多少个特权订单
      routerFrom: '',//用于判断那个路由过来的
      innerHeight: 0,
      user_info: null,
      collect: null,//收藏数量
    }
  },
  created () {
    this.getUser()
    this.getCollect()
  },
  mounted () {
    // this.postLogin()
    this.innerHeight = window.innerHeight + 'px'
    this.wechatUser = JSON.parse(localStorage.getItem('wechatUser'))
    let data = {
      status: ''
    }
    this.aesKey = aesKey(data)
    this.getGoodsOrderList(this.aesKey)
    this.getHotelOrderList(this.aesKey)
    this.getSpecOrderList(this.aesKey)
  },
  methods: {

    getCollect () {
      getCollect().then(res => {
        this.collect = res.data.data.total
        console.log(res.data.data.total);
      })
    },
    getUser () {
      getUser_info().then(res => {
        console.log(res, "获取用户信息");
        this.user_info = res.data.data
      })
    },
    CustCare () {
      if (this.showCustCare == false) {
        this.showCustCare = true
      } else {
        this.showCustCare = false
      }
    },
    gowallet () {
      this.$router.push({ path: '/allPerInfo/wallet' })

    },

    callTel () {
      // 安卓
      window.location.href = 'tel://' + this.tel;
    },
    goAddress () {
      this.$router.push({ path: '/allShop/shopAddress' })
    },
    goBankIfno () {
      this.$router.push({ path: '/bankInfo' })
    },
    //底部导航切换
    switchBottomTab () {
      // console.log(this.bottomActive)
      if (this.bottomActive == 0) {
        this.$router.push({ path: '/Home' })
      } else if (this.bottomActive == 1) {
        this.$router.push({ path: '/allDest/destination' })
      } else if (this.bottomActive == 2) {
        this.$router.push({ path: '/allShop/shopHome' })
      } else if (this.bottomActive == 3) {
        this.$router.push({ path: '/allPerInfo/personalHome' })
      }

    },
    gocollect () {
      console.log("点击了");
      this.$router.push({ path: '/allPerInfo/collect' })
    },

    // //登录接口
    // postLogin () {
    //   postLogin('POST', this.aesKey).then((res) => {
    //     // console.log(res)
    //     this.wallet = res.data.data.wallet
    //   })
    // },
    //查看酒店订单列表
    getHotelOrderList (data) {
      getHotelOrderList(data).then(res => {
        this.hotelCount = res.data.data.total
      })
    },
    //获取商品订单列表
    getGoodsOrderList (data) {
      getGoodsOrderList(data).then(res => {
        // console.log(res.data.data.total)
        this.goodsCount = res.data.data.total
      })
    },
    //获取特权订单列表
    getSpecOrderList (data) {
      getSpecOrderList(data).then(res => {
        // console.log(res.data.data.total)
        this.vipCount = res.data.data.total
      })
    },
    //查看酒店订单
    goHotelOrder () {
      this.$router.replace({ name: 'HotelMyOrder' })
    },
    //查看商品订单
    goShop () {
      this.$router.replace({ name: 'shopMyOrder' })
    },
    //查看特权订单
    getVipOrder () {
      this.$router.replace({ name: 'vipMyOrder' })
    }
  }
}
</script>

<style lang="less" scoped>
#personalHome {
  font-size: 0.3rem;
  background-color: #fafafa;

  .header {
    position: relative;
    height: 4rem;

    .back {
      img {
        width: 100%;
        height: 3.9rem;
      }
    }

    .content {
      position: absolute;
      top: 10%;
      width: 92%;
      text-align: left;
      padding: 0 0.3rem;

      .left {
        position: relative;

        .touxiang {
          width: 20%;
          height: 100%;
          position: absolute;
          border-radius: 50%;
        }

        .text {
          margin-left: 1.5rem;
          display: inline-block;
          font-size: 0.25rem;
          color: white;

          div {
            width: 2.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .vip {
          width: 20%;
          position: absolute;
          right: 0%;
          top: 29%;
        }
      }

      .van-row {
        text-align: center;
        margin-top: 0.3rem;
        font-size: 0.27rem;
        color: white;
      }
    }

    .botImg {
      position: absolute;
      bottom: 0%;

      img {
        width: 92%;
      }
    }
  }

  .orderBtn {
    text-align: left;
    background-color: white;
    margin: 0 0.1rem;
    padding: 0.2rem 0;
    margin-top: 0.18rem;

    .title {
      margin-left: 0.2rem;
      font-size: 0.28rem;
    }

    .van-row {
      text-align: center;
      font-size: 0.25rem;
      margin-top: 0.1rem;

      // margin-left: -0.2rem;
      .van-col {
        width: 20%;
        position: relative;

        /deep/.van-badge {
          position: absolute;
          top: 10%;
          right: 22%;
          font-size: 0.1rem;
        }

        img {
          width: 50%;
        }

        div {
          margin-top: -0.1rem;
        }
      }
    }
  }

  .myWallet {
    text-align: left;
    background-color: white;
    margin: 0 0.1rem;
    padding: 0.2rem 0;
    margin-top: 0.18rem;

    .title {
      margin-left: 0.2rem;
      font-size: 0.28rem;

      span {
        float: right;
        font-size: 0.22rem;
        color: #999999;
        margin-right: 0.4rem;
      }
    }

    .van-row {
      text-align: center;
      font-size: 0.25rem;
      margin-top: 0.1rem;
      margin-left: -0.2rem;

      .van-col {
        width: 25%;

        img {
          width: 38%;
        }

        div {
          color: #b7b7b7;
          margin-top: 0.1rem;
        }

        .Number {
          color: black;
          font-size: 0.26rem;
          margin-top: 0rem;
        }
      }
    }
  }

  .comFuntion {
    text-align: left;
    background-color: white;
    margin: 0 0.1rem;
    padding: 0.2rem 0;
    margin-top: 0.18rem;

    .CustCare {
      padding: 16px 16px 160px;

      .phone {
        margin: 0;
      }

      .time {
        width: 30px;
      }
    }

    .title {
      margin-left: 0.2rem;
      font-size: 0.28rem;
    }

    .van-row {
      text-align: center;
      font-size: 0.25rem;
      margin-top: 0.1rem;
      margin-left: -0.2rem;
      padding-bottom: 50px;

      .van-col {
        margin-top: 0.3rem;
        width: 25%;

        img {
          width: 38%;
        }

        div {
          color: #b7b7b7;
        }

        .Number {
          color: black;
          font-size: 0.26rem;
        }

        .commT {
          color: #666666;
          // margin-top: -0.1rem;
        }
      }
    }
  }

  .bottom {
    text-align: center;

    img {
      width: 30%;
    }
  }
}
</style>