<template>
  <div id="HotelOrderDetail">
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div style="background-color:#36E0C9">
      <div class="topState"
           v-if="orderDetailData.order_status==0">
        <div style="font-size:0.38rem;color:white">待付款</div>
        <div style="width:70%;margin-top:0.1rem;color:white">
          请尽快支付订单
        </div>
        <div class="btn">
          <van-row type="flex"
                   justify="space-between">
            <van-col span="6">
              <span @click="payOrder()">确认支付</span>
            </van-col>
            <van-col span="6">
              <span @click="cancelOrder('cancel')">取消订单</span>
            </van-col>
            <van-col span="6">
              <span @click="goOrder">查看订单</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="topState"
           v-if="orderDetailData.order_status==1">
        <div style="font-size:0.38rem;color:white">待确认</div>
        <div style="width:70%;margin-top:0.1rem;color:white">
          商家会尽快确认您的订单，请耐心等候(该商家一般会在五分钟内确认订单)
        </div>
        <div class="btn"
             style="width:104%">
          <van-row type="flex"
                   justify="space-between">
            <van-col span="6">
              <span style="width:65%">催促确认</span>
            </van-col>
            <van-col span="6">
              <span @click="cancelOrder('cancel')"
                    style="width:65%">取消订单</span>
            </van-col>
            <van-col span="6">
              <span @click="goOrder"
                    style="width:65%">查看订单</span>
            </van-col>
            <van-col span="6">
              <span style="width:65%"
                    @click="reBook">再次预订</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="topState"
           v-if="orderDetailData.order_status==2">
        <div style="font-size:0.38rem;color:white">已确认</div>
        <div style="width:70%;margin-top:0.1rem;color:white">
          房间将为你整晚保留，请安心入住
        </div>
        <div class="btn"
             style="width:104%">
          <van-row type="flex"
                   justify="space-between">
            <van-col span="6">
              <span @click="cancelOrder('refund')"
                    style="width:65%">申请退款</span>
            </van-col>
            <van-col span="6">
              <span @click="goOrder"
                    style="width:65%">查看订单</span>
            </van-col>
            <van-col span="6">
              <span style="width:65%"
                    @click="reBook">再次预订</span>
            </van-col>
            <van-col span="6">
              <span style="width:65%">入后点评</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="topState"
           v-if="orderDetailData.order_status==3">
        <div style="font-size:0.38rem;color:white">待评价</div>
        <div style="width:70%;margin-top:0.1rem;color:white">
          添加你宝贵的意见
        </div>
        <div class="btn"
             style="width:104%">
          <van-row type="flex"
                   justify="space-between">
            <van-col span="6">
              <span style="width:65%"
                    @click="goComment">点评有礼</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="topState"
           v-if="orderDetailData.order_status==4">
        <div style="font-size:0.38rem;color:white">已评价</div>
        <div style="width:70%;margin-top:0.1rem;color:white">
          已经评价，欢迎下次到来
        </div>
        <div class="btn"
             style="width:104%">
          <van-row type="flex"
                   justify="space-between">
            <van-col span="6">
              <span style="width:65%">再次下单</span>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="topState"
           v-if="orderDetailData.order_status==5">
        <div style="font-size:0.38rem;color:white">退款中</div>
        <div style="width:70%;margin-top:0.1rem;color:white">
          正在处理订单中，请稍等
        </div>
        <div class="btn"
             style="width:104%;text-align: center;">
          <span style="width:65%"
                @click="reBook">再次预订</span>
        </div>
      </div>
      <div class="topState"
           v-if="orderDetailData.order_status==6">
        <div style="font-size:0.38rem;color:white">退款成功</div>
        <div style="width:70%;margin-top:0.1rem;color:white">
          退款成功，资金将原路返回支付账户，1-3个工作日到账
        </div>
        <div class="btn"
             style="width:104%;text-align: center;">
          <span style="width:65%"
                @click="reBook">再次预订</span>
        </div>
      </div>
      <div class="topState"
           v-if="orderDetailData.order_status==7">
        <div style="font-size:0.38rem;color:white">已取消</div>
        <div style="width:70%;margin-top:0.1rem;color:white">
          订单已关闭
        </div>
        <div class="btn"
             style="width:104%;text-align: center;">
          <span style="width:65%"
                @click="reBook">再次预订</span>
        </div>
      </div>
    </div>
    <div style="background-color:#36E0C9">
      <div class="cancel_Read">
        <div class="cancel">
          <div style="color:#36e0c9">限时取消
            <span @click="getPolicy">取消·修改政策
              <van-icon name="arrow" />
            </span>
          </div>
          <div style="color:black;font-size: 0.25rem;">{{year}}年{{ArrivalDate}} 18:00前，可免费取消</div>
        </div>
        <div class="thinner-border"></div>
        <div class="read">
          <div class="room_musRea">
            <div class="title">
              <img src="@/assets/HotelImg/tinme_icon.png"
                   alt="">
              <a style="margin-left:0.4rem;font-weight:bold">入住必读</a>
              <span style="font-size:0.2rem;color:#0086F7;float: right;"
                    @click="getMusRea">查看
                <van-icon name="arrow" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="payOnline">
      <div v-if="orderDetailData.user_real_sum!=0"> 在线预付<span>￥{{orderDetailData.user_real_sum}}</span></div>
      <div v-else-if='orderDetailData.balance'> 在线预付<span>￥{{orderDetailData.balance}}</span> 抵用金：<span>￥{{orderDetailData.integral}}</span></div>

    </div>
    <div class="hotelInfo">
      <div class="title">淘客栈专营店</div>
      <div class="hotelDetail">
        <div class="list">
          <div class="left">
            <img :src="roomIndexData.room_pic"
                 alt="">
          </div>
          <div class="HDright">
            <div style="font-size: 0.28rem;">{{hotelInfo.name}}</div>
            <div style="margin-top:0.2rem">
              <span v-for="fac in hotelInfo.facility"
                    :key='fac.id'>
                <span v-if="fac.name=='停车场'">
                  <span class="title">
                    <img src="@/assets/HotelImg/park_icon.png"
                         alt="">
                    <span class="textName">{{fac.name}}</span>
                  </span>
                </span>
                <span v-if="fac.name=='网络'"
                      style="margin-left:1rem">
                  <span class="title">
                    <img src="@/assets/HotelImg/wifi_Icon.png"
                         alt="">
                    <span class="textName">{{fac.name}}</span>
                  </span>
                </span>
              </span>
              <span style="float: right;color: #0086F7;line-height:0.4rem"
                    @click="getFac">
                设施服务
                <van-icon name="arrow" />
              </span>
            </div>
          </div>
        </div>
        <div class="localAddress">
          <div class="address">{{this.hotelInfo.address}}</div>
          <div class="address_btn"
               @click="goMap">地图/周边<span class="iconfont">&#xe709;</span></div>
          <baidu-map class="localAddress"
                     ak='VFc7NsfU7amcxYbEiqypp5a3gHPLaiOO'
                     :center="position"
                     :zoom="zoom"
                     @ready="initMap"
                     :scroll-wheel-zoom="true">
            <!-- <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                            :showAddressBar="true"
                            :autoLocation="true"></bm-geolocation> -->

            <bm-marker :position="position"
                       :dragging="false">

            </bm-marker>
          </baidu-map>
        </div>
        <div class="rName">
          <div class="title">{{roomIndexData.title}}
            <span style="font-size:0.2rem;color:#0086F7;float: right;font-weight:500"
                  @click="getRoomType">
              房型详情
              <van-icon name="arrow" />
            </span>
          </div>
          <div style="font-size:0.2rem;color:gray">
            <span style="margin-right:0.1rem">{{roomIndexData.room_size}}m² |</span>
            <span style="margin-right:0.1rem">{{roomIndexData.smoking}} |</span>
            <span style="margin-right:0.1rem">{{roomIndexData.window}} |</span>
            <span style="margin-right:0.1rem">{{roomIndexData.internet}} |</span>
          </div>
        </div>
        <div class="date">
          <div class="enter">{{ArrivalDate}}&nbsp;
            <span>14:00后入住</span>
          </div>
          <div class="leave">{{LeavelDate}}&nbsp;
            <span>12:00前离店</span>
          </div>
        </div>
        <!-- <div class="breakfastInfo">
          <div style="position: relative;">
            <div style="position: absolute;top:0%;color:gray">早餐信息</div>
            <div class="dateInfo">
              05-21 &nbsp;无早餐x1间
            </div>
          </div>
          <div class="dateInfo">
            05-22 &nbsp;无早餐x1间
          </div>
        </div> -->
        <div class="peopleInfo">
          <div class="everyInfo">
            <div style="width:20%;color:gray">入住人</div>
            <div>{{orderDetailData.real_name}}</div>
          </div>
          <div class="everyInfo">
            <div style="width:20%;color:gray">联系手机</div>
            <div>{{orderDetailData.mobile}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="orderInfo">
      <div class="title">
        订单信息
      </div>
      <div class="info">
        <div style="display: flex;margin-top: 0.3rem;">
          <div style="width:50%">订单编号</div>
          <div style="width:50%;text-align: right">{{orderDetailData.order_no}}</div>
        </div>
        <div style="display: flex;margin-top: 0.3rem;">
          <div style="width:50%">下单时间</div>
          <div style="width:50%;text-align: right">{{orderDetailData.create_time}}</div>
        </div>
        <!-- <div style="display: flex;margin-top: 0.3rem;">
          <div style="width:50%">预计到店时间</div>
          <div style="width:50%;text-align: right">2021-05-21 18:00:00</div>
        </div> -->
      </div>
    </div>
    <div class="bottom">
      关注“观视界旅行酒店促销”公众号可抽大奖
      <van-button type="primary"
                  size="small"
                  color="#36e0c9">去关注</van-button>
    </div>
    <div class="policySheet">
      <van-action-sheet v-model="policyShow"
                        title="取消政策">
        <div class="content">
          北京时间2021-05-22 00:00前可免费修改。若未入住
          酒店或过时取消修改将收取您全额房费 RMB 170.00。
          平台会根据您的付款方式进行预授权或扣除房费，如订
          单不确认将解除预授权或全额退款至您的付款账户。
        </div>
      </van-action-sheet>
    </div>
    <div class="policySheet">
      <van-action-sheet v-model="mutReaShow"
                        title="入住必读">
        <div class="content">
          <div v-html="hotelInfo.buy_notice"></div>
        </div>
      </van-action-sheet>
    </div>
    <div class="policySheet">
      <van-action-sheet v-model="facShow"
                        title="设施服务">
        <div class="content">
          <div class="Facilities">
            <div class="title">
              设施服务
            </div>
            <div class="contentF"
                 v-if="hotelInfo.facility">
              <div v-for="fac in hotelInfo.facility"
                   :key='fac.id'>
                <div v-if="fac.name=='停车场'">
                  <div class="title">
                    <img src="@/assets/HotelImg/park_icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
                <div v-if="fac.name=='网络'">
                  <div class="title">
                    <img src="@/assets/HotelImg/wifi_Icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
                <div v-if="fac.name=='交通服务'">
                  <div class="title">
                    <img src="@/assets/HotelImg/tra_icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
                <div v-if="fac.name=='前台服务'">
                  <div class="title">
                    <img src="@/assets/HotelImg/rec_icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
                <div v-if="fac.name=='餐饮服务'">
                  <div class="title">
                    <img src="@/assets/HotelImg/meal_Icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
                <div v-if="fac.name=='通用设施'">
                  <div class="title">
                    <img src="@/assets/HotelImg/cur_icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
                <div v-if="fac.name=='商务服务'">
                  <div class="title">
                    <img src="@/assets/HotelImg/bus_icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
                <div v-if="fac.name=='其他服务'">
                  <div class="title">
                    <img src="@/assets/HotelImg/oth_icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
                <div v-if="fac.name=='儿童设施'">
                  <div class="title">
                    <img src="@/assets/HotelImg/childF_icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
                <div v-if="fac.name=='公共区'">
                  <div class="title">
                    <img src="@/assets/HotelImg/pub_icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
                <div v-if="fac.name=='康体设施'">
                  <div class="title">
                    <img src="@/assets/HotelImg/well_icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
                <div v-if="fac.name=='娱乐活动设施'">
                  <div class="title">
                    <img src="@/assets/HotelImg/swim_icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
                <div v-if="fac.name=='特色设施'">
                  <div class="title">
                    <img src="@/assets/HotelImg/spec_icon.png"
                         alt=""
                         style="width:5%;left:-1%">
                    <span>{{fac.name}}</span>
                  </div>
                  <div class="cont"
                       v-for="item in fac.data"
                       :key="item.id">
                    <div style="color:gray;font-size:0.22rem">
                      {{item}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-action-sheet>
    </div>
    <div class="cancelReason">
      <van-popup v-model="cancelShow"
                 position="right"
                 style="height:100%;">
        <!-- <NavBar :NavBarTitle='cancelTitle'
                @onClickLeft='cancelReturn'></NavBar> -->
        <div class="content">

          <div class="title"
               style="display:inline-block"
               v-if="reason == 'cancel' ">*请选择取消原因</div>
          <div class="title"
               style="display:inline-block"
               v-if="reason == 'refund' ">*请选择退款原因</div>
          <div style="display:inline-block; float: right ; position: absolute; right:-20%"><img style="width:20%"
                 src="../../../assets/Esports/bt.png"
                 @click="closeCancelShow"
                 alt=""></div>
          <div class="thinner-border"></div>
          <div style="position: relative;margin:0.6rem 0"
               v-for="(item) in cancelReason"
               :key="item.id">
            <van-checkbox v-model="item.checked"
                          checked-color='#24C5AF' />
            <div class="name">
              <div @click="getCheck(item)">{{item.name}}</div>
            </div>
          </div>
          <van-button type="primary"
                      round
                      size="large"
                      @click="submitReason">{{cancelTitle}}</van-button>
        </div>
      </van-popup>
    </div>
    <div class="ActionSheet">
      <ActionSheet :Actionshow='roomTyDetShow'
                   :WhichOne='WhichOne'
                   :roomIndexData='roomIndexData'
                   @cancel='cancel'
                   @clickOverlay='clickOverlay'></ActionSheet>
    </div>
  </div>
</template>

<script>
import {
  getHotelOrderDetail, getHotelDetail,
  getRoomDatePrice, payHotelOrder, payWechatHotel, cancelHotelOrder, refundHotelOrder
} from '../../../api/hotel.js'
import { PAYJSSDK } from '../../../api/JSSDk.js';
import { aesKey } from "../../../commom/aesKey"
import { Toast } from 'vant';
// import NavBar from '../../../components/NavBar.vue'
import ActionSheet from '../../../components/ActionSheet.vue'
import BaiduMap from 'vue-baidu-map/components/map/Map'


import BmMarker from 'vue-baidu-map/components/overlays/Marker'
export default {
  components: {
    // NavBar,
    ActionSheet,
    BaiduMap: BaiduMap,
    BmMarker: BmMarker,
  },
  data () {
    return {
      city: null,
      position: { lng: null, lat: null },
      zoom: 15,
      // center: { lng: 121.833138, lat: 39.081725 },
      show: false,
      reason: null,
      NavBarTitle: '订单详情',
      order_no: '',//酒店订单编号
      orderDetailData: [],//接口返回酒店订单详情的数据
      hotelInfo: [],//接受接口酒店详情数据
      roomInfo: [],//房型列表信息
      roomIndexData: {},//被选中的房型
      priceId: [],//房型的room_price_ids
      datePrice: [],//房型日期价格
      ArrivalDate: '',//入住日期的文字数据
      LeavelDate: '',//离开日期的文字数据
      year: '',//年份
      aesKey: aesKey(),//接口通过密文来获取数据

      mutReaShow: false,//入住必读弹窗

      facShow: false,//设施服务弹窗

      roomTyDetShow: false,//房型详情弹窗
      WhichOne: 1,

      policyShow: false,//取消·修改政策弹窗
      cancelShow: false,//取消订单原因的弹窗
      cancelReason: [
        {
          name: '其他app更便宜',
          checked: false,
        },
        {
          name: '信息填错',
          checked: false,
        },
        {
          name: '前台送早餐',
          checked: false,
        },
        {
          name: '酒店质量良差',
          checked: false,
        },
        {
          name: '行程取消',
          checked: false,
        },
        {
          name: '前台价格更低',
          checked: false,
        },
        {
          name: 'app信息错误',
          checked: false,
        },
        {
          name: '卖家告诉满房（酒店无房）',
          checked: false,
        },
        {
          name: '确认慢',
          checked: false,
        },
        {
          name: '其它',
          checked: false,
        },
      ],
      cancelTitle: '取消订单',//取消弹窗的标题
      actType: '',//取消弹窗的类型
    }
  },
  created () {
    this.order_no = sessionStorage.getItem("order_no");
    let data = {
      order_no: this.order_no
    }
    this.aesKey = aesKey(data)
    this.getHotelOrderDetail(this.aesKey)
  },
  mounted () {
  },
  watch: {
    //订单信息
    orderDetailData (newData) {
      let data = {
        hotel_id: newData.hotel_id
      }
      this.aesKey = aesKey(data)
      this.getHotelDetail(this.aesKey)
    },
    //房型信息
    roomInfo () {
      this.roomInfo.forEach(item => {
        if (item.room_id == this.orderDetailData.room_id) {
          this.roomIndexData = item
        }
      })
    },
    priceId () {
      this.priceId.forEach(item => {
        let data = {
          room_id: this.orderDetailData.room_id,
          price_id: item
        }
        this.aesKey = aesKey(data)
        this.getRoomDatePrice(this.aesKey)
      })
    },
    datePrice () {
      let nextDay = new Date(this.datePrice[this.datePrice.length - 1].date * 1000)
      nextDay.setDate(nextDay.getDate() + 1)
      nextDay = nextDay.getTime()
      this.year = new Date(nextDay).getFullYear()
      this.ArrivalDate = new Date(this.datePrice[0].date * 1000).getMonth() + 1 + '月' + new Date(this.datePrice[0].date * 1000).getDate() + '日'
      this.LeavelDate = new Date(nextDay).getMonth() + 1 + '月' + new Date(nextDay).getDate() + '日'
    }
  },
  methods: {
    goComment () {
      this.$router.push({ path: '/allHotel/HotelComment' })
    },
    // 定位
    handler (e) {
      // this.city = e.address.city
      // this.center.lng = e.longitude
      // this.center.let = e.latitude
      console.log(e, '获取定位后,百度地图逆解析获得');
      // console.log(this.city, "====this.city====");
      const myGeo = new window.BMap.Geocoder({ extensions_town: true })
      myGeo.getLocation(new window.BMap.Point(e.point.lng, e.point.lat),
      )
    },
    initMap ({ BMap }) {
      this.BMap = BMap
      // 通过百度map获取经纬度再传给地图组件
      const that = this
      try {
        const geolocation = new window.BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            // console.log(r);
            // that.center = { lat: r.point.lat, lng: r.point.lng }
            that.point = { lat: r.point.lat, lng: r.point.lng }
            that.handler(r)
          },
          { enableHighAccuracy: true }
        )
      } catch (error) {
        this.$toast('请打开定位或退出重试')
      }
    },

    closeCancelShow () {
      this.cancelShow = false
    },
    //接口获取数据
    //查看酒店订单详情
    getHotelOrderDetail (data) {
      getHotelOrderDetail(data).then(res => {
        this.orderDetailData = res.data.data
        sessionStorage.setItem("orderDetailData", JSON.stringify(this.orderDetailData))
        console.log(this.orderDetailData, 'this.orderDetailData');
        this.priceId = res.data.data.room_price_ids
        this.priceId = this.priceId.split(',')
      })
    },
    //通过酒店id获取酒店详情
    getHotelDetail (data) {
      getHotelDetail(data).then(res => {
        this.hotelInfo = res.data.data.hotel_info
        sessionStorage.setItem("hotelDetail", JSON.stringify(this.hotelInfo))
        this.position.lng = this.hotelInfo.lng_lat[0]
        this.position.lat = this.hotelInfo.lng_lat[1]

        this.roomInfo = res.data.data.room_list
      })
    },
    //通过room_id和price_id来获取房型的日期价格
    getRoomDatePrice (data) {
      getRoomDatePrice(data).then(res => {
        // console.log(res)
        this.datePrice.push(res.data.data[0])
      })
    },
    //支付酒店订单
    payHotelOrder (data) {
      payHotelOrder(data).then(res => {
        console.log(res)
      })
    },


    onClickLeft () {
      this.$router.go(-1)
    },

    //入住必读‘查看’
    getMusRea () {
      this.mutReaShow = true
    },

    //设施服务
    getFac () {
      this.facShow = true
    },


    //取消·修改政策点击
    getPolicy () {
      this.policyShow = true
    },

    //房型详情点击弹窗
    getRoomType () {
      this.roomTyDetShow = true
      this.roomIndexData.deposit = this.hotelInfo.deposit
      this.roomIndexData.entry_departure_time = this.hotelInfo.entry_departure_time
      this.roomIndexData.meal_explanation = this.hotelInfo.meal_explanation
      this.roomIndexData.pets = this.hotelInfo.pets
      this.roomIndexData.unsubscribe = this.hotelInfo.unsubscribe
      this.roomIndexData.add_beds = this.hotelInfo.add_beds
    },
    cancel () {
      this.roomTyDetShow = false
    },
    clickOverlay () {
      this.roomTyDetShow = false
    },
    goMap () {
      console.log("点击了");

      console.log(this.position);
      this.$router.push({ path: '/allHotel/hotelMap', query: { position: this.position } })
    },
    //查看订单按钮点击
    goOrder () {
      this.$router.push({ path: '/allHotel/HotelMyOrder' })
    },
    //取消订单按钮点击
    cancelOrder (type) {
      if (type == 'cancel') {
        this.cancelTitle = '取消订单'
        this.actType = 'cancel'
        this.reason = 'cancel'
      } else {
        this.cancelTitle = '申请退款'
        this.actType = 'refund'
        this.reason = 'refund'
      }
      this.cancelShow = true
    },
    //取消订单弹窗中的返回键
    cancelReturn () {
      this.cancelShow = false
    },
    //原因点击操作
    getCheck (item) {
      if (item.checked) {
        item.checked = false
      } else {
        item.checked = true
      }
    },
    //提交取消原因
    async submitReason () {
      let arr = []
      this.cancelReason.forEach((item) => {
        if (item.checked == true) {
          arr.push(item)
        }
      })
      let data = {
        order_no: this.orderDetailData.order_no
      }
      this.aesKey = aesKey(data)
      if (this.actType == 'cancel') {
        let res = await cancelHotelOrder(this.aesKey)
        if (res.data.code == 200) {
          this.$router.push({ path: '/allHotel/HotelMyOrder' })
          Toast.success(res.data.message);
        }
      } else {
        let res = await refundHotelOrder(this.aesKey)
        if (res.data.code == 200) {
          this.$router.push({ path: '/allHotel/HotelMyOrder' })

        }
      }

      this.cancelShow = false
    },

    //确认支付按钮
    async payOrder () {
      console.log(this.orderDetailData, "this.orderDetailData");
      let data2 = {
        pay_for: 1,//酒店
        pay_provider_id: 1,//收银宝
        pay_method: 'W02',//微信js
        product_order_no: this.orderDetailData.order_no,
      }
      let res2 = await payWechatHotel(data2)
      if (res2.data.code != 200) {
        Toast.fail(res2.data.message);
      }
      let data3 = {
        prepay_id: res2.data.data.payinfo.prepay_id
      }
      this.aesKey = aesKey(data3)
      let res3 = await PAYJSSDK(this.aesKey)
      if (res3.data.code == 200) {
        Toast.fail('支付中');
        // this.res2.data.data.payinfo = JSON.parse(this.res2.data.data.payinfo)
        this.onBridgeReady(res3)
      } else {
        Toast.fail(res3.data.message);
      }
    },
    //微信浏览器的sdk
    onBridgeReady (data) {
      console.log(data)
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId": data.data.data.appId,     //公众号ID，由商户传入     
        "timeStamp": data.data.data.timeStamp,         //时间戳，自1970年以来的秒数     
        "nonceStr": data.data.data.nonceStr, //随机串     
        "package": data.data.data.package,
        "signType": data.data.data.signType,         //微信签名方式：     
        "paySign": data.data.data.paySign //微信签名 
      },
        function (res) {
          // get_brand_wcpay_request:ok	支付成功
          // get_brand_wcpay_request:cancel	支付过程中用户取消
          // get_brand_wcpay_request:fail	支付失败

          if (res.err_msg == "get_brand_wcpay_request:cancel" || res.err_msg == "get_brand_wcpay_request:fail") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.res3 = res
            Toast.fail('支付失败');
            // this.$router.push({ path: '/allHotel/HotelOrderDetail' })
            window.location.href = "https://pt.guansj.com/allHotel/HotelOrderDetail"
          } else {
            // //用户支付成功后，代理商账户扣钱
            // let  data = {
            //   order_no: this.order_no
            // }
            // this.aesKey = aesKey(data)
            // this.payHotelOrder(this.aesKey)
            Toast.success('支付成功');
            // window.location.href = "https://pt.guansj.com/allHotel/orderDtra"\
            this.$router.go(0)
          }
        });
    },

    //再次预订按钮
    reBook () {
      console.log(this.hotelInfo.id)
      this.$router.push(
        {
          path: '/allHotel/HotelDatail',
          query: { hotelId: this.hotelInfo.id }
        }
      )
    }
  }
}
</script>

<style lang="less" scoped>
#HotelOrderDetail {
  background-color: #f4f9f8;
  text-align: left;
  .topState {
    padding: 0 0.3rem;
    overflow: hidden;
    font-size: 0.2rem;
    padding-top: 0.6rem;
    padding-bottom: 0.3rem;
    .btn {
      margin-top: 0.3rem;
      width: 95%;
      .van-row--flex {
        font-size: 0.23rem;
        span {
          display: inline-block;
          width: 100%;
          text-align: center;
          border-radius: 20px;
          background-color: white;
          padding: 0.12rem 0.2rem;
        }
      }
      span {
        display: inline-block;
        width: 100%;
        font-size: 0.23rem;
        text-align: center;
        border-radius: 20px;
        background-color: white;
        padding: 0.12rem 0.2rem;
      }
    }
  }
  .cancel_Read {
    font-size: 0.27rem;
    padding: 0 0.3rem;
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    .cancel {
      padding: 0.2rem 0;
      span {
        font-size: 0.2rem;
        float: right;
        color: #0086f7;
      }
    }
    .read {
      .room_musRea {
        background-color: white;
        font-size: 0.3rem;
        padding: 0.2rem 0;
        .title {
          position: relative;
          img {
            position: absolute;
            width: 4%;
            top: 13%;
          }
        }
        ul {
          padding: 0.15rem 0.3rem;
          font-size: 0.2rem;
          color: gray;
          list-style-type: disc;
        }
      }
    }
  }
  .payOnline {
    margin-top: 0.15rem;
    font-size: 0.3rem;
    font-weight: bold;
    padding: 0.1rem 0.3rem;
    background-color: white;
    span {
      margin-left: 0.2rem;
      color: #fd7644;
    }
  }
  .hotelInfo {
    padding: 0.2rem 0.3rem;
    margin-top: 0.15rem;
    background-color: white;
    .title {
      font-size: 0.2rem;
    }
    .hotelDetail {
      font-size: 0.28rem;
      .list {
        display: flex;
        margin-top: 0.2rem;
        height: 1.5rem;
        .left {
          width: 40%;
          img {
            width: 60%;
            border-radius: 5px;
            height: 100%;
          }
        }
        .HDright {
          width: 77%;
          margin-left: -0.6rem;
          font-size: 0.1rem;
          span {
            // margin-right: 0.4rem;
            position: relative;
            img {
              width: 35%;
              position: absolute;
              top: -17%;
              right: 70%;
            }
            .textName {
              margin-left: 0.3rem;
            }
          }
        }
      }
      .localAddress {
        margin-top: 0.2rem;
        img {
          width: 100%;
        }
      }
      .rName {
        padding-bottom: 0.2rem;
        .title {
          font-size: 0.3rem;
          font-weight: bold;
        }
        div {
          margin-top: 0.2rem;
        }
      }
      .date {
        font-size: 0.23rem;
        margin-top: 0.2rem;
        .enter {
          display: inline-block;
        }
        .leave {
          float: right;
          line-height: 23px;
        }
        span {
          font-size: 0.21rem;
          color: gray;
        }
      }
      .breakfastInfo {
        font-size: 0.23rem;
        background-color: #f7f7f7;
        margin-top: 0.3rem;
        padding: 0.15rem 0.15rem;
        border-radius: 2px;
        .dateInfo {
          margin-left: 1.5rem;
        }
      }
      .peopleInfo {
        .everyInfo {
          display: flex;
          margin-top: 0.2rem;
          font-size: 0.23rem;
        }
      }
    }
  }
  .orderInfo {
    padding: 0.2rem 0.3rem;
    background-color: white;
    margin-top: 0.15rem;
    .title {
      font-size: 0.3rem;
      font-weight: bold;
    }
    .info {
      font-size: 0.26rem;
    }
  }
  .bottom {
    font-size: 0.25rem;
    padding: 0.2rem 0.3rem;
    background-color: white;
    margin-top: 0.15rem;
    position: relative;
    .van-button--small {
      font-size: 0.2rem;
      padding: 0.2rem 0.2rem;
      position: absolute;
      right: 7%;
      top: 11%;
      margin-top: 0.01rem;
    }
  }
  .policySheet {
    font-size: 0.3rem;
    .van-popup--bottom {
      height: 40%;
    }
    .van-action-sheet__header {
      font-size: 0.3rem;
      font-weight: bold;
    }
    .content {
      padding: 0 0.3rem;
      color: black;
      .Facilities {
        margin-top: 0.14rem;
        background-color: white;
        .title {
          font-size: 0.3rem;
          font-weight: bold;
          margin-left: 0.3rem;
        }
        .imageList {
          white-space: nowrap;
          overflow-x: scroll;
          overflow-y: hidden;
          margin-top: 0.2rem;
          padding-left: 0.2rem;
          width: 7rem;
          span {
            position: relative;
            div {
              position: absolute;
              font-size: 0.1rem;
              bottom: 45%;
              left: 4%;
              color: white;
              opacity: 0.5;
              padding: 0.05rem 0.11rem;
              border-radius: 20px;
              background-color: #1f1f20;
            }
            img {
              width: 40%;
              margin-right: 0.1rem;
              height: 2rem;
            }
          }
        }
        .contentF {
          overflow: hidden;
          height: 100%;
          .title {
            font-size: 0.25rem;
            position: relative;
            margin-top: 0.2rem;
            span {
              margin-left: 0.4rem;
            }
            img {
              position: absolute;
            }
          }
          .cont {
            margin-left: 0.7rem;
            color: gray;
            font-size: 0.22rem;
            .van-col--6 {
              width: 33.3%;
              margin-top: 0.1rem;
            }
          }
        }
        .btn {
          width: 100%;
          text-align: center;
          margin-top: 0.2rem;
          font-size: 0.2rem;
          /deep/.van-button--normal {
            height: 36px;
            padding: 0.03rem 1rem;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .cancelReason {
    ::v-deep .van-popup.van-popup--right {
      width: 100%;
    }

    .van-popup--bottom {
      top: 0%;
    }
    .content {
      padding: 0.2rem 0.3rem;
      font-size: 0.28rem;
      .title {
        padding: 0.2rem 0;
        font-size: 0.22rem;
      }
      .name {
        display: inline-block;
        margin-left: 0.5rem;
        width: 90%;
        position: absolute;
        top: -5%;
        div {
          display: inline-block;
          width: 95%;
        }
      }
    }
    .van-button--round {
      position: absolute;
      width: 92%;
      bottom: 3%;
      font-size: 0.29rem;
      background-color: #36e0c9;
      border: none;
      height: 40px;
    }
  }
  //比1px还小的border
  .thinner-border {
    position: relative;
    width: 100%;
    height: 1px;
  }
  .thinner-border:after {
    content: "";
    position: absolute;
    width: 1000%;
    border: 1px solid gray;
    transform-origin: 0 0;
    transform: scale(0.1, 0.1);
    box-sizing: border-box;
    // margin-top: 0.2rem;
  }
}
.localAddress {
  width: 100%;
  height: 1.1111rem;
  position: relative;
  .address_btn {
    position: absolute;
    z-index: 1000;
    right: 8%;
    top: 30%;
    font-size: 2.3vw;
    color: #0086f7;
    span {
      position: absolute;
      z-index: 1000;
    }
  }
  .address {
    position: absolute;
    z-index: 1000;
    top: 25%;
    width: 40%;
    left: 5%;
    font-size: 2.3vw;
  }
}
</style>