<template>
  <div id="privacy">
    <div class="theme">
      观视界隐私政策
    </div>
    <span class="foreword">欢迎您使用我们的产品和服务！观视界网络科技有限公司（本隐私政策中指广东观视界网络科技有限网讯科技有限公司，以下亦称“我们”）非常重视您的隐私保护和个人信息保护。本隐私政策适用于您通过任何方式对观视界提供的各项服务的访问和使用，包括观视界网站等在内的各种形态（包括未来技术发展出现的新的服务形态），<div style="color:#3388ff;display: initial;">《观视界网络科技有限隐私政策总则》</div>将作为补充内容覆盖本政策未涵盖的信息。需要特别说明的是，本政策不适用于其他第三方向您提供的服务，第三方向您提供的服务适用其向您说明的隐私权政策。</span>
    <span class="foreword">观视界为您提供如下服务：
      如果您是用户，即加盟者身份，您可以利用观视界搜索浏览加盟项目信息及品牌商信息、咨询等一系列服务。
      如果您是项目方即品牌商身份，您可以将您的项目信息及公司信息上传至观视界，同时利用观视界提供的工具进行数据监控、咨询管理及信息维护等服务。

      本隐私政策旨在帮助您了解我们会收集那些数据、为什么收集这些数据，会利用这些数据做些什么及如何保护这些数据。请您务必认真阅读本隐私政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分了解并同意后使用观视界的产品或服务。若您使用观视界的相关产品和服务，即表示您充分了解并同意我们在本政策中所述内容。如果您或您的监护人不同意本隐私政策的任何内容，您应该立即停止使用观视界服务。

      本政策将帮助您了解以下内容：

      一、我们如何收集和使用您的个人信息
      二、我们如何使用Cookie和同类技术
      三、我们如何共享、转让、公开披露您的个人信息
      四、我们如何保存及保护您的个人信息
      五、您的权利
      六、我们如何处理未成年人的个人信息
      七、您的个人信息如何在全球范围转移
      八、本隐私政策如何更新
      九、如何联系我们

      我们深知个人信息对您的重要性，也深知为您的信息提供有效保护是我们业务健康可持续发展的基石。感谢您对观视界的使用和信任！我们致力于维持您对我们的信任，恪守适用法律和我们对您的承诺，尽全力保证您的个人信息安全和合理使用。同时，我们郑重承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。鉴此，观视界服务提供者（或简称“我们”）制定本《隐私政策》并提醒您：
      我们尽量以更清晰、更容易被您理解的方式展现本隐私政策，从而希望能够真实地传达我们希望向您传达的信息，并希望您在向我们提供某些信息以及允许我们处理并分享某些信息前，能够清晰地了解这些信息收集的目的、可能的用途以及其他方面的内容。</span>
    <div class="main">
      <div class="title">一、我们如何收集和使用您的个人信息</div>
      <div class="main_body">
        个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。观视界应用数据中与您个人无关的信息不属于您的个人信息。
        当您使用以下服务、功能或设置时，我们会以下列方式收集和使用您的个人信息：

      </div>

    </div>
    <div class="main">
      <div class="title">（一）您直接提供和我们自动采集的个人信息</div>
      <div class="main_body">
        观视界各项业务功能包括为用户提供搜索服务、浏览服务、咨询服务、申请加盟服务；为项目方提供数据监控、客户信息管理、咨询管理、来电管理等。
        除上述业务功能外，观视界接入了其他第三方服务，包括以软件开发工具包（SDK）形式接入的第三方账号登录、分享、消息推送以及其他各类第三方服务。请注意，第三方服务提供方对您个人信息的处理将适用该第三方服务的隐私政策。
      </div>
    </div>
    <div class="main">
      <div class="title">1、注册信息。</div>
      <div class="main_body">
        ①在您使用观视界提供的服务时，如果您是用户，您可以登录经注册的观视界网络科技有限通用账号。当注册观视界网络科技有限通用账号时，您需向我们提供以下信息：帐号名称、头像、密保邮箱、密保手机、密保问题，并创建密码。如果您是项目方，您可以创建观视界会员账号及密码，创建账号时，您需向我们提供以下信息：账号名称、公司名称、营业执照、联系人姓名、联系人电话等信息。
        ②为便于向您提供我们的产品或服务，我们会记录您提供的信息，例如使用观视界咨询服务时，您需要提供手机号码、联系人姓名等信息。
        ③当您使用观视界的特定业务功能时，为满足向您提供该产品和服务之目的，除注册信息外，您还需要进一步提供包括但不限于您的个人身份信息及其他为您提供服务所必须信息，如果您不使用特定产品和服务，则无需提供相关信息。如果仅仅需使用浏览、搜索功能，您无需登录观视界网络科技有限通用账号。
        ④对于需要通过观视界网络科技有限通用账号才能使用的观视界服务，我们可能会根据您提供的上述信息校验您的身份，确保我们是在为您本人提供服务。
        ⑤我们的一些产品或服务需要付费才能使用，当您需要使用这些付费产品或服务时，您需要提供您的银行卡信息等，以便我们了解您的支付状态。您可以通过观视界为其他人订购产品或服务，您需要提供该实际订购人的前述个人信息。向我们提供该实际订购人的前述个人信息之前，您需确保您已经取得其授权同意。
      </div>
    </div>
    <div class="main">
      <div class="title">2、日志信息。</div>
      <div class="main_body">
        与大多数互联网服务一样，当您使用观视界的各项服务时，我们的服务器会自动记录您的日志信息，例如您输入的搜索关键词信息和点击的链接，您的浏览记录，您发布/查看的咨询信息，您的IP 地址、浏览器的类型和使用的语言、硬件设备信息、操作系统的版本、网络运营商的信息、您访问服务的日期、时间、时长等您在使用我们的产品或服务时提供、形成或留存的信息。为了给您带来更便捷的服务并不断完善观视界产品及服务，我们可能会使用浏览器网络存储等机制（包括HTML5）和应用数据缓存，收集您设备上的信息并进行本地存储。
      </div>
    </div>
    <div class="main">
      <div class="title">
        （二）我们间接收集的您的信息
      </div>
      <div class="main_body">
        1、您使用的第三方产品或服务的信息。
        观视界接入了的第三方服务。当您使用观视界的第三方服务时，可能需要提交您的个人信息。我们将基于您的授权将该服务所必要的信息提供给为您提供服务的第三方，同时基于您的授权向第三方获取必要的相关信息。关于第三方服务具体如何收集、使用您的个人信息，建议您参考第三方服务的相关服务协议及隐私政策。
        2、为共同向您提供产品/服务或改进产品服务的质量和个性化程度或出于对产品和服务安全性的考量等合理需要，我们将按照法律法规的规定或基于您的授权从观视界网络科技有限平台、关联公司、合作伙伴及其他受信任的第三方供应商处接收您的个人信息及其他信息，并依照您与第三方的约定或本隐私政策使用您的个人信息，两者出现冲突以本隐私政策为准。
      </div>
    </div>
    <div class="main">
      <div class="title">
        （三）我们可能如何使用收集到的信息
      </div>
      <div class="main_body">
        1、提供和改进产品和服务
        我们使用收集的信息来提供并改进我们及我们的关联公司、合作伙伴及其他受信任的第三方供应商、服务商及代理商提供的产品和服务，并进行必要的业务运营，例如运营产品或提供服务、评估、维护和改进产品或服务的性能，参与有关我们产品和服务的市场调查活动，开发新的产品或服务、提供客户支持等。
        为改进我们及我们的关联公司、合作伙伴及其他受信任的第三方供应商、服务商及代理商的产品和服务，观视界网络科技有限公司也可能会对产品使用情况进行统计和分析。同时，观视界网络科技有限公司可能会与公众共享这些统计信息，以展示我们服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。
        2、为您提供咨询服务
        您可以使用直接拨打电话或填写表单咨询具体加盟项目或了解加盟服务。若您使用前述功能，您授权观视界获取您的以下信息：
        ①您在使用观视界咨询功能时，主动填写的项目咨询信息，包括姓名、手机号码、咨询项目内容等，您授权我们收集前述信息及您点击的项目信息，并将该项目咨询信息同步给您咨询的项目方或项目方所属的第三方平台。如果您同意勾选“允许我们将上述咨询信息分发至更多项目方”按钮，则您授权我们将上述咨询信息分发给系统自动匹配的项目方。同时，为了给您提供更好的咨询服务、帮助您找到合适的加盟项目，我们可能会根据您的咨询情况与您电话联系，以便为您提供更优质的服务及相关加盟资料等。
        ②为了方便您输入手机号码，更快捷的进行咨询，您同意我们可能会默认展示您观视界网络科技有限通用账号绑定的手机号码，您可以点击手机号码输入框进行修改，填写使用咨询服务时您要使用的手机号码。
        3、提供个性化产品和服务
        我们会基于我们收集的信息对您的偏好、习惯、位置作特征分析和用户画像，以便为您展示、推送更适合您的定制化信息、商品、服务，或进行安全保障和风险防范。
        我们可能会将您在观视界中的画像信息与您在其他观视界网络科技有限服务中的信息（包括个人信息）结合起来，以便观视界网络科技有限公司整体能更好地满足您的需求。您同意授权我们与关联公司、联盟成员或其他合作伙伴共享必要的信息以便总体上提升为您提供产品或服务的个性化和优质程度。
        4、为您展示和推送个性化内容和广告
        我们会分析您在观视界内的使用行为信息（搜索、浏览等）、地理位置（经过您的授权）形成用户画像，以便向您推荐相关性更高的内容和广告。
      </div>
    </div>
    <div class="main">
      <div class="title">（四）事先征得授权同意的例外</div>
      <div class="main_body">
        请注意：在以下情形中，收集、使用个人信息无需事先征得您的授权同意：
        1、与国家安全、国防安全直接相关的；
        2、与公共安全、公共卫生、重大公共利益直接相关的；
        3、与犯罪侦查、起诉、审判和判决执行等直接有关的；
        4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        5、所收集的个人信息是您自行向社会公众公开的；
        6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
        7、根据您的要求向您提供产品或服务所必需的；
        8、用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
        9、为新闻单位开展合法的新闻报道所必需的；
        10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
        11、法律法规规定的其他情形。
      </div>
    </div>
    <div class="main">
      <div class="title">（五）个人信息的匿名化处理</div>
      <div class="main_body">
        在收集到您的个人信息后，我们将通过技术手段及时对数据进行匿名化处理。在不泄露您个人信息的前提下，观视界网络科技有限公司有权对匿名化处理后的用户数据库进行挖掘、分析和利用（包括商业性使用），有权对产品/服务使用情况进行统计并与公众/第三方共享脱敏的统计信息。
        您授权我们在您使用观视界产品及服务的期间使用您的个人信息，若您注销帐号，我们将根据本隐私政策的规定处理您的个人信息。我们可能使用您的信息用于安全防范和诈骗监测等，以预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、我们或我们的关联公司、合作伙伴及社会公众的合法权益。当我们要将信息用于本隐私政策未载明的其它用途时，会事先征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
      </div>
    </div>
    <div class="main">
      <div class="title">
        二、我们如何使用Cookie和同类技术
      </div>
      <div class="main_body">Cookie和匿名标识符工具。Cookie是支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制。当您使用观视界产品或服务时，我们会向您的设备发送一个或多个Cookie或匿名标识符。当您与观视界服务进行交互时，我们允许Cookie或者匿名标识符发送给观视界网络科技有限公司服务器。Cookie 通常包含标识符、站点名称以及一些号码和字符。运用Cookie技术，观视界网络科技有限公司能够了解您的使用习惯，记住您的偏好，省去您输入重复信息的步骤，为您提供更加周到的个性化服务，或帮您判断您账户的安全性。Cookie还可以帮助我们统计流量信息，分析页面设计和广告的有效性。
        我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该等修改，无法登录或使用依赖于Cookie的观视界网络科技有限公司提供的服务或功能。
        您可以通过更改您的浏览器设置限制观视界网络科技有限公司对Cookie的使用。以观视界网络科技有限浏览器为例，您可以在观视界网络科技有限浏览器右上方的下拉菜单的“浏览器设置”中，通过“隐私设置——清除浏览数据”，选择清除您的Cookie。如需详细了解其他浏览器如何更改浏览器设置，请参考该浏览器的帮助中心或其他相关功能指引页面，</div>
    </div>
    <div class="main">
      <div class="title">
        三、我们如何共享、转让、公开披露您的个人信息
      </div>
      <div class="main_body">
        仅为实现本隐私政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
      </div>
    </div>
    <div class="main">
      <div class="title">
        （一）共享
      </div>
      <div class="main_body">
        除非经过您本人事先授权同意，我们不会向除观视界网络科技有限关联公司以外的第三方共享您的个人信息，但经过处理无法识别特定个人且不能复原的除外。
      </div>
    </div>
    <div class="main">
      <div class="title">
        在下列情况下，经过您的授权同意，我们可能会共享的个人信息：
      </div>
      <div class="main_body">
        1、第三方产品或服务提供商。当您使用第三方产品或服务时，我们会根据您的授权将使用第三方产品或服务所必需的信息提供给第三方产品或服务提供商，以便其基于相关信息为您提供产品或服务。关于第三方服务具体如何收集、使用您的个人信息，建议您参考第三方服务的相关服务协议及隐私政策。
        2、广告、咨询类服务商/广告主。未经您授权，我们不会将您的个人信息与提供广告、咨询类服务商共享。您授权我们将经处理无法识别您的身份且接收方无法复原的信息，例如经匿名化处理的用户画像，与广告或咨询类服务商或广告主共享，以帮助其在不识别您个人的前提下，提升广告有效触达率，以及分析我们的产品和服务使用情况等。
        3、金融服务平台或服务提供商。您授权我们有权根据您对金融服务类合作伙伴的授权，或基于法律法规、法律程序的要求或强制性的政府要求或司法裁定，对您的个人信息进行匿名化的数据统计和分析处理，并将数据处理的结果提供给金融服务平台或提供商，用于判定您的资信状况，筛选出优质用户，降低信贷坏账率。
        对我们与之共享个人信息的公司、组织和个人，我们会对其数据安全环境进行调查，与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
        4、您主动与他人共享的信息
        您可以利用观视界的分享功能与页面上显示的社交账号好友共享，包括但不限于您的信息、商品信息结果等。请您在使用第三方服务或分享功能前充分考虑信息接收人的信誉情况，并建议您查看您利用的社交网络或第三方服务提供商的隐私声明，以了解他们如何处理您的信息，以便审慎决策。
      </div>
    </div>
    <div class="main">
      <div class="title">（二）转让</div>
      <div class="main_body">
        我们不会将您的个人信息转让给除关联公司外的任何公司、组织和个人，但以下情况除外：
        1、事先获得您的明确授权或同意；
        2、满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；
        3、如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并尽最大可能确保新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
      </div>
    </div>
    <div class="main">
      <div class="title">（三）公开披露</div>
      <div class="main_body">
        我们仅会在以下情况下，公开披露您的个人信息：
        获得您的明确同意；
        基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。
      </div>
    </div>
    <div class="main">
      <div class="title">（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</div>
      <div class="main_body">
        在以下情形中，共享、转让、公开披露个人信息无需事先征得个人信息主体的授权同意：
        1、与国家安全、国防安全直接相关的；
        2、与公共安全、公共卫生、重大公共利益直接相关的；
        3、与犯罪侦查、起诉、审判和判决执行等直接相关的；
        4、 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        5、个人信息主体自行向社会公众公开的个人信息；
        6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </div>
    </div>
    <div class="main">
      <div class="title">
        四、我们如何保存及保护您的个人信息
      </div>
      <div class="title">（一）保存期限。</div>
      <div class="main_body">
        您在使用观视界产品及服务期间，我们将持续为您保存您的个人信息。 如果您注销帐户或主动删除上述信息，我们将依据网络安全法等法律法规规定保存您的信息。在您注销帐户或主动删除上述信息后，我们不会再对您的个人信息进行商业化使用，但我们可能会对您的个人信息进行匿名化处理后使用。
      </div>
    </div>
    <div class="main">
      <div class="title">（二）保存地域</div>
      <div class="main_body"> 您的个人信息均储存于中华人民共和国境内，如需跨境传输，我们将会严格按照法律法规的规定执行，并单独征得您的授权同意。</div>
    </div>
    <div class="main">
      <div class="title">（三）安全措施</div>
      <div class="main_body">
        1、我们会以“最小化”原则收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。
        2、我们非常重视信息安全。我们成立了专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全背景审查，我们建立了完善的信息安全管理制度和内部安全事件处置机制等。我们会采取适当的符合业界标准的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、遭到被未经授权的访问、公开披露、使用、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。
        3、我们会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。我们会对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，会被立即终止与观视界网络科技有限公司的合作关系，并会被追究相关法律责任，对接触个人信息人员在离岗时也提出了保密要求。
        4、我们提醒您注意，互联网并非绝对安全的环境，当您通过观视界中嵌入的第三方社交软件等与其他用户交互您的地理位置或行踪轨迹信息时，不确定第三方软件对信息的传递是否完全加密，注意确保您个人信息的安全。
        5、我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。请您了解，您使用我们的产品和/或服务时所用的系统和通讯网络，有可能在我们控制之外的其他环节而出现安全问题。
        6、根据我们的安全管理制度，个人信息泄露、毁损或丢失事件被列为最特大安全事件，一经发生将启动公司最高级别的紧急预案，由安全部、政府关系部、法务部等多个部门组成联合应急响应小组处理。
      </div>
    </div>
    <div class="main">
      <div class="title">
        （四）安全事件通知
      </div>
      <div class="main_body">
        1、我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。
        2、个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，组成紧急应急小组，在最短时间内追溯原因并减少损失。
        3、在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以站内通知、短信通知、电话、邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
      </div>
    </div>
    <div class="main">
      <div class="title">
        五、您的权利
      </div>
      <div class="main_body">
        按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
      </div>
    </div>
    <div class="main">
      <div class="title">（一）访问权。</div>
      <div class="main_body">
        原则上您可以通过如下方式访问您的个人信息：
      </div>
    </div>
    <div class="main">
      <div class="title">
        1、帐户信息：
      </div>
      <div class="main_body">
        如您是用户，您可以随时登录，访问或编辑您的帐户中的个人资料信息、更改您的密码、添加安全信息、进行账户关联或身份认证等；如您是项目方，您可以随时登陆观视界项目方后台（观视界品牌商中心），访问或编辑您账户中的个人资料信息。
      </div>
    </div>
    <div class="main">
      <div class="title">
        咨询信息：
      </div>
      <div class="main_body">
        您可以在观视界中查阅您的咨询记录
      </div>
    </div>
    <div class="main">
      <div class="title">3、线索信息：</div>
      <div class="main_body">
        项目方可以在观视界品牌商中心与用户进行管理历史咨询人信息资料等信息。
      </div>
    </div>
    <div class="main">
      <div class="title">（二）更正权。</div>
      <div class="main_body">
        当您发现我们处理的关于您的个人信息有错误时，经对您的身份进行验证，且更正不影响信息的客观性和准确性的情况下，您有权对错误或不完整的信息作出更正或更新，您可以自行在观视界网站内进行更正，或通过反馈与报错等将您的更正申请提交给我们。
      </div>
    </div>
    <div class="main">
      <div class="title">
        （三）删除权
      </div>
      <div class="main_body">
        在以下情形中，您可以向我们提出删除个人信息的请求：
        1、如果我们违反法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息；
        2、如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。
        当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。
      </div>
    </div>
    <div class="main">
      <div class="title">
        （四）撤销权
      </div>
      <div class="main_body">
        每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。如：您可以登录https://passport.baidu.com，解除观视界网络科技有限通用帐号与第三方帐号的绑定关系。当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。
      </div>
    </div>
    <div class="main">
      <div class="title">
        （五）注销权。
      </div>
      <div class="main_body">
        您随时可以登录，注销您的观视界网络科技有限帐号。一旦您注销观视界网络科技有限账号，将无法使用包括观视界在内的观视界网络科技有限公司全线用户产品的服务，因此请您谨慎操作。我们为了保护您或他人的合法权益会结合您对观视界网络科技有限公司各产品的使用情况判断是否支持您的注销请求。例如若您在观视界网络科技有限经验里有未提现的稿费，或您在观视界网络科技有限糯米里有未消费的糯米储值卡，或您的网盘中还存有资料，则我们不会立即支持您的请求。除法律法规另有规定外，注销帐号之后我们将停止为您提供产品和服务，并根据您的要求删除您的个人信息。
      </div>
    </div>
    <div class="main">
      <div class="title">（六）提前获知产品和服务停止运营权</div>
      <div class="main_body">
        观视界愿一直陪伴您，若因特殊原因导致观视界产品和服务被迫停止运营，我们将提前15日在产品或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在三十天内做出答复。
        对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。

        在以下情形中，按照法律法规要求，我们将无法响应您的更正、删除、注销信息的请求：

        1、与国家安全、国防安全直接相关的；
        2、与公共安全、公共卫生、重大公共利益直接相关的；
        3、与犯罪侦查、起诉、审判和执行判决等直接相关的；
        4、我们有充分证据表明您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；
        5、响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；
        6、涉及商业秘密的。
      </div>
    </div>
    <div class="main">
      <div class="title">
        六、我们如何处理未成年人的个人信息
      </div>
      <div class="main_body">
        观视界网络科技有限公司非常重视对未成年人信息的保护。
        我们的产品、网站和服务主要面向成年人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。
        尽管当地法律和习俗对儿童的定义不同，但我们将不满 14 周岁的任何人均视为儿童。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
        若您是14周岁以下的未成年人，建议您请您的监护人仔细阅读本隐私政策，并在征得您的监护人同意的前提下使用我们的产品和/或服务或向我们提供信息。
        如果任何时候监护人有理由相信我们在未获监护人同意的情况下收集了未成年人的个人信息，请通过联系我们，我们会采取措施尽快删除相关数据。
      </div>
    </div>
    <div class="main">
      <div class="title">
        七、您的个人信息如何在全球范围转移
      </div>
      <div class="main_body">
        原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。
        由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。
        此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
      </div>
    </div>
    <div class="main">
      <div class="title">
        八、本隐私政策如何更新
      </div>
      <div class="main_body">
        我们的隐私政策可能变更。
        未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本隐私政策所做的任何变更。
        对于重大变更，我们会在产品或服务的主要曝光页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您。若您不同意该等变更，请您立即停止使用观视界产品和服务。

        本政策所指的重大变更包括但不限于：
        1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
        2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
        3、个人信息共享、转让或公开披露的主要对象发生变化；
        4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
        5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
        6、个人信息安全影响评估报告表明存在高风险时。

        我们还会将本政策的旧版本存档，供您查阅。
        如有本隐私政策未尽事宜，以《观视界网络科技有限隐私权保护声明》为准。
      </div>
    </div>
    <div class="main">
      <div class="title">九、如何联系我们</div>
      <div class="main_body">
        观视界的成长离不开用户与项目方的共同努力，我们非常感谢您对观视界数据更新、使用反馈方面的贡献。
        您可以通过观视界内【意见反馈】联系我们，反馈您对观视界的建议以及在使用过程中遇到的问题，以帮助我们优化产品功能及服务，使更多用户更加便捷的使用我们的产品和服务。
        您可以通过个人信息保护问题反馈平台同我们联系。
        您也可以通过如下联络方式同我们联系：
        中国广东市海淀区上地十街10号
        广东观视界网络科技有限网讯科技有限公司 法务部
        邮政编码：100085

        为保障我们高效处理您的问题并及时向您反馈，需要您提交身份证明、有效联系方式和书面请求及相关证据，我们会在验证您的身份处理您的请求。
        如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解决方案：向广东市海淀区人民法院提起诉讼。

      </div>
    </div>
    <div class="main">
      <div class="title">
        附：名词解释
      </div>
      <div class="title">
        （一）个人信息：
      </div>
      <div class="main_body">
        是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。
      </div>
    </div>
    <div class="main">
      <div class="title">
        （二）个人敏感信息
      </div>
      <div class="main_body">
        是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14岁以下（含）儿童的个人信息等。
      </div>
    </div>
    <div class="main">
      <div class="title">
        （三）设备
      </div>
      <div class="main_body">
        是指可用于使用观视界网络科技有限产品和/或服务的装置，例如桌面设备、平板电脑或智能手机。
      </div>
    </div>
    <div class="main">
      <div class="title">（四）唯一设备标识符：</div>
      <div class="main_body">
        是指具有唯一性的设备标识符（有时称为通用专属 ID 或 UUID）是指由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备（例如国际移动设备身份码IMEI号、网络设备硬件地址MAC）。不同的设备标识符在有效期、用户是否可以重置以及获取方式方面会有所不同。相应设备可能会有多个不同的具有唯一性的设备标识符。唯一设备标识符可用于多种用途，其中包括安全性和欺诈检测、同步服务（如用户的电子邮件收件箱）、记录用户的偏好设置以及提供具有相关性的广告。
      </div>
    </div>
    <div class="main">
      <div class="title">
        （五）Cookie
      </div>
      <div class="main_body">
        是指支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制，通过增加简单、持续的客户端状态来扩展基于Web的客户端/服务器应用。服务器在向客户端返回HTTP对象的同时发送一条状态信息，并由客户端保存。状态信息中说明了该状态下有效的URL范围。此后，客户端发起的该范围内的HTTP请求都将把该状态信息的当前值从客户端返回给服务器，这个状态信息被称为Cookie。
      </div>
    </div>

    <div class="main">
      <div class="title">
        （六）匿名标示符
      </div>
      <div class="main_body">
        是指观视界网络科技有限公司基于设备参数，通过机器算法，生成一个标示符例如BAIDUID、CUID，帮助观视界网络科技有限识别一个用户，为其提供更好的产品和/或服务与改善用户体验。
      </div>
    </div>
    <div class="main">
      <div class="title">
        （七）用户画像
      </div>
      <div class="main_body">
        指通过收集、汇聚、分析个人信息，对某特定自然人个人特征，如其职业、经济、健康、教育、个人喜好、信用、行为等方面做出分析或预测，形成其个人特征模型的过程。直接使用特定自然人的个人信息，形成该自然人的特征模型，称为直接用户画像。使用来源于特定自然人以外的个人信息，如其所在群体的数据，形成该自然人的特征模型，称为间接用户画像。
      </div>
    </div>
    <div class="main">
      <div class="title">（八）匿名化</div>
      <div>是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。</div>
    </div>
    <div class="main">
      <div class="title">（九）观视界网络科技有限平台</div>
      <div class="main_body">是指观视界网络科技有限公司旗下各专门频道或平台服务（包括观视界网络科技有限搜索、观视界网络科技有限百科、观视界网络科技有限知道、观视界网络科技有限贴吧、观视界网络科技有限图片、观视界网络科技有限手机助手及其他观视界网络科技有限系产品）等网站、程序、服务、工具及客户端。</div>
    </div>
    <div class="main">
      <div class="title">（十）关联公司：</div>
      <div class="main_body">是指观视界的经营者广东观视界网络科技有限网讯科技有限公司及其他与观视界网络科技有限公司存在关联关系的公司的单称或合称。“关联关系”是指对于任何主体（包括个人、公司、合伙企业、组织或其他任何实体）而言，即其直接或间接控制的主体，或直接或间接控制其的主体，或直接或间接与其受同一主体控制的主体。前述“控制”指，通过持有表决权、合约或其他方式，直接或间接地拥有对相关主体的管理和决策作出指示或责成他人作出指示的权力或事实上构成实际控制的其他关系。</div>
    </div>
  </div>
</template>


<script>
export default {

}
</script>

<style lang="less" scoped>
#privacy {
  .theme {
    font-size: 0.4364rem;
  }
  span {
    display: block;
  }
  .main {
    .title {
      font-size: 0.3rem;
      text-align: left;
    }
    .main_body {
      font-size: 0.3rem;
      text-indent: 0.2rem;
      text-align: left;
    }
  }
  .foreword {
    font-size: 0.2rem;
    text-align: left;
    text-indent: 0.2rem;
  }
}
</style>