import { request } from './request.js'

export function getCollect (params) {
  return request({
    url: '/home/user/favorite/product',
    method:'GET',
    params
  })
}

export function postCollect (params) {
  return request({
    url: '/home/user/favorite/product',
    method:'POST',
    params
  })
}

export function delCollect (params) {
  return request({
    url: '/home/user/favorite/product',
    method:'delete',
    params
  })
}

export function commentList (params) {
  return request({
    url: '/home/comment/list',
    method:'GET',
    params
  })
}