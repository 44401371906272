<template>
  <div id="shopAddress">
    <!-- <NavBar NavBarTitle="我的收货地址"
            @onClickLeft="onClickLeft"
            class="Navbar"></NavBar> -->
    <div class="addressInfo"
         :style="{ height: innerHeight }">
      <div class="Info"
           v-for="(item, index) in addressInfo"
           :key="item.id">
        <div style="width: 95%"
             @click="getAddressInfo(item)">
          <div class="town">
            <van-tag type="danger"
                     v-if="item.is_default">默认</van-tag>
            <span>{{ item.area }}</span>
          </div>
          <div class="detail">
            {{ item.address }}
          </div>
          <div class="perInfo">
            {{ item.name }}<span>{{ item.phone }}</span>
          </div>
        </div>
        <van-icon name="edit"
                  @click="editInfo(item, index)" />
      </div>
      <div class="btn">
        <van-button type="primary"
                    color="#36E0C9"
                    round
                    @click="addAddress">新建收货地址</van-button>
      </div>
    </div>
    <div class="ReceivingAddress">
      <van-action-sheet v-model="addShow"
                        :round="false">
        <!-- <NavBar :NavBarTitle="SheetTitle"
                @onClickLeft="onSheetClickLeft"></NavBar> -->
        <div class="content">
          <div>
            <van-field v-model="name"
                       :border="false"
                       label="收货人"
                       placeholder="姓名" />
            <van-divider />
            <van-field v-model="phone"
                       type="tel"
                       :border="false"
                       placeholder="手机号码"
                       label="联系方式" />
            <van-divider />
            <div class="city">
              <van-field v-model="fieldValue"
                         readonly
                         label="地区"
                         placeholder="请选择所在地区"
                         @click="cascaderShow = true" />
            </div>
            <van-divider />
            <van-field v-model="detailAddress"
                       :border="false"
                       placeholder="街道，楼牌号等"
                       label="详细地址" />
            <van-divider />
          </div>
          <!-- <div class="identifiAddress">
            <div class="title">智能识别地址:</div>
            <textarea name=""
                      placeholder="粘贴整段文字如：北京市大兴区科创十一街京东集团总部，张小三，13988998899名"
                      id=""
                      v-model="textareaValue"></textarea>
            <div class="btn1">
              <van-button plain
                          round>清除</van-button>
              <van-button type="primary"
                          color="#71EEDD"
                          round>确定</van-button>
            </div>
          </div> -->
          <div class="setDefault">
            <van-cell center
                      title="设置默认地址"
                      label="提醒:每次下单会默认推荐使用该地址">
              <template #right-icon>
                <van-switch v-model="is_default"
                            size="24"
                            active-color="#36E0C9" />
              </template>
            </van-cell>
          </div>
        </div>
        <div class="bottom">
          <div class="btn1"
               v-if="addressType == 'add'">
            <van-button type="primary"
                        round
                        color="#36E0C9"
                        size="large"
                        @click="submitAddress">保存并使用该地址</van-button>
          </div>
          <div class="btn2"
               v-else>
            <van-button type="primary"
                        round
                        plain
                        color="gray"
                        style="background-color: #f5f5f5"
                        @click="deleteAddress">删除</van-button>
            <van-button type="primary"
                        round
                        color="#36e0c9"
                        @click="editAddress"
                        style="margin-left: 0.8rem">保存
            </van-button>
          </div>
        </div>
      </van-action-sheet>
    </div>
    <div class="city">
      <van-popup v-model="cascaderShow"
                 round
                 position="bottom">
        <van-cascader v-model="cascaderValue"
                      title="请选择所在地区"
                      :options="options"
                      active-color="#36e0c9"
                      @close="cascaderShow = false"
                      :field-names="{ text: 'name', value: 'code', children: 'children' }"
                      @change="onChangeArea"
                      @finish="onFinish" />
        <!-- @finish="onFinish" -->
        <!-- @change="showTown" -->
        <!-- @finish="onFinish" -->
      </van-popup>
    </div>
  </div>
</template>

<script>
import { getArea } from "../../../api/common";
import { aesKey } from "../../../commom/aesKey";
// import NavBar from "@/components/NavBar";
import { postAddress, getAddress, EditAddress, DelAddress } from "../../../api/address.js";
export default {
  components: {
    // NavBar,
  },
  data () {
    return {
      one: null,
      two: null,
      three: null,
      innerHeight: 0,
      json: {
        area: {},
      },
      aesKey: aesKey(), //接口通过密文来获取数据
      routerFrom: "", //用于判断那个路由进来
      addressInfo: [
        //地址信息
        {
          // name: '刘德华',
          // phone: 13450646463,
          // fieldValue: '广东省东莞市万江区',
          // detailAddress: '美力酒店503',
          // is_default: true
        },
        // {
        //   name: '陈奕迅',
        //   phone: 18000000001,
        //   fieldValue: '湖北省武汉市江岸区',
        //   detailAddress: '美力酒店隔壁麦当劳',
        //   is_default: false
        // },
      ],
      cityInfo: "", //接口获取的地址信息
      addressType: "add", //地址管理是增加还是修改

      //收货地址弹窗
      addShow: false, //收货地址弹窗
      editIndex: "", //存放需要修改的下标，接入数据后直接用id修改
      SheetTitle: "新增收货地址",
      name: "", //收货人
      phone: "", //电话号码
      detailAddress: "", //详细地址
      textareaValue: "", //文本区内容
      is_default: false, //是否默认地址
      addressCIty_id: "",
      //地区的弹窗
      cascaderShow: false, //所在地区的弹窗选择
      fieldValue: "", //所在地区绑定的值
      cascaderValue: "", //多级联绑定的内容 value值
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [],
      townData: [],
      editData: "",
      // 点击修改获取id
      edit_id: null
    };
  },
  //路由守卫
  beforeRouteEnter (to, from, next) {
    // 这个路由钩子函数比生命周期beforeCreate函数先执行，所以this实例还没有创建出来
    // this.routerFrom = from.name
    // console.log(this.routerFrom)
    next((vm) => {
      vm.routerFrom = from.name;
    });
  },
  created () {
    this.getAddress();
  },

  mounted () {
    this.getArea();
    let NavTop = document.querySelector(".Navbar").clientHeight;
    this.innerHeight = window.innerHeight - NavTop + "px";
  },
  computed: {},
  methods: {
    async getAddress () {
      // console.log("获取地址");
      await getAddress().then((res) => {
        // console.log(res);
        const Data = res.data;
        this.addressInfo = Data.data.list;
        // this.addressInfo = JSON.parse(Data)
        console.log(this.addressInfo);
        this.addressInfo.map((item) => {
          if (item.is_default == 1) {
            let shopAddress = JSON.stringify(item)
            localStorage.setItem("shopAddress", shopAddress)
          }
        })


        //处理三四级显示
        this.addressInfo.map((item) => {
          // console.log(item);
          if (item.area.parent.level == 2) {
            this.fieldValue =
              item.area.parent.parent.name +
              item.area.parent.name +
              item.area.name;
          } else {
            this.fieldValue =
              item.area.parent.parent.parent.name +
              item.area.parent.parent.name +
              item.area.parent.name +
              item.area.name;
          }
          item.area = this.fieldValue
        })
      });
    },
    onClickLeft () {
      this.$router.go(-1);
    },
    //地址数据
    async getArea () {
      await getArea(this.aesKey).then((res) => {
        const DATA = res.data;
        // console.log(DATA, "====Data====");
        if (DATA.code == 200) {
          this.cityInfo = DATA.data;
          const arr = DATA.data.map((ele) => {
            return {
              ...ele,
              children: ele.children.map((ele2) => {
                return {
                  ...ele2,
                  children: [],
                };
              }),
            };
          });
          this.options = arr;
        }
      });
    },
    //点击地址时带信息返回到商品详情页面
    getAddressInfo (data) {
      console.log(data);
      console.log(this.routerFrom);
      if (this.routerFrom == "shopDetail") {
        this.$router.push({
          path: "/allShop/shopDetail",
          query: {
            addressInfo: data.fieldValue,
          },
        });
      } else if (this.routerFrom == "shopConfirmOrder") {
        this.$router.push({
          path: "/allShop/shopConfirmOrder",
          query: {
            addressInfo: [
              {
                name: data.name,
                address: data.address,
                area: data.area,
                phone: data.phone,
                is_default: data.is_default,
              },
            ],
          },
        });
      }
    },
    // onChangeAcidentArea({ value, selectedOptions, tabIndex }){
    // 	let level = tabIndex === 0 ? '01' : '02';
    // 	queryAreas({level:level,parentCode:value}).then(res =>{
    // 		if(res.code == '0'){
    // 			//市
    // 			if(tabIndex === 0){
    // 				res.result.forEach(item => item.childResources = [])
    // 				let index = this.accidentArea.options.findIndex(item =>item.code == value);
    // 				this.accidentArea.options[index].childResources = res.result
    // 			}else if(tabIndex === 1){
    // 				let firstIndex = this.accidentArea.options.findIndex(item =>item.code == selectedOptions[0].code);		//省级 index
    // 				let cities =  this.accidentArea.options[firstIndex].childResources	//所有城市
    // 				let index = cities.findIndex(item =>item.code == value);	//市级 index
    // 				cities[index].childResources = res.result
    // 			}
    // 		}
    // 	})
    onChangeArea ({ selectedOptions }) {
      if (selectedOptions.length < 2) return;
      let data = {
        pcode:
          selectedOptions.length === 2
            ? selectedOptions[1].code
            : selectedOptions[2].code,
      };
      this.aesKey = aesKey(data);
      if (selectedOptions.length === 2) {
        getArea(this.aesKey).then((res) => {
          if (res.data.code === 200) {
            selectedOptions[1].children = res.data.data;
          }
        });
      } else if (selectedOptions.length === 3) {
        getArea(this.aesKey).then((res) => {
          if (res.data.code === 200) {
            if (!res.data.data.length) {
              console.log(selectedOptions);
              delete selectedOptions[2].children;
              this.options = [...this.options];
            } else {
              selectedOptions[2].children = res.data.data;
            }
          }
        });
      }
    },

    //选择所在地区
    onFinish ({ selectedOptions }) {
      this.cascaderShow = false;
      this.fieldValue = selectedOptions.map((option) => option.name).join("");
      if (selectedOptions.length == 4) {
        this.addressCIty_id = selectedOptions[3].code
      } else {
        this.addressCIty_id = selectedOptions[2].code
      }
      console.log(selectedOptions);
    },

    //新建收货地址按钮
    addAddress () {
      this.fieldValue = "";
      this.addShow = true;
      this.addressType = "add";
      this.SheetTitle = "新增收货地址";
      // this.$toast.loading({
      //   message: '加载中...',
      //   forbidClick: true,
      // });
    },
    //编辑地址
    editInfo (data, index) {
      this.edit_id = data.id
      console.log(this.edit_id);
      this.addShow = true;
      this.addressType = "edit";
      this.SheetTitle = "编辑收货地址";
      this.editIndex = index;
      this.name = data.name;
      this.phone = data.phone;
      this.fieldValue = data.area;
      this.detailAddress = data.address;
      this.is_default = data.is_default;
      if (this.is_default == 1) {
        this.is_default = true;
      } else {
        this.is_default = false;
      }
      this.editData = {
        name: data.name,
        phone: data.phone,
        area_code: data.area,
        address: data.address,
        is_default: data.is_default,

        id: data.id,
      };
      // console.log(this.editData);
    },
    //编辑后的地址保存
    async editAddress () {
      let editData = this.editData;
      console.log(this.editData);
      if (this.is_default == true) {
        this.is_default = 1;
      } else {
        this.is_default = 0;
      }
      editData = {
        name: this.name,
        phone: this.phone,
        area_code: this.addressCIty_id,
        address: this.address,
        is_default: this.is_default,
        id: editData.id,
      };
      await EditAddress(editData);
      this.addShow = false;
      this.getAddress()
    },

    //地址管理弹窗
    //弹窗的标题按键
    onSheetClickLeft () {
      this.name = "";
      this.phone = "";
      this.fieldValue = "";
      this.detailAddress = "";
      this.is_default = false;
      this.addShow = false;
    },
    //保存地址
    async submitAddress () {
      if (this.addressType == "add") {

        if (this.is_default == false) {
          this.is_default = 0;
        } else {
          this.is_default = 1;
        }
        // console.log(this.addressInfo, "点击了新增地址");
        console.log(this.is_default);
        let address = {
          name: this.name,
          phone: this.phone,
          area_code: this.addressCIty_id,
          address: this.detailAddress,
          is_default: this.is_default,
        };
        console.log(address);
        await postAddress(address);
      }
      this.getAddress()
      this.name = "";
      this.phone = "";
      this.fieldValue = "";
      this.detailAddress = "";
      this.is_default = false;
      this.addShow = false;
    },
    //删除选中地址
    deleteAddress () {
      this.$dialog
        .confirm({
          message: "是否删除地址",
        })
        .then(() => {
          let data = {
            id: this.edit_id
          }
          DelAddress(data)
          this.addShow = false;
          this.addressInfo.splice(this.editIndex, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
    async showTown (pcode) {
      let data = {
        pcode: pcode.value,
      };

      this.aesKey = aesKey(data);
      await getArea(this.aesKey).then((res) => {
        // console.log(res.data.data);
        this.townData = res.data.data;
        this.townData.map((val) => {
          // console.log(val);
          val.text = val.name;
        });
        if (pcode.tabIndex == 0) {
          this.one = this.options.find((val) => {
            // console.log(val, "1");
            return val.value == pcode.value;
          });
        }
        if (pcode.tabIndex == 2) {
          this.two = this.one.children.find((val) => {
            return val.value == pcode.value;
          });
          this.options.map((val) => {
            if (val.value == this.one.value) {
              val.children.map((data) => {
                // console.log(data);
                // console.log(pcode);
                if (data.value == pcode.value) {
                  data.children = this.townData;
                  // console.log(data.children);
                }
              });
            }
          });
        }
        console.log("县区", this.townData);
      });
    },
    //地区弹窗
  },
};
</script>

<style lang="less" scoped>
#shopAddress {
  .btn {
    .van-button {
      width: 75%;
      font-size: 0.27rem;
      height: 0.7rem;
    }
  }

  .addressInfo {
    background-color: #f5f5f5;

    .Info {
      text-align: left;
      background-color: white;
      padding: 0.2rem 0.4rem;
      font-size: 0.3rem;
      margin-bottom: 0.1852rem;
      border-radius: 10px;
      display: flex;

      .town {
        font-size: 0.24rem;
        color: #666666;

        span {
          margin-right: 0.1rem;
        }
      }

      .detail {
        font-size: 0.3rem;
        font-weight: bold;
      }

      .perInfo {
        font-size: 0.24rem;
        color: #666666;

        span {
          margin-left: 0.3704rem;
        }
      }

      .van-icon {
        font-size: 0.4rem;
        line-height: 1.1111rem;
      }
    }
  }

  .ReceivingAddress {
    /deep/.van-popup--bottom {
      top: 0%;
    }

    .van-action-sheet {
      max-height: 100%;
      background-color: #f5f5f5;
    }

    .content {
      text-align: left;
      padding: 0 0.4rem;
      background-color: white;

      .van-divider {
        margin: 0;
      }

      .van-cell {
        padding: 0.2rem 0;
        font-size: 0.27rem;
      }

      .city {
        font-size: 0.27rem;
      }

      .setDefault {
        span {
          font-size: 0.28rem;
        }

        .van-cell__label {
          font-size: 0.2rem;
          margin: 0;
        }
      }

      .identifiAddress {
        margin-top: 0.4rem;
        position: relative;

        .title {
          font-size: 0.27rem;
          margin-bottom: 0.2rem;
        }

        textarea {
          width: 94%;
          font-size: 0.23rem;
          background-color: #f7f7f7;
          border: none;
          padding: 0.1rem 0.2rem;
          height: 1.8519rem;
          border-radius: 10px;
        }

        .btn1 {
          position: absolute;
          z-index: 10;
          bottom: 15%;
          right: 2%;

          .van-button {
            margin-right: 0.1rem;
            height: 0.6rem;
            width: 1.3rem;
            font-size: 0.25rem;
          }
        }
      }
    }

    .bottom {
      padding: 0 0.4rem;

      .btn1 {
        .van-button {
          width: 100%;
          font-size: 0.27rem;
          height: 0.7rem;
        }
      }

      .btn2 {
        text-align: left;

        .van-button {
          width: 43%;
          font-size: 0.27rem;
          height: 0.7rem;
        }
      }
    }
  }
}
</style>