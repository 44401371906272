<template>
  <div id="Login"
       :style="{ height: innerHeight }">
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="content">
      <div class="title">请输入验证码</div>
      <div style="color: #999999; margin-top: 0.2rem">
        验证码已发送到 {{ $route.query.phone }}
      </div>
      <div class="verifyCode">
        <!-- 密码输入框 -->
        <van-password-input :value="value"
                            :focused="showKeyboard"
                            :mask="false"
                            :length="6"
                            @focus="showKeyboard = true" />
        <div class="countDown"
             v-if="is_send">
          <van-count-down :time="time"
                          format="ss"
                          style="display: inline-block; color: #999999"
                          @finish="finish" />秒后重发
        </div>
        <div class="repeat"
             v-else
             @click="repeat">重发验证码</div>

        <!-- 数字键盘 -->
        <van-number-keyboard v-model="value"
                             :show="showKeyboard"
                             @blur="showKeyboard = false" />
      </div>
      <div class="btn">
        <van-button type="primary"
                    size="large"
                    round
                    color="#38e0c9"
                    @click="LoginInfo">提交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
// import NavBar from '../../components/NavBar.vue'
import { LoginInfo, Login_user } from "@/api/user";
import { Toast } from 'vant';
export default {
  components: {
    // NavBar,
  },
  data () {
    return {
      innerHeight: 0,
      NavBarTitle: "验证手机",
      showKeyboard: false, //号码键盘弹窗
      value: "", //验证码
      is_send: true,
      time: 60 * 1000, //倒计时
      phone: null,
      verification_key: null,
    };
  },
  mounted () {
    this.innerHeight = window.innerHeight + "px";
  },
  watch: {
    value () {
      if (this.value.length == 6) {
        this.confirmCode();
      }
    },
  },
  created () {
    this.phone = this.$route.query.phone;
    this.verification_key = sessionStorage.getItem("verification_key");
    console.log(this.verification_key, "");
  },
  methods: {
    LoginInfo () {
      let data = {
        verification_key: this.verification_key,
        phone: this.phone,
        verification_code: this.value,
      };
      LoginInfo(data).then((res) => {
        if (res.data.message == '登录成功') {
          console.log(res, "提交成功");
          localStorage.setItem("authorization",
            res.data.data.token.access_token)
          let balanceRoute = sessionStorage.getItem('balanceRoute')
          Toast.fail(res.data.message)
          if (balanceRoute != undefined) {
            this.$router.push({ path: balanceRoute })
          } else {
            this.$router.push({ path: '/Home' })
          }
        } else {
          Toast.fail(res.data.message)
        }

      });
    },
    onClickLeft () {
      this.$router.go(-1);
    },
    //验证码输入完时
    confirmCode () {
      this.LoginInfo()
    },
    //倒计时结束时触发
    finish () {
      this.is_send = false;
    },
    //重发验证码
    repeat () {
      let data = {
        phone: this.phone,
      };
      Login_user(data).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.is_send = true;
          this.verification_key = res.data.data.verification_key
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#Login {
  font-size: 0.21rem;

  .content {
    margin-top: 0.6rem;
    padding: 0 0.2rem;

    // background-color: aqua;
    .title {
      font-size: 0.45rem;
      font-weight: bold;
    }

    .verifyCode {
      margin-top: 1rem;
      z-index: 10;
      padding-right: 1.3rem;
      position: relative;

      /deep/.van-cell {
        font-size: 0.3rem;
      }

      .countDown {
        position: absolute;
        right: 1%;
        top: 20%;
        background-color: #f5f5f5;
        font-size: 0.13rem;
        padding: 0.1rem 0.1rem;
        border-radius: 20px;
        color: #999999;
      }

      .repeat {
        position: absolute;
        right: 1%;
        top: 20%;
        background-color: #36e0c9;
        font-size: 0.13rem;
        padding: 0.2rem 0.13rem;
        border-radius: 20px;
        color: white;
      }
    }

    .btn {
      margin-top: 2rem;
    }
  }
}
</style>