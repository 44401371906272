// TreeSelect组件根据定位距离返回数据
import { getHomeData } from '@/api/hotel.js'
export async function coordinates (km) {
  let dateArray = sessionStorage.getItem('dateArray')
  let Hoteldate = dateArray.split(',')
  let start_date = Hoteldate[0].substring(0, Hoteldate[0].length - 3)
  let end_date = Hoteldate[1].substring(0, Hoteldate[1].length - 3)
  let latitude = localStorage.getItem('latitude')
  let longitude = localStorage.getItem('longitude')
  let data = {
    longitude: longitude,
    latitude: latitude,
    start_date: start_date,
    end_date: end_date,
    distance: km
  }
  console.log(data)
  let res = await getHomeData(data)
  console.log(res.data.data.list, '=====res=====')
  let shuju = res.data.data.list
  console.log(shuju, '======shuju======')
  return shuju
}
