<template>
  <div class="container">
    <div class="BG"><img src="../../../assets/HotelImg/EsportsBg.jpg"
           alt=""></div>
    <div class="container_hotel">
      <div class="hotel_top"> <img src="../../../assets/HotelImg/esports_tab.png"
             alt=""></div>
      <div class="hotel">
        <div class="hotel_tab">
          <van-button v-for="item in tabList "
                      :key="item.id">{{item.city}}</van-button>

        </div>
        <div class="HotelList">
          <div class="hotel_info"
               v-for=" item  in showEsportsData"
               :key="item.id">
            <div class="hotel_info_img">
              <div class="hot"> <img src="../../../assets/Esports/hot.png"
                     alt=""> <span>
                  <van-icon name="fire" />热销
                </span></div>
              <div class="hotel_img">
                <div class="hotel_imgage"> <img class="hotelImage"
                       :src="item.img"
                       :onerror="errorImg01"
                       alt=""></div>

                <div class="hotel_img_bottom">
                  <span class="grade"><img src="../../../assets/Esports/grade_2.png"
                         alt=""></span>
                </div>
              </div>
            </div>
            <div class="hotel_info_text">
              <div class="title">{{item.title}}</div>
              <div class="hotel_info_2">
                <div class="level">三星级</div><span style="font-size:12px;margin-left:.0909rem;margin-right:.0909rem">|</span>
                <div class="DIST">当前位置{{item.distance}}km</div>\
                <div style="margin-top: .1rem;"></div>

                <div class="price">
                  <span style="font-size: .1455rem;">¥</span>
                  <span style="font-size:16px;font-weight: 600;">{{item.starting_price}}</span>
                  <span style="font-size:12px">起</span>
                </div>
                <div class="hotel_btn">
                  <van-button @click="getHotel(item)">抢购</van-button>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- <div class="more_hotel">
          <van-button>更多酒店</van-button>
        </div> -->

      </div>
    </div>
    <div id="map"
         v-show="showMap">
      <baidu-map class="map"
                 :center="center"
                 @click="handler"
                 @ready="initMap" />
    </div>
  </div>
</template>

<script>
import BMap from 'vue-baidu-map/components/map/Map'
import { getHomeData } from '../../../api/hotel.js'
import coordtransform from 'coordtransform'; //eslint-disable-line no-unused-vars
import { Toast } from 'vant';
export default {
  components: {
    BaiduMap: BMap,
  },
  data () {
    return {
      tabList: [
        {
          id: 1,
          city: '全部'
        },
        {
          id: 2,
          city: '东莞'
        },
        {
          id: 3,
          city: '广州'
        },
        {
          id: 4,
          city: '深圳'
        },
        {
          id: 5,
          city: '佛山'
        },
      ],
      calendarShow: false,
      showCity: false,
      list: [],
      loading: false,
      showMap: false,
      finished: false,
      like_icon: "like",
      bottom_nav: 0,
      defaultDate: [],//默认日期
      ArrivalDate: null,
      LeavelDate: null,
      dateLength: '',
      showEsportsData: null,
      errorImg01: require('@/assets/HotelImg/esport.png'),
      city: null,
      SearchValue: null,
      center: { lng: null, lat: null },
      point: null,
      //当前用户定位
      latitude_now: null,
      longitude_now: null,
      //获取的酒店定位
      latitude_hotel: null,
      longitude_hotel: null,
      //地址索引组件
      CityIndexes: null,
      container: null,
    }
  },
  created () {
    if (sessionStorage.getItem("dateArray") == null) {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      tomorrow.setDate(tomorrow.getDate() + 1);
      let dateArray = [today.getTime(), tomorrow.getTime()];
      sessionStorage.setItem("dateArray", dateArray);
    }
    this.getTime()
    this.longitude_now = localStorage.getItem('longitude')
    this.latitude_now = localStorage.getItem('latitude')
    console.log(this.longitude_now);
    this.getEsportsData()
  },
  mounted () {
    this.container = this.$refs.container;
  },
  methods: {
    getDate () {

    },
    getWechat () {
      let authorization = localStorage.getItem('authorization')
      if (authorization == undefined) {
        Toast.fail("未有登录信息,稍后跳转")
        setTimeout(() => {
          window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa68cd9e5c0b30448&redirect_uri=https%3A%2F%2Fnewapi.guansj.com%2Fapi%2Fhome%2Fwechat-login-redirect&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'  // 正式服
        }, 1000);
      }
    },
    // 通过首页获取的酒店
    searchE (SearchValue) {
      let data = {
        search: SearchValue,
        room_theme: 12,
        status: 1
      }
      getHomeData(data).then(res => {
        let arr = []
        res.data.data.list.forEach(item => {
          console.log(res.data.data.list);
          arr.push(item)
        })
        this.showEsportsData = arr.map(item => {
          if (item.lng_lat.length != 0) {
            // console.log(item, "当前");
            let gcj02tobd09 = coordtransform.gcj02tobd09(item.lng_lat[0], item.lng_lat[1])
            // console.log(gcj02tobd09, "转换后的酒店定位");
            let distance = this.getDistance(gcj02tobd09[1], gcj02tobd09[0], Number(this.latitude_now), Number(this.longitude_now))//eslint-disable-line no-unused-vars
            item.distance = Math.trunc(distance / 1000)
          }
          return {
            id: item.hotel_id,
            img: item.hotel_pic,
            title: item.name,
            mode: item.mode,
            starting_price: Math.ceil(Number(item.starting_price)),
            city_name: item.city_name,
            hotel_tags: item.hotel_tags,
            discount: item.hotel_tags,
            lng_lat: item.lng_lat,
            distance: item.distance
          }
        })
        console.log(res, "索引获取数据");
      })
    },
    //地址索引获取的酒店
    closeIndexes (city) {
      this.showCity = false
      this.CityIndexes = city
      this.city = this.CityIndexes.name
      console.log(city, "接受到了子组件传过来的数据");
      let data = {
        room_theme: 12,
        city_id: this.CityIndexes.city_id
      }
      getHomeData(data).then(res => {
        let arr = []
        res.data.data.list.forEach(item => {
          console.log(res.data.data.list);
          arr.push(item)
        })
        this.showEsportsData = arr.map(item => {
          if (item.lng_lat.length != 0) {
            // console.log(item, "当前");
            let gcj02tobd09 = coordtransform.gcj02tobd09(item.lng_lat[0], item.lng_lat[1])
            // console.log(gcj02tobd09, "转换后的酒店定位");
            let distance = this.getDistance(gcj02tobd09[1], gcj02tobd09[0], Number(this.latitude_now), Number(this.longitude_now))//eslint-disable-line no-unused-vars
            item.distance = Math.trunc(distance / 1000)
          }
          return {
            id: item.hotel_id,
            img: item.hotel_pic,
            title: item.name,
            mode: item.mode,
            starting_price: Math.ceil(Number(item.starting_price)),
            city_name: item.city_name,
            hotel_tags: item.hotel_tags,
            discount: item.hotel_tags,
            lng_lat: item.lng_lat,
            distance: item.distance
          }
        })
        console.log(res, "索引获取数据");
      })
    },
    shopIndexes () {
      this.showCity = true
    },
    // 测量百度地图两个点间的距离
    getDistance (lat1, lng1, lat2, lng2) {
      let EARTH_RADIUS = 6378.137;
      let radLat1 = this.rad(lat1);
      let radLat2 = this.rad(lat2);
      let a = radLat1 - radLat2;
      let b = this.rad(lng1) - this.rad(lng2);
      let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2)
        + Math.cos(radLat1) * Math.cos(radLat2)
        * Math.pow(Math.sin(b / 2), 2)));
      s = s * EARTH_RADIUS;
      //s = Math.round(s * 10000d) / 10000d;
      s = Math.round(s * 10000) / 10000;
      s = s * 1000;    //乘以1000是换算成米
      console.log(s, "距离");
      return s;
    },
    rad (d) {
      return d * Math.PI / 180.0;
    },


    //获取定位后,百度地图逆解析获得
    handler (e) {
      console.log(e);
      this.city = e.address.city
      console.log(e);
      console.log(this.latitude_now, " this.latitude_now");
      console.log(this.longitude_now, " this.longitude_now");

      // console.log(this.city, "====this.city====");
      const myGeo = new window.BMap.Geocoder({ extensions_town: true })
      myGeo.getLocation(new window.BMap.Point(e.point.lng, e.point.lat),
      )
    },
    initMap ({ BMap }) {
      this.BMap = BMap
      // 通过百度map获取经纬度再传给地图组件
      const that = this
      try {
        const geolocation = new window.BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            console.log(r);
            that.center = { lat: r.point.lat, lng: r.point.lng }
            that.point = { lat: r.point.lat, lng: r.point.lng }
            that.handler(r)
          },
          { enableHighAccuracy: true }
        )
      } catch (error) {
        this.$toast('请打开定位或退出重试')
      }
    },
    getHotel (item) {
      this.$router.push(
        {
          path: '/allHotel/HotelDatail',
          query: { hotelId: item.id }
        }
      )

    },
    async getEsportsData () {
      let data = {
        room_theme: 12,
        status: 1
      }
      await getHomeData(data).then(res => {
        // console.log(res, "获取热门推荐数据");
        let arr = []
        res.data.data.list.forEach(item => {
          console.log(res.data.data.list);
          arr.push(item)
        })
        console.log("===arr", arr);
        this.showEsportsData = arr.map(item => {
          if (item.lng_lat.length != 0) {
            // console.log(item, "当前");
            let gcj02tobd09 = coordtransform.gcj02tobd09(item.lng_lat[0], item.lng_lat[1])
            console.log(gcj02tobd09);
            // console.log(gcj02tobd09, "转换后的酒店定位");
            let distance = this.getDistance(gcj02tobd09[1], gcj02tobd09[0], Number(this.latitude_now), Number(this.longitude_now))//eslint-disable-line no-unused-vars
            item.distance = Math.trunc(distance / 1000)
          }
          return {
            id: item.hotel_id,
            img: item.hotel_pic,
            title: item.name,
            mode: item.mode,
            starting_price: Math.ceil(Number(item.starting_price)),
            city_name: item.city_name,
            hotel_tags: item.hotel_tags,
            discount: item.hotel_tags,
            lng_lat: item.lng_lat,
            distance: item.distance
          }
        })
      })
      console.log(this.showEsportsData, "====showEsportsData=====");
    },
    async like () {
      console.log("点击了");
      if (this.like_icon == "like-o") {
        console.log(this.like_icon, "1");
        this.like_icon = "like"
      } else if (this.like_icon == "like") {
        this.like_icon = "like-o"
        console.log(this.like_icon, "2");
      }
    },

    //日历上的显示
    getTime () {
      this.dateArray = sessionStorage.getItem('dateArray')
      this.dateArray = this.dateArray.split(',')
      this.ArrivalDate = new Date(Number(this.dateArray[0])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[0])).getDate() + '日'
      this.LeavelDate = new Date(Number(this.dateArray[this.dateArray.length - 1])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[this.dateArray.length - 1])).getDate() + '日'
      this.dateLength = this.dateArray.length - 1
    },
    formatter (day) {
      if (day.type === 'start') {
        day.bottomInfo = '入住';
      } else if (day.type === 'end') {
        day.bottomInfo = '退房';
      }
      return day;
    },
    dateBetweenDeal (startDate, endDate) {
      let st = new Date(startDate).getTime();
      let et = new Date(endDate).getTime();
      let retArr = [];
      const oneDay = 1000 * 60 * 60 * 24

      while (st != et) {
        retArr.push(st);
        st = st + oneDay
      }
      retArr.push(et);

      return retArr
    },

    onConfirm (date) {
      // const [start, end] = date;
      console.log(date);
      this.calendarShow = false;
      this.dateArray = this.dateBetweenDeal(new Date(date[0]).getTime(), new Date(date[1]).getTime())
      sessionStorage.setItem('dateArray', this.dateArray)
      console.log(this.dateArray, "====this.dateArray====")
      this.ArrivalDate = new Date(Number(this.dateArray[0])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[0])).getDate() + "日"
      this.LeavelDate = new Date(Number(this.dateArray[this.dateArray.length - 1])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[this.dateArray.length - 1])).getDate() + '日'
      // 储存时间戳
      // console.log(this.ArrivalDate)
      this.start_date = this.dateArray[0]
      this.end_date = this.dateArray[this.dateArray.length - 1]
      this.dateLength = this.dateArray.length - 1
    },
    onLoad () {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    openDate () {
      this.calendarShow = true
    },
    pay () {
      this.$router.push({ path: '/allPerInfo/wallet' })
    },
    goOrder () {
      this.$router.push({ path: '/allHotel/HotelMyOrder' })

    }
  },
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.container {
  width: 100%;
  height: 20.8148rem;
  background-color: #0f1235;
  // background-color: aqua;
  .BG {
    img {
      width: 100%;
    }
  }
  .container_hotel {
    font-size: 0;
    .hotel_top {
      display: inline-block;
      img {
        width: 7rem;
        font-size: 0;
      }
    }

    .hotel {
      margin-bottom: 0.3636rem;
      /deep/ .more_hotel {
        margin-top: 0.2727rem;
        margin-bottom: 0.2909rem;
        overflow: hidden;
        display: block;
        .van-button {
          border-radius: 20px;
          width: 135px;
          height: 36px;
          box-shadow: 0px 4px 7px 2px rgba(16, 16, 16, 0.24),
            inset 0px 4px 4px rgba(255, 255, 255, 0.25);
          background: linear-gradient(101.31deg, #0029ff 2.69%, #ad04fc 86.49%);
        }
      }
      padding: 0 0.2182rem;
      display: inline-block;
      .hotel_tab {
        display: flex;
        justify-content: space-between;
      }
      .van-button {
        font-size: 12px;
        border-radius: 5px;
        width: 0.9455rem;
        height: 0.4364rem;
        background: #f6f9ff linear-gradient(89deg, #2222ff 0%, #ab04fb 100%);
      }
      /deep/ .van-button--default {
        border: 1px solid #21007d !important;
        color: white !important;
      }
      width: 6.5736rem;
      // height: 500px;
      border-end-end-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: #21007d;
      .HotelList {
        overflow: hidden;
        width: 6.8736rem;
        .hotel_info {
          float: left;
          .hotel_info_img {
            border-radius: 12px 12px 0 0;
            overflow: hidden;

            width: 3.0909rem;
            height: 2.2727rem;
            // background-color: #2222ff;
            .hot {
              z-index: 1000;

              span {
                color: white;
                font-size: 0.182rem;
                position: absolute;
                display: inherit;
                z-index: 1000;
                margin-left: 0.0873rem;
              }
              position: absolute;
              img {
                width: 0.7273rem;
                display: inline;
                float: left;
              }
            }
            .hotel_img {
              float: left;
              position: relative;
              .hotel_imgage {
                float: left;
                img {
                  width: 3.1909rem;
                }
              }
              .hotel_img_bottom {
                bottom: -2%;
                position: absolute;
                .grade {
                  width: 3.0909rem;

                  height: 0.3636rem;
                  img {
                    width: 3.0909rem;
                  }
                }
              }
            }
          }

          .hotel_info_text {
            width: 2.9rem;
            padding: 0 0.1091rem;
            height: 1.5455rem;
            // background-color: aquamarine;

            border-radius: 0 0 12px 12px;
            .hotel_info_2 {
              .level {
                margin-top: 10px;
                display: inline-block;
                font-size: 12px;
              }
              .DIST {
                display: inline-block;

                font-size: 12px;
              }
            }
            .title {
              height: 0.3636rem;
              overflow: hidden;
              font-size: 16px;
              font-weight: 600;
            }

            .price {
              color: #ff6327;
              height: 20px;
              margin-top: 5px;
              margin-left: 0.0909rem;
              display: inline-block;
              float: left;
            }
            .hotel_btn {
              float: right;
              display: inline-block;
              margin-top: 5px;
              .van-button {
                background: #f6f9ff
                  linear-gradient(89deg, #fc6a20 0%, #fc231f 100%);
                width: 0.6545rem;
                height: 0.3636rem;
                font-size: 0.2182rem;
              }
              /deep/ .van-button--default {
                border: 1px solid white !important;
                color: white !important;
              }
            }
          }
          display: inline-block;
          margin-top: 0.2909rem;
          width: 3.0909rem;
          height: 3.8182rem;
          margin-right: 0.3273rem;
          background-color: white;
          border-radius: 12px;
        }
        .hotel_info_right {
          display: inline-block;
          float: right;
          margin-top: 0.2909rem;
          width: 3.0909rem;
          height: 3.8182rem;

          background-color: #ab04fb;
          border-radius: 12px;
        }
      }
      padding-top: 0.3636rem;
      padding-bottom: 0.3636rem;
    }
  }
}
</style>