<template>
  <div>
    <div class="headerImg"></div>
    <div class="header">
      <div class="headerTab_black">
        <div class="headerTab_white"
             :class="{content_white:ys1,content_white_right:ys2}">
          <van-tabs v-model="active">
            <van-tab title="国内"
                     style="display: flex;">
              <div slot="title"
                   @click="whole_left">国内</div>
              <div slot="default"
                   class="default">
                <div class="location">
                  <span class="current position"
                        @click="openAddrSheet">{{ city }}</span>
                  <span class="myLocation"
                        @click="Myposition">
                    <img @click="Myposition"
                         src="../assets/HotelImg/location_icon.png"
                         alt="">
                    <span class="my">
                      我的位置
                    </span>

                  </span>
                </div>
                <div class="date">
                  <van-cell :title="dateText == '' ? '选择日期' : dateText"
                            :value="dateCount"
                            is-link
                            @click="calendarShow = true" />
                  <van-calendar v-model="calendarShow"
                                color="#36e0c9"
                                type="range"
                                @confirm="onConfirm"
                                :default-date="defaultDate"
                                :formatter="formatter" />
                </div>
                <van-divider />
                <div class="KeyWord">
                  <van-field v-model="hotelKey"
                             disabled
                             is-link
                             placeholder="酒店/地标/关键词"
                             @click="goSerpage" />
                  <van-icon v-if="hotelKey"
                            class="close"
                            name="close"
                            @click="clearHotKey" />
                </div>
                <van-divider />

                <div class="KeyWord">
                  <van-field v-model="priceKey"
                             placeholder="价格/类型"
                             disabled
                             is-link
                             @click="openPrcieSheet" />
                  <van-icon v-if="newTypeArr.length != 0 && priceValue != [0, 1200]"
                            class="close"
                            name="close"
                            @click="clearPriKey" />
                </div>

                <div class="button">
                  <van-button type="primary"
                              @click="getKeyword">搜索酒店</van-button>
                </div>

              </div>
            </van-tab>
            <van-tab title="海外"
                     disabled>
              <div slot="title">海外</div>
              <div slot="default"></div>
            </van-tab>

          </van-tabs>
          <!--  -->
          <!-- <div class="headerTab_white_bottom">

          </div> -->

        </div>
      </div>

    </div>

    <!-- 地址弹窗 -->
    <AddressSheet :addressShow='addressShow'
                  @getbtn_Address1='getbtn_Address1'
                  @cancelA='cancelA'
                  @getCityInfo="getCityInfo"
                  @clickOverlayA='clickOverlayA'
                  @getCity='getCity'></AddressSheet>

    <PriceSheet :priceShow='priceShow'
                @getPrice='getPrice'
                @cancel='cancel'
                @clickOverlay='clickOverlay'></PriceSheet>
    <div class="search_action_sheet">
      <van-action-sheet v-model="searchShow"
                        title="搜索">
        <div id='HomeSearch'
             v-touch:right="onSwipeRight">
          <div class="top">
            <van-row class="row-radius">
              <van-col span="8">
                <span class="return"
                      @click="fanuhi">
                  <van-icon name="arrow-left" />
                </span>
              </van-col>
              <van-col span="8">
                <span class="name">搜索</span>
              </van-col>
              <van-col span="8">
                <span class="right">
                  <van-icon name="ellipsis" />
                </span>
              </van-col>
            </van-row>
            <div id="search">
              <form action="/">
                <van-search v-model="SearchValue"
                            @search="HotelSearch(SearchValue)"
                            shape="round"
                            left-icon=""
                            placeholder="请输入搜索关键词" />
              </form>
              <van-button id="search_btn"
                          size="small"
                          @click="HotelSearch(SearchValue)"
                          color="linear-gradient(to right, #00ECAF, #5EE3DE)">搜索</van-button>
            </div>
          </div>
          <div class="search_his"
               v-if="hotHotel">
            <div>
              <img class="search_his_img"
                   src="../assets/HotelImg/search.png"
                   alt="">

              <span class="search_his_text"
                    style="margin-left:0.1rem;display: inline-block;">搜索历史</span>

              <span style="float: right;margin-right:0.3rem;">
                <van-icon name="delete-o"
                          @click="clearHistory" />
              </span>

            </div>

          </div>
          <van-row>
            <van-col span="5"
                     v-for="item in HotelSearchValue "
                     :key="item"
                     style="margin-left:0.3rem">{{item}}</van-col>
          </van-row>
          <div class="container_hotel"
               v-if="container_hotel">
            <div class="Esports_hotel">
              <div class="Esports_hotel_left">
                <div class="Esports_hotel_img"><img :src="HotelContent.img"
                       alt=""></div>
              </div>
              <div class="Esports_hotel_right">
                <div class="Esports_hotel_top">
                  <div class="Esports_hotel_Info">
                    <div class="Esports_hotel_Info_name">{{HotelContent.title}}
                    </div>
                  </div>
                  <van-icon :name="like_icon"
                            color="red" />
                </div>
                <div class="Esports_hotel_leavel">
                  <div class="starting_price">¥{{HotelContent.starting_price}}</div>
                  <div style="color:#666666">起</div>
                </div>
                <div class="Esports_hotel_address">
                  <div class="Esports_hotel_address_left">
                    <van-icon name="location" /> <span>{{HotelContent.city_name}}·</span>

                  </div>
                  <!-- <div class="Esports_hotel_address_right">
                    <div> 距市中心3.2km</div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div :class="hotStatus==true?'hot':'hot_2'"
               v-for="item in 1"
               :key="item.id">
            <span>
              <img src="../assets/HotelImg/hot.png"
                   alt="">
            </span>
            <span style="margin-left:0.2rem;">热门酒店</span>
            <div class="container_hotel"
                 v-for="item in showEsportsData"
                 :key="item.id"
                 @click="getHotel(item)">
              <div class="Esports_hotel">
                <div class="Esports_hotel_left">
                  <div class="Esports_hotel_img"><img :src="item.img"
                         alt=""></div>
                </div>
                <div class="Esports_hotel_right">
                  <div class="Esports_hotel_top">
                    <div class="Esports_hotel_Info">
                      <div class="Esports_hotel_Info_name">{{item.title}}
                      </div>
                    </div>
                    <van-icon :name="like_icon"
                              color="red" />
                  </div>
                  <div class="Esports_hotel_leavel">
                    <div class="starting_price">¥{{item.starting_price}}</div>
                    <div style="color:#666666">起</div>
                    <!-- <div>4.7分|</div>
                    <div>307条点评|</div>
                    <div>$179起</div> -->
                  </div>
                  <div class="Esports_hotel_address">
                    <div class="Esports_hotel_address_left">
                      <van-icon name="location" /> <span>{{item.city_name}} ·</span>
                    </div>
                    <!-- <div class="Esports_hotel_address_right">
                      <div> 距市中心3.2km</div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <span style="float: right;margin-right:0.3rem;">
            <van-icon v-if="openFlag"
                      @click="openF"
                      name="arrow-up" />
            <van-icon v-else
                      @click="openT"
                      name="arrow-down" />
          </span>
          <van-row>
            <van-col span="5"
                     v-for="item in hotCityName"
                     :key="item"
                     @click="getHotelKey(item)">{{ item }}</van-col>
          </van-row> -->
        </div>
      </van-action-sheet>
    </div>
    <!--创建地图容器-->
    <div v-show="showMap">
      <baidu-map :center="center"
                 :scroll-wheel-zoom="true"
                 @ready="initMap"
                 ak='VFc7NsfU7amcxYbEiqypp5a3gHPLaiOO'
                 @click="handler">

      </baidu-map>
    </div>
  </div>
</template>
<script>
import { getHomeData } from '@/api/hotel.js'
// import { BMPGL } from '@/commom/bmpgl.js'
import wx from 'weixin-js-sdk'
import { getJSSDK } from '@/api/JSSDk.js'
import AddressSheet from './AddressSheet.vue'
import PriceSheet from './PriceSheet.vue'
import { aesKey } from "@/commom/aesKey"
import { getHotAddress } from '../api/address'
import { Toast } from 'vant';
import Bar_address from '@/utils/address'
import { Address_three } from '@/api/address.js'

export default {
  components: {
    AddressSheet,
    PriceSheet
  },
  data () {
    return {
      //百度地图
      BMap: '',
      address: '',
      active: 0,
      like_icon: 'like',
      city: '',
      point: {},
      center: {
        lat: '',
        lng: ''
      },
      history: '历史',
      showMap: false,
      ak: 'VFc7NsfU7amcxYbEiqypp5a3gHPLaiOO',
      cityInfo: {

      },//form上的城市信息
      dateText: '',
      calendarShow: false,
      dateCount: '',
      dateArray: '',//日期数据
      defaultDate: [],//默认日期
      hotelKey: '',//酒店关键字
      priceKey: '',//价格/类型关键字

      //地址弹窗
      addressShow: false,//地址弹窗是否出现
      showEsportsData: null,
      //价格弹窗
      priceShow: false,//价格弹窗是否出现
      priceValue: [],//价格弹窗滑块的值
      newTypeArr: [],//用于存放点击之后的类型按钮数据
      hotAddress: [], //热门城市
      hotCityName: [],
      detailed_address: '',
      HotelSearchValue: null,
      container_hotel: false,
      latitude: null,
      ys1: true,
      ys2: false,
      longitude: null,
      HotelContent: null,
      hotHotel: false,
      //搜索弹窗
      searchShow: false,//搜索弹窗是否出现
      SearchValue: '',//搜索框内容
      openFlag: false,//是否展开
      // addList: ['虎门镇', '长安镇', '厚街镇', '常平镇', '大朗镇', '塘厦镇', '寮步镇',
      //   '鸿福路口', '黄江镇', '东城', '国贸中心', '广东科技学院', '东莞南城区', '鸿福路口',
      //   '松山湖', '万科城'],//虚拟后台获取的地址数据
      before_addList: [],
      addressList: [],//上面显示的地址数据
      url: '',
      hotStatus: true
    }
  },
  //预加载图片
  beforeCreate () {
    let count = 0; // eslint-disable-line no-unused-vars
    let imgs = [
      //用require的方式添加图片地址，直接添加图片地址的话，在build打包之后会查找不到图片，因为打包之后的图片名称会有一个加密的字符串
      require('../assets/HotelImg/white_right.png')
    ]
    for (let img of imgs) {
      let image = new Image();
      image.onload = () => {
        count++;
      };
      image.src = img;
    }
  },
  created () {
    // this.PreLoad_img()
    this.showHotelSearchValue()
    this.url = window.location.href.split('#')[0]
    this.shareWechat()
    this.before_addList = this.hotCityName.splice(0, 8)
    this.addressList = this.before_addList
    //获取热门城市
    getHotAddress().then(res => {
      res.data.data.list.forEach(item => {
        // this.hotAddress = item.name
        this.hotCityName.push(item.name)
      })
      // console.log(this.hotCityName)
    })
    // console.log(getHotAddress.data'热门城市')
    //处理日期时间

    this.dateArray = sessionStorage.getItem('dateArray')
    console.log(this.dateArray, '======  this.dateArray======');
    this.dateArray = this.dateArray.split(',')
    let ArrivalDate = new Date(Number(this.dateArray[0])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[0])).getDate() + '日'
    let LeavelDate = new Date(Number(this.dateArray[this.dateArray.length - 1])).getMonth() + 1 + '月' + new Date(Number(this.dateArray[this.dateArray.length - 1])).getDate() + '日'
    // console.log(this.dateArray.length)
    this.dateText = ArrivalDate + '-' + LeavelDate
    this.dateCount = '共' + (this.dateArray.length - 1) + '晚'
    //城市默认信息
    if (sessionStorage.getItem('sessionCityData') == null) {
      this.cityInfo = {
        child: Array(0),
        city_id: 29422,
        code: 441900000000,
        first: "D",
        level: 2,
        name: "东莞市",
        pcode: 440000000000,
      }
    } else {
      this.cityInfo = JSON.parse(sessionStorage.getItem('sessionCityData'))
    }
    this.historyHotel()
  },
  mounted () {
    this.hotClass()
    //获取热门城市
    let data = {
      "is_hot": 1
    }
    this.getHotCity(data)
    this.getHomeData()
    //搜索历史 
    if (localStorage.getItem('HotelContent') == null) {
      this.HotelContent = []
    } else {
      this.HotelContent = JSON.parse(localStorage.getItem('HotelContent'))
    }

    if (localStorage.getItem('HotelSearchValue') == null) {
      this.HotelSearchValue = []
    } else {
      this.HotelSearchValue = JSON.parse(localStorage.getItem('HotelSearchValue'))
    }
    if (this.HotelSearchValue.length >= 7) {
      this.HotelSearchValue.pop()
    }
  },
  methods: {
    whole_left () {
      // 当点击第一次时，ys1从true变为false,Style样式type就会隐藏
      // 再点击第二次的时候由false,变为true,显示样式
      console.log("left");
      // !为取反
      if (this.ys1 == false) {
        this.ys2 = false,
          this.ys1 = true
      }
    },
    whole_right () {
      console.log("right");
      // 当点击第一次时，ys1从true变为false,Style样式type就会隐藏
      // 再点击第二次的时候由false,变为true,显示样式
      // !为取反
      if (this.ys2 == false) {
        this.ys1 = false,
          this.ys2 = true
      }
    },
    clearHistory () {
      localStorage.removeItem('HotelContent', 'HomeValue')
    },
    shareWechat () {
      let data = {
        url: this.url,
        apis: ['getLocation', 'openLocation']
      }
      // console.log(data);
      this.aesKey = aesKey(data)
      getJSSDK(this.aesKey).then(res => {
        // console.log(res, "JSSDK");
        wx.config({
          debug: false,
          appId: res.data.data.appId,
          timestamp: res.data.data.timestamp,
          nonceStr: res.data.data.nonceStr,
          signature: res.data.data.signature,
          jsApiList: ['getLocation', 'openLocation']
        })
      })
    },
    Myposition () {
      console.log("点击了我的位置");
      wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        wx.getLocation({
          altitude: true,
          isHighAccuracy: true,
          type: "gcj02", // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
          success: function (res) {
            console.log(res, "获取微信用户位置");
            const latitude = res.latitude;
            this.latitude = res.latitude;
            const longitude = res.longitude;
            this.longitude = res.longitude;
            let detailed_address = localStorage.getItem("detailed_address")
            console.log(detailed_address, "=====detailed_address=====");
            wx.openLocation({
              latitude,
              longitude,
              scale: 18,
              name: '当前位置',
              address: detailed_address, // 地址详情说明
              infoUrl: '跳转',// 在查看位置界面底部显示的超链接,可点击跳转
              success: function (res) {
                console.log(res);
              },
            });
            // console.log(latitude, longitude, "原gcj02经纬度");
            // //国测局坐标转百度经纬度坐标
            // let gcj02tobd09 = coordtransform.gcj02tobd09(longitude, latitude)
            // console.log(gcj02tobd09);
            // sessionStorage.setItem("latitude", gcj02tobd09[1]);
            // sessionStorage.setItem("longitude", gcj02tobd09[0]);

          },
        });
      })

    },
    hotClass () {
      let HotelContent = localStorage.getItem('HotelContent')
      if (HotelContent != null) {
        this.hotStatus = false
      } else {
        this.hotStatus = true
      }
    },
    historyHotel () {
      this.HotelContent = localStorage.getItem('HotelContent')
      if (this.HotelContent != null) {
        this.hotHotel = true
      } else {
        this.hotHotel = false
      }
    },

    handler (e) {
      this.city = e.address.city
      console.log(e);
      // console.log(this.city, "====this.city====");
      const myGeo = new window.BMap.Geocoder({ extensions_town: true })
      myGeo.getLocation(new window.BMap.Point(e.point.lng, e.point.lat),
        function (result) {
          console.log(result);
          localStorage.setItem("detailed_address", result.address)
          result.addressComponents.city
          console.log(result.addressComponents.city, " result.addressComponents.city======");
        })
      this.point = { lat: e.point.lat, lng: e.point.lng }
    },


    initMap ({ BMap }) {
      this.BMap = BMap
      // 通过百度map获取经纬度再传给地图组件
      const that = this
      try {
        const geolocation = new window.BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            // console.log(r);
            that.center = { lat: r.point.lat, lng: r.point.lng }
            that.point = { lat: r.point.lat, lng: r.point.lng }
            that.handler(r)
          },
          { enableHighAccuracy: true }
        )
      } catch (error) {
        this.$toast('请打开定位或退出重试')
      }
    },
    getbtn_Address2 () {
      let data = {
        name: this.city
      }
      console.log(data, "======data======");
      Address_three(data).then(res => {
        this.cityInfo = res.data.data
        this.cityInfo = {
          child: Array(0),
          city_id: this.cityInfo.id,
          code: this.cityInfo.code,
          level: this.cityInfo.level,
          name: this.cityInfo.name,
          pcode: this.cityInfo.pcode,
        }
        let sessionCityData = []
        sessionCityData = JSON.stringify(this.cityInfo)
        sessionStorage.setItem('sessionCityData', sessionCityData)
        console.log(this.cityInfo, "获取地址信息");
        // alert(res)
        let data2 = {
          city_id: this.cityInfo.city_id,
          status: 1
        }
        getHomeData(data2).then(res => {
          this.sheetData = res.data.data.list
          this.$emit('getsheet_Data', this.sheetData)
        })
      })
      console.log("点击了", this.cityInfo);
      this.$emit("getbtn_Address1", this.cityInfo)


    },
    HotelSearch (SearchValue) {
      if (SearchValue == '') {
        Toast.fail('搜索不能为空')
        return
      }
      this.cities = Bar_address
      this.cities.filter(item => {
        let newSeachValue = SearchValue + '市' //eslint-disable-line no-unused-vars
        if (item.name == newSeachValue) {
          console.log(item);

          this.searchAddress = item
          // this.$router.push({ path: '/allHotel/HotelList' })
        }
      })
      // this.HotelSearchValue = new Array (4)
      // console.log(this.HotelSearchValue.length);
      // console.log(SearchValue, "主页搜索");
      this.HotelSearchValue.unshift(SearchValue)
      localStorage.setItem("HomeValue", SearchValue)
      this.HotelSearchValue = this.HotelSearchValue.filter((item, index) => {
        return this.HotelSearchValue.indexOf(item, 0) === index
      })
      sessionStorage.setItem("searchValue", SearchValue)
      sessionStorage.setItem("sessionCityData", JSON.stringify(this.searchAddress))
      localStorage.setItem("HotelSearchValue", JSON.stringify(this.HotelSearchValue))
      let HomeValue = {
        search: this.SearchValue
      }
      if (this.searchAddress == undefined) {
        sessionStorage.setItem("sessionCityData", JSON.stringify(this.cityInfo))

      }
      // this.getbtn_Address2()
      // console.log(this.cityInfo, "213123123");
      // this.getAddressInfo()
      this.$router.push({ path: '/allHotel/HotelList', query: { SearchValue: HomeValue, status: 1 } })
    },
    // HotelSearch (SearchValue) {
    //   console.log(SearchValue);
    //   let sessionCityData = []
    //   sessionCityData = JSON.stringify(this.cityInfo)
    //   this.HotelSearchValue.unshift(SearchValue)
    //   console.log("222222222");
    //   this.HotelSearchValue = this.HotelSearchValue.filter((item, index) => {
    //     return this.HotelSearchValue.indexOf(item, 0) === index
    //   })
    //   sessionStorage.setItem('sessionCityData', sessionCityData)
    //   localStorage.setItem("HotelSearchValue", JSON.stringify(this.HotelSearchValue))
    //   // console.log(SearchValue, "hotel");
    //   if (SearchValue == "") {
    //     Toast.fail('搜索不能为空')
    //     return
    //   }
    //   let data = {
    //     SearchValue: SearchValue,
    //     city_id: this.cityInfo.city_id
    //   }
    //   console.log(data);
    //   sessionStorage.setItem('HotelSearchData', JSON.stringify(data))
    //   // let data = {
    //   //   search: SearchValue
    //   // }
    //   // getHomeData(data).then(res => {
    //   //   console.log(res);
    //   //   sessionStorage.setItem('HotelSearchData', JSON.stringify(res.data.data.list))
    //   // })
    //   this.$router.push({
    //     path: '/allHotel/HotelList'
    //   })
    // },
    // 获取热门酒店
    getHomeData () {
      let data = {
        is_hot: 1,
        longitude: 113.739228,
        latitude: 23.056675
      }
      getHomeData(data).then(res => {
        let arr = []
        res.data.data.list.forEach((item) => {
          arr.push(item);
        });
        this.showEsportsData = arr.map(item => {
          return {
            address: item.address,
            id: item.hotel_id,
            img: item.hotel_pic,
            title: item.name,
            mode: item.mode,
            starting_price: Math.ceil(Number(item.starting_price)),
            city_name: item.city_name,
            hotel_tags: item.hotel_tags,
            discount: item.hotel_tags
          }
        })
        // console.log(this.showEsportsData, "====showEsportsData=====");
      })
    },
    showHotelSearchValue () {
      let HotelSearchValu = localStorage.getItem('HotelContent')
      if (HotelSearchValu != undefined) {
        this.container_hotel = true
      } else {
        this.container_hotel = false

      }
    },
    getHotel (item) {
      localStorage.setItem('HotelContent', JSON.stringify(item))
      console.log(item);
      this.$router.push(
        {
          path: '/allHotel/HotelDatail',
          query: { hotelId: item.id }
        }
      )

    },
    //获取热门城市
    getHotCity () { },
    //form表单
    //计算日期差处理， 返回时间戳
    dateBetweenDeal (startDate, endDate) {
      let st = new Date(startDate).getTime();
      let et = new Date(endDate).getTime();
      let retArr = [];
      const oneDay = 1000 * 60 * 60 * 24
      while (st != et) {
        retArr.push(st);
        st = st + oneDay
      }
      retArr.push(et);
      return retArr
    },
    //处理日期数据
    formatDate (date) {
      return `${date.getMonth() + 1}月${date.getDate()}日`;
    },
    //日历确认
    onConfirm (date) {
      const [start, end] = date;
      this.calendarShow = false;
      this.dateArray = this.dateBetweenDeal(new Date(date[0]).getTime(), new Date(date[1]).getTime())
      this.dateText = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.dateCount = `共 ${this.dateArray.length - 1} 晚`;
    },
    //日历上的显示
    formatter (day) {
      if (day.type === 'start') {
        day.bottomInfo = '入住';
      } else if (day.type === 'end') {
        day.bottomInfo = '退房';
      }
      return day;
    },
    //清除酒店关键字
    clearHotKey () {
      // console.log(this.hotelKey)
      this.hotelKey = ''
    },
    //清除价格/类型数据
    clearPriKey () {
      console.log(this.newTypeArr)
      this.newTypeArr = []
      this.priceValue = []
      this.priceKey = ''
    },
    //发送数据
    getKeyword () {
      this.ShowHotelHotData = ''
      this.$emit('getKeyword', this.cityInfo, this.dateArray, this.hotelKey,
        this.newTypeArr, this.priceValue)
    },
    //地址弹窗
    //表单地址点击操作
    openAddrSheet () {
      this.addressShow = true
    },
    getbtn_Address () {
      // console.log(22)
    },
    getCityInfo (data) {
      // console.log(data);
      this.city = data.name
      this.cityInfo = data
      this.addressShow = false
    },
    //按钮获取地址
    getbtn_Address1 (data) {
      console.log(data);
      this.city = data.name
      this.cityInfo = data
      this.addressShow = false
    },
    //点击地址弹窗的关闭按钮
    cancelA () {
      this.addressShow = false
    },
    //点击地址弹窗时遮罩层
    clickOverlayA () {
      this.addressShow = false
    },
    //城市信息
    getCity (data) {
      this.cityInfo = data
      this.addressShow = false
    },

    //价格弹窗
    //酒店输入框点击打开弹窗
    //表单中价格input点击
    openPrcieSheet () {
      this.priceShow = true
    },
    //价格弹窗完成按钮
    getPrice (priceKey, priceValue, newTypeArr) {
      this.priceKey = priceKey
      this.priceValue = priceValue
      this.newTypeArr = newTypeArr
      this.priceShow = false
    },
    //点击价格弹窗的关闭按钮
    cancel () {
      this.priceShow = false
    },
    //点击价格弹窗时遮罩层
    clickOverlay () {
      this.priceShow = false
    },

    //搜索弹窗
    //酒店关键字点击
    goSerpage () {
      this.searchShow = true
    },
    //地址点击
    getHotelKey (data) {
      console.log(data)
      this.searchShow = false
      this.hotelKey = data
    },
    //按钮返回
    fanuhi () {
      this.searchShow = false
    },
    //移动端右滑返回
    onSwipeRight () {
      this.searchShow = false
    },
    //展开
    openT () {
      this.openFlag = true
      this.addressList = this.before_addList.concat(this.hotCityName)
    },
    //收起
    openF () {
      this.openFlag = false
      this.addressList = this.before_addList
    }

  }
}
</script>
<style lang="less" scoped>
/deep/.van-field__control {
  height: 36px;
  /depp/ input {
    background-color: white;

    border-radius: 10px;
    padding: 10px 5px 5px;
  }
}
.headerImg {
  width: 100%;
  height: 4.2593rem;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0) 0%
    ),
    url("../assets/HomeIcon/formbeijing1.png");
  // border-end-end-radius: 58px;
  // border-bottom-left-radius: 58px;
}
.header {
  /deep/ .van-tab__text--ellipsis {
    text-align: center;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    height: 100%;
    line-height: 0.8rem;
    width: 100%;
    -webkit-box-orient: vertical;
  }
  width: 100%;
  height: 100%;
  margin-top: -0.96rem;
  .headerTab_black {
    top: -9%;
    width: 7rem;
    height: 6rem;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../assets/HotelImg/black.png");
    // background: rgba(51, 51, 51, 0.59);
    // border-radius: 32px;
    background-size: 100%;
    .headerTab_white {
      background-image: url("../assets/HotelImg/white_left.png");
      // background-clip: content-box;
      position: absolute;
      width: 7.2rem;
      height: 7rem;
      // padding: 0.25rem 0.3rem 0.25rem 0.28rem;
      margin-left: auto;
      margin-right: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-size: 101%;
      left: -1.5%;
      top: 0%;
      // /deep/ .van-tabs__nav {
      //   background-color: rgb(4 3 3 / 0%) !important;
      // }
      .default {
        .KeyWord {
          /deep/ .van-cell__right-icon {
            line-height: 0.65rem;
          }
        }
        /deep/ .van-divider {
          margin: 0px;
        }
        /deep/ .van-cell {
          padding: 10px 0px;
        }
        padding: 0px 20px;
        padding-bottom: 0.5rem;
        border-bottom: white;
        padding-bottom: white;
        margin-top: 0.3rem;
        background-color: white;
        .button {
          width: 100%;
          margin: auto;
          .van-button--primary {
            border: #36e0c9 1px solid;
          }
          .van-button {
            width: 100%;

            background: #36e0c9;
            border-radius: 36px;
          }
        }
        .van-cell {
          color: #999999;
        }
      }
      .location {
        // margin-top: 0.3rem;
        display: flex;
        font-size: 0.3rem;
        justify-content: space-between;
      }
      .myLocation {
        display: flex;
        color: #36e0c9;
        font-size: 0.2rem;
        img {
          width: 0.3rem;
          height: 0.4rem;
        }
        .my {
          line-height: 0.5rem;
          margin-left: 0.1rem;
        }
      }
      .headerTab_white_bottom {
        width: 6.8rem;
        height: 1rem;
        // padding: 0rem 0.3rem;
      }
    }

    .content_white_right {
      background-image: url("../assets/HotelImg/white_right.png");
      position: absolute;
      width: 7.2rem;
      height: 6rem;
      // padding: 0.25rem 0.3rem 0.3rem 0.28rem;
      margin-left: auto;
      margin-right: auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-size: 101%;
      left: -1.5%;
      top: 0%;
      // /deep/ .van-tabs__nav {
      //   background-color: rgb(4 3 3 / 0%) !important;
      // }
      .default {
        padding: 0px 20px;
        margin-top: 0.3rem;
        background-color: white;
        .button {
          width: 100%;
          margin: auto;
          .van-button--primary {
            border: #36e0c9 1px solid;
          }
          .van-button {
            width: 100%;

            background: #36e0c9;
            border-radius: 36px;
          }
        }
        .van-cell {
          color: #999999;
        }
      }
      .location {
        // margin-top: 0.3rem;
        display: flex;
        font-size: 0.3rem;
        justify-content: space-between;
      }
      .myLocation {
        display: flex;
        color: #36e0c9;
        font-size: 0.2rem;
        img {
          width: 0.3rem;
          height: 0.4rem;
        }
        .my {
          line-height: 0.5rem;
          margin-left: 0.1rem;
        }
      }
    }

    .search {
      width: 100%;
      position: absolute;
      top: 0;
    }
    // /deep/ .van-tab {
    //   // background: rgba(51, 51, 51, 0.585758);
    //   color: white;
    // }
    // /deep/.van-tabs__wrap {
    //   // overflow: visible;
    //   // margin-top: 50px;
    //   width: 80%;
    // }
    /deep/ .van-tab {
      color: white;
    }
    /deep/ .van-tabs__nav {
      background: rgba(200, 54, 54, 0);
      color: white;
    }
    /deep/ .van-tab--active {
      color: black;
      font-weight: bold;
      // background-image: url("../assets/HotelImg/left.png");
    }
    /deep/.van-tabs__line {
      background-color: #36e0c9;
      width: 0.5rem;
    }
    // /deep/.van-tabs__nav--card {
    //   height: 0.9259rem;
    //   border: none;
    //   border-top-left-radius: 22px;
    //   border-top-right-radius: 22px;
    //   background-color: transparent;
    //   width: 92%;
    //   .van-tab {
    //     color: white;
    //     width: 3.5rem;
    //     border: none;
    //     background-color: rgb(17, 16, 16);
    //     opacity: 0.4;
    //     font-size: 0.3rem;
    //     border-top-left-radius: 10px;
    //     border-top-right-radius: 10px;
    //     // font-weight: bold;
    //     .van-tab__text--ellipsis {
    //       overflow: visible;
    //     }
    //   }
    //   .van-tab--active {
    //     margin-top: -5px;
    //     background-color: white;
    //     color: black;
    //     opacity: 1;
    //     height: 1.0185rem;
    //   }
    // }
    // /deep/.van-tabs__content {
    //   width: 92%;
    //   height: 100%;
    //   padding-bottom: 20px;
    //   border-bottom-left-radius: 20px;
    //   border-bottom-right-radius: 20px;
    //   background-color: white;
    //   margin-top: 20px;
    //   margin-left: 16px;
    //   box-shadow: 0px 14.8px 8.1px -5px rgba(0, 0, 0, 0.022),
    //     0px 23.8px 18.5px -5px rgba(0, 0, 0, 0.025),
    //     0px 40.1px 41.3px -5px rgba(0, 0, 0, 0.026),
    //     0px 77px 126px -5px rgba(0, 0, 0, 0.03);
    // }
  }
}
.form {
  .address {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;

    .city {
      font-size: 0.3rem;
      margin-left: 0.5rem;
      display: inline-block;
      width: 4.3rem;
    }

    .location {
      float: right;
      z-index: 999;
      margin-right: 0.4rem;
      font-size: 0.2rem;
      margin-top: 5px;
      color: #24c5af;
      position: relative;

      img {
        width: 30%;
        position: absolute;
        left: -40%;
      }
    }
  }

  .date {
    .van-cell {
      text-align: left;
    }

    .van-cell__title {
      font-size: 0.25rem;
      margin-left: 0.28rem;
    }

    .van-cell__value {
      margin-right: 0.24rem;
    }
  }

  .KeyWord {
    position: relative;

    .close {
      position: absolute;
      top: 35%;
      right: 4%;
      font-size: 0.2rem;
      color: #999999;
    }

    /deep/.van-cell {
      // border-bottom: 0.5px solid black;
    }

    /deep/.van-field__control {
      padding-left: 0.3rem;
    }
  }

  .button {
    margin-top: 0.1rem;

    .van-button--normal {
      text-align: center;
      font-size: 0.25rem;
      width: 6rem;
      background-color: #36e0c9;
      border: none;
      border-radius: 20px;
      margin-left: 0.5rem;
    }
  }
}

.address_action_sheet {
  .van-popup--bottom {
    top: 10%;
  }

  .van-action-sheet {
    max-height: 90%;

    .van-action-sheet__header {
      font-size: 0.3rem;
    }

    .content {
      height: 100%;
      font-size: 0.18rem;
      position: relative;

      /deep/.van-tabs__nav--line {
        position: fixed;
        width: 7rem;
        height: 0.3rem;
        z-index: 10;
        top: 21%;
        // background-color: aqua;
      }

      /deep/.van-tab__text--ellipsis {
        overflow: visible;
        // width: 100%;
      }

      .van-search {
        position: fixed;
        width: 100%;
        top: 15%;
        z-index: 10;
      }

      .van-search__content {
        border-radius: 20px;
      }

      /deep/.van-tab {
        font-size: 0.25rem;
        padding-top: 0.1rem;
      }

      /deep/.van-tab--active {
        font-weight: bold;
      }

      /deep/.van-tabs__line {
        background-color: #36e0c9;
        bottom: 5px;
        width: 0.4rem;
      }

      /deep/.van-tabs__content {
        width: 97% !important;
        margin-left: 0px !important;
        box-shadow: none !important;
        padding-left: 0.2rem;
      }

      .myAddress {
        margin-top: 0.65rem;

        .myA_left {
          font-size: 0.24rem;
          font-weight: bold;
        }

        .myA_right {
          float: right;
          margin-right: 0.5rem;
          color: #0d9ff0;
        }

        .myA_text {
          font-size: 0.24rem;
          margin-top: 0.15rem;
        }
      }

      .histChose {
        margin-top: 0.3rem;

        .hiC_left {
          font-size: 0.24rem;
          font-weight: bold;
        }

        .hiC_right {
          float: right;
          margin-right: 0.5rem;
          color: #999999;
        }
      }

      .letterChose {
        margin-top: 0.3rem;

        .ltC_left {
          font-weight: bold;
          font-size: 0.24rem;
        }

        /deep/.van-index-anchor {
          font-weight: bold;
        }

        #btn_tag {
          width: 5%;
        }

        .indexBar {
          margin-top: 0.3rem;

          /deep/.van-index-bar__sidebar {
            top: 62% !important;
          }

          /deep/.van-index-bar__index {
            margin-top: 0.1rem;
            color: #666666;
          }
        }
      }
    }

    #btn_tag {
      padding-right: 0.25rem;
      width: 11%;
    }

    .overseaPart {
      margin-top: 30px;
      margin-left: 1rem;

      /deep/.van-index-anchor {
        font-weight: 700;
      }

      /deep/.van-index-bar__sidebar {
        left: 0%;
        top: 76%;
        width: 18%;
        height: 100%;
        background: #f7f8fa;

        .van-index-bar__index {
          text-align: right;
          font-size: 0.23rem;
          padding-top: 0.15rem;
          padding-bottom: 0.15rem;
          padding-right: inherit;
          text-align: center;
        }

        .van-index-bar__index--active {
          color: black;
          background-color: white;
          font-weight: bold;
        }

        /deep/.van-index-anchor--sticky {
          top: 12%;
        }
      }

      .myAddress {
        margin-top: 0;
        margin-left: 0.25rem;
      }

      #letter_tag {
        display: inline-block;
        border: 1px solid #dedede;
        text-align: center;
        padding: 0.15rem 0.3rem;
        border-radius: 6px;
        font-size: 0.25rem;
        margin-left: 0.1rem;
        width: 5%;
        font-weight: 500;
        margin-top: 0.05rem;
      }

      .OP_letter {
        font-size: 0.1rem;

        .van-cell {
          font-size: 0.1rem;
          color: gray;
        }

        /deep/.van-index-bar__sidebar {
          left: 84% !important;
          height: 0% !important;
          top: 40% !important;
        }

        /deep/.van-index-bar__index {
          padding-bottom: 0rem;
          padding-top: 0.05rem;
          font-size: 0.1rem;
          color: #929292;
          font-weight: 300;
        }
      }
    }
  }
}

#btn_tag {
  display: inline-block;
  border: 1px solid #dedede;
  text-align: center;
  padding: 0.15rem 0.3rem;
  border-radius: 6px;
  font-size: 0.25rem;
  margin-left: 0.1rem;
  width: 13%;
  margin-top: 0.05rem;

  .button {
    // width: 1rem;
  }
}

.is_active {
  color: white;
  background-color: #36e0c9;
  border: none;
}

.price_action_sheet {
  /deep/.van-popup--bottom {
    bottom: 0%;
  }

  .van-action-sheet__header {
    font-size: 0.3rem;
    font-weight: 545;
  }

  .content {
    color: #333333;
    padding: 0 0.23rem;

    .van-slider {
      margin-top: 0.2rem;
      width: 94%;
      margin-left: 0.3rem;
    }

    .priceBtn {
      margin-top: 0.3rem;

      .van-col {
        position: relative;
        background-color: w;
        margin-left: 0.1rem;
        text-align: center;
        padding: 0.3rem 0;
        font-size: 0.23rem;
        width: 23%;
        margin-top: 0.1rem;

        .checked {
          position: absolute;
          bottom: 0%;
          right: 0%;
        }
      }

      .van-col.is_type_active {
        background-color: #36e0c9;
        color: white;
      }
    }

    .typeBtn {
      margin-top: 0.2rem;

      .chose_type {
        margin-top: 0.2rem;
        font-size: 0.23rem;

        .btn {
          position: relative;
          text-align: center;
          display: inline-block;
          background-color: #f6f6f6;
          padding: 0.32rem 0.48rem;
          margin-left: 0.1rem;

          .checked {
            position: absolute;
            bottom: 0%;
            right: 0%;
          }
        }

        .btn.is_type_active {
          background-color: #36e0c9;
          color: white;
        }
      }
    }
  }

  .bottom_btn {
    margin-top: 0.45rem;
  }
}

.search_action_sheet {
  background-color: none;

  /deep/.van-action-sheet {
    max-height: 100%;
  }

  /deep/.van-action-sheet__header {
    display: none;
  }

  .van-popup--bottom {
    top: 0;
  }

  /deep/.van-popup--bottom.van-popup--round {
    border-radius: 0%;
  }

  #HomeSearch {
    // background-color: #f7f7f7;
    font-size: 0.3rem;
    // height: 500px;

    .van-row {
      margin-top: 0.1rem;

      .van-col--5 {
        padding: 0.25rem 0;
        color: rgb(110, 108, 108);
        // border: 1px solid #eeebeb;
        background-color: white;
        margin-right: 8px;
        margin-left: 8px;
        margin-top: 5px;
        border-radius: 24px;
        margin-bottom: 5px;
        // margin-top: -1px;
        // margin-left: -1px;
        text-align: center;
        font-size: 0.25rem;
      }
    }

    .top {
      position: fixed;
      z-index: 10;
      top: 0%;

      .row-radius {
      }

      .van-row {
        padding: 0.2rem 0;
        border-radius: 10px;
      }

      width: 100%;
      text-align: center;
      padding-top: 0.1rem;
      background-color: white;

      .return {
        float: left;
        margin-left: 0.1rem;
        font-size: 0.4rem;
        color: #333333;
      }

      .name {
        // margin-left: -1rem;
        font-weight: bold;
      }

      .right {
        float: right;
        margin-right: 0.5rem;
      }

      .van-search {
        // background-color: #f7f7f7;
        .van-search__content {
          border-radius: 15px;
          // background-color: white;
        }
      }
    }

    .search_his {
      position: relative;
      // background-color: white;
      font-size: 0.27rem;
      color: #666666;
      padding-top: 0.1rem;
      height: 0.5rem;
      line-height: 0.5rem;
      margin: 2.3rem 0.15rem 0.15rem;
      padding-bottom: 10px;
      .search_his_img {
        margin-left: 0.1rem;
        margin-top: 0.07rem;
        display: inline-block;
        width: 10%;
      }
      .search_his_text {
        position: absolute;
        top: 40%;
      }
    }

    .hot {
      font-size: 0.27rem;
      margin-top: 2.5rem;
      color: #666666;
      padding-top: 0.1rem;
      margin-right: 0.15rem;
      margin-left: 0.15rem;
      border-radius: 10px;
    }
    .hot_2 {
      font-size: 0.27rem;
      margin-top: 0.4rem;
      color: #666666;
      padding-top: 0.1rem;
      margin-right: 0.15rem;
      margin-left: 0.15rem;
      border-radius: 10px;
    }
  }

  /deep/.van-field__control {
    background-color: white;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;

    span {
      line-height: 50px;
    }
  }

  .search {
    display: flex;

    /deep/.van-field__control {
      width: 280px;
    }

    span {
      line-height: 70px;
      color: #fe6326;
    }
  }
  #search {
    position: relative;
    height: 20px;
    .van-field__control {
      height: 40px;
    }
    #search_btn {
      position: absolute;
      top: 17px;
      right: 23px;
      width: 70px;
      border-radius: 20px;
    }
  }
}
.van-action-sheet__content {
  background-color: #f7f7f7;
}
.container_hotel {
  margin-top: 10px;
  position: relative;
  margin-left: 10px;
  height: 120px;
  width: 95%;
  overflow: hidden;
  .Esports_hotel {
    display: flex;
    .Esports_hotel_left {
      display: inline-block;
      width: 98px;
      float: left;
      height: 120px;
      .Esports_hotel_img {
        margin-top: 10px;
        margin-left: 10px;
        float: left;
        img {
          width: 88px;
          height: 88px;
          border-radius: 10px;
        }
      }
    }
    .Esports_hotel_right {
      display: inline-block;
      height: 120px;
      width: 250px;
      margin-left: 10px;
      float: left;
      .Esports_hotel_top {
        overflow: hidden;
        float: left;
        .van-icon {
          // left: 30%;
          top: 10%;
          left: 90%;
          position: absolute;
          font-size: 20px;
          margin-right: 10px;
        }
        .van-icon.is_like_active {
          // left: 30%;
          top: 10%;
          left: 90%;
          color: red;
          position: absolute;
          font-size: 20px;
          margin-right: 10px;
        }
        .Esports_hotel_Info {
          width: 50.3333vw;
          .Esports_hotel_Info_name {
            margin-top: 10px;
            // margin-left: -20px;
            font-size: 16px;
            height: 10.6667vw;
          }
        }
      }
      .Esports_hotel_leavel {
        overflow: hidden;

        position: absolute;
        right: 8%;
        top: 58%;
        // margin-left: 10px;
        font-size: 16px;
        color: #fe6529;
        div {
          float: left;
        }
        .starting_price {
          font-size: 20px;
        }
      }
      .Esports_hotel_address {
        overflow: hidden;
        margin-top: 66px;
        color: #999999;
        font-size: 12px;
        width: 150px;
        .Esports_hotel_address_left {
          // margin-left: 10px;
          float: left;
        }
        .Esports_hotel_address_right {
          position: absolute;
          display: inline-block;
          left: 75%;
          // margin-left: 30px;
          // left: 100%;
          // margin-right: 10px;
        }
      }
    }

    height: 108px;
    width: 96%;
    background-color: white;
    margin-left: 8px;
    border-radius: 10px;
  }
}
.van-search {
  background-color: #f7f7f7;
  .van-search__content {
    background-color: #f7f7f7;
  }
}
</style>