import { render, staticRenderFns } from "./outbound_trip_home.vue?vue&type=template&id=f38c2c24&scoped=true&"
import script from "./outbound_trip_home.vue?vue&type=script&lang=js&"
export * from "./outbound_trip_home.vue?vue&type=script&lang=js&"
import style0 from "./outbound_trip_home.vue?vue&type=style&index=0&id=f38c2c24&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f38c2c24",
  null
  
)

export default component.exports