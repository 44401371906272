<template>
  <div id="Login"
       :style="{height:innerHeight}">
    <!-- <NavBar :NavBarTitle='NavBarTitle'
            @onClickLeft='onClickLeft'></NavBar> -->
    <div class="content">
      <div class="title">登录/注册</div>
      <div style="color:#999999;margin-top:0.2rem">登录即刻享受低价</div>
      <div class="phone">
        <van-field readonly
                   clickable
                   :value="phoneValue"
                   placeholder="请输入手机号码"
                   @touchstart.native.stop="numberShow = true" />
        <van-number-keyboard v-model="phoneValue"
                             :show="numberShow"
                             :maxlength="11"
                             @blur="numberShow = false" />
      </div>
      <div class="btn">
        <van-button type="primary"
                    size="large"
                    round
                    color='#38e0c9'
                    @click="goVerify">获取验证码</van-button>
      </div>
      <div class="bottom">
        <div class="text">
          已阅读并同意
          <span>《用户服务协议》</span>、
          <span>《隐私政策》</span>及
          <span>《内容平台协议》</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import NavBar from '../../components/NavBar.vue'
import { Login_user } from '@/api/user'
import { Toast } from 'vant';

export default {
  components: {
    // NavBar,
  },
  data () {
    return {
      innerHeight: 0,
      NavBarTitle: '登录',
      numberShow: false,//号码键盘弹窗
      phoneValue: '',//电话号码
    }
  },
  mounted () {
    this.innerHeight = window.innerHeight + 'px'
  },
  methods: {
    onClickLeft () {
      this.$router.push({ path: '/Home' })
    },
    goVerify () {
      console.log("点击了");
      if (this.phoneValue == '') {
        Toast.fail('填写手机不能为空')
        return
      }
      let data = {
        phone: this.phoneValue
      }
      Login_user(data).then(res => {
        if (res.data.message == "请输入正确的手机号") {
          Toast.fail("请输入正确的手机号")
        }
        else if (res.data.code == 200) {
          console.log('verification_key', res.data.data.verification_key);
          sessionStorage.setItem('verification_key', res.data.data.verification_key)
          this.$router.push({ path: '/Login/Verify', query: { phone: this.phoneValue } })
        } else {
          Toast.fail("服务器发生错误")

        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
#Login {
  width: 100%;
  font-size: 0.21rem;
  .content {
    margin-top: 0.6rem;
    // padding: 0 0.2rem;
    // background-color: aqua;
    .title {
      font-size: 0.45rem;
      font-weight: bold;
    }
    .phone {
      margin-top: 1rem;
      z-index: 10;
      /deep/.van-cell {
        font-size: 0.3rem;
      }
    }
    .btn {
      margin-top: 0.4rem;
      padding: 0 0.4rem;
      .van-button {
        font-size: 0.28rem;
      }
    }
    .bottom {
      position: absolute;
      width: 100%;
      bottom: 5%;
      .text {
        color: gray;
        width: 4.6rem;
        margin-left: 1.3rem;
        span {
          color: #24c5af;
        }
      }
    }
  }
}
</style>