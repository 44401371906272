<template>
  <div id="weiaiRegister">
    <NavBar NavBarTitle='观视界'
            @onClickLeft='onClickLeft'></NavBar>
    <div class="img">
      <img src="@/assets/weiaiImg/top.gif"
           alt="">
    </div>
    <div style="padding-bottom:1rem">
      <div class="from">
        <div class="title">填写信息领取</div>
        <van-cell-group>
          <van-field v-model="phone"
                     placeholder="请输入手机号" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="name"
                     placeholder="请输入真实姓名" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="id_card"
                     placeholder="请输入有效证件号" />
        </van-cell-group>
        <van-cell-group>
          <!-- <van-field v-model="wangdian" placeholder="请选择领取网点" /> -->
          <van-field readonly
                     clickable
                     name="picker"
                     :value="wangdian"
                     placeholder="请选择领取网点"
                     @click="showPicker = true" />
        </van-cell-group>
        <div class="bottom">
          <van-button type="primary"
                      size="large"
                      round
                      color="linear-gradient(to bottom, #ff526b, #ee0a24)"
                      @click='submitInfo'>立即申领</van-button>
        </div>
      </div>
    </div>
    <van-popup v-model="showPicker"
               position="bottom">
      <van-picker show-toolbar
                  :columns="columns"
                  @confirm="onConfirm"
                  @cancel="showPicker = false" />
    </van-popup>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import { Toast } from 'vant';
import { aesKey } from '../../commom/aesKey'
import { getBankPoint, submitWeiaiUser } from '../../api/weiaiApi'
export default {
  components: {
    NavBar,
  },
  data () {
    return {
      // aesKey: aesKey(),//接口通过密文来获取数据
      phone: '',
      name: '',
      id_card: '',
      wangdian: '',
      user_id: '',
      bankList: [],//银行网点列表
      columns: [],
      showPicker: false,
    }
  },
  mounted () {
    this.getBankPoint()
  },
  created () {
    this.getUserId()
  },
  methods: {
    //第一次登录
    // isToken () {
    //   if (localStorage.getItem('wechatUser') = null) {
    //     this.$router.push('/Home')
    //   }
    // },
    getUserId () {
      const data = JSON.parse(localStorage.getItem('wechatUser'));
      // console.log(data.id, "用户id")
      this.user_id = data.id
      console.log(this.user_id, "this.user_id")
    },

    onClickLeft () {
      this.$router.go(-1)
    },
    //获取银行网点列表
    getBankPoint () {
      getBankPoint().then(res => {
        this.bankList = res.data.data.list
        // console.log(this.bankList)
        this.bankList.forEach(item => {
          this.columns.push(item.name)
        })
      })
    },

    // 网点选择
    onConfirm (value) {
      this.wangdian = value;
      this.showPicker = false;
    },

    //提交数据
    async submitInfo () {
      let bank_id = ''
      this.bankList.forEach(item => {
        if (item.name == this.wangdian) {
          bank_id = item.bank_id
        }
      })
      const DATA = {
        bank_point_id: bank_id,
        phone: this.phone,
        name: this.name,
        id_card: this.id_card,
        user_id: this.user_id
      }
      console.log(DATA)
      this.aesKey = aesKey(DATA)
      console.log(this.aesKey, " this.aesKey ")
      let res = await submitWeiaiUser(this.aesKey)
      // console.log(res)
      if (res.data.code == 200) {
        Toast.success(res.data.message);
        this.$router.push({ path: '/registerSuccess' })
      } else {
        Toast.fail(res.data.message);
      }
    },
  }
}
</script>

<style lang="less" scoped>
#weiaiRegister {
  background-color: #edc9ac;
  .img {
    img {
      width: 100%;
    }
  }
  .from {
    margin: 0 0.6rem;
    background-color: #fcf0e9;
    font-size: 0.3rem;
    .title {
      font-size: 0.4rem;
      font-weight: bold;
      color: #743828;
      padding: 0.3rem 0;
    }
    .van-cell-group {
      margin: 0 0.3rem;
      border-radius: 999px;
      .van-cell {
        padding: 0.13rem 0.2rem;
        border: 1px solid #eecbb0;
        font-size: 0.26rem;
        margin-bottom: 0.3rem;
        border-radius: 999px;
      }
    }
    .bottom {
      padding: 0.3rem 0.3rem;
      .van-button {
        height: 0.8rem;
        font-size: 0.32rem;
      }
    }
  }
}
</style>